
import useVuelidate from '@vuelidate/core'
import { helpers, minLength, required } from '@vuelidate/validators'
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import Input from '@/components/Library/Input/index.vue'
import PazzeiSimpleSelect from '@/components/Generics/PazzeiSimpleSelect.vue'
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import paymentsService from '@/services/payment'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { CouponsDTO } from '@/dtos/CouponsDTO'
import { setLimitDate } from '@/utils/common/limitDate'

export default defineComponent({
  name: 'ContentModalRegisterGroups',
  emits: ['close', 'changeSizeModal'],
  props: {
    groupId: {
      type: String,
      default: '',
    },
    listGroup: Function as PropType<() => void>,
    sizeEditLoading: {
      type: String,
    },
    listData: Function as PropType<() => void>,
  },
  components: { Input, PazzeiSimpleSelect, PazzeiBtn },
  setup(props, { emit }) {
    const messageRequired = 'Este campo é obrigatório'
    const selectOptions = ['Porcentagem', 'Valor']
    const $q = useQuasar()
    const couponValue = ref('')
    const update = ref(0)
    const isLoading = ref(false)
    const applyCouponMonthly = ref(false)
    const confirmAction = ref(false)
    const isConfirmAction = async () => {
      const result = await v$.value.$validate()
      if (result) {
        emit('changeSizeModal', true)
        confirmAction.value = true
      }
    }
    const isCancel = () => {
      emit('changeSizeModal', false)
      confirmAction.value = false
    }

    const formData = ref({
      code: '',
      type: ['Valor'],
      expiresAt: '',
      couponValue: '',
      description: '',
      usageLimit: '',
      validity: '',
      maxDiscount: '',
      hasValidity: false,
    })

    const rules = computed(() => {
      return {
        code: { required: helpers.withMessage(messageRequired, required), minLength: helpers.withMessage('O nome do cupom deve ter no mínimo 3 caracteres', minLength(3)) },
        type: { required: helpers.withMessage(messageRequired, required) },
        expiresAt: { validDate: helpers.withMessage('Insira uma data maior ou igual a data atual', (value: string) => setLimitDate(value)) },
        couponValue: { required: helpers.withMessage(messageRequired, required) },
        description: { required: helpers.withMessage(messageRequired, required), minLength: helpers.withMessage('A descrição deve ter no mínimo 3 caracteres', minLength(3)) },
      }
    })
    const v$ = useVuelidate(rules, formData)

    const getCurrentDate = () => {
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate())
      return currentDate.toISOString().split('T')[0]
    }

    const handleSubmit = async () => {
      let newDiscountValue = 0
      if (formData.value.type[0] === 'Porcentagem') {
        newDiscountValue = parseFloat(formData.value.couponValue.replace(',', '.')) / 100
      } else {
        newDiscountValue = parseFloat(formData.value.couponValue.replace(',', '.'))
      }

      const result = await v$.value.$validate()
      formData.value.hasValidity = applyCouponMonthly.value

      if (result) {
        try {
          isLoading.value = true
          const data = new CouponsDTO(
            formData.value.code,
            formData.value.type[0] === 'Porcentagem' ? 1 : 2,
            newDiscountValue,
            formData.value.description,
            formData.value.hasValidity,
            formData.value.maxDiscount ? parseFloat(formData.value.maxDiscount.replace(',', '.')) : undefined,
            formData.value.validity ? Number(formData.value.validity) : undefined,
            formData.value.usageLimit ? Number(formData.value.usageLimit) : undefined,
            formData.value.expiresAt ? new Date(formData.value.expiresAt) : undefined
          )
          await paymentsService.post('/coupons', data)
          $q.notify({
            textColor: 'grey-1',
            message: 'Cupom cadastrado com sucesso',
            color: 'green',
            position: 'top',
            classes: 'notify',
          })
        } catch (error) {
          console.error(error)
          $q.notify({
            textColor: 'grey-1',
            message: 'Erro ao cadastrar cupom',
            color: 'red',
            position: 'top',
            classes: 'notify',
          })
        } finally {
          props.listData?.()
          isLoading.value = false
          emit('close')
        }
      }
    }

    watch(
      () => formData.value.maxDiscount,
      () => {
        if (formData.value.type[0] === 'Valor') {
          const maxValue = parseFloat(formData.value.maxDiscount.replace(/\./g, '').replace(',', '.'))
          const compareValue = parseFloat(formData.value.couponValue.replace(/\./g, '').replace(',', '.'))
          if (maxValue > compareValue) {
            formData.value.maxDiscount = formData.value.couponValue
          }
        }
      }
    )

    watch(
      () => formData.value.type,
      (prev, cur) => {
        if (prev[0] !== cur[0]) {
          formData.value.couponValue = ''
        }
        update.value++
      }
    )

    return {
      update,
      formData,
      v$,
      selectOptions,
      couponValue,
      isLoading,
      applyCouponMonthly,
      confirmAction,
      handleSubmit,
      getCurrentDate,
      isConfirmAction,
      isCancel,
    }
  },
})
