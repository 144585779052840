
import PazzeiGoal from '@/components/Generics/PazzeiGoal.vue';
import Card from '@/components/Library/Card/index.vue';
import { defineComponent, inject, Ref, ref, watch } from 'vue';

const __default__ = defineComponent({
  name: 'GoalsChart',
  components: {
    Card,
    PazzeiGoal,
  },
  setup() {
    const itemContentRef = ref<HTMLElement | null>(null)
    const isExpanded = ref(false)
    const itemContentHeight = ref('0px')
    const warningHasBeenShown = inject<Ref<boolean>>('warningHasBeenShown')
    const onEditGoal = () => (isExpanded.value = true)
    const trialAlert = ref(false)
    const expandedAndWarning = ref(false)

    watch(
      warningHasBeenShown!,
      () => {
        if (warningHasBeenShown && !warningHasBeenShown.value) trialAlert.value = true
        else trialAlert.value = false
      },
      { deep: true, immediate: true }
    )

    watch(isExpanded, () => {
      if (isExpanded.value) {
        itemContentHeight.value = itemContentRef.value?.scrollHeight + 'px' || '0px'
        if (warningHasBeenShown && !warningHasBeenShown.value) expandedAndWarning.value = true
        else expandedAndWarning.value = false
      } else {
        itemContentHeight.value = '0px'
        expandedAndWarning.value = false
      }
    })

    return { itemContentRef, isExpanded, itemContentHeight, onEditGoal, trialAlert, expandedAndWarning }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "11e8ea73": (_ctx.itemContentHeight)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__