export class TagDTO {
  id?: number
  key: string
  value: string

  constructor(key: string, value: string, id?: number) {
    this.id = id
    this.key = key
    this.value = value
  }
}
