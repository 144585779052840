
import ListSummary from '@/components/AnswerList/ListSummary.vue';
import PazzeiButton from '@/components/Generics/Redesign/PazzeiButton.vue';
import { listHasQuestionDTO } from '@/dtos/ResponseDTOs/ListHasQuestionDTO';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: "ListCurrentTryResume",
  components: { PazzeiButton, ListSummary },
  props: {
    isMobile: { type: Boolean, required: true },
    showCheckQuestionsComponent: { type: Function, required: true },
    currentQuestionId: { type: Number },
    isUserCheckingQuestions: { type: Boolean, required: true },
    mainCtaDisabled: { type: Boolean, required: true },
    exitListButton: { type: Object as PropType<{ takeBreak: boolean, label: string }>, required: true },
    instantFeedback: { type: Boolean },
    relatedQuestions: { type: Array as PropType<listHasQuestionDTO[]> },
  },
  emits: ['change-question-handler', 'exit-list-handler', 'finalize-list-handler'],
  setup(_, { emit }) {
    const onQuestionChange = (info: any) => {
      emit('change-question-handler', info)
    }

    return {
      onQuestionChange
    }
  }
})
