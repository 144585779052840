
import Button from '@/components/Library/Button/index.vue';
import Input from '@/components/Library/Input/index.vue';
import { defineComponent, onMounted, ref, watch } from 'vue';

export default defineComponent({
  name: 'QuestionControlInputs',
  emits: ['update:modelValue', 'enter'],
  inheritAttrs: false,
  props: {
    disabled: { type: Boolean, default: false },
    hideNoQuestionButton: { type: Boolean, default: false },
    totalQuestionVariant: { type: Boolean, default: false },
    label: { type: String, default: '' },
    maxNumberQuestions: { type: Number, default: -1 },
    modelValue: { type: [Number, String], default: '0', required: true },
    step: { type: Number, default: 1 },
  },
  components: {
    Button,
    Input,
  },
  setup(props, { emit }) {
    const amountQuestions = ref(String(props.modelValue))

    onMounted(() => (amountQuestions.value = String(props.modelValue)))

    const handleIncrementNumber = (amount: number) => {
      if (amount === props.maxNumberQuestions) return
      amountQuestions.value = String(amount + props.step)
      emit('update:modelValue', Number(amountQuestions.value))
      emit('enter')
    }

    const handleDecrementNumber = (amount: number) => {
      if (amount === 0) return
      amountQuestions.value = String(amount - props.step)
      emit('update:modelValue', Number(amountQuestions.value))
      emit('enter')
    }

    const handleBlur = (amount: number) => {
      if (amount > props.maxNumberQuestions) amountQuestions.value = String(props.maxNumberQuestions)
      if (amountQuestions.value === '') amountQuestions.value = '0'

      emit('update:modelValue', Number(amountQuestions.value))
      emit('enter')
    }

    watch(amountQuestions, (newValue) => emit('update:modelValue', Number(newValue)))

    watch(
      () => props.modelValue,
      (newValue) => {
        if (newValue === props.modelValue) return
        amountQuestions.value = String(newValue)
      }
    )

    return { amountQuestions, handleIncrementNumber, handleDecrementNumber, handleBlur }
  },
})
