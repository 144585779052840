
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BreadcrumbComponent',
  props: {
    items: { type: Array, default: () => [] },
    separator: { type: String, default: '>' },
  },
  setup() {
    return {}
  },
})
