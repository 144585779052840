import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 16 13",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M1 8.07143C1 4.16599 4.13401 1 8 1C11.866 1 15 4.16599 15 8.07143M11.8889 8.07143C11.8889 10.2411 10.1478 12 8 12C5.85223 12 4.11111 10.2411 4.11111 8.07143C4.11111 5.90174 5.85223 4.14286 8 4.14286C10.1478 4.14286 11.8889 5.90174 11.8889 8.07143Z",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}