
import { ComponentPublicInstance, defineComponent, getCurrentInstance, inject, ref } from 'vue'
import { ParentAttrs } from './helpers/interfaces'

export default defineComponent({
  name: 'TabComponent',
  props: {
    name: { type: String, default: '', required: true },
  },
  setup() {
    const tabRef = ref<HTMLDataListElement | null>(null)
    const tabInjectedProps = inject<(tabName: string) => void>('handleTabUpdateClick')
    const parentInstance = getCurrentInstance()

    const handleTabChange = (tabName: string) => tabInjectedProps?.(tabName)

    const handleActivationTab = (tabName: string, parentAttrs: ComponentPublicInstance | null) => {
      const parentAttr = parentAttrs as ParentAttrs
      return { actived: tabName === parentAttr.modelValue, justify: parentAttr.align === 'justify' }
    }

    return { tabRef, tabInjectedProps, handleTabChange, handleActivationTab, parentInstance }
  },
})
