export class CheckoutDTO {
  street: string
  number: string
  zipcode: string
  city: string
  state: string
  country: string
  neighborhood: string
  cardholderName?: string
  document?: string
  expirationDate?: string
  cardNumber?: string
  cvv?: string
  planId: number
  installments?: number
  paymentMethodCode: string
  coupon?: string
  autoRenew?: boolean

  constructor(
    street: string,
    number: string,
    zipcode: string,
    city: string,
    state: string,
    country: string,
    neighborhood: string,
    paymentMethodCode: string,
    planId: number,
    document?: string,
    cardholderName?: string,
    expirationDate?: string,
    cardNumber?: string,
    cvv?: string,
    installments?: number,
    coupon?: string,
    autoRenew?: boolean
  ) {
    this.street = street
    this.number = number
    this.zipcode = zipcode
    this.city = city
    this.state = state
    this.country = country
    this.neighborhood = neighborhood
    this.cardholderName = cardholderName
    this.document = document
    this.expirationDate = expirationDate
    this.cardNumber = cardNumber
    this.cvv = cvv
    this.planId = planId
    this.installments = installments
    this.paymentMethodCode = paymentMethodCode
    this.coupon = coupon
    this.autoRenew = autoRenew
  }
}
