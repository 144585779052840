
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import ModalEmailConfirmation from '@/components/LoginForms/ModalEmailConfirmation.vue'
import ModalTerms from '@/components/Terms/ModalTerms.vue'
import DefaultTheme from '@/theme/defaultTheme'
import cpfRules from '@/utils/vuelidateRules/cpfRules'
import email from '@/utils/vuelidateRules/emailRules'
import password from '@/utils/vuelidateRules/passwordRules'
import telephone from '@/utils/vuelidateRules/telephoneRules'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required, sameAs } from '@vuelidate/validators'
import { computed, defineComponent, onUnmounted, provide, reactive, ref, watch } from 'vue'
import { ConfirmationEmailDTO } from '../../dtos/ConfirmationEmailDTO'
import { ValidationEmailDTO } from '../../dtos/ValidationEmailDTO'
import authenticationService from '../../services/authentication'
import authService from '../../services/authService'
import usersService from '../../services/users'
import PazzeiModalAlert from '../GenericModals/PazzeiModalAlert/PazzeiModalAlert.vue'
import ThumbDownIcon from '../Icons/default/ThumbDownIcon.vue'

export default defineComponent({
  name: 'RegistrationForm',
  components: {
    PazzeiBtn,
    ModalEmailConfirmation,
    ModalTerms,
    PazzeiModalAlert,
  },
  setup() {
    let interval: number
    const modalVisibility = ref(false)
    const modalTerms = ref({
      visibility: false,
      term: '',
    })
    const messageRequired = 'Este campo é obrigatório'
    const isLoading = ref(false)
    const confirmTermsCheckbox = ref(false)

    const formData = reactive({
      name: '',
      lastName: '',
      telephone: '',
      cpf: '',
      email: '',
      emailConfirm: '',
      password: '',
      passwordConfirmation: '',
    })

    const modalExistingAccountAlert = reactive({
      show: false,
    });

    provide('formData', formData)

    const rules = computed(() => {
      return {
        name: { required: helpers.withMessage(messageRequired, required) },
        lastName: { required: helpers.withMessage(messageRequired, required) },
        telephone,
        cpf: cpfRules,
        email,
        emailConfirm: {
          required: helpers.withMessage(messageRequired, required),
          sameAs: helpers.withMessage('Os e-mails devem coincidir', sameAs(formData.email)),
        },
        password,
        passwordConfirmation: {
          required: helpers.withMessage('Este campo é obrigatório', required),
          sameAs: helpers.withMessage('As senhas devem coincidir', sameAs(formData.password)),
        },
      }
    })

    const closeModal = () => {
      modalVisibility.value = false
      formData.email = ''
      formData.emailConfirm = ''
      formData.name = ''
      formData.lastName = ''
      formData.password = ''
      formData.passwordConfirmation = ''
      formData.telephone = ''
    }

    watch(
      formData,
      async () => {
        v$.value.$validate()
      },
      { deep: true }
    )
    const handleModalOfTerms = (state = !modalTerms.value.visibility, termType?: string) => {
      modalTerms.value.visibility = state
      modalTerms.value.term = termType ?? ''
    }

    const handleModalOfExistingAccountAlert = (state = !modalExistingAccountAlert.show) => {
      modalExistingAccountAlert.show = state
    }

    const close = (e: Event) => {
      if (e instanceof KeyboardEvent)
        if (e.key === 'Escape') {
          closeModal()
          handleModalOfTerms(false)
        }
    }

    document.addEventListener('keydown', close)

    onUnmounted(() => {
      document.removeEventListener('keydown', close)
      clearInterval(interval)
    })

    const v$ = useVuelidate(rules, formData)

    const handleRegistration = () => {
      const validationEmail = new ValidationEmailDTO(formData.email)

      usersService.post('/email', validationEmail).then((res) => {
        const userExists = res.data.data.exists
        if (!userExists) {
          const confirmation = new ConfirmationEmailDTO(formData.email, formData.name)

          authenticationService.post('/request-email-verification', confirmation).then((res) => {
            authService.setOtpToken(res.data.data.otpToken)
            modalVisibility.value = true
          })
        } else {
          sessionStorage.setItem('email', formData.email)

          handleModalOfExistingAccountAlert(true);
        }
      })
    }
    const submit = async () => {
      const result = await v$.value.$validate()

      if (result) handleRegistration()
    }

    onUnmounted(() => clearInterval(interval))

    return {
      v$,
      formData,
      submit,
      isLoading,
      isPwd: ref(true),
      modalTerms,
      modalVisibility,
      modalExistingAccountAlert,
      closeModal,
      handleModalOfTerms,
      handleModalOfExistingAccountAlert,
      confirmTermsCheckbox,
      isPwdOk: ref(true),
      DefaultTheme,
      ThumbDownIcon
    }
  },
})
