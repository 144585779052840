
import ChangeAnswerIcon from '@/components/Icons/default/redesign/sharedList/ChangeAnswerIcon.vue'
import { QuestionDTO } from '@/dtos/ResponseDTOs/QuestionDTO'
import questionsService from '@/services/question'
import DefaultTheme from '@/theme/defaultTheme'
import axios from 'axios'
import { defineComponent, inject, onBeforeMount, onMounted, onUnmounted, PropType, reactive, Ref, ref, watch, watchEffect } from 'vue'
import { CorrectAndSelectedAlternativeDTO } from '../../../dtos/CorrectAndSelectedAlternativeDTO'
import { AlternativeDTO } from '../../../dtos/ResponseDTOs/AlternativeDTO'
import { ResponseDTO } from '../../../dtos/ResponseDTOs/ResponseDTO'
import { TagDTO } from '../../../dtos/ResponseDTOs/TagDTO'
import { UserResponseDTO } from '../../../dtos/ResponseDTOs/UserResponseDTO'
import AlternativesSelector from '../../Application/alternativesSelector/AlternativesSelector.vue'
import Alternative from '../../Application/alternativesSelector/Redesign/Alternative.vue'
import { AlternativesSelectorModelValueType } from '../../Application/alternativesSelector/types/AlternativesSelectorModelValueType'
import CustomCTA from '../../Generics/Redesign/CustomCTA.vue'
import PazzeiButton from '../../Generics/Redesign/PazzeiButton.vue'

export default defineComponent({
  name: 'QuestionsCheckWrapper',
  emits: [
    'handle-feedback',
    'update:modelValue',
    'get-question-type',
    'get-question-answer',
    'get-tags',
    'stop-loading',
    'getCorrectAlternatives',
    'next-question',
    'previous-question',
    'save-question',
  ],
  props: {
    questionId: Number,
    questionOrder: Number,
    answers: Object as PropType<Array<UserResponseDTO>>,
    listId: Number,
    feedback: Boolean,
    isInstantFeedback: { type: Boolean, default: false },
    modelValue: { type: Object as PropType<AlternativesSelectorModelValueType>, default: () => ({}) },
    correctAndSelect: Object as PropType<CorrectAndSelectedAlternativeDTO>,
    selected: Number,
    correctAlternatives: { type: Array as PropType<Array<number>>, default: () => [] },
    modal: Boolean,
    modalAnsweredQuestion: Boolean,
    maxWidthTotal: Boolean,
    summary: Boolean,
    paddingRightAlternatives: Boolean,
    variant: { type: String as PropType<'primary'>, default: '' },
    page: { type: String as PropType<'check-answers'>, default: '' },
    questionNumber: Number,
    totalQuestions: Number,
    isDoubt: Boolean,
    questions: { type: Array as PropType<AlternativesSelectorModelValueType[]>, default: () => ([{}]) },
    isPrinting: { type: Boolean, default: false },
    checkedAlternative: { type: Number, default: null },
    discardedAlternatives: { type: Array as PropType<number[]>, default: () => [] },
  },
  components: {
    Alternative,
    AlternativesSelector,
    CustomCTA,
    PazzeiButton
  },
  setup(props, { emit }) {
    const alternativesModel = ref<AlternativesSelectorModelValueType>()
    const summationModel = ref(0)
    const questionType = ref<string>('')
    const showAnswer = ref(false)
    const controller = new AbortController()
    const questionDTO = ref<QuestionDTO>()
    const questionURL = ref<string>('')
    const selectedAlternative = ref<number>()
    const alternativesURL = ref(Array<string>())
    const textBaseURL = ref<string>('')
    const getTag = inject<Ref<Array<TagDTO>>>('topicTag')
    const optionCurrentSelected = ref<number | null>(0)
    const alternatives = ref(Array<AlternativeDTO>())
    const questionHTML = ref<any>()
    const textBaseHTML = ref<any>()
    const alternativeHTML = ref<Record<string, any>>({})
    const showDebugId = ref(false)
    const alternativeSelectedOnInstantFeedback = ref<Array<number>>([])
    const timeOut = ref(false)
    const correctAlternativeIds = ref<Array<number>>([])
    const questionNotDownloaded = ref(false)
    const showLoading = ref(true)
    const count = ref(0)
    const correctAlternativesSummary = ref<Array<number>>([])
    let interval = 0
    let letIntervalBe = 2000
    const alternativeObject = reactive({
      selected: false,
    });

    const initialAlternativesModel = ref<AlternativesSelectorModelValueType>()
    const initialDiscardedAlternatives: any = ref([]);

    const updateDiscarded = (alternativeId: number, newState: boolean) => {
      if (newState) {
        if (!alternativesModel.value?.discardedAlternatives?.includes(alternativeId)) {
          !alternativesModel.value?.discardedAlternatives?.push(alternativeId);
        } else {
          alternativesModel.value.discardedAlternatives.push(alternativeId)
        }
      }
    };

    const { updateQuestionAws } = inject<{ questionAws?: string; updateQuestionAws: (awsKey?: string) => void }>('questionAwsKey', {
      questionAws: '',
      updateQuestionAws: () => {
        return
      },
    })

    const goBack = () => {
      emit('previous-question', props.questionNumber);
    }
    const goForward = () => {
      emit('next-question', props.questionNumber);
    }

    const initQuestion = async () => {
      emit('stop-loading', false)
      count.value++
      if (letIntervalBe < 10000) letIntervalBe *= 2
      else letIntervalBe = 10000

      try {
        const response = await questionsService.get<ResponseDTO<QuestionDTO>>(`/${props.listId}/${props.questionId}`, { signal: controller.signal })

        questionType.value = response.data.data.tags.find((tag) => tag.key === 'type')?.value || ''

        if (props.summary) correctAlternativesSummary.value = response.data.data.correctAlternativeIds || []

        emit('get-tags', response.data.data.tags)
        emit('get-question-type', questionType.value)
        emit('getCorrectAlternatives', response.data.data.correctAlternativeIds)

        questionDTO.value = response.data.data

        if (props.isInstantFeedback && response.data.data.correctAlternativeIds && response.data.data.correctAlternativeIds.length > 0 && response.data.data.selectedAlternativeIds.length > 0) {
          showAnswer.value = true
          correctAlternativeIds.value = response.data.data.correctAlternativeIds || []
        }

        updateQuestionAws(questionDTO.value?.resolutionAwsKey)

        const retrievedAlternativesSorted = response.data.data.alternatives.sort((a, b) => a.order! - b.order!)
        retrievedAlternativesSorted.forEach((alt) => {
          alternativeHTML.value[alt.awsKey] = ''
        })
        alternatives.value.push(...retrievedAlternativesSorted)

        questionURL.value = process.env.VUE_APP_AWS_QUESTIONS_BASE_URL + questionDTO.value.awsKey

        if (getTag) getTag.value = response.data.data.tags

        textBaseURL.value = process.env.VUE_APP_AWS_QUESTIONS_TEXTS_BASE_URL + questionDTO.value?.baseTextAwsKey

        const res = await axios.get<ResponseDTO<string>>(questionURL.value, { signal: controller.signal })
        questionHTML.value = res.data

        if (res) {
          showLoading.value = false
          clearTimeout(interval)
        }

        questionDTO.value.alternatives.forEach((it) => {
          alternativesURL.value.push(process.env.VUE_APP_QUESTIONS_ALTERNATIVES_BASE_URL + it.awsKey)
        })

        for await (const url of alternativesURL.value) {
          const res = await axios.get<ResponseDTO<any>>(url, { signal: controller.signal })
          alternativeHTML.value[url.replace(process.env.VUE_APP_QUESTIONS_ALTERNATIVES_BASE_URL, '')] = res.data
          clearTimeout(interval)

          if (!res) showLoading.value = true
        }

        axios.get<ResponseDTO<any>>(textBaseURL.value, { signal: controller.signal }).then((resText) => (textBaseHTML.value = resText.data))

        if (props.answers?.length) {
          const alternativeId = props.answers.find((answer) => props.questionId === answer.questionId)?.alternativesIds
          if (alternativeId) selectedAlternative.value = alternativeId as any
          const t = questionDTO.value.alternatives.map((it) => it).findIndex((it) => it.id === selectedAlternative.value)
          optionCurrentSelected.value = t
        } else optionCurrentSelected.value = null
      } catch (error) {
        if (count.value < 100)
          interval = setTimeout(async () => {
            await initQuestion()
          }, letIntervalBe)
        else {
          clearTimeout(interval)
          questionNotDownloaded.value = true
        }
      } finally {
        emit('handle-feedback', !questionNotDownloaded.value && !showLoading.value)
        emit('stop-loading', true)
        // isHtmlLoading.value = false
      }
    }

    const displaySelectedOnly = ref(true);
    const handleDisplaySelectedOnly = (value: boolean) => {
      displaySelectedOnly.value = value;
    }

    onMounted(async () => {
      setTimeout(() => {
        timeOut.value = true
      }, 1500)

      await initQuestion()

      const debug = sessionStorage.getItem('debug')
      if (debug === 'true') showDebugId.value = true
      else showDebugId.value = false
    })

    onUnmounted(() => {
      controller.abort()
      clearTimeout(interval)
      document.removeEventListener('keydown', showDebug)
    })

    const showDebug = (e: Event) => {
      if (e instanceof KeyboardEvent)
        if (e.key === 'd') {
          showDebugId.value = !showDebugId.value
          sessionStorage.setItem('debug', String(showDebugId.value))
        }
    }

    const handleClickSeeAnswerQuestion = () => {
      emit('get-question-answer', alternativesModel.value)
      showAnswer.value = true
    }

    const cancelChanges = () => {
      alternativesModel.value = initialAlternativesModel.value;

      if (alternativesModel.value) {
        if (initialAlternativesModel.value?.alternatives) {
          alternativesModel.value.alternatives = initialAlternativesModel.value?.alternatives;
        }

        alternativesModel.value.discardedAlternatives = initialAlternativesModel.value?.discardedAlternatives;
      }
      handleDisplaySelectedOnly(true);
      emit('update:modelValue', alternativesModel.value);
    }

    document.addEventListener('keydown', showDebug)

    onBeforeMount(() => {
      alternativesModel.value = props.questions.find((id) => id.questionId === props.questionId);
      initialAlternativesModel.value = alternativesModel.value;
      initialDiscardedAlternatives.value = alternativesModel.value?.discardedAlternatives;

    })

    watch(
      () => props.modelValue,
      () => {
        emit('update:modelValue', alternativesModel.value)

        if (props.isInstantFeedback && props.modelValue.alternatives && props.modelValue.alternatives.length) {
          alternativeSelectedOnInstantFeedback.value = props.modelValue.alternatives as number[]
        }
      },
      {
        deep: true,
        immediate: true,
      }
    )

    watchEffect(() => {
      if (questionType.value.includes('Múltipla escolha') && questionDTO.value && props.page === 'check-answers') showAnswer.value = true
      if (questionType.value.includes('Múltipla escolha') && props.page !== 'check-answers') showAnswer.value = true
      if (!questionType.value.includes('Múltipla escolha') && props.feedback) showAnswer.value = true

      if (props.correctAlternatives && props.correctAlternatives.length) correctAlternativeIds.value = props.correctAlternatives
      if (props.feedback) alternativeSelectedOnInstantFeedback.value = props.correctAndSelect?.responseAlternativeIds || []
    })

    return {
      alternativesModel,
      selectedAlternative,
      questionDTO,
      questionURL,
      alternativesURL,
      optionCurrentSelected,
      showAnswer,
      correctAlternativeIds,
      alternatives,
      textBaseHTML,
      questionHTML,
      correctAlternativesSummary,
      summationModel,
      alternativeSelectedOnInstantFeedback,
      handleClickSeeAnswerQuestion,
      alternativeHTML,
      questionType,
      showDebugId,
      timeOut,
      questionNotDownloaded,
      showLoading,
      goBack,
      goForward,
      alternativeObject,
      handleDisplaySelectedOnly,
      displaySelectedOnly,
      cancelChanges,
      updateDiscarded,
      DefaultTheme,
      ChangeAnswerIcon
    }
  },
})
