import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export const redirectLink = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const token = String(to.query.token)

  if (token) {
    try {
      localStorage.setItem('refreshToken', token)
      window.location.href = '/refresh'
    } catch (error) {
      next({ name: 'loginPage' })
    }
  } else {
    next({ name: 'loginPage' })
  }
}
