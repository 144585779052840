
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import DefaultTheme from '@/theme/defaultTheme'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, inject, onMounted, PropType, provide, ref, watch, Ref } from 'vue'
import { ListTryDTO, SubjectInfoDTO } from '@/dtos/SubjectInfoDTO'
import listsService from '../../services/list'
import GroupIcon from '../Icons/default/GroupIcon.vue'
import PazzeiMenuLists from './PazzeiMenuLists.vue'

const __default__ = defineComponent({
  name: 'SubjectList',
  components: {
    PazzeiBtn,
    PazzeiMenuLists,
    GroupIcon
  },
  props: {
    subjectInfo: {
      type: Object as PropType<SubjectInfoDTO>,
      required: true,
    },
    notShowMenu: {
      type: Boolean,
      default: false,
    },
    isWhiteLabel: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const progressSize = ref({
      progressCircleSize: '5.5em',
      progressAvatarSize: '4.5em',
    })
    const lastTry = ref<ListTryDTO>()
    const totalResponses = props.subjectInfo.listTries && props.subjectInfo.listTries.length > 0 ? props.subjectInfo.listTries[props.subjectInfo.listTries.length - 1].totalResponses : 0
    const currentColor = totalResponses ? ref(props.subjectInfo.color) : DefaultTheme().colors.gray300
    const tryFinished = props.subjectInfo.listTries && props.subjectInfo.listTries.length > 0 ? props.subjectInfo.listTries[props.subjectInfo.listTries.length - 1].isFinished : false
    const editName = ref(false)
    const showFeedback = ref(props.subjectInfo.returnFeedback)
    provide('editName', editName)
    const name = ref(props.subjectInfo.name)
    const $q = useQuasar()
    const focusEditName = ref<HTMLInputElement>()
    const tempName = ref(props.subjectInfo.name)
    const isTest = ref(props.subjectInfo.isTest)
    const pathname = window.location.pathname
    const reloadLists = inject<() => void>('loadLists')
    const initExerciseListPage = inject<() => void>('initExerciseListPage')
    const expirationDate = ref(props.subjectInfo.listTries.length > 0 && props.subjectInfo.listTries[0].expiresAt)
    let blockDoubleClick = false
    const userCanInteract = inject<Ref<string>>('userCanInteract')

    const setProgressSize = () => {
      if (window.innerWidth <= 768) progressSize.value = { progressCircleSize: '3.5em', progressAvatarSize: '2.5em' }
      else progressSize.value = { progressCircleSize: '5.5em', progressAvatarSize: '4.5em' }
    }

    const handleTooltipInfos = () => {
      if (!expirationDate.value) {
        return { message: '', color: '' }
      }

      const dateObject = new Date(expirationDate.value)
      const currentDate = new Date()
      const dayDiff = (dateObject.getTime() - currentDate.getTime()) / (1000 * 3600 * 24)
      if (dayDiff > 1) return { message: `Expira em ${dateObject.toLocaleString()}`, color: 'bg-purple' }
      if (dayDiff > 0) return { message: `Expira hoje às ${dateObject.toLocaleTimeString()}`, color: 'bg-purple' }
      if (dayDiff > -1) return { message: `Expirou hoje às ${dateObject.toLocaleTimeString()}`, color: 'bg-red' }
      return { message: `Expirou em ${dateObject.toLocaleString()}`, color: 'bg-red' }
    }

    const isExpired = handleTooltipInfos().color === 'bg-red'

    onMounted(setProgressSize)

    window.addEventListener('resize', setProgressSize)

    const cancelEditName = () => {
      if (!userCanInteract) return;
      name.value = tempName.value
      editName.value = false
    }

    const handleNameChange = () => {
      if (!userCanInteract) return;
      const data = ref({ listName: name.value, id: props.subjectInfo.id, color: props.subjectInfo.color })
      listsService.patch('/', data.value).then(() => {
        if (isTest.value) reloadLists?.()
        else initExerciseListPage?.()

        $q.notify({
          textColor: 'white',
          message: 'Nome alterado com sucesso',
          color: 'green',
          position: 'top',
          classes: 'notify',
        })
        editName.value = false
      })
    }

    const btnLabel = () => {
      if (tryFinished === true && !isExpired) return 'Ver respostas'
      else if (totalResponses && !isExpired) return 'Continuar'
      else if (isExpired) return 'Expirado'
      else return 'Iniciar'
    }

    const checkForGoldenClass = () => {
      if (tryFinished === true && !isExpired) return 'golden-border'
      else if (isExpired) return 'expired-border'
      else return ''
    }

    if (props.subjectInfo.listTries) {
      const arraySorted = props.subjectInfo.listTries.map((it) => it).sort((a, b) => a.id - b.id)
      lastTry.value = arraySorted[arraySorted.length - 1]
    }

    const handleClick = async () => {
      if (!userCanInteract) return;

      sessionStorage.setItem('keyDrawer', pathname)
      if (!blockDoubleClick) {
        blockDoubleClick = true
        if (lastTry.value) {
          if (tryFinished === true) {
            blockDoubleClick = false
            if (showFeedback.value || isExpired) window.location.href = `/gabarito/${props.subjectInfo.id}/${lastTry.value.id}`
            else {
              $q.notify({
                textColor: 'white',
                message: 'O professor bloqueou a visualização do gabarito desta lista',
                color: 'yellow-8',
                position: 'top',
                classes: 'notify',
              })
            }
          } else if (isExpired) {
            blockDoubleClick = false
            $q.notify({
              textColor: 'white',
              message: 'Essa lista não foi finalizada, você não poderá ver o gabarito',
              color: 'yellow-8',
              position: 'top',
              classes: 'notify',
            })
          } else if (props.subjectInfo.listTries && lastTry.value) {
            window.location.href = `/responder-lista/${props.subjectInfo.id}/${lastTry.value.id}`
          }
        } else {
          const { data } = await listsService.post('/tries/new-try', { listId: props.subjectInfo.id })
          window.location.href = `/responder-lista/${props.subjectInfo.id}/${data.data.id}`
        }
      }
    }

    watch(
      editName,
      () => {
        setTimeout(() => {
          if (editName.value) focusEditName.value!.focus()
        }, 100)
      },
      { deep: true }
    )
    return {
      currentColor,
      totalResponses,
      btnLabel,
      handleClick,
      editName,
      name,
      isTest,
      progressSize,
      handleNameChange,
      focusEditName,
      cancelEditName,
      tryFinished,
      checkForGoldenClass,
      handleTooltipInfos,
      expirationDate,
      isExpired,
      showFeedback,
      DefaultTheme,
      userCanInteract
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "1f473504": (_ctx.currentColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__