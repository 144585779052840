
import EnemModuleAtom from '@/components/Icons/enemModuleAtomIcon.vue'
import EnemModuleBookClosed from '@/components/Icons/enemModuleBookClosedIcon.vue'
import EnemModuleCalculator from '@/components/Icons/enemModuleCalculatorIcon.vue'
import EnemModuleGlobe from '@/components/Icons/enemModuleGlobeIcon.vue'
import Card from '@/components/Library/Card/index.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CardModule',
  components: {
    Card,
    EnemModuleAtom,
    EnemModuleBookClosed,
    EnemModuleCalculator,
    EnemModuleGlobe,
  },
  props: {
    alt: { type: String, default: '' },
    icon: { type: String, default: '' },
    modelValue: { type: Boolean, default: false },
    title: { type: String, default: '' },
    addHoverToCard: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  setup() {
    return {}
  },
})
