import { AlternativesSelectorModelValueType } from '../components/Application/alternativesSelector/types/AlternativesSelectorModelValueType'
import { AlternativesSetType } from '../components/Application/alternativesSelector/types/AlternativesSet'

export class CreateUpdateAnswerDTO {
  questionId: number
  tryId: number
  alternatives: Array<AlternativesSetType | null>
  isDoubt?: boolean
  discardedAlternatives?: Array<AlternativesSetType | null>

  constructor(model: AlternativesSelectorModelValueType | undefined, tryId: number, isDoubt?: boolean) {
    this.questionId = model?.questionId || 0
    this.tryId = tryId
    this.alternatives = model?.alternatives || []
    this.isDoubt = isDoubt
    this.discardedAlternatives = model?.discardedAlternatives || []
  }
}
