export class ListTagDTO {
  key: ListTagKeyEnum
  value: string

  constructor(key: ListTagKeyEnum, value: string) {
    this.key = key
    this.value = value
  }
}

export enum ListTagKeyEnum {
  AREA = 'area',
  CATEGORY = 'category',
  ALREADY_ANSWERED = 'includeAlreadyAnswered',
  SHUFFLE = 'shuffle',
  CHOSEN_LANGUAGE = 'chosenLanguage',
}
