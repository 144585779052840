import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClearIcon = _resolveComponent("ClearIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex", _ctx.isReports ? 'div-chip-wrapper-reports' : 'div-chip-wrapper'])
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.isReports ? 'span-label-reports' : 'span-label')
    }, _toDisplayString(_ctx.label?.replace('G1 - ', '')), 3),
    _createElementVNode("button", {
      class: "cursor-pointer btn-clear",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('remove', { label: _ctx.label, field: _ctx.field, currentKey: _ctx.currentKey })))
    }, [
      _createVNode(_component_ClearIcon, {
        fill: _ctx.DefaultTheme().colors.gray400
      }, null, 8, ["fill"])
    ])
  ], 2))
}