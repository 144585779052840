import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", {
    ref: "tabRef",
    class: _normalizeClass(["tab-item", { 'tab-item--active': _ctx.handleActivationTab(_ctx.name, _ctx.$parent).actived, 'tab-item--justify': _ctx.handleActivationTab(_ctx.name, _ctx.$parent).justify }]),
    tabindex: "1",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleTabChange(_ctx.name))),
    onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleTabChange(_ctx.name)))
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["tab-item-content", { 'tab-item-content--active': _ctx.handleActivationTab(_ctx.name, _ctx.$parent).actived }])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ], 34))
}