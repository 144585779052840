import { getSubdomainFromLocalStorage } from "@/utils/subdomain"

export class ForgotPasswordDTO {
  email: string | undefined
  subdomain?: string

  constructor(email: string | undefined, subdomain?: string) {
    this.email = email
    this.subdomain = getSubdomainFromLocalStorage() ?? subdomain
  }
}
