
import InstitutionPlans from '@/helpers/InstitutionPlans';
import getUserInfos from '@/helpers/UserInfos';
import lockedPaths from '@/utils/lockedPaths';
import { defineComponent, onBeforeMount, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import PazzeiModalDoodle from '../GenericModals/PazzeiModalDoodle/PazzeiModalDoodle.vue';

export default defineComponent({
  name: 'NoAccess',
  components: { PazzeiModalDoodle },
  setup() {
    const displayModal = ref(false);
    const route = useRoute();
    const router = useRouter();
    const currentPath = route.path;
    const context = ref({
      isDoodleModal: true,
      doodle: require('@/assets/img/noAccess.svg')
    });
    onBeforeMount(async () => {
      const data = await getUserInfos();
      const roleCode = data.roleCode;
      const institutionPlan = await InstitutionPlans.getPlan();
      
      if (
        lockedPaths.includes(currentPath)
        && roleCode === 'pazzei:no-credits'
        && !institutionPlan
      ) {
        displayModal.value = true;
      }
    })

    const confirm = () => {
      router.push('/checkout')
    }

    const cancel = () => {
      router.push('/')
    }

    const close = () => {
      router.push('/')
    }

    return {
      displayModal,
      context,
      confirm,
      cancel,
      close,
    }
  }
})
