export class PaymentProfileDTO {
  cardholderName: string
  expirationDate: string
  number: string
  cvv: string
  autoRenew?: boolean

  constructor(cardholderName: string, expirationDate: string, number: string, cvv: string) {
    this.cardholderName = cardholderName
    this.expirationDate = expirationDate
    this.number = number
    this.cvv = cvv
  }
}
