
import { ApexOptions } from 'apexcharts'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { computed, defineComponent, onMounted, onUnmounted, provide, ref } from 'vue'
import { TagDTO } from '../../dtos/ResponseDTOs/TagDTO'
import { SaveTagsDTO } from '../../dtos/SaveTagsDTO'
import reportsService from '../../services/reports'
import usersService from '../../services/users'
import setLineGraphicInfos from '../../utils/graphics/line'
import PazzeiBtn from './PazzeiBtn.vue'
import PazzeiInput from './PazzeiInput.vue'

export default defineComponent({
  name: 'PazzeiGoal',
  emits: ['editGoal'],
  components: { PazzeiBtn, PazzeiInput },
  setup(_, { emit }) {
    const controller = new AbortController()
    const informedGoal = ref('')
    const isLoading = ref(false)
    const hitGoal = ref(0)
    const addGoal = ref(false)
    const cache = ref<string>('')
    const tags = ref(Array<TagDTO>())
    const namedLink = ref<string>('')
    const allTags = ref(Array<TagDTO>())
    const $q = useQuasar()
    const goalBtn = true
    provide('goalBtn', goalBtn)

    const saveGoal = () => {
      isLoading.value = true
      const data = new TagDTO('goal', String(informedGoal.value))
      tags.value.push(data)

      allTags.value.forEach((it) => {
        if (it.key !== 'goal') tags.value.push({ key: it.key, value: it.value })
      })

      const parsedData = new SaveTagsDTO(tags.value)
      usersService
        .put('/users-tags', parsedData)
        .then(() => {
          isLoading.value = false
          $q.notify({
            textColor: 'grey-1',
            message: 'Meta salva com sucesso',
            color: 'green',
            position: 'top',
            classes: 'notify',
          })
          namedLink.value = 'Editar meta'
          addGoal.value = false
        })
        .catch(() => (isLoading.value = false))
    }

    const handleAddGoal = (clickAt: string) => {
      if (clickAt === 'link') cache.value = informedGoal.value
      else informedGoal.value = cache.value
      addGoal.value = !addGoal.value
      emit('editGoal')
    }

    const lineChart = ref<ApexOptions>({ series: [] })
    const goal = computed(() => {
      if (!Number(informedGoal.value)) return 0
      if (Number(informedGoal.value) < hitGoal.value) return 1
      return hitGoal.value / Number(informedGoal.value)
    })

    onMounted(() => {
      reportsService.get('/goals', { signal: controller.signal }).then((res) => {
        lineChart.value = setLineGraphicInfos(res.data.data.countByDay)
        hitGoal.value = res.data.data.thisWeek
      })

      usersService.get('/users-tags').then((res) => {
        allTags.value.push(...res.data.data)
        allTags.value.map((it) => {
          if (it.key === 'goal') informedGoal.value = it.value
        })
        Number(informedGoal.value) ? (namedLink.value = 'Editar meta') : (namedLink.value = 'Criar meta')
      })
    })

    onUnmounted(() => controller.abort())

    return { informedGoal, hitGoal, handleAddGoal, addGoal, saveGoal, namedLink, lineChart, isLoading, goal }
  },
})
