
import PazzeiButton from '@/components/Generics/Redesign/PazzeiButton.vue';
import PazzeiModal from '@/components/Generics/Redesign/PazzeiModal/PazzeiModal.vue';
import PrintListIcon from '@/components/Icons/default/redesign/sharedList/PrintListIcon.vue';
import { QuestionFileDTO } from '@/dtos/QuestionFIleDTO';
import { QuestionDTO } from '@/dtos/ResponseDTOs/QuestionDTO';
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO';
import questionsService from '@/services/question';
import DefaultTheme from '@/theme/defaultTheme';
import { QuestionElementFileType } from '@/types/QuestionElementFileType';
import GenerateDoc from '@/views/GenerateDoc/GenerateDoc.vue';
import html2pdf from 'html2pdf.js';
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { createApp, defineComponent, onMounted, ref, watch } from 'vue';

export default defineComponent({
  name: 'ModalPrintList',
  emits: ['close', 'updateSubjects', 'print-list', 'close-modal-print-list'],
  props: {
    list: { type: Object }
  },
  components: {
    PazzeiModal,
    PazzeiButton,
  },
  setup(props, {emit}) {
    const $q = useQuasar();
    const isLoading = ref<boolean>(false);
    const isDisabled = ref<boolean>(false);
    const totalQuestionsFetchTries = ref(0);

    const context = {
      name: 'printList',
      icon: PrintListIcon,
      iconProps: {stroke: DefaultTheme().colors.warning700, size: 48 }
    }

    const showModal = ref(true);

    const handleModalClose = () => {
      showModal.value = false;
    }

    const questionsInfos = ref(Array<{ questionId: number, type: string | undefined}>());
    const questions = ref<Array<{ question: QuestionElementFileType, type: string | undefined }>>([]);

    onMounted(async () => {
      questionsInfos.value = await getQuestionsExternalIds();
    })

    const getQuestionsExternalIds = async () => {
      try {
        const { data } = await questionsService.get<ResponseDTO<QuestionDTO[]>>(
          `/${props.list?.id}`,
        );

        return data.data.map((i) => { 
          return { 
            questionId: i.externalId,
            type: i.tags.find(item => item.key === 'type')?.value
          }
        });
      } catch (e) {
        return [];
      }
    }

    const questionsFetched = ref(false);
    const isFetching = ref(false);

    const getQuestions = async () => {
      if (isFetching.value) {
        return;
      }

      try {
        
        totalQuestionsFetchTries.value++;
        isFetching.value = true;
        isDisabled.value = true;
        isLoading.value = true;

        const data = await questionsService.post<ResponseDTO<QuestionFileDTO[]>>(
          'superpro-questions-files', {
            questions: questionsInfos.value
          }
        );
        
        if (!data.data.data.length && totalQuestionsFetchTries.value <= 3) {
          isFetching.value = false;
          await getQuestions();
        }
        
        data.data.data.forEach((item: QuestionFileDTO) => {
          questions.value.push({
            question: item.question,
            type: item.type,
          });

          questionsFetched.value = true;
        });

      } catch (e) {
        isLoading.value = false;
      }
    }

    watch(questionsFetched, async (newValue) => {
      if (newValue) {
        if (!questions.value.length && totalQuestionsFetchTries.value > 3) {
          isLoading.value = false;
          totalQuestionsFetchTries.value++;
          isFetching.value = false;
          isDisabled.value = false;
          emit('close-modal-print-list');

          $q.notify({
            textColor: 'grey-1',
            message: 'Não foi possível buscar as questões para impressão.',
            color: 'red',
            position: 'top',
            classes: 'notify',
          })
        }

        await exportPdf();
      }
    });

    const exportPdf = async () => {
      if(!questionsInfos.value.length) {
        return;
      }

      const app = createApp(GenerateDoc, {
        listInfos: props.list,
        questions: questions.value,
      });

      const vm = app.mount(document.createElement('div'));
      const el = vm.$el;

      const options = {
        filename: props?.list?.name ? `${props.list.name + '_' + new Date().getTime()}.pdf` : `${'lista_do_pazzei_' + new Date().getTime()}.pdf`,
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        image: { type: 'jpeg', quality: 0.98 },
      }

      setTimeout(() => {
        isLoading.value = false;
        isDisabled.value = false;
        isFetching.value = false;
        html2pdf().set(options).from(el).save();
        emit('close-modal-print-list');
      }, 10000);
    }

    return {
      context,
      showModal,
      handleModalClose,
      getQuestions,
      isLoading,
      isDisabled,
      questions,
    }
  }
})
