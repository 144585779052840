import { helpers, minLength, required } from '@vuelidate/validators'

const messageRequired = 'Este campo é obrigatório'

const createListRules = {
  listName: { required: helpers.withMessage(messageRequired, required), minLength: helpers.withMessage('O nome da lista deve ter no mínimo 3 caracteres', minLength(3)) },
  color: {
    required: helpers.withMessage(messageRequired, required),
  },
}

export default createListRules
