
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO';
import { UserDTO } from '@/dtos/ResponseDTOs/UserDTO';
import usersService from '@/services/users';
import { defineComponent, onMounted, ref } from 'vue';
import MainLayoutWrapper from '../Layouts/MainLayoutWrapper.vue';
import InstitutionConfigHelper from '@/helpers/InstitutionConfigHelper';

export default defineComponent({
  name: 'PazzeiDashboard',
  components: {
    MainLayoutWrapper,
  },
  setup() {
    const dashboardUrl = ref<string>('');
    const institutionId = ref<number | undefined>(0);

    const getUserInstitutionId = async () => {
      const responseMe = await usersService.get<ResponseDTO<UserDTO>>('/me')
      institutionId.value = responseMe.data.data.institutionId;
    }

    const getDashboardUrl = async () => {
      try {
        const config = await InstitutionConfigHelper.getInstitutionsConfig();
        if (config?.dashboardUrl) {
          dashboardUrl.value = `${config.dashboardUrl}&institution_id=${config.institution_id}`;
          return
        }
        dashboardUrl.value = `${process.env.VUE_APP_PAZZEI_DASHBOARD_URL}&institution_id=${institutionId.value}`;
      } catch (error) {
        dashboardUrl.value = `${process.env.VUE_APP_PAZZEI_DASHBOARD_URL}&institution_id=${institutionId.value}`;
        console.error('Error fetching dashboard URL:', error);
      }
    };
    
    onMounted(async () => {
      await getUserInstitutionId();
      await getDashboardUrl();
    })

    return {
      dashboardUrl
    }
  }
})
