export class QuestionTagsDTO {
  types: Array<string>
  subjects: Array<string>
  years: Array<string>
  levels: Array<string>
  sources: Array<string>

  constructor(questionTagsDTO?: QuestionTagsDTO) {
    this.types = questionTagsDTO ? [...questionTagsDTO.types] : new Array<string>()
    this.subjects = questionTagsDTO ? [...questionTagsDTO.subjects] : new Array<string>()
    this.years = questionTagsDTO ? [...questionTagsDTO.years] : new Array<string>()
    this.levels = questionTagsDTO ? [...questionTagsDTO.levels] : new Array<string>()
    this.sources = questionTagsDTO ? [...questionTagsDTO.sources] : new Array<string>()
  }
}
