
import PazzeiInput from '@/components/Generics/PazzeiInput.vue'
import CooliconIcon from '@/components/Icons/default/CooliconIcon.vue'
import QuestionWrapper from '@/components/Layouts/QuestionWrapper.vue'
import listsService from '@/services/list'
import DefaultTheme from '@/theme/defaultTheme'
import axios from 'axios'
import { defineComponent, onMounted, onUnmounted, provide, ref } from 'vue'

export default defineComponent({
  name: 'ModalViewAnsweredQuestion',
  emits: ['close'],
  components: { QuestionWrapper, PazzeiInput, CooliconIcon },
  props: {
    listId: { type: Number, default: 0 },
    listName: { type: String, default: 'Nome da lista' },
    indexQuestion: { type: Number, default: 1 },
  },
  setup(props) {
    const controller = new AbortController()
    const justification = ref<string>()
    const allQuestionsOnList = ref<Array<{ questionId: number; order: number }>>([])
    const tempQuestionId = ref(0)
    const tempIndexQuestion = ref(props.indexQuestion)
    const nextQuestion = ref<number | undefined>()
    const previousQuestion = ref<number | undefined>()
    const flagNextQuestion = ref(0)
    const questionHaveIndex = ref(0)
    const justificationUrlAws = ref<string>('')
    const questionAws = ref('')
    const hideFeedback = ref(false)
    const topicTag = ref<Array<{ key: string; value: string }>>([])
    const questionNumber = ref<number>(1)
    const questionNumberInput = ref<string>(questionNumber.value.toString())
    const skippingQuestions = ref(false)

    const showFeedback = (state: boolean) => (hideFeedback.value = state)

    const updateQuestionAws = (awsKey: string) => {
      questionAws.value = awsKey

      justificationUrlAws.value = process.env.VUE_APP_AWS_QUESTIONS_JUSTIFICATIONS_BASE_URL + questionAws?.value

      axios.get(justificationUrlAws.value, { signal: controller.signal }).then((res) => {
        justification.value = res.data
      })
    }

    provide('questionAwsKey', { questionAws, updateQuestionAws })

    const handleNextQuestion = () => {
      hideFeedback.value = false
      questionHaveIndex.value = allQuestionsOnList.value.indexOf(allQuestionsOnList.value.find((it) => it.questionId === tempQuestionId.value)!)
      nextQuestion.value = allQuestionsOnList.value[++questionHaveIndex.value].questionId
      tempQuestionId.value = nextQuestion.value
      tempIndexQuestion.value!++
      questionNumberInput.value = tempIndexQuestion.value!.toString()
      flagNextQuestion.value++
    }

    const handlePreviousQuestion = () => {
      hideFeedback.value = false
      questionHaveIndex.value = allQuestionsOnList.value.indexOf(allQuestionsOnList.value.find((it) => it.questionId === tempQuestionId.value)!)
      previousQuestion.value = allQuestionsOnList.value[--questionHaveIndex.value].questionId
      nextQuestion.value = allQuestionsOnList.value[++questionHaveIndex.value].questionId
      tempQuestionId.value = previousQuestion.value
      tempIndexQuestion.value!--
      questionNumberInput.value = tempIndexQuestion.value!.toString()
      flagNextQuestion.value--
    }

    const onEnterKey = () => {
      if (Number(questionNumberInput.value) >= allQuestionsOnList.value.length) questionNumberInput.value = allQuestionsOnList.value.length.toString()
      else if (Number(questionNumberInput.value) < 1) questionNumberInput.value = '1'

      hideFeedback.value = false
      tempQuestionId.value = allQuestionsOnList.value[Number(questionNumberInput.value) - 1].questionId

      questionHaveIndex.value = allQuestionsOnList.value.indexOf(allQuestionsOnList.value.find((it) => it.questionId === tempQuestionId.value)!)
      tempIndexQuestion.value = Number(questionNumberInput.value)
      previousQuestion.value = allQuestionsOnList.value[questionHaveIndex.value - 1] ? allQuestionsOnList.value[questionHaveIndex.value - 1].questionId : -1
      nextQuestion.value = allQuestionsOnList.value[questionHaveIndex.value + 1] ? allQuestionsOnList.value[questionHaveIndex.value + 1].questionId : -1
      flagNextQuestion.value--
    }

    const getTags = (tags: Array<{ key: string; value: string }>) => (topicTag.value = tags)

    onMounted(async () => {
      await listsService.get('/id', { params: { listId: props.listId } }).then((res) => {
        allQuestionsOnList.value = res.data.data.listHasQuestions

        if (allQuestionsOnList.value.length) {
          tempQuestionId.value = allQuestionsOnList.value[0].questionId

          questionHaveIndex.value = allQuestionsOnList.value.indexOf(allQuestionsOnList.value.find((it) => it.questionId === tempQuestionId.value)!)
        }
      })
    })

    onUnmounted(() => controller.abort())
    return {
      hideFeedback,
      showFeedback,
      justification,
      questionNumber,
      questionNumberInput,
      skippingQuestions,
      onEnterKey,
      tempQuestionId,
      handleNextQuestion,
      flagNextQuestion,
      topicTag,
      tempIndexQuestion,
      getTags,
      allQuestionsOnList,
      handlePreviousQuestion,
      nextQuestion,
      DefaultTheme,
    }
  },
})
