import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 19 18",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M17 7.58824V5.51765C17 3.93632 17 3.14566 16.6923 2.54167C16.4216 2.01039 15.9896 1.57845 15.4583 1.30775C14.8543 1 14.0637 1 12.4824 1H5.51765C3.93632 1 3.14566 1 2.54167 1.30775C2.01039 1.57845 1.57845 2.01039 1.30775 2.54167C1 3.14566 1 3.93632 1 5.51765V8.71765C1 10.299 1 11.0896 1.30775 11.6936C1.57845 12.2249 2.01039 12.6568 2.54167 12.9275C3.14566 13.2353 3.93632 13.2353 5.51765 13.2353H10.4118M1.33882 2.48706L7.11856 7.02426C7.79004 7.56144 8.12577 7.83003 8.49811 7.93303C8.82653 8.02389 9.17347 8.02389 9.5019 7.93303C9.87423 7.83003 10.21 7.56144 10.8814 7.02426L16.6612 2.48706M16.061 17V11.3529M17.9412 13.2353L16.0588 11.3529L14.1765 13.2355",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}