import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 16 21",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M7.14286 13.3571H8.57143V16.2143H7.14286V13.3571ZM10.7143 11.9286H12.1429V16.2143H10.7143V11.9286ZM3.57143 9.07143H5V16.2143H3.57143V9.07143Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M14.2857 2.64286H12.1429V1.92857C12.1429 1.54969 11.9923 1.18633 11.7244 0.918419C11.4565 0.65051 11.0932 0.5 10.7143 0.5H5C4.62112 0.5 4.25776 0.65051 3.98985 0.918419C3.72194 1.18633 3.57143 1.54969 3.57143 1.92857V2.64286H1.42857C1.04969 2.64286 0.686328 2.79337 0.418419 3.06128C0.15051 3.32919 0 3.69255 0 4.07143V19.0714C0 19.4503 0.15051 19.8137 0.418419 20.0816C0.686328 20.3495 1.04969 20.5 1.42857 20.5H14.2857C14.6646 20.5 15.028 20.3495 15.2959 20.0816C15.5638 19.8137 15.7143 19.4503 15.7143 19.0714V4.07143C15.7143 3.69255 15.5638 3.32919 15.2959 3.06128C15.028 2.79337 14.6646 2.64286 14.2857 2.64286ZM5 1.92857H10.7143V4.78571H5V1.92857ZM14.2857 19.0714H1.42857V4.07143H3.57143V6.21429H12.1429V4.07143H14.2857V19.0714Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_3)
  ], 8, _hoisted_1))
}