
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MainLayoutSubWrapper',
  props: {
    answerList: { type: Boolean, default: false },
    page: { type: String, default: '' },
  },
})
