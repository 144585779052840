import { QuestionTagsDTO } from './../../../../dtos/CreateListPage/QuestionTagsDTO'

export class ListSummaryInfos {
  listName = ''
  color = ''
  summary: Record<string, Infos> = {}
}

class Infos {
  filters = new QuestionTagsDTO()
  total = 0
}
