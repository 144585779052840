export const checkImageAvailability = (src: string, timeout = 5000): Promise<boolean> => {
  return new Promise((resolve) => {
    const img = new Image()

    const onLoad = () => {
      img.onload = null
      img.onerror = null
      clearTimeout(timer)
      resolve(true)
    }

    const onError = () => {
      img.onload = null
      img.onerror = null
      clearTimeout(timer)
      resolve(false)
    }

    const timer = setTimeout(() => {
      img.onload = null
      img.onerror = null
      resolve(false)
    }, timeout)

    img.onload = onLoad
    img.onerror = onError
    img.src = src
  })
}
