import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69d48c56"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "colored-icon-container" }
const _hoisted_2 = { class: "colored-icon-container-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EnemModuleBookClosed = _resolveComponent("EnemModuleBookClosed")!
  const _component_EnemModuleGlobe = _resolveComponent("EnemModuleGlobe")!
  const _component_EnemModuleAtom = _resolveComponent("EnemModuleAtom")!
  const _component_EnemModuleCalculator = _resolveComponent("EnemModuleCalculator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.icon === 'bookClosed')
        ? (_openBlock(), _createBlock(_component_EnemModuleBookClosed, {
            key: 0,
            stroke: "var(--pink-color)",
            size: 30
          }))
        : (_ctx.icon === 'globe')
          ? (_openBlock(), _createBlock(_component_EnemModuleGlobe, {
              key: 1,
              stroke: "var(--pink-color)",
              size: 30
            }))
          : (_ctx.icon === 'atom')
            ? (_openBlock(), _createBlock(_component_EnemModuleAtom, {
                key: 2,
                stroke: "var(--pink-color)",
                size: 30
              }))
            : (_ctx.icon === 'calculator')
              ? (_openBlock(), _createBlock(_component_EnemModuleCalculator, {
                  key: 3,
                  stroke: "var(--pink-color)",
                  size: 30
                }))
              : _createCommentVNode("", true)
    ])
  ]))
}