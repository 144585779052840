
import { defineComponent, ref } from 'vue';
import CloseIcon from '../Icons/default/CloseIcon.vue';

const __default__ = defineComponent({
  name: 'PazzeiModal',
  components: { CloseIcon },
  props: {
    forgotPwdIcon: { type: Boolean },
    congrats: { type: Boolean },
    flagModalViewAnsweredQuestion: { type: Boolean },
    questions: { type: Boolean },
    noImg: { type: Boolean },
    createList: { type: Boolean },
    payment: { type: Boolean },
    terms: { type: Boolean },
    small: { type: Boolean },
    noImage: { type: Boolean },
    responsivenessWidth: { type: String, default: '90%' },
    responsivenessHeight: { type: String },
  },
  setup(_, context) {
    const page = ref('page-0')

    const close = () => context.emit('close')

    return {
      page,
      close,
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "ee52933a": (_ctx.responsivenessHeight),
  "ab2f1dec": (_ctx.responsivenessWidth)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__