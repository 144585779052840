
import EnemTestSummary from '@/components/Application/enemModulePage/EnemTestSummary.vue'
import EnemModule from '@/components/Application/enemModulePage/enemModule/EnemModule.vue'
import { OnSelectedAreaType } from '@/components/Application/enemModulePage/enemModule/types'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutSubWrapper from '@/components/Layouts/MainLayoutSubWrapper.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import TrialCard from '@/components/Trial/TrialCard.vue'
import { defineComponent, ref } from 'vue'
import authService from '@/services/authService'

const NO_CREDITS_ROLE = 'pazzei:no-credits';

export default defineComponent({
  name: 'ViewEnemModule',
  components: {
    EnemModule,
    EnemTestSummary,
    GenericLayout,
    MainLayoutSubWrapper,
    MainLayoutWrapper,
    TrialCard
  },
  setup() {
    const areas = ref<OnSelectedAreaType>()
    const isSummary = ref(false)
    const largeAreasCache = ref<OnSelectedAreaType>()

    const onAreasSelected = (largeAreas: OnSelectedAreaType) => {
      isSummary.value = largeAreas.areas.length > 0
      areas.value = largeAreas
    }

    const permissions = authService.getPermissions();
    const roleCode = permissions?.roleCode ?? undefined;
    const isForbidden = roleCode ? Boolean(roleCode.includes(NO_CREDITS_ROLE)) : true;

    return { areas, isSummary, largeAreasCache, onAreasSelected, isForbidden }
  },
})
