
import MissingFieldsModal from '@/components/Checkout/MissingFieldsModal.vue'
import PaymentPixModal from '@/components/Checkout/PaymentPixSubscription.vue'
import PixContentCard from '@/components/Checkout/PixContentCard.vue'
import PlanBenefitsInfo from '@/components/Checkout/PlanBenefitsInfo.vue'
import SubscriptionRequestCard from '@/components/Checkout/SubscriptionRequestCard.vue'
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiInput from '@/components/Generics/PazzeiInput.vue'
import ClearIcon from '@/components/Icons/default/ClearIcon.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutSubWrapper from '@/components/Layouts/MainLayoutSubWrapper.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import Collapse from '@/components/Library/Collapse/index.vue'
import Input from '@/components/Library/Input/index.vue'
import { CheckoutDTO } from '@/dtos/CheckoutDTO'
import { PaymentsPlansDTO } from '@/dtos/PaymentsPlansDTO'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import { UserDTO } from '@/dtos/ResponseDTOs/UserDTO'
import getStates, { ApiDataStates, getDistricts } from '@/services/apis/ibgeStates'
import { AddressTypeReturn, getAddressByCep } from '@/services/apis/viaCep'
import paymentsService from '@/services/payment'
import DefaultTheme from '@/theme/defaultTheme'
import { BillingInfos, BillingInfosData } from '@/types/BillingInfosType'
import { ResponseCouponType } from '@/types/ResponseCouponType'
import addressesRules from '@/utils/vuelidateRules/addressesRules'
import purchaseRules from '@/utils/vuelidateRules/purchaseRules'
import { useVuelidate } from '@vuelidate/core'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { computed, defineComponent, inject, onMounted, onUnmounted, provide, Ref, ref, watch, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import PazzeiSimpleSelect from '../components/Generics/PazzeiSimpleSelect.vue'
import authenticationService from '../services/authentication'
import authService from '../services/authService'
import usersService from '../services/users'

export default defineComponent({
  name: 'CheckoutPage',
  components: {
    GenericLayout,
    MainLayoutSubWrapper,
    MainLayoutWrapper,
    PazzeiInput,
    PazzeiBtn,
    PazzeiSimpleSelect,
    MissingFieldsModal,
    Input,
    Collapse,
    PixContentCard,
    PaymentPixModal,
    PlanBenefitsInfo,
    SubscriptionRequestCard,
    ClearIcon
  },
  setup() {
    const controller = new AbortController()
    const $q = useQuasar()
    const plan = ref(false)
    const autoRenew = ref(true)
    const paymentMethodSelected = ref('')
    const planValue = ref({
      semester: 119.4,
      monthly: 29.9,
    })

    let originalSemesterValue = planValue.value.semester
    let originalMonthlyValue = planValue.value.monthly
    const installmentsOptions = ref<Array<string>>([])
    const isMissingFieldsModalOpen = ref(false)
    const formData = ref({
      name: '',
      document: '',
      installments: '',
      cardNumber: '',
      expirationMonth: '',
      expirationYear: '',
      expirationDate: '',
      cvv: '',
    })

    const formDataAddress = ref({
      country: 'BR',
      zipcode: '',
      state: '',
      city: '',
      neighborhood: '',
      street: '',
      houseNumber: '',
    })
    const update = ref(0)
    const heightCpfField = ref('3.2375em')
    const rulesAddress = computed(() => addressesRules)
    const rules = computed(() => purchaseRules)
    const stateOptions = ref<Array<string>>([])
    const isLoading = ref(false)
    const citiesOptions = ref<Array<string>>([''])
    const statesInfos = ref<Array<ApiDataStates>>([])
    const planOptions = ref<{ planId: number; installments: number }>({
      planId: 2,
      installments: 0,
    })
    const allPlans = ref<Array<PaymentsPlansDTO>>([])
    const userLocationState = ref<{ state: Array<string> }>({
      state: [],
    })
    const viaCep = ref<AddressTypeReturn>({
      cep: '',
      logradouro: '',
      complemento: '',
      bairro: '',
      localidade: '',
      uf: '',
      ibge: '',
      gia: '',
      ddd: '',
      siafi: '',
    })
    const userInfosAux = ref({
      city: [''],
      expirationMonth: [''],
      expirationYear: [''],
      installmentsQtd: [`1x de R$${planValue.value.semester.toFixed(2)}`],
    })
    const expiredMonth = ref({
      message: '',
      expired: false,
    })

    const zipcode = ref('')
    const ufAux = ref('')
    const couponValue = ref('')
    const discountValue = ref(0)
    const validity = ref(-1)
    const discountType = ref('')
    const getUser = inject<Ref<UserDTO>>('userDTO')
    const tempUserDTO = ref<UserDTO | undefined>()
    const forceUpdate = ref(0)
    const router = useRouter()
    const innerWidth = ref(window.innerWidth)
    const applyCoupon = ref(false)
    const changePagePayment = ref(false)
    const isState = ref(false)
    const hasValidity = ref(false)
    const couponValueCheckout = ref('')
    const validateForm$ = useVuelidate(rules, formData)
    const validateFormAddress$ = useVuelidate(rulesAddress, formDataAddress)
    const awaitPendingRequest = ref(true)
    const billingId = ref(-1)
    const couponTooltip = ref('')
    const billingResponse = ref<BillingInfos>({
      id: -1,
      plan: { description: '' },
      planId: -1,
      holderName: '',
      paymentMethod: '',
      autoRenew: false,
      createdAt: '',
      discount: '',
      price: -1,
      fullPrice: '',
      hasCoupon: false,
      status: '',
      isReversed: false,
      cardNumber: '',
      installments: -1,
      hasValidity: false,
      validity: -1,
      _qrCodeUrl: '',
      _pixCopyAndPaste: '',
    })
    let count = ref(0)
    let interval = 0
    let letIntervalBe = 2000
    const hasConfirmedLegalAge = ref(false)
    const hasCpf = ref(false)
    const hasMaxDiscount = ref(false)

    const setPix = computed(() => {
      return paymentMethodSelected.value === 'pix' ? true : false
    })

    const setCreditCard = computed(() => {
      return paymentMethodSelected.value === 'credit_card' ? true : false
    })

    const receiveClickGenerateCode = async (value: boolean) => {
      if (value) {
        onPurchase()
      }
    }

    const logout = () => {
      authService.logoutUser()
    }

    let cancelStatus = false
    const changePayment = () => {
      cancelStatus = true
      changePagePayment.value = !changePagePayment.value
    }

    const setNewTab = () => {
      innerWidth.value = window.innerWidth
    }
    window.addEventListener('resize', setNewTab)

    provide('userDTO', tempUserDTO)

    const priceByInstallments = computed(() => planValue.value.semester / parseInt(userInfosAux.value.installmentsQtd[0]))

    const applyAndNotifyCoupon = async () => {
      try {
        const { data: infos } = await paymentsService.get<ResponseCouponType>('/coupons', { params: { code: couponValue.value } })

        const discountPercentage = Number(infos.data.value) || 0
        const discountThreshold = infos.data.maxDiscount
        discountValue.value = discountPercentage
        hasMaxDiscount.value = !!discountThreshold

        const semesterOriginalDiscount = discountPercentage * originalSemesterValue
        const semesterFinalDiscount = discountThreshold ? Math.min(semesterOriginalDiscount, discountThreshold) : semesterOriginalDiscount

        const monthlyOriginalDiscount = discountPercentage * originalMonthlyValue
        const monthlyFinalDiscount = discountThreshold ? Math.min(monthlyOriginalDiscount, discountThreshold) : monthlyOriginalDiscount
        discountType.value = infos.data.discountType ?? 'Absolute'
        validity.value = infos.data.validity ?? 0
        hasValidity.value = infos.data.hasValidity ?? false

        if (infos.statusCode === 200) {
          couponValueCheckout.value = couponValue.value
          applyCoupon.value = true
          $q.notify({
            textColor: 'grey-1',
            message: 'Cupom aplicado com sucesso',
            color: 'green',
            position: 'top',
            classes: 'notify',
          })
          applyDiscount(monthlyFinalDiscount, semesterFinalDiscount)
          const couponTooltipValue = plan.value ? semesterFinalDiscount : monthlyFinalDiscount
          const couponTooltipPlanOriginalDiscount = plan.value ? semesterOriginalDiscount : monthlyOriginalDiscount
          const couponTooltipPlanFinalDiscount = plan.value ? semesterFinalDiscount : monthlyFinalDiscount

          couponTooltip.value =
            discountType.value === 'Percentage' && couponTooltipPlanOriginalDiscount <= couponTooltipPlanFinalDiscount
              ? discountValue.value * 100 + '%'
              : 'R$' + couponTooltipValue.toFixed(2).replace('.', ',')
          couponValue.value = ''
        }
      } catch (error) {
        console.error(error)
      }
    }

    const applyDiscount = (monthlyDiscount: number, semesterDiscount: number) => {
      planValue.value.semester = Math.max(originalSemesterValue - semesterDiscount, 0)
      planValue.value.monthly = Math.max(originalMonthlyValue - monthlyDiscount, 0)
    }

    onMounted(async () => {
      billingStatus(true)

      if (innerWidth.value <= 768) {
        heightCpfField.value = '2.3em'
      }

      const { data: user } = await usersService.get<ResponseDTO<UserDTO>>('/me')
      hasConfirmedLegalAge.value = user.data.isLegalAge || (!user.data.isLegalAge && 'legalRepresentative' in user.data && 'emailLegalRepresentative' in user.data)
      hasCpf.value = 'cpf' in user.data

      if (!hasConfirmedLegalAge.value || !hasCpf.value) isMissingFieldsModalOpen.value = true

      const { data, statesUf } = await getStates(controller)
      stateOptions.value = statesUf
      statesInfos.value = data

      const { data: plans } = await paymentsService.get<ResponseDTO<Array<PaymentsPlansDTO>>>('/plans/all')
      allPlans.value = plans.data

      planOptions.value.planId = plans.data.find((item) => item.description === 'Essencial mensal')?.id || 0
      planValue.value.semester = plans.data.find((item) => item.description === 'Essencial semestral')?.fullPrice || 0
      planValue.value.monthly = plans.data.find((item) => item.description === 'Essencial mensal')?.fullPrice || 0
    })

    watch(
      userLocationState,
      async () => {
        const uf = statesInfos.value.filter((item) => item.sigla === userLocationState.value.state[0])

        if (ufAux.value !== uf[0]?.sigla) {
          userInfosAux.value.city = ['']
          zipcode.value = ''
          formDataAddress.value.street = ''
          formDataAddress.value.neighborhood = ''
          formDataAddress.value.state = uf[0].sigla
          citiesOptions.value = await getDistricts(uf[0].id.toString())
          update.value++
        }
      },
      { deep: true }
    )

    watch(zipcode, async () => {
      formDataAddress.value.zipcode = zipcode.value

      if (formDataAddress.value.zipcode.length >= 9) {
        viaCep.value = await getAddressByCep(formDataAddress.value.zipcode.replace('-', ''))

        formDataAddress.value.street = viaCep.value.logradouro
        formDataAddress.value.neighborhood = viaCep.value.bairro
        userInfosAux.value.city = [viaCep.value.localidade]
        userLocationState.value.state = [viaCep.value.uf]
        ufAux.value = userLocationState.value.state[0]
        isState.value = false
        update.value++
      }
    })

    watch(
      userInfosAux,
      () => {
        formData.value.expirationMonth = userInfosAux.value.expirationMonth[0]
        formData.value.expirationYear = userInfosAux.value.expirationYear[0]
        formDataAddress.value.city = userInfosAux.value.city[0]
      },
      { deep: true }
    )

    const monthArray = Array.from({ length: 12 }, (_, index) => (index < 9 ? '0' + (index + 1) : (index + 1).toString()))
    const currentYear = new Date().getFullYear()
    const yearArray = Array.from({ length: 15 }, (_, index) => (currentYear + index).toString())

    watchEffect(() => {
      const yearTwoDigits = userInfosAux.value.expirationYear[0] ? userInfosAux.value.expirationYear[0].slice(2, 4) : '**'
      const month = formData.value.expirationMonth[0] ? userInfosAux.value.expirationMonth[0] : '**'
      formData.value.expirationDate = month + '/' + yearTwoDigits
    })

    watchEffect(() => {
      const updatedInstallmentsOptions = Array.from({ length: 6 }, (_, index) => {
        const cost = (planValue.value.semester / (index + 1)).toFixed(2)

        return index + 1 + 'x de R$' + cost
      })
      if (installmentsOptions.value.length && installmentsOptions.value.every((it) => updatedInstallmentsOptions.includes(it))) return

      installmentsOptions.value = updatedInstallmentsOptions
      userInfosAux.value.installmentsQtd = [updatedInstallmentsOptions[0]]
      update.value++
    })

    const numberCredLabel = computed(() => formData.value.cardNumber + '**** **** **** ****'.slice(formData.value.cardNumber.length))
    const expirationCredLabel = computed(() => formData.value.expirationDate + '**/**'.slice(formData.value.expirationDate.length))
    const cvvCredLabel = computed(() => formData.value.cvv + '***'.slice(formData.value.cvv.length))

    const cardFlag = computed(() => {
      if (formData.value.cardNumber.charAt(0) === '4') return 'visa'
      else if (formData.value.cardNumber.charAt(0) === '5') return 'mastercard'
      else if (formData.value.cardNumber.charAt(0) === '3') return 'american_express'
      else return ''
    })

    const clearDiscountCoupon = () => {
      couponValueCheckout.value = ''
      discountValue.value = 0
      discountType.value = ''
      applyCoupon.value = false
      planValue.value.semester = originalSemesterValue
      planValue.value.monthly = originalMonthlyValue
    }

    const tryMakePayment = async (billingId: number) => {
      const { data } = await paymentsService.get(`/billings/${billingId}/status`)

      if (data.statusCode === 200 && data.data.status === 'paid') {
        clearInterval(interval)
        const checkToken = localStorage.getItem('refreshToken') ?? sessionStorage.getItem('refreshToken')
        authenticationService
          .post(
            '/token-sign-in',
            {},
            {
              headers: {
                Authorization: `Bearer ${checkToken}`,
              },
            }
          )
          .then((res) => {
            authService.setUserOnLocal(res.data.data.token, res.data.data.refreshToken)
            usersService.get<ResponseDTO<UserDTO>>('/me').then((res) => {
              if (tempUserDTO.value) {
                tempUserDTO.value.roleCode = res.data.data.roleCode
                tempUserDTO.value.registrationCompleted = res.data.data.registrationCompleted
                clearInterval(interval)
                router.push('/bem-vindo')
              }
            })
          })
          .finally(() => {
            isLoading.value = false
          })
      } else if (data.statusCode === 200 && data.data.status === 'rejected') {
        if (!cancelStatus) {
          $q.notify({
            textColor: 'grey-1',
            message: 'Compra recusada, tente novamente',
            color: 'red',
            position: 'top',
            classes: 'notify',
          })
        }
        clearInterval(interval)
        changePagePayment.value = false
        cancelStatus = false
      } else if (data.statusCode === 200 && data.data.status === 'pending') {
        if (letIntervalBe < 10000) letIntervalBe *= 2
        else letIntervalBe = 10000
        count.value++
        clearInterval(interval)
        interval = setInterval(() => tryMakePayment(billingResponse.value.id), letIntervalBe)
      }
    }

    const billingStatus = async (isMounted = false) => {
      try {
        const { data: infos } = await paymentsService.get<BillingInfosData>('/billings/pending')
        if ('data' in infos) {
          billingResponse.value = new BillingInfos(infos.data)
        }

        if (billingResponse.value.status === 'pending') {
          changePagePayment.value = true
          clearInterval(interval)
          tryMakePayment(billingResponse.value.id)
        } else {
          if (!isMounted) {
            isLoading.value = true
            setTimeout(() => {
              tryMakePayment(billingId.value)
            }, 1000)
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        awaitPendingRequest.value = false
        isLoading.value = false
      }
    }

    const onPurchase = async () => {
      formDataAddress.value.state = userLocationState.value.state[0]

      isState.value = true
      if (parseInt(validateForm$.value.expirationYear.$model) === new Date().getFullYear() && parseInt(validateForm$.value.expirationMonth.$model) <= new Date().getMonth()) {
        formData.value.expirationMonth = ''
        expiredMonth.value.expired = true
        expiredMonth.value.message = 'Data expirada'
      } else {
        expiredMonth.value.expired = false
        expiredMonth.value.message = ''
      }

      let result = false
      const isValid1 = await validateForm$.value.$validate()
      const isValid2 = await validateFormAddress$.value.$validate()

      if (paymentMethodSelected.value === 'pix') result = isValid2
      else result = isValid1 && isValid2

      let planId = 0
      let installments = 0

      if (result) {
        try {
          isLoading.value = true
          if (plan.value) {
            planId = allPlans.value.find((item) => item.description === 'Essencial semestral')?.id || 0
            installments = parseInt(userInfosAux.value.installmentsQtd[0])
          } else {
            planId = planOptions.value.planId
            installments = 1
          }

          const checkoutData = new CheckoutDTO(
            formDataAddress.value.street,
            formDataAddress.value.houseNumber,
            formDataAddress.value.zipcode.replaceAll('-', ''),
            formDataAddress.value.city,
            formDataAddress.value.state,
            'BR',
            formDataAddress.value.neighborhood,
            paymentMethodSelected.value,
            planId,
            formData.value.document !== '' ? formData.value.document.replaceAll('.', '').replaceAll('-', '') : undefined,
            formData.value.name ? formData.value.name : undefined,
            setCreditCard.value && formData.value.expirationDate ? formData.value.expirationDate : undefined,
            formData.value.cardNumber ? formData.value.cardNumber.replaceAll(' ', '') : undefined,
            formData.value.cvv ? formData.value.cvv : undefined,
            setCreditCard.value ? installments : undefined,
            couponValueCheckout.value !== '' ? couponValueCheckout.value : undefined,
            setCreditCard.value ? autoRenew.value : undefined
          )

          const { data: res } = await paymentsService.post('/checkout', checkoutData)
          billingId.value = res.data.id
          setTimeout(() => {
            billingStatus()
          }, 500)
        } catch (error) {
          console.error(error)
        } finally {
          isLoading.value = false
        }
      }
    }

    onUnmounted(() => {
      clearInterval(interval)
      if (tempUserDTO.value) getUser!.value = tempUserDTO.value
      controller.abort()
    })

    return {
      update,
      userInfosAux,
      validateFormAddress$,
      validateForm$,
      monthArray,
      installmentsOptions,
      autoRenew,
      stateOptions,
      plan,
      isLoading,
      zipcode,
      forceUpdate,
      citiesOptions,
      planValue,
      expiredMonth,
      userLocationState,
      priceByInstallments,
      isState,
      statesInfos,
      yearArray,
      cardFlag,
      formData,
      formDataAddress,
      numberCredLabel,
      expirationCredLabel,
      onPurchase,
      cvvCredLabel,
      logout,
      isMissingFieldsModalOpen,
      heightCpfField,
      applyAndNotifyCoupon,
      couponValue,
      applyCoupon,
      discountValue,
      discountType,
      changePagePayment,
      receiveClickGenerateCode,
      paymentMethodSelected,
      setPix,
      setCreditCard,
      clearDiscountCoupon,
      changePayment,
      couponValueCheckout,
      billingResponse,
      awaitPendingRequest,
      validity,
      hasValidity,
      hasConfirmedLegalAge,
      hasCpf,
      hasMaxDiscount,
      couponTooltip,
      DefaultTheme,
    }
  },
})
