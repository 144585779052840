import { getSubdomainFromLocalStorage } from "@/utils/subdomain"

export class ConfirmationEmailDTO {
  email: string
  username: string
  subdomain?: string
  

  constructor(email: string, username: string, subdomain?: string) {
    this.email = email
    this.username = username
    this.subdomain = getSubdomainFromLocalStorage() ?? subdomain
  }
}
