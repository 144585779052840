import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3fdb4b93"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "series-container-dot" }
const _hoisted_2 = { class: "serie-count" }
const _hoisted_3 = { class: "container-dot" }
const _hoisted_4 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, "Série " + _toDisplayString(_ctx.serie), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.count, (_, idx) => {
        return (_openBlock(), _createElementBlock("svg", {
          key: idx,
          width: "10",
          height: "10",
          viewBox: "0 0 5 5",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg"
        }, [
          _createElementVNode("circle", {
            cx: "2.5",
            cy: "2.5",
            r: "2",
            fill: _ctx.getColor(idx)
          }, null, 8, _hoisted_4)
        ]))
      }), 128))
    ])
  ]))
}