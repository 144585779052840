import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8a1189a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alternative-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["alternative-container--alternative-option-label", {
        'alternative-option-label__selected': _ctx.isSelected || _ctx.selected,
        'alternative-option-label__correct': _ctx.correct,
        'alternative-option-label__wrong': _ctx.wrong,
        'alternative-option-label__horizontal': _ctx.horizontalMode,
        [`alternative-option-label__${_ctx.questionType}`]: _ctx.questionType,
        'alternative-option-label__not-selectable': _ctx.selected || _ctx.correct || _ctx.wrong ? true : _ctx.notSelectable,
      }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (!(_ctx.notSelectable || _ctx.selected || _ctx.correct || _ctx.wrong) ? _ctx.handleAlternativeSelection(_ctx.id) : null))
    }, _toDisplayString(_ctx.setAlternativeOptionLabels(_ctx.$parent, _ctx.index)), 3),
    _createElementVNode("div", {
      class: _normalizeClass(["alternative-container--label", { label__horizontal: _ctx.horizontalMode }])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.content), 1)
      ], true)
    ], 2)
  ]))
}