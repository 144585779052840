import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61296dac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PazzeiDashboard = _resolveComponent("PazzeiDashboard")!
  const _component_PazzeiDashboardAlunos = _resolveComponent("PazzeiDashboardAlunos")!
  const _component_MainLayoutWrapper = _resolveComponent("MainLayoutWrapper")!
  const _component_GenericLayout = _resolveComponent("GenericLayout")!

  return (_openBlock(), _createBlock(_component_GenericLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_MainLayoutWrapper, { "answer-list": "" }, {
        main: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "tab-item",
                key: index,
                id: `${index}`
              }, [
                _createElementVNode("button", {
                  class: _normalizeClass({ tabSelecionada: _ctx.activeTab === index }),
                  onClick: ($event: any) => (_ctx.selectTab(index))
                }, _toDisplayString(tab), 11, _hoisted_3)
              ], 8, _hoisted_2))
            }), 128))
          ]),
          _createElementVNode("div", null, [
            (_ctx.activeTab === 0)
              ? (_openBlock(), _createBlock(_component_PazzeiDashboard, { key: 0 }))
              : _createCommentVNode("", true),
            (_ctx.activeTab === 1)
              ? (_openBlock(), _createBlock(_component_PazzeiDashboardAlunos, { key: 1 }))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}