
import { VirtualScrollType } from '@/types/VirtualScrollType';
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue';

export default defineComponent({
  emits: ['update:modelValue', 'update:listsOnReports', 'scroll'],
  props: {
    moveUp: { type: Boolean, default: false },
    tableMobile: { type: Boolean, default: false },
    mobileRegisterModal: { type: Boolean, default: false },
    options: {
      type: Array as PropType<Array<string | number>>,
      required: false,
    },
    reportsOptions: {
      type: Array as PropType<Array<{ name: string; id: number }>>,
      default: () => [],
    },
    tabindex: {
      type: Number,
      required: false,
      default: 1,
    },
    readonly: {
      type: Boolean,
    },
    reports: {
      type: Boolean,
    },
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Array as PropType<Array<string | number> | undefined>,
      required: false,
      default: () => [],
    },
    listsOnReports: {
      type: Object as PropType<{ name: string; id: number }>,
      required: false,
      default: () => ({}),
    },
    liteStyle: { type: Boolean, default: false },
    rounded: Boolean,
    placeholder: String,
    error: Boolean,
    hideBottomSpace: Boolean,
    errorMessage: String,
    valueSelectedDefault: Boolean,
    topic: String,
    valuePrimary: Number,
    checkout: Boolean,
    myPlans: { type: Boolean, default: false },
    itemsSelected: {
      type: Array as PropType<Array<string>>,
    },
    roundedSimple: Boolean,
    notSearchInput: Boolean,
    showChoices: Boolean,
    disabled: Boolean,
  },
  setup(props, { emit }) {
    const vModel = ref<Array<string | number> | undefined>(props.modelValue)
    const inputShow = ref(props.modelValue![0])
    const inputSearch = ref<HTMLDivElement | null>(null)

    const selected = ref<string>()
    const filtered = ref<string>('')
    const vReportsLists = ref<{ name: string; id: number }>(props.listsOnReports!)
    const arraySelected = ref<Array<string | number> | undefined>([])
    const key = ref(0)

    const open = ref(false)
    const handleClick = (option: string | number) => {
      vModel.value = [option]
      inputShow.value = option
      emit('update:modelValue', [option])
      open.value = false
    }

    const handleClickWithObject = (name: string, id: number) => {
      vReportsLists.value.name = name
      vReportsLists.value.id = id
      open.value = false
      inputShow.value = name
      emit('update:listsOnReports', { name: name, id: id })
    }
    const mouseDown = () => {
      if (props.disabled) return

      if (open.value !== true) {
        let timeout: number
        timeout = setTimeout(() => {
          open.value = !open.value
          clearTimeout(timeout)
        }, 10)
      }
    }

    const handleScroll = (details: VirtualScrollType) => emit('scroll', details, props.reportsOptions!.length)

    const optionsComputed = computed(() =>
      filtered.value !== ''
        ? props.options!.filter(
            (item) => (item as string).toLowerCase().includes(filtered.value) || (item as string).toUpperCase().includes(filtered.value) || (item as string).includes(filtered.value)
          )
        : props.options
    )

    const reportsComputed = computed(() =>
      filtered.value !== ''
        ? props.reportsOptions!.filter(
            (item) => (item.name as string).toLowerCase().includes(filtered.value) || (item.name as string).toUpperCase().includes(filtered.value) || (item.name as string).includes(filtered.value)
          )
        : props.reportsOptions
    )

    const handleClose = (event: Event) => {
      if (event.target instanceof HTMLInputElement) {
        if (event.target.id !== 'input-search') open.value = false
      } else {
        open.value = false
      }
    }

    watch(
      () => props.modelValue,
      () => {
        inputShow.value = props.modelValue ? String(props.modelValue[0]) : ''
      }
    )

    onMounted(() => {
      document.addEventListener('click', handleClose)

      if (props.reports && props.listsOnReports?.name !== '' && props.listsOnReports?.id !== 0) inputShow.value = props.listsOnReports?.name
    })

    return { inputShow, inputSearch, filtered, selected, handleClick, open, arraySelected, key, mouseDown, optionsComputed, handleClickWithObject, reportsComputed, handleScroll }
  },
})
