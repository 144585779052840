import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 19 5",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("circle", {
      cx: "2.55937",
      cy: "2.42302",
      r: "2.02092",
      fill: _ctx.fill
    }, null, 8, _hoisted_2),
    _createElementVNode("circle", {
      cx: "9.60118",
      cy: "2.42302",
      r: "2.02092",
      fill: _ctx.fill
    }, null, 8, _hoisted_3),
    _createElementVNode("circle", {
      cx: "16.643",
      cy: "2.42302",
      r: "2.02092",
      fill: _ctx.fill
    }, null, 8, _hoisted_4)
  ], 8, _hoisted_1))
}