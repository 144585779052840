
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PazzeiToggle',
  props: {
    isActive: Boolean,
    disabled: { type: Boolean, default: false },
  },
  emits: [
    'mark-as-doubt'
  ],
})
