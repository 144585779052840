export class PutLegalRepresentativeDTO {
  name?: string
  email?: string
  isLegalAge?: boolean

  constructor(name?: string, email?: string, isLegalAge?: boolean) {
    this.name = name !== '' ? name : undefined
    this.email = email !== '' ? email : undefined
    this.isLegalAge = isLegalAge
  }
}
