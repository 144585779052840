
import MountListsIcon from '@/components/Icons/default/MountListsIcon.vue';
import DefaultTheme from '@/theme/defaultTheme';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ButtonComponent',
  props: {
    disabled: { type: Boolean, default: false },
    fullWidth: { type: Boolean, default: false },
    leftIcon: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    rightIcon: { type: Boolean, default: false },
    seeAnswer: { type: Boolean, default: false },
    height: { type: String, default: '42px' },
    label: { type: String, default: '', required: true },
    type: { type: String as PropType<'button' | 'submit' | 'reset' | undefined>, default: 'button' },
    variant: { type: String as PropType<'primary' | 'secondary' | 'dense' | 'primary-bold' | 'secondary-bold' | 'secondary-purple' | 'primary-purple'>, default: 'primary' },
    width: { type: String, default: '160px' },
  },
  components: {
    MountListsIcon
  },
  setup() {
    return {
      DefaultTheme
    }
  },
})
