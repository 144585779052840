
import { SubjectInfoDTO } from '@/dtos/SubjectInfoDTO';
import { QuestionElementFileType } from '@/types/QuestionElementFileType';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'GenerateDoc',
  setup(props, { emit }) {
    const bufferToString = (buffer: any) => {
      const b = new Uint8Array(buffer);
      const decoder = new TextDecoder('utf-8');
      
      return decoder.decode(b);
    };

    const mcqOptions = ['A', 'B', 'C', 'D', 'E'];
    const sumOptions = ['01', '02', '04', '08', '16'];

    const sortFiles = (fileArray: QuestionElementFileType[]): QuestionElementFileType[] => {
      
      return fileArray.filter((file) => { return !['enunciado.html', 'texto.html', 'resolucao.html'].includes(file.filename) }).sort((a, b) => {
        const nameA = a.filename.replace('.html', '');
        const nameB = b.filename.replace('.html', '');

        const regex = /(\D+)(\d*)/;
        const partsA = nameA.match(regex);
        const partsB = nameB.match(regex);

        const alphaA = partsA ? partsA[1] : '';
        const alphaB = partsB ? partsB[1] : '';
        const numA = partsA && partsA[2] ? parseInt(partsA[2], 10) : 0;
        const numB = partsB && partsB[2] ? parseInt(partsB[2], 10) : 0;

        const alphaComparison = alphaA.localeCompare(alphaB);
        if (alphaComparison !== 0) {
          return alphaComparison;
        }

        return numA - numB;
      });
    };

    return {
      bufferToString,
      sortFiles,
      mcqOptions,
      sumOptions,
    }
  },
  props: {
    questions: { type: Array as PropType<Array<{ question: QuestionElementFileType[], type: string | undefined }>>, required: true },
    listInfos: { type: Object as PropType<SubjectInfoDTO>, required: true },
  },
})
