
import Button from '@/components/Library/Button/index.vue'
import Input from '@/components/Library/Input/index.vue'
import Modal from '@/components/Library/Modal/index.vue'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { computed, defineComponent, onMounted, onUpdated, ref, watch } from 'vue'
import { PutLegalRepresentativeDTO } from '../../dtos/PutLegalRepresentativeDTO'
import usersService from '../../services/users'
import { helpers, minLength, required } from '@vuelidate/validators'
import email from '@/utils/vuelidateRules/emailRules'
import useVuelidate from '@vuelidate/core'
import { cpfValidation } from '@/utils/common/cpfValidation'

export default defineComponent({
  name: 'MissingFieldsModal',
  emits: ['update:visibility'],
  props: {
    visibility: { type: Boolean, default: false, required: true },
    hasCpf: { type: Boolean, default: false },
    hasConfirmedLegalAge: { type: Boolean, default: false },
  },
  components: { Modal, Button, Input },
  setup(props, { emit }) {
    const heightCpfField = ref('2.9em')
    const modalVisibility = ref(props.visibility)
    const isLoading = ref(false)
    const validationOfMajority = ref(false)
    const width = ref('33em')
    const formLegalRepresentative = ref({
      legalRepresentative: '',
      emailLegalRepresentative: '',
    })

    const formDocument = ref({
      document: '',
    })
    const messageRequired = 'Este campo é obrigatório'
    const rulesMajority = computed(() => {
      if (!modalVisibility.value) return {}
      return {
        legalRepresentative: { required: helpers.withMessage(messageRequired, required) },
        emailLegalRepresentative: email,
      }
    })

    const rulesDocument = computed(() => {
      if (!modalVisibility.value) return {}
      return {
        document: {
          required: helpers.withMessage(messageRequired, required),
          minLength: helpers.withMessage('O cpf deve conter 11 caracteres', minLength(11)),
          cpfValid: helpers.withMessage('Insira um cpf válido', (value: string) => cpfValidation(value)),
        },
      }
    })
    const majority$ = useVuelidate(rulesMajority, formLegalRepresentative)
    const document$ = useVuelidate(rulesDocument, formDocument)

    const $q = useQuasar()
    const innerWidth = ref(window.innerWidth)
    const setNewTab = () => {
      innerWidth.value = window.innerWidth
    }
    window.addEventListener('resize', setNewTab)

    onMounted(() => {
      if (innerWidth.value <= 768) {
        width.value = '90%'
        heightCpfField.value = '2.3em'
      }
    })

    watch(innerWidth, () => {
      if (innerWidth.value <= 768) {
        heightCpfField.value = '2.3em'
        width.value = '90%'
      } else width.value = '33em'
    })

    const handleSubmitFields = async () => {
      let result = false
      const isValid1 = !validationOfMajority.value ? await majority$.value.$validate() : true
      const isValid2 = await document$.value.$validate()

      if (!props.hasCpf && props.hasConfirmedLegalAge) result = isValid2
      else if (!props.hasConfirmedLegalAge && props.hasCpf) result = isValid1
      else result = isValid1 && isValid2

      if (result) {
        isLoading.value = true
        try {
          const data = new PutLegalRepresentativeDTO(
            formLegalRepresentative.value.legalRepresentative !== '' ? formLegalRepresentative.value.legalRepresentative : undefined,
            formLegalRepresentative.value.emailLegalRepresentative !== '' ? formLegalRepresentative.value.emailLegalRepresentative : undefined,
            validationOfMajority.value
          )
          if (!props.hasCpf) await usersService.patch('/update', { document: formDocument.value.document })
          if (!props.hasConfirmedLegalAge) await usersService.put('/legal-representative', data)

          $q.notify({
            textColor: 'grey-1',
            message: 'Dados salvos com sucesso',
            color: 'green',
            position: 'top',
            classes: 'notify',
          })
        } catch {
          $q.notify({
            textColor: 'grey-1',
            message: 'Erro ao salvar dados, tente novamente',
            color: 'red',
            position: 'top',
            classes: 'notify',
          })
        } finally {
          isLoading.value = false
          modalVisibility.value = false
        }
      }
    }

    onUpdated(() => {
      if (modalVisibility.value !== props.visibility) modalVisibility.value = props.visibility
    })

    watch(
      () => modalVisibility.value,
      () => {
        emit('update:visibility', modalVisibility.value)
      }
    )

    return {
      isLoading,
      modalVisibility,
      validationOfMajority,
      formLegalRepresentative,
      width,
      majority$,
      document$,
      formDocument,
      heightCpfField,
      handleSubmitFields,
    }
  },
})
