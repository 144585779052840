import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]
const _hoisted_5 = ["fill"]
const _hoisted_6 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 22 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M17.9654 6.86426L18.5443 7.44324C19.8152 8.71423 19.8152 10.7823 18.5443 12.0533L17.9654 12.6323C17.7283 12.8703 17.5975 13.1863 17.5975 13.5223L17.5 14.5L16 13C16 12.5 16.3834 12.0923 16.9044 11.5713L17.4835 10.9923C18.1693 10.3063 18.1693 9.19031 17.4835 8.5033L16.9044 7.92432C16.3834 7.40433 16.0963 6.71124 16.0963 5.97424V5.16028C16.0963 4.19028 15.3062 3.40027 14.3362 3.40027H13.5213C12.7842 3.40027 12.0923 3.11328 11.5714 2.59229L10.9922 2.01331C10.3062 1.32629 9.19025 1.32629 8.50348 2.01331L7.92438 2.59229C7.40436 3.11328 6.71124 3.40027 5.97443 3.40027H5.16046C4.19025 3.40027 3.40045 4.18927 3.40045 5.16028V5.97424C3.40045 6.71124 3.11334 7.40332 2.59235 7.92432L2.01324 8.5033C1.32721 9.1893 1.32721 10.3053 2.01324 10.9923L2.59235 11.5713C3.11334 12.0913 3.40045 12.7842 3.40045 13.5212V14.3353C3.40045 15.3053 4.18927 16.0953 5.16046 16.0953H5.97443C6.71124 16.0953 7.40338 16.3823 7.92438 16.9033L8.50348 17.4823C9.18927 18.1693 10.3052 18.1693 10.9922 17.4823L11.5714 16.9033C12 16.5 13 16 13 16L14.264 17.5963H13.5223C13.1854 17.5963 12.8694 17.7272 12.6324 17.9642L12.0533 18.5433C11.4173 19.1794 10.5833 19.4973 9.74835 19.4973C8.91339 19.4973 8.07941 19.1794 7.44342 18.5433L6.86432 17.9642C6.62628 17.7272 6.31036 17.5963 5.97443 17.5963H5.16046C3.36237 17.5963 1.90045 16.1343 1.90045 14.3363V13.5223C1.90045 13.1853 1.76935 12.8693 1.53229 12.6323L0.95343 12.0533C-0.31781 10.7823 -0.31781 8.71423 0.95343 7.44324L1.53229 6.86426C1.76935 6.62625 1.90045 6.31024 1.90045 5.97424V5.16028C1.90045 3.36227 3.36237 1.90027 5.16046 1.90027H5.97443C6.31134 1.90027 6.62726 1.76935 6.86432 1.53235L7.44342 0.953247C8.71442 -0.317749 10.7823 -0.317749 12.0533 0.953247L12.6324 1.53235C12.8704 1.76935 13.1863 1.90027 13.5223 1.90027H14.3375C16.1353 1.90027 17.5975 3.36227 17.5975 5.16028V5.97424C17.5975 6.31125 17.7283 6.62726 17.9654 6.86426Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M13.2794 7.27832C13.5723 6.98532 13.5723 6.51028 13.2794 6.21729C12.9864 5.92529 12.5123 5.92529 12.2193 6.21729L6.2193 12.2173C5.92633 12.5103 5.92633 12.9853 6.2193 13.2783C6.3653 13.4253 6.55743 13.4983 6.74933 13.4983C6.94122 13.4983 7.13336 13.4243 7.27936 13.2783L13.2794 7.27832Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      d: "M12.2493 13.2483C12.8013 13.2483 13.2493 12.8003 13.2493 12.2483C13.2493 11.6963 12.8013 11.2483 12.2493 11.2483C11.6973 11.2483 11.2493 11.6963 11.2493 12.2483C11.2493 12.8003 11.6973 13.2483 12.2493 13.2483Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_4),
    _createElementVNode("path", {
      d: "M7.24933 6.24829C6.69733 6.24829 6.24933 6.69629 6.24933 7.24829C6.24933 7.80029 6.69733 8.24829 7.24933 8.24829C7.80133 8.24829 8.24933 7.80029 8.24933 7.24829C8.24933 6.69629 7.80133 6.24829 7.24933 6.24829Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_5),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M21.3764 13.4828C21.6621 13.7827 21.6505 14.2574 21.3506 14.5431L17.6172 18.0987C17.3276 18.3745 16.8724 18.3745 16.5828 18.0987L14.9828 16.5749C14.6828 16.2892 14.6712 15.8145 14.9569 15.5145C15.2426 15.2146 15.7173 15.203 16.0172 15.4886L17.1 16.5198L20.3161 13.4569C20.616 13.1712 21.0908 13.1828 21.3764 13.4828Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_6)
  ], 8, _hoisted_1))
}