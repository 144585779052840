import axios from 'axios'

const paymentsService = axios.create({
  baseURL: process.env.VUE_APP_PAZZEI_PAYMENTS_BASE_URL,
  timeout: 60000,
})

paymentsService.interceptors.request.use(
  (request) => {
    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    if (request.headers && token) {
      request.headers.Authorization = `Bearer ${token}`
      request.headers['Access-Control-Allow-Origin'] = '*'
    }

    return request
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default paymentsService
