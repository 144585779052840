
import { InstitutionTable } from '@/types/InstitutionType'
import { User } from '@/types/UsersType'

import { defineComponent, onMounted, PropType, ref, toRaw, watch } from 'vue'
import FiltersTableComponent from './components/FiltersTableComponent.vue'
import MenuListComponent from './components/MenuTableComponent.vue'

const __default__ = defineComponent({
  components: { FiltersTableComponent, MenuListComponent },
  name: 'TableComponent',
  emits: ['selectedRow'],
  props: {
    columns: {
      type: Array as PropType<Array<{ label: string; key: string }>>,
      required: true,
      default: () => [],
    },
    rows: {
      type: Array as PropType<Array<User | InstitutionTable | any>>,
      required: true,
      default: () => [],
    },
    isFilterSelected: {
      type: Boolean,
      default: false,
    },
    showOptionsMenu: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 1,
    },
    sizeColumnsTable: {
      type: String,
      default: '1fr / 2fr 1.2fr 2fr 1fr 2fr 1fr 1fr 0.25fr',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    sizeLoading: {
      type: String,
      default: '1fr / 2fr 1.2fr 2fr 1fr 2fr 1fr 1fr 0.25fr',
    },
    heightTable: {
      type: String,
    },
    widthTable: {
      type: String,
      default: '70em',
    },
    showCheckBox: {
      type: Boolean,
      default: false,
    },
    hasPadding: {
      type: Boolean,
      default: true,
    },
    isMediumModal: {
      type: Boolean,
      default: false,
    },
    tablePageSize: {
      type: Number,
      default: 20,
    },
  },

  setup(props, { emit }) {
    const widthTable = ref('')
    const handleToObject = (row: any) => {
      return toRaw(row)
    }
    const rowsHandler = ref<Array<any>>([])
    const isChecked = ref(false)

    let allIsChecked = 0
    const toggleAllRows = () => {
      rowsHandler.value.forEach((row) => {
        row.isChecked = isChecked.value
      })

      emit('selectedRow', rowsHandler.value)
    }

    const selectedItem = () => {
      allIsCheckedHandler()

      emit('selectedRow', toRaw(rowsHandler.value))
    }

    onMounted(() => {
      widthTable.value = props.widthTable
    })

    const allIsCheckedHandler = () => {
      allIsChecked = 0
      rowsHandler.value.forEach((row) => {
        if (row.isChecked) allIsChecked++
      })

      if (allIsChecked === rowsHandler.value.length) isChecked.value = true
      else isChecked.value = false
    }

    watch(
      () => props.rows,
      () => {
        rowsHandler.value = props.rows
        allIsCheckedHandler()
      }
    )

    watch(
      () => props.tablePageSize,
      () => allIsCheckedHandler()
    )

    const getColumnClass = (column: { key: string }, row: { [x: string]: any; isBlocked: boolean }) => {
      const isClassStyles = []
      if (column.key === 'isActive') {
        if (row[column.key] && !row.isBlocked) {
          isClassStyles.push('active')
        } else if (!row[column.key]) {
          isClassStyles.push('inactive')
        } else if (row[column.key] && row.isBlocked) {
          isClassStyles.push('blocked')
        }
      } else if (column.key === 'isDisabled') {
        if (!row[column.key]) isClassStyles.push('active')
        else isClassStyles.push('inactive')
      }
      return isClassStyles.join(' ')
    }

    const getColumnValue = (column: { key: string }, row: { [x: string]: any; isBlocked: boolean }) => {
      if (column.key === 'isActive') {
        if (row[column.key] && row.isBlocked) {
          return 'Bloqueado'
        } else if (row[column.key]) {
          return 'Ativo'
        } else {
          return 'Inativo'
        }
      } else if (column.key === 'isDisabled') {
        if (!row[column.key]) return 'Válido'
        else return 'Inválido'
      } else if (column.key === 'createdAt') {
        return new Date(row[column.key]).toLocaleDateString('pt-BR')
      } else if (column.key === 'expiresAt' && row[column.key]) {
        return new Date(row[column.key]).toLocaleString('pt-BR')
      } else if (column.key === 'plan' && !row.plan) {
        return 'Livre'
      } else if (row[column.key] && column.key === 'telephone') {
        return row[column.key].replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3')
      } else {
        return row[column.key] || '-'
      }
    }

    return {
      getColumnValue,
      handleToObject,
      getColumnClass,
      selectedItem,
      toggleAllRows,
      isChecked,
      rowsHandler,
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "88393bf2": (_ctx.sizeColumnsTable),
  "55ee56dd": (_ctx.widthTable)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__