import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9ccbd086"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "modal-heading" }
const _hoisted_3 = {
  key: 0,
  class: "modal-heading-title"
}
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = {
  key: 0,
  class: "body-description"
}
const _hoisted_6 = {
  key: 0,
  class: "loading-text"
}
const _hoisted_7 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PazzeiButton = _resolveComponent("PazzeiButton")!
  const _component_PazzeiModalDoodle = _resolveComponent("PazzeiModalDoodle", true)!

  return (_ctx.showModal)
    ? (_openBlock(), _createBlock(_component_PazzeiModalDoodle, {
        key: 0,
        onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
        context: _ctx.context,
        name: _ctx.name
      }, _createSlots({
        description: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (!_ctx.showLoader)
                ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.heading), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_4, [
              (!_ctx.showLoader)
                ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.description), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          (_ctx.showLoader)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, " Aguarde um momento "))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, [
        (!_ctx.showLoader)
          ? {
              name: "footer-actions-container",
              fn: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_PazzeiButton, {
                    classes: 'md',
                    text: _ctx.cancelButtonText,
                    alt: _ctx.cancelButtonText,
                    style: _normalizeStyle(`width: fit-content; ${_ctx.showLoader ? 'height: 252px' : '' }` ),
                    "text-classes": { text: 'text-16px', fontWeight: 'font-weight-600'},
                    onClick: _ctx.handleCancel
                  }, null, 8, ["text", "alt", "style", "onClick"]),
                  _createVNode(_component_PazzeiButton, {
                    classes: `with-background-color ${_ctx.confirmButtonVariant} lg`,
                    text: _ctx.confirmButtonText,
                    alt: _ctx.confirmButtonText,
                    style: 'width: fit-content;',
                    "text-classes": { text: 'text-18px', fontWeight: 'font-weight-600'},
                    onClick: _ctx.handleConfirm
                  }, null, 8, ["classes", "text", "alt", "onClick"])
                ])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["context", "name"]))
    : _createCommentVNode("", true)
}