import { io } from 'socket.io-client'
import { StopWatchWebSocketServiceEvents } from './events'

export class WebSocketService {
  stopWatchWs: StopWatchWebSocketServiceEvents

  constructor() {
    const token = localStorage.getItem('token')

    const url = (process.env.VUE_APP_PAZZEI_QUESTIONS_BASE_URL as string).replace('/questions', '')
    const path = '/questions/socket.io'

    this.stopWatchWs = new StopWatchWebSocketServiceEvents(
      io(url, {
        auth: { token: `Bearer ${token}` },
        transports: ['websocket'],
        path,
      })
    )
  }
}
