
import StopwatchClockIcon from "@/components/Icons/default/redesign/sharedList/StopwatchClockIcon.vue";
import TimeupStopwatchClockIcon from "@/components/Icons/default/redesign/sharedList/TimeupStopwatchClockIcon.vue";
import DefaultTheme from "@/theme/defaultTheme";
import { defineComponent } from "vue";

export default defineComponent({
  name: 'CardStopwatch',
  props: {
    maxTime: { type: Number, required: true, default: 0 },
    duration: { type: Number, required: true, default: 0 },
  },
  components: {
    StopwatchClockIcon,
    TimeupStopwatchClockIcon
  },
  setup(props) {
    const timeIsUp = () => {
      return props.duration / 60000 >= props.maxTime
    }

    return {
      timeIsUp,
      DefaultTheme
    }
  }
});

