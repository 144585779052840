import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 12 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M0 10L9.71444 20L12 17.6473L4.56466 9.99334L12 2.33943L9.71444 0L0 10Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}