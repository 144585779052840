import { User } from '@/types/UsersType'
import { ConfigDTO } from './ListConfigDTO'
import ListStatusDTO from './ListStatusDTO'
import { UserResponseDTO } from './ResponseDTOs/UserResponseDTO'

export class SubjectInfoDTO {
  id: number
  name: string
  totalQuestions: number
  color: string
  isTest: boolean
  listTries: Array<ListTryDTO>
  subjects?: Array<string>
  userHasList?: UserHasListDTO | null
  studentsCount?: number
  returnFeedback?: boolean
  status?: ListStatusDTO
  config?: ConfigDTO | null

  constructor(id: number, name: string, questionTotal: number, color: string, isTest: boolean, listTries: Array<ListTryDTO>, config: ConfigDTO | null, userHasList: UserHasListDTO | null) {
    this.id = id
    this.name = name
    this.totalQuestions = questionTotal
    this.color = color
    this.id = id
    this.isTest = isTest
    this.listTries = listTries
    this.config = config
    this.userHasList = userHasList
  }
}

export class ListTryDTO {
  id: number
  listId: number
  totalQuestions?: number
  totalResponses?: number
  duration?: number
  isFinished: boolean
  expiresAt?: string
  createdAt?: string
  updatedAt?: string
  userResponses?: Array<UserResponseDTO>

  constructor(listId: number, id: number, isFinished: boolean) {
    this.listId = listId
    this.id = id
    this.isFinished = isFinished
  }
}

export class UserHasListDTO {
  userId: string
  user: User
  active?: boolean
  professorName: string


  constructor(userId: string, user: User, professorName: string) {
    this.userId = userId
    this.user = user
    this.professorName = professorName
  }
}
