
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiSelectNew from '@/components/Generics/PazzeiSelectNew.vue'
import { Tag, UserTagsDTO } from '@/dtos/UserTagsDTO'
import getStates from '@/services/apis/ibgeStates'
import usersService from '@/services/users'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { ResponseDTO } from '../../dtos/ResponseDTOs/ResponseDTO'
import PazzeiSimpleSelect from '../Generics/PazzeiSimpleSelect.vue'
import LayoutEditing from '../Layouts/LayoutEditing.vue'

export default defineComponent({
  name: 'MyPreferences',
  components: { PazzeiSelectNew, PazzeiBtn, LayoutEditing, PazzeiSimpleSelect },
  setup() {
    const schoolingOptions = ['Ensino fundamental', 'Ensino Médio', 'Ensino Superior', 'Pós-Graduação', 'Mestrado', 'Doutorado']
    const trainingOptions = ['ENEM', 'Vestibular', 'Escola', 'Outros']
    const targetOptions = ref<string[]>([])
    const statesOptions = ref<string[]>([])
    const subjectOptions = ref<string[]>([])
    const isLoading = ref(false)
    const loading = ref(true)
    const readonly = ref(true)
    const currentTags = ref(Array<Tag>())
    const userTags = ref<UserTagsDTO>({
      target_state: [],
      scholarity: [],
      target: [],
      target_course: [],
      favorite_subject: [],
      target_subject: [],
    })
    const $q = useQuasar()

    const forceRender = reactive({
      render: 0,
    })

    onMounted(async () => {
      const { statesName } = await getStates()
      statesOptions.value = statesName

      const { data: tags } = await usersService.get<ResponseDTO<Array<Tag>>>('/users-tags')
      currentTags.value = tags.data

      const { data: target } = await usersService.get('/users-tags/target-course')
      targetOptions.value = target.data

      const { data: subject } = await usersService.get('/users-tags/favorite-subject')
      subjectOptions.value = subject.data
      try {
        tags.data.forEach((it: Tag) => {
          if (it.key === 'target_state') userTags.value.target_state.push(it.value)
          if (it.key === 'scholarity') userTags.value.scholarity.push(it.value)
          if (it.key === 'target') userTags.value.target.push(it.value)
          if (it.key === 'favorite_subject') userTags.value.favorite_subject.push(it.value)
          if (it.key === 'target_course') userTags.value.target_course.push(it.value)
          if (it.key === 'target_subject') userTags.value.target_subject.push(it.value)
        })
      } finally {
        loading.value = false
      }
    })

    const submitInfos = () => {
      isLoading.value = true
      const tags = Array<{ id?: string; key: string; value: string }>()
      for (const key in userTags.value) {
        userTags.value[key as keyof UserTagsDTO].map((item: string) => tags.push({ key, value: item }))
      }
      currentTags.value.forEach((it) => {
        if (it.key === 'studied_at') tags.push({ key: it.key, value: it.value })
        if (it.key === 'goal') tags.push({ key: it.key, value: it.value })
      })

      if (!tags.length) notification(false, 'Você precisa responder pelo menos uma opção')
      else
        usersService
          .put('/users-tags', { tags })
          .then(() => {
            isLoading.value = false
            notification(true, 'Dados salvos com sucesso')
            readonly.value = true
            tags.forEach((it) => {
              if (it.key === 'target_state') currentTags.value.push(it)
              if (it.key === 'scholarity') currentTags.value.push(it)
              if (it.key === 'target') currentTags.value.push(it)
              if (it.key === 'favorite_subject') currentTags.value.push(it)
              if (it.key === 'target_course') currentTags.value.push(it)
              if (it.key === 'target_subject') currentTags.value.push(it)
            })
          })
          .catch(() => {
            readonly.value = true
            isLoading.value = false
          })
    }
    const notification = (success: boolean, message: string) => {
      if (success) {
        $q.notify({
          textColor: 'grey-1',
          message: message,
          color: 'green',
          position: 'top',
          classes: 'notify',
        })
      } else {
        $q.notify({
          textColor: 'grey-1',
          message: message,
          color: 'yellow-8',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    const cancelChanges = () => {
      userTags.value.target_state = []
      userTags.value.scholarity = []
      userTags.value.target = []
      userTags.value.favorite_subject = []
      userTags.value.target_course = []
      userTags.value.target_subject = []
      forceRender.render = 1
      currentTags.value.forEach((it: Tag) => {
        if (it.key === 'target_state') userTags.value.target_state.push(it.value)
        if (it.key === 'scholarity') userTags.value.scholarity.push(it.value)
        if (it.key === 'target') userTags.value.target.push(it.value)
        if (it.key === 'favorite_subject') userTags.value.favorite_subject.push(it.value)
        if (it.key === 'target_course') userTags.value.target_course.push(it.value)
        if (it.key === 'target_subject') userTags.value.target_subject.push(it.value)
      })
      readonly.value = true
    }

    return {
      loading,
      subjectOptions,
      statesOptions,
      trainingOptions,
      schoolingOptions,
      targetOptions,
      userTags,
      isLoading,
      submitInfos,
      cancelChanges,
      forceRender,
      readonly,
    }
  },
})
