export class UserInfosDTO {
  email = ''
  groupId = 0
  id = ''
  name = ''
  lastName = ''
  roleCode = ''
  telephone = ''
  cpf = ''
  isLegalAge? = false
  legalRepresentative? = ''
  emailLegalRepresentative? = ''

  constructor(
    email: string,
    groupId: number,
    id: string,
    name: string,
    lastName: string,
    roleCode: string,
    telephone: string,
    cpf: string,
    isLegalAge?: boolean,
    legalRepresentative?: string,
    emailLegalRepresentative?: string
  ) {
    this.email = email
    this.groupId = groupId
    this.id = id
    this.name = name
    this.lastName = lastName
    this.roleCode = roleCode
    this.telephone = telephone
    this.cpf = cpf
    this.isLegalAge = isLegalAge
    this.legalRepresentative = legalRepresentative
    this.emailLegalRepresentative = emailLegalRepresentative
  }
}
