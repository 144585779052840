
import DefaultTheme from '@/theme/defaultTheme';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'PazzeiBtn',
  props: {
    label: String,
    icon: [Object, Function],
    submit: Boolean,
    loading: Boolean,
    disabled: Boolean,
    fill: Boolean,
    btnCollapsed: { type: Boolean, default: false },
    success: Boolean,
    purchase: Boolean,
    cancel: Boolean,
    subjectList: Boolean,
    iconFill: { type: String, default: DefaultTheme().colors.whiteColor },  
    iconStroke: { type: String, default:  DefaultTheme().colors.whiteColor }, 
    iconSize: { type: [String, Number], default: 24 } 
  },
  setup(props) {
    const handleClass = () => {
      if (props.fill) return props.purchase ? 'pazzei-btn-over purchase' : 'pazzei-btn-over';
      else if (props.success) return 'green-save-btn';
      else if (props.cancel) return 'grey-cancel-btn';
      else return 'btn-white';
    };

    const iconProps = computed(() => ({
      fill: props.iconFill,
      stroke: props.iconStroke,
      size: props.iconSize
    }));

    return { handleClass, iconProps };
  },
});
