import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.size + 1,
    height: _ctx.size,
    viewBox: "0 0 21 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M17.1667 15.8333V13.3333H6.33337C4.95266 13.3333 3.83337 14.4526 3.83337 15.8333M7.83337 18.3333H14.5C15.4335 18.3333 15.9002 18.3333 16.2567 18.1516C16.5703 17.9918 16.8253 17.7369 16.9851 17.4233C17.1667 17.0668 17.1667 16.6 17.1667 15.6666V4.33329C17.1667 3.39987 17.1667 2.93316 16.9851 2.57664C16.8253 2.26304 16.5703 2.00807 16.2567 1.84828C15.9002 1.66663 15.4335 1.66663 14.5 1.66663H7.83337C6.43324 1.66663 5.73318 1.66663 5.1984 1.93911C4.72799 2.17879 4.34554 2.56124 4.10586 3.03165C3.83337 3.56643 3.83337 4.26649 3.83337 5.66663V14.3333C3.83337 15.7334 3.83337 16.4335 4.10586 16.9683C4.34554 17.4387 4.72799 17.8211 5.1984 18.0608C5.73318 18.3333 6.43324 18.3333 7.83337 18.3333Z",
      stroke: _ctx.stroke,
      "stroke-width": "1.66667",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}