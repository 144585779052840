export const setLimitDate = (date: string) => {
  if (date !== '') {
    const inputDate = new Date(date)
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear() + 5
    inputDate.setUTCHours(23 + 3, 59, 59, 999)
    currentDate.setUTCHours(23 + 3, 59, 59, 999)

    return !isNaN(inputDate.getTime()) && inputDate >= currentDate && inputDate.getFullYear() <= currentYear
  } else {
    return true
  }
}
