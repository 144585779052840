
import { defineComponent, PropType } from 'vue';
import { CaptionAttributesProps } from './types';

export default defineComponent({
  name: 'CaptionView',
  props: {
    captionAttributes: { type: Object as PropType<Array<CaptionAttributesProps>>, required: true, default: () => ({}) },
    title: { type: String, required: true, default: '' },
  },
  setup() {
    return {}
  },
})
