
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import Input from '@/components/Library/Input/index.vue'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import { EditNameGroupDTO, GroupDTO } from '@/dtos/schoolPlanPages/GroupDTO/GroupDTO'
import groupsService from '@/services/groups'
import useVuelidate from '@vuelidate/core'
import { helpers, minLength, required } from '@vuelidate/validators'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'

export default defineComponent({
  name: 'ContentModalRegisterGroups',
  emits: ['close'],
  props: {
    groupId: {
      type: String,
      default: '',
    },
    listGroup: Function as PropType<() => void>,
    sizeEditLoading: {
      type: String,
    },
  },
  components: { Input, PazzeiBtn },

  setup(props, { emit }) {
    const isLoading = ref(false)
    const isFetchData = ref(false)
    const $q = useQuasar()
    const messageRequired = 'Este campo é obrigatório'
    const validation = ref<{ listName: string }>({
      listName: '',
    })

    const rules = computed(() => {
      return {
        listName: { required: helpers.withMessage(messageRequired, required), minLength: helpers.withMessage('O nome do grupo deve ter no mínimo 3 caracteres', minLength(3)) },
      }
    })

    const v$ = useVuelidate(rules, validation)
    const fetchNameGroup = async (groupId: number) => {
      isFetchData.value = true

      try {
        const { data: groupName } = await groupsService.get<ResponseDTO<GroupDTO>>('/single', { params: { id: groupId } })
        validation.value.listName = groupName.data.name
      } catch (error) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao buscar nome',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      } finally {
        isFetchData.value = false
      }
    }

    const updateGroupName = async () => {
      const result = await v$.value.$validate()

      if (result) {
        isLoading.value = true
        try {
          const data = new EditNameGroupDTO(Number(props.groupId), validation.value.listName)
          await groupsService.patch<ResponseDTO<GroupDTO>>('/', data)
          $q.notify({
            textColor: 'grey-1',
            message: 'Nome editado com sucesso',
            color: 'green',
            position: 'top',
            classes: 'notify',
          })
        } catch (error) {
          $q.notify({
            textColor: 'grey-1',
            message: 'Erro ao editar nome',
            color: 'red',
            position: 'top',
            classes: 'notify',
          })
        } finally {
          props.listGroup?.()
          isLoading.value = false
          emit('close')
        }
      }
    }

    onMounted(() => {
      fetchNameGroup(Number(props.groupId))
    })

    return {
      v$,
      validation,
      updateGroupName,
      isLoading,
      isFetchData,
    }
  },
})
