import { loadTheme } from './theme/defaultTheme'

import { createApp, ref } from 'vue'
import VueApexCharts from 'vue3-apexcharts'
import App from './App.vue'
import { UserDTO } from './dtos/ResponseDTOs/UserDTO'
import router from './router'

import Quasar from 'quasar/src/vue-plugin.js';
import getUserInfos from './helpers/UserInfos'
import groupsService from './services/groups'
import { ResponseDTO } from './dtos/ResponseDTOs/ResponseDTO'
import { InstitutionHasInstitutionPlanDTO } from './dtos/ResponseDTOs/InstitutionPlanDTO'

const user = ref<UserDTO>();

const initApp = async () => {
    const currentInstitutionConfig = await loadTheme();
    const {default: quasarUserOptions} = await import('./quasar-user-options')
    const userInfos = await getUserInfos();
    const roleCode = userInfos?.roleCode;
    const institutionId = userInfos?.institutionId;

    let entity = null;
    if (institutionId) {
        const response = await groupsService.get<ResponseDTO<InstitutionHasInstitutionPlanDTO>>(
            `/institution_plans/single?id=${institutionId}`
        );
        entity = response?.data;
    }

    const institutionPlanId = entity?.data?.institutionPlanId;
    const userCanInteract = (roleCode !== 'pazzei:no-credits') || (roleCode === 'pazzei:no-credits' && institutionPlanId);

    createApp(App)
        .use(Quasar, quasarUserOptions)
        .use(router)
        .use(VueApexCharts)
        .provide('userDTO', user)
        .provide('institutionConfig', currentInstitutionConfig)
        .provide('userRoleCode', roleCode)
        .provide('userCanInteract', userCanInteract)
        .mount('#app')
}

initApp();
