import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fec81bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "div-container" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "q-ma-none font-bold text-primary font-style" }
const _hoisted_4 = {
  key: 0,
  class: "fetch-data"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "form" }
const _hoisted_7 = { class: "margin-1 input-large" }
const _hoisted_8 = { class: "input-label" }
const _hoisted_9 = { style: {"display":"flex","margin-top":"2.5em","justify-content":"flex-end"} }
const _hoisted_10 = { class: "btn-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_circular_progress = _resolveComponent("q-circular-progress")!
  const _component_Input = _resolveComponent("Input")!
  const _component_PazzeiBtn = _resolveComponent("PazzeiBtn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.modalTitle), 1)
    ]),
    (_ctx.isFetchData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_q_circular_progress, {
            indeterminate: "",
            size: _ctx.sizeEditLoading,
            color: "secondary"
          }, null, 8, ["size"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("form", {
            id: "modal-form",
            name: "modal-form",
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateGroupName && _ctx.updateGroupName(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.label), 1),
                _createVNode(_component_Input, {
                  modelValue: _ctx.validation.listName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.validation.listName) = $event)),
                  error: _ctx.v$.listName.$invalid && _ctx.v$.listName.$error,
                  "error-message": _ctx.v$.listName.required?.$invalid ? _ctx.v$.listName.required?.$message : '' || _ctx.v$.listName.minLength.$invalid ? _ctx.v$.listName.minLength?.$message : '',
                  placeholder: "Digite o nome da sua lista",
                  maxLength: 50,
                  "create-list": "",
                  variant: "semi-rounded",
                  width: "100%",
                  height: "100%",
                  class: "input-large"
                }, null, 8, ["modelValue", "error", "error-message"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_PazzeiBtn, {
                    label: "Cancelar",
                    class: "btn",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
                  }),
                  _createVNode(_component_PazzeiBtn, {
                    label: "Salvar",
                    class: "btn",
                    fill: "",
                    submit: "",
                    loading: _ctx.isLoading
                  }, null, 8, ["loading"])
                ])
              ])
            ])
          ], 32)
        ]))
  ]))
}