
import PazzeiButton from '@/components/Generics/Redesign/PazzeiButton.vue';
import DefaultTheme from '@/theme/defaultTheme';
import { defineComponent } from 'vue';
import ReturnIcon from '../Icons/default/redesign/sharedList/ReturnIcon.vue';

export default defineComponent({
  name: 'MobileNavigationPanel',
  components: {
    PazzeiButton,
  },
  props: {
    onRegress: { type: Function, required: false, default: null }
  },
  setup() {
    return {
      ReturnIcon,
      DefaultTheme
    }
  }
})
