import DefaultTheme from "../../theme/defaultTheme";

export const colors = [
  {
    name: 'Vermelho',
    hex: DefaultTheme().colors.error,
  },
  {
    name: 'Azul',
    hex: DefaultTheme().colors.tertiary500,
  },
  {
    name: 'Amarelo',
    hex: DefaultTheme().colors.warning400,
  },
  {
    name: 'Verde',
    hex: DefaultTheme().colors.lightGreenColor,
  },
  {
    name: 'Laranja',
    hex: DefaultTheme().colors.orange500,
  },
  {
    name: 'Roxo',
    hex: DefaultTheme().colors.purple500,
  },
  {
    name: 'Rosa',
    hex: DefaultTheme().colors.pinkColor,
  },
]