
import HintIcon from '@/components/Icons/default/HintIcon.vue';
import DefaultTheme from '@/theme/defaultTheme';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'HintTooltip',
  props: {
    icon: { type: [Object, Function], default: HintIcon },
    text: { type: String, required: true },
    bold: { type: Boolean, default: false },
    iconFill: { type: String, default: DefaultTheme().colors.secondaryGray }, 
    iconStroke: { type: String, default: DefaultTheme().colors.secondaryGray }, 
    iconSize: { type: [String, Number], default: 20 },
  },
  setup(props) {
    const boldClass = computed(() => (props.bold ? 'font-weight-bold' : ''));
    const iconProps = computed(() => ({
      fill: props.iconFill,
      stroke: props.iconStroke,
      size: props.iconSize,
    }));

    return {
      boldClass,
      iconProps,
      tooltipRootStyles: { padding: '8px 12px', borderRadius: '8px !important', background: 'var(--gray-800)' },
    };
  },
});
