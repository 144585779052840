export class FilterListsDTO {
  id: number
  label: string
  isActive: boolean

  constructor(id: number, label: string, isActive: boolean) {
    this.id = id
    this.label = label
    this.isActive = isActive
  }
}

export class GenericFilterDTO {
  isActive: boolean
  
  constructor(isActive: boolean) {
    this.isActive = isActive
  }
}

