import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("rect", {
      width: "24",
      height: "24",
      rx: "12",
      fill: _ctx.DefaultTheme().colors.gray100
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M14.5 11.5V10C14.5 8.61929 13.3807 7.5 12 7.5C10.6193 7.5 9.5 8.61929 9.5 10V11.5M10.4 16.5H13.6C14.4401 16.5 14.8601 16.5 15.181 16.3365C15.4632 16.1927 15.6927 15.9632 15.8365 15.681C16 15.3601 16 14.9401 16 14.1V13.9C16 13.0599 16 12.6399 15.8365 12.319C15.6927 12.0368 15.4632 11.8073 15.181 11.6635C14.8601 11.5 14.4401 11.5 13.6 11.5H10.4C9.55992 11.5 9.13988 11.5 8.81901 11.6635C8.53677 11.8073 8.3073 12.0368 8.16349 12.319C8 12.6399 8 13.0599 8 13.9V14.1C8 14.9401 8 15.3601 8.16349 15.681C8.3073 15.9632 8.53677 16.1927 8.81901 16.3365C9.13988 16.5 9.55992 16.5 10.4 16.5Z",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_3)
  ], 8, _hoisted_1))
}