import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e11725f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "question-nav"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PazzeiButton = _resolveComponent("PazzeiButton")!

  return (_ctx.totalQuestions > 1 && _ctx.questionNumber)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PazzeiButton, {
          text: 'Anterior',
          icon: _ctx.ReturnIcon,
          iconStroke: _ctx.DefaultTheme().colors.secondaryGray,
          alt: 'Anterior',
          style: {"max-width":"108px"},
          disabled: Boolean(_ctx.questionNumber && _ctx.questionNumber === 1),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goBack()))
        }, null, 8, ["icon", "iconStroke", "disabled"]),
        (!_ctx.hideQuestionNumber)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass({ 'question-number': true, 'question-in-doubt': _ctx.isDoubt })
            }, [
              _createElementVNode("span", null, "Questão " + _toDisplayString(_ctx.questionNumber && _ctx.questionNumber < 10 ? `0${_ctx.questionNumber}` : _ctx.questionNumber), 1)
            ], 2))
          : _createCommentVNode("", true),
        _createVNode(_component_PazzeiButton, {
          text: 'Próxima',
          icon: _ctx.ForwardIcon,
          iconStroke: _ctx.DefaultTheme().colors.secondaryGray,
          "icon-right": true,
          alt: 'Próxima',
          style: {"max-width":"108px"},
          disabled: Boolean(_ctx.questionNumber && _ctx.questionNumber === _ctx.totalQuestions),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goForward()))
        }, null, 8, ["icon", "iconStroke", "disabled"])
      ]))
    : _createCommentVNode("", true)
}