import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SchoolLoginForm = _resolveComponent("SchoolLoginForm")!
  const _component_B2bAuthLayout = _resolveComponent("B2bAuthLayout")!

  return (_openBlock(), _createBlock(_component_B2bAuthLayout, {
    heading: _ctx.heading,
    subtitl: "",
    textCenter: ""
  }, {
    default: _withCtx(() => [
      (_ctx.user)
        ? (_openBlock(), _createBlock(_component_SchoolLoginForm, {
            key: 0,
            inheritUser: _ctx.user
          }, null, 8, ["inheritUser"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["heading"]))
}