import { helpers, minLength, required } from '@vuelidate/validators'
import { cpfValidation } from './../common/cpfValidation'

const cpfRules = {
  required: helpers.withMessage('Este campo é obrigatório', required),
  minLength: helpers.withMessage('O cpf deve conter 11 caracteres', minLength(11)),
  cpfValid: helpers.withMessage('Insira um cpf válido', (value: string) => cpfValidation(value)),
}

export default cpfRules
