
import B2bAuthLayout from '@/components/Layouts/B2bAuthLayout/B2bAuthLayout.vue'
import { defineComponent } from 'vue'
import B2bSignUpForm from '@/components/LoginForms/B2bSignUpForm/B2bSignUpForm.vue'

export default defineComponent({
  name: 'SignUpB2bPage',
  components: {
    B2bAuthLayout,
    B2bSignUpForm,
  },
  setup() {
    const heading = {
      title: 'Bem-vindo ao Pazzei!',
      subtitle: 'A plataforma de lista on-line do Super Professor®.'
    }

    return {
      heading,
    }
  },
})
