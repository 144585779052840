
import { defineComponent, ref, watch } from 'vue'

const __default__ = defineComponent({
  name: 'CollapseComponent',
  props: {
    hideChevron: { type: Boolean, default: false },
    modelValue: { type: Boolean, default: false },
    isCheckout: { type: Boolean, default: false },
    height: { type: String, default: '42px' },
    childWidth: { type: String, default: '100%' },
    label: { type: String, default: '' },
    width: { type: String, default: '100%' },
  },
  setup(props, { emit }) {
    const isExpanded = ref(props.modelValue)
    const itemContentRef = ref<HTMLDivElement | null>(null)
    const itemContentHeight = ref('0px')

    watch(isExpanded, () => {
      itemContentHeight.value = itemContentRef.value?.scrollHeight + 'px' || 0 + 'px'
      emit('update:modelValue', isExpanded.value)
    })

    watch(
      () => props.modelValue,
      (value) => {
        isExpanded.value = value
      }
    )

    return { isExpanded, itemContentRef, itemContentHeight }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "2b704fe8": (_ctx.childWidth)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__