import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 16 17",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M3.5 6.83333V5.16667C3.5 2.86548 5.36548 1 7.66667 1C9.37527 1 10.8437 2.02841 11.4866 3.5M7.66667 10.5833V12.25M5 16H10.3333C11.7335 16 12.4335 16 12.9683 15.7275C13.4387 15.4878 13.8212 15.1054 14.0608 14.635C14.3333 14.1002 14.3333 13.4001 14.3333 12V10.8333C14.3333 9.4332 14.3333 8.73314 14.0608 8.19836C13.8212 7.72795 13.4387 7.3455 12.9683 7.10582C12.4335 6.83333 11.7335 6.83333 10.3333 6.83333H5C3.59987 6.83333 2.8998 6.83333 2.36502 7.10582C1.89462 7.3455 1.51217 7.72795 1.27248 8.19836C1 8.73314 1 9.4332 1 10.8333V12C1 13.4001 1 14.1002 1.27248 14.635C1.51217 15.1054 1.89462 15.4878 2.36502 15.7275C2.8998 16 3.59987 16 5 16Z",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}