import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 16 19",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M1 4.30046H15.2222M11.6667 4.30046L11.4261 3.57874C11.193 2.87935 11.0764 2.52965 10.8602 2.27111C10.6693 2.0428 10.4241 1.86608 10.1471 1.75714C9.83346 1.63379 9.46485 1.63379 8.72762 1.63379H7.4946C6.75738 1.63379 6.38876 1.63379 6.07512 1.75714C5.79815 1.86608 5.55296 2.0428 5.36203 2.27111C5.14582 2.52965 5.02926 2.87935 4.79613 3.57874L4.55556 4.30046M13.4444 4.30046V13.3671C13.4444 14.8606 13.4444 15.6073 13.1538 16.1778C12.8981 16.6795 12.4902 17.0875 11.9884 17.3431C11.418 17.6338 10.6713 17.6338 9.17778 17.6338H7.04445C5.55097 17.6338 4.80423 17.6338 4.2338 17.3431C3.73204 17.0875 3.32409 16.6795 3.06843 16.1778C2.77778 15.6073 2.77778 14.8606 2.77778 13.3671V4.30046M9.88889 7.85601V14.0782M6.33333 7.85601V14.0782",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}