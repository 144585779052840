
import CardButton from '@/components/Application/entranceExamPage/TestDataBaseModal/CardButton/index.vue'
import { computed, defineComponent, onActivated, PropType, ref } from 'vue'

export default defineComponent({
  name: 'RegionComponent',
  emits: ['getSelection'],
  components: {
    CardButton,
  },
  props: {
    region: { type: Array as PropType<Array<string>>, default: () => [] },
    choices: { type: Array as PropType<Array<string>>, default: () => [] },
  },
  setup(props, { emit }) {
    const items = computed(() => props.region)
    const selectedValue = ref('')

    const handleClick = (value: string) => {
      selectedValue.value = value
      emit('getSelection', value)
    }

    onActivated(() => {
      selectedValue.value = props.choices[0] ? props.choices[0] : ''
    })

    return { items, selectedValue, handleClick }
  },
})
