const SUBDOMAIN_KEY = 'APP_SUBDOMAIN';

export const getSubdomainFromHost = (): string => {
  const host = window.location.hostname;
  const subdomain = host.split('.')[0];

  return subdomain.includes('-') ? subdomain.split('-')[0] : subdomain;
};

export const getSubdomainFromLocalStorage = (): string | null => {
  return localStorage.getItem(SUBDOMAIN_KEY);
};

export const setSubdomainToLocalStorage = (subdomain: string): void => {
  localStorage.setItem(SUBDOMAIN_KEY, subdomain);
};

export const checkAndSaveSubdomain = (): string => {
  const subdomain = getSubdomainFromHost();
  const savedSubdomain = getSubdomainFromLocalStorage();

  if (savedSubdomain !== subdomain) {
    setSubdomainToLocalStorage(subdomain);
  }

  return subdomain;
};

export const handleStagingSubdomain = (theme: string | null) => {
  if (!theme) {
    return;
  }

  return theme.split('-').filter((item) => item !== 'staging')[0];
}
