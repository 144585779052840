import { helpers, maxLength, minLength, required } from '@vuelidate/validators'
import { minLowerLetter, minUpperLetter, minNumber, minSpecialChar } from '@/utils/regex/passwordRegex'

const passwordRule = {
  required: helpers.withMessage('Este campo é obrigatório', required),
  minLength: helpers.withMessage('A senha deve possuir no mínimo 8 caracteres', minLength(8)),
  minNumber: helpers.withMessage('Insira no mínimo 1 número', helpers.regex(minNumber)),
  minUpperLetter: helpers.withMessage('Insira no mínimo 1 letra maiúscula', helpers.regex(minUpperLetter)),
  minLowerLetter: helpers.withMessage('Insira no mínimo 1 letra minúscula', helpers.regex(minLowerLetter)),
  maxLength: helpers.withMessage('Limite máximo de 30 caracteres', maxLength(30)),
  specialChar: helpers.withMessage('Insira no mínimo 1 símbolo especial', helpers.regex(minSpecialChar))
}

export const calculatePasswordStrength = (password: string) => {
  let score = 0
  if (password.length >= 8) score += 0.2;
  if (minNumber.test(password)) score += 0.2;
  if (minUpperLetter.test(password)) score += 0.2;
  if (minLowerLetter.test(password)) score += 0.2;
  if (minSpecialChar.test(password)) score += 0.2;

  return Math.min(score, 1);
}

export default passwordRule
