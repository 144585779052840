
import { defineComponent, inject, onMounted, ref, watch } from 'vue';
import { AlternativesSetType } from './types/AlternativesSet';
import { ParentPropsType, ParentType } from './types/ParentType';

export default defineComponent({
  name: 'AlternativeOption',
  props: {
    correct: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
    wrong: { type: Boolean, default: false },
    notSelectable: { type: Boolean, default: false },
    id: { type: Number, required: true, default: -1 },
    index: { type: Number, required: true, default: -1 },
    content: { type: String, default: '' },
    label: { type: String, default: '' },
  },
  setup(props) {
    const isSelected = ref<boolean | null>(null)
    const horizontalMode = ref(false)
    const questionType = ref('summation')

    const defineSetSelectedAlternatives = inject<(alternativesSetType: AlternativesSetType) => void>('defineSetSelectedAlternatives')
    const setMcq = inject<(index: number) => void>('setMcq')
    const setSummation = inject<(index: number) => void>('setSummation')

    const initSettings = (alternativeId: number) => {
      if (questionType.value === 'true/false') {
        const state = props.label ? (props.label === 'V' ? true : false) : null
        isSelected.value = state

        defineSetSelectedAlternatives?.({ alternativeId, state })
      }
    }

    const setAlternativeOptionLabels = (parent: ParentType, index: number) => {
      const p = parent as ParentPropsType

      questionType.value = p.type
      horizontalMode.value = p.horizontal

      if (questionType.value === 'true/false' && isSelected.value !== null) {
        if (!isSelected.value) p.alternativeOptionLabels[index] = 'F'
        else p.alternativeOptionLabels[index] = 'V'
      } else if (questionType.value === 'mcq') {
        if (p.modelValue.alternatives && p.modelValue.alternatives[0] === props.id) isSelected.value = true
        else isSelected.value = p.arraySelectedAlternatives[index]
      } else if (questionType.value === 'summation') {
        if (p.modelValue.alternatives && p.modelValue.alternatives.length && p.modelValue.alternatives.find((alt) => alt === props.id)) isSelected.value = true
      }

      return p.alternativeOptionLabels[index]
    }

    const handleAlternativeSelection = (alternativeId: number) => {
      isSelected.value = !isSelected.value

      switch (questionType.value) {
        case 'true/false':
          defineSetSelectedAlternatives?.({ alternativeId, state: isSelected.value })
          break
        default:
          if (questionType.value === 'mcq') setMcq?.(props.index)
          if (questionType.value === 'summation') setSummation?.(props.index)
          defineSetSelectedAlternatives?.(alternativeId)
      }
    }

    onMounted(() => initSettings(props.id))
    watch(questionType, () => initSettings(props.id))

    return { horizontalMode, isSelected, setAlternativeOptionLabels, handleAlternativeSelection, questionType }
  },
})
