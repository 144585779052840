
import { defineComponent, onMounted, ref } from 'vue'
import PlanBenefitsInfo from './PlanBenefitsInfo.vue'
import { formatCurrency } from '@/utils/common/formatCurrency'

export default defineComponent({
  props: {
    plan: {
      type: String,
      default: 'Teste',
      required: true,
    },
    requestDate: {
      type: String,
      default: '-',
      required: true,
    },
    paymentMethod: {
      type: String,
      default: '',
      required: true,
    },
    paymentValue: {
      type: String,
      default: '',
      required: true,
    },
    subtotal: {
      type: String,
      default: 'R$ 0,00',
      required: true,
    },
    coupon: {
      type: String,
      default: '',
      required: true,
    },
    hasCoupon: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: -1,
      required: true,
    },
    autoRenew: {
      type: Boolean,
      default: false,
    },
    installments: {
      type: Number,
      default: 0,
    },
    isSemester: {
      type: Boolean,
      default: false,
    },
    validity: {
      type: Number,
      default: -1,
    },
    hasValidity: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const totalValueUpdated = ref('')

    onMounted(() => {
      const value = props.installments === 0 ? props.total : props.total / props.installments
      totalValueUpdated.value = formatCurrency(value)
    })
    return { totalValueUpdated }
  },
  components: { PlanBenefitsInfo },
})
