import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 22 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M4 7.99994V14.0111C4 14.37 4 14.5495 4.05465 14.708C4.10299 14.8481 4.18187 14.9757 4.28558 15.0816C4.40287 15.2014 4.5634 15.2816 4.88446 15.4422L10.2845 18.1422C10.5468 18.2733 10.678 18.3389 10.8156 18.3648C10.9375 18.3876 11.0625 18.3876 11.1844 18.3648C11.322 18.3389 11.4532 18.2733 11.7155 18.1422L17.1155 15.4422C17.4366 15.2816 17.5971 15.2014 17.7144 15.0816C17.8181 14.9757 17.897 14.8481 17.9453 14.708C18 14.5495 18 14.37 18 14.0111V7.99994M1 6.49994L10.6422 1.67883C10.7734 1.61324 10.839 1.58044 10.9078 1.56753C10.9687 1.5561 11.0313 1.5561 11.0922 1.56753C11.161 1.58044 11.2266 1.61324 11.3578 1.67883L21 6.49994L11.3578 11.3211C11.2266 11.3866 11.161 11.4194 11.0922 11.4324C11.0313 11.4438 10.9687 11.4438 10.9078 11.4324C10.839 11.4194 10.7734 11.3866 10.6422 11.3211L1 6.49994Z",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}