
import BottomActionPanelMobile from '@/components/BottomActionPanelMobile/BottomActionPanelMobile.vue';
import PrinterIcon from '@/components/Icons/default/redesign/sharedList/PrinterIcon.vue';
import ReturnIcon from '@/components/Icons/default/redesign/sharedList/ReturnIcon.vue';
import ListHeader from '@/components/ListHeader/ListHeader.vue';
import MobileNavigationPanel from '@/components/MobileNavigationPanel/MobileNavigationPanel.vue';
import { useScreenSize } from '@/composables/screen-size';
import { ListTryDTO, SubjectInfoDTO } from '@/dtos/SubjectInfoDTO';
import DefaultTheme from '@/theme/defaultTheme';
import { computed, defineComponent, ref, onMounted } from 'vue';
import CardDeadline from '../CardDeadline.vue';
import CardStopwatch from '../CardStopwatch.vue';
import PazzeiButton from '../PazzeiButton.vue';

export default defineComponent({
  name: 'ListPage',
  components: {
    MobileNavigationPanel,
    BottomActionPanelMobile,
    PazzeiButton,
    CardDeadline,
    CardStopwatch,
    ListHeader,
  },
  props: {
    selectedList: { type: SubjectInfoDTO, required: true }
  },
  setup(props, {emit}) {
    const createdBy = ref(props?.selectedList.config?.professorName || props?.selectedList?.userHasList?.user?.name || null);
    const totalResponses = ref(0)
    const progress = ref(0)
    const lastTry = ref<ListTryDTO>();
    const showPrintListModal = ref(false);

    const formattedTimeToConclude = computed(() => {
      return 10
    })

    if (props.selectedList.listTries) {
      const arraySorted = props.selectedList.listTries.map((it) => it).sort((a, b) => a.id - b.id)
      lastTry.value = arraySorted[arraySorted.length - 1]
    }

    const screen = useScreenSize();

    const goBack = () => {
      emit('showListPreview', false);
    }

    const isListPrintable = () => {
      return !props.selectedList.config || props.selectedList.config?.print
    }

    const handleShowPrintListModal = () => {
      if (!isListPrintable()) return;

      emit('handleShowPrintListModal', true);
    }

    const handleShowLimitTimeModal = () => {
      if (isTimeUp() || reachedDeadline()) return;

      if (
        !props.selectedList?.config
        || (!props.selectedList?.config?.countdown || props.selectedList?.config?.countdown === 0)
      ) {
        emit('start-list');
        return;
      }

      emit('show-modal-limit-time', true);
    }

    const reachedDeadline = () => {
      if (props.selectedList.config?.endDate) {
        return new Date() >= new Date(props.selectedList.config?.endDate);
      } else if (props.selectedList.listTries[0].expiresAt) {
        return new Date() >= new Date(props.selectedList.listTries[0].expiresAt);
      }

      return false;
    }

    const isTimeUp = () => {
      return (props.selectedList?.config?.countdown && props.selectedList?.config?.countdown !== 0)
        && props.selectedList.listTries[0].duration
        && props.selectedList?.config?.countdown < props.selectedList.listTries[0].duration / 60000;
    }

    const getCautionText = () => {
      let message = [];

      if (!reachedDeadline() && !isTimeUp()) {
        message.push('Atenção: Não será possível realizar a atividade após o tempo limite.')
      }

      if (reachedDeadline()) {
        message.push('Atenção: O prazo para realizar esta atividade já encerrou. Infelizmente, não é mais possível acessar a lista.')
      }
      if (isTimeUp()) {
        message.push('Atenção: O tempo limite para completar esta atividade já esgotou. Infelizmente, não é mais possível acessar a lista.');
        message.push('Suas respostas anteriores foram salvas.')
      }

      return message;
    }

    const setTotalResponses = () => {
      const responses = props.selectedList.listTries[0].userResponses;
      totalResponses.value = responses?.filter((response) => response.isCorrect !== null).length || 0;
      progress.value = totalResponses.value ? totalResponses.value / props.selectedList.totalQuestions : 0
    }

    onMounted(() => {
      setTotalResponses();
    })

    return {
      goBack,
      createdBy,
      totalResponses,
      progress,
      handleShowPrintListModal,
      showPrintListModal,
      formattedTimeToConclude,
      handleShowLimitTimeModal,
      getCautionText,
      isTimeUp,
      reachedDeadline,
      isListPrintable,
      isMobile: screen.isMobile,
      PrinterIcon,
      DefaultTheme,
      ReturnIcon
    }
  }
})
