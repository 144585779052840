
import ContentModalAddReceivers from '@/components/Application/planSchoolPortal/modals/modalCreateListExercices/ContentModalAddReceivers.vue'
import ContentModalEditListName from '@/components/Application/planSchoolPortal/modals/modalCreateListExercices/ContentModalEditListName.vue'
import TablesComponentQuestions from '@/components/Application/planSchoolPortal/mountTableComponent/TablesComponent.vue'
import PaginationTableComponent from '@/components/Application/planSchoolPortal/mountTableComponent/components/PaginationTableComponent.vue'
import ViewingListSummary from '@/components/Application/viewingListSummary/ViewingListSummary.vue'
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiInput from '@/components/Generics/PazzeiInput.vue'
import AddIcon from '@/components/Icons/default/AddIcon.vue'
import EditRegisterIcon from '@/components/Icons/default/EditRegisterIcon.vue'
import EstatisticsIcon from '@/components/Icons/default/EstatisticsIcon.vue'
import MountListsIcon from '@/components/Icons/default/MountListsIcon.vue'
import RemoveSchoolIcon from '@/components/Icons/default/RemoveSchoolIcon.vue'
import SearchIcon from '@/components/Icons/default/SearchIcon.vue'
import SharingListHistoricIcon from '@/components/Icons/default/SharingListHistoricIcon.vue'
import ViewRegisterIcon from '@/components/Icons/default/ViewRegisterIcon.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import Modal from '@/components/Library/Modal/index.vue'
import ModalConfirmation from '@/components/Library/ModalConfirmation/index.vue'
import { PagedDTO } from '@/dtos/ResponseDTOs/PagedDTO'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import { SubjectInfoDTO } from '@/dtos/SubjectInfoDTO'
import authService from '@/services/authService'
import listsService from '@/services/list'
import DefaultTheme from '@/theme/defaultTheme'
import { colors } from '@/utils/colorPallete/colors'
import { columnsProfessor, columnsRoot } from '@/utils/hardCoded/planSchool/listExercices/exercices'
import debounce from 'quasar/src/utils/debounce.js';import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, onMounted, provide, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'ViewUsersPage',
  components: {
    GenericLayout,
    MainLayoutWrapper,
    PazzeiBtn,
    PazzeiInput,
    PaginationTableComponent,
    TablesComponentQuestions,
    Modal,
    ContentModalEditListName,
    ContentModalAddReceivers,
    ViewingListSummary,
    ModalConfirmation,
    SearchIcon
  },

  setup() {
    const isSearchInputExpanded = ref(true)
    const isModalOpen = ref(false)
    const currentPage = ref(1)
    const pageSize = ref(20)
    const totalElements = ref(0)
    const isLoading = ref(false)
    const isEditNameList = ref(false)
    const isModalAddReceiversOpen = ref(false)
    const isModalRemoveList = ref(false)
    const isModalViewingListSummary = ref(false)
    const heightTable = ref('49em')
    const widthTable = ref('70em')
    const sizeEditLoading = ref('3em')
    const heightLoading = ref('17.55em')
    const sizeLoading = ref('60px')
    const $q = useQuasar()
    const rowsLists = ref<SubjectInfoDTO[]>([])
    const getResponse = ref()
    const filterField = ref<string | undefined>('')
    const previousCurrentPage = ref(1)
    const previousPageSize = ref(20)
    const listId = ref(-1)
    const listName = ref('')
    const innerWidth = ref(window.innerWidth)
    const sizeColumnLists = ref('1fr / 3fr 1.2fr 2.5fr 1.2fr 1fr 0.25fr')
    const sizeColumnListsRoot = ref('1fr / 2.5fr 2.5fr 1.2fr 2.5fr 1.2fr 1.2fr 0.25fr')
    const width = ref('66.875em')
    const height = ref('37.3em')
    const widthModalSmall = ref('60.625em')
    const heightModalSmall = ref('24em')
    const optionsColors = ref(colors.map((color) => color.name))
    const userPermissionRoot = ref(false)
    const permissions = authService.getPermissions()?.permissions
    userPermissionRoot.value = permissions?.includes('root:users:users:manage') ?? false
    const router = useRouter()

    const openModalAddReceivers = () => (isModalAddReceiversOpen.value = true)
    const openModalRemoveList = () => (isModalRemoveList.value = true)
    const openViewingListSummary = () => (isModalViewingListSummary.value = true)
    const openModal = () => (isModalOpen.value = true)
    const editNameList = () => (isEditNameList.value = true)
    const closeModal = () => ((isModalOpen.value = false), (isModalAddReceiversOpen.value = false))
    const closeModalEditName = () => (isEditNameList.value = false)
    const setNewTab = () => {
      innerWidth.value = window.innerWidth
    }
    window.addEventListener('resize', setNewTab)

    const handleCreateLists = () => router.push('/criar-lista')
    const getColorName = (color: string) => {
      const matchedColor = colors.find((c) => c.hex === color)
      return matchedColor ? matchedColor.name : ''
    }

    const handlePageSizeChange = (selectedPageSize: number) => {
      if (selectedPageSize !== previousPageSize.value) {
        pageSize.value = selectedPageSize
        getListsData()
        previousPageSize.value = selectedPageSize
      }
    }

    const handleCurrentPage = (selectedCurrentPage: number) => {
      if (selectedCurrentPage !== previousCurrentPage.value) {
        currentPage.value = selectedCurrentPage
        getListsData()
        previousCurrentPage.value = selectedCurrentPage
      }
    }
    const handleValueUpdated = (value: { id: number; name: string }) => {
      listId.value = value.id
      listName.value = value.name
    }

    onMounted(() => {
      getListsData()

      if (innerWidth.value <= 768) {
        width.value = '90%'
        height.value = '30.2em'
        heightTable.value = '58vh'
        widthTable.value = '70em'
        heightLoading.value = '20.65em'
        sizeEditLoading.value = '2em'
        sizeLoading.value = '2.1875em'
        widthModalSmall.value = '90%'
        heightModalSmall.value = '14.5em'
        sizeEditLoading.value = '2em'
      }
    })

    const iconProps = {
      fill: DefaultTheme().colors.pinkColor,
      stroke: DefaultTheme().colors.pinkColor,
      size: 20,
    }

    const optionsMenu = [
      { label: 'Visualizar Questões', icon: ViewRegisterIcon, iconProps, action: () => openViewingListSummary?.() },
      { label: 'Vincular Aluno/Grupo', icon: AddIcon, iconProps, action: () => openModalAddReceivers?.() },
      { label: 'Renomear Lista', icon: EditRegisterIcon, iconProps, action: () => editNameList?.() },
      { label: 'Excluir Lista', icon: RemoveSchoolIcon, iconProps, action: () => openModalRemoveList?.() },
      { label: 'Histórico de Compartilhamento', icon:SharingListHistoricIcon, iconProps, action: (row: any) => router.push({ name: 'viewSharingHistory', params: { id: row.id } }) },
      { label: 'Ver estatísticas', icon: EstatisticsIcon, iconProps, action: () => '', disabled: true },
    ]

    provide('optionsMenu', optionsMenu)
    provide('heightLoading', heightLoading)
    provide('emitValueToParent', handleValueUpdated)

    const removeList = async () => {
      try {
        await listsService.delete('/', { data: { ids: [listId.value] } })

        $q.notify({
          textColor: 'grey-1',
          message: 'Lista deletada com sucesso',
          color: 'green',
          position: 'top',
          classes: 'notify',
        })

        getListsData()
      } catch (error) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao deletar lista',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    const getListsData = async () => {
      const requestBody = {
        page: currentPage.value,
        pageSize: pageSize.value,
        search: {
          name: filterField.value !== '' ? filterField.value : undefined,
        },
        filter: { isTest: false },
      }

      isLoading.value = true
      try {
        const response = await listsService.post<ResponseDTO<PagedDTO<SubjectInfoDTO>>>('/lists', requestBody)
        getResponse.value = response.data.data.data
        rowsLists.value = getResponse.value.map((it: SubjectInfoDTO) => {
          return {
            id: it.id,
            name: it.name,
            createdBy: userPermissionRoot.value ? `${it.userHasList?.user.name} ${it.userHasList?.user.lastName !== null ? it.userHasList?.user.lastName : ''}` : undefined,
            color: getColorName(it.color),
            subjects: it.subjects?.join(', ').toString(),
            totalQuestions: it.totalQuestions,
            students: it.studentsCount,
          }
        })
        totalElements.value = response.data.data.totalElements
      } catch (error) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao buscar dados',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
        console.error(error)
      } finally {
        isLoading.value = false
      }
    }

    const debouncedGetListExercices = debounce(getListsData, 500)

    watch(filterField, () => {
      currentPage.value = 1
      debouncedGetListExercices()
    })

    return {
      isSearchInputExpanded,
      isModalOpen,
      openModal,
      innerWidth,
      width,
      height,
      closeModal,
      rowsLists,
      optionsColors,
      isEditNameList,
      columnsRoot,
      columnsProfessor,
      handlePageSizeChange,
      handleCurrentPage,
      totalElements,
      sizeColumnLists,
      isLoading,
      sizeLoading,
      heightTable,
      widthTable,
      userPermissionRoot,
      sizeColumnListsRoot,
      filterField,
      closeModalEditName,
      widthModalSmall,
      heightModalSmall,
      getListsData,
      sizeEditLoading,
      isModalAddReceiversOpen,
      handleCreateLists,
      listId,
      listName,
      isModalViewingListSummary,
      isModalRemoveList,
      removeList,
      MountListsIcon,
      DefaultTheme,
    }
  },
})
