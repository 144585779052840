import { defaultColors } from "@/theme/tokens/colors";
import { ColorsType } from "@/theme/types";

export const BioExplicaBrandColors: ColorsType = {
  ...defaultColors,

  primary: '#3ca594',
  secondary: '#38b34a',
  primaryColor: '#308275',
  pinkColor: '#2a8437',

  primary25: '#eef9f7',
  primary50: '#ccece7',
  primary100: '#aae0d7',
  primary200: '#88d4c7',
  primary300: '#66c7b8',
  primary400: '#44bba8',
  primary500: '#389989',
  primary600: '#2b776b',
  primary700: '#1f554c',
  primary800: '#13332e',
  primary900: '#06110f',

  secondary25: '#edf9ef',
  secondary50: '#caeecf',
  secondary100: '#a7e3b0',
  secondary200: '#83d890',
  secondary300: '#60cd70',
  secondary400: '#3dc250',
  secondary500: '#329f42',
  secondary600: '#277c33',
  secondary700: '#1c5824',
  secondary800: '#113516',
  secondary900: '#061207',

  rose25: 'edf9ef',  
  rose50: 'caeecf', 
  rose100: 'a7e3b0', 
  rose200: '83d890',
  rose300: '60cd70', 
  rose400: '3dc250',
  rose500: '329f42', 
  rose600: '277c33', 
  rose700: '1c5824',
  rose800: '113516', 
  rose900: '061207', 
};
