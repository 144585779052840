
import DefaultTheme from '@/theme/defaultTheme';
import { defineComponent } from 'vue';
import ClearIcon from '../Icons/default/ClearIcon.vue';

export default defineComponent({
  name: 'PazzeiChip',
  props: {
    label: String,
    field: String,
    isReports: { type: Boolean, default: false },
    currentKey: { type: Number, default: -1 },
  },
  components: {
    ClearIcon
  },
  setup() {
    return {
      DefaultTheme
    }
  }
})
