import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-94402ca4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"height":"100%"} }
const _hoisted_2 = { style: {"width":"92%"} }
const _hoisted_3 = { class: "text-primary text-size-20 font-bold" }
const _hoisted_4 = { class: "text-secundary text-size-18" }
const _hoisted_5 = { style: {"display":"flex","justify-content":"flex-end"} }
const _hoisted_6 = { class: "btn-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseIcon = _resolveComponent("CloseIcon")!
  const _component_PazzeiBtn = _resolveComponent("PazzeiBtn")!
  const _component_Backdrop = _resolveComponent("Backdrop")!

  return (_ctx.modelValue)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "#portal-root"
      }, [
        _createVNode(_component_Backdrop, {
          onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (!_ctx.preventClosing ? _ctx.handleClose() : null), ["self"]))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              ref: "modalRef",
              class: _normalizeClass(["modal", { [`modal--${_ctx.variant}`]: _ctx.variant }]),
              tabindex: "-1"
            }, [
              (!_ctx.hideIcon)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["modal-close-button-container", { [`modal--${_ctx.variant}`]: _ctx.variant }])
                  }, [
                    _createElementVNode("button", {
                      class: "modal-close-button",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.preventClosing ? _ctx.handleClose() : null))
                    }, [
                      _createVNode(_component_CloseIcon, {
                        fill: _ctx.DefaultTheme().colors.primary,
                        class: _normalizeClass({ [`modal--${_ctx.variant}`]: _ctx.variant })
                      }, null, 8, ["fill", "class"])
                    ])
                  ], 2))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.label), 1)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.info), 1)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_PazzeiBtn, {
                      label: "Cancelar",
                      class: "btn",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleClose()))
                    }),
                    _createVNode(_component_PazzeiBtn, {
                      label: "Confirmar",
                      class: "btn",
                      fill: "",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clickConfirm?.(), _ctx.handleClose()))
                    })
                  ])
                ])
              ])
            ], 2)
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}