import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bca847a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "div-nav-historic" }
const _hoisted_2 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_3 = { class: "q-ma-none font-bold text-primary font-style-header" }
const _hoisted_4 = { class: "grid-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentModalBillingInformation = _resolveComponent("ContentModalBillingInformation")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_ContentModalRefoundPayment = _resolveComponent("ContentModalRefoundPayment")!
  const _component_ArrowBackIcon = _resolveComponent("ArrowBackIcon")!
  const _component_PazzeiBtn = _resolveComponent("PazzeiBtn")!
  const _component_TablesComponentHistory = _resolveComponent("TablesComponentHistory")!
  const _component_PaginationTableComponent = _resolveComponent("PaginationTableComponent")!
  const _component_MainLayoutWrapper = _resolveComponent("MainLayoutWrapper")!
  const _component_GenericLayout = _resolveComponent("GenericLayout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Modal, {
      modelValue: _ctx.isModalOpen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isModalOpen) = $event)),
      height: _ctx.height,
      width: _ctx.width
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ContentModalBillingInformation, { id: _ctx.billingId }, null, 8, ["id"])
      ]),
      _: 1
    }, 8, ["modelValue", "height", "width"]),
    _createVNode(_component_Modal, {
      modelValue: _ctx.isModalRefundOpen,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isModalRefundOpen) = $event)),
      height: _ctx.heightRefund,
      width: _ctx.width
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ContentModalRefoundPayment, {
          billingId: _ctx.billingId,
          billingPrice: _ctx.billingPrice,
          reloadData: _ctx.getPurchasesHistory,
          onClose: _ctx.closeModalRefundPayment
        }, null, 8, ["billingId", "billingPrice", "reloadData", "onClose"])
      ]),
      _: 1
    }, 8, ["modelValue", "height", "width"]),
    _createVNode(_component_GenericLayout, null, {
      default: _withCtx(() => [
        _createVNode(_component_MainLayoutWrapper, null, {
          nav: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("button", {
                  class: "button-back",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.router.push('/usuarios')))
                }, [
                  _createVNode(_component_ArrowBackIcon, {
                    fill: _ctx.DefaultTheme().colors.pinkColor
                  }, null, 8, ["fill"])
                ]),
                _createElementVNode("h2", _hoisted_3, "Histórico de compra - " + _toDisplayString(_ctx.userName), 1)
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["btn-container", { 'btn-pazzei__collapsed': _ctx.isSearchInputExpanded }])
              }, [
                _createVNode(_component_PazzeiBtn, {
                  icon: _ctx.ExportcsvIcon,
                  iconStroke: _ctx.DefaultTheme().colors.pinkColor,
                  label: "Exportar CSV",
                  class: _normalizeClass(["btn-pazzei", { 'btn-pazzei__collapsed': _ctx.isSearchInputExpanded }]),
                  disabled: _ctx.rowsPurchasesHistoric.length === 0,
                  "btn-collapsed": _ctx.isSearchInputExpanded,
                  onClick: _ctx.downloadCsvFile
                }, null, 8, ["icon", "iconStroke", "disabled", "class", "btn-collapsed", "onClick"])
              ], 2)
            ])
          ]),
          main: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_TablesComponentHistory, {
                columns: _ctx.columnsHistoric,
                rows: _ctx.rowsPurchasesHistoric,
                isLoading: _ctx.isLoading,
                sizeLoading: _ctx.sizeLoading,
                heightTable: _ctx.heightTable,
                widthTable: _ctx.widthTable,
                sizeColumnsTable: _ctx.sizeColumnHistoric,
                showOptionsMenu: ""
              }, null, 8, ["columns", "rows", "isLoading", "sizeLoading", "heightTable", "widthTable", "sizeColumnsTable"])
            ])
          ]),
          footer: _withCtx(() => [
            _createVNode(_component_PaginationTableComponent, {
              onPageSizeChange: _ctx.handlePageSizeChange,
              onCurrentPage: _ctx.handleCurrentPage,
              totalElements: _ctx.totalElements
            }, null, 8, ["onPageSizeChange", "onCurrentPage", "totalElements"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}