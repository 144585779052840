import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]
const _hoisted_3 = ["stroke"]
const _hoisted_4 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 12 12",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M6.79991 2.80005L9.29974 2.80032M6.79991 4.79992L9.29974 4.80018M6.79991 6.79979L9.29974 6.80005M5.3 6.79979H5.305M5.3 4.79992H5.305M5.3 2.80005H5.305",
      "stroke-width": "0.7",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M4.29991 9.29979L6.79974 9.30005M2.8 9.29979H2.805M2.8 7.29992H2.805M2.8 5.30005H2.805",
      "stroke-width": "0.7",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      d: "M3.5 3.5H3C2.29993 3.5 1.9499 3.5 1.68251 3.63624C1.44731 3.75608 1.25608 3.94731 1.13624 4.18251C1 4.4499 1 4.79993 1 5.5V9C1 9.70007 1 10.0501 1.13624 10.3175C1.25608 10.5527 1.44731 10.7439 1.68251 10.8638C1.9499 11 2.29993 11 3 11H6.5C7.20007 11 7.5501 11 7.81749 10.8638C8.05269 10.7439 8.24392 10.5527 8.36376 10.3175C8.5 10.0501 8.5 9.70007 8.5 9V8.5M5.5 8.5H9C9.70007 8.5 10.0501 8.5 10.3175 8.36376C10.5527 8.24392 10.7439 8.05269 10.8638 7.81749C11 7.5501 11 7.20007 11 6.5V3C11 2.29993 11 1.9499 10.8638 1.68251C10.7439 1.44731 10.5527 1.25608 10.3175 1.13624C10.0501 1 9.70007 1 9 1H5.5C4.79993 1 4.4499 1 4.18251 1.13624C3.94731 1.25608 3.75608 1.44731 3.63624 1.68251C3.5 1.9499 3.5 2.29993 3.5 3V6.5C3.5 7.20007 3.5 7.5501 3.63624 7.81749C3.75608 8.05269 3.94731 8.24392 4.18251 8.36376C4.4499 8.5 4.79993 8.5 5.5 8.5Z",
      "stroke-width": "0.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_4)
  ], 8, _hoisted_1))
}