import { QuestionTagsDTO } from '../../../../dtos/CreateListPage/QuestionTagsDTO'

export type SumPerArea = {
  areaName: string
  totalArea: number
  subjects: [
    {
      name: string
      total: number
    }
  ]
  difOnArea: number
}

export class SearchQuestions {
  includeAlreadyAnswered: boolean
  subjects: Array<AuxTree | null>
  listId?: number

  constructor(includeAlreadyAnswered: boolean, subjects: Array<AuxTree | null>, listId?: number) {
    this.includeAlreadyAnswered = includeAlreadyAnswered
    this.subjects = subjects
    this.listId = listId
  }
}

export class AuxTree {
  name?: string
  quantity?: number
  divisions?: Array<Partial<AuxTree>>
  filters?: QuestionTagsDTO

  constructor(name?: string, divisions?: Array<Partial<AuxTree>>, filters?: QuestionTagsDTO, quantity?: number) {
    this.name = name
    this.quantity = quantity
    this.divisions = divisions
    this.filters = filters
  }
}

export class Tree {
  name: string
  percentage: number
  children: Array<Tree>
  totalQuestions: number
  id: number
  parentId: number | null

  constructor(name: string, percentage: number, children: Array<Tree>, totalQuestions: number, id: number, parentId: number | null) {
    this.name = name
    this.children = children
    this.percentage = percentage
    this.totalQuestions = totalQuestions
    this.id = id
    this.parentId = parentId
  }
}
