import { helpers, minLength, required } from '@vuelidate/validators'

const messageRequired = 'Este campo é obrigatório'

const addressesRules = {
  zipcode: { required: helpers.withMessage(messageRequired, required), minLength: helpers.withMessage('CEP inválido', minLength(8)) },
  state: { required: helpers.withMessage(messageRequired, required) },
  city: { required: helpers.withMessage(messageRequired, required) },
  street: { required: helpers.withMessage(messageRequired, required) },
  houseNumber: { required: helpers.withMessage(messageRequired, required) },
  neighborhood: { required: helpers.withMessage(messageRequired, required) },
}

export default addressesRules
