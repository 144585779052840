import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7978dbce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inputs-wrapper" }
const _hoisted_2 = { class: "div-input" }
const _hoisted_3 = {
  key: 0,
  class: "grid-table"
}
const _hoisted_4 = {
  key: 1,
  class: "grid-table"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentModalAddReceivers = _resolveComponent("ContentModalAddReceivers")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_ViewingListSummary = _resolveComponent("ViewingListSummary")!
  const _component_ContentModalEditListName = _resolveComponent("ContentModalEditListName")!
  const _component_ModalConfirmation = _resolveComponent("ModalConfirmation")!
  const _component_SearchIcon = _resolveComponent("SearchIcon")!
  const _component_PazzeiInput = _resolveComponent("PazzeiInput")!
  const _component_PazzeiBtn = _resolveComponent("PazzeiBtn")!
  const _component_TablesComponentQuestions = _resolveComponent("TablesComponentQuestions")!
  const _component_PaginationTableComponent = _resolveComponent("PaginationTableComponent")!
  const _component_MainLayoutWrapper = _resolveComponent("MainLayoutWrapper")!
  const _component_GenericLayout = _resolveComponent("GenericLayout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Modal, {
      modelValue: _ctx.isModalAddReceiversOpen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isModalAddReceiversOpen) = $event)),
      height: _ctx.heightModalSmall + '10em',
      width: _ctx.width
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ContentModalAddReceivers, {
          assignListCreated: "",
          addPadding: "",
          showButton: "",
          onClose: _ctx.closeModal,
          getListsData: _ctx.getListsData,
          listId: _ctx.listId
        }, null, 8, ["onClose", "getListsData", "listId"])
      ]),
      _: 1
    }, 8, ["modelValue", "height", "width"]),
    _createVNode(_component_Modal, {
      modelValue: _ctx.isModalViewingListSummary,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isModalViewingListSummary) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ViewingListSummary, {
          listId: _ctx.listId,
          listName: _ctx.listName
        }, null, 8, ["listId", "listName"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_Modal, {
      modelValue: _ctx.isEditNameList,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isEditNameList) = $event)),
      height: _ctx.heightModalSmall,
      width: _ctx.widthModalSmall
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ContentModalEditListName, {
          modalTitle: "Renomear Lista",
          label: "Nome da lista",
          onClose: _ctx.closeModalEditName,
          listId: _ctx.listId,
          listExercices: _ctx.getListsData,
          sizeEditLoading: _ctx.sizeEditLoading
        }, null, 8, ["onClose", "listId", "listExercices", "sizeEditLoading"])
      ]),
      _: 1
    }, 8, ["modelValue", "height", "width"]),
    _createVNode(_component_ModalConfirmation, {
      modelValue: _ctx.isModalRemoveList,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isModalRemoveList) = $event)),
      label: "Tem certeza que deseja excluir esta lista?",
      info: "Ao confirmar, todas as respostas dos alunos, incluindo a lista respondida, serão excluídas permanentemente.",
      "click-confirm": _ctx.removeList
    }, null, 8, ["modelValue", "click-confirm"]),
    _createVNode(_component_GenericLayout, null, {
      default: _withCtx(() => [
        _createVNode(_component_MainLayoutWrapper, null, {
          nav: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_PazzeiInput, {
                  mobileSchool: "",
                  "max-length": 80,
                  placeholder: 'Buscar lista',
                  "hide-bottom-space": "",
                  "search-list": "",
                  "input-collapsed": _ctx.isSearchInputExpanded,
                  modelValue: _ctx.filterField,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filterField) = $event))
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_SearchIcon, {
                      size: 32,
                      fill: _ctx.DefaultTheme().colors.primary,
                      style: {"padding-left":"15px"},
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isSearchInputExpanded = !_ctx.isSearchInputExpanded))
                    }, null, 8, ["fill"])
                  ]),
                  _: 1
                }, 8, ["input-collapsed", "modelValue"])
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["btn-container", { 'btn-pazzei__collapsed': !_ctx.isSearchInputExpanded }])
              }, [
                _createVNode(_component_PazzeiBtn, {
                  icon: _ctx.MountListsIcon,
                  iconFill: _ctx.DefaultTheme().colors.whiteColor,
                  label: "Montar Lista",
                  fill: "",
                  class: _normalizeClass(["btn-pazzei", { 'btn-pazzei__collapsed': !_ctx.isSearchInputExpanded }]),
                  "btn-collapsed": !_ctx.isSearchInputExpanded,
                  onClick: _ctx.handleCreateLists
                }, null, 8, ["icon", "iconFill", "class", "btn-collapsed", "onClick"])
              ], 2)
            ])
          ]),
          main: _withCtx(() => [
            (_ctx.userPermissionRoot)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_TablesComponentQuestions, {
                    columns: _ctx.columnsRoot,
                    rows: _ctx.rowsLists,
                    sizeColumnsTable: _ctx.sizeColumnListsRoot,
                    showOptionsMenu: "",
                    isLoading: _ctx.isLoading,
                    sizeLoading: _ctx.sizeLoading,
                    heightTable: _ctx.heightTable,
                    widthTable: _ctx.widthTable
                  }, null, 8, ["columns", "rows", "sizeColumnsTable", "isLoading", "sizeLoading", "heightTable", "widthTable"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_TablesComponentQuestions, {
                    columns: _ctx.columnsProfessor,
                    rows: _ctx.rowsLists,
                    sizeColumnsTable: _ctx.sizeColumnLists,
                    showOptionsMenu: "",
                    isLoading: _ctx.isLoading,
                    sizeLoading: _ctx.sizeLoading,
                    heightTable: _ctx.heightTable,
                    widthTable: _ctx.widthTable
                  }, null, 8, ["columns", "rows", "sizeColumnsTable", "isLoading", "sizeLoading", "heightTable", "widthTable"])
                ]))
          ]),
          footer: _withCtx(() => [
            _createVNode(_component_PaginationTableComponent, {
              onPageSizeChange: _ctx.handlePageSizeChange,
              onCurrentPage: _ctx.handleCurrentPage,
              totalElements: _ctx.totalElements
            }, null, 8, ["onPageSizeChange", "onCurrentPage", "totalElements"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}