
import Button from '@/components/Library/Button/index.vue'
import { defineComponent, onMounted, onUnmounted, onUpdated, PropType, ref, watch } from 'vue'
import { ArrayOfButtonsType } from './helpers/types'

export default defineComponent({
  name: 'MultipleActions',
  inheritAttrs: false,
  props: {
    hideIcon: { type: Boolean, default: false },
    modelValue: { type: Boolean, default: true, required: false },
    preventClosing: { type: Boolean, default: false },
    variant: { type: String as PropType<'default'>, default: 'default' },
    title: { type: String, default: '' },
    info: { type: String, default: '' },
    auxiliaryInfo: { type: String, default: '' },
    arrayOfButtons: { type: Array as PropType<ArrayOfButtonsType[]>, required: true },
  },
  components: {
    Button,
  },
  setup(props, { emit }) {
    const modalRef = ref<HTMLDivElement | null>(null)
    const checkboxModel = ref(false)

    const initModal = () => {
      modalRef.value?.focus()
      modalRef.value?.addEventListener('keyup', handlePressingEsc)
    }

    const handlePressingEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') handleClose()
    }

    const handleClose = () => {
      if (!props.preventClosing) {
        emit('update:modelValue', false)
      }
    }

    onMounted(initModal)
    onUpdated(initModal)
    onUnmounted(() => modalRef.value?.removeEventListener('keyup', handlePressingEsc))

    watch(checkboxModel, () => {
      if (checkboxModel.value) localStorage.setItem('dontShowAgain', String(checkboxModel.value))
    })

    return { modalRef, handleClose, checkboxModel }
  },
})
