type DataColumn = {
  label: string
  key: string
}

export const usersCoupon: DataColumn[] = [
  { key: 'name', label: 'Nome do Usuário' },
  { key: 'plan', label: 'Plano' },
  { key: 'createdAt', label: 'Data da compra' },
  { key: 'fullPrice', label: 'Preço total' },
  { key: 'discount', label: 'Valor do desconto' },
  { key: 'price', label: 'Preço final' },
]
