export const largeAreas = [
  {
    id: 'languagesAndCodesActive',
    icon: 'bookClosed',
    title: 'Linguagens, Códigos e suas Tecnologias',
    subtitle: 'Artes, Língua Estrangeira, Língua Portuguesa e Literatura',
    subjectsOnArea: ['Artes', 'Inglês', 'Espanhol', 'Português'],
    subjectsWithEnglish: ['Artes', 'Inglês', 'Português'],
    subjectsWithSpanish: ['Artes', 'Espanhol', 'Português'],
    hasForeignLanguage: true,
    order: 1,
  },
  {
    id: 'humanitiesAndTechnologiesActive',
    icon: 'globe',
    title: 'Ciências Humanas e suas Tecnologias',
    subtitle: 'Filosofia, Geografia, História e Sociologia',
    subjectsOnArea: ['Filosofia', 'Geografia', 'História', 'Sociologia'],
    hasForeignLanguage: false,
    order: 2,
  },
  {
    id: 'naturalSciencesAndTechnologiesActive',
    icon: 'atom',
    title: 'Ciências da Natureza e suas Tecnologias',
    subtitle: 'Biologia, Física e Química',
    subjectsOnArea: ['Biologia', 'Física', 'Química'],
    hasForeignLanguage: false,
    order: 3,
  },
  {
    id: 'mathematicsAndTechnologiesActive',
    icon: 'calculator',
    title: 'Matemática e suas Tecnologias',
    subtitle: 'Álgebra, Geometria, Matemática Básica e Trigonometria',
    subjectsOnArea: ['Matemática'],
    hasForeignLanguage: false,
    order: 4,
  },
]

export const subjectsOrder = ['Artes', 'Espanhol', 'Inglês', 'Português', 'Literatura', 'Filosofia', 'Geografia', 'História', 'Sociologia', 'Biologia', 'Física', 'Química', 'Matemática']
