
import { defineComponent, ref } from 'vue';
import GenericLayout from '../Layouts/GenericLayout.vue';
import MainLayoutWrapper from '../Layouts/MainLayoutWrapper.vue';
import PazzeiDashboard from '../PowerBi/PazzeiDashboard.vue';
import PazzeiDashboardAlunos from '../PowerBiListAlunos/PazzeiDashboardAlunos.vue';

export default defineComponent({
  name: 'TabsBi',
  components: {
    GenericLayout,
    MainLayoutWrapper,
    PazzeiDashboard,
    PazzeiDashboardAlunos,
  },
  setup() {

    const tabs = ref(['Listas compartilhadas', 'Listas dos alunos']);
    const activeTab = ref(0);

    const selectTab = (index: number) => {
      activeTab.value = index; 
    };

    return {
      tabs,
      activeTab,
      selectTab,
    };
  },
});
