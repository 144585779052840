import DefaultTheme from '@/theme/defaultTheme'
import { ApexOptions } from 'apexcharts'
import { SubjectTreeCollapse } from '../../types/SubjectTreeCollapseType'

const setBarGraphicInfos = (subjectTreeCollapse: SubjectTreeCollapse): ApexOptions => {
  const divisions = subjectTreeCollapse.children || []

  const sortedDivisions = divisions.slice().sort((a, b) => b.maxQuestions - a.maxQuestions)

  const categories = sortedDivisions.map((division) => division.name)
  const data = sortedDivisions.map((division) => division.maxQuestions)

  let show = true
  if (!data.length) show = false

  const options = {
    chart: {
      id: 'vuechart-example',
      toolbar: {
        show: false,
      },
    },
    fill: {
      colors: [DefaultTheme().colors.primary800],
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: [DefaultTheme().colors.gray800],
      },
      offsetX: 50,
    },
    xaxis: {
      categories: categories ? categories : [],
      axisTicks: { show: false },
    },
    yaxis: {
      show: show,
    },
    series: [
      {
        name: 'Quantidade',
        data,
      },
    ],
  }

  return options
}

export default setBarGraphicInfos
