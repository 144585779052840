import { ref, watch } from 'vue';

export interface UseFaviconOptions {
  baseUrl?: string;
  rel?: string;
}

export function useFavicon(newIcon: string | null | undefined, options?: UseFaviconOptions) {
  const { baseUrl = '', rel = 'icon' } = options || {};
  
  const favicon = ref(newIcon);

  const applyIcon = (icon: string) => {
    const elements = document.head.querySelectorAll<HTMLLinkElement>(`link[rel*="${rel}"]`);
    
    if (!elements.length) {
      const link = document.createElement('link');
      link.rel = rel;
      link.href = `${baseUrl}${icon}`;
      link.type = `image/${icon.split('.').pop()}`; 
      document.head.append(link);
    } else {
      elements.forEach(el => el.href = `${baseUrl}${icon}`);
    }
  };

  watch(favicon, (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      applyIcon(newValue);
    }
  }, { immediate: true });

  return favicon;
}
