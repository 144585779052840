
import Button from '@/components/Library/Button/index.vue'
import Modal from '@/components/Library/Modal/index.vue'
import password from '@/utils/vuelidateRules/passwordRules'
import useVuelidate from '@vuelidate/core'
import { helpers, required, sameAs } from '@vuelidate/validators'
import { AxiosResponse } from 'axios'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { computed, defineComponent, onMounted, onUnmounted, onUpdated, reactive, ref, watch } from 'vue'
import { EditPasswordDTO } from '../../dtos/EditUserInfosDTO'
import { LoginResponseDTO } from '../../dtos/ResponseDTOs/LoginResponseDTO'
import { ResponseDTO } from '../../dtos/ResponseDTOs/ResponseDTO'
import { UserInfosDTO } from '../../dtos/UserInfosDTO'
import authService from '../../services/authService'
import authenticationService from '../../services/authentication'
import router from '../../router'

export default defineComponent({
  name: 'ModalChangePassword',
  emits: ['update:visibility'],
  props: {
    visibility: { type: Boolean, default: false, required: true },
    email: { type: String, required: false },
    redirect: { type: Boolean, default: false, required: false },
    description: { type: String, required: false },
    notClosable: { type: Boolean, default: false, required: false },
  },
  components: { Modal, Button },
  setup(props, { emit }) {
    const $q = useQuasar()
    const modalVisibility = ref(props.visibility)
    const isLoading = ref(false)
    const userInfos = ref(new UserInfosDTO('', 0, '', '', '', '', '', ''))
    const readonly = ref(true)
    const keeplogged = localStorage.getItem('keeplogged') === 'true'

    const changePassword = reactive({
      currentPassword: '',
      password: '',
      newPasswordConfirmation: '',
    })

    const passwordConfirmation = computed(() => {
      return {
        currentPassword: {
          required: helpers.withMessage('Este campo é obrigatório', required),
        },
        password,
        newPasswordConfirmation: {
          required: helpers.withMessage('Este campo é obrigatório', required),
          sameAs: helpers.withMessage('As senhas devem coincidir', sameAs(changePassword.password)),
        },
      }
    })

    const passwordConfirmation$ = useVuelidate(passwordConfirmation, changePassword)

    const cleanChanges = () => {
      changePassword.currentPassword = ''
      changePassword.password = ''
      changePassword.newPasswordConfirmation = ''
      modalVisibility.value = false
    }

    const submitPassword = async () => {
      const result = await passwordConfirmation$.value.$validate()

      if (result) {
        isLoading.value = true
        const token = localStorage.getItem('token')
        const changeData = !props.redirect
          ? new EditPasswordDTO(changePassword.password, changePassword.currentPassword, keeplogged)
            : new EditPasswordDTO(changePassword.password, changePassword.currentPassword, keeplogged, true, new Date())

        authenticationService
          .patch('/', changeData, { headers: { Authorization: `Bearer ${token}` } })
          .then((res: AxiosResponse<ResponseDTO<LoginResponseDTO>>) => {
            authService.setUserOnLocal(res.data.data.token, res.data.data.refreshToken)

            $q.notify({
              textColor: 'grey-1',
              message: 'Dados salvos com sucesso',
              color: 'green',
              position: 'top',
              classes: 'notify',
            })

            cleanChanges()

            if (props.redirect) {
              router.push('/')
             }
          })
          .catch((err) => console.error(err))
          .finally(() => (isLoading.value = false))
      }
    }

    const innerWidth = ref(window.innerWidth)
    const width = ref('34.5em')
    const height = ref('35.5em')

    const setNewTab = () => {
      innerWidth.value = window.innerWidth
    }

    window.addEventListener('resize', setNewTab)

    onMounted(() => {
      if (innerWidth.value <= 768) {
        width.value = '90%'
        height.value = '60%'
      }
    })

    onUnmounted(() => window.removeEventListener('resize', setNewTab))

    watch(
      changePassword,
      async () => {
        passwordConfirmation$.value.$validate()
      },
      { deep: true }
    )

    watch(
      () => modalVisibility.value,
      () => {
        emit('update:visibility', modalVisibility.value)
      }
    )

    onUpdated(() => {
      if (modalVisibility.value !== props.visibility) modalVisibility.value = props.visibility
    })

    return {
      passwordConfirmation$,
      submitPassword,
      userInfos,
      isLoading,
      changePassword,
      modalVisibility,
      isPwd: ref(true),
      isPwdOk: ref(true),
      isPwdCurrent: ref(true),
      readonly,
      cancelChanges: cleanChanges,
      width,
      height,
    }
  },
})
