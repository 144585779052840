
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue';
import router from '@/router';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'ModalCongrats',
  emits: ['close'],
  props: {
    showFinishFeedback: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PazzeiBtn,
  },
  setup() {
    const { id, tryId } = useRoute().params
    const isLoading = ref(false)

    const returnToHomePage = () => router.go(-1)

    const goToFeedbackPage = () => router.push(`/gabarito/${id}/${tryId}`)

    return {
      isLoading,
      returnToHomePage,
      goToFeedbackPage,
    }
  },
})
