import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b6f7349"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "div-loading"
}
const _hoisted_2 = { class: "hello-container" }
const _hoisted_3 = { class: "hello" }
const _hoisted_4 = {
  key: 0,
  class: "container no-farewell"
}
const _hoisted_5 = { class: "container choice-container" }
const _hoisted_6 = {
  key: 0,
  class: "choice-heading"
}
const _hoisted_7 = { class: "wrapper" }
const _hoisted_8 = { class: "container-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_circular_progress = _resolveComponent("q-circular-progress")!
  const _component_TrialCard = _resolveComponent("TrialCard")!
  const _component_NoFarewell = _resolveComponent("NoFarewell")!
  const _component_PazzeiButton = _resolveComponent("PazzeiButton")!
  const _component_Card = _resolveComponent("Card")!
  const _component_MainLayoutWrapper = _resolveComponent("MainLayoutWrapper")!
  const _component_GenericLayout = _resolveComponent("GenericLayout")!

  return (_openBlock(), _createBlock(_component_GenericLayout, null, {
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_q_circular_progress, {
              indeterminate: "",
              size: '40px',
              color: "secondary"
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, " Oi, " + _toDisplayString(_ctx.userName) + " :) ", 1)
      ]),
      _createVNode(_component_TrialCard, {
        onExpiringTrial: _ctx.handleNoFarewellComponent,
        onSetTrialInfo: _ctx.setTrialInfo
      }, null, 8, ["onExpiringTrial", "onSetTrialInfo"]),
      _createVNode(_component_MainLayoutWrapper, null, {
        main: _withCtx(() => [
          (_ctx.showNoFarewellComponent)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_NoFarewell)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.showChoiceHeading)
              ? (_openBlock(), _createElementBlock("h4", _hoisted_6, _toDisplayString(_ctx.trialInfo.headingMessage), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardsContentToRender, (card) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: card.id
                }, [
                  _createVNode(_component_Card, { variant: "shaded-border" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("p", null, _toDisplayString(card.description), 1),
                        _createVNode(_component_PazzeiButton, {
                          classes: `with-background-color ${card?.className === 'secondary-btn' ? 'secondary-btn' : 'primary'}`,
                          text: card.buttonTitle,
                          alt: card.buttonTitle,
                          style: 'width: fit-content;',
                          "text-classes": { text: 'text-14px', fontWeight: 'font-weight-600'},
                          onClick: ($event: any) => (_ctx.router.push(card.goTo))
                        }, null, 8, ["classes", "text", "alt", "onClick"])
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]))
              }), 128))
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}