
import { defineComponent } from 'vue';
import PazzeiBtn from '../Generics/PazzeiBtn.vue';

export default defineComponent({
  components: { PazzeiBtn },
  props: {
    isMonthlyPlan: {
      type: Boolean,
      required: true,
    },
  },
  setup(_, { emit }) {
    const handleClick = () => {
      emit('generateQrCode', true)
    }

    return {
      handleClick,
    }
  },
})
