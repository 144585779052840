import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6501de08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "text-primary font-bold modal-title" }
const _hoisted_3 = { class: "modal-content-info" }
const _hoisted_4 = { class: "text-secundary text-size-14" }
const _hoisted_5 = { class: "text-secundary text-size-14" }
const _hoisted_6 = {
  key: 0,
  class: "model-content-checkbox"
}
const _hoisted_7 = { class: "modal-content-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.info), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.auxiliaryInfo), 1),
      (_ctx.auxiliaryInfo)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_q_checkbox, {
              modelValue: _ctx.checkboxModel,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkboxModel) = $event)),
              label: "Não mostrar novamente"
            }, null, 8, ["modelValue"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arrayOfButtons, (button, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(`button--${button.position}`)
        }, [
          _createVNode(_component_Button, {
            label: button.label,
            variant: button.variant,
            onClick: ($event: any) => (!button.loading ? button.click() : null),
            width: button.width,
            loading: button.loading,
            disabled: button.disabled
          }, null, 8, ["label", "variant", "onClick", "width", "loading", "disabled"])
        ], 2))
      }), 128))
    ])
  ]))
}