
import PazzeiButton from '@/components/Generics/Redesign/PazzeiButton.vue';
import PazzeiModalDoodle from '@/components/Generics/Redesign/PazzeiModal/PazzeiModalDoodle.vue';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'PazzeiModalDoodleWrapper',
  components: { PazzeiButton, PazzeiModalDoodle },
  props: {
    context: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    confirmButtonText: {
      type: String,
      required: true,
    },
    confirmButtonVariant: {
      type: String,
      required: true,
    },
    cancelButtonText: {
      type: String,
      required: true,
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'on-confirm', 'on-cancel'],
  setup(props, { emit }) {
    const showModal = ref(true);

    const handleConfirm = () => {
      emit('on-confirm');
    };

    const handleCancel = () => {
      emit('on-cancel');
    };

    return {
      showModal,
      handleConfirm,
      handleCancel,
    };
  },
});
