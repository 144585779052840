export class PlanSchoolBaseUserDTO {
  name: string
  lastName?: string | null
  email: string
  roleDescription: string
  credits?: number

  constructor(name: string, email: string, roleDescription: string, credits?: number, lastName?: string) {
    this.name = name
    this.email = email
    this.roleDescription = roleDescription
    this.credits = credits
    this.lastName = lastName
  }
}

export class PlanSchoolUserDTO extends PlanSchoolBaseUserDTO {
  plan?: string
  id?: string
  institutionId?: number
  lastName?: string | null
  isRegistrationCompleted?: boolean
  autoRenew?: boolean

  constructor(name: string, email: string, roleDescription: string, id?: string, institutionId?: number, lastName?: string | null, autoRenew?: boolean) {
    super(name, email, roleDescription)
    this.id = id
    this.lastName = lastName !== '' ? lastName : null
    this.autoRenew = autoRenew
    if (institutionId && institutionId > 0) this.institutionId = institutionId
  }
}

export class PlanSchoolCreateUserDTO extends PlanSchoolBaseUserDTO {
  institutionId?: number

  constructor(name: string, email: string, roleDescription: string, credits?: number, institutionId?: number) {
    super(name, email, roleDescription, credits)
    this.institutionId = institutionId
  }
}
