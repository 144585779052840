type DataColumn = {
  label: string
  key: string
}

export const columnsCoupon: DataColumn[] = [
  { key: 'id', label: 'ID' },
  { key: 'code', label: 'Código' },
  { key: 'description', label: 'Descrição' },
  { key: 'typeId', label: 'Tipo de Desconto' },
  { key: 'value', label: 'Desconto' },
  { key: 'usageLimit', label: 'Limite de Uso' },
  { key: 'usedCoupons', label: 'Utilizados' },
  { key: 'expiresAt', label: 'Data de Expiração' },
  { key: 'isDisabled', label: 'Status' },
]
