import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04245109"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "q-avatar-span" }
const _hoisted_2 = { class: "list-summary-header-list" }
const _hoisted_3 = {
  class: "list-summary-header-list-name",
  style: {"line-height":"1em"}
}
const _hoisted_4 = {
  class: "list-summary-header-list-amount",
  style: {"line-height":"1em"}
}
const _hoisted_5 = { class: "listing-summary" }
const _hoisted_6 = { class: "list-summary-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GroupIcon = _resolveComponent("GroupIcon")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_circular_progress = _resolveComponent("q-circular-progress")!
  const _component_ListSummaryCard = _resolveComponent("ListSummaryCard")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ListSummaryLayout = _resolveComponent("ListSummaryLayout")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.open) = $event)),
    "prevent-closing": _ctx.isCreatingList,
    variant: "list-summary",
    height: _ctx.height,
    width: _ctx.width
  }, {
    "list-summary": _withCtx(() => [
      _createVNode(_component_q_circular_progress, {
        color: "primary",
        class: "q-circ-progress",
        "font-size": "0.15em",
        size: "5em",
        "show-value": "",
        thickness: 0.17,
        "track-color": "grey-3",
        value: 0
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_avatar, {
            size: "4.5em",
            style: _normalizeStyle({ backgroundColor: _ctx.listSummaryInfos.color })
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, [
                _createVNode(_component_GroupIcon, {
                  fill: _ctx.DefaultTheme().colors.whiteColor
                }, null, 8, ["fill"])
              ])
            ]),
            _: 1
          }, 8, ["style"])
        ]),
        _: 1
      }, 8, ["thickness"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_ListSummaryLayout, null, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.listSummaryInfos.listName), 1),
            _createElementVNode("h2", _hoisted_4, "Total de questões: " + _toDisplayString(_ctx.totalListQuestions), 1)
          ])
        ]),
        content: _withCtx(() => [
          _createElementVNode("ul", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listSummaryInfos.summary, (item, key) => {
              return (_openBlock(), _createElementBlock("li", {
                key: key,
                class: "listing-item"
              }, [
                _createVNode(_component_ListSummaryCard, {
                  filterOptions: {
                subject: key,
                amountQuestions: item.total,
                filter: { levels: item.filters.levels, types: item.filters.types, years: item.filters.years, source: item.filters.sources },
              }
                }, null, 8, ["filterOptions"])
              ]))
            }), 128))
          ])
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Button, {
              label: "Editar",
              variant: "secondary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.isCreatingList ? (_ctx.open = false) : null)),
              width: "50%"
            }),
            _createVNode(_component_Button, {
              label: "Confirmar",
              loading: _ctx.isCreatingList,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('confirm'), (_ctx.isCreatingList = true))),
              width: "50%"
            }, null, 8, ["loading"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "prevent-closing", "height", "width"]))
}