import { email, helpers, minLength, required } from '@vuelidate/validators'

const messageRequired = 'Este campo é obrigatório'

export const firstTabRules = (isEditing: boolean) => {
  const applyRequired = () => (isEditing ? {} : { required: helpers.withMessage(messageRequired, required) })

  return {
    name: applyRequired(),
    corporateName: applyRequired(),
    managerName: applyRequired(),
    totalStudent: applyRequired(),
    cnpj: {
      ...applyRequired(),
      minLength: helpers.withMessage('Insira um cnpj válido', minLength(14)),
    },
    email: {
      ...applyRequired(),
      regex: helpers.withMessage('Insira um e-mail válido', email),
    },
    telephone: {
      ...applyRequired(),
      minLength: helpers.withMessage('Informe um número de telefone válido', minLength(11)),
    },
  }
}

export const secondTabRules = (isEditing: boolean) => {
  const applyRequired = () => (isEditing ? {} : { required: helpers.withMessage(messageRequired, required) })

  return {
    address: applyRequired(),
    cep: {
      ...applyRequired(),
      minLength: helpers.withMessage('CEP inválido', minLength(8)),
    },
    state: applyRequired(),
    number: applyRequired(),
    city: applyRequired(),
  }
}
