export const filtersUser = [
  {
    type: 'input',
    placeholder: 'Pesquisar',
    width: '100%',
    maxLength: 80,
    value: '',
    key: 'name',
  },
  {
    type: 'input',
    placeholder: 'Pesquisar',
    width: '100%',
    maxLength: 15,
    value: '',
    key: 'telephone',
  },
  {
    type: 'input',
    placeholder: 'Pesquisar',
    width: '100%',
    maxLength: 80,
    value: '',
    key: 'email',
  },
  {
    type: 'select',
    placeholder: 'Selecione',
    options: ['Diretor', 'Professor', 'Administrador', 'Aluno'],
    value: '',
    key: 'roleDescription',
  },
  {
    type: 'select',
    placeholder: 'Selecione',
    options: ['Ativo', 'Inativo'],
    value: '',
    key: 'isActive',
  },
]

export const filtersUserInstitution = [
  {
    type: 'input',
    placeholder: 'Pesquisar',
    width: '100%',
    maxLength: 80,
    value: '',
    key: 'readableId',
  },
  {
    type: 'input',
    placeholder: 'Pesquisar',
    width: '100%',
    maxLength: 80,
    value: '',
    key: 'name',
  },
  {
    type: 'input',
    placeholder: 'Pesquisar',
    width: '100%',
    maxLength: 15,
    value: '',
    key: 'telephone',
  },
  {
    type: 'input',
    placeholder: 'Pesquisar',
    width: '100%',
    maxLength: 80,
    value: '',
    key: 'email',
  },
  {
    type: 'select',
    placeholder: 'Selecione',
    options: ['Diretor', 'Professor', 'Administrador', 'Aluno'],
    value: '',
    key: 'roleDescription',
  },
  {
    type: 'date',
    placeholder: 'Pesquisar',
    width: '100%',
    maxLength: 15,
    value: '',
    key: 'createdAt',
  },
  {
    type: 'select',
    placeholder: 'Selecione',
    options: ['Ativo', 'Inativo'],
    value: '',
    key: 'isActive',
  },
]

export const columnsUser: Array<{ label: string; key: string }> = [
  { key: 'name', label: 'Nome' },
  { key: 'telephone', label: 'Telefone' },
  { key: 'email', label: 'E-mail' },
  { key: 'roleDescription', label: 'Permissão' },
  { key: 'isActive', label: 'Situação' },
]

export const columnsUserInstitution: Array<{ label: string; key: string }> = [
  { key: 'readableId', label: 'ID' },
  { key: 'name', label: 'Nome' },
  { key: 'telephone', label: 'Telefone' },
  { key: 'email', label: 'E-mail' },
  { key: 'roleDescription', label: 'Permissão' },
  { key: 'createdAt', label: 'Data de Registro' },
  { key: 'isActive', label: 'Situação' },
]
