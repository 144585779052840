import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("rect", {
      width: "24",
      height: "24",
      rx: "12",
      fill: _ctx.DefaultTheme().colors.error100
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M16.5 11H7.5M14 7V9M10 7V9M9.9 17H14.1C14.9401 17 15.3601 17 15.681 16.8365C15.9632 16.6927 16.1927 16.4632 16.3365 16.181C16.5 15.8601 16.5 15.4401 16.5 14.6V10.4C16.5 9.55992 16.5 9.13988 16.3365 8.81901C16.1927 8.53677 15.9632 8.3073 15.681 8.16349C15.3601 8 14.9401 8 14.1 8H9.9C9.05992 8 8.63988 8 8.31901 8.16349C8.03677 8.3073 7.8073 8.53677 7.66349 8.81901C7.5 9.13988 7.5 9.55992 7.5 10.4V14.6C7.5 15.4401 7.5 15.8601 7.66349 16.181C7.8073 16.4632 8.03677 16.6927 8.31901 16.8365C8.63988 17 9.05992 17 9.9 17Z",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_3)
  ], 8, _hoisted_1))
}