
import authService from '@/services/authService'
import DefaultTheme from '@/theme/defaultTheme'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, inject, onMounted, onUpdated, provide, Ref, ref, watch } from 'vue'
import { ResponseDTO } from '../../dtos/ResponseDTOs/ResponseDTO'
import { TagsInterfaceDTO } from '../../dtos/ResponseDTOs/TagsParamsDTO'
import listsService from '../../services/list'
import questionsService from '../../services/question'
import InstitutionComponent from '../Application/entranceExamPage/TestDataBaseModal/InstitutionComponent/index.vue'
import RegionComponent from '../Application/entranceExamPage/TestDataBaseModal/RegionComponent/index.vue'
import StateComponent from '../Application/entranceExamPage/TestDataBaseModal/StateComponent/index.vue'
import Stepper from '../Application/entranceExamPage/TestDataBaseModal/Stepper/index.vue'
import YearComponent from '../Application/entranceExamPage/TestDataBaseModal/YearComponent/index.vue'
import ContentModalAddReceivers from '../Application/planSchoolPortal/modals/modalCreateListExercices/ContentModalAddReceivers.vue'
import Button from '../Library/Button/index.vue'
import Modal from '../Library/Modal/index.vue'
import Steps from './Steps.vue'

export default defineComponent({
  name: 'EntranceExamContent',
  components: {
    ContentModalAddReceivers,
    Steps,
    Button,
    Modal,
    InstitutionComponent,
    StateComponent,
    Stepper,
    YearComponent,
    RegionComponent,
  },
  props: {
    modelValue: { type: Boolean, default: false },
  },

  setup(props, { emit }) {
    const tags = ref<any>({
      region: [],
      source: [],
      state: [],
      year: [],
      subject: [],
    })
    const modalState: Ref<boolean> = ref<boolean>(props.modelValue)
    const titles = ref<Array<{ item: string; isDone: boolean }>>([
      { item: 'Região', isDone: true },
      { item: 'Estado', isDone: false },
      { item: 'Instituição', isDone: false },
      { item: 'Ano', isDone: false },
    ])
    const ctrlErrorModal = ref({ message: '', requestError: false, sucess: false })
    const filtersSelected = ref({
      region: '',
      source: '',
      state: '',
      year: '',
      subject: '',
    })
    const choices = ref<Array<string>>([])
    const choicesCache = ref<Array<string>>([])
    const viewComponent = ref('RegionComponent')
    const isLoading = ref(false)
    const reloadLists = inject<() => void>('loadLists')
    const reloadListsViewGroupOrRoot = inject<() => void>('getListsData')
    const $q = useQuasar()
    const filtersNotFound = ref(false)
    const optionSelected = ref('')
    const blockButton = ref(true)
    const userPermissionGroupOrRoot = ref(false)
    const isAddReceivers = ref(false)
    const expirationDate = ref<string>('')
    const showOption = ref(false)
    const showOptionSeeFeedback = (value: boolean) => {
      showOption.value = value
    }
    const dateTimeHandler = (newDate: string) => {
      expirationDate.value = newDate
    }

    const selectedGroups = ref<Array<{ id: string; name: string }>>([])
    const selectedStudents = ref<Array<{ id: string; name: string }>>([])
    const setSelectedGroups = (groups: Array<{ id: string; name: string }>) => (selectedGroups.value = groups)
    const setSelectedStudents = (students: Array<{ id: string; name: string }>) => (selectedStudents.value = students)

    provide('selectedGroups', { selectedGroups, setSelectedGroups })
    provide('selectedStudents', { selectedStudents, setSelectedStudents })

    const getTagsBasedOnIds = async (option: string, paramToSearch: string) => {
      isLoading.value = true
      type Filters = {
        filter?: Record<string, Array<string>>
        includes?: Array<string>
      }
      let filters: Filters = {}

      if (option) {
        filters.filter = { [paramToSearch]: [option] }

        if (paramToSearch === 'regions') filters.includes = ['states']
        else if (paramToSearch === 'states') filters.includes = ['sources']
        else if (paramToSearch === 'sources') filters.includes = ['yearsWithPhase', 'subjects']
      } else filters.includes = ['regions']

      const { data } = await questionsService.post<TagsInterfaceDTO>('/tags-search-params', filters)
      isLoading.value = false
      return data.data
    }

    const getOptionSelected = async (option: string) => {
      optionSelected.value = option
      blockButton.value = false
      if (viewComponent.value === 'RegionComponent') {
        filtersSelected.value.region = option
        choices.value[0] = option
        choicesCache.value[0] = option
      } else if (viewComponent.value === 'StateComponent') {
        filtersSelected.value.state = option
        choices.value[1] = option
        titles.value[1].isDone = true
        choicesCache.value[1] = option
      } else if (viewComponent.value === 'InstitutionComponent') {
        filtersSelected.value.source = option
        choices.value[2] = option.replace('G1 - ', '')
        titles.value[2].isDone = true
        choicesCache.value[2] = option
      } else {
        filtersSelected.value.year = option
        choices.value[3] = option
        titles.value[3].isDone = true
        choicesCache.value[3] = option
      }
    }

    const handleLabelButton = (view: string) => {
      if (view === 'RegionComponent') return { primary: 'Avançar', secondary: 'Cancelar' }
      else if (view === 'InstitutionComponent' || view === 'StateComponent') return { primary: 'Avançar', secondary: 'Voltar' }
      else if (view === 'YearComponent' && !isAddReceivers.value && userPermissionGroupOrRoot.value) return { primary: 'Enviar para', secondary: 'Voltar' }
      else return { primary: 'Gerar Prova', secondary: 'Voltar' }
    }

    const changeStepsTo = (goToStep: string) => {
      blockButton.value = true
      filtersNotFound.value = false

      if (goToStep === 'Região') {
        if (choicesCache.value[0]) {
          blockButton.value = false
          optionSelected.value = choicesCache.value[0]
        }

        viewComponent.value = 'RegionComponent'
        titles.value[1].isDone = false
        titles.value[2].isDone = false
        titles.value[3].isDone = false
        choices.value.length = 1
      } else if (goToStep === 'Estado') {
        viewComponent.value = 'StateComponent'
        if (choicesCache.value[1]) {
          blockButton.value = false
          optionSelected.value = choicesCache.value[1]
        }

        titles.value[2].isDone = false
        titles.value[3].isDone = false
        choices.value.length = 2
      } else if (goToStep === 'Instituição') {
        if (choicesCache.value[2]) {
          blockButton.value = false
          optionSelected.value = choicesCache.value[2]
        }

        viewComponent.value = 'InstitutionComponent'
        titles.value[3].isDone = false
        choices.value.length = 3
      } else {
        if (choicesCache.value[3]) {
          blockButton.value = false
          optionSelected.value = choicesCache.value[3]
        }

        viewComponent.value = 'YearComponent'
        choices.value.length = 4
      }
    }
    const handleSecondaryButtonAction = (view: string) => {
      blockButton.value = true
      filtersNotFound.value = false

      if (view === 'RegionComponent') {
        modalState.value = false
      } else if (view === 'StateComponent') {
        if (choicesCache.value[0]) {
          blockButton.value = false
          optionSelected.value = choicesCache.value[0]
        }

        titles.value[1].isDone = false
        choices.value = choicesCache.value.slice(0, 1)
        filtersSelected.value.state = ''
        viewComponent.value = 'RegionComponent'
      } else if (view === 'InstitutionComponent') {
        if (choicesCache.value[1]) {
          blockButton.value = false
          optionSelected.value = choicesCache.value[1]
        }

        titles.value[2].isDone = false
        choices.value = choicesCache.value.slice(0, 2)
        filtersSelected.value.source = ''
        viewComponent.value = 'StateComponent'
      } else if (isAddReceivers.value) {
        if (choicesCache.value[3]) {
          blockButton.value = false
          optionSelected.value = choicesCache.value[3]
        }

        isAddReceivers.value = false
      } else {
        if (choicesCache.value[2]) {
          blockButton.value = false
          optionSelected.value = choicesCache.value[2]
        }

        titles.value[3].isDone = false
        choices.value = choicesCache.value.slice(0, 3)
        filtersSelected.value.year = ''
        viewComponent.value = 'InstitutionComponent'
      }
    }

    const handleCreateList = async () => {
      isLoading.value = true

      if (!filtersSelected.value.year) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Selecione um ano',
          color: 'yellow-8',
          position: 'top',
          classes: 'notify',
        })
        isLoading.value = false
        return
      }

      try {
        const { data } = await questionsService.post<ResponseDTO<Array<number>>>('/', {
          subjects: tags.value.subject.map((it: string) => ({
            name: it,
            quantity: 200,
            divisions: [],
            filters: {
              sources: [filtersSelected.value.source],
              yearsWithPhase: [filtersSelected.value.year],
              states: [filtersSelected.value.state],
            },
          })),
        })
        if (!data.data.length) {
          $q.notify({
            textColor: 'grey-1',
            message: 'Não encontramos questões para o filtro escolhido. Escolha outro ano.',
            color: 'yellow-8',
            position: 'top',
            classes: 'notify',
          })
          isLoading.value = false
        } else {
          await listsService.post('/', {
            listName: filtersSelected.value.source.replace('G1 - ', '') + ' ' + filtersSelected.value.year,
            color: DefaultTheme().colors.tertiary500,
            questionIds: data.data,
            isTest: true,
            returnFeedback: userPermissionGroupOrRoot.value ? showOption.value : undefined,
            userIds: selectedStudents.value.length ? selectedStudents.value.map((it) => it.id) : undefined,
            groupIds: selectedGroups.value.length ? selectedGroups.value.map((it) => Number(it.id)) : undefined,
            expiresAt: expirationDate.value ? expirationDate.value : undefined,
          })

          $q.notify({
            textColor: 'grey-1',
            message: 'Prova criada com sucesso',
            color: 'green',
            position: 'top',
            classes: 'notify',
          })

          setTimeout(() => {
            modalState.value = false
            if (userPermissionGroupOrRoot.value) {
              reloadListsViewGroupOrRoot?.()
            } else {
              reloadLists?.()
            }
          }, 2000)
        }
      } catch (error) {
        isLoading.value = false
        $q.notify({
          textColor: 'grey-1',
          message: 'Não conseguimos criar sua lista, tente novamente',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    const handlePrimaryButtonAction = async (view: string) => {
      blockButton.value = true
      choicesCache.value = choicesCache.value.map((choice, index) => {
        if (index === choicesCache.value.length - 1) return optionSelected.value
        return choice
      })

      if (view === 'RegionComponent') {
        tags.value.state = (await getTagsBasedOnIds(optionSelected.value, 'regions')).states as any
        viewComponent.value = 'StateComponent'
      } else if (view === 'StateComponent') {
        tags.value.source = (await getTagsBasedOnIds(optionSelected.value, 'states')).sources as any
        viewComponent.value = 'InstitutionComponent'
      } else if (view === 'InstitutionComponent') {
        const aux = await getTagsBasedOnIds(optionSelected.value, 'sources')
        if (!aux.yearsWithPhase.length) filtersNotFound.value = true
        tags.value.year = aux.yearsWithPhase as any
        tags.value.subject = aux.subjects as any
        viewComponent.value = 'YearComponent'
      } else if (view === 'YearComponent' && userPermissionGroupOrRoot.value) {
        isAddReceivers.value = true
        blockButton.value = false
      } else handleCreateList()
    }

    onMounted(async () => {
      const permissionsToCheck = ['group:users:users:manage', 'root:users:users:manage']
      const permissions = authService.getPermissions()?.permissions
      userPermissionGroupOrRoot.value = permissionsToCheck.some((permission) => permissions?.includes(permission))

      tags.value.region = (await getTagsBasedOnIds('', 'includes')).regions as any

      isLoading.value = false
    })
    onUpdated(() => {
      modalState.value = props.modelValue
    })

    watch(modalState, () => emit('update:modelValue', modalState.value))

    return {
      modalState,
      viewComponent,
      choices,
      choicesCache,
      ctrlErrorModal,
      getOptionSelected,
      handleLabelButton,
      handleSecondaryButtonAction,
      changeStepsTo,
      handlePrimaryButtonAction,
      titles,
      filtersSelected,
      tags,
      isLoading,
      filtersNotFound,
      blockButton,
      isAddReceivers,
      handleCreateList,
      dateTimeHandler,
      showOptionSeeFeedback,
    }
  },
})
