import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d69a620"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "icon-wrapper flex row justify-center items-center",
  style: {"width":"20px","height":"20px"}
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "flex cta-wrapper",
    onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleShowActionLabel && _ctx.handleShowActionLabel(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleShowActionLabel && _ctx.handleShowActionLabel(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), _mergeProps({
            key: 0,
            style: {"width":"18px","height":"20px"},
            class: { darker: _ctx.showActionLabel }
          }, _ctx.iconProps), null, 16, ["class"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showActionLabel)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.actionLabel), 1))
      : _createCommentVNode("", true)
  ], 32))
}