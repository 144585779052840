
import { defineComponent, onMounted, ref } from 'vue'
import { ResponseDTO } from '../../../dtos/ResponseDTOs/ResponseDTO'
import reportsService from '../../../services/reports'
import LineChartHistoryProficiency from './LineChartHistoryProficiency.vue'
import { HistoryTestType } from '../../../types/HistoryTestType'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { HandleErrorAxiosResponseDTO } from '../../../dtos/HandleErrorAxiosResponseDTO'

export default defineComponent({
  emits: ['close'],
  components: { LineChartHistoryProficiency },
  props: {
    subjectName: { type: String, required: true, default: '' },
  },
  setup(props) {
    const registerDate = ref<Array<string>>([])
    const proficiencyValue = ref<Array<number>>([])

    const $q = useQuasar()

    onMounted(async () => {
      try {
        const { data } = await reportsService.post<ResponseDTO<Array<HistoryTestType>>>('/proficience-history', {
          subject: props.subjectName,
        })

        data.data.forEach((item) => {
          if (item.finishedAt) {
            registerDate.value.push(item.finishedAt.split(',')[0])
            proficiencyValue.value.push(Number(item.estimatedTheta.toFixed(1)))
          }
        })
      } catch (err) {
        const { error } = new HandleErrorAxiosResponseDTO(err)

        $q.notify({
          textColor: 'grey-1',
          message: error.userMessage,
          color: 'red',
          timeout: 2000,
          position: 'top',
          classes: 'notify',
        })

        console.error(error)
      }
    })

    return { registerDate, proficiencyValue }
  },
})
