
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue';
import CloseIcon from '@/components/Icons/default/CloseIcon.vue';
import Backdrop from '@/components/Library/Backdrop/index.vue';
import DefaultTheme from '@/theme/defaultTheme';
import { defineComponent, onMounted, onUnmounted, onUpdated, PropType, ref } from 'vue';

export default defineComponent({
  name: 'ModalConfirmation',
  inheritAttrs: false,
  props: {
    hideIcon: { type: Boolean, default: false },
    modelValue: { type: Boolean, default: true, required: true },
    preventClosing: { type: Boolean, default: false },
    variant: { type: String as PropType<'default' | 'list-summary'>, default: 'default' },
    label: { type: String, default: '' },
    info: { type: String, default: '' },
    clickConfirm: Function as PropType<() => void>,
  },
  components: {
    Backdrop,
    PazzeiBtn,
    CloseIcon,
  },
  setup(props, { emit }) {
    const modalRef = ref<HTMLDivElement | null>(null)

    const initModal = () => {
      modalRef.value?.focus()
      modalRef.value?.addEventListener('keyup', handlePressingEsc)
    }

    const handlePressingEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') handleClose()
    }

    const handleClose = () => {
      if (!props.preventClosing) {
        emit('update:modelValue', false)
      }
    }

    onMounted(initModal)
    onUpdated(initModal)
    onUnmounted(() => modalRef.value?.removeEventListener('keyup', handlePressingEsc))

    return { modalRef, handleClose, DefaultTheme }
  },
})
