
import { defineComponent, onMounted, PropType, provide, ref, watch, watchEffect } from 'vue';
import { AlternativesSelectorModelValueType } from './types/AlternativesSelectorModelValueType';
import { AlternativesSetType, TrueOrFalseType } from './types/AlternativesSet';

export default defineComponent({
  name: 'AlternativesSelector',
  props: {
    horizontal: { type: Boolean, default: false },
    summationTotal: { type: Number, default: 0 },
    questionId: { type: Number, required: true, default: -1 },
    modelValue: { type: Object as PropType<AlternativesSelectorModelValueType>, default: () => ({}) },
    type: { type: String as PropType<'summation' | 'true/false' | 'mcq'>, default: 'summation' },
  },
  setup(props, { emit, slots }) {
    const alternativeOptionLabels = ref<Array<string>>([])
    const arraySelectedAlternatives = ref<Array<boolean>>([])
    let modelValueAux: Array<AlternativesSetType> = []
    let discardedAlternativesAux: Array<AlternativesSetType> = []
    props.modelValue.discardedAlternatives?.forEach((i) => {
      discardedAlternativesAux.push(i)
    })

    const initSettings = () => {
      const amountAlternatives = (slots.default?.()[0].children?.length as number) || 0

      switch (props.type) {
        case 'summation':
          for (let i = 0; i < amountAlternatives; i++) alternativeOptionLabels.value[i] = i < 4 ? '0' + String(2 ** i) : String(2 ** i)
          arraySelectedAlternatives.value = new Array(amountAlternatives).fill(false)
          break
        case 'true/false':
          for (let i = 0; i < amountAlternatives; i++) alternativeOptionLabels.value[i] = ' '
          break
        case 'mcq':
          for (let i = 0; i < amountAlternatives; i++) alternativeOptionLabels.value[i] = String.fromCharCode(65 + i)
          arraySelectedAlternatives.value = new Array(amountAlternatives).fill(false)

          if (!props.modelValue.questionId) emit('update:modelValue', { questionId: props.questionId, alternatives: [] })

          break
      }
    }

    const setMcq = (index: number) => {
      for (let i = 0; i < arraySelectedAlternatives.value.length; i++) arraySelectedAlternatives.value[i] = false

      arraySelectedAlternatives.value[index] = !arraySelectedAlternatives.value[index]
    }

    const setSummation = (index: number) => {
      arraySelectedAlternatives.value[index] = !arraySelectedAlternatives.value[index]

      let summation = 0

      arraySelectedAlternatives.value.forEach((value, index) => {
        if (value) summation += 2 ** index
      })

      emit('update:summationTotal', summation)
    }

    provide('setMcq', setMcq)
    provide('setSummation', setSummation)

    const defineSetSelectedAlternatives = (alternativesSet: AlternativesSetType) => {
      switch (typeof alternativesSet) {
        case 'object':
          modelValueAux.forEach((alternativeSet, index) => {
            if ((alternativeSet as TrueOrFalseType).alternativeId === alternativesSet.alternativeId) modelValueAux.splice(index, 1)
          })
          modelValueAux.push(alternativesSet)

          break
        case 'number':
          {
            const elementIndex = modelValueAux.indexOf(alternativesSet)

            if (props.type === 'mcq') {
              modelValueAux.splice(elementIndex, 1)
              modelValueAux.push(alternativesSet)
            } else {
              if (elementIndex !== -1) modelValueAux.splice(elementIndex, 1)
              else modelValueAux.push(alternativesSet)
            }
          }
          break
      }

      emit('update:modelValue', {
        questionId: props.questionId,
        alternatives: modelValueAux,
        discardedAlternatives: props.modelValue.discardedAlternatives
      });
    }

    provide('defineSetSelectedAlternatives', defineSetSelectedAlternatives)

    const defineSetDiscardedAlternatives = (alternativesSet: TrueOrFalseType) => {
      if (alternativesSet.state) {
        discardedAlternativesAux.push(alternativesSet.alternativeId)
      } else {
        discardedAlternativesAux = discardedAlternativesAux.filter((i) => { return i !== alternativesSet.alternativeId})
      }

      emit('update:modelValue', {
        questionId: props.questionId,
        alternatives: props.modelValue.alternatives,
        discardedAlternatives: discardedAlternativesAux
      });
    }

    provide('defineSetDiscardedAlternatives', defineSetDiscardedAlternatives)

    onMounted(initSettings)
    watchEffect(() => {
      initSettings()

      if (props.type === 'summation') modelValueAux = props.modelValue.alternatives || []
    })

    watch(
      () => props.type,
      () => {
        emit('update:modelValue', { questionId: props.questionId, alternatives: modelValueAux, discardedAlternatives: discardedAlternativesAux })
      },
    )

    return { alternativeOptionLabels, arraySelectedAlternatives }
  },
})
