
import CardButton from '@/components/Application/entranceExamPage/TestDataBaseModal/CardButton/index.vue'
import { computed, defineComponent, onActivated, PropType, ref } from 'vue'

export default defineComponent({
  name: 'YearComponent',
  emits: ['getSelection'],
  components: {
    CardButton,
  },
  props: {
    year: { type: Array as PropType<Array<string>>, default: () => [] },
    choices: { type: Array as PropType<Array<string>>, default: () => [] },
    isExams: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const items = computed(() => {
      const years = ref<Array<string>>(props.year)
      years.value = years.value.filter((year) => Number(year) !== 0)

      const yearsWithPhase = years.value.filter((it) => {
        if (String(it).includes(' ')) return it
      })

      if (yearsWithPhase.length) {
        const ordenedValues = years.value
          .map((it) => {
            const data = it.split(' ')
            return { year: data[0], rest: data[1] ? it : undefined }
          })
          .sort((a, b) => Number(b.year) - Number(a.year))
          .map((it) => {
            if (it.rest) return it.rest
            return it.year
          })

        return ordenedValues
      } else return years.value.sort((a, b) => Number(b) - Number(a))
    })

    const selectedValue = ref('-1')

    const handleClick = (value: string) => {
      selectedValue.value = value
      emit('getSelection', value)
    }

    onActivated(() => {
      if (props.isExams) selectedValue.value = props.choices[1] ? props.choices[1] : '-1'
      else selectedValue.value = props.choices[3] ? props.choices[3] : '-1'
    })

    return { items, selectedValue, handleClick }
  },
})
