import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25a8b448"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stopwatch-container" }
const _hoisted_2 = { class: "stopwatch-timer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StopwatchEyeIcon = _resolveComponent("StopwatchEyeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.toggleEye ? _ctx.timer : '-- -- --'), 1),
    _createElementVNode("button", {
      class: "stopwatch-eye-button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleToggleEye && _ctx.handleToggleEye(...args)))
    }, [
      _createVNode(_component_StopwatchEyeIcon, { eyeOff: _ctx.toggleEye }, null, 8, ["eyeOff"])
    ])
  ]))
}