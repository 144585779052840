
import { defineComponent, PropType, provide, ref } from 'vue'

export default defineComponent({
  name: 'TabsComponent',
  props: {
    noCaps: { type: Boolean, default: false },
    vertical: { type: Boolean, default: false },
    align: { type: String as PropType<'center' | 'justify' | 'left' | 'right'>, default: 'center' },
    height: { type: String, default: '3em' },
    modelValue: { type: String, default: '', required: true },
    width: { type: String, default: '100%' },
    validate: { type: Function as PropType<() => Promise<boolean>>, default: () => Promise.resolve(true) },
    validateAddress: { type: Function as PropType<() => Promise<boolean>>, default: () => Promise.resolve(true) },
    isModalInstitutions: { type: Boolean, default: false },
    isCreateInstitutions: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const tabSelected = ref(props.modelValue)
    const tabs = ref<string[]>()

    const handleTabUpdateClick = async (tabName: string) => {
      if (props.isModalInstitutions && props.isCreateInstitutions) {
        const result = await props.validate()
        const resultAddress = await props.validateAddress()

        if (resultAddress && result) {
          tabSelected.value = tabName
          emit('update:modelValue', tabSelected.value)
        } else if (result && !resultAddress && tabName !== 'subjects') {
          tabSelected.value = tabName
          emit('update:modelValue', tabSelected.value)
        } else if (resultAddress && !result) {
          tabSelected.value = 'schoolData'
          emit('update:modelValue', tabSelected.value)
        }
      } else {
        tabSelected.value = tabName
        emit('update:modelValue', tabSelected.value)
      }
    }

    provide('handleTabUpdateClick', handleTabUpdateClick)

    const handleFocus = (event: FocusEvent) => {
      const target = event.target as HTMLElement
      target.click()
    }

    return { tabs, tabSelected, handleFocus }
  },
})
