import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 7 13",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M6.81339 6.95966L1.08635 12.8096C0.962264 12.9363 0.799381 13 0.636478 13C0.473576 13 0.310692 12.9363 0.186606 12.8096C-0.0622021 12.5554 -0.0622021 12.1446 0.186606 11.8904L5.46374 6.50009L0.186606 1.10974C-0.0622021 0.855591 -0.0622021 0.444756 0.186606 0.19061C0.435414 -0.0635366 0.837542 -0.0635366 1.08635 0.19061L6.81339 6.04053C7.0622 6.29467 7.0622 6.70551 6.81339 6.95966Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}