import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b49768e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "terms-change-modal-wrapper" }
const _hoisted_2 = { class: "terms-change-modal-term-container" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "terms-change-modal-button-skip-wrapper"
}
const _hoisted_5 = { class: "terms-change-modal-button-skip-container" }
const _hoisted_6 = { class: "terms-change-modal-button-skip-container-next" }
const _hoisted_7 = { class: "terms-change-modal-terms-changed" }
const _hoisted_8 = { class: "terms-change-modal-button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CooliconIcon = _resolveComponent("CooliconIcon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    modelValue: _ctx.modalVisibility,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalVisibility) = $event)),
    "prevent-closing": "",
    "hide-icon": "",
    width: "40em",
    height: "fit-content"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            innerHTML: !_ctx.currentTerm ? _ctx.termHTML.termsOfUse.data : _ctx.termHTML.privacyPolicy.data
          }, null, 8, _hoisted_3)
        ]),
        (_ctx.newTerms.length > 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("button", {
                  class: _normalizeClass(["terms-change-modal-button-skip", { disabled: !_ctx.currentTerm }]),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentTerm = _ctx.currentTerm ? 0 : 1))
                }, [
                  _createVNode(_component_CooliconIcon, {
                    fill: _ctx.DefaultTheme().colors.whiteColor
                  }, null, 8, ["fill"])
                ], 2)
              ]),
              _createElementVNode("span", null, _toDisplayString(_ctx.termsPhrase.term[_ctx.currentTerm]), 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("button", {
                  class: _normalizeClass(["terms-change-modal-button-skip", { disabled: _ctx.currentTerm }]),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.currentTerm = _ctx.currentTerm ? 0 : 1))
                }, [
                  _createVNode(_component_CooliconIcon, {
                    fill: _ctx.DefaultTheme().colors.whiteColor
                  }, null, 8, ["fill"])
                ], 2)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.termsPhrase.phrase) + ". Para continuar utilizando a plataforma, leia e assine novamente.", 1),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_Button, {
            label: "Aceitar",
            onClick: _ctx.handleAcceptTerms
          }, null, 8, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}