
import { defineComponent, inject, onMounted, onUnmounted, provide, reactive, Ref, ref } from 'vue'
import { ResponseDTO } from '../../dtos/ResponseDTOs/ResponseDTO'
import { UserDTO } from '../../dtos/ResponseDTOs/UserDTO'
import authService from '../../services/authService'
import usersService from '../../services/users'
import PazzeiBtn from '../Generics/PazzeiBtn.vue'

export default defineComponent({
  name: 'PazzeiAvatar',
  emits: ['setUserInfos'],
  components: { PazzeiBtn },
  setup() {
    const controller = new AbortController()
    const logout = () => {
      authService.logoutUser()
    }

    const userAuth = ref(false)
    const getUser = inject<Ref<UserDTO>>('userDTO')
    const userInfo = ref<UserDTO>()
    const sizeAvatar = ref('80px')
    const sizeLetter = ref('30px')
    const innerWidth = ref(window.innerWidth)
    const selectedTheme = reactive({
      light: {
        selected: true
      },
      dark: {
        selected: false
      },
    })

    const setWidthAvatar = () => {
      innerWidth.value = window.innerWidth
    }

    window.addEventListener('resize', setWidthAvatar)
    provide('userDTO', getUser)

    const initialLetterName = ref<string | undefined>('')

    const handleThemeChange = () => {
      if (selectedTheme.dark.selected) {
        selectedTheme.dark.selected = false;
        selectedTheme.light.selected = true;
        document.documentElement.setAttribute('data-theme', 'light');
        return;
      }

      selectedTheme.dark.selected = true;
      selectedTheme.light.selected = false;
      document.documentElement.setAttribute('data-theme', 'dark');
    }

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.setAttribute('data-theme', 'dark');
      selectedTheme.dark.selected = true;
      selectedTheme.light.selected = false;
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
      selectedTheme.dark.selected = false;
      selectedTheme.light.selected = true;
    }

    onMounted(() => {
      if (innerWidth.value <= 768) {
        sizeAvatar.value = '3.5em'
        sizeLetter.value = '0.4em'
      }

      userInfo.value = getUser?.value
      if (!getUser?.value) {
        usersService.get<ResponseDTO<UserDTO>>('/me', { signal: controller.signal }).then((res) => {
          initialLetterName.value = res.data.data.name[0].toUpperCase()
          if (getUser) {
            userInfo.value = res.data.data

            getUser.value = new UserDTO({
              name: res.data.data.name,
              lastName: res.data.data.lastName,
              email: res.data.data.email,
              groupId: 0,
              roleCode: res.data.data.roleCode,
              registrationCompleted: res.data.data.registrationCompleted,
              credits: res.data.data.credits,
              autoRenew: res.data.data.autoRenew,
            })
          }
          if (authService.verifyPermissions() === 'home') userAuth.value = true
        })
      } else {
        initialLetterName.value = getUser?.value.name[0].toUpperCase()
        if (authService.verifyPermissions() === 'home') userAuth.value = true
      }
    })

    onUnmounted(() => {
      controller.abort()
      window.removeEventListener('resize', setWidthAvatar)
    })

    return {
      logout,
      initialLetterName,
      userInfo,
      userAuth,
      getUser,
      sizeAvatar,
      sizeLetter,
      selectedTheme,
      handleThemeChange,
    }
  },
})
