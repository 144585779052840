import { DataByTabsDTO } from '../dtos/CreateListPage/DataByTabsDTO'
import { QuestionTagsDTO } from '../dtos/CreateListPage/QuestionTagsDTO'
import { SubjectTreeCollapseDivision, SubjectTreeCollapseItems, SubjectTreeCollapseSubItems, SubjectTreeCollapseTopics } from './SubjectTreeCollapseType'

export interface SummaryType {
  subjects: Array<SummaryTreeSubjectsType>
  includeAlreadyAnswered: boolean
}

export interface SubjectsType {
  divisions?: Record<string, number>
  topics?: Record<string, number>
  items?: Record<string, number>
  subItems?: Record<string, number>
  filter?: FilterType
}

export interface FilterType {
  types: Array<string>
  subjects: Array<string>
  years: Array<string>
  levels: Array<string>
  sources: Array<string>
  divisions?: Array<string>
}

export class SummaryTreeSubjectsType {
  name: string
  quantity?: number
  divisions: Array<SummaryTreeDivisionType>
  filters: QuestionTagsDTO

  constructor(subjectName: string, dataByTabsDTO: DataByTabsDTO) {
    this.name = subjectName
    this.filters = dataByTabsDTO.filters
    if (!dataByTabsDTO.subjectTree.children.length) this.quantity = dataByTabsDTO.subjectTree.totalDesiredQuestions

    this.divisions = dataByTabsDTO.subjectTree.children.map((division) => new SummaryTreeDivisionType(division))
  }
}

export class SummaryTreeDivisionType {
  name: string
  quantity?: number
  topics: Array<SummaryTreeTopicsType>

  constructor(subjectTreeCollapseDivision: SubjectTreeCollapseDivision) {
    this.name = subjectTreeCollapseDivision.name
    if (!subjectTreeCollapseDivision.children.length) this.quantity = subjectTreeCollapseDivision.totalDesiredQuestions

    this.topics = subjectTreeCollapseDivision.children.map((topic) => new SummaryTreeTopicsType(topic))
  }
}

export class SummaryTreeTopicsType {
  name: string
  quantity?: number
  items: Array<SummaryTreeItemsType>

  constructor(subjectTreeCollapseTopics: SubjectTreeCollapseTopics) {
    this.name = subjectTreeCollapseTopics.name
    if (!subjectTreeCollapseTopics.children.length) this.quantity = subjectTreeCollapseTopics.totalDesiredQuestions

    this.items = subjectTreeCollapseTopics.children.map((item) => new SummaryTreeItemsType(item))
  }
}

export class SummaryTreeItemsType {
  name: string
  quantity?: number
  subItems: Array<SummaryTreeSubItemsType>

  constructor(subjectTreeCollapseItems: SubjectTreeCollapseItems) {
    this.name = subjectTreeCollapseItems.name
    if (!subjectTreeCollapseItems.children.length) this.quantity = subjectTreeCollapseItems.totalDesiredQuestions

    this.subItems = subjectTreeCollapseItems.children.map((subItem) => new SummaryTreeSubItemsType(subItem))
  }
}

export class SummaryTreeSubItemsType {
  name: string
  quantity: number

  constructor(subjectTreeCollapseItems: SubjectTreeCollapseSubItems) {
    this.name = subjectTreeCollapseItems.name
    this.quantity = subjectTreeCollapseItems.totalDesiredQuestions
  }
}
