
import PrinterIcon from '@/components/Icons/default/redesign/sharedList/PrinterIcon.vue'
import { ListTryDTO, SubjectInfoDTO } from '@/dtos/SubjectInfoDTO'
import { listStatus } from '@/enum/listStatusEnum'
import listsService from '@/services/list'
import DefaultTheme from '@/theme/defaultTheme'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, onMounted, PropType, provide, ref, watch } from 'vue'
import PazzeiMenuLists from '../../PazzeiMenuLists.vue'
import CardDeadline from '../CardDeadline.vue'

export default defineComponent({
  name: 'SubjectList',
  components: {
    PazzeiMenuLists,
    CardDeadline,
    PrinterIcon
  },
  props: {
    subjectInfo: {
      type: Object as PropType<SubjectInfoDTO>,
      required: true,
    },
    notShowMenu: {
      type: Boolean,
      default: false,
    },
    displayCardsInline: Boolean,
  },
  setup(props, {emit}) {
    const progressSize = ref({
      progressCircleSize: '5.5em',
      progressAvatarSize: '4.5em',
    })
    const lastTry = ref<ListTryDTO>()
    const totalResponses = ref(0)

    const currentColor = ref(props.subjectInfo.color)
    const tryFinished = props.subjectInfo.listTries && props.subjectInfo.listTries.length > 0 ? props.subjectInfo.listTries[props.subjectInfo.listTries.length - 1].isFinished : false
    const editName = ref(false)
    const showFeedback = ref(props.subjectInfo.returnFeedback || props.subjectInfo.config?.showResult);
    provide('editName', editName)
    const name = ref(props.subjectInfo.name)
    const createdBy = ref(props?.subjectInfo?.config?.professorName || props?.subjectInfo?.userHasList?.user?.name || null)
    const $q = useQuasar()
    const focusEditName = ref<HTMLInputElement>()
    const tempName = ref(props.subjectInfo.name)
    const isTest = ref(props.subjectInfo.isTest)
    const pathname = window.location.pathname
    const expirationDate = ref(props.subjectInfo.listTries.length > 0 && props.subjectInfo.listTries[0].expiresAt)
    let blockDoubleClick = false
    const progress = ref(0)

    const setProgressSize = () => {
      if (window.innerWidth <= 768) progressSize.value = { progressCircleSize: '3.5em', progressAvatarSize: '2.5em' }
      else progressSize.value = { progressCircleSize: '5.5em', progressAvatarSize: '4.5em' }
    }

    const setTotalResponses = () => {
      const responses = props.subjectInfo.listTries[0].userResponses;
      totalResponses.value = responses?.filter((response) => response.isCorrect !== null).length || 0;
      progress.value = totalResponses.value ? totalResponses.value / props.subjectInfo.totalQuestions : 0
    }

    const handleTooltipInfos = () => {
      if (!expirationDate.value) {
        return { message: '', color: '' }
      }

      const dateObject = new Date(expirationDate.value)
      const currentDate = new Date()
      const dayDiff = (dateObject.getTime() - currentDate.getTime()) / (1000 * 3600 * 24)
      if (dayDiff > 1) return { message: `Expira em ${dateObject.toLocaleString()}`, color: 'bg-purple' }
      if (dayDiff > 0) return { message: `Expira hoje às ${dateObject.toLocaleTimeString()}`, color: 'bg-purple' }
      if (dayDiff > -1) return { message: `Expirou hoje às ${dateObject.toLocaleTimeString()}`, color: 'bg-red' }
      return { message: `Expirou em ${dateObject.toLocaleString()}`, color: 'bg-red' }
    }

    const isExpired = handleTooltipInfos().color === 'bg-red'

    onMounted(() => {
      setTotalResponses()
      setProgressSize
    })

    window.addEventListener('resize', setProgressSize)

    const cancelEditName = () => {
      name.value = tempName.value
      editName.value = false
    }

    const handleNameChange = () => {
      const data = ref({ listName: name.value, id: props.subjectInfo.id, color: props.subjectInfo.color })
      listsService.patch('/', data.value).then(() => {
        $q.notify({
          textColor: 'white',
          message: 'Nome alterado com sucesso',
          color: 'green',
          position: 'top',
          classes: 'notify',
        })
        editName.value = false
      })
    }

    const btnLabel = () => {
      if (tryFinished === true && !isExpired) return 'Ver respostas'
      else if (totalResponses.value && !isExpired) return 'Continuar'
      else if (isExpired) return 'Expirado'
      else return 'Iniciar'
    }

    const checkForSuccessClass = () => {
      if (tryFinished === true) return 'success-border'
      else return ''
    }

    const checkForDisplayInlineClass = () => {
      return props.displayCardsInline ? 'display-inline' : '';
    }

    if (props.subjectInfo.listTries) {
      const arraySorted = props.subjectInfo.listTries.map((it) => it).sort((a, b) => a.id - b.id)
      lastTry.value = arraySorted[arraySorted.length - 1]
    }

    const handleClick = async (e: Event) => {
      if (
        e.target instanceof HTMLElement
        && e.target.classList?.contains('printable')
        || isCardDisabled()
      ) {
        return;
      }

      sessionStorage.setItem('keyDrawer', pathname)

      if(!blockDoubleClick) {
        blockDoubleClick = true

        if (lastTry.value) {
          if (tryFinished === true) {
            blockDoubleClick = false
            if (showFeedback.value || isExpired) window.location.href = `/gabarito/${props.subjectInfo.id}/${lastTry.value.id}`
            else {
              $q.notify({
                textColor: 'white',
                message: 'O professor bloqueou a visualização do gabarito desta lista',
                color: 'yellow-8',
                position: 'top',
                classes: 'notify',
              })
            }
          } else if (isExpired) {
            blockDoubleClick = false
            $q.notify({
              textColor: 'white',
              message: 'Essa lista não foi finalizada, você não poderá ver o gabarito',
              color: 'yellow-8',
              position: 'top',
              classes: 'notify',
            })
          } else {
            emit('showListPreview', true);
            emit('selectedList', props.subjectInfo)
          }
        }
      }
    }

    const exportToPDF = async () => {
      emit('handle-show-print-list-modal', true, props.subjectInfo);
    }

    const isCardDisabled = () => {
      return props.subjectInfo.status?.code === listStatus.comingSoon.code || (props.subjectInfo.config && !props.subjectInfo.config.status);
    }

    watch(
      editName,
      () => {
        setTimeout(() => {
          if (editName.value) focusEditName.value!.focus()
        }, 100)
      },
      { deep: true }
    )
    return {
      currentColor,
      totalResponses,
      btnLabel,
      handleClick,
      editName,
      name,
      createdBy,
      isTest,
      progressSize,
      handleNameChange,
      focusEditName,
      cancelEditName,
      tryFinished,
      checkForSuccessClass,
      handleTooltipInfos,
      expirationDate,
      isExpired,
      showFeedback,
      progress,
      checkForDisplayInlineClass,
      exportToPDF,
      isCardDisabled,
      DefaultTheme
    }
  },
})
