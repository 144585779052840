import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f4d41c52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "q-card-div subject-list__card" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  key: 0,
  class: "elipses"
}
const _hoisted_4 = { class: "div-wrapper-name" }
const _hoisted_5 = { class: "p-div" }
const _hoisted_6 = { class: "text-h6 q-ma-none p-title p-list-name" }
const _hoisted_7 = {
  key: 0,
  class: "q-ma-none p-title p-list-creator"
}
const _hoisted_8 = { class: "status-wrapper" }
const _hoisted_9 = { class: "user-progress" }
const _hoisted_10 = { class: "info-p text-center" }
const _hoisted_11 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PazzeiMenuLists = _resolveComponent("PazzeiMenuLists")!
  const _component_q_linear_progress = _resolveComponent("q-linear-progress")!
  const _component_card_deadline = _resolveComponent("card-deadline")!
  const _component_PrinterIcon = _resolveComponent("PrinterIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["q-card-cpnt q-py-md", `${_ctx.checkForSuccessClass()} ${_ctx.checkForDisplayInlineClass()}`]),
      disabled: _ctx.isCardDisabled() ? true : null
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.displayCardsInline ? 'q-area-selection' : ''),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
      }, [
        (!_ctx.notShowMenu)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_PazzeiMenuLists, {
                listId: _ctx.subjectInfo.id,
                isTest: _ctx.isTest
              }, null, 8, ["listId", "isTest"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.name), 1),
            (_ctx.createdBy)
              ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.createdBy), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.totalResponses) + "/" + _toDisplayString(_ctx.subjectInfo.totalQuestions) + " questões ", 1),
            _createVNode(_component_q_linear_progress, {
              size: '8px',
              value: _ctx.progress,
              color: "info",
              "track-color": "track-gray"
            }, null, 8, ["value"])
          ]),
          _createVNode(_component_card_deadline, {
            "subject-info": _ctx.subjectInfo,
            "display-cards-inline": _ctx.displayCardsInline
          }, null, 8, ["subject-info", "display-cards-inline"])
        ])
      ], 2),
      (_ctx.displayCardsInline)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "print-list printable",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (!_ctx.subjectInfo.config?.print ? null : _ctx.exportToPDF())),
            disabled: !_ctx.subjectInfo.config?.print ? true : null
          }, [
            _createVNode(_component_PrinterIcon, {
              fill: _ctx.DefaultTheme().colors.secondaryGray
            }, null, 8, ["fill"])
          ], 8, _hoisted_11))
        : _createCommentVNode("", true)
    ], 10, _hoisted_2)
  ]))
}