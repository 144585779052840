import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44208c64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-layout-wrapper" }
const _hoisted_2 = { class: "login-layout-header" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "login-layout-content" }
const _hoisted_6 = { class: "login-layout-content--slot" }
const _hoisted_7 = { class: "login-layout-content--form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tabs = _resolveComponent("Tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("a", { href: _ctx.brandHomeUrl }, [
        _createElementVNode("img", {
          class: "login-layout-header--img-logo",
          src: _ctx.DefaultTheme().assets.logo,
          alt: "logotipo Pazzei"
        }, null, 8, _hoisted_4)
      ], 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createElementVNode("h1", {
          class: _normalizeClass(["line-height q-ma-none q-mb-md login-layout-content--slot-title global-color", { 'text-center': _ctx.textCenter }])
        }, "Olá, " + _toDisplayString(_ctx.person), 3), [
          [_vShow, _ctx.greetings]
        ]),
        _createElementVNode("h1", {
          class: _normalizeClass(["text-h5 q-ma-none q-mb-md login-layout-content--slot-title global-color", { 'text-center': _ctx.textCenter }])
        }, _toDisplayString(_ctx.title), 3),
        _withDirectives(_createVNode(_component_Tabs, { tab: _ctx.tab }, null, 8, ["tab"]), [
          [_vShow, _ctx.hasTab]
        ]),
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ])
    ])
  ]))
}