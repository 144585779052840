import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO';
import usersService from '@/services/users';
import Window from '../../interfaces/window.interface';
declare const window: Window;

const formStartedAt = process.env.VUE_APP_NPS_FORM_ON_LOGIN_START_DATE;

export default class TallyService {
  protected static render = async (formId: string, userId: string) => {
    const userAnsweredForm = (await usersService.get<ResponseDTO<string[]>>(`/answered-tally-forms/${userId}`)).data.data;
    
    if (userAnsweredForm.length && userAnsweredForm.includes(formId) && !this.quarterlyCheck()) {
      return;
    }

    window.Tally.openPopup(formId, {
      onOpen: () => {
        //
      },
      onClose: () => {
        window.Tally.closePopup(formId);
      },
      onPageView: (page: number) => {
        //
      },
      onSubmit: async (payload: any) => {
        await usersService.post<ResponseDTO<[]>>('/answered-tally-form', {
          id: userId,
          form: formId
        });
      }
    });
  }

  protected static checkIfOneYearHasPassed = () => {
    const startDate = new Date(formStartedAt);

    const nextYearDate = new Date(startDate);
    nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);

    return new Date() >= nextYearDate;
  }

  protected static quarterlyCheck = () => {
    const startDate = new Date(formStartedAt);
    
    const checkDates = [90, 180, 270, 360].map(days => {
      const date = new Date(startDate);
      date.setDate(date.getDate() + days);
      return date;
    });
  
    const today = new Date();

    return checkDates.some(date => 
      today.getFullYear() === date.getFullYear() &&
      today.getMonth() === date.getMonth() &&
      today.getDate() === date.getDate()
    );
  }

  /** 
   * --------------
   * PUBLIC METHODS 
   * --------------
   * */
  public static createListForm = (userId: string) => {
    const formId = 'wAllNk';
    this.render(formId, userId);
  }

  public static loginForm = (
    userId: string | undefined,
    institutionPlanId: number | undefined,
    userInfos: any
  ) => {
    if (
      (['pazzei:trial', 'pazzei:no-credits'].includes(userInfos.roleCode) && !institutionPlanId)
      || ['institution:manager', 'institution:teacher', 'pazzei:manager', 'pazzei:integration'].includes(userInfos.roleCode)
    ) { return }

    // form must be available for one year only
    if (this.checkIfOneYearHasPassed()) {
      return;
    }

    const formId = 'mOLjY8';

    if (userId) {
      this.render(formId, userId);
    }
  }
}
