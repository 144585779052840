import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6da52030"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-container" }
const _hoisted_2 = { class: "table" }
const _hoisted_3 = { class: "tbody" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col, idx) => {
            return (_openBlock(), _createElementBlock("th", { key: idx }, _toDisplayString(col), 1))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _createElementVNode("tfoot", null, [
        _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
      ])
    ])
  ]))
}