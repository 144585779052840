import { defaultColors } from "@/theme/tokens/colors";
import { ColorsType } from "@/theme/types";

 export const ArcanjoBrandColors: ColorsType = {
  ...defaultColors,

  primary: '#142b4f',
  secondary: '#2e354d',
  primaryColor: '#101230',
  pinkColor: '#17325B',

  primary25: '#b1b4c6',
  primary50: '#8a8fb2',
  primary100: '#636a9e',
  primary200: '#4e5680',
  primary300: '#3e4666',
  primary400: '#2e354d',
  primary500: '#101230',
  primary600: '#0d0f28',
  primary700: '#0a0c20',
  primary800: '#070918',
  primary900: '#050611',

  secondary25: '#b2c2d8',
  secondary50: '#8c9dbb',
  secondary100: '#66789d',
  secondary200: '#506185',
  secondary300: '#3f4e6b',
  secondary400: '#2e3b51',
  secondary500: '#17325B',
  secondary600: '#142b4f',
  secondary700: '#102444',
  secondary800: '#0c1d38',
  secondary900: '#08162c',

  rose25: '#b2c2d8',  
  rose50: '#8c9dbb', 
  rose100: '#66789d', 
  rose200: '#506185',
  rose300: '#3f4e6b', 
  rose400: '#2e3b51',
  rose500: '#17325B', 
  rose600: '#142b4f', 
  rose700: '#102444',
  rose800: '#0c1d38', 
  rose900: '#08162c', 
};
