
import ChevronDownIcon from "@/components/Icons/default/redesign/ChevronDownIcon.vue";
import DefaultTheme from "@/theme/defaultTheme";
import { InstitutionPlans } from "@/types/InstitutionPlansType";
import { defineComponent, PropType, ref } from "vue";

type DropdownTypes = InstitutionPlans;

export default defineComponent({
  name: 'PazzeiDropdown',
  props: {
    placeholder: { type: String, required: true },
    options: { type: Array as PropType<Array<DropdownTypes> | undefined> },
  },
  components: {
    ChevronDownIcon
  },
  setup(props, { emit }) {
    const showOptions = ref(false);

    const handleShowOptions = () => {
      showOptions.value = !showOptions.value;
    }

    const handleSelectedOption = (option: DropdownTypes) => {
      props.options?.forEach((op) => {
        op.selected = false;
      });

      option.selected = true;
      emit('select-option', option);
      handleShowOptions();
    }

    return {
      showOptions,
      handleShowOptions,
      handleSelectedOption,
      DefaultTheme
    }
  }
});
