
import { defineComponent, onMounted, ref, watchEffect } from 'vue';

export default defineComponent({
  name: 'PazzeiInputCode',
  emits: ['getCode'],
  props: {
    code: {
      type: String,
      require: true,
    },
    hasError: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const codeRefs = ref([ref<HTMLInputElement>(), ref<HTMLInputElement>(), ref<HTMLInputElement>(), ref<HTMLInputElement>(), ref<HTMLInputElement>(), ref<HTMLInputElement>()])
    const isLoading = ref(false)
    const disabled = ref(true)
    const otpCodeFields = ref(['', '', '', '', '', ''])

    const onInput = (event: Event, index: number) => {
      if (event.target && event.target instanceof HTMLInputElement) {
        if (!event.target.value) return
        otpCodeFields.value[index] = ''
        otpCodeFields.value[index] = event.target.value.toUpperCase()

        if (index < 5) {
          const nextCodeField = codeRefs.value[index + 1].value as unknown as Array<HTMLInputElement>

          focusThis(nextCodeField[0])
        }
      }
    }
    const onBackspaceKeyDown = (index: number) => {
      otpCodeFields.value[index] = ''
      if (index > 0 && codeRefs.value[index].value) {
        const laterCodeField = codeRefs.value[index - 1].value as unknown as Array<HTMLInputElement>
        focusThis(laterCodeField[0])
      }
    }

    const onPaste = (event: ClipboardEvent, index: number) => {
      const text = event.clipboardData?.getData('text')
      if (!text) return

      const trimmedText = text.replace(' ', '').trim().toUpperCase()

      if (trimmedText.length > 0) {
        const length = codeRefs.value.length - index < trimmedText.length ? codeRefs.value.length : trimmedText.length

        for (let i = index; i < length; i++) {
          const codeField = codeRefs.value[i].value as unknown as Array<HTMLInputElement>

          otpCodeFields.value[i] = trimmedText[i]
          codeField[0].value = trimmedText[i]

          codeField[0].focus()
        }
      }
    }

    const moveLeft = (index: number) => {
      if (index > 0) {
        const laterCodeField = codeRefs.value[index - 1].value as unknown as Array<HTMLInputElement>
        focusThis(laterCodeField[0])
      }
    }

    const moveRight = (index: number) => {
      if (index < 5) {
        const nextCodeField = codeRefs.value[index + 1].value as unknown as Array<HTMLInputElement>
        focusThis(nextCodeField[0])
      }
    }

    const focusThis = (codeField: HTMLInputElement) => {
      codeField.focus()
      codeField.select()
    }

    const onLeftKeyUp = (index: number) => {
      if (codeRefs.value[index].value)
        if (index > 0) moveLeft(index)
        else {
          const actualCodeField = codeRefs.value[index].value as unknown as Array<HTMLInputElement>
          focusThis(actualCodeField[0])
        }
    }

    const onRightKeyUp = (index: number) => {
      if (codeRefs.value[index].value)
        if (index < 5) moveRight(index)
        else {
          const actualCodeField = codeRefs.value[index].value as unknown as Array<HTMLInputElement>
          focusThis(actualCodeField[0])
        }
    }

    onMounted(() => {
      ;(document.querySelector('.input-code') as HTMLInputElement).focus()
    })

    const returnToLoginPage = () => {
      if (isLoading.value === true) isLoading.value = false
      else window.location.href = '/'
    }

    const validateCode = () => {
      const code = codeRefs.value.map((it) => (it.value?.value ? it.value?.value.toUpperCase() : '')).join('')
      if (code.length < 6) {
        codeRefs.value.forEach((it) => {
          if (it.value) it.value.className = 'input-code-on-error'
        })
        alert('Preencha todos os campos')
        return
      }
      disabled.value = false
      isLoading.value = true
      codeRefs.value[0].value?.focus()
      emit('getCode', code)
    }

    watchEffect(() => {
      if (!otpCodeFields.value || !otpCodeFields.value.length) return
      const code = otpCodeFields.value.join('').trim().replace(' ', '').toUpperCase()
      if (code.length === otpCodeFields.value.length) {
        disabled.value = false
        isLoading.value = true
        emit('getCode', code)
        return
      }
      disabled.value = true
      isLoading.value = false
    })

    return {
      codeRefs,
      otpCodeFields,
      isLoading,
      disabled,
      returnToLoginPage,
      validateCode,
      onInput,
      onBackspaceKeyDown,
      onPaste,
      onLeftKeyUp,
      onRightKeyUp,
    }
  },
})
