import authenticationService from "@/services/authentication"
import AuthHelper from "./AuthHelper";
import router from "@/router";

const AccessHelper = async (userId: string | undefined): Promise<void> => {
  if (!userId) throw new Error('UserId não foi fornecido.')

  const { data: req } = await authenticationService.patch('/simulate', { id: userId })
  const { token, refreshToken } = req.data;

  if (token && refreshToken) {
    await AuthHelper(token, refreshToken).then(() => {
      router.push('/').then(() => {
        window.location.reload();
      })
      return
    })
  } else {
    throw new Error('')
  }
}

export default AccessHelper