import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58faadc3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inputs-wrapper" }
const _hoisted_2 = { class: "div-input" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "grid-table"
}
const _hoisted_6 = {
  key: 1,
  class: "grid-table"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentModalRegisterUsers = _resolveComponent("ContentModalRegisterUsers")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_ContentModalImportStudents = _resolveComponent("ContentModalImportStudents")!
  const _component_ContentModalReleaseGratuity = _resolveComponent("ContentModalReleaseGratuity")!
  const _component_ModalConfirmation = _resolveComponent("ModalConfirmation")!
  const _component_SearchIcon = _resolveComponent("SearchIcon")!
  const _component_PazzeiInput = _resolveComponent("PazzeiInput")!
  const _component_PazzeiBtn = _resolveComponent("PazzeiBtn")!
  const _component_PazzeiOptionsMenu = _resolveComponent("PazzeiOptionsMenu")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_TablesComponent = _resolveComponent("TablesComponent")!
  const _component_PaginationTableComponent = _resolveComponent("PaginationTableComponent")!
  const _component_MainLayoutWrapper = _resolveComponent("MainLayoutWrapper")!
  const _component_GenericLayout = _resolveComponent("GenericLayout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Modal, {
      modelValue: _ctx.isModalOpen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isModalOpen) = $event)),
      height: _ctx.height,
      width: _ctx.width,
      notClose: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ContentModalRegisterUsers, {
          sizeInputDaysGratuity: _ctx.sizeInputDaysGratuity,
          onClose: _ctx.closeModal,
          isEditing: _ctx.isEditing,
          "user-id": _ctx.userId,
          listUser: _ctx.getInstitutionUsers,
          sizeEditLoading: _ctx.sizeEditLoading,
          userPermission: _ctx.userPermission,
          isPageStudentIntitution: false,
          isStudentEssential: _ctx.isStudentEssential
        }, null, 8, ["sizeInputDaysGratuity", "onClose", "isEditing", "user-id", "listUser", "sizeEditLoading", "userPermission", "isStudentEssential"])
      ]),
      _: 1
    }, 8, ["modelValue", "height", "width"]),
    _createVNode(_component_Modal, {
      modelValue: _ctx.isImportStudentsModalOpen,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isImportStudentsModalOpen) = $event)),
      height: _ctx.height,
      width: _ctx.width,
      notClose: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ContentModalImportStudents, {
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleImportStudentsModal(false)))
        })
      ]),
      _: 1
    }, 8, ["modelValue", "height", "width"]),
    _createVNode(_component_Modal, {
      modelValue: _ctx.isModalReleaseGratuity,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isModalReleaseGratuity) = $event)),
      height: _ctx.heightSmall,
      width: _ctx.widthSmall
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ContentModalReleaseGratuity, {
          onClose: _ctx.closeModalReleaseGratuity,
          "user-id": _ctx.userId,
          sizeEditLoading: _ctx.sizeEditLoading
        }, null, 8, ["onClose", "user-id", "sizeEditLoading"])
      ]),
      _: 1
    }, 8, ["modelValue", "height", "width"]),
    _createVNode(_component_ModalConfirmation, {
      modelValue: _ctx.isModalConfirmOpen,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isModalConfirmOpen) = $event)),
      label: "Tem certeza que deseja inativar este usuário?",
      info: _ctx.infoUser,
      "click-confirm": _ctx.toggleResourceStatus
    }, null, 8, ["modelValue", "info", "click-confirm"]),
    _createVNode(_component_GenericLayout, null, {
      default: _withCtx(() => [
        _createVNode(_component_MainLayoutWrapper, null, {
          nav: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_PazzeiInput, {
                  mobileSchool: "",
                  "max-length": 80,
                  placeholder: 'Buscar usuário',
                  "hide-bottom-space": "",
                  "search-list": "",
                  "input-collapsed": _ctx.isSearchInputExpanded,
                  modelValue: _ctx.filterNameSearch,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.filterNameSearch) = $event))
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_SearchIcon, {
                      size: 32,
                      fill: _ctx.DefaultTheme().colors.primary,
                      style: {"padding-left":"15px"},
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isSearchInputExpanded = !_ctx.isSearchInputExpanded))
                    }, null, 8, ["fill"])
                  ]),
                  button: _withCtx(() => [
                    _createElementVNode("button", {
                      class: _normalizeClass(["btn-filter", { active: _ctx.isFilterSelected }]),
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openFilterSelected()))
                    }, "Filtro avançado", 2)
                  ]),
                  _: 1
                }, 8, ["input-collapsed", "modelValue"])
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["btn-container", { 'btn-pazzei__collapsed': !_ctx.isSearchInputExpanded, 'spacing-buttons': _ctx.userPermission }])
              }, [
                _createVNode(_component_PazzeiBtn, {
                  icon: _ctx.AddIcon,
                  label: "Cadastrar Usuário",
                  fill: "",
                  class: _normalizeClass(["btn-pazzei", { 'btn-pazzei__collapsed': !_ctx.isSearchInputExpanded }]),
                  "btn-collapsed": !_ctx.isSearchInputExpanded,
                  iconStroke: _ctx.DefaultTheme().colors.whiteColor,
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.openModal()))
                }, null, 8, ["icon", "class", "btn-collapsed", "iconStroke"])
              ], 2),
              (!_ctx.userPermission)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["btn-container", { 'btn-pazzei__collapsed': !_ctx.isSearchInputExpanded, 'spacing-buttons': _ctx.userPermission }])
                  }, [
                    _createVNode(_component_PazzeiBtn, {
                      icon: _ctx.AddIcon,
                      label: "Importar Alunos",
                      fill: "",
                      class: _normalizeClass(["btn-pazzei", { 'btn-pazzei__collapsed': !_ctx.isSearchInputExpanded }]),
                      "btn-collapsed": !_ctx.isSearchInputExpanded,
                      iconStroke: _ctx.DefaultTheme().colors.whiteColor,
                      onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.handleImportStudentsModal(true)), ["prevent"]))
                    }, null, 8, ["icon", "class", "btn-collapsed", "iconStroke"])
                  ], 2))
                : _createCommentVNode("", true),
              (_ctx.userPermission)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (!_ctx.changeButton)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(["btn-container", { 'btn-pazzei__collapsed': !_ctx.isSearchInputExpanded }])
                        }, [
                          _createVNode(_component_PazzeiBtn, {
                            icon: _ctx.ExportcsvIcon,
                            label: "Exportar CSV",
                            onClick: _ctx.downloadCsvFile,
                            class: _normalizeClass(["btn-csv", { 'btn-pazzei__collapsed': true }]),
                            disabled: _ctx.rows.length === 0,
                            "btn-collapsed": true,
                            iconStroke: _ctx.DefaultTheme().colors.pinkColor
                          }, null, 8, ["icon", "onClick", "disabled", "iconStroke"])
                        ], 2))
                      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createVNode(_component_PazzeiBtn, {
                            icon: _ctx.OptionsIcon,
                            label: "Opções",
                            class: _normalizeClass(["btn-csv", { 'btn-pazzei__collapsed': true }]),
                            "btn-collapsed": true,
                            iconFill: _ctx.DefaultTheme().colors.pinkColor
                          }, null, 8, ["icon", "iconFill"]),
                          _createVNode(_component_q_menu, { "auto-close": "" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_PazzeiOptionsMenu, { optionsCheckBox: _ctx.optionsCheckBox }, null, 8, ["optionsCheckBox"])
                            ]),
                            _: 1
                          })
                        ]))
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          main: _withCtx(() => [
            (_ctx.userPermission)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_TablesComponent, {
                    columns: _ctx.columnsAdmin,
                    rows: _ctx.rows,
                    isFilterSelected: _ctx.isFilterSelected,
                    sizeColumnsTable: "1fr / 2.5fr 1.2fr 2fr 1fr 1.2fr 1.2fr 1.2fr 0.9fr 0.25fr",
                    showOptionsMenu: "",
                    onFiltersApplied: _ctx.handleFiltersApplied,
                    isLoading: _ctx.isLoading,
                    sizeLoading: _ctx.sizeLoading,
                    "height-table": _ctx.heightTable,
                    "width-table": _ctx.widthTableAdm,
                    onSelectedRow: _ctx.checkboxSelect
                  }, null, 8, ["columns", "rows", "isFilterSelected", "onFiltersApplied", "isLoading", "sizeLoading", "height-table", "width-table", "onSelectedRow"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_TablesComponent, {
                    columns: _ctx.columnsUser,
                    rows: _ctx.rows,
                    isFilterSelected: _ctx.isFilterSelected,
                    sizeColumnsTable: _ctx.sizeColumnsUsers,
                    showOptionsMenu: "",
                    onFiltersApplied: _ctx.handleFiltersApplied,
                    isLoading: _ctx.isLoading,
                    sizeLoading: _ctx.sizeLoading,
                    "height-table": _ctx.heightTable,
                    "width-table": _ctx.widthTable
                  }, null, 8, ["columns", "rows", "isFilterSelected", "sizeColumnsTable", "onFiltersApplied", "isLoading", "sizeLoading", "height-table", "width-table"])
                ]))
          ]),
          footer: _withCtx(() => [
            _createVNode(_component_PaginationTableComponent, {
              onPageSizeChange: _ctx.handlePageSizeChange,
              onCurrentPage: _ctx.handleCurrentPage,
              totalElements: _ctx.totalElements,
              isFilterSelected: _ctx.isFilterSelected
            }, null, 8, ["onPageSizeChange", "onCurrentPage", "totalElements", "isFilterSelected"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}