
import ActionButton from '@/components/ActionButton/ActionButton.vue'
import InputIcon from '@/components/InputIcon/InputIcon.vue'
import SecretSecurityLevelBar from '@/components/SecretSecurityLevelBar/SecretSecurityLevelBar.vue'
import { PutLegalRepresentativeDTO } from '@/dtos/PutLegalRepresentativeDTO'
import { LoginResponseDTO } from '@/dtos/ResponseDTOs/LoginResponseDTO'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import { UpdateUserPreRegisteredDTO } from '@/dtos/UpdateUserPreRegisteredDTO'
import authService from '@/services/authService'
import listsService from '@/services/list'
import usersService from '@/services/users'
import DefaultTheme from '@/theme/defaultTheme'
import { AuthUserRole } from '@/types/AuthType'
import validationRulesEmail from '@/utils/vuelidateRules/emailRules'
import validationRulesPassword from '@/utils/vuelidateRules/passwordRules'
import useVuelidate from '@vuelidate/core'
import { helpers, required, sameAs } from '@vuelidate/validators'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { computed, defineComponent, onUnmounted, PropType, provide, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import EyeIcon from '../Icons/default/EyeIcon.vue'
import EyeOffIcon from '../Icons/default/EyeOffIcon.vue'
import Mail01Icon from '../Icons/default/Mail01Icon.vue'
import ModalTerms from '../Terms/ModalTerms.vue'

export default defineComponent({
  name: 'SchoolLoginForm',
  props: {
    inheritUser: { type: Object as PropType<AuthUserRole>, required: false }
  },
  components: {
    InputIcon,
    SecretSecurityLevelBar,
    ActionButton,
    ModalTerms,
  },
  setup(props) {
    const url = new URL(window.location.href)
    history.replaceState(null, '', url.origin + '/completar-cadastro')

    let interval: number | undefined
    const isLoading = ref(false)
    const router = useRouter()
    const modalVisibility = ref(false)
    const messageRequired = 'Campo obrigatório'
    const readonly = ref(true)
    const isRoleStudent = ref(false)
    const isStudentB2b = computed(() => props.inheritUser?.code === 'institution:student')

    const formData = reactive({
      name: '',
      lastName: '',
      email: props.inheritUser?.email ?? '',
      password: '',
      passwordConfirmation: '',
    })

    const rules = computed(() => {
      return {
        name: { required: helpers.withMessage(messageRequired, required) },
        lastName: { required: helpers.withMessage(messageRequired, required) },
        email: validationRulesEmail,
        password: validationRulesPassword,
        passwordConfirmation: {
          required: helpers.withMessage('Este campo é obrigatório', required),
          sameAs: helpers.withMessage('As senhas devem coincidir', sameAs(formData.password)),
        },
      }
    })

    const v$ = useVuelidate(rules, formData)
    const $q = useQuasar()

    const isFormValid = computed(() => {
      return !v$.value.$invalid
    })

    const modalTerms = reactive({
      visibility: false,
      term: '',
    });

    const errors = reactive(() => {
      const [ _name, _lastName, _email, _password, _passwordConfirmation ] = [
        v$.value.name,
        v$.value.lastName,
        v$.value.email,
        v$.value.password,
        v$.value.passwordConfirmation
      ];

      return {
        name: _name.required?.$message,
        lastName: _lastName.required?.$message,
        email: (_email.required.$invalid ? _email.required?.$message : '')
          || (_email.regex.$invalid ? _email.regex?.$message : ''),
        password: (_password.required.$invalid ? _password.required?.$message : '')
          || (_password.minLength.$invalid ? _password.minLength?.$message : '')
          || (_password.minNumber.$invalid ? _password.minNumber?.$message : '')
          || (_password.minUpperLetter.$invalid ? _password.minUpperLetter?.$message : '')
          || (_password.minLowerLetter.$invalid ? _password.minLowerLetter?.$message : '')
          || (_password.maxLength.$invalid ? _password.maxLength?.$message : '')
          || (_password.specialChar.$invalid ? _password.specialChar?.$message : ''),
        passwordConfirmation:
          (_passwordConfirmation.required.$invalid ? _passwordConfirmation.required?.$message : '')
            || (_passwordConfirmation.sameAs.$invalid ? _passwordConfirmation.sameAs?.$message : '')
      }
    })

    const closeModal = () => {
      modalVisibility.value = false;
      formData.name = '';
      formData.lastName = '';
      formData.email = '';
      formData.password = '';
      formData.passwordConfirmation = '';
    }

    provide('formData', formData)

    const submitValidation = async () => {
      return v$.value.$validate();
    }

    const validateField = (field: keyof typeof formData) => {
      v$.value[field].$touch();
    };

    const handleModalOfTerms = (state = !modalTerms.visibility, termType?: string) => {
      modalTerms.visibility = state
      modalTerms.term = termType ?? ''
    }

    const close = (e: Event) => {
      if (e instanceof KeyboardEvent)
        if (e.key === 'Escape') {
          closeModal()
          handleModalOfTerms(false)
        }
    }

    const redirectToAuthentication = () => {
      router.push('/b2b/login')
    }

    const handleRegistration = async (): Promise<void> => {

      // if ((result && validationOfMajority.value) || (result && resultRepresentatives && !validationOfMajority.value) || (result && !isRoleStudent.value)) {
      isLoading.value = true

      let dataMajority: PutLegalRepresentativeDTO | undefined
      try {
        // if (!validationOfMajority.value) {
        //   dataMajority = new PutLegalRepresentativeDTO(formData.legalRepresentative, formData.emailLegalRepresentative, validationOfMajority.value)
        // } else if (validationOfMajority.value) {
        //   dataMajority = new PutLegalRepresentativeDTO(undefined, undefined, validationOfMajority.value)
        // }
        dataMajority = new PutLegalRepresentativeDTO(undefined, undefined, false)

        if (isRoleStudent.value && !isStudentB2b.value) await usersService.put('/legal-representative', dataMajority)

        const data = new UpdateUserPreRegisteredDTO(formData.password, formData.lastName)
        const res = await usersService.patch<ResponseDTO<LoginResponseDTO>>('/complete-registration', data)
        const { token, refreshToken } = res.data.data

        authService.setUserOnSession(token, refreshToken)

        const { data: areas } = await listsService.get<ResponseDTO<Array<string>>>('/areas')
        localStorage.setItem('areas', JSON.stringify(areas.data))

        $q.notify({
          textColor: 'grey-1',
          message: 'Dados salvos com sucesso',
          color: 'green',
          position: 'top',
          classes: 'notify',
        })

        setTimeout(() => {
          if (props.inheritUser?.isStudent) router.push('/')
          else router.push('/usuarios')
        }, 1000)
      } catch (error) {
        isLoading.value = false

        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao salvar dados',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
      // }
    }

    const submit = async () => {
      const result = await submitValidation();
      if (result) handleRegistration()
    }

    provide('formData', formData)

    watch(
      formData,
      async () => {
        if (formData.password) {
          v$.value['password'].$touch();
        }
      },
      { deep: true }
    )

    document.addEventListener('keydown', close)

    onUnmounted(() => {
      document.removeEventListener('keydown', close)
      clearInterval(interval)
    })

    return {
      isPwd: ref(true),
      isPwdOk: ref(true),
      isLoading,
      formData,
      validateField,
      v$,
      errors,
      submit,
      router,
      modalTerms,
      handleModalOfTerms,
      readonly,
      isRoleStudent,
      isStudentB2b,
      redirectToAuthentication,
      isFormValid,
      EyeOffIcon,
      DefaultTheme,
      EyeIcon,
      Mail01Icon
    }
  },
})
