
import { WebSocketService } from '@/services/websocket';
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import StopwatchEyeIcon from '../../Icons/stopwatchEyeIcon.vue';

export default defineComponent({
  name: 'StopWatch',
  components: { StopwatchEyeIcon },
  props: {
    startTime: { type: Number, default: 0 },
    tryId: { type: [Number, String], required: true },
  },
  setup(props) {
    const timer = ref('00:00:00')
    const toggleEye = ref(true)
    const socket = new WebSocketService().stopWatchWs

    let interval: number

    onMounted(() => {
      startTimer()
    })

    onUnmounted(() => {
      clearInterval(interval)

      socket.close()
    })

    const handleToggleEye = () => (toggleEye.value = !toggleEye.value)

    const startTimer = () => {
      let time = millisecondsToSeconds(props.startTime)

      interval = setInterval(() => {
        updateTimer(++time)

        socket.heartbeat({ tryId: props.tryId })
      }, 1000)
    }

    const updateTimer = (time: number) => (timer.value = new Date(time * 1000).toLocaleTimeString('pt-BR', { timeZone: 'UTC' }))

    const millisecondsToSeconds = (milliseconds: number) => milliseconds / 1000

    return { timer, toggleEye, handleToggleEye }
  },
})
