
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiForgotPwd from '@/components/LoginForms/ModalForgotPwd.vue'
import { AuthenticationDTO } from '@/dtos/AuthenticationDTO'
import { InstitutionHasInstitutionPlanDTO } from '@/dtos/ResponseDTOs/InstitutionPlanDTO'
import TallyService from '@/lib/tally/services/tallyService'
import router from '@/router'
import authService from '@/services/authService'
import authenticationService from '@/services/authentication'
import groupsService from '@/services/groups'
import email from '@/utils/vuelidateRules/emailRules'
import { useVuelidate } from '@vuelidate/core'
import { AxiosError } from 'axios'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { Ref, computed, defineComponent, inject, onMounted, onUnmounted, provide, reactive, ref } from 'vue'
import { HandleErrorAxiosResponseDTO } from '../../dtos/HandleErrorAxiosResponseDTO'
import { LoginResponseDTO } from '../../dtos/ResponseDTOs/LoginResponseDTO'
import { ResponseDTO } from '../../dtos/ResponseDTOs/ResponseDTO'
import { UserDTO } from '../../dtos/ResponseDTOs/UserDTO'
import listsService from '../../services/list'
import usersService from '../../services/users'
import {getSubdomainFromLocalStorage} from "@/utils/subdomain";

export default defineComponent({
  name: 'AuthenticationForm',
  components: { PazzeiBtn, PazzeiForgotPwd },
  setup() {
    const isModalVisible = ref(false)
    const isLoading = ref(false)
    const stayLoggedIn = ref(false)

    const showForgotPasswordModal = () => {
      isModalVisible.value = true
    }

    const closeModal = () => (isModalVisible.value = false)
    const rules = computed(() => {
      return {
        email,
      }
    })

    const formData: { email: string; password: string } = reactive({
      email: '',
      password: '',
    })

    const getUser = inject<Ref<UserDTO> | undefined>('userDTO')
    const tempUserDTO = ref<UserDTO | undefined>()

    provide('userDTO', tempUserDTO)

    onMounted(() => {
      formData.email = sessionStorage.getItem('email') as string
    })

    const $q = useQuasar()
    const v$ = useVuelidate(rules, formData)
    onUnmounted(() => {
      if (tempUserDTO.value) getUser!.value = tempUserDTO.value
    })
    const submit = async () => {
      const result = await v$.value.$validate()

      if (result) {
        try {
          const subdomain = getSubdomainFromLocalStorage();
          const loginDTO = new AuthenticationDTO(formData.email, formData.password, false, stayLoggedIn.value, subdomain)
          isLoading.value = true

          const res = await authenticationService.post<ResponseDTO<LoginResponseDTO>>('/', loginDTO)

          authService.setUserOnLocal(res.data.data.token, res.data.data.refreshToken)
          localStorage.setItem('keeplogged', String(stayLoggedIn.value))

          const { data } = await listsService.get<ResponseDTO<Array<string>>>('/areas')
          localStorage.setItem('areas', JSON.stringify(data.data))

          const responseMe = await usersService.get<ResponseDTO<UserDTO>>('/me')
          const userId = responseMe.data.data.id;

          tempUserDTO.value = new UserDTO({
            name: responseMe.data.data.name,
            lastName: responseMe.data.data.lastName,
            email: responseMe.data.data.email,
            roleCode: responseMe.data.data.roleCode,
            registrationCompleted: responseMe.data.data.registrationCompleted,
            credits: responseMe.data.data.credits,
            autoRenew: responseMe.data.data.autoRenew,
            groupId: 0,
            createdAt: responseMe.data.data.createdAt,
            updatedAt: responseMe.data.data.updatedAt,
          })

          sessionStorage.setItem(
            'userInfos',
            JSON.stringify({
              credits: tempUserDTO.value.credits,
              autoRenew: tempUserDTO.value.autoRenew,
              institutionId: responseMe.data.data.institutionId,
              roleCode: tempUserDTO.value.roleCode,
              registrationCompleted: tempUserDTO.value.registrationCompleted,
              createdAt: tempUserDTO.value.createdAt,
              updatedAt: tempUserDTO.value.updatedAt,
            })
          )
          sessionStorage.setItem('warningHasBeenShown', 'false')


          const userLogged = localStorage.getItem('token') ?? sessionStorage.getItem('token')
          if (userLogged) {
            const userInfos: any = sessionStorage.getItem('userInfos');
            const institutionId = JSON.parse(userInfos)?.institutionId;
            let institutionPlanId;

            if(institutionId) {
              try {
                const { data: entity } = await groupsService.get<ResponseDTO<InstitutionHasInstitutionPlanDTO>>(
                  `/institution_plans/single?id=${institutionId}`
                );

                institutionPlanId = entity.data.institutionPlanId;
              } catch (error) {
                localStorage.removeItem('plan')
              }

              TallyService.loginForm(userId, institutionPlanId, JSON.parse(userInfos));
            }
          }

          const userScoped = authService.getHigherScoped()
          const userCanViewHomePage = authService.validatePermissions('self:lists:lists:enem-simulation')
          if (!userScoped) {
            isLoading.value = false
            $q.notify({
              textColor: 'grey-1',
              message: 'Erro ao fazer login, tente novamente',
              color: 'red',
              position: 'top',
              classes: 'notify',
            })
            router.push('/login')
          } else if (userScoped === 'self') {
            if (userCanViewHomePage) router.push('/')
            else router.push(localStorage.getItem('lastPage') ?? '/')
          } else if (userScoped === 'all') router.push('/instituicoes')
          else router.push('/usuarios')
        } catch (err) {
          if (err instanceof AxiosError) {
            const { error } = new HandleErrorAxiosResponseDTO(err)

            $q.notify({
              textColor: 'grey-1',
              message: error.userMessage,
              color: 'red',
              position: 'top',
              classes: 'notify',
            })
          }
        } finally {
          isLoading.value = false
        }
      }
    }

    return {
      closeModal,
      showForgotPasswordModal,
      isModalVisible,
      v$,
      formData,
      submit,
      stayLoggedIn,
      isLoading,
      isPwd: ref(true),
    }
  },
})
