import { defaultColors } from "@/theme/tokens/colors";
import { ColorsType } from "@/theme/types";

export const PazzeiBrandColors: ColorsType = {
  ...defaultColors,

  primary: '#884BB2',
  secondary: '#6F369C',
  primaryColor: '#400376',
  pinkColor: '#ff0094',

  primary25: '#FEFAFF',
  primary50: '#FCF2FF',
  primary100: '#EEDAF5',
  primary200: '#DDBBED',
  primary300: '#C191DB',
  primary400: '#A76BC9',
  primary500: '#884BB2',
  primary600: '#6F369C',
  primary700: '#511B85',
  primary800: '#400376',
  primary900: '#2D005E',

  secondary25: '#FFFAFD',
  secondary50: '#FFF0F8',
  secondary100: '#FFDBEE',
  secondary200: '#FFADD7',
  secondary300: '#FF80BF',
  secondary400: '#FF45A5',
  secondary500: '#FF0094',
  secondary600: '#E30084',
  secondary700: '#BF0A77',
  secondary800: '#990061',
  secondary900: '#6B0044',

  rose25: '#FFF5F6',
  rose50: '#FFF1F3',
  rose100: '#FFE4E8',
  rose200: '#FECDD6',
  rose300: '#FEA3B4',
  rose400: '#FD6F8E',
  rose500: '#F63D68',
  rose600: '#E31B54',
  rose700: '#E31B54',
  rose800: '#A11043',
  rose900: '#89123E',
};

