
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiInput from '@/components/Generics/PazzeiInput.vue'
import { EditUserInfosDTO } from '@/dtos/EditUserInfosDTO'
import { UserInfosDTO } from '@/dtos/UserInfosDTO'
import usersService from '@/services/users'
import { cpfValidation } from '@/utils/common/cpfValidation'
import emailRule from '@/utils/vuelidateRules/emailRules'
import useVuelidate from '@vuelidate/core'
import { helpers, minLength, required } from '@vuelidate/validators'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { computed, defineComponent, onMounted, provide, reactive, ref, watch } from 'vue'
import { PutLegalRepresentativeDTO } from '../../dtos/PutLegalRepresentativeDTO'
import { ResponseDTO } from '../../dtos/ResponseDTOs/ResponseDTO'
import LayoutEditing from '../Layouts/LayoutEditing.vue'

export default defineComponent({
  name: 'MyProfile',
  components: { PazzeiInput, PazzeiBtn, LayoutEditing },
  setup() {
    const isLoading = ref(false)
    const loading = ref(true)
    const $q = useQuasar()
    const readonly = ref(true)
    const userInfos = ref(new UserInfosDTO('', 0, '', '', '', '', '', '', false, '', ''))
    const formData = reactive({
      name: '',
      lastName: '',
      telephone: '',
      cpf: '',
    })
    const formChanged = ref(false)
    const formLegalRepresentative = reactive({
      legalRepresentative: '',
      emailLegalRepresentative: '',
    })
    const messageRequired = 'Este campo é obrigatório'
    const validationOfMajority = ref(false)
    const isMount = ref(false)
    const hasCpf = ref(false)
    let cancelMajority: boolean | undefined = false

    provide('userDTO', formData)

    const rules = computed(() => {
      return {
        name: { required: helpers.withMessage(messageRequired, required) },
        telephone: { minLength: helpers.withMessage('Informe um número de telefone válido', minLength(15)) },
        lastName: { minLength: helpers.withMessage('O sobrenome do usuário deve ter no mínimo 3 caracteres', minLength(3)) },
        cpf: !hasCpf.value && formData.cpf !== '' && { cpfValid: helpers.withMessage('Insira um cpf válido', (value: string) => cpfValidation(value)) },
      }
    })

    const rulesMajority = computed(() => {
      if (!validationOfMajority.value)
        return {
          legalRepresentative: { required: helpers.withMessage(messageRequired, required), minLength: helpers.withMessage('O nome deve conter pelo menos 3 caracteres ', minLength(3)) },
          emailLegalRepresentative: emailRule,
        }
      else return {}
    })

    const v$ = useVuelidate(rules, formData)
    const majority$ = useVuelidate(rulesMajority, formLegalRepresentative)

    onMounted(async () => {
      isMount.value = true
      const { data: infos } = await usersService.get('/me')
      userInfos.value = infos.data

      formLegalRepresentative.legalRepresentative = infos.data.legalRepresentative ?? ''
      formLegalRepresentative.emailLegalRepresentative = infos.data.emailLegalRepresentative ?? ''

      if (
        (formLegalRepresentative.legalRepresentative === '' && formLegalRepresentative.emailLegalRepresentative === '') ||
        (formLegalRepresentative.legalRepresentative === ' ' && formLegalRepresentative.emailLegalRepresentative === ' ')
      )
        validationOfMajority.value = true

      try {
        const formatCpfMask = userInfos.value.cpf ? userInfos.value.cpf.substring(0, 3) + '.***.***-' + userInfos.value.cpf.substring(9) : ''
        formData.telephone = userInfos.value.telephone !== null ? userInfos.value.telephone : ''
        formData.name = userInfos.value.name
        formData.lastName = userInfos.value.lastName !== null ? userInfos.value.lastName : ''
        formData.cpf = formatCpfMask
        hasCpf.value = formData.cpf !== ''
        cancelMajority = userInfos.value.isLegalAge
      } finally {
        loading.value = false
      }
    })

    const notification = (success: boolean, message: string) => {
      if (success) {
        $q.notify({
          textColor: 'grey-1',
          message: message,
          color: 'green',
          position: 'top',
          classes: 'notify',
        })
      } else {
        $q.notify({
          textColor: 'grey-1',
          message: message,
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    watch(
      () => [formData.name, formData.lastName, formData.telephone, formData.cpf, formLegalRepresentative.legalRepresentative, formLegalRepresentative.emailLegalRepresentative],
      (newValue, prevValues) => {
        if (!isMount.value)
          for (let i = 0; i < newValue.length; i++) {
            if (newValue[i] !== prevValues[i]) {
              if (newValue[i] !== '') {
                formChanged.value = true
              } else {
                formChanged.value = false
              }
            }
          }
      }
    )

    watch(validationOfMajority, () => {
      if (validationOfMajority.value && !isMount.value && (formLegalRepresentative.legalRepresentative !== '' || formLegalRepresentative.emailLegalRepresentative !== '')) {
        formChanged.value = true
      } else {
        formChanged.value = false
      }
    })

    const cancelChanges = () => {
      formData.name = userInfos.value.name
      formData.lastName = userInfos.value.lastName ?? ''
      const fone =
        userInfos.value.telephone !== null
          ? userInfos.value.telephone
              .replace(/\D/g, '')
              .replace(/^(\d\d)(\d)/g, '($1) $2')
              .replace(/(\d{5})(\d)/, '$1-$2')
          : ''
      formData.telephone = fone
      formData.cpf = userInfos.value.cpf?.replace(/^(\d{3})(\*{3})(\*{3})(\d{2})$/, '$1.$2.$3-$4') ?? ''
      formLegalRepresentative.legalRepresentative = userInfos.value.legalRepresentative ?? ''
      formLegalRepresentative.emailLegalRepresentative = userInfos.value.emailLegalRepresentative ?? ''
      validationOfMajority.value = cancelMajority ?? false
      readonly.value = true
    }

    const data = ref<EditUserInfosDTO>()

    const submitProfile = async () => {
      const result = await v$.value.$validate()
      const resultMajority = await majority$.value.$validate()

      if (validationOfMajority.value) {
        formLegalRepresentative.legalRepresentative = ''
        formLegalRepresentative.emailLegalRepresentative = ''
      }

      try {
        isLoading.value = true
        if (result && resultMajority) {
          data.value = new EditUserInfosDTO(formData.name, formData.lastName, formData.telephone.replace(/(\(|\)| |-)/gm, ''), !hasCpf.value ? formData.cpf.replace(/\.|-/gm, '') : undefined)
          handleRequestUpdateUser()

          const dataMajority = new PutLegalRepresentativeDTO(formLegalRepresentative.legalRepresentative, formLegalRepresentative.emailLegalRepresentative, validationOfMajority.value)
          const res = await usersService.put<ResponseDTO<any>>('/legal-representative', dataMajority)
          userInfos.value.legalRepresentative = res.data.data.legalRepresentative
          userInfos.value.emailLegalRepresentative = res.data.data.emailLegalRepresentative
          cancelMajority = res.data.data.isLegalAge
          hasCpf.value = formData.cpf !== ''
        }
      } catch (error) {
        console.error(error)
        notification(false, 'Erro ao editar dados')
      } finally {
        isLoading.value = false
        formChanged.value = false
      }
    }

    const handleRequestUpdateUser = () => {
      usersService
        .patch('/update', data.value)
        .then((res) => {
          formData.cpf = res.data.data.cpf?.replace(/^(\d{3})(\*{3})(\*{3})(\d{2})$/, '$1.$2.$3-$4') ?? ''
          userInfos.value.cpf = res.data.data.cpf ?? ''
          userInfos.value.name = res.data.data.name ?? ''
          userInfos.value.lastName = res.data.data.lastName ?? ''
          userInfos.value.telephone = res.data.data.telephone ?? ''
          notification(true, 'Dados salvos com sucesso')
          isLoading.value = false
          readonly.value = true
        })
        .catch(() => {
          isLoading.value = false
          readonly.value = true
        })
    }

    return {
      formData,
      loading,
      userInfos,
      isLoading,
      submitProfile,
      v$,
      cancelChanges,
      readonly,
      isMount,
      hasCpf,
      formLegalRepresentative,
      majority$,
      formChanged,
      validationOfMajority,
    }
  },
})
