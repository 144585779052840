import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9aa77f10"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "debug"
}
const _hoisted_2 = { class: "question-nav" }
const _hoisted_3 = ["id"]
const _hoisted_4 = {
  key: 0,
  class: "in-doubt"
}
const _hoisted_5 = { class: "question-container" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 1,
  class: "cta-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomCTA = _resolveComponent("CustomCTA")!
  const _component_Alternative = _resolveComponent("Alternative")!
  const _component_AlternativesSelector = _resolveComponent("AlternativesSelector")!
  const _component_PazzeiButton = _resolveComponent("PazzeiButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["div-questions", { 'last-div': _ctx.questionNumber === _ctx.totalQuestions ? true : false }])
  }, [
    (_ctx.showDebugId && _ctx.questionDTO?.externalId)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, "Id externo para consulta: " + _toDisplayString(_ctx.questionDTO?.externalId), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass({
          'question-number': true,
          'question-in-doubt': _ctx.isDoubt
        })
      }, [
        _createElementVNode("span", {
          id: 'questao' + _ctx.questionNumber
        }, " Questão " + _toDisplayString(_ctx.questionNumber && _ctx.questionNumber < 10 ? `0${_ctx.questionNumber}` : _ctx.questionNumber), 9, _hoisted_3),
        (_ctx.isDoubt)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Em dúvida"))
          : _createCommentVNode("", true)
      ], 2),
      (!_ctx.isPrinting)
        ? (_openBlock(), _createBlock(_component_CustomCTA, {
            key: 0,
            icon: _ctx.ChangeAnswerIcon,
            iconStroke: _ctx.DefaultTheme().colors.secondaryGray,
            actionLabel: 'Alterar resposta',
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleDisplaySelectedOnly(false)))
          }, null, 8, ["icon", "iconStroke"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.textBaseHTML)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass([_ctx.maxWidthTotal ? 'max-width-total' : 'file-html']),
            innerHTML: _ctx.textBaseHTML
          }, null, 10, _hoisted_6))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        id: "question",
        innerHTML: _ctx.questionHTML,
        class: _normalizeClass([_ctx.maxWidthTotal ? 'max-width-total' : 'file-html']),
        height: "80px",
        width: "100%"
      }, null, 10, _hoisted_7),
      (!_ctx.showLoading)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["question-wrapper-alternatives-container", { 'padding-modal-alternatives': _ctx.paddingRightAlternatives }])
          }, [
            _createVNode(_component_AlternativesSelector, {
              summationTotal: _ctx.summationModel,
              "onUpdate:summationTotal": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.summationModel) = $event)),
              modelValue: _ctx.alternativesModel,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.alternativesModel) = $event)),
              "question-id": _ctx.questionId,
              type: _ctx.questionType.includes('Múltipla escolha') ? 'mcq' : 'summation'
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alternatives, (value, index) => {
                  return (_openBlock(), _createBlock(_component_Alternative, {
                    id: value.id,
                    index: index,
                    key: value.id,
                    wrong: 
                _ctx.alternativeSelectedOnInstantFeedback.length && _ctx.correctAlternativeIds.length && _ctx.showAnswer
                  ? _ctx.alternativeSelectedOnInstantFeedback.includes(value.id) && !_ctx.correctAlternativeIds.includes(value.id)
                  : false
              ,
                    selected: _ctx.alternativesModel?.alternatives.includes(value.id),
                    discarded: _ctx.alternativesModel?.discardedAlternatives?.includes(value.id),
                    "not-selectable": (_ctx.alternativeSelectedOnInstantFeedback.length !== 0 && _ctx.showAnswer) || _ctx.feedback,
                    correct: 
                (_ctx.alternativeSelectedOnInstantFeedback && _ctx.showAnswer) || _ctx.correctAlternativesSummary.length
                  ? _ctx.correctAlternativeIds.includes(value.id) || _ctx.correctAlternativesSummary.includes(value.id)
                  : false
              ,
                    "display-selected-only": _ctx.displaySelectedOnly && !_ctx.isPrinting,
                    "is-checking-questions": true,
                    "onUpdate:discarded": _ctx.updateDiscarded
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(["v-html", [_ctx.maxWidthTotal ? 'file-html-alternatives-modal' : 'file-html-alternatives']]),
                        key: index,
                        innerHTML: _ctx.alternativeHTML[value.awsKey],
                        width: "100%"
                      }, null, 10, _hoisted_8))
                    ]),
                    _: 2
                  }, 1032, ["id", "index", "wrong", "selected", "discarded", "not-selectable", "correct", "display-selected-only", "onUpdate:discarded"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["summationTotal", "modelValue", "question-id", "type"])
          ], 2))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.displaySelectedOnly)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_PazzeiButton, {
            classes: 'text-center',
            text: 'Cancelar alterações',
            style: {"width":"164px"},
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.cancelChanges()))
          }),
          _createVNode(_component_PazzeiButton, {
            classes: 'text-center secondary with-background-color smaller',
            text: 'Salvar alterações',
            style: {"width":"145px"},
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('save-question', _ctx.alternativesModel), _ctx.handleDisplaySelectedOnly(true)))
          })
        ]))
      : _createCommentVNode("", true)
  ], 2))
}