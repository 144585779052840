
  import B2bAccessLayout from '@/components/Layouts/AccessB2B/B2bAccessLayout.vue'
  import { defineComponent } from 'vue'
  
  export default defineComponent({
    name: 'AccessB2BPage',
    components: {
      B2bAccessLayout,
    },
    setup() {
      const heading = {
        title: 'Bem-vindo ao Pazzei!',
        subtitle: 'A plataforma de lista on-line do Super Professor®.'
      }
  
      return {
        heading,
      }
    },
  })
  