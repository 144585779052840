import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b8f20060"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "flex justify-center items-center",
  style: {"height":"100%","width":"100%","margin-top":"20em"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentModalHistoryTest = _resolveComponent("ContentModalHistoryTest")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_q_circular_progress = _resolveComponent("q-circular-progress")!
  const _component_PazzeiCardSubject = _resolveComponent("PazzeiCardSubject")!
  const _component_MainLayoutSubWrapper = _resolveComponent("MainLayoutSubWrapper")!
  const _component_MainLayoutWrapper = _resolveComponent("MainLayoutWrapper")!
  const _component_GenericLayout = _resolveComponent("GenericLayout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Modal, {
      modelValue: _ctx.isOpenTestHistoryModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isOpenTestHistoryModal) = $event)),
      height: _ctx.heightModal,
      width: _ctx.widthModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ContentModalHistoryTest, {
          onClose: _ctx.closeHistoricProficiency,
          subjectName: _ctx.subjectName
        }, null, 8, ["onClose", "subjectName"])
      ]),
      _: 1
    }, 8, ["modelValue", "height", "width"]),
    _createVNode(_component_GenericLayout, null, {
      default: _withCtx(() => [
        _createVNode(_component_MainLayoutWrapper, null, _createSlots({ _: 2 }, [
          (_ctx.isLoading)
            ? {
                name: "main",
                fn: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_q_circular_progress, {
                      indeterminate: "",
                      size: "70px",
                      color: "secondary"
                    })
                  ])
                ]),
                key: "0"
              }
            : {
                name: "main",
                fn: _withCtx(() => [
                  (_ctx.proficiencySubjectsInfoInProgress.length > 0)
                    ? (_openBlock(), _createBlock(_component_MainLayoutSubWrapper, { key: 0 }, {
                        title: _withCtx(() => [
                          _createElementVNode("div", { class: "div-title-header" }, [
                            _createElementVNode("p", { class: "q-ma-none h1-title text-size-24" }, "Testes iniciados")
                          ])
                        ]),
                        main: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.proficiencySubjectsInfoInProgress, (item, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: `listsNotStarted-${index}`,
                              class: "div-lists"
                            }, [
                              _createVNode(_component_PazzeiCardSubject, { subjectInfo: item }, null, 8, ["subjectInfo"])
                            ]))
                          }), 128))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.proficiencySubjectsInfoNotStarted.length > 0)
                    ? (_openBlock(), _createBlock(_component_MainLayoutSubWrapper, { key: 1 }, {
                        title: _withCtx(() => [
                          _createElementVNode("div", { class: "div-title-header" }, [
                            _createElementVNode("p", { class: "q-ma-none h1-title text-size-24" }, "Testes não iniciados")
                          ])
                        ]),
                        main: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.proficiencySubjectsInfoNotStarted, (item, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: `listsInProgress-${index}`,
                              class: "div-lists"
                            }, [
                              _createVNode(_component_PazzeiCardSubject, { subjectInfo: item }, null, 8, ["subjectInfo"])
                            ]))
                          }), 128))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.proficiencySubjectsInfoFinished.length > 0)
                    ? (_openBlock(), _createBlock(_component_MainLayoutSubWrapper, { key: 2 }, {
                        title: _withCtx(() => [
                          _createElementVNode("div", { class: "div-title-header" }, [
                            _createElementVNode("p", { class: "q-ma-none h1-title text-size-24" }, "Testes concluídos")
                          ])
                        ]),
                        main: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.proficiencySubjectsInfoFinished, (item, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: `listsFinished-${index}`,
                              class: "div-lists"
                            }, [
                              _createVNode(_component_PazzeiCardSubject, { subjectInfo: item }, null, 8, ["subjectInfo"])
                            ]))
                          }), 128))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                key: "1"
              }
        ]), 1024)
      ]),
      _: 1
    })
  ], 64))
}