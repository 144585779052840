import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RegistrationForm = _resolveComponent("RegistrationForm")!
  const _component_LoginLayout = _resolveComponent("LoginLayout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("title", null, _toDisplayString(_ctx.title), 1),
    _createVNode(_component_LoginLayout, {
      title: `Crie aqui sua conta ${_ctx.title}`,
      tab: "registration",
      hasTab: "",
      textCenter: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_RegistrationForm)
      ]),
      _: 1
    }, 8, ["title"])
  ], 64))
}