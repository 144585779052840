import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22fb1c4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-header" }
const _hoisted_2 = { class: "table-body" }
const _hoisted_3 = {
  key: 1,
  class: "without-register"
}
const _hoisted_4 = {
  key: 2,
  style: {"height":"90%"}
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "without-register text-size-16"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_FiltersTableComponent = _resolveComponent("FiltersTableComponent")!
  const _component_q_circular_progress = _resolveComponent("q-circular-progress")!
  const _component_MenuListComponent = _resolveComponent("MenuListComponent")!

  return (_openBlock(), _createElementBlock("div", {
    class: "table-container",
    style: _normalizeStyle({ height: _ctx.heightTable })
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showCheckBox)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(["table-col", { 'table-col-padding': _ctx.hasPadding }])
          }, [
            _createVNode(_component_q_checkbox, {
              modelValue: _ctx.isChecked,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isChecked) = $event)),
              onClick: _ctx.toggleAllRows,
              disable: _ctx.rowsHandler.length === 0
            }, null, 8, ["modelValue", "onClick", "disable"])
          ], 2))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, index) => {
        return (_openBlock(), _createElementBlock("span", {
          key: index,
          style: _normalizeStyle(_ctx.showCheckBox ? 'padding-top: 0.4em' : ''),
          class: _normalizeClass(["table-col", { 'table-col-padding': _ctx.hasPadding }])
        }, _toDisplayString(column.label), 7))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isFilterSelected)
        ? (_openBlock(), _createBlock(_component_FiltersTableComponent, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_q_circular_progress, {
              indeterminate: "",
              size: _ctx.sizeLoading,
              color: "secondary"
            }, null, 8, ["size"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.rows && _ctx.rows.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rowsHandler, (row, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: _normalizeClass({ 'table-body-row': !_ctx.showCheckBox, 'table-body-row-checkbox': _ctx.showCheckBox })
                    }, [
                      (_ctx.showCheckBox)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass(["table-col-checkbox", { 'table-col-padding': _ctx.hasPadding }])
                          }, [
                            _createVNode(_component_q_checkbox, {
                              modelValue: row.isChecked,
                              "onUpdate:modelValue": ($event: any) => ((row.isChecked) = $event),
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedItem()))
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ], 2))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, index) => {
                        return (_openBlock(), _createElementBlock("span", {
                          key: index,
                          class: _normalizeClass(["table-col", [_ctx.hasPadding ? 'table-col-padding' : '', _ctx.getColumnClass(column, row), _ctx.showCheckBox ? 'padding-top' : '']])
                        }, _toDisplayString(_ctx.getColumnValue(column, row)), 3))
                      }), 128)),
                      _createElementVNode("span", {
                        class: _normalizeClass(["table-col", { 'table-col-padding': _ctx.hasPadding }])
                      }, [
                        (_ctx.showOptionsMenu)
                          ? (_openBlock(), _createBlock(_component_MenuListComponent, {
                              key: 0,
                              objectRow: _ctx.handleToObject(row)
                            }, null, 8, ["objectRow"]))
                          : _createCommentVNode("", true)
                      ], 2)
                    ], 2))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, "Nenhum resultado encontrado"))
          ]))
    ])
  ], 4))
}