import { FontSizes } from "../types";

export const defaultFonts: FontSizes = {
  eight: '0.5rem',
  ten: '0.625rem',
  twelve: '0.75rem',
  fourteen: '0.875rem',
  sixteen: '1rem',
  eighteen: '1.125rem',
  twenty: '1.25rem',
  twentyFour: '1.5rem',
  twentyEight: '1.75rem',
  thirtyFour: '2.125rem',
}