
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO';
import listsService from '@/services/list';
import { WebSocketService } from '@/services/websocket';
import { SingleTryType } from '@/types/SingleTryType';
import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import StopwatchEyeIcon from '../../Icons/stopwatchEyeIcon.vue';

export default defineComponent({
  name: 'StopWatch',
  components: { StopwatchEyeIcon },
  emits: ['time-is-up'],
  props: {
    startTime: { type: Number, default: 0 },
    tryId: { type: [Number, String], required: true },
    listId: { type: String, required: true },
  },
  setup(props, { emit }) {
    const timer = ref('00:00:00')
    const toggleEye = ref(true)
    const socket = new WebSocketService().stopWatchWs

    onMounted(() => {
      startTimer()
    })

    onUnmounted(() => {
      localStorage.setItem('tryId', JSON.stringify({ tryId: props.tryId }));
      startTimer();
    })

    const handleToggleEye = () => (toggleEye.value = !toggleEye.value)

    let time: number

    const startTimer = async () => {
      const listId = props.listId;
      const tryId = props.tryId;

      const { data: answer } = await listsService.get<ResponseDTO<SingleTryType>>(
        '/tries/single',
        { params: { tryId, listId }}
      );

      const timeElapsed = millisecondsToSeconds(answer.data.duration)
      time = minutesToSeconds(props.startTime) - timeElapsed;

      setInterval(() => {
        socket.heartbeat({ tryId: props.tryId })
        
        updateTimer(--time)
      }, 1000)
    }

    const updateTimer = (time: number) => (timer.value = new Date(time * 1000).toLocaleTimeString('pt-BR', { timeZone: 'UTC' }))

    const minutesToSeconds = (minutes: number) => minutes * 60

    const millisecondsToSeconds = (milliseconds: number) => milliseconds / 1000

    watch(timer, () => {
      if (Math.trunc(time) <= 0) {
        updateTimer(0);
        emit('time-is-up');
        localStorage.removeItem('tryId');

        setTimeout(() => {
          socket.close()
        }, 10000)
      }
    })

    return { timer, toggleEye, handleToggleEye }
  },
})
