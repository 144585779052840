import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsCheckBox, (option, index) => {
    return _withDirectives((_openBlock(), _createBlock(_component_q_item, {
      class: "item-menu-list",
      clickable: "",
      key: index,
      onClick: ($event: any) => (_ctx.handleOptionClick(option))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_item_section, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(option.label), 1)
          ]),
          _: 2
        }, 1024)
      ]),
      _: 2
    }, 1032, ["onClick"])), [
      [_directive_close_popup]
    ])
  }), 128))
}