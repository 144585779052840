
import Button from '@/components/Library/Button/index.vue'
import { CreateListDTO } from '@/dtos/CreateListPage/CreateListDTO'
import { ListTagDTO, ListTagKeyEnum } from '@/dtos/CreateListPage/ListTagDTO'
import { HandleErrorAxiosResponseDTO } from '@/dtos/HandleErrorAxiosResponseDTO'
import { ListsInfosType } from '@/dtos/ListRetrieveDTO'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import listsService from '@/services/list'
import DefaultTheme from '@/theme/defaultTheme'
import { AuxTree } from '@/utils/hardCoded/enemModule/weightedQuestionTree/types'
import { AxiosError } from 'axios'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, PropType, ref } from 'vue'
import CardKnowledgeArea from './cardKnowledgeArea/CardKnowledgeArea.vue'
import { OnSelectedAreaType } from './enemModule/types'
import { formatDataToSend, weighting } from './WeightingFunctions'

export default defineComponent({
  name: 'EnemTestSummary',
  components: {
    Button,
    CardKnowledgeArea,
  },
  props: {
    knowledgeAreas: { type: Object as PropType<OnSelectedAreaType>, default: () => ({}) },
    cache: { type: Object, default: () => ({}) },
    summary: { type: Boolean, default: false },
    lockResources: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const $q = useQuasar()

    const instantFeedback = ref(false)
    const isForeignLanguageSelected = ref('')
    const hasError = ref(false)
    const showAlreadyAnsweredQuestion = ref(false)
    const shuffle = ref(false)
    const dataListToSend = ref<CreateListDTO>()
    const subjects = ref<AuxTree[]>([])
    const isSpanishForeignLanguageSelected = ref(false)
    const questionsToAddOnList = ref<Array<number>>([])
    const isLoading = ref(false)

    const handlePreviousBtnClick = () => {
      emit('update:cache', props.knowledgeAreas)
      emit('update:summary', false)
    }

    const handleNextBtnClick = async () => {
      if (props.knowledgeAreas.areas.some((area) => area.hasForeignLanguage) && !isForeignLanguageSelected.value) {
        hasError.value = true
        return
      }
      isLoading.value = true
      isSpanishForeignLanguageSelected.value = isForeignLanguageSelected.value === 'Espanhol'
      hasError.value = false

      subjects.value = await weighting(props.knowledgeAreas, isSpanishForeignLanguageSelected.value)
      try {
        questionsToAddOnList.value = await formatDataToSend(shuffle.value, !showAlreadyAnsweredQuestion.value, subjects.value)
        finishListCreation()
      } catch (err) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao buscar questões',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
        isLoading.value = false
      }
    }

    const finishListCreation = async () => {
      try {
        const date = new Date().toLocaleString().split(',')[0]
        const areaTags = props.knowledgeAreas.areas.map((area) => new ListTagDTO(ListTagKeyEnum.AREA, area.title))
        const categoryTag = new ListTagDTO(ListTagKeyEnum.CATEGORY, props.knowledgeAreas.type.toLowerCase())
        const shuffleTag = new ListTagDTO(ListTagKeyEnum.SHUFFLE, String(shuffle.value))
        const includeAlreadyAnsweredTag = new ListTagDTO(ListTagKeyEnum.ALREADY_ANSWERED, String(!showAlreadyAnsweredQuestion.value))
        let chosenLanguageTag: ListTagDTO | undefined

        if (props.knowledgeAreas.areas.some((area) => area.hasForeignLanguage) && isForeignLanguageSelected.value) {
          chosenLanguageTag = new ListTagDTO(ListTagKeyEnum.CHOSEN_LANGUAGE, isForeignLanguageSelected.value)
        }

        const tags = chosenLanguageTag
          ? [...areaTags, categoryTag, shuffleTag, includeAlreadyAnsweredTag, chosenLanguageTag]
          : [...areaTags, categoryTag, shuffleTag, includeAlreadyAnsweredTag]

        dataListToSend.value = new CreateListDTO(
          `${props.knowledgeAreas.type} - ${date}`,
          DefaultTheme().colors.tertiary500,
          instantFeedback.value,
          questionsToAddOnList.value,
          undefined,
          undefined,
          undefined,
          undefined,
          tags
        )
        const listCreatedResponse = await listsService.post<ResponseDTO<ListsInfosType>>('/', dataListToSend.value)
        if (listCreatedResponse.status === 200) {
          $q.notify({
            textColor: 'grey-1',
            message: 'Lista criada com sucesso',
            color: 'green',
            position: 'top',
            classes: 'notify',
          })
        } else {
          $q.notify({
            textColor: 'grey-1',
            message: 'Erro ao criar lista, tente novamente',
            color: 'red',
            position: 'top',
            classes: 'notify',
          })
          return
        }

        const listId = listCreatedResponse.data.data.id
        const { data } = await listsService.post('/tries/new-try', { listId })

        setTimeout(() => {
          window.location.assign(`/responder-lista-enem/${listId}/${data.data.id}`);
        }, 500)
      } catch (err) {
        if (err instanceof AxiosError) {
          const { error } = new HandleErrorAxiosResponseDTO(err)

          $q.notify({
            textColor: 'grey-1',
            message: error.userMessage,
            color: 'yellow-8',
            position: 'top',
            classes: 'notify',
          })
        }
      }
    }

    return { instantFeedback, isForeignLanguageSelected, hasError, showAlreadyAnsweredQuestion, shuffle, handlePreviousBtnClick, handleNextBtnClick, isLoading }
  },
})
