
import ActionButton from '@/components/ActionButton/ActionButton.vue'
import PazzeiModalAlert from '@/components/GenericModals/PazzeiModalAlert/PazzeiModalAlert.vue'
import EyeIcon from '@/components/Icons/default/EyeIcon.vue'
import EyeOffIcon from '@/components/Icons/default/EyeOffIcon.vue'
import Mail01Icon from '@/components/Icons/default/Mail01Icon.vue'
import PhoneIcon from '@/components/Icons/default/PhoneIcon.vue'
import ThumbDownIcon from '@/components/Icons/default/ThumbDownIcon.vue'
import InputIcon from '@/components/InputIcon/InputIcon.vue'
import ModalEmailConfirmation from '@/components/LoginForms/ModalEmailConfirmation.vue'
import SecretSecurityLevelBar from '@/components/SecretSecurityLevelBar/SecretSecurityLevelBar.vue'
import ModalTerms from '@/components/Terms/ModalTerms.vue'
import { ConfirmationEmailDTO } from '@/dtos/ConfirmationEmailDTO'
import { ValidationEmailDTO } from '@/dtos/ValidationEmailDTO'
import router from '@/router'
import authenticationService from '@/services/authentication'
import authService from '@/services/authService'
import usersService from '@/services/users'
import DefaultTheme from '@/theme/defaultTheme'
import validationRulesEmail from '@/utils/vuelidateRules/emailRules'
import validationRulesPassword from '@/utils/vuelidateRules/passwordRules'
import telephoneRules from '@/utils/vuelidateRules/telephoneRules'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required, sameAs, } from '@vuelidate/validators'
import { computed, defineComponent, onUnmounted, provide, reactive, ref, watch } from 'vue'

export default defineComponent({
  name: 'B2bSignUpInviteForm',
  components: {
    InputIcon,
    SecretSecurityLevelBar,
    ActionButton,
    ModalEmailConfirmation,
    ModalTerms,
    PazzeiModalAlert,
  },
  props: {
    queryEmail: { type: String, required: false },
  },
  setup(props) {
    // let interval: number;
    const modalVisibility = ref(false);
    const messageRequired = 'Campo obrigatório';
    const isLoading = ref(false);

    const rules = computed(() => {
      return {
        name: { required: helpers.withMessage(messageRequired, required) },
        lastName: { required: helpers.withMessage(messageRequired, required) },
        email: validationRulesEmail,
        telephone: telephoneRules,
        password: validationRulesPassword,
        passwordConfirmation: {
          required: helpers.withMessage('Este campo é obrigatório', required),
          sameAs: helpers.withMessage('As senhas devem coincidir', sameAs(formData.password)),
        },
      }
    })

    const formData = reactive({
      name: '',
      lastName: '',
      email: props.queryEmail ?? '',
      telephone: '',
      password: '',
      passwordConfirmation: '',
    })

    const v$ = useVuelidate(rules, formData)

    const isFormValid = computed(() => {
      return !v$.value.$invalid
    })

    const modalTerms = reactive({
      visibility: false,
      term: '',
    });

    const modalExistingAccountAlert = reactive({
      show: false,
    });

    const errors = reactive(() => {
      const [ _name, _lastName, _email, _telephone, _password, _passwordConfirmation ] = [
        v$.value.name,
        v$.value.lastName,
        v$.value.email,
        v$.value.telephone,
        v$.value.password,
        v$.value.passwordConfirmation
      ];

      return {
        name: _name.required?.$message,
        lastName: _lastName.required?.$message,
        email: (_email.required.$invalid ? _email.required?.$message : '')
          || (_email.regex.$invalid ? _email.regex?.$message : ''),
        telephone: (_telephone.required.$invalid ? _telephone.required?.$message : '')
          || (_telephone.minLength.$invalid ? _telephone.minLength?.$message : ''),
        password: (_password.required.$invalid ? _password.required?.$message : '')
          || (_password.minLength.$invalid ? _password.minLength?.$message : '')
          || (_password.minNumber.$invalid ? _password.minNumber?.$message : '')
          || (_password.minUpperLetter.$invalid ? _password.minUpperLetter?.$message : '')
          || (_password.minLowerLetter.$invalid ? _password.minLowerLetter?.$message : '')
          || (_password.maxLength.$invalid ? _password.maxLength?.$message : '')
          || (_password.specialChar.$invalid ? _password.specialChar?.$message : ''),
        passwordConfirmation:
          (_passwordConfirmation.required.$invalid ? _passwordConfirmation.required?.$message : '')
            || (_passwordConfirmation.sameAs.$invalid ? _passwordConfirmation.sameAs?.$message : '')
      }
    })

    const closeModal = () => {
      modalVisibility.value = false;
      formData.name = '';
      formData.lastName = '';
      formData.email = '';
      formData.telephone = '';
      formData.password = '';
      formData.passwordConfirmation = '';
    }

    const submitValidation = async () => {
      return v$.value.$validate();
    }

    const validateField = (field: keyof typeof formData) => {
      v$.value[field].$touch();
    };

    const handleModalOfTerms = (state = !modalTerms.visibility, termType?: string) => {
      modalTerms.visibility = state
      modalTerms.term = termType ?? ''
    }

    const handleModalOfExistingAccountAlert = (state = !modalExistingAccountAlert.show) => {
      modalExistingAccountAlert.show = state
    }

    const close = (e: Event) => {
      if (e instanceof KeyboardEvent)
        if (e.key === 'Escape') {
          closeModal()
          handleModalOfTerms(false)
        }
    }

    const redirectToAuthentication = () => {
      router.push('/b2b/login')
    }

    provide('formData', formData)

    watch(
      formData,
      async () => {
        if (formData.password) {
          v$.value['password'].$touch();
        }
      },
      { deep: true }
    )

    document.addEventListener('keydown', close)

    onUnmounted(() => {
      document.removeEventListener('keydown', close)
      // clearInterval(interval)
    })

    const handleRegistration = () => {
      const validationEmail = new ValidationEmailDTO(formData.email)

      usersService.post('/email', validationEmail).then((res) => {
        const userExists = res.data.data.exists
        if (!userExists) {
          const confirmation = new ConfirmationEmailDTO(formData.email, formData.name)

          authenticationService.post('/request-email-verification', confirmation).then((res) => {
            authService.setOtpToken(res.data.data.otpToken)
            modalVisibility.value = true
          })
          localStorage.setItem('firstAccess', 'true');
        } else {
          sessionStorage.setItem('email', formData.email)

          handleModalOfExistingAccountAlert(true);
        }
      })
    }
    const submit = async () => {
      const result = await submitValidation();

      if (result) handleRegistration()
    }

    return {
      v$,
      formData,
      submit,
      isLoading,
      isPwd: ref(true),
      modalTerms,
      modalVisibility,
      modalExistingAccountAlert,
      closeModal,
      handleModalOfTerms,
      handleModalOfExistingAccountAlert,
      isPwdOk: ref(true),
      redirectToAuthentication,
      isFormValid,
      validateField,
      errors,
      EyeIcon,
      EyeOffIcon,
      PhoneIcon,
      Mail01Icon,
      ThumbDownIcon,
      DefaultTheme
    }
  },
})
