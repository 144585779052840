import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 16 18",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M11.8333 7.46712V5.80046C11.8333 3.49927 9.96785 1.63379 7.66667 1.63379C5.36548 1.63379 3.5 3.49927 3.5 5.80046V7.46712M7.66667 11.2171V12.8838M5 16.6338H10.3333C11.7335 16.6338 12.4335 16.6338 12.9683 16.3613C13.4387 16.1216 13.8212 15.7392 14.0608 15.2688C14.3333 14.734 14.3333 14.0339 14.3333 12.6338V11.4671C14.3333 10.067 14.3333 9.36693 14.0608 8.83215C13.8212 8.36174 13.4387 7.97929 12.9683 7.73961C12.4335 7.46712 11.7335 7.46712 10.3333 7.46712H5C3.59987 7.46712 2.8998 7.46712 2.36502 7.73961C1.89462 7.97929 1.51217 8.36174 1.27248 8.83215C1 9.36693 1 10.067 1 11.4671V12.6338C1 14.0339 1 14.734 1.27248 15.2688C1.51217 15.7392 1.89462 16.1216 2.36502 16.3613C2.8998 16.6338 3.59987 16.6338 5 16.6338Z",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}