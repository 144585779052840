import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "title", {}, undefined, true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["main-wrapper", { 'main-wrapper-answer-list': _ctx.answerList, [`main-wrapper--${_ctx.page}`]: !!_ctx.page }])
    }, [
      _renderSlot(_ctx.$slots, "main", {}, undefined, true)
    ], 2)
  ], 64))
}