
import { defineComponent, inject, ref } from 'vue'
import { InstitutionConfigType } from '@/types/InstitutionConfigType'
export default defineComponent({
  name: 'PazzeiTab',
  props: {
    tab: String,
  },
  setup(props) {
    const tabRef = ref(props.tab)
    const handleClick = (item: string) => (tabRef.value = item)

    const institutionConfig = inject<InstitutionConfigType>('institutionConfig');

    return {
      tabRef,
      handleClick,
      isWhiteLabel: institutionConfig?.isWhiteLabel,
    }
  },
})
