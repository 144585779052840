
import ListSummaryCard from '@/components/Application/ListSummaryCard/index.vue'
import { ListSummaryInfos } from '@/components/Application/ListSummaryModal/helpers/listSummaryInfos'
import GroupIcon from '@/components/Icons/default/GroupIcon.vue'
import ListSummaryLayout from '@/components/Layouts/Modal/ListSummaryLayout.vue'
import Button from '@/components/Library/Button/index.vue'
import Modal from '@/components/Library/Modal/index.vue'
import DefaultTheme from '@/theme/defaultTheme'
import { computed, defineComponent, inject, onMounted, onUnmounted, onUpdated, PropType, Ref, ref, watch } from 'vue'

export default defineComponent({
  name: 'ListSummaryModal',
  emits: ['update:visibility', 'confirm'],
  props: {
    listSummaryInfos: { type: Object as PropType<ListSummaryInfos>, default: new ListSummaryInfos(), required: true },
    visibility: { type: Boolean, default: false, required: true },
  },
  components: {
    Button,
    ListSummaryCard,
    ListSummaryLayout,
    Modal,
    GroupIcon,
  },
  setup(props, { emit }) {
    const open = ref(false)
    const isCreatingList = inject<Ref<boolean>>('isCreatingList')

    const innerWidth = ref(window.innerWidth)
    const width = ref('31em')
    const height = ref('40em')

    const setNewTab = () => {
      innerWidth.value = window.innerWidth
    }

    window.addEventListener('resize', setNewTab)

    onMounted(() => {
      if (innerWidth.value <= 768) {
        width.value = '90%'
        height.value = '80%'
      }
    })

    const totalListQuestions = computed(() => {
      return Object.keys(props.listSummaryInfos.summary)
        .map((key) => props.listSummaryInfos.summary[key].total)
        .reduce((a, b) => a + b, 0)
    })

    watch(
      () => open.value,
      () => emit('update:visibility', open.value)
    )

    onUpdated(() => (open.value = props.visibility))
    onUnmounted(() => window.removeEventListener('resize', setNewTab))

    return { open, isCreatingList, totalListQuestions, width, height, DefaultTheme }
  },
})
