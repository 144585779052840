
import SchoolLoginForm from '@/components/PreRegistrationForms/PreRegistrationForm.vue'
import { defineComponent, onMounted, ref } from 'vue'
import B2bAuthLayout from '@/components/Layouts/B2bAuthLayout/B2bAuthLayout.vue'
import { useRoute, useRouter } from 'vue-router'
import authService from '@/services/authService'
import { AuthUserRole } from '@/types/AuthType'

export default defineComponent({
  name: 'PlanSchoolLoginPage',
  components: { B2bAuthLayout, SchoolLoginForm },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const user = ref<AuthUserRole | undefined>(undefined);

    const removeQueryParam = (param: string) => {
      const { [param]: _removed, ...query } = route.query;
      router.replace({ query });
    }

    const getTokenFromParams = () => {
      const { id } = route.params;
      const token = id;

      removeQueryParam('cod');

      return token ? token as string : undefined;
    }

    const heading = {
      title: 'Bem-vindo ao Pazzei!',
      subtitle: 'Tudo pronto para começar a resolver suas listas?'
    }

    onMounted(async () => {
      if (localStorage.getItem('token')) localStorage.removeItem('token');

      const token = getTokenFromParams();

      if (!token) {
        router.push('/login');
        return;
      }

      authService.setUserOnSession(token);

      const userPermissions = authService.getPermissions();

      if (!userPermissions || !userPermissions.id) {
        router.push('/login');
        return;
      }

      user.value = {
        id: userPermissions.id,
        code: userPermissions.roleCode,
        description: userPermissions.roleDescription,
        isStudent: userPermissions.roleDescription === 'Aluno',
        email: userPermissions.email,
      }
    })

    return {
      heading,
      user,
    }
  },
})
