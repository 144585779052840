
import axios from 'axios'
import { defineComponent, PropType, ref, watch } from 'vue'
import { CorrectAndSelectedAlternativeDTO } from '../../dtos/CorrectAndSelectedAlternativeDTO'
import { UserResponseDTO } from '../../dtos/ResponseDTOs/UserResponseDTO'
import { ProficiencyQuestionArtifacts, ProficiencyQuestionState } from '../../types/ProficiencySubjectType'
import Alternative from '../Application/alternativesSelector/Alternative.vue'
import AlternativesSelector from '../Application/alternativesSelector/AlternativesSelector.vue'
import { AlternativesSelectorModelValueType } from '../Application/alternativesSelector/types/AlternativesSelectorModelValueType'
import Skeleton from '../Library/Skeleton/index.vue'

export default defineComponent({
  name: 'QuestionWrapperProficiency',
  emits: ['handle-feedback', 'update:modelValue', 'get-question-type', 'get-question-answer', 'get-tags'],
  props: {
    questionSource: {
      type: Object as PropType<ProficiencyQuestionState>,
      required: true,
    },
    questionId: Number,
    answers: Object as PropType<Array<UserResponseDTO>>,
    listId: Number,
    modelValue: { type: Object as PropType<AlternativesSelectorModelValueType>, default: () => ({}) },
    correctAndSelect: Object as PropType<CorrectAndSelectedAlternativeDTO>,
    selected: Number,
    correctAlternatives: { type: Array as PropType<Array<number>>, default: () => [] },
    modal: Boolean,
    modalAnsweredQuestion: Boolean,
    maxWidthTotal: Boolean,
    summary: Boolean,
    paddingRightAlternatives: Boolean,
  },
  components: {
    Alternative,
    AlternativesSelector,
    Skeleton,
  },
  setup(props, { emit }) {
    const alternativesModel = ref<AlternativesSelectorModelValueType>()
    const summationModel = ref(0)
    const questionNotDownloaded = ref(false)
    const showLoading = ref(true)
    const isHtmlLoading = ref(true)

    const questionArtifacts = ref<ProficiencyQuestionArtifacts | Record<string, never>>({})

    const handleQuestionChange = async () => {
      showLoading.value = true
      alternativesModel.value = undefined

      const questionURL = process.env.VUE_APP_AWS_QUESTIONS_BASE_URL + props.questionSource.question.awsKey
      const textBaseURL = process.env.VUE_APP_AWS_QUESTIONS_TEXTS_BASE_URL + props.questionSource.question?.baseTextAwsKey
      const alternativesURLs = props.questionSource.question.alternatives.map((alternative, index) => ({
        [index]: process.env.VUE_APP_QUESTIONS_ALTERNATIVES_BASE_URL + alternative.awsKey,
        id: alternative.id,
      }))

      let textBaseHTML = ''
      try {
        const { data: textHTML } = await axios.get<string>(textBaseURL)

        textBaseHTML = textHTML
      } catch (error) {
        console.error(error)
      }

      try {
        const { data: questionHTML } = await axios.get<string>(questionURL)
        const alternativeHTMLs = await Promise.all(
          alternativesURLs.map(async (alternative, index) => {
            const { data } = await axios.get<string>(alternative[index])
            return { data, id: alternative.id }
          })
        )

        questionArtifacts.value = {
          questionHTML: questionHTML,
          textBaseHTML: textBaseHTML,
          alternatives: alternativeHTMLs.map((alternative) => ({
            id: alternative.id,
            html: alternative.data,
          })),
        }

        showLoading.value = false
      } catch (error) {
        await (window as any).com.wiris.js.JsPluginViewer.parseElement(document.getElementById('app'))
        console.error(error)
        isHtmlLoading.value = false
      }
    }

    watch(() => props.questionSource, handleQuestionChange, { deep: true })

    watch(
      alternativesModel,
      () => {
        emit('update:modelValue', alternativesModel.value)
      },
      { deep: true }
    )

    return {
      alternativesModel,
      questionArtifacts,
      summationModel,
      questionNotDownloaded,
      showLoading,
      isHtmlLoading,
    }
  },
})
