import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-285afd5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "cta-wrapper"
}
const _hoisted_2 = { class: "main-actions-container" }
const _hoisted_3 = {
  key: 1,
  class: "cta-wrapper cta-wrapper__mobile"
}
const _hoisted_4 = { class: "main-actions-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PazzeiButton = _resolveComponent("PazzeiButton")!
  const _component_ListSummary = _resolveComponent("ListSummary")!

  return (!_ctx.isMobile)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_PazzeiButton, {
            classes: 'secondary with-background-color text-center',
            text: !_ctx.isUserCheckingQuestions ? 'Enviar respostas' : 'Finalizar e enviar respostas',
            onClick: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.isUserCheckingQuestions && !_ctx.instantFeedback ? _ctx.showCheckQuestionsComponent() : _ctx.$emit('finalize-list-handler', true))),
            disabled: _ctx.mainCtaDisabled
          }, null, 8, ["text", "disabled"]),
          _createVNode(_component_PazzeiButton, {
            classes: 'text-center with-border',
            text: _ctx.exitListButton.label,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('exit-list-handler')))
          }, null, 8, ["text"])
        ]),
        _createVNode(_component_ListSummary, {
          "current-question-id": !_ctx.isUserCheckingQuestions ? _ctx.currentQuestionId : undefined,
          onHandleQuestionChange: _ctx.onQuestionChange,
          "related-questions": _ctx.relatedQuestions,
          "user-is-checking-questions": _ctx.isUserCheckingQuestions
        }, null, 8, ["current-question-id", "onHandleQuestionChange", "related-questions", "user-is-checking-questions"])
      ]))
    : (_ctx.isMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_PazzeiButton, {
              classes: 'secondary with-background-color text-center',
              text: !_ctx.isUserCheckingQuestions ? 'Enviar respostas' : 'Finalizar e enviar respostas',
              onClick: _cache[2] || (_cache[2] = ($event: any) => (!_ctx.isUserCheckingQuestions && !_ctx.instantFeedback ? _ctx.showCheckQuestionsComponent() : _ctx.$emit('finalize-list-handler', true))),
              disabled: _ctx.mainCtaDisabled
            }, null, 8, ["text", "disabled"]),
            _createVNode(_component_PazzeiButton, {
              classes: 'text-center with-border',
              text: _ctx.exitListButton.label,
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('exit-list-handler')))
            }, null, 8, ["text"])
          ]),
          _createVNode(_component_ListSummary, {
            "current-question-id": !_ctx.isUserCheckingQuestions ? _ctx.currentQuestionId : undefined,
            onHandleQuestionChange: _ctx.onQuestionChange,
            relatedQuestions: _ctx.relatedQuestions,
            userIsCheckingQuestions: _ctx.isUserCheckingQuestions
          }, null, 8, ["current-question-id", "onHandleQuestionChange", "relatedQuestions", "userIsCheckingQuestions"])
        ]))
      : _createCommentVNode("", true)
}