
import LoadingSpinner from '@/components/Generics/LoadingSpinner/LoadingSpinner.vue';
import DefaultTheme from '@/theme/defaultTheme';
import { computed, defineComponent } from 'vue';

const availableButtonType = ['button', 'submit'];

export default defineComponent({
  name: 'PazzeiButton',
  components: { LoadingSpinner },
  props: {
    type: { type: String, default: 'button', validator: (value: string) => availableButtonType.includes(value) },
    fullWidth: { type: Boolean, default: false },
    text: { type: String },
    icon: [Object, Function], 
    alt: { type: String },
    classes: { type: String },
    iconRight: Boolean,
    disabled: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    iconFill: { type: String, default: DefaultTheme().colors.primaryColor },
    iconStroke: { type: String, default: DefaultTheme().colors.primaryColor },
    iconSize: { type: [String, Number], default: 24 }, 
    textClasses: { type: Object, default: () => ({ 
      text: 'text-14px',
      fontWeight: 'font-weight-600'
    })},
  },
  setup(props) {
    const iconProps = computed(() => ({
      fill: props.iconFill,
      stroke: props.iconStroke,
      size: props.iconSize,
    }));

    return { iconProps };
  },
});
