
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiSelect from '@/components/Generics/PazzeiSelect.vue'
import PazzeiSelectNew from '@/components/Generics/PazzeiSelectNew.vue'
import getStates from '@/services/apis/ibgeStates'
import usersService from '@/services/users'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, inject, onMounted, onUnmounted, Ref, ref, watch } from 'vue'

export default defineComponent({
  name: 'FormOne',
  components: {
    PazzeiSelect,
    PazzeiSelectNew,
    PazzeiBtn,
  },
  setup() {
    const $q = useQuasar()
    const controller = new AbortController()
    const target = ref<{ state: Array<string>; course: Array<string> }>({
      state: [],
      course: [],
    })
    const schoolingOptions = ['Ensino fundamental', 'Ensino Médio', 'Ensino Superior', 'Pós-Graduação', 'Mestrado', 'Doutorado']
    const trainingOptions = ['ENEM', 'Vestibular', 'Escola', 'Outros']
    const statesOptions = ref<string[]>([])
    const targetOptions = ref<string[]>([])
    const isExam = ref(false)
    const formData = inject<Ref<Array<{ key: string; value: string }>>>('formData')

    onMounted(async () => {
      const { statesName } = await getStates()
      statesOptions.value = statesName

      usersService
        .get('/users-tags/target-course', { signal: controller.signal })
        .then((res) => {
          targetOptions.value = res.data.data
        })
        .catch(() => {
          $q.notify({
            textColor: 'grey-1',
            message: 'Erro ao obter tags de usuário',
            color: 'red',
            timeout: 2000,
            position: 'top',
            classes: 'notify',
          })
        })
    })

    onUnmounted(() => controller.abort())

    const handleItems = (items: Array<string>, key: string) => {
      if (formData) {
        items.forEach((value) => {
          if (!formData.value.find((it) => it.value === value)) formData.value.push({ key, value })
        })

        formData.value = formData.value.filter((item) => {
          if (item.key === key) return items.includes(item.value)
          else return true
        })
      }
    }

    watch(
      target,
      () => {
        handleItems(target.value.state, 'target_state')
        handleItems(target.value.course, 'target_course')
      },
      { deep: true }
    )

    const input = (value: string, inputName: string) => {
      if (!formData) return
      if (inputName === 'select-1') {
        if (formData.value.some((item) => item.key === 'scholarity')) {
          formData.value.map((item, index) => {
            if (item.key === 'scholarity') {
              formData.value[index] = { key: 'scholarity', value }
            }
          })
        } else {
          formData.value.push({ key: 'scholarity', value })
        }
      }

      if (inputName === 'select-2') {
        if (formData.value.some((item) => item.key === 'target')) {
          formData.value.map((item, index) => {
            if (item.key === 'target') {
              formData.value[index] = { key: 'target', value }
            }
          })
        } else {
          formData.value.push({ key: 'target', value })
        }

        if (value === 'Vestibular') {
          isExam.value = true
        } else {
          isExam.value = false
        }
      }
    }

    return {
      target,
      formData,
      schoolingOptions,
      trainingOptions,
      statesOptions,
      targetOptions,
      input,
      isExam,
    }
  },
})
