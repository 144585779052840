import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 14 17",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M4.66667 0.633789C4.04783 0.633789 3.45434 0.8746 3.01675 1.30325C2.57917 1.73189 2.33333 2.31326 2.33333 2.91946V5.49769C2.70433 5.3697 3.09517 5.28055 3.5 5.23598V2.91946C3.5 2.61636 3.62292 2.32568 3.84171 2.11135C4.0605 1.89703 4.35725 1.77662 4.66667 1.77662H8.16667V4.63371C8.16667 5.08836 8.35104 5.52438 8.67923 5.84587C9.00742 6.16735 9.45254 6.34796 9.91667 6.34796H12.8333V14.3478C12.8333 14.6509 12.7104 14.9416 12.4916 15.1559C12.2728 15.3702 11.9761 15.4906 11.6667 15.4906H11.5675C11.7005 15.8598 11.7005 16.2643 11.5675 16.6335H11.6667C12.2855 16.6335 12.879 16.3927 13.3166 15.964C13.7542 15.5354 14 14.954 14 14.3478V5.67826C13.9996 5.22377 13.8149 4.78803 13.4867 4.46686L10.0882 1.13549C9.92558 0.976334 9.73259 0.850109 9.5202 0.764025C9.30781 0.677942 9.08019 0.633687 8.85033 0.633789H4.66667ZM12.5918 5.20513H9.91667C9.76196 5.20513 9.61358 5.14492 9.50419 5.03776C9.39479 4.9306 9.33333 4.78526 9.33333 4.63371V2.01319L12.5918 5.20513ZM6.52867 13.5512C5.7019 14.1569 4.67164 14.4332 3.64525 14.3247C2.61885 14.2162 1.6725 13.7309 0.996655 12.9665C0.320811 12.202 -0.0343595 11.2152 0.00262336 10.2046C0.0396062 9.19397 0.465997 8.23459 1.19598 7.51951C1.92597 6.80444 2.90536 6.38676 3.93706 6.35053C4.96875 6.3143 5.97616 6.66222 6.75654 7.32426C7.53692 7.9863 8.03234 8.91331 8.1431 9.91874C8.25386 10.9242 7.97173 11.9334 7.3535 12.7433L10.3285 15.6575C10.438 15.7646 10.4996 15.91 10.4997 16.0616C10.4998 16.2133 10.4385 16.3587 10.3291 16.466C10.2197 16.5733 10.0713 16.6337 9.9165 16.6338C9.7617 16.6339 9.6132 16.5738 9.50367 16.4666L6.52867 13.5524V13.5512ZM7 10.3479C7 9.59014 6.69271 8.86342 6.14573 8.32762C5.59875 7.79181 4.85688 7.4908 4.08333 7.4908C3.30978 7.4908 2.56792 7.79181 2.02094 8.32762C1.47396 8.86342 1.16667 9.59014 1.16667 10.3479C1.16667 11.1056 1.47396 11.8323 2.02094 12.3681C2.56792 12.904 3.30978 13.205 4.08333 13.205C4.85688 13.205 5.59875 12.904 6.14573 12.3681C6.69271 11.8323 7 11.1056 7 10.3479Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}