
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ButtonGroup',
  props: {
    buttonList: { type: Array as PropType<Array<string>>, default: () => [] },
    modelValue: { type: String, default: '' },
  },
  setup() {
    return {}
  },
})
