type DataColumn = {
  label: string
  key: string
}

export const columnsGroups: DataColumn[] = [
  { key: 'name', label: 'Nome do Grupo' },
  { key: 'owner', label: 'Criado por' },
  { key: 'members', label: 'N° de Alunos' },
]

export const columnsUserGroup: DataColumn[] = [
  { key: 'readableId', label: 'ID' },
  { key: 'name', label: 'Nome' },
  { key: 'telephone', label: 'Telefone' },
  { key: 'email', label: 'E-mail' },
]

export const filtersUserGroup = [
  {
    type: 'input',
    placeholder: 'Pesquisar',
    width: '100%',
    maxLength: 80,
    value: '',
    key: 'readableId',
  },
  {
    type: 'input',
    placeholder: 'Pesquisar',
    width: '100%',
    maxLength: 80,
    value: '',
    key: 'name',
  },
  {
    type: 'input',
    placeholder: 'Pesquisar',
    width: '100%',
    maxLength: 15,
    value: '',
    key: 'telephone',
  },
  {
    type: 'input',
    placeholder: 'Pesquisar',
    width: '100%',
    maxLength: 80,
    value: '',
    key: 'email',
  },
]
