import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "tabs-container-wrapper",
    style: _normalizeStyle({ height: _ctx.height, width: _ctx.width })
  }, [
    _createElementVNode("nav", {
      class: "tabs-container-content",
      style: _normalizeStyle({ justifyContent: _ctx.align === 'justify' ? 'center' : _ctx.align })
    }, [
      _createElementVNode("ul", {
        class: _normalizeClass(["tabs-container-items", { 'tabs-container-items--vertical': _ctx.vertical }]),
        style: _normalizeStyle({ width: _ctx.align === 'justify' ? '100%' : 'auto' })
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 6)
    ], 4)
  ], 4))
}