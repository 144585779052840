import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ea7544a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "type"]
const _hoisted_2 = { class: "button-content-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "button-icon-container"
}
const _hoisted_4 = {
  key: 2,
  class: "button-icon-container"
}
const _hoisted_5 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MountListsIcon = _resolveComponent("MountListsIcon")!
  const _component_q_circular_progress = _resolveComponent("q-circular-progress")!

  return (_openBlock(), _createElementBlock("div", {
    class: "button-container-wrapper",
    style: _normalizeStyle({ height: _ctx.height, width: _ctx.width })
  }, [
    _createElementVNode("button", {
      class: _normalizeClass(["button", { [`button--${_ctx.variant}`]: _ctx.variant }]),
      disabled: _ctx.disabled || _ctx.loading,
      type: _ctx.type
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.leftIcon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "left-icon", {}, () => [
                _createVNode(_component_MountListsIcon, {
                  fill: _ctx.DefaultTheme().colors.whiteColor
                }, null, 8, ["fill"])
              ], true)
            ]))
          : _createCommentVNode("", true),
        (!_ctx.loading)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass(["button-label-content", { [`button--${_ctx.variant}`]: _ctx.variant, 'see-answer': _ctx.seeAnswer }])
            }, _toDisplayString(_ctx.label), 3))
          : _createCommentVNode("", true),
        (_ctx.rightIcon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_MountListsIcon, {
                fill: _ctx.DefaultTheme().colors.whiteColor
              }, null, 8, ["fill"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_q_circular_progress, {
                indeterminate: "",
                size: "20px",
                color: "secondary"
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ], 10, _hoisted_1)
  ], 4))
}