import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["stroke"]
const _hoisted_4 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 67 66",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("rect", {
      x: "5.5",
      y: "5",
      width: "56",
      height: "56",
      rx: "28",
      fill: _ctx.DefaultTheme().colors.error100
    }, null, 8, _hoisted_2),
    _createElementVNode("rect", {
      x: "5.5",
      y: "5",
      width: "56",
      height: "56",
      rx: "28",
      stroke: _ctx.DefaultTheme().colors.error50,
      "stroke-width": "10"
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      d: "M38.1667 26V25.0667C38.1667 23.7599 38.1667 23.1065 37.9123 22.6074C37.6886 22.1683 37.3317 21.8114 36.8926 21.5876C36.3935 21.3333 35.7401 21.3333 34.4333 21.3333H32.5667C31.2599 21.3333 30.6065 21.3333 30.1074 21.5876C29.6683 21.8114 29.3114 22.1683 29.0877 22.6074C28.8333 23.1065 28.8333 23.7599 28.8333 25.0667V26M31.1667 32.4167V38.25M35.8333 32.4167V38.25M23 26H44M41.6667 26V39.0667C41.6667 41.0268 41.6667 42.0069 41.2852 42.7556C40.9496 43.4142 40.4142 43.9496 39.7556 44.2852C39.0069 44.6667 38.0268 44.6667 36.0667 44.6667H30.9333C28.9731 44.6667 27.9931 44.6667 27.2444 44.2852C26.5858 43.9496 26.0504 43.4142 25.7148 42.7556C25.3333 42.0069 25.3333 41.0268 25.3333 39.0667V26",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_4)
  ], 8, _hoisted_1))
}