import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6cc79f76"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loading-container"
}
const _hoisted_2 = { class: "alternatives-control-description" }
const _hoisted_3 = { class: "alternatives-control-series-label" }
const _hoisted_4 = { class: "alternatives-control-missing-alternatives" }
const _hoisted_5 = { class: "alternatives-control-description" }
const _hoisted_6 = { class: "alternatives-control-missing-alternatives" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_circular_progress = _resolveComponent("q-circular-progress")!
  const _component_QuestionNumberLabel = _resolveComponent("QuestionNumberLabel")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_q_circular_progress, {
          indeterminate: "",
          size: "40px",
          color: "secondary"
        })
      ]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_ctx.isListEnemModuleAndAtYourPace)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.questionsSplittedIntoSeries, (items, idx) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "alternatives-control-wrapper",
                key: idx
              }, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(items.name), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.series, (series, idx) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: idx }, [
                    _createElementVNode("div", _hoisted_3, "Série " + _toDisplayString(series.number), 1),
                    _createElementVNode("div", _hoisted_4, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(series.questions, (item) => {
                        return (_openBlock(), _createBlock(_component_QuestionNumberLabel, {
                          key: item.key,
                          "question-number": item.value,
                          "question-label": item.label,
                          "question-id": Number(item.key),
                          state: _ctx.handleQuestionState(item.isDoubt, item.isAnswered, item.isCorrect, _ctx.screen),
                          "is-doubt": item.isDoubt
                        }, null, 8, ["question-number", "question-label", "question-id", "state", "is-doubt"]))
                      }), 128))
                    ])
                  ], 64))
                }), 128))
              ]))
            }), 128))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.questions, (items, idx) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "alternatives-control-wrapper",
                key: idx
              }, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.isListEnemModule ? items.name : _ctx.listName), 1),
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.questions, (item) => {
                    return (_openBlock(), _createBlock(_component_QuestionNumberLabel, {
                      key: item.key,
                      "question-number": item.value,
                      "question-label": item.label,
                      "question-id": Number(item.key),
                      state: _ctx.handleQuestionState(item.isDoubt, item.isAnswered, item.isCorrect, _ctx.screen),
                      "is-doubt": item.isDoubt
                    }, null, 8, ["question-number", "question-label", "question-id", "state", "is-doubt"]))
                  }), 128))
                ])
              ]))
            }), 128))
      ], 64))
}