
import ElipseIcon from '@/components/Icons/default/ElipseIcon.vue';
import DefaultTheme from '@/theme/defaultTheme';
import { defineComponent, inject, ref } from 'vue';

export default defineComponent({
  name: 'MenuTableComponent',
  components: {
    ElipseIcon
  },
  props: {
    objectRow: Object,
  },

  setup(props) {
    const isLoading = ref(true)
    const optionsMenu = inject<Array<any>>('optionsMenu')
    const heightLoading = inject<string>('heightLoading')
    const emitValueToParent = inject<(value: any) => void>('emitValueToParent')
    let lastClickedObjectRow: any = null

    const sendValueToParent = async () => {
      if (props.objectRow !== lastClickedObjectRow) {
        isLoading.value = true
        lastClickedObjectRow = props.objectRow

        try {
          await new Promise((resolve) => setTimeout(resolve, 100))

          emitValueToParent?.(props.objectRow)
        } catch (error) {
          console.error(error)
        } finally {
          isLoading.value = false
        }
      } else {
        emitValueToParent?.(props.objectRow)
      }
    }

    const handleClick = (callback: (prop: any) => void) => {
      callback(props.objectRow)
    }

    return {
      optionsMenu,
      handleClick,
      sendValueToParent,
      isLoading,
      heightLoading,
      DefaultTheme
    }
  },
})
