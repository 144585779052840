
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PazzeiDot',
  props: {
    count: { type: Number, default: 1 },
    serie: { type: Number, default: 1 },
  },
  setup(props) {
    const getColor = (idx: number) => (idx === props.serie - 1 ? 'var(--pink-color)' : 'var(--gray-200)')

    return {
      getColor,
    }
  },
})
