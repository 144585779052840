export interface LargeAreasType {
  id: string
  icon: string
  title: string
  subtitle: string
  hasForeignLanguage: boolean
  order: number
}

export interface OnSelectedAreaType {
  areas: Array<LargeAreasType>
  type: string
}

export class SelectedAreasToWeighting {
  areas: Array<AreasTitles> | Array<LargeAreasType>
  type: string

  constructor(areas: Array<AreasTitles>, type: string) {
    this.areas = areas
    this.type = type
  }
}

export class AreasTitles {
  title: string

  constructor(title: string) {
    this.title = title
  }
}
