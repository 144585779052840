
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StepperComponent',
  props: {
    currentView: { type: String, default: 'StateComponent' },
  },
  setup() {
    return {}
  },
})
