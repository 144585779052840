
import { ListsInfosType } from '@/dtos/ListRetrieveDTO';
import { listStatus } from '@/enum/listStatusEnum';
import DefaultTheme from '@/theme/defaultTheme';
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { PropType, computed, defineComponent, reactive, ref } from 'vue';

export default defineComponent({
  name: 'PazzeiWeekLists',
  emits: ['editGoal', 'showListPreview', 'selectedList'],
  props: {
    iconStroke: { type: String, default: DefaultTheme().colors.secondaryGray },
    iconFill: { type: String, default: DefaultTheme().colors.secondaryGray },
    iconSize: { type: [String, Number], default: 20 },
    lists: { type: Array as PropType<ListsInfosType[]>, default: () => ([]) },
  },
  components: { },
  setup(props, { emit }) {
    const $q = useQuasar()
    const isLoading = ref(false)

    const lists = [...props.lists].sort((a, b) => {
      const first = new Date(a.listTries[0].createdAt).getTime();
      const last = new Date(b.listTries[0].createdAt).getTime();
      return first - last;
    });

    const dateParser = (date: Date) => {
      return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() ));
    }

    const getListsOfTheWeek = () => {
      const today = new Date();
      const dayOfWeek = today.getDay();
      const diff = today.getDate() - dayOfWeek;
      const firstDayOfWeek = new Date(today.setDate(diff));
      const lastDayOfWeek = new Date(firstDayOfWeek);
      lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

      return lists.filter((list: ListsInfosType) => {
        let listDate = new Date(list.listTries[0].createdAt);
        return dateParser(listDate) >= dateParser(firstDayOfWeek) && dateParser(listDate) <= dateParser(lastDayOfWeek);
      });
    }

    const flag = ref(0);
    const filteredLists = getListsOfTheWeek();

    filteredLists.forEach((el) => {
      if (el.listTries[0].isFinished) {
        flag.value++;
      }
    })

    const weekLists = reactive({
      numberOfLists: filteredLists.length,
      numberOfListsConcluded: flag.value
    })

    const progress = computed(() => (weekLists.numberOfLists ? Math.round(weekLists.numberOfListsConcluded / weekLists.numberOfLists * 100) : 0))

    const handleClick = async (list: ListsInfosType) => {
      if (
        list.status?.code === listStatus.comingSoon.code
        || (list.config && !list.config.status)
      ) {
        return;
      }

      const lastTry = list.listTries[list.listTries.length - 1];
      const tryFinished = list.listTries && list.listTries.length > 0 ? list.listTries[list.listTries.length - 1].isFinished : false;
      const showFeedback = list.returnFeedback || list.config?.showResult;
      const isExpired = list.status ? list.status.code === listStatus.expired.code : false;

      if (lastTry) {
        if (tryFinished === true) {
          if (showFeedback || isExpired) window.location.href = `/gabarito/${list.id}/${lastTry.id}`
          else {
            $q.notify({
              textColor: 'white',
              message: 'O professor bloqueou a visualização do gabarito desta lista',
              color: 'yellow-8',
              position: 'top',
              classes: 'notify',
            })
          }
        } else if (isExpired && !showFeedback) {
          $q.notify({
            textColor: 'white',
            message: 'Essa lista não foi finalizada, você não poderá ver o gabarito',
            color: 'yellow-8',
            position: 'top',
            classes: 'notify',
          })
        } else {
          emit('showListPreview', true);
          emit('selectedList', list)
        }
      }
    }

    return { isLoading, weekLists, progress, getListsOfTheWeek, handleClick }
  },
})
