import { onMounted, onUnmounted, reactive, toRefs } from 'vue'

export function useScreenSize() {
  const sizes = reactive({
    browserWidth: window.innerWidth,
    deviceWidth: screen.width,
    isMobile: window.innerWidth <= 768,
  })
  
  const browserResized = () => {
    sizes.browserWidth = window.innerWidth;
    sizes.deviceWidth = screen.width;
    sizes.isMobile = window.innerWidth <= 768;
  }
  
  onMounted(() => {
    window.addEventListener('resize', browserResized);
  });
  
  onUnmounted(() => {
    window.removeEventListener('resize', browserResized);
  });

  return {
    ...toRefs(sizes),
  }
}
