import { ThemeType } from "../types";
import { PazzeiBrandAssets } from "./assets";
import { PazzeiBrandColors } from "./tokens/colors";
import { PazzeiBrandFontSizes } from "./tokens/fonts";
import { PazzeiBrandSizes } from "./tokens/sizes";

const PazzeiBrandTheme: ThemeType = {
  colors: PazzeiBrandColors,
  fontSizes: PazzeiBrandFontSizes,
  sizes: PazzeiBrandSizes,
  assets: PazzeiBrandAssets
};

export default PazzeiBrandTheme;