export class CouponsDTO {
  code: string
  typeId: number
  value: number
  hasValidity: boolean
  validity?: number
  description?: string
  maxDiscount?: number
  usageLimit?: number
  expiresAt?: Date

  constructor(code: string, typeId: number, value: number, description: string, hasValidity: boolean, maxDiscount?: number, validity?: number, usageLimit?: number, expiresAt?: Date) {
    this.code = code
    this.typeId = typeId
    this.value = value
    this.maxDiscount = maxDiscount
    this.description = description
    this.usageLimit = usageLimit
    this.expiresAt = expiresAt
    this.validity = validity
    this.hasValidity = hasValidity
  }
}
