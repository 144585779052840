import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EnemModuleBookClosed = _resolveComponent("EnemModuleBookClosed")!
  const _component_EnemModuleGlobe = _resolveComponent("EnemModuleGlobe")!
  const _component_EnemModuleAtom = _resolveComponent("EnemModuleAtom")!
  const _component_EnemModuleCalculator = _resolveComponent("EnemModuleCalculator")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    class: _normalizeClass(["card-area", { 'card-area--selected': _ctx.modelValue, hover: _ctx.addHoverToCard && !_ctx.modelValue }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.disabled ? undefined : _ctx.$emit('update:modelValue', !_ctx.modelValue)))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        (_ctx.icon === 'bookClosed')
          ? (_openBlock(), _createBlock(_component_EnemModuleBookClosed, {
              key: 0,
              class: "card-icon",
              stroke: _ctx.modelValue ? 'var(--rose-600)' : 'var(--primary-color)'
            }, null, 8, ["stroke"]))
          : (_ctx.icon === 'globe')
            ? (_openBlock(), _createBlock(_component_EnemModuleGlobe, {
                key: 1,
                class: "card-icon",
                stroke: _ctx.modelValue ? 'var(--rose-600)' : 'var(--primary-color)'
              }, null, 8, ["stroke"]))
            : (_ctx.icon === 'atom')
              ? (_openBlock(), _createBlock(_component_EnemModuleAtom, {
                  key: 2,
                  class: "card-icon",
                  stroke: _ctx.modelValue ? 'var(--rose-600)' : 'var(--primary-color)'
                }, null, 8, ["stroke"]))
              : (_ctx.icon === 'calculator')
                ? (_openBlock(), _createBlock(_component_EnemModuleCalculator, {
                    key: 3,
                    class: "card-icon",
                    stroke: _ctx.modelValue ? 'var(--rose-600)' : 'var(--primary-color)'
                  }, null, 8, ["stroke"]))
                : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["card-title", { 'card-title--selected': _ctx.modelValue }])
        }, _toDisplayString(_ctx.title), 3)
      ], true)
    ]),
    _: 3
  }, 8, ["class"]))
}