import { ListTagDTO } from './ListTagDTO'

export class CreateListDTO {
  listName: string
  color: string
  instantFeedback: boolean
  questionIds: Array<number>
  userIds?: Array<string>
  groupIds?: Array<number>
  expiresAt?: string
  returnFeedback?: boolean
  tags?: Array<ListTagDTO>

  constructor(
    listName: string,
    colors: string,
    instantFeedback: boolean,
    questionIds: Array<number>,
    usersId?: Array<string>,
    groupsId?: Array<number>,
    expiresAt?: string,
    returnFeedback?: boolean,
    tags?: Array<ListTagDTO>
  ) {
    this.listName = listName
    this.color = colors
    this.questionIds = questionIds
    this.instantFeedback = instantFeedback
    this.userIds = usersId
    this.groupIds = groupsId
    this.expiresAt = expiresAt
    this.returnFeedback = returnFeedback
    this.tags = tags
  }
}
