import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 20 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M3.3335 10H16.6668M16.6668 10L11.6668 5M16.6668 10L11.6668 15",
      "stroke-width": "1.67",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}