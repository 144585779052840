import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1498faca"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "printable-page"
}
const _hoisted_2 = { class: "list-infos" }
const _hoisted_3 = { class: "question-number" }
const _hoisted_4 = { class: "statement" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "alternatives" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.questions)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.listInfos.name), 1)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "list-content",
            key: index
          }, [
            _createElementVNode("span", _hoisted_3, " Questão " + _toDisplayString(index + 1 > 9 ? index + 1 : '0' + (index + 1)), 1),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                innerHTML: _ctx.bufferToString(
            item.question.find((item) => { return item.filename === 'enunciado.html' })?.body.data)
              }, null, 8, _hoisted_5),
              _createElementVNode("div", {
                innerHTML: _ctx.bufferToString(
            item.question.find((item) => { return item.filename === 'texto.html' })?.body.data)
              }, null, 8, _hoisted_6)
            ]),
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortFiles(item.question), (q, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "alternative",
                  key: index
                }, [
                  (item.type?.toLowerCase() === 'somatória')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.sumOptions[index]), 1))
                    : (item.type?.toLowerCase() === 'múltipla escolha')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.mcqOptions[index]), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_10, "( )")),
                  _createElementVNode("div", {
                    innerHTML: _ctx.bufferToString(q.body.data)
                  }, null, 8, _hoisted_11)
                ]))
              }), 128))
            ])
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}