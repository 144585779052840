
import { defineComponent, onMounted, ref } from 'vue'
import Input from '@/components/Library/Input/index.vue'
import PazzeiBtn from '../Generics/PazzeiBtn.vue'
import useQuasar from 'quasar/src/composables/use-quasar.js';

export default defineComponent({
  props: {
    pixCopyAndPaste: {
      type: String,
      default: '',
    },
    pixQrCode: {
      type: String,
      default: '',
    },
  },
  components: {
    Input,
    PazzeiBtn,
  },
  setup(props) {
    const formData = ref<{ code: string }>({
      code: '',
    })
    const open = ref(false)
    const $q = useQuasar()

    const width = ref('38.875em')
    const height = ref('53em')
    const innerWidth = ref(window.innerWidth)
    const setNewTab = () => {
      innerWidth.value = window.innerWidth
    }
    onMounted(() => {
      formData.value.code = props.pixCopyAndPaste
    })
    window.addEventListener('resize', setNewTab)

    const handleCopy = () => {
      navigator.clipboard.writeText(formData.value.code).then(() => {
        $q.notify({
          textColor: 'grey-1',
          message: 'Código copiado com sucesso',
          color: 'green',
          position: 'top',
          classes: 'notify',
        })
      })
    }

    return {
      formData,
      width,
      height,
      open,
      handleCopy,
    }
  },
})
