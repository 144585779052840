
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'InputIcon',
  props: {
    icon: { type: [Object, Function], required: true },  
    iconFill: { type: String, default: 'currentColor' },
    iconStroke: { type: String, default: 'none' },
    iconSize: { type: [String, Number], default: 20 }
  },
  setup(props) {
    const iconProps = computed(() => ({
      fill: props.iconFill,
      stroke: props.iconStroke,
      size: props.iconSize
    }));

    return {
      iconProps
    };
  }
});
