import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f8d5a40"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "div-header-container" }
const _hoisted_2 = { class: "div-nav-historic" }
const _hoisted_3 = { class: "q-ma-none font-bold text-primary font-style-header" }
const _hoisted_4 = { class: "inputs-wrapper" }
const _hoisted_5 = { class: "div-input" }
const _hoisted_6 = { class: "grid-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalConfirmation = _resolveComponent("ModalConfirmation")!
  const _component_ContentModalRegisterGroups = _resolveComponent("ContentModalRegisterGroups")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_ArrowBackIcon = _resolveComponent("ArrowBackIcon")!
  const _component_SearchIcon = _resolveComponent("SearchIcon")!
  const _component_PazzeiInput = _resolveComponent("PazzeiInput")!
  const _component_PazzeiBtn = _resolveComponent("PazzeiBtn")!
  const _component_TablesComponentStudentsGroup = _resolveComponent("TablesComponentStudentsGroup")!
  const _component_PaginationTableComponent = _resolveComponent("PaginationTableComponent")!
  const _component_MainLayoutWrapper = _resolveComponent("MainLayoutWrapper")!
  const _component_GenericLayout = _resolveComponent("GenericLayout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ModalConfirmation, {
      modelValue: _ctx.isModalConfirmOpen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isModalConfirmOpen) = $event)),
      label: "Tem certeza que deseja remover este usuário?",
      "click-confirm": _ctx.removeUserFromGroup
    }, null, 8, ["modelValue", "click-confirm"]),
    _createVNode(_component_Modal, {
      modelValue: _ctx.isModalOpenStudent,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isModalOpenStudent) = $event)),
      height: _ctx.heightModalSmall,
      width: _ctx.widthModalSmall
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ContentModalRegisterGroups, {
          onClose: _ctx.closeModal,
          listData: _ctx.listUserGroups,
          isAddUser: true,
          groupId: _ctx.groupId
        }, null, 8, ["onClose", "listData", "groupId"])
      ]),
      _: 1
    }, 8, ["modelValue", "height", "width"]),
    _createVNode(_component_GenericLayout, null, {
      default: _withCtx(() => [
        _createVNode(_component_MainLayoutWrapper, null, {
          nav: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("button", {
                  class: "button-back",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.router.push('/grupos')))
                }, [
                  _createVNode(_component_ArrowBackIcon, {
                    fill: _ctx.DefaultTheme().colors.pinkColor
                  }, null, 8, ["fill"])
                ]),
                _createElementVNode("h2", _hoisted_3, "Grupo - " + _toDisplayString(_ctx.groupNameHeader), 1)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_PazzeiInput, {
                    mobileSchool: "",
                    "max-length": 80,
                    placeholder: 'Buscar usuário',
                    "hide-bottom-space": "",
                    "search-list": "",
                    "input-collapsed": _ctx.isSearchInputExpanded,
                    modelValue: _ctx.generalFilter,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.generalFilter) = $event))
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_SearchIcon, {
                        size: 32,
                        fill: _ctx.DefaultTheme().colors.primary,
                        style: {"padding-left":"15px"},
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isSearchInputExpanded = !_ctx.isSearchInputExpanded))
                      }, null, 8, ["fill"])
                    ]),
                    button: _withCtx(() => [
                      _createElementVNode("button", {
                        class: _normalizeClass(["btn-filter", { active: _ctx.isFilterSelected }]),
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openFilterSelected()))
                      }, "Filtro avançado", 2)
                    ]),
                    _: 1
                  }, 8, ["input-collapsed", "modelValue"])
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["btn-container", { 'btn-pazzei__collapsed': !_ctx.isSearchInputExpanded }])
                }, [
                  _createVNode(_component_PazzeiBtn, {
                    icon: _ctx.AddIcon,
                    label: "Cadastrar Usuário",
                    fill: "",
                    class: _normalizeClass(["btn-pazzei", { 'btn-pazzei__collapsed': !_ctx.isSearchInputExpanded }]),
                    "btn-collapsed": !_ctx.isSearchInputExpanded,
                    iconStroke: _ctx.DefaultTheme().colors.whiteColor,
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openModalStudent()))
                  }, null, 8, ["icon", "class", "btn-collapsed", "iconStroke"])
                ], 2)
              ])
            ])
          ]),
          main: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_TablesComponentStudentsGroup, {
                columns: _ctx.columnsUserGroup,
                rows: _ctx.rowsUsersGroups,
                isFilterSelected: _ctx.isFilterSelected,
                sizeColumnsTable: _ctx.sizeColumnGroups,
                showOptionsMenu: "",
                isLoading: _ctx.isLoading,
                sizeLoading: _ctx.sizeLoading,
                heightTable: _ctx.heightTable,
                "width-table": _ctx.widthTable
              }, null, 8, ["columns", "rows", "isFilterSelected", "sizeColumnsTable", "isLoading", "sizeLoading", "heightTable", "width-table"])
            ])
          ]),
          footer: _withCtx(() => [
            _createVNode(_component_PaginationTableComponent, {
              onPageSizeChange: _ctx.handlePageSizeChange,
              onCurrentPage: _ctx.handleCurrentPage,
              totalElements: _ctx.totalElements,
              isFilterSelected: _ctx.isFilterSelected
            }, null, 8, ["onPageSizeChange", "onCurrentPage", "totalElements", "isFilterSelected"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}