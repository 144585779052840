import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_B2bAuthForm = _resolveComponent("B2bAuthForm")!
  const _component_B2bAuthLayout = _resolveComponent("B2bAuthLayout")!

  return (_openBlock(), _createBlock(_component_B2bAuthLayout, {
    heading: _ctx.heading,
    subtitl: "",
    textCenter: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_B2bAuthForm, { listCode: _ctx.listCode }, null, 8, ["listCode"])
    ]),
    _: 1
  }, 8, ["heading"]))
}