import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68d782de"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["tabindex"]
const _hoisted_2 = ["disabled", "value", "placeholder"]
const _hoisted_3 = ["onMousedown"]
const _hoisted_4 = {
  key: 0,
  class: "error"
}
const _hoisted_5 = ["tabindex"]
const _hoisted_6 = ["disabled", "value", "placeholder"]
const _hoisted_7 = {
  key: 0,
  class: "error"
}
const _hoisted_8 = { class: "items" }
const _hoisted_9 = {
  key: 0,
  class: "item input"
}
const _hoisted_10 = ["onMouseup"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_virtual_scroll = _resolveComponent("q-virtual-scroll")!

  return (!_ctx.multiple)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "custom-select",
        tabindex: _ctx.tabindex
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["selected", { open: _ctx.open, rounded: _ctx.rounded, 'rounded-simple': _ctx.roundedSimple, [`selected--${_ctx.variant}`]: _ctx.variant }]),
          onMousedown: _cache[2] || (_cache[2] = ($event: any) => (_ctx.open = !_ctx.open))
        }, [
          _createElementVNode("input", {
            class: _normalizeClass(["input-select", { [`input-select--${_ctx.variant}`]: _ctx.variant }]),
            disabled: _ctx.disabled,
            type: "text",
            value: _ctx.inputShow,
            readonly: "",
            placeholder: _ctx.placeholder,
            onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = false)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
          }, null, 42, _hoisted_2)
        ], 34),
        _createElementVNode("div", {
          class: _normalizeClass(["items", { selectHide: !_ctx.open, [`items--${_ctx.variant}`]: _ctx.variant }])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["item single", { [`item--${_ctx.variant}`]: _ctx.variant }]),
              key: i,
              onMousedown: ($event: any) => (_ctx.handleClick(option))
            }, _toDisplayString(option), 43, _hoisted_3))
          }), 128))
        ], 2),
        (_ctx.error || _ctx.inputValidation)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.errorMessage), 1))
          : _createCommentVNode("", true)
      ], 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", {
        key: _ctx.key,
        class: "custom-select",
        tabindex: _ctx.tabindex
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["selected", { open: _ctx.open, rounded: _ctx.rounded, disabled: _ctx.disabled }]),
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleClickMultiple && _ctx.handleClickMultiple(...args)))
        }, [
          _createElementVNode("input", {
            id: "input-main",
            class: "input-search input-select",
            style: {"background":"transparent"},
            disabled: _ctx.disabled,
            type: "text",
            value: _ctx.inputShow,
            readonly: "",
            placeholder: _ctx.placeholder
          }, null, 8, _hoisted_6)
        ], 2),
        (!_ctx.hideBottomSpace && (_ctx.error || _ctx.inputValidation))
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.errorMessage), 1))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("div", _hoisted_8, [
          (!_ctx.readonly)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _withDirectives(_createElementVNode("input", {
                  id: "input-search",
                  autocomplete: "off",
                  placeholder: "Buscar",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filtered) = $event)),
                  class: "text-gray input-search",
                  style: {"padding":"0 22px 0 22px","background":"transparent"}
                }, null, 512), [
                  [_vModelText, _ctx.filtered]
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_q_virtual_scroll, {
            class: "scroll item",
            items: _ctx.filtered !== '' ? _ctx.options.filter((subject) => (subject as string).toLowerCase().includes(_ctx.filtered.toLowerCase())) : _ctx.options,
            style: {"max-height":"190px"}
          }, {
            default: _withCtx(({ item, index }) => [
              (_openBlock(), _createBlock(_component_q_item, { key: index }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "w-full",
                    onMouseup: ($event: any) => (_ctx.handleMultipleItems(item, _ctx.name))
                  }, [
                    _createVNode(_component_q_checkbox, {
                      size: "md",
                      label: item.replace('G1 - ', ''),
                      modelValue: _ctx.arraySelected,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.arraySelected) = $event)),
                      val: item,
                      style: {"width":"100%"}
                    }, null, 8, ["label", "modelValue", "val"])
                  ], 40, _hoisted_10)
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }, 8, ["items"])
        ], 512), [
          [_vShow, _ctx.open]
        ])
      ], 8, _hoisted_5))
}