
import CardModule from '@/components/Library/CardModule/index.vue'
import ColoredIcon from '@/components/Library/ColoredIcon/index.vue'
import { defineComponent, PropType, ref, watch } from 'vue'
import PazzeiSelectNew from '../../../Generics/PazzeiSelectNew.vue'
import { CardKnowledgeAreaContent } from './types'

export default defineComponent({
  name: 'CardKnowledgeArea',
  components: {
    CardModule,
    ColoredIcon,
    PazzeiSelectNew,
  },
  props: {
    cardContent: { type: Object as PropType<CardKnowledgeAreaContent>, default: () => ({}) },
    foreignLanguage: { type: String, default: '' },
    hasError: { type: Boolean, default: false },
  },
  setup(_, { emit }) {
    const options = ref(['Inglês', 'Espanhol'])
    const selected = ref('')

    watch(selected, (newValue) => {
      if (newValue) {
        emit('update:hasError', false)
        emit('update:foreignLanguage', selected.value)
      }
    })

    return { options, selected }
  },
})
