
import { defineComponent, onMounted, PropType, ref } from 'vue';

const __default__ = defineComponent({
  name: 'StepsComponent',
  emits: ['changeStepsTo'],
  props: {
    selected: Boolean,
    titles: { type: Array as PropType<Array<{ item: string; isDone: boolean }>>, default: () => [] },
    choices: { type: Array as PropType<Array<string>>, default: () => [] },
    currentView: { type: String, default: 'Região' },
    currentTab: { type: String, default: '' },
    widthOfChoices: { type: String, default: '25%' },
  },
  setup(props, { emit }) {
    const lenghtOfTitles = ref('100%')
    const lenghtOfChoice = ref(props.widthOfChoices)

    const handleChangeSteps = (view: { item: string; isDone: boolean }) => {
      if (!view.isDone) return
      else emit('changeStepsTo', view.item)
    }

    onMounted(() => {
      lenghtOfTitles.value = 100 / props.titles.length + '%'
    })
    return { lenghtOfTitles, handleChangeSteps, lenghtOfChoice }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "f487be84": (_ctx.lenghtOfTitles),
  "68a95544": (_ctx.lenghtOfChoice)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__