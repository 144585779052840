
import { defineComponent, onMounted, onUnmounted, PropType, ref, watch } from 'vue';

export default defineComponent({
  props: {
    options: {
      type: Array as PropType<Array<string | number>>,
      required: false,
    },
    tabindex: {
      type: Number,
      required: false,
      default: -1,
    },
    readonly: {
      type: Boolean,
    },
    optionObject: { type: Array as PropType<Array<{ name: string; id: string }>> },
    name: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
    },
    isReports: {
      type: Boolean,
    },
    modelValue: {
      type: Array as PropType<Array<{ name: string; id: string } | undefined>>,
      required: true,
    },
    modifyBehavior: { type: Boolean },
    rounded: Boolean,
    placeholder: String,
    error: Boolean,
    hideBottomSpace: Boolean,
    errorMessage: String,
    valueSelectedDefault: Boolean,
    topic: String,
    valuePrimary: Number,
    itemsSelected: {
      type: Array as PropType<Array<string>>,
    },
    roundedSimple: Boolean,
    showChoices: Boolean,
    disabled: Boolean,
  },
  setup(props, { emit }) {
    const vObjectModel = ref<Array<{ name: string; id: string } | undefined>>(props.modelValue)
    const inputShow = ref<string | undefined>(props.modelValue[0]?.name ?? '')

    const getIndexSchool = ref(0)

    const selected = ref<string>()
    const filtered = ref<string>('')
    const arraySelected = ref<Array<string | number | { name: string; id: string } | undefined> | undefined>([])
    const key = ref(0)

    watch(
      arraySelected,
      () => {
        if (!props.modifyBehavior) {
          arraySelected.value = vObjectModel.value
          inputShow.value = vObjectModel.value[0]?.name ?? ''
        } else {
          vObjectModel.value = props.modelValue
        }
      },
      { deep: true }
    )

    const fetchIndexValue = (details: { index: number }) => {
      getIndexSchool.value = details.index
      emit('index', getIndexSchool.value)
    }

    if (props.valueSelectedDefault) selected.value = props.valuePrimary ? props.valuePrimary.toString() : (props.options?.[props.options?.length - 1] as string)

    const open = ref(false)

    const handleClose = (event: Event) => {
      if (!props.multiple) open.value = false

      if (props.multiple) {
        if (event.target instanceof HTMLInputElement) {
          if (event.target.id !== 'input-search') open.value = false
        } else {
          open.value = false
        }
      }
    }
    document.addEventListener('click', handleClose)

    const handleMultipleObject = (option: { name: string; id: string }, name: string) => {
      emit('input', option, name)

      if (props.multiple) {
        const index = vObjectModel.value.findIndex((item) => item?.id === option.id)
        if (index !== -1) {
          vObjectModel.value.splice(index, 1)
        } else {
          vObjectModel.value.push(option)
        }

        inputShow.value = vObjectModel.value.map((it) => it?.name).join(', ')
      } else {
        vObjectModel.value = [option]
        inputShow.value = option.name
        open.value = false
      }
      emit('update:modelValue', vObjectModel.value)
    }

    watch(
      () => props.modelValue,
      () => {
        if (!props.modifyBehavior) inputShow.value = props.modelValue[0]?.name

        arraySelected.value = props.modelValue.map((it) => it?.id)
        vObjectModel.value = []
        inputShow.value = props.modelValue.map((it) => it?.name).join(', ')
      }
    )

    onMounted(() => {
      key.value++
      if (props.valueSelectedDefault) {
        selected.value = props.valuePrimary ? props.valuePrimary.toString() : (props.options?.[props.options?.length - 1] as string)
        emit('input', props.options?.[0])
      }
    })

    onUnmounted(() => {
      key.value = 0
      document.removeEventListener('click', handleClose)
    })

    const inputValidation = ref(false)
    watch(selected, () => {
      inputValidation.value = !selected.value
    })

    const searchValue = ref('')

    watch(
      () => searchValue.value,
      (newValue) => {
        emit('search', newValue)
      }
    )

    const handleClickMultiple = () => {
      if (!props.disabled && !open.value) {
        setTimeout(() => {
          open.value = true
        }, 10)
      }
    }

    return { inputShow, filtered, handleClickMultiple, inputValidation, selected, open, arraySelected, key, handleMultipleObject, fetchIndexValue, searchValue }
  },
})
