import DefaultTheme from '@/theme/defaultTheme'
import { ApexOptions } from 'apexcharts'

const setLineGraphicInfos = (countByDay: Array<number | null>) => {
  const a = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
  let b = countByDay

  if (b.length < 7) {
    const injectValues = Array.from({ length: 7 - b.length }, () => null)
    b = [...b, ...injectValues]
  }
  const options: ApexOptions = {
    series: [
      {
        name: 'Questões',
        data: b,
      },
    ],
    chart: {
      height: 200,
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
      style: {
        colors: [DefaultTheme().colors.warning300],
      },
    },
    stroke: {
      curve: 'straight',
      colors: [DefaultTheme().colors.warning300],
      width: 1.5,
    },
    markers: {
      size: 4,
      colors: [DefaultTheme().colors.whiteColor],
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
      strokeWidth: 2.5,
      strokeColors: DefaultTheme().colors.warning300,
    },
    xaxis: {
      categories: b.length < 7 ? ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'] : a,
      axisTicks: { show: false },
      labels: {
        show: true,
        style: {
          colors: DefaultTheme().colors.colorGrayDark,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: DefaultTheme().colors.colorGrayDark,
        },
      },
      tickAmount: 5,
    },
  }
  return options
}

export default setLineGraphicInfos
