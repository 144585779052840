<template>
  <div
    class="filter-tag"
    :class="{ active, hovered: isHovered }"
    @click="toggleActive"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <span class="icon">
      <AddTagIcon v-if="!active && isHovered" :stroke="DefaultTheme().colors.secondaryGray" />
      <RemoveTagIcon v-else-if="active && isHovered" :stroke="DefaultTheme().colors.error400" />
      <CheckedTagIcon v-else-if="active && !isHovered" :stroke="DefaultTheme().colors.success400" />
    </span>
    <label>{{ labelText }}</label>
  </div>
</template>

<script>
import AddTagIcon from '@/components/Icons/default/redesign/AddTagIcon.vue';
import CheckedTagIcon from '@/components/Icons/default/redesign/CheckedTagIcon.vue';
import RemoveTagIcon from '@/components/Icons/default/redesign/RemoveTagIcon.vue';
import DefaultTheme from '@/theme/defaultTheme';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'ModalFilterList',
  components: {
    AddTagIcon,
    CheckedTagIcon,
    RemoveTagIcon,
  },
  props: {
    labelText: { type: String, default: '' },
    isActive: { type: Boolean, default: false },
  },
  data() {
    return {
      isHovered: false,
    };
  },
  setup(props) {
    const active = computed(() => props.isActive);
    return {
      DefaultTheme,
      active,
    };
  },
  methods: {
    toggleActive() {
      this.active = !this.active; 
      this.$emit('updateSubjects', this.labelText);
      this.$emit('close'); 
    },
  },
});
</script>

<style scoped>
.filter-tag {
  border: 1px solid var(--default-border);
  border-radius: 6px;
  padding: 2px 9px;
  color: var(--gray-700);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: border-color 0.2s, color 0.2s;
}

label {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
}

.filter-tag.active {
  border-color: var(--success-border);
  color: var(--success-600);
}

.filter-tag.active.hovered {
  border-color: var(--error-border);
  color: var(--error-font-color);
}

.filter-tag:not(.active):hover {
  border-color: var(--hover-border); 
  color: var(--hover-color); 
}

.icon {
  display: flex;
  align-items: center;
}
</style>
