import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14b11587"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "div-input-code-wrapper" }
const _hoisted_2 = ["id", "value", "onKeyup", "onPaste", "onInput"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otpCodeFields, (codeField, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `code-container-${index}`,
        class: "div-input-code"
      }, [
        _createElementVNode("input", {
          id: `code-${index}`,
          ref_for: true,
          ref: _ctx.codeRefs[index],
          value: codeField.toUpperCase(),
          class: _normalizeClass(["input-code", _ctx.hasError ? 'input-code-on-error ' : '']),
          onKeyup: [
            _withKeys(($event: any) => (_ctx.onBackspaceKeyDown(index)), ["backspace"]),
            _withKeys(($event: any) => (_ctx.onLeftKeyUp(index)), ["left"]),
            _withKeys(($event: any) => (_ctx.onRightKeyUp(index)), ["right"])
          ],
          onPaste: ($event: any) => (_ctx.onPaste($event, index)),
          onInput: ($event: any) => (_ctx.onInput($event, index)),
          type: "text",
          maxlength: "1"
        }, null, 42, _hoisted_2)
      ]))
    }), 128))
  ]))
}