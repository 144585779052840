export class EditUserInfosDTO {
  name: string
  lastName?: string
  telephone?: string
  document?: string

  constructor(name: string, lastName?: string, telephone?: string, document?: string) {
    this.name = name
    this.lastName = lastName !== '' ? lastName : undefined
    this.telephone = telephone !== '' ? telephone : undefined
    this.document = document !== '' ? document : undefined
  }
}

export class EditEmailDTO {
  email: string

  constructor(email: string) {
    this.email = email
  }
}

export class EditPasswordDTO {
  newPassword: string
  oldPassword: string
  stayLoggedIn: boolean
  registrationCompleted?: boolean
  updatedAt?: Date

  constructor(newPassword: string, oldPassword: string, stayLoggedIn: boolean, registrationCompleted?: boolean, updatedAt?: Date) {
    this.newPassword = newPassword
    this.oldPassword = oldPassword
    this.stayLoggedIn = stayLoggedIn

    if (registrationCompleted) this.registrationCompleted = registrationCompleted
    if (updatedAt) this.updatedAt = updatedAt
  }
}
