import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "menu-list-btn-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElipseIcon = _resolveComponent("ElipseIcon")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_circular_progress = _resolveComponent("q-circular-progress")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("button", _hoisted_1, [
      _createVNode(_component_ElipseIcon, {
        fill: _ctx.DefaultTheme().colors.grayColor
      }, null, 8, ["fill"])
    ]),
    _createVNode(_component_q_menu, {
      class: "menu-icons-menu-lists-table",
      "transition-show": "jump-down",
      "transition-hide": "jump-up",
      "auto-close": "",
      onShow: _ctx.sendValueToParent
    }, {
      default: _withCtx(() => [
        (!_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_q_list, {
              key: 0,
              dense: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsMenu, (option, index) => {
                  return _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                    class: "item-menu-list",
                    key: index,
                    clickable: "",
                    onClick: ($event: any) => (_ctx.handleClick(option.action)),
                    disable: option.disabled
                  }, {
                    default: _withCtx(() => [
                      (option.icon)
                        ? (_openBlock(), _createBlock(_resolveDynamicComponent(option.icon), _normalizeProps(_mergeProps({ key: 0 }, option.iconProps)), null, 16))
                        : _createCommentVNode("", true),
                      _createVNode(_component_q_item_section, { class: "menu-list-infos" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(option.label), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["onClick", "disable"])), [
                    [_directive_close_popup]
                  ])
                }), 128))
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "menu-lists-table-loading",
              style: _normalizeStyle({ height: _ctx.heightLoading })
            }, [
              _createVNode(_component_q_circular_progress, {
                indeterminate: "",
                size: "20px",
                color: "secondary"
              })
            ], 4))
      ]),
      _: 1
    }, 8, ["onShow"])
  ]))
}