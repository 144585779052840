
import { defineComponent, inject, ref } from 'vue'
import ModalChangePassword from '@/components/EditingProfile/ModalChangePassword.vue'
import { InstitutionConfigType } from '@/types/InstitutionConfigType';

export default defineComponent({
  name: 'ChangePasswordPage',
  components: { ModalChangePassword },
  setup() {
    const flags = ref({
      visibility: true,
    })

    const institutionConfig = inject<InstitutionConfigType>('institutionConfig');
    const title = institutionConfig?.name;

    return {
      flags,
      title,
    }
  },
})
