import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d268fd0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseIcon = _resolveComponent("CloseIcon")!

  return (_ctx.showImageTeleport)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "modal",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeModalOutside && _ctx.closeModalOutside(...args)))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "div-close cursor-pointer",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeButton && _ctx.closeButton(...args)))
          }, [
            _createVNode(_component_CloseIcon, {
              fill: _ctx.DefaultTheme().colors.whiteColor
            }, null, 8, ["fill"])
          ]),
          _createElementVNode("img", {
            src: _ctx.image,
            alt: "Imagem",
            onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
          }, null, 8, _hoisted_2)
        ])
      ]))
    : _createCommentVNode("", true)
}