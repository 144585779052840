import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 40 40",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("rect", {
      width: "40",
      height: "40",
      rx: "20",
      fill: _ctx.DefaultTheme().colors.warning50
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M27.5 18.3334H12.5M23.3333 11.6667V15M16.6667 11.6667V15M16.5 28.3334H23.5C24.9001 28.3334 25.6002 28.3334 26.135 28.0609C26.6054 27.8212 26.9878 27.4387 27.2275 26.9683C27.5 26.4336 27.5 25.7335 27.5 24.3334V17.3334C27.5 15.9332 27.5 15.2332 27.2275 14.6984C26.9878 14.228 26.6054 13.8455 26.135 13.6058C25.6002 13.3334 24.9001 13.3334 23.5 13.3334H16.5C15.0999 13.3334 14.3998 13.3334 13.865 13.6058C13.3946 13.8455 13.0122 14.228 12.7725 14.6984C12.5 15.2332 12.5 15.9332 12.5 17.3334V24.3334C12.5 25.7335 12.5 26.4336 12.7725 26.9683C13.0122 27.4387 13.3946 27.8212 13.865 28.0609C14.3998 28.3334 15.0999 28.3334 16.5 28.3334Z",
      "stroke-width": "1.66667",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_3)
  ], 8, _hoisted_1))
}