export const filters = [
  {
    type: 'input',
    placeholder: 'Pesquisar',
    width: '100%',
    maxLength: 80,
    value: '',
    key: 'name',
  },
  {
    type: 'input',
    placeholder: 'Pesquisar',
    width: '100%',
    maxLength: 15,
    value: '',
    key: 'phone',
  },
  {
    type: 'input',
    placeholder: 'Pesquisar',
    width: '100%',
    maxLength: 80,
    value: '',
    key: 'legalRepresentative',
  },
  {
    type: 'input',
    placeholder: 'Pesquisar',
    width: '100%',
    maxLength: 80,
    value: '',
    key: 'email',
  },
  {
    type: 'date',
    placeholder: '00/00/0000',
    width: '100%',
    maxLength: 11,
    value: '',
    key: 'createdAt',
  },
  {
    type: 'select',
    placeholder: 'Selecione',
    options: ['Ativo', 'Inativo', 'Bloqueado'],
    value: '',
    key: 'isActive',
  },
]

export const columns: Array<{ label: string; key: string }> = [
  { key: 'name', label: 'Nome fantasia' },
  { key: 'phone', label: 'Telefone' },
  { key: 'legalRepresentative', label: 'Responsável' },
  { key: 'email', label: 'E-mail' },
  { key: 'createdAt', label: 'Data de Registro' },
  { key: 'isActive', label: 'Situação' },
]
