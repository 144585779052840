import DefaultTheme from '@/theme/defaultTheme'
import { ApexOptions } from 'apexcharts'

const setLineGraphicProficiency = (date: Array<string>, proficiency: Array<number>) => {
  const options: ApexOptions = {
    series: [
      {
        name: 'Proficiência',
        data: proficiency,
      },
    ],
    chart: {
      height: 150,
      offsetY: 40,
      offsetX: -5,
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
      style: {
        colors: [DefaultTheme().colors.pinkColor],
      },
    },
    tooltip: {
      enabled: true,
      shared: false,
      marker: {
        show: true,
        fillColors: [DefaultTheme().colors.pinkColor],
      },
      style: {
        fontSize: '12px',
      },
    },
    stroke: {
      curve: 'straight',
      width: 2,
      colors: [DefaultTheme().colors.pinkColor],
      show: true,
    },
    markers: {
      size: 4,
      colors: [DefaultTheme().colors.whiteColor],
      hover: {
        size: undefined,
        sizeOffset: 2,
      },
      strokeWidth: 2.5,
      strokeColors: DefaultTheme().colors.pinkColor,
    },
    title: {
      text: 'Histórico de testes por data de realização',
      align: 'center',
      style: {
        color: DefaultTheme().colors.colorGrayDark,
        fontWeight: 700,
      },
    },
    grid: {
      row: {
        colors: [DefaultTheme().colors.lightGray, 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: date,
      axisTicks: { show: true, height: 8, color: DefaultTheme().colors.colorGrayDark, },
      labels: {
        show: true,
        offsetY: 5,
        style: {
          colors: DefaultTheme().colors.colorGrayDark,
          fontSize: '10px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: DefaultTheme().colors.colorGrayDark,
          fontSize: '10px',
        },
      },
      tickAmount: 10,
      max: 1000,
      min: 0,
    },

    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            height: 250,
            offsetY: 10,
            offsetX: 0,
          },
          title: {
            text: '',
          },
          yaxis: {
            labels: {
              style: {
                colors: DefaultTheme().colors.colorGrayDark,
                fontSize: '7px',
              },
            },
            tickAmount: 5,
            max: 1000,
            min: 0,
          },
          xaxis: {
            labels: {
              style: {
                colors: DefaultTheme().colors.colorGrayDark,
                fontSize: '5px',
              },
            },
          },
        },
      },
    ],
  }
  return options
}

export default setLineGraphicProficiency
