
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiSelectObject from '@/components/Generics/PazzeiSelectObject.vue'
import Input from '@/components/Library/Input/index.vue'
import { AddUserDTO, GroupDTO } from '@/dtos/schoolPlanPages/GroupDTO/GroupDTO'
import groupsService from '@/services/groups'
import usersService from '@/services/users'
import { User } from '@/types/UsersType'
import modalCreateGroupRules from '@/utils/vuelidateRules/modalCreateGroupRules'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import debounce from 'quasar/src/utils/debounce.js';import useQuasar from 'quasar/src/composables/use-quasar.js';
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue'

export default defineComponent({
  name: 'ContentModalRegisterGroups',
  emits: ['close'],
  props: {
    studentsOptions: { type: Array as PropType<Array<User>>, default: () => [] },
    listData: Function as PropType<() => void>,
    isAddUser: {
      type: Boolean,
      default: false,
    },
    groupId: {
      type: String,
      default: '',
    },
  },
  components: { Input, PazzeiBtn, PazzeiSelectObject },

  setup(props, { emit }) {
    const formatListObject = ref<Array<{ name: string; id: string }>>([])
    const listUserByInstitution = ref<User[]>()
    const $q = useQuasar()
    const groupId = ref(props.groupId)
    const isAddUserToGroup = ref(props.isAddUser)
    const messageRequired = 'Este campo é obrigatório'
    const scrollValue = ref(0)
    const totalElements = ref(0)
    const searchValue = ref<string | undefined>('')
    const formDataGroups = ref<{ listName: string; users: { id: string; name: string }[] }>({
      listName: '',
      users: [],
    })
    const selectedOptionsCount = computed(() => formDataGroups.value.users.length)
    const countUsers = computed(() => (selectedOptionsCount.value === 1 ? '' : 's'))
    const isLoading = ref(false)
    const currentPage = ref(1)
    const pageSize = 30
    const rules = computed(() => {
      return {
        users: { required: helpers.withMessage(messageRequired, required) },
      }
    })
    const v$ = useVuelidate(isAddUserToGroup.value ? rules : modalCreateGroupRules, formDataGroups)
    const lastIndexScroll = (event: number) => {
      scrollValue.value = event
    }

    const listUserInstitution = async (page: number, pageSize: number) => {
      const reqBody = {
        page: page,
        pageSize: pageSize,
        filter: {
          group: isAddUserToGroup.value ? groupId.value : undefined,
          inGroup: isAddUserToGroup.value ? false : undefined,
        },
        search: {
          name: searchValue.value !== '' ? searchValue.value : undefined,
        },
      }

      try {
        const response = await usersService.post<{ data: { entities: User[]; totalElements: number } }>('/filter-users', reqBody)
        listUserByInstitution.value = response.data.data.entities
        totalElements.value = response.data.data.totalElements

        const newListFormatted = listUserByInstitution.value?.map((it: User) => ({
          name: `${it.name} ${it.lastName !== null ? it.lastName : ''}`,
          id: it.id,
        }))

        if (page === 1) {
          formatListObject.value = newListFormatted
        } else {
          formatListObject.value = [...formatListObject.value, ...newListFormatted]
        }
      } catch (error) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao buscar dados',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    const createGroupStudent = async () => {
      const result = await v$.value.$validate()
      if (result) {
        isLoading.value = true
        try {
          if (isAddUserToGroup.value) {
            const userIds = formDataGroups.value.users.map((user) => user.id)
            const data = new AddUserDTO(Number(groupId.value), userIds)
            await groupsService.post('/add-users', data)
          } else {
            const userIds = formDataGroups.value.users.map((user) => user.id)
            const data = new GroupDTO(formDataGroups.value.listName, userIds)
            await groupsService.post('/', data)
          }

          $q.notify({
            textColor: 'grey-1',
            message: isAddUserToGroup.value ? `Usuário${countUsers.value} adicionado${countUsers.value} com sucesso` : 'Grupo criado com sucesso',
            color: 'green',
            position: 'top',
            classes: 'notify',
          })
        } catch (error) {
          $q.notify({
            textColor: 'grey-1',
            message: isAddUserToGroup.value ? `Erro ao adicionar Usuário${countUsers.value}` : 'Erro ao criar grupo',
            color: 'red',
            position: 'top',
            classes: 'notify',
          })
        } finally {
          props.listData?.()
          isLoading.value = false
          emit('close')
        }
      }
    }

    const handleSearch = (value: any) => {
      searchValue.value = value
      currentPage.value = 1
      debouncedGetInstitutionUsers(currentPage.value, pageSize)
    }

    const debouncedGetInstitutionUsers = debounce(listUserInstitution, 500)

    watch(searchValue, () => {
      debouncedGetInstitutionUsers(1, pageSize)
    })

    watch(scrollValue, (newValue) => {
      const lastIndex = formatListObject.value.length - 1

      if (newValue === lastIndex && formatListObject.value.length < totalElements.value) {
        const remainingResults = totalElements.value - formatListObject.value.length
        const remainingPages = Math.ceil(remainingResults / pageSize)

        if (remainingPages > 0) {
          currentPage.value++
          listUserInstitution(currentPage.value, pageSize)
        }
      }
    })

    onMounted(async () => {
      await listUserInstitution(currentPage.value, pageSize)
    })

    return {
      v$,
      validation: formDataGroups,
      createGroupStudent,
      formatListObject,
      isLoading,
      lastIndexScroll,
      handleSearch,
    }
  },
})
