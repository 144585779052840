
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import { ProficiencySubjectCreatedType, ProficiencySubjectType } from '@/types/ProficiencySubjectType'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { PropType, defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { HandleErrorAxiosResponseDTO } from '../../dtos/HandleErrorAxiosResponseDTO'
import { ResponseDTO } from '../../dtos/ResponseDTOs/ResponseDTO'
import listsService from '../../services/list'
import PazzeiMenuLists from './PazzeiMenuLists.vue'

export default defineComponent({
  name: 'SubjectList',
  components: {
    PazzeiBtn,
    PazzeiMenuLists,
  },
  props: {
    subjectInfo: {
      type: Object as PropType<ProficiencySubjectType>,
      required: true,
    },
  },
  setup(props) {
    const cardStatus = ref(props.subjectInfo.status)
    const btnLabel = ref('Iniciar')
    const btnColor = ref('primary')
    const cardBorder = ref('')
    const router = useRouter()
    const $q = useQuasar()

    const changeBtnLabel = () => {
      if (cardStatus.value === 'not_started') {
        btnLabel.value = 'Iniciar'
        btnColor.value = 'btn-pazzei'
      } else if (cardStatus.value === 'in_progress') {
        btnLabel.value = 'Continuar'
        btnColor.value = 'btn-pazzei-continue'
      } else {
        btnLabel.value = 'Refazer'
        btnColor.value = 'golden-button'
        cardBorder.value = 'golden-border'
      }
    }

    const requestQuestion = async () => {
      try {
        let catId = props.subjectInfo.catId

        if (cardStatus.value === 'not_started' || cardStatus.value === 'finished') {
          const { data } = await listsService.post<ResponseDTO<ProficiencySubjectCreatedType>>('/cat', {
            subject: props.subjectInfo.subject,
          })

          catId = data.data.catId
        }

        router.push({ name: 'answerTestPage', query: { materia: props.subjectInfo.subject }, params: { id: catId } })
      } catch (err) {
        const { error } = new HandleErrorAxiosResponseDTO(err)

        $q.notify({
          textColor: 'grey-1',
          message: error.userMessage,
          color: 'red',
          timeout: 2000,
          position: 'top',
          classes: 'notify',
        })

        console.error('error', error)
      }
    }

    onMounted(() => {
      changeBtnLabel()
    })

    return {
      btnLabel,
      btnColor,
      cardBorder,
      requestQuestion,
    }
  },
})
