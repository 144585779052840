export const columnsProfessor: Array<{ label: string; key: string }> = [
  { key: 'name', label: 'Nome da Lista' },
  { key: 'color', label: 'Cor da Lista' },
  { key: 'subjects', label: 'Matérias' },
  { key: 'totalQuestions', label: 'N° de Questões' },
  { key: 'students', label: 'N° de Alunos' },
]

export const columnsRoot: Array<{ label: string; key: string }> = [
  { key: 'name', label: 'Nome da Lista' },
  { key: 'createdBy', label: 'Criado por' },
  { key: 'color', label: 'Cor da Lista' },
  { key: 'subjects', label: 'Matérias' },
  { key: 'totalQuestions', label: 'N° de Questões' },
  { key: 'students', label: 'N° de Alunos' },
]

export const columnsSharingHistory: Array<{ label: string; key: string }> = [
  { key: 'name', label: 'Nome do aluno' },
  { key: 'createdAt', label: 'Data de compartilhamento' },
  { key: 'expiresAt', label: 'Data/Hora de expiração' },
]
