import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO';
import { THEMES } from '@/theme/types';
import { InstitutionConfigType, RawInstitutionConfigType } from '@/types/InstitutionConfigType';
import usersService from '@/services/users';
import { getSubdomainFromLocalStorage, handleStagingSubdomain } from '@/utils/subdomain';

class InstitutionConfigHelper {
  
  static configParser = (plainConfig: RawInstitutionConfigType): InstitutionConfigType => {
    const innerConfig = plainConfig.config.data as InstitutionConfigType;
    
    return {
      ...plainConfig,
      isWhiteLabel: !['localhost', 'pazzei', 'portal'].includes(innerConfig.subdomain),
      ...innerConfig,
    }
  }

  static getInstitutionsConfig = async () => {
    const subdomain = getSubdomainFromLocalStorage();
    const handledSubdomain = handleStagingSubdomain(subdomain);
    
    if (!handledSubdomain || handledSubdomain === THEMES.PAZZEI) return;
    const config = await usersService.get<ResponseDTO<RawInstitutionConfigType>>(`/institution/${handledSubdomain}`);

    return InstitutionConfigHelper.configParser(config.data.data);
  }
}

export default InstitutionConfigHelper;
