import AvailableIcon from "@/components/Icons/default/redesign/sharedList/AvailableIcon.vue";
import CheckIcon from "@/components/Icons/default/redesign/sharedList/CheckIcon.vue";
import ComingSoonIcon from "@/components/Icons/default/redesign/sharedList/ComingSoonIcon.vue";
import UnavailableIcon from "@/components/Icons/default/redesign/sharedList/UnavailableIcon.vue";
import DefaultTheme from "../theme/defaultTheme";

export const listStatus = {
  expired: { code: 1, message: 'Prazo encerrado', icon: UnavailableIcon, iconStroke: DefaultTheme().colors.error300 },
  available: { code: 2, message: 'Lista disponível', icon: AvailableIcon, iconStroke: DefaultTheme().colors.defaultBorder },
  finished: { code: 3, message: 'Lista concluída', icon: CheckIcon, iconFill: DefaultTheme().colors.success500 },
  resultsAvailable: { code: 4, message: 'Resultado liberado', icon: CheckIcon, iconFill: DefaultTheme().colors.success500 },
  comingSoon: { code: 5, message: 'Inicia', icon: ComingSoonIcon, iconStroke: DefaultTheme().colors.disabled },
}
