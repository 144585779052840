import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a79fc17"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "breadcrumb-container" }
const _hoisted_2 = {
  key: 0,
  class: "breadcrumb-separator"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock("span", { key: index }, [
        _createElementVNode("span", null, _toDisplayString(item), 1),
        (index < _ctx.items.length - 1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.separator), 1))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}