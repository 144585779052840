import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fe1d753c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["tabindex"]
const _hoisted_2 = ["disabled", "value", "placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "error"
}
const _hoisted_4 = {
  key: 0,
  class: "item input"
}
const _hoisted_5 = ["onMouseup"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_virtual_scroll = _resolveComponent("q-virtual-scroll")!

  return (_openBlock(), _createElementBlock("div", {
    key: _ctx.key,
    class: "custom-select",
    tabindex: _ctx.tabindex
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["selected", { open: _ctx.open, rounded: _ctx.rounded, disabled: _ctx.disabled, 'not-mult': !_ctx.multiple }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClickMultiple && _ctx.handleClickMultiple(...args)))
    }, [
      _createElementVNode("input", {
        id: "input-main",
        class: "input-search input-select",
        style: {"background":"transparent"},
        disabled: _ctx.disabled,
        type: "text",
        value: _ctx.inputShow,
        readonly: "",
        placeholder: _ctx.placeholder
      }, null, 8, _hoisted_2)
    ], 2),
    (!_ctx.hideBottomSpace && (_ctx.error || _ctx.inputValidation))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass({ 'items margin-top': !_ctx.isReports, 'items margin-top-reports': _ctx.isReports })
    }, [
      (!_ctx.readonly)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              id: "input-search",
              autocomplete: "off",
              placeholder: "Buscar",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchValue) = $event)),
              class: "text-gray input-search",
              style: {"padding":"0 22px 0 22px","background":"transparent"}
            }, null, 512), [
              [_vModelText, _ctx.searchValue]
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_q_virtual_scroll, {
        onVirtualScroll: _ctx.fetchIndexValue,
        class: "scroll item",
        items: _ctx.optionObject,
        style: {"max-height":"190px"}
      }, {
        default: _withCtx(({ item, index }) => [
          (_openBlock(), _createBlock(_component_q_item, {
            key: index,
            class: "minHeight"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "w-full",
                onMouseup: ($event: any) => (_ctx.handleMultipleObject(item, _ctx.name))
              }, [
                (_ctx.multiple)
                  ? (_openBlock(), _createBlock(_component_q_checkbox, {
                      key: 0,
                      size: "md",
                      label: item.name,
                      modelValue: _ctx.arraySelected,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.arraySelected) = $event)),
                      val: item.id,
                      style: {"width":"100%"}
                    }, null, 8, ["label", "modelValue", "val"]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(item.name), 1)
                    ], 64))
              ], 40, _hoisted_5)
            ]),
            _: 2
          }, 1024))
        ]),
        _: 1
      }, 8, ["onVirtualScroll", "items"])
    ], 2), [
      [_vShow, _ctx.open]
    ])
  ], 8, _hoisted_1))
}