
import CardButton from '@/components/Application/entranceExamPage/TestDataBaseModal/CardButton/index.vue';
import SearchIcon from '@/components/Icons/default/SearchIcon.vue';
import Input from '@/components/Library/Input/index.vue';
import DefaultTheme from '@/theme/defaultTheme';
import { computed, defineComponent, onActivated, onMounted, onUnmounted, PropType, ref } from 'vue';

export default defineComponent({
  name: 'InstitutionComponent',
  emits: ['getSelection'],
  components: {
    CardButton,
    Input,
    SearchIcon
  },
  props: {
    source: { type: Array as PropType<Array<string>>, default: () => [] },
    isExams: { type: Boolean, default: false },
    choices: { type: Array as PropType<Array<string>>, default: () => [] },
  },
  setup(props, { emit }) {
    const inputValue = ref('')
    const selectedValue = ref('')
    const loadAllInstitutions = ref<boolean>(false)

    const innerWidth = ref(window.innerWidth)
    const width = ref('22em')

    const setNewTab = () => {
      innerWidth.value = window.innerWidth
    }

    window.addEventListener('resize', setNewTab)

    onMounted(() => {
      if (innerWidth.value <= 768) {
        width.value = '19em'
      }
    })
    onUnmounted(() => window.removeEventListener('resize', setNewTab))

    const handleClick = (value: string) => {
      selectedValue.value = value
      emit('getSelection', value)
    }

    const items = computed(() => {
      const source = props.source.length ? props.source.filter((item) => item.match(/^(?!ENEM|ENCCEJA|ENADE|SIMULADO)/)) : []

      if (inputValue.value.length > 0) {
        return source.filter((item) => item.toLowerCase().includes(inputValue.value.toLowerCase()))
      }

      return source
    })

    onActivated(() => {
      if (props.isExams) selectedValue.value = props.choices[0] ? props.choices[0] : ''
      else selectedValue.value = props.choices[2] ? props.choices[2] : ''

      inputValue.value = ''
    })

    return { inputValue, items, selectedValue, loadAllInstitutions, handleClick, width, DefaultTheme }
  },
})
