import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalChangePassword = _resolveComponent("ModalChangePassword")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("title", null, _toDisplayString(_ctx.title), 1),
    _createVNode(_component_ModalChangePassword, {
      height: 'auto',
      visibility: _ctx.flags.visibility,
      "onUpdate:visibility": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.flags.visibility) = $event)),
      redirect: true,
      description: 'Para sua própria segurança é necessário que atualize a sua senha antes de continuar.',
      "not-closable": true
    }, null, 8, ["visibility", "description"])
  ], 64))
}