export const defaultColors = {
  black: '#000000',
  gray: '#D0D3DB',
  graphite: '#2E2E2E',
  grayColor: '#353535',
  whiteColor: '#fff',
  colorGrayDark: '#999999',
  colorGray: '#cccccc',
  blueColor: '#00bddc',
  greenBtnColor: '#1a951a',
  backdropColor: 'hsla(272, 34%, 22%, 0.8)',

  secondaryGray: '#717386',
  lightGray: '#F5F5F5',
  disabled: '#9699AB',

  error: '#E50006',
  errorHover: '#BE0003',

  defaultBackground: '#FEFEFE',
  secondaryBackground: '#F0F0F2',
  selectedItemBackground: '#F3E9F7',

  defaultBorder: '#BCBFCC',
  highlightBorder: '#717386',
  successBorder: '#9DEADC',
  errorBorder: '#FDA29B',
  disabledBorder: '#D0D3DB',

  defaultFontColor: '#3A3A4A',
  warningFontColor: '#945906',
  errorFontColor: '#B42318',

  selectedOptionBackground: '#F7F7F7',

  correctQuestionPrimary: '#077B70',
  correctQuestionBackground: '#E8FCF8',
  correctQuestionBorder: '#9DEADC',
  correctQuestionNumber: '#52d3b3',
  correctQuestionNumberHover: '#35a886',

  incorrectQuestionPrimary: '#B42318',
  incorrectQuestionBackground: '#FFFBFA',
  incorrectQuestionBorder: '#FEE4E2',
  incorrectQuestionNumber: '#f70b0b',
  incorrectQuestionNumberHover: '#d70707',

  defaultLoadingBackground: 'rgba(0, 0, 0, 0.1)',
  defaultLoadingBorder: 'rgba(0, 0, 0, 0.2)',

  lightGreenColor: '#43b544',

  gray25: '#F7F9FF',
  gray50: '#EDF0FA',
  gray100: '#E6E7EB',
  gray200: '#E0E3F0',
  gray300: '#C5C9DB',
  gray400: '#9396AB',
  gray500: '#6E7186',
  gray600: '#545669',
  gray700: '#3A3A4A',
  gray750: '#383747',
  gray800: '#242433',
  gray900: '#18181E',

  error25: '#FFFBFA',
  error50: '#FEF3F2',
  error100: '#FEE4E2',
  error200: '#FECDCA',
  error300: '#FDA29B',
  error400: '#F97066',
  error500: '#F04438',
  error600: '#D92D20',
  error700: '#B42318',
  error800: '#912018',
  error900: '#7A271A',

  warning25: '#FFFFE5',
  warning50: '#FFFDCC',
  warning100: '#FEF498',
  warning200: '#FBE66F',
  warning300: '#FCD319',
  warning400: '#F7C10F',
  warning500: '#F2AC08',
  warning600: '#C98703',
  warning700: '#945906',
  warning800: '#522C02',
  warning900: '#301B00',

  success25: '#F5FFFC',
  success50: '#E8FCF8',
  success100: '#C3F3EA',
  success200: '#9DEADC',
  success300: '#70DBC9',
  success400: '#48C1B1',
  success500: '#2C9E91',
  success600: '#077B70',
  success700: '#09625A',
  success800: '#114D4A',
  success900: '#053837',

  tertiary25: '#F5FCFF',
  tertiary50: '#EBFAFF',
  tertiary100: '#DCF7FF',
  tertiary200: '#A6EFFF',
  tertiary300: '#7CE4F7',
  tertiary400: '#0CD1F5',
  tertiary500: '#00BDDC',
  tertiary600: '#009EB4',
  tertiary700: '#007B86',
  tertiary800: '#005658',
  tertiary900: '#00322B',

  blue25: '#F5FAFF',
  blue50: '#EFF8FF',
  blue100: '#D1E9FF',
  blue200: '#B2DDFF',
  blue300: '#84CAFF',
  blue400: '#53B1FD',
  blue500: '#2E90FA',
  blue600: '#1570EF',
  blue700: '#175CD3',
  blue800: '#1849A9',
  blue900: '#194185',

  darkBlue25: '#F5F8FF',
  darkBlue50: '#EFF4FF',
  darkBlue100: '#D1E0FF',
  darkBlue200: '#D1E0FF',
  darkBlue300: '#84ADFF',
  darkBlue400: '#528BFF',
  darkBlue500: '#2970FF',
  darkBlue600: '#155EEF',
  darkBlue700: '#004EEB',
  darkBlue800: '#0040C1',
  darkBlue900: '#00359E',

  indigo25: '#F5FAFF',
  indigo50: '#EFF8FF',
  indigo100: '#D1E9FF',
  indigo200: '#B2DDFF',
  indigo300: '#84CAFF',
  indigo400: '#53B1FD',
  indigo500: '#2E90FA',
  indigo600: '#1570EF',
  indigo700: '#175CD3',
  indigo800: '#1849A9',
  indigo900: '#194185',

  purple25: '#FAFAFF',
  purple50: '#F4F3FF',
  purple100: '#EBE9FE',
  purple200: '#D9D6FE',
  purple300: '#BDB4FE',
  purple400: '#9B8AFB',
  purple500: '#7A5AF8',
  purple600: '#6938EF',
  purple700: '#5925DC',
  purple800: '#5925DC',
  purple900: '#3E1C96',

  darkOrange25: '#FFF9F5',
  darkOrange50: '#FFF4ED',
  darkOrange100: '#FFE6D5',
  darkOrange200: '#FFD6AE',
  darkOrange300: '#FF9C66',
  darkOrange400: '#FF692E',
  darkOrange500: '#FF4405',
  darkOrange600: '#E62E05',
  darkOrange700: '#BC1B06',
  darkOrange800: '#97180C',
  darkOrange900: '#771A0D',

  orange25: '#FEFAF5',
  orange50: '#FEF6EE',
  orange100: '#FDEAD7',
  orange200: '#F9DBAF',
  orange300: '#F7B27A',
  orange400: '#F38744',
  orange500: '#EF6820',
  orange600: '#E04F16',
  orange700: '#B93815',
  orange800: '#932F19',
  orange900: '#772917',

  fuchsia25: '#FEFAFF',
  fuchsia50: '#FDF4FF',
  fuchsia100: '#FBE8FF',
  fuchsia200: '#F6D0FE',
  fuchsia300: '#EEAAFD',
  fuchsia400: '#E478FA',
  fuchsia500: '#D444F1',
  fuchsia600: '#BA24D5',
  fuchsia700: '#9F1AB1',
  fuchsia800: '#821890',
  fuchsia900: '#6F1877',
} as const;

