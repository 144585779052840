
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  name: 'PazzeiOptionsMenu',
  components: {},
  emits: ['actionValue'],
  props: {
    optionsCheckBox: { type: Object as PropType<Array<{ label: string; action: () => void }>> },
  },

  setup() {
    const isLoading = ref(true)

    const handleOptionClick = (option: any) => {
      option.action()
    }

    return { isLoading, handleOptionClick }
  },
})
