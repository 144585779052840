
import ContentModalCreateCoupon from '@/components/Application/couponPage/ContentModalCreateCoupon.vue'
import TablesComponentCoupon from '@/components/Application/planSchoolPortal/mountTableComponent/TablesComponent.vue'
import PaginationTableComponent from '@/components/Application/planSchoolPortal/mountTableComponent/components/PaginationTableComponent.vue'
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiInput from '@/components/Generics/PazzeiInput.vue'
import AddIcon from '@/components/Icons/default/AddIcon.vue'
import CouponInvalidIcon from '@/components/Icons/default/CouponInvalidIcon.vue'
import CouponValidIcon from '@/components/Icons/default/CouponValidIcon.vue'
import SearchIcon from '@/components/Icons/default/SearchIcon.vue'
import UnBlockSchoolIcon from '@/components/Icons/default/UnBlockSchoolIcon.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import Modal from '@/components/Library/Modal/index.vue'
import ModalConfirmation from '@/components/Library/ModalConfirmation/index.vue'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import paymentsService from '@/services/payment'
import DefaultTheme from '@/theme/defaultTheme'
import { CouponInfosData, ResponseCouponListType } from '@/types/ResponseCouponType'
import { formatCurrency } from '@/utils/common/formatCurrency'
import { columnsCoupon } from '@/utils/hardCoded/coupon/coupon'
import debounce from 'quasar/src/utils/debounce.js';import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, onBeforeUnmount, onMounted, provide, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'ViewCouponPage',
  components: {
    GenericLayout,
    MainLayoutWrapper,
    ModalConfirmation,
    PazzeiBtn,
    PazzeiInput,
    TablesComponentCoupon,
    PaginationTableComponent,
    Modal,
    ContentModalCreateCoupon,
    SearchIcon
  },

  setup() {
    const isSearchInputExpanded = ref(true)
    const isModalOpen = ref(false)
    const sizeColumnsCoupon = ref('1fr / 0.5fr 1.5fr 2.5fr 1.5fr 1.2fr 1.2fr 1.2fr 1.5fr 1fr 0.25fr')
    const optionsPagination = [10, 20, 50, 100]
    const router = useRouter()
    const isModalConfirmOpen = ref(false)
    const innerWidth = ref(window.innerWidth)
    const currentPage = ref(1)
    const pageSize = ref(20)
    const isLoading = ref(false)
    const sizeLoading = ref('60px')
    const totalElements = ref(0)
    const filterField = ref<string | undefined>('')
    const heightLoading = ref('5.5em')
    const $q = useQuasar()
    const previousPageSize = ref(20)
    const previousCurrentPage = ref(1)
    const heightTable = ref('53.5em')
    const widthTable = ref('70em')
    const heightModal = ref('56.5em')
    const heightModalSmall = ref('22em')
    const isModalConfirm = ref(false)
    const widthModal = ref('45.5em')
    const isDisabled = ref<boolean | undefined>(undefined)
    const rows = ref<Array<CouponInfosData>>([])
    const dataTableRow = ref<CouponInfosData>()
    const couponId = ref(-1)

    const openModal = () => {
      isModalOpen.value = true
      isModalConfirm.value = false
    }
    const closeModal = () => (isModalOpen.value = false)
    const setNewTab = () => {
      innerWidth.value = window.innerWidth
    }
    window.addEventListener('resize', setNewTab)

    const receiveChangeSize = (value: boolean) => {
      isModalConfirm.value = value
    }

    const handlePageSizeChange = (selectedPageSize: number) => {
      if (selectedPageSize !== previousPageSize.value) {
        pageSize.value = selectedPageSize
        getCouponData()
        previousPageSize.value = selectedPageSize
      }
    }

    const handleCurrentPage = (selectedCurrentPage: number) => {
      if (selectedCurrentPage !== previousCurrentPage.value) {
        currentPage.value = selectedCurrentPage
        getCouponData()
        previousCurrentPage.value = selectedCurrentPage
      }
    }

    onMounted(() => {
      if (innerWidth.value <= 768) {
        sizeColumnsCoupon.value = '1fr /  0.5fr 2fr 2.5fr 1.5fr 1.2fr 1.2fr 1.2fr 1.5fr 1fr 0.25fr'
        heightTable.value = '58vh'
        sizeLoading.value = '2.1875em'
        widthTable.value = '80em'
        heightLoading.value = '6em'
        widthModal.value = '80%'
        heightModal.value = '44em'
        heightModalSmall.value = '14em'
      } else if (innerWidth.value <= 1500) {
        widthModal.value = '37em'
        heightModal.value = '45.5em'
        heightModalSmall.value = '18em'
      }

      getCouponData()
    })

    const handleModalConfirm = () => {
      if (dataTableRow.value && !isDisabled.value) {
        isModalConfirmOpen.value = true
      } else {
        ValidateOrInvalidateCoupons()
      }
    }

    const iconProps = {
      fill: DefaultTheme().colors.pinkColor,
      stroke: DefaultTheme().colors.pinkColor,
      size: 20,
    }

    const optionsMenu = ref([
      {
        label: 'Ver Quem Utilizou',
        icon: UnBlockSchoolIcon,
        iconProps,
        action: (row: any) => router.push({ name: 'viewUsersCouponPage', params: { id: row.id }, query: { codigoCupom: row.code } }),
      },
      { label: '', icon: '', action: handleModalConfirm },
    ])

    const handleValueUpdated = (value: CouponInfosData) => {
      dataTableRow.value = value
      couponId.value = value.id
      isDisabled.value = value.isDisabled

      if (isDisabled.value !== undefined) {
        optionsMenu.value[1].label = `${isDisabled.value ? 'Validar' : 'Invalidar'} Cupom`
        optionsMenu.value[1].icon = !isDisabled.value ? CouponValidIcon : CouponInvalidIcon
        optionsMenu.value[1].iconProps = iconProps
      }
    }

    const ValidateOrInvalidateCoupons = async () => {
      try {
        await paymentsService.patch(`/coupons?id=${couponId.value}`)
        $q.notify({
          textColor: 'grey-1',
          message: `Cupom ${isDisabled.value ? 'validado' : 'invalidado'} com sucesso`,
          color: 'green',
          position: 'top',
          classes: 'notify',
        })

        getCouponData()
      } catch (error) {
        console.error(error)
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao alterar status',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    const getCouponData = async () => {
      const requestBody = {
        page: currentPage.value,
        pageSize: pageSize.value,
        search: {
          code: filterField.value !== '' ? filterField.value : undefined,
        },
      }

      isLoading.value = true
      try {
        const { data: infos } = await paymentsService.post<ResponseDTO<ResponseCouponListType>>('/coupons/list', requestBody)
        rows.value = infos.data.data.map((it) => {
          const showFormatedValue = Number(it.typeId) === 1 ? `${Number(it.value) * 100}%` : formatCurrency(Number(it.value))
          return {
            id: it.id,
            code: it.code,
            description: it.description,
            usageLimit: it.usageLimit || it.usageLimit === 0 ? String(it.usageLimit) : '',
            usedCoupons: it.usedCoupons || it.usedCoupons === 0 ? String(it.usedCoupons) : '',
            typeId: Number(it.typeId) === 1 ? 'Porcentagem' : 'Valor',
            value: it.value || Number(it.value) === 0 ? showFormatedValue : '',
            expiresAt: it.expiresAt,
            isDisabled: it.isDisabled,
          }
        })
        totalElements.value = infos.data.totalElements
      } catch (error) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao buscar dados',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      } finally {
        isLoading.value = false
      }
    }

    const debouncedGetCouponData = debounce(() => {
      getCouponData()
    }, 500)

    watch(filterField, () => {
      currentPage.value = 1
      debouncedGetCouponData()
    })

    provide('heightLoading', heightLoading)
    provide('optionsMenu', optionsMenu)
    provide('emitValueToParent', handleValueUpdated)

    onBeforeUnmount(() => {
      window.removeEventListener('resize', setNewTab)
    })

    return {
      openModal,
      handlePageSizeChange,
      handleCurrentPage,
      getCouponData,
      closeModal,
      ValidateOrInvalidateCoupons,
      receiveChangeSize,
      isSearchInputExpanded,
      heightModalSmall,
      isModalConfirm,
      isModalOpen,
      optionsPagination,
      innerWidth,
      sizeLoading,
      isLoading,
      totalElements,
      filterField,
      heightTable,
      widthTable,
      sizeColumnsCoupon,
      columnsCoupon,
      rows,
      isModalConfirmOpen,
      heightModal,
      widthModal,
      AddIcon,
      DefaultTheme,
    }
  },
})
