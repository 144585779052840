import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f00eaa96"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "div-header-container" }
const _hoisted_2 = { class: "div-nav-coupon" }
const _hoisted_3 = { class: "q-ma-none font-bold text-primary font-style-header" }
const _hoisted_4 = { class: "grid-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowBackIcon = _resolveComponent("ArrowBackIcon")!
  const _component_TablesComponentCoupon = _resolveComponent("TablesComponentCoupon")!
  const _component_PaginationTableComponent = _resolveComponent("PaginationTableComponent")!
  const _component_MainLayoutWrapper = _resolveComponent("MainLayoutWrapper")!
  const _component_GenericLayout = _resolveComponent("GenericLayout")!

  return (_openBlock(), _createBlock(_component_GenericLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_MainLayoutWrapper, null, {
        nav: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("button", {
                class: "button-back",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.push('/cupons')))
              }, [
                _createVNode(_component_ArrowBackIcon, {
                  fill: _ctx.DefaultTheme().colors.pinkColor
                }, null, 8, ["fill"])
              ]),
              _createElementVNode("h2", _hoisted_3, "Cupom - " + _toDisplayString(_ctx.nameCoupon), 1)
            ])
          ])
        ]),
        main: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_TablesComponentCoupon, {
              columns: _ctx.usersCoupon,
              rows: _ctx.rows,
              sizeColumnsTable: _ctx.sizeColumnsCoupon,
              isLoading: _ctx.isLoading,
              sizeLoading: _ctx.sizeLoading,
              heightTable: _ctx.heightTable,
              widthTable: _ctx.widthTable
            }, null, 8, ["columns", "rows", "sizeColumnsTable", "isLoading", "sizeLoading", "heightTable", "widthTable"])
          ])
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_PaginationTableComponent, {
            onPageSizeChange: _ctx.handlePageSizeChange,
            onCurrentPage: _ctx.handleCurrentPage,
            totalElements: _ctx.totalElements
          }, null, 8, ["onPageSizeChange", "onCurrentPage", "totalElements"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}