import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-224fd0ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bottomActions__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "left-action", {}, undefined, true),
    _renderSlot(_ctx.$slots, "right-action", {}, undefined, true)
  ]))
}