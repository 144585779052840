import { minLength, helpers, maxLength } from '@vuelidate/validators'
import { numberOnly } from '@/utils/regex/passwordRegex'

const listCodeRule = {
  minLength: helpers.withMessage('O código da lista é composto por 10 caracteres numéricos', minLength(10)),
  maxLength: helpers.withMessage('O código da lista é composto por 10 caracteres numéricos', maxLength(10)),
  regex: helpers.withMessage('O código da lista é composto por 10 caracteres numéricos', helpers.regex(numberOnly)),
}

export default listCodeRule
