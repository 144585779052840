
import ContentModalHistoryTest from '@/components/Application/testProficiency/ContentModalHistoryTest.vue'
import PazzeiCardSubject from '@/components/Generics/PazzeiCardSubject.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutSubWrapper from '@/components/Layouts/MainLayoutSubWrapper.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import Modal from '@/components/Library/Modal/index.vue'
import { defineComponent, onBeforeUnmount, onMounted, provide, ref } from 'vue'
import { ResponseDTO } from '../dtos/ResponseDTOs/ResponseDTO'
import listsService from '../services/list'
import { ProficiencySubjectType } from '../types/ProficiencySubjectType'

export default defineComponent({
  name: 'ProficiencyTestPage',
  components: {
    GenericLayout,
    MainLayoutWrapper,
    MainLayoutSubWrapper,
    PazzeiCardSubject,
    Modal,
    ContentModalHistoryTest,
  },
  setup() {
    const proficiencySubjectsInfoFinished = ref<ProficiencySubjectType[]>([])
    const proficiencySubjectsInfoNotStarted = ref<ProficiencySubjectType[]>([])
    const proficiencySubjectsInfoInProgress = ref<ProficiencySubjectType[]>([])
    const isExerciseList = ref(true)
    const isLoading = ref(true)
    const subjectName = ref('')
    const isOpenTestHistoryModal = ref(false)
    const widthModal = ref('70em')
    const heightModal = ref('40em')
    const innerWidth = ref(window.innerWidth)
    const setNewTab = () => {
      innerWidth.value = window.innerWidth

      if (innerWidth.value <= 768) {
        widthModal.value = '90%'
        heightModal.value = '22em'
      } else {
        widthModal.value = '70em'
        heightModal.value = '40em'
      }
    }
    window.addEventListener('resize', setNewTab)

    let proficiencyExams: ProficiencySubjectType[] = []
    const separateByStatus = () => {
      const notStarted = proficiencyExams.filter((item) => item.status === 'not_started')
      const inProgress = proficiencyExams.filter((item) => item.status === 'in_progress')
      const finished = proficiencyExams.filter((item) => item.status === 'finished')

      proficiencySubjectsInfoNotStarted.value = notStarted
      proficiencySubjectsInfoInProgress.value = inProgress
      proficiencySubjectsInfoFinished.value = finished
    }

    onMounted(async () => {
      if (innerWidth.value <= 768) {
        widthModal.value = '90%'
        heightModal.value = '22em'
      }

      try {
        const { data } = await listsService.get<ResponseDTO<Array<ProficiencySubjectType>>>('/cat/subjects-with-cat-info')
        proficiencyExams = data.data

        separateByStatus()
      } catch (error) {
        console.error(error)
      } finally {
        isLoading.value = false
      }
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', setNewTab)
    })

    const handleValueUpdated = (value: string) => (subjectName.value = value)

    const openHistoricProficiency = (value: boolean) => (isOpenTestHistoryModal.value = value)

    const closeHistoricProficiency = () => (isOpenTestHistoryModal.value = false)

    provide('isExerciseList', isExerciseList)
    provide('emitValueToParent', handleValueUpdated)
    provide('openHistoric', openHistoricProficiency)

    return {
      proficiencySubjectsInfoNotStarted,
      proficiencySubjectsInfoInProgress,
      proficiencySubjectsInfoFinished,
      isOpenTestHistoryModal,
      widthModal,
      heightModal,
      subjectName,
      isLoading,
      closeHistoricProficiency,
    }
  },
})
