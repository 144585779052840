import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-83ab04d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "authLayout_wrapper" }
const _hoisted_2 = { class: "authLayout_header" }
const _hoisted_3 = { class: "header_topLeft" }
const _hoisted_4 = { href: "https://pazzei.com.br" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "header_topRight" }
const _hoisted_7 = { href: "https://superprofessor.com.br" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "authLayout_content" }
const _hoisted_10 = { class: "authLayout_content__slot" }
const _hoisted_11 = { class: "authLayout_content__slotForm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", _hoisted_4, [
          _createElementVNode("img", {
            class: "header_logo header_logo__pazzei",
            src: _ctx.DefaultTheme().assets.logo,
            alt: "Logotipo Pazzei"
          }, null, 8, _hoisted_5)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("a", _hoisted_7, [
          _createElementVNode("img", {
            class: "header_logo header_logo__superpro",
            src: _ctx.DefaultTheme().assets.superProLogo,
            alt: "Logotipo SuperPro"
          }, null, 8, _hoisted_8)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _withDirectives(_createElementVNode("h2", {
          class: _normalizeClass(["authLayout_content__slotGreetings line-height q-ma-none q-mb-md global-color", { 'text-center': _ctx.textCenter }])
        }, " Seja bem vindo, " + _toDisplayString(_ctx.person) + "! Bons estudos ", 3), [
          [_vShow, _ctx.greetings]
        ]),
        _createElementVNode("h2", {
          class: _normalizeClass(["authLayout_content__slotTitle text-h5 q-ma-none q-mb-md global-color", { 'text-center': _ctx.textCenter }])
        }, _toDisplayString(_ctx.heading.title), 3),
        _createElementVNode("h3", {
          class: _normalizeClass(["authLayout_content__slotSubtitle text-h6 q-ma-none q-mb-md global-color", { 'text-center': _ctx.textCenter }])
        }, _toDisplayString(_ctx.heading.subtitle), 3),
        _createElementVNode("div", _hoisted_11, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ])
    ])
  ]))
}