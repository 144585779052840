import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 16 19",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M10.7778 13.1893L15.2222 17.6338M15.2222 13.1893L10.7778 17.6338M7.22222 11.4116C3.78578 11.4116 1 14.1974 1 17.6338H7.22222M10.7778 5.18934C10.7778 7.15302 9.1859 8.7449 7.22222 8.7449C5.25854 8.7449 3.66667 7.15302 3.66667 5.18934C3.66667 3.22567 5.25854 1.63379 7.22222 1.63379C9.1859 1.63379 10.7778 3.22567 10.7778 5.18934Z",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}