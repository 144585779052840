export const columnsAdmin: Array<{ label: string; key: string }> = [
  { key: 'name', label: 'Nome' },
  { key: 'telephone', label: 'Telefone' },
  { key: 'email', label: 'E-mail' },
  { key: 'roleDescription', label: 'Permissão' },
  { key: 'plan', label: 'Plano' },
  { key: 'createdAt', label: 'Data de Registro' },
  { key: 'roleCode', label: 'Créditos' },
  { key: 'isActive', label: 'Situação' },
]

export const columnsHistoric = [
  { key: 'holderName', label: 'Nome do Titular' },
  { key: 'recurrence', label: 'Recorrência' },
  { key: 'paymentMethod', label: 'Forma de Pagamento' },
  { key: 'price', label: 'Preço Final' },
  { key: 'createdAt', label: 'Aquisição' },
  { key: 'status', label: 'Status da compra' },
  { key: 'reversedAmount', label: 'Valor Estornado' },
]

export const filtersAdmin = [
  {
    type: 'input',
    placeholder: 'Pesquisar',
    width: '100%',
    maxLength: 80,
    value: '',
    key: 'name',
  },
  {
    type: 'input',
    placeholder: 'Pesquisar',
    width: '100%',
    maxLength: 15,
    value: '',
    key: 'telephone',
  },
  {
    type: 'input',
    placeholder: 'Pesquisar',
    width: '100%',
    maxLength: 80,
    value: '',
    key: 'email',
  },
  {
    type: 'select',
    placeholder: 'Selecione',
    options: ['Diretor', 'Professor', 'Administrador', 'Aluno'],
    value: '',
    key: 'roleDescription',
  },
  {
    type: 'select',
    placeholder: 'Selecione',
    options: ['Essencial'],
    value: '',
    key: 'plan',
  },
  {
    type: 'date',
    placeholder: '00/00/0000',
    width: '100%',
    maxLength: 80,
    value: '',
    key: 'createdAt',
  },
  {
    type: 'select',
    placeholder: 'Selecione',
    options: ['Liberado', 'Bloqueado'],
    value: '',
    key: 'roleCode',
  },
  {
    type: 'select',
    placeholder: 'Selecione',
    options: ['Ativo', 'Inativo'],
    value: '',
    key: 'isActive',
  },
]
