export class AuthenticationDTO {
  email: string
  password: string
  changeEmail?: boolean
  stayLoggedIn?: boolean
  subdomain?: string | null

  constructor(email: string, password: string, changeEmail?: boolean, stayLoggedIn?: boolean, subdomain?: string | null) {
    this.email = email
    this.password = password
    this.changeEmail = changeEmail
    this.stayLoggedIn = stayLoggedIn
    this.subdomain = subdomain
  }
}
