
import CloseIcon from '@/components/Icons/default/CloseIcon.vue';
import DefaultTheme from '@/theme/defaultTheme';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CardImage',
  props: {
    image: {
      type: String,
      default: '',
    },
    showImageTeleport: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CloseIcon,
  },
  setup(_, { emit }) {
    const closeModalOutside = (event: Event) => {
      if (event.target === event.currentTarget) {
        emit('close')
      }
    }

    const closeButton = () => {
      emit('close')
    }

    return { closeModalOutside, closeButton, DefaultTheme }
  },
})
