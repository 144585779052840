
import NoFarewell from '@/components/ TrialExpired/NoFarewell.vue'
import PazzeiButton from '@/components/Generics/Redesign/PazzeiButton.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import Card from '@/components/Library/Card/index.vue'
import TrialCard from '@/components/Trial/TrialCard.vue'
import InstitutionPlans from '@/helpers/InstitutionPlans'
import getUserInfos from '@/helpers/UserInfos'
import authService from '@/services/authService'
import { CardModule } from '@/types/CardModuleType'
import { InstitutionConfigType } from '@/types/InstitutionConfigType'
import { defineComponent, inject, onBeforeMount, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'HomePage',
  components: { MainLayoutWrapper, GenericLayout, Card, TrialCard, PazzeiButton, NoFarewell },
  setup() {
    const router = useRouter()
    const cardsContentToRender = ref<CardModule[]>([])
    const showNoFarewellComponent = ref(false);
    const userName = ref('');
    const isLoading = ref(false);

    const institutionConfig = inject<InstitutionConfigType>('institutionConfig');
    const userShouldSeeAlerts = ref(false);

    const setCardsToDisplay = async () => {
      const area = localStorage.getItem('areas') ?? ''
      const areasToCheck = JSON.parse(area)
      const userConfig = authService.getPermissions();

      const enemPermissions = userConfig?.permissions.filter(permission => permission.includes('enem'));

      
      if (areasToCheck.length) {
        cardsContentToRender.value = [
          {
            id: 1,
            description: 'Crie listas de exercícios personalizadas por matéria, fonte e conteúdo',
            buttonTitle: 'Montar minha lista',
            goTo: '/listas',
          },
          {
            id: 2,
            description: 'Responda provas anteriores das principais bancas de vestibular do Brasil',
            buttonTitle: 'Ver provas anteriores',
            goTo: '/banco-de-provas',
          },
        ]

        if (!!enemPermissions?.length || !institutionConfig?.isWhiteLabel) {
          cardsContentToRender.value.push({
            id: 3,
            description: 'Com foco no Enem? Estude em blocos de questões para responder no seu tempo',
            buttonTitle: 'Treinar para o Enem',
            goTo: '/modulo-enem',
          })
        }

      } else {
        cardsContentToRender.value = [
          {
            id: 2,
            cardTitle: 'Monte listas de estudo',
            description: 'Crie listas de exercícios personalizadas por matéria, fonte e conteúdo',
            buttonTitle: 'Montar minha lista',
            goTo: '/listas',
          },
          {
            id: 3,
            cardTitle: 'Estude para o vestibular',
            description: 'Responda provas de edições anteriores dos principais vestibulares do Brasil',
            buttonTitle: 'Acessar banco de provas',
            goTo: '/banco-de-provas',
          },
        ]
      }
    }

    const handleNoFarewellComponent = (flag: boolean) => {
      showNoFarewellComponent.value = flag;
      isLoading.value = false;
    }

    const showChoiceHeading = ref(false);

    const trialInfo = reactive({
      credits: 0,
      headingMessage: ''
    });

    const setTrialInfo = async (info: { credits: number, headingMessage: string }) => {
      trialInfo.credits = info?.credits;
      trialInfo.headingMessage = info?.headingMessage;
      
      if (trialInfo.credits < 0) {
        cardsContentToRender.value = [
          {
            id: 2,
            cardTitle: '',
            description: 'Resolva listas compartilhadas com você.',
            buttonTitle: 'Ver listas compartilhadas',
            goTo: '/listas-compartilhadas',
            className: 'secondary-btn'
          },
          {
            id: 3,
            cardTitle: '',
            description: 'Configure sua conta, dados pessoais e assinatura',
            buttonTitle: 'Configurar minha conta',
            goTo: '/meus-dados',
            className: 'secondary-btn',
          },
        ]
      }

      isLoading.value = false;
    }

    onBeforeMount(async () => {
      isLoading.value = true;
    })

    onMounted(async () => {
      await setCardsToDisplay();
      const { roleCode, name } = await getUserInfos();
      userName.value = name;
      const institutionPlan = await InstitutionPlans.getPlan();
      userShouldSeeAlerts.value = ['pazzei:trial', 'pazzei:no-credits'].includes(roleCode) && !institutionPlan;


      if (!userShouldSeeAlerts.value) {
        isLoading.value = false;
      }
    })

    return { router, cardsContentToRender, handleNoFarewellComponent, showNoFarewellComponent, userName, setTrialInfo, showChoiceHeading, trialInfo, isLoading }
  },
})
