
import PazzeiButton from '@/components/Generics/Redesign/PazzeiButton.vue'
import PazzeiModal from '@/components/Generics/Redesign/PazzeiModal/PazzeiModal.vue'
import { FilterListsDTO, GenericFilterDTO } from '@/dtos/modal/FilterListsDTO'
import { UserHasListDTO } from '@/dtos/SubjectInfoDTO'
import DefaultTheme from '@/theme/defaultTheme'
import { defineComponent, PropType } from 'vue'
import ClearFiltersIcon from '../Icons/default/redesign/sharedList/ClearFiltersIcon.vue'

export default defineComponent({
  name: 'ModalClearFilters',
  emits: ['close', 'updateSubjects', 'close-modal-clear-filters', 'clear-filters'],
  props: {
    status: { type: Object as PropType<FilterListsDTO> },
    availableAnswers: { type: Object as PropType<GenericFilterDTO> },
    listCreators: { type: Array as PropType<Array<UserHasListDTO>>, default: () => [] }
  },
  components: {
    PazzeiModal,
    PazzeiButton,
  },
  setup(_props, { emit }) {
    const context = {
      name: 'clearFilters',
      icon: ClearFiltersIcon,
      iconProps: { stroke: DefaultTheme().colors.error600, size: 48 }
    }

    const handleCleanFilters = () => {
      emit('clear-filters');
      emit('close-modal-clear-filters');
    }

    return {
      context,
      handleCleanFilters
    }
  }
})
