
import ActionButton from '@/components/ActionButton/ActionButton.vue'
import EyeIcon from '@/components/Icons/default/EyeIcon.vue'
import EyeOffIcon from '@/components/Icons/default/EyeOffIcon.vue'
import LoginIcon from '@/components/Icons/default/LoginIcon.vue'
import Mail01Icon from '@/components/Icons/default/Mail01Icon.vue'
import InputIcon from '@/components/InputIcon/InputIcon.vue'
import PazzeiForgotPwd from '@/components/LoginForms/ModalForgotPwd.vue'
import { AuthenticationDTO } from '@/dtos/AuthenticationDTO'
import { HandleErrorAxiosResponseDTO } from '@/dtos/HandleErrorAxiosResponseDTO'
import { InstitutionHasInstitutionPlanDTO } from '@/dtos/ResponseDTOs/InstitutionPlanDTO'
import { LoginResponseDTO } from '@/dtos/ResponseDTOs/LoginResponseDTO'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import { UserDTO } from '@/dtos/ResponseDTOs/UserDTO'
import TallyService from '@/lib/tally/services/tallyService'
import router from '@/router'
import authService from '@/services/authService'
import authenticationService from '@/services/authentication'
import groupsService from '@/services/groups'
import listsService from '@/services/list'
import usersService from '@/services/users'
import DefaultTheme from '@/theme/defaultTheme'
import email from '@/utils/vuelidateRules/emailRules'
import listCode from '@/utils/vuelidateRules/listCodeRules'
import { useVuelidate } from '@vuelidate/core'
import { AxiosError } from 'axios'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { Ref, computed, defineComponent, inject, onMounted, onUnmounted, provide, reactive, ref } from 'vue'
import HintTooltip from '@/components/Generics/HintTooltip/HintTooltip.vue'

export default defineComponent({
  name: 'B2bAuthForm',
  components: { HintTooltip, InputIcon, ActionButton, PazzeiForgotPwd },
  props: { listCode: { type: String, required: false } },
  setup(props) {
    const isModalVisible = ref(false)
    const isLoading = ref(false)
    const stayLoggedIn = ref(false)

    const showForgotPasswordModal = () => {
      isModalVisible.value = true
    }

    const closeModal = () => (isModalVisible.value = false);

    const rules = computed(() => {
      return {
        email,
        listCode,
      }
    })

    const redirectToSignUp = () => {
      router.push('/b2b/registro')
    }

    const formData: { email: string; password: string, listCode: string } = reactive({
      email: '',
      password: '',
      listCode: props.listCode ?? '',
    })

    const getUser = inject<Ref<UserDTO> | undefined>('userDTO')
    const tempUserDTO = ref<UserDTO | undefined>()

    provide('userDTO', tempUserDTO)

    onMounted(() => {
      formData.email = sessionStorage.getItem('email') as string
    })

    const $q = useQuasar();
    const v$ = useVuelidate(rules, formData);

    const errors = reactive(() => {
      const [fieldEmail, fieldListCode] = [v$.value.email, v$.value.listCode];

      return {
        email: (fieldEmail.required.$invalid ? fieldEmail.required?.$message : '')
            || (fieldEmail.regex.$invalid ? fieldEmail.regex?.$message : ''),
        listCode: (fieldListCode.minLength.$invalid ? fieldListCode.minLength?.$message : '')
            || (fieldListCode.maxLength.$invalid ? fieldListCode.maxLength?.$message : '')
            || (fieldListCode.regex.$invalid ? fieldListCode.regex?.$message : '')
      }
    })

    onUnmounted(() => {
      if (tempUserDTO.value) getUser!.value = tempUserDTO.value
    })

    const submit = async () => {
      const result = await v$.value.$validate()

      if (result) {
        try {
          const loginDTO = new AuthenticationDTO(formData.email, formData.password, false, stayLoggedIn.value)
          isLoading.value = true

          const res = await authenticationService.post<ResponseDTO<LoginResponseDTO>>('/', loginDTO)

          authService.setUserOnLocal(res.data.data.token, res.data.data.refreshToken)
          localStorage.setItem('keeplogged', String(stayLoggedIn.value))

          const { data } = await listsService.get<ResponseDTO<Array<string>>>('/areas')
          localStorage.setItem('areas', JSON.stringify(data.data))

          const responseMe = await usersService.get<ResponseDTO<UserDTO>>('/me')
          const userId = responseMe.data.data.id;

          tempUserDTO.value = new UserDTO({
            name: responseMe.data.data.name,
            lastName: responseMe.data.data.lastName,
            email: responseMe.data.data.email,
            roleCode: responseMe.data.data.roleCode,
            registrationCompleted: responseMe.data.data.registrationCompleted,
            credits: responseMe.data.data.credits,
            autoRenew: responseMe.data.data.autoRenew,
            groupId: 0,
            createdAt: responseMe.data.data.createdAt,
            updatedAt: responseMe.data.data.updatedAt,
          })

          sessionStorage.setItem(
            'userInfos',
            JSON.stringify({
              credits: tempUserDTO.value.credits,
              autoRenew: tempUserDTO.value.autoRenew,
              institutionId: responseMe.data.data.institutionId,
              roleCode: tempUserDTO.value.roleCode,
              registrationCompleted: tempUserDTO.value.registrationCompleted,
              createdAt: tempUserDTO.value.createdAt,
              updatedAt: tempUserDTO.value.updatedAt,
            })
          )
          sessionStorage.setItem('warningHasBeenShown', 'false')

          if (formData.listCode.length && errors().listCode === '') {
            await listsService.post(`/shared-lists/${formData.listCode}/code/add-user`);
          }

          const userLogged = localStorage.getItem('token') ?? sessionStorage.getItem('token')
          if (userLogged) {
            const userInfos: any = sessionStorage.getItem('userInfos');
            const institutionId = JSON.parse(userInfos)?.institutionId;
            let institutionPlanId;

            if(institutionId) {
              try {
                const { data: entity } = await groupsService.get<ResponseDTO<InstitutionHasInstitutionPlanDTO>>(
                  `/institution_plans/single?id=${institutionId}`
                );

                institutionPlanId = entity.data.institutionPlanId;

              } catch (error) {
                localStorage.removeItem('plan')
              }

              TallyService.loginForm(userId, institutionPlanId, JSON.parse(userInfos));
            }
          }

          const userScoped = authService.getHigherScoped()
          const userCanViewHomePage = authService.validatePermissions('self:lists:lists:enem-simulation')
          if (!userScoped) {
            isLoading.value = false
            $q.notify({
              textColor: 'grey-1',
              message: 'Erro ao fazer login, tente novamente',
              color: 'red',
              position: 'top',
              classes: 'notify',
            })
            router.push('/login')
          } else if (userScoped === 'self') {

            if (userCanViewHomePage) {
              router.push('/');
            }
            else router.push(localStorage.getItem('lastPage') ?? '/listas')
          } else if (userScoped === 'all') router.push('/instituicoes')
          else router.push('/usuarios')
        } catch (err) {
          if (err instanceof AxiosError) {
            const { error } = new HandleErrorAxiosResponseDTO(err)

            $q.notify({
              textColor: 'grey-1',
              message: error.userMessage,
              color: 'red',
              position: 'top',
              classes: 'notify',
            })
          }
        } finally {
          isLoading.value = false
        }
      }
    }

    return {
      closeModal,
      showForgotPasswordModal,
      isModalVisible,
      v$,
      formData,
      errors,
      submit,
      stayLoggedIn,
      isLoading,
      isPwd: ref(true),
      redirectToSignUp,
      EyeIcon,
      EyeOffIcon,
      Mail01Icon,
      DefaultTheme,
      LoginIcon,
    }
  },
})
