
import ContentModalRegisterSchool from '@/components/Application/planSchoolPortal/modals/modalSchoolComponent/ContentModalRegisterSchool.vue'
import PaginationTableComponent from '@/components/Application/planSchoolPortal/mountTableComponent/components/PaginationTableComponent.vue'
import TableComponentSchool from '@/components/Application/planSchoolPortal/mountTableComponent/TablesComponent.vue'
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiInput from '@/components/Generics/PazzeiInput.vue'
import AddIcon from '@/components/Icons/default/AddIcon.vue'
import BlockSchoolIcon from '@/components/Icons/default/BlockSchoolIcon.vue'
import EditRegisterIcon from '@/components/Icons/default/EditRegisterIcon.vue'
import LockIcon from '@/components/Icons/default/LockIcon.vue'
import SearchIcon from '@/components/Icons/default/SearchIcon.vue'
import UnBlockSchoolIcon from '@/components/Icons/default/UnBlockSchoolIcon.vue'
import UnLockIcon from '@/components/Icons/default/UnLockIcon.vue'
import ViewRegisterIcon from '@/components/Icons/default/ViewRegisterIcon.vue'
import ViewStudentsIcon from '@/components/Icons/default/ViewStudentsIcon.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import ModalConfirmation from '@/components/Library/ModalConfirmation/index.vue'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import groupsService from '@/services/groups'
import DefaultTheme from '@/theme/defaultTheme'
import { Institution, InstitutionEntities, ToggleResourceStatusInstitutionType } from '@/types/InstitutionType'
import { columns, filters } from '@/utils/hardCoded/planSchool/institutions/institution'
import debounce from 'quasar/src/utils/debounce.js';import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, onBeforeUnmount, onMounted, provide, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'viewInstitutionsPage',
  components: { GenericLayout, MainLayoutWrapper, ModalConfirmation, PazzeiBtn, PazzeiInput, TableComponentSchool, PaginationTableComponent, ContentModalRegisterSchool, SearchIcon },

  setup() {
    const isSearchInputExpanded = ref(true)
    const isModalOpen = ref(false)
    const isFilterSelected = ref(false)
    const isEditingSchool = ref(false)
    const isViewRegisterSchool = ref(false)
    const router = useRouter()
    const isModalConfirmOpen = ref(false)
    const isModalConfirmOpenBlock = ref(false)
    const dataTableRow = ref<any>()
    const infoActiveSchool = 'Após a confirmação, todos os usuários desta instituição serão inativados.'
    const infoBlockSchool = 'Após a confirmação, o diretor não poderá realizar ações na plataforma.'
    const innerWidth = ref(window.innerWidth)
    const currentPage = ref(1)
    const pageSize = ref(20)
    const $q = useQuasar()
    const rows = ref<Institution[]>([])
    const institutionId = ref<number>(-1)
    const isLoading = ref(false)
    const sizeLoading = ref('3.75em')
    const totalElements = ref(0)
    const filterName = ref<string | undefined>('')
    const filterGeneralName = ref<string | undefined>('')
    const filterTelephone = ref<string | undefined>('')
    const filterlegalRepresentative = ref<string | undefined>('')
    const filterEmail = ref<string | undefined>('')
    const filterDate = ref<string | undefined>('')
    const filterIsActive = ref<string | undefined>('')
    const isActive = ref<boolean | undefined>(undefined)
    const isBlocked = ref<boolean | undefined>(undefined)
    const updatedRows = ref<Institution[]>([])
    const heightLoading = ref('13.5em')
    const previousPageSize = ref(20)
    const previousCurrentPage = ref(1)
    const heightTable = ref('53.5em')
    const sizeEditLoading = ref('3em')

    const openModalSchool = () => {
      isModalOpen.value = true
      isEditingSchool.value = false
    }
    const openEditModalSchool = () => {
      isModalOpen.value = true
      isEditingSchool.value = true
    }
    const openViewRegisterModalSchool = () => {
      isModalOpen.value = true
      isEditingSchool.value = false
      isViewRegisterSchool.value = true
    }
    const openFilterSelected = () => (isFilterSelected.value = !isFilterSelected.value)
    const handleModalConfirm = () => {
      if (dataTableRow.value && isActive.value) {
        isModalConfirmOpen.value = true
      } else {
        toggleInstitutionInactiveStatus()
      }
    }
    const handleModalConfirmBlock = () => {
      if (dataTableRow.value && !isBlocked.value) {
        isModalConfirmOpenBlock.value = true
      } else {
        toggleInstitutionBlockStatus()
      }
    }
    const setNewTab = () => {
      innerWidth.value = window.innerWidth
    }
    window.addEventListener('resize', setNewTab)

    onMounted(() => {
      if (innerWidth.value <= 768) {
        sizeLoading.value = '2.1875em'
        heightTable.value = '59.1vh'
        heightLoading.value = '16em'
        sizeEditLoading.value = '2em'
      }

      getListInstitutions()
    })

    const handleFiltersApplied = (filterValues: { name: string; phone: string; email: string; legalRepresentative: string; isActive: string; createdAt: string }) => {
      filterName.value = filterValues.name
      filterTelephone.value = filterValues.phone
      filterEmail.value = filterValues.email
      filterlegalRepresentative.value = filterValues.legalRepresentative
      filterIsActive.value = filterValues.isActive
      filterDate.value = filterValues.createdAt

      debouncedGetInstitutions()
    }

    const handlePageSizeChange = (selectedPageSize: number) => {
      if (selectedPageSize !== previousPageSize.value) {
        pageSize.value = selectedPageSize
        getListInstitutions()
        previousPageSize.value = selectedPageSize
      }
    }

    const handleCurrentPage = (selectedCurrentPage: number) => {
      if (selectedCurrentPage !== previousCurrentPage.value) {
        currentPage.value = selectedCurrentPage
        getListInstitutions()
        previousCurrentPage.value = selectedCurrentPage
      }
    }

    const getListInstitutions = async () => {
      const searchByAvancedName = filterGeneralName.value !== '' ? { name: filterGeneralName.value } : {}
      const searchByName = filterName.value !== '' ? { name: filterName.value } : {}

      const requestBody: { page: number; pageSize: number; search: Record<string, string | undefined>; filter: Record<string, boolean | undefined> } = {
        page: currentPage.value,
        pageSize: pageSize.value,
        search: {
          ...searchByAvancedName,
          ...searchByName,
          email: filterEmail.value !== '' ? filterEmail.value : undefined,
          owner: filterlegalRepresentative.value !== '' ? filterlegalRepresentative.value : undefined,
          phone: filterTelephone.value !== '' ? filterTelephone.value : undefined,
          date: filterDate.value !== '' ? filterDate.value : undefined,
        },
        filter: {},
      }
      if (filterIsActive.value !== '') {
        if (filterIsActive.value === 'Ativo') {
          requestBody.filter.isActive = true
          requestBody.filter.isBlocked = false
        } else if (filterIsActive.value === 'Bloqueado') {
          requestBody.filter.isActive = true
          requestBody.filter.isBlocked = true
        } else {
          requestBody.filter.isActive = false
          requestBody.filter.isBlocked = false
        }
      }

      isLoading.value = true
      try {
        const response = await groupsService.post<ResponseDTO<InstitutionEntities>>('/institutions/list', requestBody)
        rows.value = response.data.data.entities
        totalElements.value = response.data.data.totalElements

        updatedRows.value = rows.value.map((row: Institution) => {
          const formattedPhone = row.phone ? row.phone.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3') : '-'
          return {
            id: row.id,
            name: row.name,
            phone: formattedPhone,
            legalRepresentative: `${row.institutionManager?.name ? row.institutionManager?.name : '-'} ${row.institutionManager?.lastName ? row.institutionManager?.lastName : ''}`,
            email: row.institutionManager?.email,
            createdAt: row.createdAt,
            isActive: row.isActive,
            isBlocked: row.isBlocked,
          }
        })
      } catch (error) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao buscar dados',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
        console.error(error)
      } finally {
        isLoading.value = false
      }
    }

    watch(filterGeneralName, () => {
      debouncedGetInstitutions()
    })

    watch(isFilterSelected, () => {
      filterGeneralName.value = ''
      if (filterName.value !== '' && filterTelephone.value !== '' && filterEmail.value !== '' && filterlegalRepresentative.value !== '' && filterIsActive.value !== '' && filterDate.value !== '') {
        debouncedGetInstitutions()
      }
    })

    const iconProps = {
      fill: DefaultTheme().colors.pinkColor,
      stroke: DefaultTheme().colors.pinkColor,
      size: 20,
    }

    const optionsMenu = ref([
      { label: 'Visualizar Cadastro', icon: ViewRegisterIcon, iconProps, action: () => openViewRegisterModalSchool?.() },
      {
        label: 'Visualizar Usuários',
        icon: ViewStudentsIcon,
        iconProps,
        action: (row: Institution) => {
          router.push({ name: 'viewStudentsInstitutionsPage', params: { id: row.id } })
        },
        disabled: false,
      },
      {
        label: 'Editar Cadastro',
        icon: EditRegisterIcon,
        iconProps,
        action: () => {
          openEditModalSchool?.()
          isViewRegisterSchool.value = false
        },
      },
      {
        label: '',
        icon: '',
        action: () => handleModalConfirm?.(),
        disabled: false,
      },
      {
        label: '',
        icon: '',
        action: () => handleModalConfirmBlock?.(),
        disabled: false,
      },
    ])

    const handleValueUpdated = (value: Institution) => {
      dataTableRow.value = value
      institutionId.value = value.id
      isActive.value = value.isActive
      isBlocked.value = value.isBlocked

      if (isActive.value !== undefined) {
        optionsMenu.value[3].label = `${isActive.value ? 'Inativar' : 'Ativar'} Escola`
        optionsMenu.value[3].icon = isActive.value ? BlockSchoolIcon : UnBlockSchoolIcon
        optionsMenu.value[3].iconProps = iconProps
      }

      if (!isActive.value) {
        optionsMenu.value[4].disabled = true
        optionsMenu.value[4].label = `${isBlocked.value ? 'Desbloquear' : 'Bloquear'} Escola`
        optionsMenu.value[4].icon = isBlocked.value ? LockIcon : UnLockIcon
        optionsMenu.value[4].iconProps = iconProps
      } else if (isActive.value && isBlocked.value !== undefined) {
        optionsMenu.value[4].disabled = false
        optionsMenu.value[4].label = `${isBlocked.value ? 'Desbloquear' : 'Bloquear'} Escola`
        optionsMenu.value[4].icon = isBlocked.value ? LockIcon : UnLockIcon
        optionsMenu.value[4].iconProps = iconProps
      }
    }

    const toggleInstitutionInactiveStatus = async () => {
      const data = { id: institutionId.value, activate: (isActive.value = !isActive.value) }
      try {
        await groupsService.patch<Promise<ToggleResourceStatusInstitutionType>>('/institutions/active-status', data)

        $q.notify({
          textColor: 'grey-1',
          message: `Instituição ${isActive.value ? 'ativada' : 'inativada'} com sucesso`,
          color: 'green',
          position: 'top',
          classes: 'notify',
        })

        getListInstitutions()
      } catch (error) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao alternar o status da instituição',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    const toggleInstitutionBlockStatus = async () => {
      const data = { id: institutionId.value, block: (isBlocked.value = !isBlocked.value) }
      try {
        await groupsService.patch<Promise<ToggleResourceStatusInstitutionType>>('/institutions/block-status', data)

        $q.notify({
          textColor: 'grey-1',
          message: `Instituição ${isBlocked.value ? 'bloqueada' : 'desbloqueada'} com sucesso`,
          color: 'green',
          position: 'top',
          classes: 'notify',
        })

        getListInstitutions()
      } catch (error) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao alternar o status da instituição',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    const debouncedGetInstitutions = debounce(getListInstitutions, 500)

    provide('heightLoading', heightLoading)
    provide('emitNewValue', handleFiltersApplied)
    provide('openFilterSelected', openFilterSelected)
    provide('emitValueToParent', handleValueUpdated)
    provide('isFilterSelected', isFilterSelected)
    provide('optionsMenu', optionsMenu)
    provide('filters', filters)

    onBeforeUnmount(() => {
      window.removeEventListener('resize', setNewTab)
    })

    return {
      isSearchInputExpanded,
      isModalOpen,
      openModalSchool,
      openEditModalSchool,
      isEditingSchool,
      innerWidth,
      optionsMenu,
      openFilterSelected,
      isFilterSelected,
      rows,
      columns,
      infoActiveSchool,
      infoBlockSchool,
      isModalConfirmOpen,
      getListInstitutions,
      handlePageSizeChange,
      handleCurrentPage,
      toggleInstitutionInactiveStatus,
      institutionId,
      isLoading,
      sizeLoading,
      totalElements,
      handleFiltersApplied,
      filterGeneralName,
      toggleInstitutionBlockStatus,
      isModalConfirmOpenBlock,
      updatedRows,
      heightTable,
      sizeEditLoading,
      isViewRegisterSchool,
      AddIcon,
      DefaultTheme,
    }
  },
})
