
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CardButton',
  props: {
    selected: { type: Boolean, default: false },
    label: { type: String, default: '' },
    isDisabled: { type: Boolean, default: false },
  },
  setup() {
    return {}
  },
})
