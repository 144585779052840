import axios from 'axios'

const questionsService = axios.create({
  baseURL: process.env.VUE_APP_PAZZEI_QUESTIONS_BASE_URL,
  timeout: 60000,
})

questionsService.interceptors.request.use(
  (request) => {
    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

    if (request.headers && token) {
      request.headers.Authorization = `Bearer ${token}`
      request.headers['Access-Control-Allow-Origin'] = '*'
    }

    return request
  },
  (error) => Promise.reject(error)
)

export default questionsService

// export const localQuestionsService = axios.create({
//   baseURL: 'http://localhost:3004',
//   timeout: 60000,
// })

// localQuestionsService.interceptors.request.use(
//   (request) => {
//     const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjp7ImlkIjoiZThjMzQ4N2EtMDI5ZS00YjYxLThjYmItZGQwMGYwZTFkNTcxIiwidmFsaWRhdGlvblRva2VuIjoiMzNjYjZhMzUtNTFiYS00NjZiLTg3NzQtMTA5M2VlNjEyOWE1IiwicGVybWlzc2lvbnMiOlsic2VsZjphdXRoZW50aWNhdGlvbjpwYXNzd29yZDptYW5hZ2UiLCJzZWxmOmxpc3RzOmxpc3RzOmVuZW0tc2ltdWxhdGlvbiIsInNlbGY6bGlzdHM6bGlzdHM6bWFuYWdlIiwic2VsZjpsaXN0czpsaXN0czpyZXRyaWV2ZSIsInNlbGY6bGlzdHM6cmVzcG9uc2VzOm1hbmFnZSIsInNlbGY6bGlzdHM6cmVzcG9uc2VzOnJldHJpZXZlIiwic2VsZjpsaXN0czpzaGFyZWQtbGlzdHM6cmV0cmlldmUiLCJzZWxmOmxpc3RzOnRyaWVzOm1hbmFnZSIsInNlbGY6bGlzdHM6dHJpZXM6cmV0cmlldmUiLCJzZWxmOnF1ZXN0aW9uczpoZWFydGJlYXQ6bWFuYWdlIiwic2VsZjpxdWVzdGlvbnM6cXVlc3Rpb25zOnJldHJpZXZlIiwic2VsZjpxdWVzdGlvbnM6cXVlc3Rpb24tdGFnczpyZXRyaWV2ZSIsInNlbGY6cXVlc3Rpb25zOnJlc3BvbnNlczptYW5hZ2UiLCJzZWxmOnF1ZXN0aW9uczpzdWJqZWN0LXRyZWU6cmV0cmlldmUiLCJzZWxmOnJlcG9ydHM6ZmVlZGJhY2s6cmV0cmlldmUiLCJzZWxmOnJlcG9ydHM6Z29hbHM6cmV0cmlldmUiLCJzZWxmOnJlcG9ydHM6cGVyZm9ybWFuY2U6cmV0cmlldmUiLCJzZWxmOnRlc3RzOmxpc3RzOnJldHJpZXZlIiwic2VsZjp1c2VyczphZGRpdGlvbmFsLWluZm86bWFuYWdlIiwic2VsZjp1c2Vyczp1c2VyczptYW5hZ2UiLCJzZWxmOnVzZXJzOnVzZXJzOnJldHJpZXZlIiwic2VsZjp1c2Vyczp1c2Vycy10YWdzOm1hbmFnZSIsInNlbGY6dXNlcnM6dXNlcnMtdGFnczpyZXRyaWV2ZSJdfSwiaWF0IjoxNzI5MDg3Nzc5LCJleHAiOjE3MjkxNzQxNzl9.x9VO5Fdxp0TUJxFtMeVJkZ3IQsEdcFItwQ_EZEjfV9Q';

//     if (request.headers && token) {
//       request.headers.Authorization = `Bearer ${token}`
//       request.headers['Access-Control-Allow-Origin'] = '*'
//     }

//     return request
//   },
//   (error) => Promise.reject(error)
// )
