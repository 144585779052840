
import QuestionNumberLabel from '@/components/AnswerList/QuestionNumberLabel.vue'
import { ListTagKeyEnum } from '@/dtos/CreateListPage/ListTagDTO'
import { GetOneListResponseDTO } from '@/dtos/ResponseDTOs/GetOneListResponseDTO'
import { QuestionDTO } from '@/dtos/ResponseDTOs/QuestionDTO'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import { TagDTO } from '@/dtos/ResponseDTOs/TagDTO'
import { UserResponseDTO } from '@/dtos/ResponseDTOs/UserResponseDTO'
import listsService from '@/services/list'
import questionsService from '@/services/question'
import { QuestionCacheType } from '@/types/QuestionCacheType'
import { SingleTryType } from '@/types/SingleTryType'
import { largeAreas } from '@/utils/hardCoded/enemModule/enemModule'
import { AxiosError } from 'axios'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, onMounted, PropType, ref, watch } from 'vue'
import { HandleErrorAxiosResponseDTO } from '@/dtos/HandleErrorAxiosResponseDTO'

export default defineComponent({
  name: 'QuestionsControl',
  emits: ['loaded', 'getQuestionsAndAlternativesResponses', 'onQuestionClick'],
  components: {
    QuestionNumberLabel,
  },
  props: {
    listId: { type: Number, required: true },
    tryId: { type: Number, required: true },
    screen: { type: String as PropType<'show-full-report' | 'check-answers'>, default: 'show-full-report' },
    responsesCache: { type: Array as PropType<Array<QuestionCacheType>>, default: () => [] },
  },
  setup(props, { emit }) {
    const controller = new AbortController()
    const $q = useQuasar()

    const isListEnemModuleAndAtYourPace = ref(false)
    const isListEnemModule = ref(false)
    const listName = ref('')
    const isLoading = ref(true)

    const questions = ref<Array<{ name: string; questions: Array<{ key: string; value: number; isDoubt: boolean; isAnswered: boolean; isCorrect: boolean | null; label: number }> }>>([])
    const questionsSplittedIntoSeries = ref<
      Array<{
        name: string;
        series: Array<{
          number: number;
          questions: Array<{
            key: string;
            value: number;
            isDoubt: boolean;
            isAnswered: boolean;
            isCorrect: boolean | null;
            label: number
          }>
        }>
      }>
    >([])

    onMounted(async () => {
      const { listLargeAreas, orderQuestions, listEnemModuleAndAtYourPace, listEnemModule, isTest, showQuestions } = await getListInfos()

      const { userResponses, createdAt, duration } = await getUserResponses()

      const orderedQuestions = ensureOrderQuestions(orderQuestions, userResponses)

      if (listLargeAreas?.isListEnemModule) {
        await inferAreaQuestions(orderedQuestions)

        if (isListEnemModuleAndAtYourPace.value) splitQuestionsIntoSeries(orderedQuestions)
        else separateByLargeAreas(orderedQuestions)
      } else getAlternativesNormalLists(orderedQuestions)

      emit('loaded', true)

      emit('getQuestionsAndAlternativesResponses', { userResponses: orderedQuestions, listNam: listName.value, listEnemModuleAndAtYourPace, listEnemModule, isTest, createdAt, duration, showQuestions })
    })

    const handleQuestionState = (isDoubt: boolean, isAnswered: boolean, isCorrect: boolean | null, screen: string) => {
      if (screen === 'show-full-report')
        if (isAnswered && isCorrect !== null) return isCorrect ? 'correct' : 'wrong'
        else return 'unanswered'
      else {
        if (isAnswered) return 'answered'
        else return 'unanswered'
      }
    }

    const getListInfos = async () => {
      try {
        const { data } = await listsService.get<ResponseDTO<GetOneListResponseDTO>>('/id', { params: { listId: props.listId }, signal: controller.signal })

        listName.value = data.data.name ? data.data.name : ''

        const listLargeAreas: { areas: Array<string>; isListEnemModuleAndAtYourPace: boolean; isListEnemModule: boolean } = { areas: [], isListEnemModuleAndAtYourPace: false, isListEnemModule: false }
        if (data.data.tags && data.data.tags.length > 0) {
          isListEnemModule.value = true
          data.data.tags.forEach((tag) => {
            if (tag.key === ListTagKeyEnum.AREA) {
              listLargeAreas.areas.push(tag.value)
              listLargeAreas.isListEnemModule = true
            } else if (tag.value === 'no seu ritmo') {
              listLargeAreas.isListEnemModuleAndAtYourPace = true
              isListEnemModuleAndAtYourPace.value = true
            }
          })
        }

        listLargeAreas.areas.map((area) => ({ order: largeAreas.find((largeArea) => largeArea.title === area)!.order, area }))
        // .sort((a, b) => a.order - b.order)

        return {
          listLargeAreas: listLargeAreas.areas.length > 0 ? listLargeAreas : null,
          orderQuestions: data.data.listHasQuestions,
          listEnemModuleAndAtYourPace: listLargeAreas.isListEnemModuleAndAtYourPace,
          listEnemModule: listLargeAreas.isListEnemModule,
          isTest: data.data.isTest,
          showQuestions: data.data.config?.showQuestion ?? true,
        }
      } catch (err) {
        if (err instanceof AxiosError) {
          const { error } = new HandleErrorAxiosResponseDTO(err)
          $q.notify({
            textColor: 'grey-1',
            message: error.userMessage,
            color: 'yellow-8',
            position: 'top',
            classes: 'notify',
          })
        }
      }

      return { listLargeAreas: null, orderQuestions: [], listEnemModuleAndAtYourPace: false, listEnemModule: false, isTest: false, showQuestions: false }
    }

    const getUserResponses = async () => {
      try {
        const { data } = await listsService.get<ResponseDTO<SingleTryType>>('/tries/single', { params: { tryId: props.tryId, listId: props.listId }, signal: controller.signal })

        return { userResponses: data.data.userResponses, createdAt: data.data.createdAt, duration: data.data.duration }
      } catch (err) {
        if (err instanceof AxiosError) {
          const { error } = new HandleErrorAxiosResponseDTO(err)
          $q.notify({
            textColor: 'grey-1',
            message: error.userMessage,
            color: 'yellow-8',
            position: 'top',
            classes: 'notify',
          })
        }
      } finally {
        isLoading.value = false
      }

      return { userResponses: [], createdAt: '', duration: 0 }
    }

    const ensureOrderQuestions = (questionsOrder: Array<{ questionId: number; order: number }>, userResponses: Array<UserResponseDTO>) => {
      const questionCache: Array<QuestionCacheType> = []

      questionsOrder.forEach((question) => {
        const questionOrderFound = questionsOrder.find((questionOrder) => questionOrder.questionId === question.questionId)

        if (questionOrderFound) {
          const response = getAnswersOnInit(question.questionId, userResponses)

          questionCache.push({
            questionId: question.questionId,
            order: questionsOrder.indexOf(questionOrderFound) + 1,
            userResponses: response.alternativeIds ?? [],
            isAnswered: response.alternativeIds?.length ? true : false,
            isDoubt: response.isDoubt ?? false,
            isCorrect: response.isCorrect ?? null,
          })
        }
      })

      return questionCache
    }

    const getAnswersOnInit = (questionId: number, userResponses: Array<UserResponseDTO>) => {
      const answerFound = userResponses.find((ur) => ur.questionId === questionId)
      let answers: Partial<{ alternativeIds: Array<number>; isDoubt: boolean; isCorrect: boolean | null }> = {}

      if (answerFound) {
        let alternatives = Array<number>()

        if (answerFound.responseHasAlternatives) alternatives = answerFound.responseHasAlternatives.map((a) => a.alternativeId)

        answers = {
          alternativeIds: alternatives,
          isDoubt: answerFound.isDoubt,
          isCorrect: answerFound.isCorrect,
        }
      }

      return answers
    }

    const inferAreaQuestions = async (questionCache: Array<QuestionCacheType>) => {
      const promises = Array<Promise<any>>()

      const questionsRange = isListEnemModuleAndAtYourPace.value ? 15 : 45

      const iterationsAmount = questionCache.length / questionsRange

      for (let i = 0; i < iterationsAmount; i++) {
        const questionPromise = questionsService.get<ResponseDTO<QuestionDTO>>(
          `/${props.listId}/${questionCache[i * questionsRange].questionId}`
        )

        promises.push(questionPromise);
      }

      const response = await Promise.allSettled(promises)

      response.forEach((res) => {
        if (res.status === 'fulfilled') {
          const questionFound = questionCache.find((question) => question.questionId === res.value.data.data.id)

          if (questionFound) {
            questionFound.questionBelongingArea = res.value.data.data.tags.find((tag: TagDTO) => tag.key === ListTagKeyEnum.AREA)?.value

            const intervalStart = questionCache.indexOf(questionFound) % questionCache.length
            const intervalEnd = intervalStart + questionsRange

            for (let i = intervalStart; i < intervalEnd; i++) {
              if (questionCache[i]) questionCache[i].questionBelongingArea = questionFound.questionBelongingArea
            }
          }
        }
      })
    }

    const splitQuestionsIntoSeries = (questionCache: Array<QuestionCacheType>) => {
      const partitionQuestion: Array<Array<QuestionCacheType>> = []
      const iterationAmount = Math.ceil(questionCache.length / 15)

      for (let i = 0; i < iterationAmount; i++) {
        partitionQuestion.push(questionCache.slice(i * 15, (i + 1) * 15))
      }

      const arraySeries: Array<{
        name: string
        series: Array<{
          number: number;
          questions: Array<{
            key: string;
            value: number;
            isDoubt: boolean;
            isAnswered: boolean;
            isCorrect: boolean | null;
            label: number
          }>
        }>
      }> = []

      partitionQuestion.forEach((item, idx) => {
        const index = arraySeries.findIndex((tItem) => tItem.name === item[0].questionBelongingArea)

        if (index === -1) {
          arraySeries.push({
            name: item[0].questionBelongingArea || '',
            series: [
              {
                number: idx + 1,
                questions: item.map((item, idx) => {
                  return {
                    key: item.questionId.toString(),
                    value: item.order,
                    isDoubt: item.isDoubt,
                    isAnswered: item.isAnswered,
                    isCorrect: item.isCorrect !== null ? item.isCorrect! : null,
                    label: idx + 1,
                  }
                }),
              },
            ],
          })
        } else {
          arraySeries[index].series.push({
            number: idx + 1,
            questions: item.map((item, idx) => {
              return {
                key: item.questionId.toString(),
                value: item.order,
                isDoubt: item.isDoubt,
                isAnswered: item.isAnswered,
                isCorrect: item.isCorrect !== null ? item.isCorrect! : null,
                label: idx + 1,
              }
            }),
          })
        }
      })

      questionsSplittedIntoSeries.value = arraySeries
    }

    const separateByLargeAreas = (questionCache: Array<QuestionCacheType>) => {
      const partitionQuestion: Array<Array<QuestionCacheType>> = []
      const iterationAmount = Math.ceil(questionCache.length / 45)

      for (let i = 0; i < iterationAmount; i++) partitionQuestion.push(questionCache.slice(i * 45, (i + 1) * 45))

      const arraySeries: Array<{ name: string; questions: Array<{ key: string; value: number; isDoubt: boolean; isAnswered: boolean; isCorrect: boolean | null; label: number }> }> = []

      partitionQuestion.forEach((item) => {
        arraySeries.push({
          name: item[0].questionBelongingArea || '',
          questions: item.map((item) => {
            return {
              key: item.questionId.toString(),
              value: item.order,
              isDoubt: item.isDoubt,
              isAnswered: item.isAnswered,
              isCorrect: item.isCorrect !== null ? item.isCorrect! : null,
              label: item.order,
            }
          }),
        })
      })

      questions.value = arraySeries
    }

    const getAlternativesNormalLists = (questionCache: Array<QuestionCacheType>) => {
      const arraySeries: Array<{
        name: string;
        questions: Array<{
          key: string;
          value: number;
          isDoubt: boolean;
          isAnswered: boolean;
          isCorrect: boolean | null;
          label: number
        }>
      }> = []

      arraySeries.push({
        name: '',
        questions: questionCache.map((item, idx) => {
          return {
            key: item.questionId.toString(),
            value: item.order,
            isDoubt: item.isDoubt,
            isAnswered: item.isAnswered,
            isCorrect: item.isCorrect !== null ? item.isCorrect! : null,
            label: idx + 1,
          }
        }),
      })

      questions.value = arraySeries
    }

    watch(
      () => props.responsesCache,
      (newVal) => {
        if (newVal.length > 0) {
          if (!isListEnemModule.value) getAlternativesNormalLists(newVal)
          else {
            if (isListEnemModuleAndAtYourPace.value) splitQuestionsIntoSeries(newVal)
            else separateByLargeAreas(newVal)
          }
        }
      },
      { deep: true }
    )

    return { isListEnemModuleAndAtYourPace, handleQuestionState, questionsSplittedIntoSeries, questions, listName, isLoading, isListEnemModule }
  },
})
