import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["stroke"]
const _hoisted_4 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 66 66",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("rect", {
      x: "5",
      y: "5",
      width: "56",
      height: "56",
      rx: "28",
      fill: _ctx.DefaultTheme().colors.warning100
    }, null, 8, _hoisted_2),
    _createElementVNode("rect", {
      x: "5",
      y: "5",
      width: "56",
      height: "56",
      rx: "28",
      stroke: _ctx.DefaultTheme().colors.warning50,
      "stroke-width": "10"
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      d: "M32.9997 29.5V34.1666M32.9997 38.8333H33.0114M31.3842 23.5403L21.7885 40.1147C21.2563 41.034 20.9902 41.4937 21.0295 41.871C21.0638 42.2 21.2362 42.499 21.5038 42.6936C21.8106 42.9166 22.3417 42.9166 23.404 42.9166H42.5954C43.6577 42.9166 44.1888 42.9166 44.4956 42.6936C44.7632 42.499 44.9356 42.2 44.9699 41.871C45.0092 41.4937 44.7431 41.034 44.2109 40.1147L34.6152 23.5403C34.0848 22.6243 33.8197 22.1663 33.4737 22.0125C33.1719 21.8783 32.8274 21.8783 32.5257 22.0125C32.1797 22.1663 31.9146 22.6243 31.3842 23.5403Z",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_4)
  ], 8, _hoisted_1))
}