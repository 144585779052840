
import { defineComponent, inject, onMounted, onUnmounted, onUpdated, PropType, Ref, ref, watch } from 'vue';

export default defineComponent({
  props: {
    options: {
      type: Array as PropType<Array<string | number>>,
      required: true,
    },
    tabindex: {
      type: Number,
      required: false,
      default: -1,
    },
    readonly: {
      type: Boolean,
    },
    name: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
    },
    modelValue: {
      type: Array as PropType<Array<string | number>>,
    },
    selectedSubjects: { type: Array as PropType<Array<string | number>>, default: () => [] },
    rounded: Boolean,
    placeholder: String,
    error: Boolean,
    hideBottomSpace: Boolean,
    variant: { type: String as PropType<'primary' | 'primary-dark'>, default: 'primary' },
    errorMessage: String,
    valueSelectedDefault: Boolean,
    topic: String,
    valuePrimary: Number,
    itemsSelected: {
      type: Array as PropType<Array<string>>,
    },
    simpleValue: String,
    roundedSimple: Boolean,
    showChoices: Boolean,
    disabled: Boolean,
    newMode: { type: Boolean, default: false },
    openModal: { type: Boolean, default: false },
  },
  setup(props, context) {
    const vModel = ref<Array<string | number>>(props.modelValue ? props.modelValue : [])
    const inputShow = ref(props.modelValue ? props.modelValue[0] : '')
    const input = inject<Ref<Array<string | number>>>('color')

    const selected = ref<string>()
    const filtered = ref<string>('')
    const arraySelected = ref<Array<string | number> | undefined>([])
    const key = ref(0)

    onUpdated(() => {
      if (!props.newMode) {
        inputShow.value = vModel.value.join(', ').replaceAll('G1 - ', '')
        arraySelected.value = vModel.value
      }
    })

    watch(
      arraySelected,
      () => {
        if (props.newMode && arraySelected.value) {
          inputShow.value = arraySelected.value.join(', ').replaceAll('G1 - ', '')
        } else {
          arraySelected.value = vModel.value
          inputShow.value = vModel.value[0]
        }
      },
      { deep: true }
    )

    if (props.valueSelectedDefault) selected.value = props.valuePrimary ? props.valuePrimary.toString() : (props.options[props.options.length - 1] as string)

    const open = ref(false)
    const handleClick = (option: string | number) => {
      if (input) input.value = [option]
      vModel.value = [option]
      inputShow.value = option
      context.emit('update:modelValue', vModel)
      context.emit('update:simpleValue', option)
      open.value = false
    }

    const handleClose = (event: Event) => {
      if (props.multiple)
        if (event.target instanceof HTMLInputElement) {
          if (event.target.id !== 'input-search') open.value = false
        } else {
          open.value = false
        }
    }
    document.addEventListener('click', handleClose)

    const handleMultipleItems = (option: string, name: string) => {
      context.emit('input', option, name)

      if (vModel.value.some((item) => item === option)) {
        vModel.value.forEach((it, index) => {
          if (it === option) {
            return vModel.value.splice(index, 1)
          }
        })
      } else {
        vModel.value.push(option)
      }

      inputShow.value = vModel.value.join(', ').replaceAll('G1 - ', '')

      context.emit('update:modelValue', vModel)
    }

    onMounted(() => {
      key.value++
      if (props.valueSelectedDefault) {
        selected.value = props.valuePrimary ? props.valuePrimary.toString() : (props.options[props.options.length - 1] as string)
        context.emit('input', props.options[0])
      }
    })

    onUnmounted(() => {
      key.value = 0
      document.removeEventListener('click', handleClose)
    })

    const inputValidation = ref(false)
    watch(selected, () => {
      if (!selected.value) {
        inputValidation.value = true
      } else {
        inputValidation.value = false
      }
    })

    watch(
      () => props.selectedSubjects,
      () => {
        arraySelected.value = props.selectedSubjects
        vModel.value = props.selectedSubjects
      },
      { deep: true }
    )

    watch(
      () => props.openModal,
      () => {
        inputShow.value = props.selectedSubjects.join(', ').replaceAll('G1 - ', '')
        arraySelected.value = [...props.selectedSubjects]
        vModel.value = [...props.selectedSubjects]
      },
      { deep: true }
    )

    const handleClickMultiple = () => {
      if (!props.disabled)
        if (open.value !== true) {
          let timeout: number
          timeout = setTimeout(() => {
            open.value = !open.value
            clearTimeout(timeout)
          }, 10)
        }
    }

    return { inputShow, filtered, handleClickMultiple, inputValidation, selected, handleClick, handleMultipleItems, open, arraySelected, key }
  },
})
