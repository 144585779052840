<template>
  <transition name="fade" mode="out-in">
    <div class="levelBar_wrapper" :class="{ shown: inputValue.length > 0 }">
      <div class="levelBar_progress">
        <q-linear-progress size="8px" :value="data.level"></q-linear-progress>
      </div>
      <span class="levelBar_hint">
        {{data.hint}}
      </span>
    </div>
  </transition>
</template>

<script>

import { calculatePasswordStrength } from '@/utils/vuelidateRules/passwordRules';

export default {
  props: {
    inputValue: { type: String }
  },
  computed: {
    data() {
      const level = calculatePasswordStrength(this.$props.inputValue);

      const getHint = () => {
        if (level === 1) return 'Ótima!';
        if (level >= 0.75) return 'Forte';
        if (level >= 0.5) return 'Média';
        if (level >= 0) return 'Fraca';
      }


      return {
        level, hint: getHint()
      }
    }
  },
}
</script>

<style scoped>
.levelBar_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  padding: 4px 0;
  margin-bottom: 8px;
  transition: opacity 0.4s ease-in, height 0.4s ease-in;
  opacity: 0;
  height: 0;
}

.levelBar_wrapper.shown {
  opacity: 1;
  height: fit-content;
}

.levelBar_wrapper .levelBar_progress {
  position: relative;
  display: block;
  width: 100%;
}

.levelBar_wrapper .levelBar_hint {
  display: inline-flex;
  text-align: end;
  width: fit-content;
  color: var(--secondary);
  font-weight: 400;
  font-size: 14px;
}
</style>
