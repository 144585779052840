import { helpers, minLength, required } from '@vuelidate/validators'
import { cpfValidation } from '../common/cpfValidation'

const messageRequired = 'Este campo é obrigatório'

const changePayCard = {
  name: { required: helpers.withMessage(messageRequired, required) },
  document: {
    required: helpers.withMessage(messageRequired, required),
    minLength: helpers.withMessage('O cpf deve conter 11 caracteres', minLength(11)),
    cpfValid: helpers.withMessage('Insira um cpf válido', (value: string) => cpfValidation(value)),
  },
  cardNumber: {
    required: helpers.withMessage(messageRequired, required),
    minLength: helpers.withMessage('Insira um cartão válido', minLength(16)),
  },
  expirationMonth: { required: helpers.withMessage(messageRequired, required) },
  expirationYear: { required: helpers.withMessage(messageRequired, required) },
  cvv: {
    required: helpers.withMessage('Obrigatório', required),
    minLength: helpers.withMessage('Insira os 3 dígitos', minLength(3)),
  },
}

export default changePayCard
