
import QuestionWrapper from '@/components/Layouts/QuestionWrapper.vue'
import BreadCrumb from '@/components/Library/Breadcrumb/index.vue'
import Button from '@/components/Library/Button/index.vue'
import { QuestionTagsOrderEnum } from '@/enum'
import DefaultTheme from '@/theme/defaultTheme'
import axios from 'axios'
import { defineComponent, onMounted, onUnmounted, PropType, provide, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { CreateUpdateAnswerDTO } from '@/dtos/CreateUpdateAnswerDTO'
import { TagDTO } from '@/dtos/ResponseDTOs/TagDTO'
import listsService from '@/services/list'
import questionsService from '@/services/question'
import reportsService from '@/services/reports'
import { QuestionCacheType } from '@/types/QuestionCacheType'
import { AlternativesSelectorModelValueType } from '../Application/alternativesSelector/types/AlternativesSelectorModelValueType'
import CooliconIcon from '../Icons/default/CooliconIcon.vue'

export default defineComponent({
  name: 'ModalViewAnsweredQuestion',
  emits: ['update:responsesCache', 'close'],
  components: {
    QuestionWrapper,
    BreadCrumb,
    Button,
    CooliconIcon
  },
  props: {
    idQuestion: Number,
    listName: String,
    indexQuestion: Number,
    isListEnemModuleAndAtYourPace: Boolean,
    isListEnemModule: Boolean,
    page: { type: String as PropType<'check-answers' | 'answer-sheet'>, default: '' },
    hideJustification: { type: Boolean, default: false },
    responsesCache: { type: Array as PropType<Array<QuestionCacheType>>, default: () => [] },
  },
  setup(props, { emit }) {
    const controller = new AbortController()
    const { id, tryId } = useRoute().params
    const isLoading = ref(false)
    const justification = ref<string>()
    const feedback = ref<Record<string, { correctAlternativeId: number | null; ResponseAlternativeId: number | null }>>({})
    const correctAndSelect = ref<{ correctAlternativeId: number | null; ResponseAlternativeId: number | null; correctAlternativeIds?: number[]; responseAlternativeIds?: number[] }>()
    const allQuestionsOnList = ref<Array<{ questionId: number; order: number }>>([])
    const tempQuestionId = ref(props.idQuestion)
    const tempIndexQuestion = ref(props.indexQuestion)
    const nextQuestion = ref<number | undefined>()
    const previousQuestion = ref<number | undefined>()
    const flagNextQuestion = ref(0)
    const questionHaveIndex = ref(0)
    const innerWidth = ref(window.innerWidth)
    const justificationUrlAws = ref<string>('')
    const questionAws = ref('')
    const hideFeedback = ref(false)
    const alternativesModel = ref<AlternativesSelectorModelValueType>()
    const responsesCache = ref<Array<QuestionCacheType>>(props.responsesCache)

    const correctAlternatives = ref<Array<number>>([])
    const isInstantFeedback = ref(false)
    const questionType = ref('')

    const isDoubt = ref(false)
    const isLoadingDoubt = ref(false)
    const isTagsLoaded = ref(false)
    const questionTags = ref({
      type: '',
      area: '',
      level: '',
      subject: Array<string>(),
    })

    let responseIsNotSaveYet = false

    const showFeedback = (state: boolean) => (hideFeedback.value = state)

    const updateQuestionAws = (awsKey: string) => {
      questionAws.value = awsKey

      justificationUrlAws.value = process.env.VUE_APP_AWS_QUESTIONS_JUSTIFICATIONS_BASE_URL + questionAws?.value

      axios.get(justificationUrlAws.value, { signal: controller.signal }).then((res) => {
        justification.value = res.data
      })
    }

    provide('questionAwsKey', { questionAws, updateQuestionAws })

    const handleNextQuestion = async () => {
      isTagsLoaded.value = false
      hideFeedback.value = false
      questionHaveIndex.value = allQuestionsOnList.value.indexOf(allQuestionsOnList.value.find((it) => it.questionId === tempQuestionId.value)!)
      nextQuestion.value = allQuestionsOnList.value[++questionHaveIndex.value].questionId
      tempQuestionId.value = nextQuestion.value
      tempIndexQuestion.value!++
      flagNextQuestion.value++

      Object.keys(feedback.value).map((it) => {
        if (Number(it) === tempQuestionId.value) correctAndSelect.value = feedback.value[it]
      })

      if (props.page === 'check-answers') {
        if (!isInstantFeedback.value || questionType.value === 'Somatória') await saveResponse(alternativesModel.value!)

        getResponses()
      }
    }

    const isDoubtLabelButton = () => {
      if (isDoubt.value) return 'Desmarcar como dúvida'
      return 'Marcar como dúvida'
    }

    const saveResponse = async (model: AlternativesSelectorModelValueType) => {
      const data = new CreateUpdateAnswerDTO(model, parseInt(tryId as string), isDoubt.value)
      const { data: correctAlternative } = await questionsService.put('/', data)

      correctAlternatives.value = correctAlternative.data.correctAlternativeIds
    }

    const handleMarkAsDoubt = async () => {
      isLoadingDoubt.value = true
      try {
        isDoubt.value = !isDoubt.value
        if (alternativesModel.value) await saveResponse(alternativesModel.value)
        setResponses()
      } finally {
        isLoadingDoubt.value = false
      }
    }

    const handlePreviousQuestion = async () => {
      isTagsLoaded.value = false
      hideFeedback.value = false
      questionHaveIndex.value = allQuestionsOnList.value.indexOf(allQuestionsOnList.value.find((it) => it.questionId === tempQuestionId.value)!)
      previousQuestion.value = allQuestionsOnList.value[--questionHaveIndex.value].questionId
      nextQuestion.value = allQuestionsOnList.value[++questionHaveIndex.value].questionId
      tempQuestionId.value = previousQuestion.value
      tempIndexQuestion.value!--
      flagNextQuestion.value--

      Object.keys(feedback.value).map((it) => {
        if (Number(it) === tempQuestionId.value) correctAndSelect.value = feedback.value[it]
      })

      if (props.page === 'check-answers') {
        if (!isInstantFeedback.value || questionType.value === 'Somatória') await saveResponse(alternativesModel.value!)

        getResponses()
      }
    }

    const getQuestionType = async (type: string) => (questionType.value = type)

    const getInnerWidth = () => (innerWidth.value = window.innerWidth)

    window.addEventListener('resize', getInnerWidth)

    onMounted(async () => {
      const bodyElement = document?.body;
      if (bodyElement) bodyElement.style.overflow = 'hidden';

      listsService.get('/id', { params: { listId: id } }).then((res) => {
        allQuestionsOnList.value = res.data.data.listHasQuestions
        questionHaveIndex.value = allQuestionsOnList.value.indexOf(allQuestionsOnList.value.find((it) => it.questionId === tempQuestionId.value)!)
        isInstantFeedback.value = res.data.data.instantFeedback
      })

      if (props.page !== 'check-answers')
        reportsService.get('/feedback', { params: { listId: id, tryId: tryId }, signal: controller.signal }).then((res) => {
          feedback.value = res.data.data.questions

          Object.keys(feedback.value).map((it) => {
            if (Number(it) === props.idQuestion) correctAndSelect.value = feedback.value[it]
          })
        })
      else getResponses()
    })

    const getResponses = () => {
      const responses = responsesCache.value.find((response) => response.order === tempIndexQuestion.value)

      isDoubt.value = responses?.isDoubt || false

      alternativesModel.value = {
        alternatives: responses?.userResponses || [],
        questionId: responses?.questionId || -1,
      }
    }

    const setResponses = () => {
      const index = responsesCache.value.indexOf(responsesCache.value.find((response) => response.order === tempIndexQuestion.value)!)

      if (index !== -1) {
        responsesCache.value[index].userResponses = alternativesModel.value!.alternatives as Array<number>
        responsesCache.value[index].isDoubt = isDoubt.value
        responsesCache.value[index].isAnswered = alternativesModel.value!.alternatives.length > 0
        responseIsNotSaveYet = responsesCache.value[index].isAnswered
      }

      emit('update:responsesCache', responsesCache.value)
    }

    const getCorrectAlternatives = (correctAlternativeIds: Array<number>) => {
      correctAlternatives.value = correctAlternativeIds
    }

    const getTags = (tags: Array<TagDTO>) => {
      if (tags.length > 0) {
        questionTags.value.area = tags.find((tag) => tag.key === 'area')?.value || ''
        questionTags.value.level = tags.find((tag) => tag.key === 'level')?.value || ''

        let subjectTags = Array<string>()
        QuestionTagsOrderEnum.forEach((tag) => {
          subjectTags.push(tags.find((it) => it.key === tag.value)?.value || '')
        })

        questionTags.value.subject = subjectTags.filter((it) => it !== '')

        if (props.isListEnemModule) questionTags.value.type = props.isListEnemModuleAndAtYourPace ? `No seu ritmo - ${questionTags.value.area}` : `Simulado Enem - ${questionTags.value.area}`

        isTagsLoaded.value = true
      }
    }

    onUnmounted(() => {
      controller.abort()
      const bodyElement = document?.body;
      if (bodyElement) bodyElement.style.overflow = 'auto'

      if (props.page === 'check-answers' && !isInstantFeedback.value) saveResponse(alternativesModel.value!)
    })

    watch(
      alternativesModel,
      async () => {
        setResponses()

        if (isInstantFeedback.value && questionType.value.includes('Múltipla escolha') && alternativesModel.value && alternativesModel.value.alternatives.length > 0 && responseIsNotSaveYet)
          await saveResponse(alternativesModel.value!)

        correctAlternatives.value = []
      },
      { deep: true }
    )

    return {
      isLoading,
      id,
      hideFeedback,
      showFeedback,
      alternativesModel,
      correctAndSelect,
      justification,
      questionType,
      tempQuestionId,
      handleNextQuestion,
      handleMarkAsDoubt,
      innerWidth,
      getCorrectAlternatives,
      flagNextQuestion,
      tempIndexQuestion,
      allQuestionsOnList,
      getQuestionType,
      handlePreviousQuestion,
      getTags,
      questionTags,
      isDoubt,
      isDoubtLabelButton,
      isLoadingDoubt,
      correctAlternatives,
      isTagsLoaded,
      nextQuestion,
      isInstantFeedback,
      saveResponse,
      DefaultTheme,
    }
  },
})
