import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-175ed85c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inputs-wrapper" }
const _hoisted_2 = { class: "div-input" }
const _hoisted_3 = { class: "grid-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalConfirmation = _resolveComponent("ModalConfirmation")!
  const _component_ContentModalRegisterSchool = _resolveComponent("ContentModalRegisterSchool")!
  const _component_SearchIcon = _resolveComponent("SearchIcon")!
  const _component_PazzeiInput = _resolveComponent("PazzeiInput")!
  const _component_PazzeiBtn = _resolveComponent("PazzeiBtn")!
  const _component_TableComponentSchool = _resolveComponent("TableComponentSchool")!
  const _component_PaginationTableComponent = _resolveComponent("PaginationTableComponent")!
  const _component_MainLayoutWrapper = _resolveComponent("MainLayoutWrapper")!
  const _component_GenericLayout = _resolveComponent("GenericLayout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ModalConfirmation, {
      modelValue: _ctx.isModalConfirmOpen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isModalConfirmOpen) = $event)),
      label: "Tem certeza que deseja inativar esta escola?",
      info: _ctx.infoActiveSchool,
      "click-confirm": _ctx.toggleInstitutionInactiveStatus
    }, null, 8, ["modelValue", "info", "click-confirm"]),
    _createVNode(_component_ModalConfirmation, {
      modelValue: _ctx.isModalConfirmOpenBlock,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isModalConfirmOpenBlock) = $event)),
      label: "Tem certeza que deseja bloquear esta escola?",
      info: _ctx.infoBlockSchool,
      "click-confirm": _ctx.toggleInstitutionBlockStatus
    }, null, 8, ["modelValue", "info", "click-confirm"]),
    (_ctx.isModalOpen)
      ? (_openBlock(), _createBlock(_component_ContentModalRegisterSchool, {
          key: 0,
          visibility: _ctx.isModalOpen,
          "onUpdate:visibility": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isModalOpen) = $event)),
          isEditingSchool: _ctx.isEditingSchool,
          isViewRegisterSchool: _ctx.isViewRegisterSchool,
          listInstitution: _ctx.getListInstitutions,
          institutionId: _ctx.institutionId,
          sizeEditLoading: _ctx.sizeEditLoading
        }, null, 8, ["visibility", "isEditingSchool", "isViewRegisterSchool", "listInstitution", "institutionId", "sizeEditLoading"]))
      : _createCommentVNode("", true),
    _createVNode(_component_GenericLayout, null, {
      default: _withCtx(() => [
        _createVNode(_component_MainLayoutWrapper, null, {
          nav: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_PazzeiInput, {
                  mobileSchool: "",
                  "max-length": 80,
                  placeholder: 'Buscar instituições',
                  "hide-bottom-space": "",
                  "search-list": "",
                  "input-collapsed": _ctx.isSearchInputExpanded,
                  modelValue: _ctx.filterGeneralName,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filterGeneralName) = $event))
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_SearchIcon, {
                      size: 32,
                      fill: _ctx.DefaultTheme().colors.primary,
                      style: {"padding-left":"15px"},
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isSearchInputExpanded = !_ctx.isSearchInputExpanded))
                    }, null, 8, ["fill"])
                  ]),
                  button: _withCtx(() => [
                    _createElementVNode("button", {
                      class: _normalizeClass(["btn-filter", { active: _ctx.isFilterSelected }]),
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openFilterSelected()))
                    }, "Filtro avançado", 2)
                  ]),
                  _: 1
                }, 8, ["input-collapsed", "modelValue"])
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["btn-container", { 'btn-pazzei__collapsed': !_ctx.isSearchInputExpanded }])
              }, [
                _createVNode(_component_PazzeiBtn, {
                  icon: _ctx.AddIcon,
                  iconStroke: _ctx.DefaultTheme().colors.whiteColor,
                  label: "Cadastrar Escola",
                  fill: "",
                  class: _normalizeClass(["btn-pazzei", { 'btn-pazzei__collapsed': !_ctx.isSearchInputExpanded }]),
                  "btn-collapsed": !_ctx.isSearchInputExpanded,
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openModalSchool(), (_ctx.isViewRegisterSchool = false)))
                }, null, 8, ["icon", "iconStroke", "class", "btn-collapsed"])
              ], 2)
            ])
          ]),
          main: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_TableComponentSchool, {
                columns: _ctx.columns,
                rows: _ctx.updatedRows,
                isFilterSelected: _ctx.isFilterSelected,
                sizeColumnsTable: "1fr /2.5fr 1.2fr 2fr 2fr 1.2fr 1fr 0.25fr",
                showOptionsMenu: "",
                onFiltersApplied: _ctx.handleFiltersApplied,
                isLoading: _ctx.isLoading,
                sizeLoading: _ctx.sizeLoading,
                "height-table": _ctx.heightTable
              }, null, 8, ["columns", "rows", "isFilterSelected", "onFiltersApplied", "isLoading", "sizeLoading", "height-table"])
            ])
          ]),
          footer: _withCtx(() => [
            _createVNode(_component_PaginationTableComponent, {
              onPageSizeChange: _ctx.handlePageSizeChange,
              onCurrentPage: _ctx.handleCurrentPage,
              totalElements: _ctx.totalElements
            }, null, 8, ["onPageSizeChange", "onCurrentPage", "totalElements"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}