
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue';
import usersService from '@/services/users';
import { AxiosError } from 'axios';
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { PropType, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'ContentModalImportStudents',
  emits: ['close'],
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      required: false,
      default: '',
    },
    institutionId: {
      type: Number,
      required: false,
    },
    listUser: Function as PropType<() => void>,
    sizeEditLoading: {
      type: String,
      default: '245px',
    },

    sizeInputDaysGratuity: {
      type: String,
    },
    userPermission: {
      type: Boolean,
      default: false,
    },
    isStudentEssential: {
      type: Boolean,
      default: false,
    },
    isPageStudentIntitution: {
      type: Boolean,
      default: false,
    },
  },
  components: { PazzeiBtn },

  setup(props, { emit }) {
    const visibleModal = ref(true)
    const $q = useQuasar()
    const isLoading = ref(false)

    const notification = (success: boolean, message: string) => {
      if (success) {
        $q.notify({
          textColor: 'grey-1',
          message: message,
          color: 'green',
          position: 'top',
          classes: 'notify',
        })
      } else {
        $q.notify({
          textColor: 'grey-1',
          message: message,
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    const importedFile: any = ref(null);

    const handleFileImport = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const files = target.files as FileList;

      importedFile.value = files[0];
    }

    const importUsers = async () => {
      isLoading.value = true

      const { data: infos } = await usersService.get('/me');
      const institutionId = infos?.data?.institutionId;

      const formData = new FormData();
      formData.append('institutionId', institutionId);
      formData.append('file', importedFile.value);

      try {
        const data = await usersService.post(
          '/import',
          formData,
        );

        notification(true, data.data.userMessage || data.data.message)
      } catch (error) {
        const axiosError = error as AxiosError
        const message = (axiosError?.response?.data as { userMessage?: string })?.userMessage || 'Erro ao salvar dados'
        notification(false, message)
      } finally {
        props.listUser?.()
        isLoading.value = false
        emit('close')
      }
    }

    return {
      visibleModal,
      isLoading,
      importUsers,
      importedFile,
      handleFileImport,
    }
  },
})
