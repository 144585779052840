import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 4 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M6 3C6 4.65685 4.65685 6 3 6C1.34314 6 -8.45516e-07 4.65685 -9.17939e-07 3C-9.90363e-07 1.34315 1.34314 -5.87108e-08 3 -1.31134e-07C3.79565 -1.65913e-07 4.55871 0.31607 5.12132 0.878681C5.68393 1.44129 6 2.20435 6 3ZM6 12C6 13.6569 4.65685 15 3 15C1.34315 15 -4.52113e-07 13.6569 -5.24537e-07 12C-5.9696e-07 10.3431 1.34314 9 3 9C3.79565 9 4.55871 9.31607 5.12132 9.87868C5.68393 10.4413 6 11.2044 6 12ZM6 21C6 22.6569 4.65685 24 3 24C1.34315 24 -5.87108e-08 22.6569 -1.31134e-07 21C-2.03558e-07 19.3431 1.34315 18 3 18C3.79565 18 4.55871 18.3161 5.12132 18.8787C5.68393 19.4413 6 20.2044 6 21Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}