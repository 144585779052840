
import DefaultTheme from '@/theme/defaultTheme';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DeadlineCalendarIcon',
  props: {
    size: { type: Number, default: 40 },
    stroke: { type: String, default: DefaultTheme().colors.warning700 }, 
    fill: { type: String, default: 'currentColor' }, 
  },

  setup() {
    return {
      DefaultTheme
    };
  }
});
