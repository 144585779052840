export class GroupDTO {
  id: number | undefined
  name: string
  usersId: string[]

  constructor(name: string, usersId: string[], id?: number) {
    if (id) this.id = id
    this.name = name
    this.usersId = usersId
  }
}

export class EditNameGroupDTO {
  id: number
  name: string

  constructor(id: number, name: string) {
    this.id = id
    this.name = name
  }
}

export class AddUserDTO {
  id: number
  usersId: string[]

  constructor(id: number, usersId: string[]) {
    this.id = id
    this.usersId = usersId
  }
}
