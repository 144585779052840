import usersService from "@/services/users";

const getUserInfos = async () => {
  const userLogged = localStorage.getItem('token') ?? sessionStorage.getItem('token')
  
  if (!userLogged) { return; }

  try {
    const { data: infos } = await usersService.get('/me');
    return infos.data;
  } catch (e) {
    console.warn('Falha ao buscar informacões do usuário', e);
  }
}

export default getUserInfos;
