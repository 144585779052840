
import { defineComponent, onMounted, onUnmounted, onUpdated, ref, watch } from 'vue'
import Modal from '../Library/Modal/index.vue'
import Tab from '../Library/Tabs/Tab/index.vue'
import TabPanel from '../Library/Tabs/TabPanel/index.vue'
import TabPanels from '../Library/Tabs/TabPanels/index.vue'
import Tabs from '../Library/Tabs/Tabs/index.vue'
import EnemContent from './EnemContent.vue'
import EntranceExamContent from './EntranceExamContent.vue'

export default defineComponent({
  name: 'TestDataBaseModal',
  components: {
    Modal,
    Tabs,
    Tab,
    TabPanels,
    TabPanel,
    EnemContent,
    EntranceExamContent,
  },
  props: {
    modelValue: { type: Boolean, default: false, required: true },
  },
  setup(props, { emit }) {
    const modalState = ref(props.modelValue)
    const tabs = ref('Vestibular')
    onUpdated(() => (modalState.value = props.modelValue))

    const isAddReceivers = ref(false)

    const innerWidth = ref(window.innerWidth)
    const width = ref('60%')
    const heightModal = ref('90%')

    const setNewTab = () => {
      innerWidth.value = window.innerWidth
    }

    window.addEventListener('resize', setNewTab)

    onMounted(() => {
      if (innerWidth.value <= 768) {
        width.value = '100%'
        heightModal.value = '70%'
      }
      isAddReceivers.value = false
    })
    onUnmounted(() => window.removeEventListener('resize', setNewTab))

    watch(modalState, () => emit('update:modelValue', modalState.value))

    return {
      modalState,
      tabs,
      width,
      heightModal,
      isAddReceivers,
    }
  },
})
