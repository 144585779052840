
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue';
import GenericLayout from '@/components/Layouts/GenericLayout.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PaymentFinishedPage',
  components: {
    PazzeiBtn,
    GenericLayout,
  },
})
