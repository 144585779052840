import InstitutionConfigHelper from '@/helpers/InstitutionConfigHelper';
import { InstitutionConfigType } from '@/types/InstitutionConfigType';
import { checkAndSaveSubdomain, handleStagingSubdomain } from '@/utils/subdomain';
import ArcanjoBrandTheme from './ArcanjoBrand';
import BioExplicaBrandTheme from './BioExplicaBrand';
import PazzeiBrandTheme from './PazzeiBrand';
import { THEMES, ThemeType } from './types';

let DefaultTheme: ThemeType = PazzeiBrandTheme;

const loadCSS = async (subdomain: string) => {
  const handledSubdomain = handleStagingSubdomain(subdomain);

  switch (handledSubdomain) {
    case THEMES.PAZZEI:
    default:
      await import('../css/global.css');
      await import('../css/main.css');
      break;
    case THEMES.ARCANJO:
      await import('../css/ArcanjoBrand/global.css');
      await import('../css/ArcanjoBrand/main.css');
      break;
    case THEMES.BIOEXPLICA:
      await import('../css/BioExplicaBrand/global.css');
      await import('../css/BioExplicaBrand/main.css');
      break;
  }
};

let title = 'Pazzei';
export const productName = () => {
  return title;
}

const getDefaultTheme = (subdomain: string): ThemeType => {
  const handledSubdomain = handleStagingSubdomain(subdomain);

  switch (handledSubdomain) {
    case THEMES.ARCANJO:
      return ArcanjoBrandTheme;
    case THEMES.BIOEXPLICA:
      return BioExplicaBrandTheme;
    default:
      return PazzeiBrandTheme;
  }
};

export const loadTheme = async (): Promise<Partial<InstitutionConfigType>> => {
  try {
    const subdomain = checkAndSaveSubdomain();
    const institutionConfig = await InstitutionConfigHelper.getInstitutionsConfig();
    const theme = getDefaultTheme(subdomain);
    title = institutionConfig?.name
     ? institutionConfig?.name
     : 'Pazzei';

    if(institutionConfig?.logo) theme.assets.logo = institutionConfig.logo;
    if(institutionConfig?.shortLogo) theme.assets.shortLogo = institutionConfig.shortLogo;
    if(institutionConfig?.superProLogo) theme.assets.superProLogo = institutionConfig.superProLogo;
    
    await loadCSS(subdomain);
    DefaultTheme = theme;
    
    const deepClone = JSON.parse(JSON.stringify(institutionConfig));
    
    return {
      ...deepClone,
      config: { theme },
    }
  } catch (error) {
    console.error('Error loading theme or institution config:', error);
    await loadCSS(THEMES.PAZZEI);
    return {
      landingPageUrl: '/',
      isWhiteLabel: false,
      name: 'Pazzei',
      config: { theme: DefaultTheme },
    };
  }
}

const getActualTheme = () => DefaultTheme;

export default getActualTheme;
