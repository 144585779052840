
import { HandleErrorAxiosResponseDTO } from '@/dtos/HandleErrorAxiosResponseDTO'
import authenticationService from '@/services/authentication'
import { default as listService, default as listsService } from '@/services/list'
import questionsService from '@/services/question'
import usersService from '@/services/users'
import { AxiosError } from 'axios'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { computed, defineComponent, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { ResponseDTO } from './dtos/ResponseDTOs/ResponseDTO'
import { useFavicon } from './helpers/useFavicon'
import authService from './services/authService'
import creditsService from './services/credits'
import groupsService from './services/groups'
import paymentsService from './services/payment'
import reportsService from './services/reports'
import DefaultTheme from './theme/defaultTheme'
import lockedPaths from './utils/lockedPaths'

export default defineComponent({
  name: 'App',
  setup() {
    const $q = useQuasar()
    const route = useRoute()

    // Change favicon based on default theme
    const favicon = computed(() => DefaultTheme().assets.shortLogo)
    useFavicon(favicon.value)
    
    onMounted(async () => {
      const userLogged = localStorage.getItem('token') ?? sessionStorage.getItem('token')

      function id<T>(x: T) {
        return x
      }
      const keepLogged = async (error: unknown) => {
        if (error instanceof AxiosError) {
          if (error.code === 'ECONNABORTED') return
          else if (error.code === 'ERR_NETWORK' || error.code === 'NS_ERROR_DOM_BAD_URI') {
            $q.notify({
              textColor: 'grey-1',
              message: 'Erro de conexão',
              color: 'red',
              position: 'top',
              classes: 'notify',
            })
          }
          const axiosError = new HandleErrorAxiosResponseDTO(error)

          if (axiosError.error.httpStatus === 401) {
            if (route.path === '/login') {
              $q.notify({
                textColor: 'grey-1',
                message: axiosError.error.userMessage,
                color: 'red',
                position: 'top',
                classes: 'notify',
              })
            } else {
              const checkToken = localStorage.getItem('refreshToken')

              if (!checkToken) authService.logoutUser()
              else window.location.href = '/refresh'
            }
          } else if (axiosError.error.httpStatus >= 400 && axiosError.error.httpStatus !== 401) {
            if (
              axiosError.error.httpStatus === 403
              && lockedPaths.includes(route.path)
            ) {
              return;
            }

            if (axiosError.error.httpStatus !== 422)
              $q.notify({
                textColor: 'grey-1',
                message: axiosError.error.userMessage,
                color: 'red',
                position: 'top',
                classes: 'notify',
              })

            throw error
          } else if (axiosError.error.httpStatus >= 500) {
            $q.notify({
              textColor: 'grey-1',
              message: axiosError.error.userMessage,
              color: 'red',
              position: 'top',
              classes: 'notify',
            })
            throw error
          } else {
            throw error
          }
        } else {
          throw error
        }
      }

      authenticationService.interceptors.response.use(id, keepLogged)
      listService.interceptors.response.use(id, keepLogged)
      questionsService.interceptors.response.use(id, keepLogged)
      usersService.interceptors.response.use(id, keepLogged)
      paymentsService.interceptors.response.use(id, keepLogged)
      reportsService.interceptors.response.use(id, keepLogged)
      groupsService.interceptors.response.use(id, keepLogged)
      creditsService.interceptors.response.use(id, keepLogged)

      if (userLogged) {
        try {
          const { data } = await listsService.get<ResponseDTO<Array<string>>>('/areas')
          localStorage.setItem('areas', JSON.stringify(data.data))
        } catch (error) {
          localStorage.removeItem('token')
        }
      }
    })
  },
})
