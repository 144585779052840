import { ThemeType } from "../types";
import BioExplicaBrandAssets from "./assets";
import { BioExplicaBrandColors } from "./tokens/colors";
import { BioExplicaBrandFontSizes } from "./tokens/fonts";
import { BioExplicaBrandSizes } from "./tokens/sizes";

const BioExplicaBrandTheme: ThemeType = {
  colors: BioExplicaBrandColors,
  fontSizes: BioExplicaBrandFontSizes,
  sizes: BioExplicaBrandSizes,
  assets: BioExplicaBrandAssets
};

export default BioExplicaBrandTheme;
