
import { defineComponent, PropType } from 'vue'
import setLineGraphicProficiency from '../../../utils/graphics/lineProficiency'

export default defineComponent({
  name: 'LineChartHistoryProficiency',
  props: {
    dateValue: { type: Array as PropType<Array<string>>, default: () => [] },
    value: {
      type: Array as PropType<Array<number>>,
      default: () => [],
    },
  },
  setup(props) {
    const chart = setLineGraphicProficiency(props.dateValue, props.value)
    return {
      chart,
    }
  },
})
