
import ContentModalRegisterGroups from '@/components/Application/planSchoolPortal/modals/modalsGroupComponent/ContentModalRegisterGroups.vue'
import PaginationTableComponent from '@/components/Application/planSchoolPortal/mountTableComponent/components/PaginationTableComponent.vue'
import TablesComponentStudentsGroup from '@/components/Application/planSchoolPortal/mountTableComponent/TablesComponent.vue'
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiInput from '@/components/Generics/PazzeiInput.vue'
import AddIcon from '@/components/Icons/default/AddIcon.vue'
import ArrowBackIcon from '@/components/Icons/default/ArrowBackIcon.vue'
import EstatisticsIcon from '@/components/Icons/default/EstatisticsIcon.vue'
import RemoveSchoolIcon from '@/components/Icons/default/RemoveSchoolIcon.vue'
import SearchIcon from '@/components/Icons/default/SearchIcon.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import Modal from '@/components/Library/Modal/index.vue'
import ModalConfirmation from '@/components/Library/ModalConfirmation/index.vue'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import { GroupDTO } from '@/dtos/schoolPlanPages/GroupDTO/GroupDTO'
import groupsService from '@/services/groups'
import usersService from '@/services/users'
import DefaultTheme from '@/theme/defaultTheme'
import { Groups } from '@/types/GroupsType'
import { EntitiesUser, User } from '@/types/UsersType'
import { columnsUserGroup, filtersUserGroup } from '@/utils/hardCoded/planSchool/groups/groups'
import debounce from 'quasar/src/utils/debounce.js';import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, onMounted, provide, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'ViewStudentsGroupPage',
  components: {
    GenericLayout,
    MainLayoutWrapper,
    PazzeiBtn,
    PazzeiInput,
    TablesComponentStudentsGroup,
    PaginationTableComponent,
    Modal,
    ModalConfirmation,
    ContentModalRegisterGroups,
    SearchIcon,
    ArrowBackIcon,
  },

  setup() {
    const isSearchInputExpanded = ref(true)
    const isModalOpenStudent = ref(false)
    const isFilterSelected = ref(false)
    const sizeColumnGroups = ref('1fr /0.7fr 3fr 3fr 3fr 0.25fr')
    const optionsPagination = [10, 20, 50, 100]
    const router = useRouter()
    const isRegisterStudent = ref(false)
    const rowsUsersGroups = ref<User[]>([])
    const getResponse = ref<User[]>([])
    const innerWidth = ref(window.innerWidth)
    const widthModalSmall = ref('60.625em')
    const heightModalSmall = ref('24.5em')
    const totalElements = ref(0)
    const currentPage = ref(1)
    const pageSize = ref(20)
    const isLoading = ref(false)
    const sizeLoading = ref('3.75em')
    const $q = useQuasar()
    const route = useRoute()
    const groupId = String(route.params.id)
    const heightLoading = ref('5.5em')
    const heightTable = ref('47.5em')
    const filterName = ref<string | undefined>('')
    const filterId = ref<string | undefined>('')
    const filterTelephone = ref<string | undefined>('')
    const filterEmail = ref<string | undefined>('')
    const generalFilter = ref<string | undefined>('')
    const groupNameHeader = ref<string>('')
    const isModalConfirmOpen = ref(false)
    const userId = ref('')
    const previousPageSize = ref(20)
    const previousCurrentPage = ref(1)
    const widthTable = ref('70em')

    const openModalConfirm = () => (isModalConfirmOpen.value = true)
    const openModalStudent = () => ((isModalOpenStudent.value = true), (isRegisterStudent.value = true))
    const openFilterSelected = () => (isFilterSelected.value = !isFilterSelected.value)
    const closeModal = () => (isModalOpenStudent.value = false)
    const setNewTab = () => {
      innerWidth.value = window.innerWidth
    }
    window.addEventListener('resize', setNewTab)

    onMounted(() => {
      if (innerWidth.value <= 768) {
        widthModalSmall.value = '90%'
        heightModalSmall.value = '14.7em'
        sizeColumnGroups.value = '1fr /0.4fr 2fr 1.2fr 2fr 0.25fr'
        heightLoading.value = '6.5em'
        heightTable.value = '52vh'
        widthTable.value = '53em'
        sizeLoading.value = '2.1875em'
      }

      listUserGroups()
      fetchNameGroup(Number(groupId))
    })

    const fetchNameGroup = async (groupId: number) => {
      const { data: groupName } = await groupsService.get<ResponseDTO<GroupDTO>>('/single', { params: { id: groupId } })
      groupNameHeader.value = groupName.data.name
    }

    const iconProps = {
      fill: DefaultTheme().colors.pinkColor,
      stroke: DefaultTheme().colors.pinkColor,
      size: 20,
    }

    const optionsMenu = ref([
      { label: 'Remover do Grupo', icon: RemoveSchoolIcon, iconProps, action: () => openModalConfirm() },
      { label: 'Ver Estatísticas', icon: EstatisticsIcon, iconProps, action: () => '', disabled: true },
    ])

    const handleFiltersApplied = (filterValues: User) => {
      filterId.value = String(filterValues.readableId)
      filterName.value = filterValues.name
      filterTelephone.value = filterValues.telephone
      filterEmail.value = filterValues.email

      debouncedGetInstitutionUsers()
    }

    const handleValueUpdated = (value: User) => {
      userId.value = value.id
    }

    const handlePageSizeChange = (selectedPageSize: number) => {
      if (selectedPageSize !== previousPageSize.value) {
        pageSize.value = selectedPageSize
        listUserGroups()
        previousPageSize.value = selectedPageSize
      }
    }

    const handleCurrentPage = (selectedCurrentPage: number) => {
      if (selectedCurrentPage !== previousCurrentPage.value) {
        currentPage.value = selectedCurrentPage
        listUserGroups()
        previousCurrentPage.value = selectedCurrentPage
      }
    }

    const removeUserFromGroup = async () => {
      const body = {
        id: groupId,
        usersId: [userId.value],
      }

      try {
        await groupsService.delete<Promise<Groups>>('/remove-users', { data: body })
        $q.notify({
          textColor: 'grey-1',
          message: 'Usuário removido com sucesso',
          color: 'green',
          position: 'top',
          classes: 'notify',
        })
      } catch (error) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao remover usuário',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      } finally {
        listUserGroups()
      }
    }

    const listUserGroups = async () => {
      const searchByName = generalFilter.value !== '' ? { name: generalFilter.value } : {}
      const searchByAdvancedField = filterName.value !== '' ? { name: filterName.value } : {}

      const requestBody = {
        page: currentPage.value,
        pageSize: pageSize.value,
        filter: {
          group: groupId,
          inGroup: true,
        },
        search: {
          ...searchByName,
          ...searchByAdvancedField,
          readableId: filterId.value !== '' ? Number(filterId.value) : undefined,
          email: filterEmail.value !== '' ? filterEmail.value : undefined,
          telephone: filterTelephone.value !== '' ? filterTelephone.value : undefined,
        },
      }

      isLoading.value = true
      try {
        const response = await usersService.post<ResponseDTO<EntitiesUser>>('/filter-users', requestBody)
        getResponse.value = response.data.data.entities
        rowsUsersGroups.value = getResponse.value.map((row: User) => {
          return {
            id: row.id,
            readableId: row.readableId,
            name: `${row.name} ${row.lastName !== null ? row.lastName : ''}`,
            email: row.email,
            telephone: row.telephone,
          }
        })
        totalElements.value = response.data.data.totalElements
      } catch (error) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao buscar dados',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      } finally {
        isLoading.value = false
      }
    }

    const debouncedGetInstitutionUsers = debounce(listUserGroups, 500)

    watch(generalFilter, () => {
      currentPage.value = 1
      debouncedGetInstitutionUsers()
    })

    watch(isFilterSelected, () => {
      generalFilter.value = ''
      currentPage.value = 1

      if (filterName.value && filterTelephone.value && filterEmail.value) {
        debouncedGetInstitutionUsers()
      }
    })

    provide('emitValueToParent', handleValueUpdated)
    provide('emitNewValue', handleFiltersApplied)
    provide('isFilterSelected', isFilterSelected)
    provide('openFilterSelected', openFilterSelected)
    provide('heightLoading', heightLoading)
    provide('optionsMenu', optionsMenu)
    provide('filters', filtersUserGroup)

    return {
      isSearchInputExpanded,
      optionsPagination,
      innerWidth,
      closeModal,
      isFilterSelected,
      openFilterSelected,
      columnsUserGroup,
      filtersUserGroup,
      sizeColumnGroups,
      openModalStudent,
      widthModalSmall,
      heightModalSmall,
      router,
      isModalOpenStudent,
      isRegisterStudent,
      rowsUsersGroups,
      handlePageSizeChange,
      handleCurrentPage,
      totalElements,
      sizeLoading,
      isLoading,
      generalFilter,
      groupNameHeader,
      removeUserFromGroup,
      isModalConfirmOpen,
      groupId,
      listUserGroups,
      heightTable,
      widthTable,
      AddIcon,
      DefaultTheme,
    }
  },
})
