import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58874a26"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tooltip" }
const _hoisted_2 = { class: "hint-icon-container" }
const _hoisted_3 = { class: "hint-text-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), _mergeProps({
            key: 0,
            class: "hint-icon"
          }, _ctx.iconProps), null, 16))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_q_tooltip, {
      "transition-show": "scale",
      "transition-hide": "scale",
      style: _normalizeStyle(_ctx.tooltipRootStyles)
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("strong", {
            class: _normalizeClass(["hint-text", _ctx.boldClass])
          }, _toDisplayString(_ctx.text), 3)
        ])
      ]),
      _: 1
    }, 8, ["style"])
  ]))
}