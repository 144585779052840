import { SubjectTreeCollapse } from '../../types/SubjectTreeCollapseType'
import { QuestionTagsDTO } from './QuestionTagsDTO'

export type QuestionIdsPerTagType = {
  totalElements: number
  tagHasQuestionIds: Record<string, number>
}

export class DataByTabsDTO {
  filters: QuestionTagsDTO

  subjectTree: SubjectTreeCollapse
  totalSelectedQuestions: number

  constructor(filter: QuestionTagsDTO, subjectTreeCollapse: SubjectTreeCollapse) {
    this.filters = filter
    this.subjectTree = subjectTreeCollapse

    this.totalSelectedQuestions = 0
  }
}
