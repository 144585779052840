import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ff9fa994"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown-container" }
const _hoisted_2 = {
  key: 0,
  class: "dropdown-options"
}
const _hoisted_3 = ["value", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "selector",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleShowOptions()))
    }, [
      _createElementVNode("p", null, _toDisplayString(_ctx.placeholder), 1),
      _createVNode(_component_ChevronDownIcon, {
        stroke: _ctx.DefaultTheme().colors.secondaryGray
      }, null, 8, ["stroke"])
    ]),
    (_ctx.showOptions)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass({ selected: option.selected ?? true }),
              key: index,
              value: option.id,
              onClick: ($event: any) => (_ctx.handleSelectedOption(option))
            }, _toDisplayString(option.description), 11, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}