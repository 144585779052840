import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M23.663 21.9767L19.2137 17.56C20.9408 15.4049 21.7771 12.6695 21.5508 9.91628C21.3245 7.16303 20.0528 4.60118 17.997 2.75751C15.9412 0.913839 13.2578 -0.0715191 10.4983 0.00404582C7.73893 0.0796108 5.11331 1.21035 3.16137 3.16377C1.20944 5.11719 0.0795504 7.74481 0.00404275 10.5063C-0.0714649 13.2678 0.913145 15.9534 2.75542 18.0107C4.59769 20.068 7.15759 21.3407 9.90875 21.5672C12.6599 21.7937 15.3932 20.9567 17.5466 19.2283L21.96 23.645C22.0715 23.7575 22.2042 23.8468 22.3503 23.9077C22.4964 23.9686 22.6532 24 22.8115 24C22.9698 24 23.1266 23.9686 23.2727 23.9077C23.4189 23.8468 23.5515 23.7575 23.663 23.645C23.8792 23.4212 24 23.1221 24 22.8108C24 22.4996 23.8792 22.2005 23.663 21.9767ZM10.8186 19.2283C9.15827 19.2283 7.53518 18.7355 6.15463 17.8124C4.77408 16.8892 3.69807 15.5771 3.06267 14.0419C2.42727 12.5068 2.26102 10.8175 2.58495 9.18784C2.90887 7.55813 3.70842 6.06115 4.88248 4.88619C6.05654 3.71124 7.55239 2.91108 9.18086 2.58691C10.8093 2.26274 12.4973 2.42912 14.0313 3.065C15.5653 3.70088 16.8764 4.77771 17.7988 6.15931C18.7213 7.54091 19.2137 9.16523 19.2137 10.8269C19.2137 13.0551 18.3292 15.192 16.7548 16.7675C15.1804 18.3431 13.0451 19.2283 10.8186 19.2283Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}