export class ListSummaryOptions {
  subject = 'Sem nome'
  amountQuestions = 0
  filter = new Filter()
}

class Filter {
  years?: string[] = []
  levels?: string[] = []
  types?: string[] = []
  source?: string[] = []
}
