
import { defineComponent, inject, PropType } from 'vue';

export default defineComponent({
  name: 'QuestionNumberLabel',
  emits: ['questionClick'],
  props: {
    isDoubt: { type: Boolean, default: false },
    questionId: { type: Number, required: true },
    questionLabel: { type: [Number, String], required: true },
    questionNumber: { type: Number, required: true },
    state: { type: String as PropType<'answered' | 'doubt' | 'unanswered' | 'correct' | 'wrong'>, required: true, default: 'answered' },
  },
  setup() {
    const handleQuestionClick = inject<(questionLabel: number, questionId: number, questionOrder: number) => void>('onQuestionClick')

    return { handleQuestionClick }
  },
})
