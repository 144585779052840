
import DefaultTheme from '@/theme/defaultTheme';
import { defineComponent, onMounted, PropType, ref, watch } from 'vue';

const __default__ = defineComponent({
  name: 'InputComponent',
  emits: ['blur', 'update:modelValue'],
  props: {
    minDate: { type: String, default: '' },
    fullWidthInput: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    hideBottomSpace: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    maxLength: { type: Number, default: -1 },
    autocomplete: { type: String as PropType<'off' | 'on'>, default: 'off' },
    bgColor: { type: String, default: DefaultTheme().colors.whiteColor },
    errorMessage: { type: String, default: '' },
    for: { type: String, default: '' },
    height: { type: String, default: '42px' },
    label: { type: String, default: '' },
    mask: { type: String, default: '' },
    modelModifiers: {
      type: Object,
      default: () => ({
        'no-special-chars': false,
        'only-alphabetic': false,
        'only-numbers': false,
        'unmasked-value': false,
      }),
    },
    maxNumber: { type: Boolean, default: false },
    minNumber: { type: Boolean, default: false },
    formattedCode: { type: Boolean, default: false },
    modelValue: { type: String, default: '', required: true },
    placeholder: { type: String, default: '' },
    type: { type: String as PropType<'text' | 'number' | 'date' | 'time'>, default: 'text' },
    variant: { type: String as PropType<'rounded' | 'semi-rounded'>, default: 'rounded' },
    width: { type: String, default: '160px' },
    liteStyle: { type: Boolean, default: false },
    formatCurrency: { type: Boolean, default: false },
    isAbsolute: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const forLabel = ref(props.for)
    const inputRef = ref<HTMLInputElement | null>(null)
    const inputValue = ref('')
    const classNames = ref({ 'input-focused': false })
    const changeFontColor = props.bgColor === DefaultTheme().colors.primaryColor ? 'font-purple' : ''

    const initInput = () => {
      if (props.modelValue) inputValue.value = props.modelValue
    }

    const handleBlur = () => {
      handleInputFocus(false)
      emit('blur')
    }

    const handleInputFocus = (state: boolean) => (classNames.value = { 'input-focused': state })

    const handleMask = (currentValue: string, prevValue: string, mask: string) => {
      if (!isNaN(Number(currentValue[currentValue.length - 1])) || currentValue[currentValue.length - 1] === mask[currentValue.length - 1]) {
        if (currentValue === mask[0]) inputValue.value = inputValue.value.slice(0, inputValue.value.length - 1)
        else if (mask[0] !== '#' && inputValue.value.length === 1) inputValue.value = mask[0] + currentValue

        if (mask[inputValue.value.length] !== '#' && inputValue.value.length < mask.length) {
          if (prevValue.length < currentValue.length) inputValue.value = inputValue.value + mask[inputValue.value.length]
          else inputValue.value = inputValue.value.slice(0, inputValue.value.length - 1)
        }
      } else inputValue.value = prevValue
    }

    const watchModelChange = (currentValue: string, prevValue: string) => {
      let updateModel = inputValue.value

      if (props.mask) handleMask(currentValue, prevValue, props.mask)

      if (props.modelModifiers['only-numbers'] || (props.modelModifiers['unmasked-value'] && props.mask)) updateModel = inputValue.value.replace(/[^0-9]/g, '')
      else if (props.modelModifiers['only-alphabetic']) updateModel = inputValue.value.replace(/[^a-zA-Z ]/g, '')
      else if (props.modelModifiers['no-special-chars']) updateModel = inputValue.value.replace(/[^a-zA-Z0-9]/g, '')

      if (!props.mask) inputValue.value = updateModel
      if (props.formatCurrency) {
        updateModel = replaceToCurrencyMask(inputValue.value)
        inputValue.value = updateModel
      }

      if (props.maxNumber) {
        updateModel = Number(inputValue.value) > 100 ? '100' : inputValue.value
        inputValue.value = updateModel
      }

      if (props.formattedCode) {
        updateModel = inputValue.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
        inputValue.value = updateModel
      }

      if (props.minNumber) {
        updateModel = Number(inputValue.value) < 1 || String(inputValue.value) === '0,00' ? '' : inputValue.value
        inputValue.value = updateModel
      }

      emit('update:modelValue', updateModel)
    }

    const handleAsyncUpdateModel = (currentValue: string) => {
      let i = 0
      inputValue.value = props.mask.replace(/#/g, () => currentValue[i++])
    }

    const replaceToCurrencyMask = (value: string) => {
      let sanitizedValue = value.replace(/\D/g, '').replace(/^0+/, '')

      if (sanitizedValue.length === 0) {
        sanitizedValue = '0'
      }

      if (sanitizedValue.length === 1) {
        sanitizedValue = '0,0' + sanitizedValue
      } else if (sanitizedValue.length === 2) {
        sanitizedValue = '0,' + sanitizedValue
      } else {
        sanitizedValue = sanitizedValue.slice(0, -2) + ',' + sanitizedValue.slice(-2)
      }

      return sanitizedValue
    }

    onMounted(initInput)

    watch(inputValue, (currentValue, prevValue) => watchModelChange(currentValue, prevValue))
    watch(
      () => props.modelValue,
      (currentValue, prevValue) => {
        if (prevValue === '' && currentValue !== '' && props.mask) handleAsyncUpdateModel(currentValue)
        if (!props.mask) inputValue.value = props.modelValue

        if (!props.modelValue && props.for === 'cep') inputValue.value = props.modelValue
      }
    )

    return { forLabel, inputValue, inputRef, classNames, changeFontColor, handleInputFocus, handleBlur }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "12f9b3d6": (_ctx.bgColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__