import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5cbf8c4f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stepper-container" }
const _hoisted_2 = { class: "stepper-item" }
const _hoisted_3 = { class: "stepper-item" }
const _hoisted_4 = { class: "stepper-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["stepper-item--circle", { 'stepper-item--circle--active': _ctx.currentView === 'StateComponent', 'stepper-item--circle--done': _ctx.currentView === 'InstitutionComponent' || _ctx.currentView === 'YearComponent' }])
      }, [
        _createElementVNode("span", {
          class: _normalizeClass({ 'stepper-item--circle-content--active': _ctx.currentView === 'StateComponent' })
        }, "1", 2)
      ], 2),
      _createElementVNode("span", {
        class: _normalizeClass(["stepper-item--label", { 'stepper-item--label--active': _ctx.currentView === 'StateComponent', 'stepper-item--label--done': _ctx.currentView === 'InstitutionComponent' || 'YearComponent' }])
      }, "Selecione o Estado", 2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass(["stepper-item--circle", { 'stepper-item--circle--active': _ctx.currentView === 'InstitutionComponent', 'stepper-item--circle--done': _ctx.currentView === 'YearComponent' }])
      }, [
        _createElementVNode("span", {
          class: _normalizeClass({ 'stepper-item--circle-content--active': _ctx.currentView === 'InstitutionComponent' })
        }, "2", 2)
      ], 2),
      _createElementVNode("span", {
        class: _normalizeClass(["stepper-item--label", { 'stepper-item--label--active': _ctx.currentView === 'InstitutionComponent', 'stepper-item--label--done': _ctx.currentView === 'YearComponent' }])
      }, "Selecione a Instituição", 2)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: _normalizeClass(["stepper-item--circle", { 'stepper-item--circle--active': _ctx.currentView === 'YearComponent' }])
      }, [
        _createElementVNode("span", {
          class: _normalizeClass({ 'stepper-item--circle-content--active': _ctx.currentView === 'YearComponent' })
        }, "3", 2)
      ], 2),
      _createElementVNode("span", {
        class: _normalizeClass(["stepper-item--label", { 'stepper-item--label--active': _ctx.currentView === 'YearComponent' }])
      }, "Selecione o Ano", 2)
    ])
  ]))
}