
import PazzeiButton from '@/components/Generics/Redesign/PazzeiButton.vue';
import PazzeiModal from '@/components/Generics/Redesign/PazzeiModal/PazzeiModal.vue';
import AlertIcon from '@/components/Icons/default/redesign/sharedList/AlertIcon.vue';
import { ModalInfoDTO } from '@/dtos/modal/ModalInfoDTO';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'PazzeiModalAlert',
  emits: ['close', 'on-confirm'],
  props: {
    name: { type: String, default: '', required: true },
    icon: { type: [Object, Function, String], default: AlertIcon, required: false },
    iconProps: { fill: String, stroke: String, size: Number, required: false},
    variant: { type: String, default: 'fit', validator: (value: string) => ['fit', 'default'].includes(value) },
    confirmButtonText: { type: String, default: 'Ok' },
    confirmButtonVariant: { type: String, default: 'secondary' },
    heading: { type: String, default: '', required: false },
    description: { type: String, default: '', required: false },
  },
  components: {
    PazzeiModal,
    PazzeiButton,
  },
  setup(props, {emit}) {
    const context: ModalInfoDTO = {
      name: props.name,
      icon: props.icon,
      iconProps: props.iconProps,
      variant: props.variant as 'fit' | 'default',
      isBorderless: true,
      isLoaderModal: false,
    }

    const showModal = ref(true);
    const showLoader = ref(false);

    const handleModalClose = () => {
      showModal.value = false;
    }

    const handleConfirm = () => {
      emit('on-confirm');
    }

    const handleShowLoader = () => {
      showLoader.value = true;
      context.icon = '';
      context.isLoaderModal = true;
    }

    return {
      context,
      showModal,
      handleModalClose,
      handleConfirm,
      showLoader,
      handleShowLoader,
    }
  }
})
