
import DefaultTheme from '@/theme/defaultTheme'
import useVuelidate from '@vuelidate/core'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { CreateNewAddressDTO } from '../../dtos/CreateNewAddressDTO'
import getStates, { ApiDataStates, getDistricts } from '../../services/apis/ibgeStates'
import { AddressTypeReturn, getAddressByCep } from '../../services/apis/viaCep'
import authService from '../../services/authService'
import paymentsService from '../../services/payment'
import addressesRules from '../../utils/vuelidateRules/addressesRules'
import PazzeiSimpleSelect from '../Generics/PazzeiSimpleSelect.vue'
import CheckIcon from '../Icons/default/CheckIcon.vue'
import LayoutEditing from '../Layouts/LayoutEditing.vue'
import Button from '../Library/Button/index.vue'
import Input from '../Library/Input/index.vue'
import ModalChangeFormPayment from './ModalChangeFormPayment.vue'

export default defineComponent({
  name: 'MyPlans',
  components: { LayoutEditing, Input, Button, PazzeiSimpleSelect, ModalChangeFormPayment, CheckIcon },
  setup() {
    const update = ref(1)
    const $q = useQuasar()
    const cardNumber = ref('')
    const formData = ref({
      zipcode: '',
      state: '',
      city: '',
      street: '',
      houseNumber: '',
      neighborhood: '',
    })
    const formDataTemp = ref({
      street: '',
      houseNumber: '',
      zipcode: '',
      city: '',
      state: '',
      neighborhood: '',
    })
    const flags = ref({
      changeForm: true,
      modalChangedPayment: false,
    })
    const stateOptions = ref<Array<string>>([])
    const statesInfos = ref<Array<ApiDataStates>>([])
    const userLocationState = ref<{ state: Array<string> }>({
      state: [],
    })
    const citiesOptions = ref<Array<string>>([''])
    const userInfosAux = ref<{ city: Array<string> }>({
      city: [],
    })

    const zipcode = ref('')
    const viaCep = ref<AddressTypeReturn>({
      cep: '',
      logradouro: '',
      complemento: '',
      bairro: '',
      localidade: '',
      uf: '',
      ibge: '',
      gia: '',
      ddd: '',
      siafi: '',
    })

    const plans = ref({
      totalPrice: 0,
      plan: '',
    })

    const rules = computed(() => addressesRules)

    const v$ = useVuelidate(rules, formData)

    const showMyPlans = ref(false)
    const associatedInstitution = ref(true)

    onMounted(async () => {
      const allPermissions = authService.getPermissions()
      if (allPermissions?.roleCode && 
        ['pazzei:student:essential', 'pazzei:no-credits', 'pazzei:trial'].some(role => allPermissions.roleCode.includes(role))) {
        associatedInstitution.value = false; 
      }
      if (allPermissions?.permissions.includes('self:payments:plans:manage')) showMyPlans.value = true

      if (showMyPlans.value) {
        const { data, statesUf } = await getStates()
        stateOptions.value = statesUf
        statesInfos.value = data
        zipcode.value = formData.value.zipcode

        paymentsService.get('/credit-cards').then((res) => {
          cardNumber.value = res.data.data.cardNumber
        })

        paymentsService.get('/billing-addresses').then((res) => {
          formData.value = res.data.data
          formData.value.houseNumber = res.data.data.number
          formDataTemp.value = { ...res.data.data }
          userLocationState.value.state.push(res.data.data.state)
          userInfosAux.value.city.push(res.data.data.city)
        })

        paymentsService.get('/plans').then((res) => {
          plans.value.totalPrice = res.data.data.fullPrice
          if (plans.value.totalPrice === 29.9) plans.value.plan = 'mensal'
          else plans.value.plan = 'semestral'
        })
      }
    })
    const notification = (success: boolean, message: string) => {
      if (success) {
        $q.notify({
          textColor: 'grey-1',
          message: message,
          color: 'green',
          position: 'top',
          classes: 'notify',
        })
      } else {
        $q.notify({
          textColor: 'grey-1',
          message: message,
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    const handleChangeAdrresses = async () => {
      formData.value.state = userLocationState.value.state[0]
      formData.value.city = userInfosAux.value.city[0]

      const result1 = await v$.value.zipcode.$validate()
      const result2 = await v$.value.state.$validate()
      const result3 = await v$.value.city.$validate()
      const result4 = await v$.value.street.$validate()
      const result5 = await v$.value.houseNumber.$validate()
      const result6 = await v$.value.neighborhood.$validate()

      if (result1 && result2 && result3 && result4 && result5 && result6) {
        const newAddress = new CreateNewAddressDTO(
          formData.value.street,
          formData.value.houseNumber,
          formData.value.zipcode.replaceAll('-', ''),
          formData.value.city,
          formData.value.state,
          'BR',
          formData.value.neighborhood
        )
        paymentsService
          .put('/billing-addresses', newAddress)
          .then(() => {
            notification(true, 'Dados salvos com sucesso')
            flags.value.changeForm = true
          })
          .catch(() => {
            notification(false, 'Algo deu errado')
            formData.value = formDataTemp.value
            flags.value.changeForm = true
          })
      } else notification(false, 'Existem campos em branco')
    }

    const cancelChanges = () => {
      formData.value = formDataTemp.value
      userInfosAux.value.city = [formDataTemp.value.city]
      userLocationState.value.state = [formDataTemp.value.state]
      update.value++
      flags.value.changeForm = true
    }

    watch(
      userLocationState,
      async () => {
        const uf = statesInfos.value.filter((item) => item.sigla === userLocationState.value.state[0])
        formData.value.state = uf[0].sigla
        citiesOptions.value = await getDistricts(uf[0].id.toString())
        update.value++

        if (uf.map((it) => it.sigla)[0] === formDataTemp.value.state) return
        else userInfosAux.value.city = ['']
      },
      { deep: true }
    )

    watch(zipcode, async () => {
      if (formData.value.zipcode.length >= 9) {
        viaCep.value = await getAddressByCep(formData.value.zipcode.replace('-', ''))

        formData.value.street = viaCep.value.logradouro
        userInfosAux.value.city = [viaCep.value.localidade]
        userLocationState.value.state = [viaCep.value.uf]

        update.value++
      }
    })

    return { formData, flags, cardNumber, stateOptions, userLocationState, update, citiesOptions, userInfosAux, handleChangeAdrresses, cancelChanges, plans, v$, associatedInstitution, showMyPlans, DefaultTheme }
  },
})
