
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import { defineComponent, inject, Ref, ref } from 'vue'

export default defineComponent({
  name: 'FormThree',
  components: {
    PazzeiBtn,
  },
  setup() {
    const selection = ref([])
    const formData = inject<Ref<Array<{ key: string; value: string }>>>('formData')
    const start = inject<() => { key: string; value: string }>('start')
    const isLoading = inject<Ref<boolean>>('isLoading')

    const handleStart = () => {
      selection.value.map((item) => {
        formData!.value.push({ key: 'studied_at', value: item })
      })

      start!()
    }

    return { selection, handleStart, isLoading }
  },
})
