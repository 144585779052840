
import TablesComponentHistory from '@/components/Application/planSchoolPortal/mountTableComponent/TablesComponent.vue'
import PaginationTableComponent from '@/components/Application/planSchoolPortal/mountTableComponent/components/PaginationTableComponent.vue'
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import ArrowBackIcon from '@/components/Icons/default/ArrowBackIcon.vue'
import ExportcsvIcon from '@/components/Icons/default/ExportcsvIcon.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import listsService from '@/services/list'
import DefaultTheme from '@/theme/defaultTheme'
import { EntitiesSharingHistory, SharingHistory } from '@/types/SharingHistoryType'
import { columnsSharingHistory } from '@/utils/hardCoded/planSchool/listExercices/exercices'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'ViewPurchaseHistory',
  components: { GenericLayout, MainLayoutWrapper, PazzeiBtn, ArrowBackIcon, TablesComponentHistory, PaginationTableComponent },
  setup() {
    const isSearchInputExpanded = ref(true)
    const sizeColumnHistoric = ref('1fr / 6fr 2fr 1.7fr 0.1fr')
    const router = useRouter()
    const route = useRoute()
    const $q = useQuasar()
    const previousPageSize = ref(20)
    const previousCurrentPage = ref(1)
    const currentPage = ref(1)
    const listId = String(route.params.id)
    const pageSize = ref(20)
    const totalElements = ref(0)
    const isLoading = ref(false)
    const sizeLoading = ref('60px')
    const heightTable = ref('47.5em')
    const widthTable = ref('70em')
    const listName = ref('')
    const isTest = ref(false)
    const innerWidth = ref(window.innerWidth)
    const rows = ref<SharingHistory[]>([])
    const formatedRows = ref<{ name: string; createdAt: string; expiresAt: string }[]>([])
    const setNewTab = () => {
      innerWidth.value = window.innerWidth
    }
    window.addEventListener('resize', setNewTab)

    const handlePageSizeChange = (selectedPageSize: number) => {
      if (selectedPageSize !== previousPageSize.value) {
        pageSize.value = selectedPageSize
        getSharingHistory()
        previousPageSize.value = selectedPageSize
      }
    }

    const handleCurrentPage = (selectedCurrentPage: number) => {
      if (selectedCurrentPage !== previousCurrentPage.value) {
        currentPage.value = selectedCurrentPage
        getSharingHistory()
        previousCurrentPage.value = selectedCurrentPage
      }
    }

    const getSharingHistory = async () => {
      const data = {
        listId: Number(listId),
        page: currentPage.value,
        pageSize: pageSize.value,
      }

      isLoading.value = true
      try {
        const { data: infos } = await listsService.post<ResponseDTO<EntitiesSharingHistory>>('/shared-history', data)
        rows.value = infos.data.data
        formatedRows.value = rows.value.map((it: SharingHistory) => {
          listName.value = it.list.name
          isTest.value = it.list.isTest
          return {
            name: `${it.user.name} ${it.user.lastName ? it.user.lastName : ''}`,
            createdAt: it.createdAt,
            expiresAt: it.expiresAt,
          }
        })
      } catch (error) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao buscar dados',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
        console.error(error)
      } finally {
        isLoading.value = false
      }
    }

    const downloadCsvFile = async () => {
      try {
        const documentBodyRef = ref<HTMLElement | undefined>(document?.body);

        if (documentBodyRef.value) {
          const response = await listsService.post<ResponseDTO<any>>('/csv', { listId: Number(listId) })
          const fileData = decodeURIComponent(escape(window.atob(response.data.data)))
          const blob = new Blob([fileData], { type: 'text/csv;charset=utf-8;' })
          const link = document.createElement('a');
          const url = URL.createObjectURL(blob);

          link.setAttribute('href', url)
          link.setAttribute('download', 'Histórico-de-compartilhamento.csv')
          link.style.visibility = 'hidden'
          documentBodyRef.value?.appendChild?.(link);
          link.click()
          documentBodyRef.value?.removeChild?.(link);
        }
      } catch (error) {
        console.error(error)
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao exportar CSV',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    onMounted(() => {
      if (innerWidth.value <= 768) {
        heightTable.value = '52vh'
        widthTable.value = '50em'
        sizeColumnHistoric.value = '1fr / 2.5fr 1.4fr 1.4fr 0.01fr'
        sizeLoading.value = '2.1875em'
      }

      getSharingHistory()
    })

    return {
      isSearchInputExpanded,
      innerWidth,
      sizeColumnHistoric,
      router,
      handlePageSizeChange,
      handleCurrentPage,
      totalElements,
      columnsSharingHistory,
      isLoading,
      sizeLoading,
      heightTable,
      listName,
      formatedRows,
      isTest,
      widthTable,
      downloadCsvFile,
      ExportcsvIcon,
      DefaultTheme,
    }
  },
})
