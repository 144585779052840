export class UpdateUserPreRegisteredDTO {
  password: string
  lastName?: string
  telephone?: string
  cpf?: string

  constructor(password: string, lastName?: string, telephone?: string, cpf?: string) {
    this.password = password
    this.telephone = telephone !== '' ? telephone : undefined
    this.lastName = lastName !== '' ? lastName : undefined
    this.cpf = cpf !== '' ? cpf : undefined
  }
}
