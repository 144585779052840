import { AxiosError } from 'axios'
import { ResponseDTO } from './ResponseDTOs/ResponseDTO'

export class HandleErrorAxiosResponseDTO {
  error: { httpStatus: number; developerMessage: string; timestamp: string; userMessage: string }

  constructor(error: any) {
    let messagesErrors = {}
    if (error.isAxiosError || error instanceof AxiosError) {
      const axiosError = error as AxiosError<ResponseDTO<any>, any>

      if (axiosError.response?.data && 'message' in axiosError.response?.data) {
        const aux = Object.entries(axiosError.response.data.message)
          .map((item) => {
            return { [item[0]]: item[1] }
          })
          .reduce((acc, item) => {
            return { ...acc, ...item }
          }, {})

        messagesErrors = { ...aux }
      }

      delete error.response?.data?.message

      if (!error.response.data.userMessage && error.response.data.developerMessage.includes('because')) error.response.data.userMessage = error.response.data.developerMessage.split(':')[1]
      if (error.response.data.userMessage.includes('because')) error.response.data.userMessage = error.response.data.userMessage.split(':')[1]

      this.error = { httpStatus: 'HttpStatus' in error.response.data ? error.response.data.HttpStatus : error.response.data.httpStatus, ...error.response.data, ...messagesErrors }
    } else this.error = { developerMessage: '', httpStatus: 500, timestamp: new Date().toISOString(), userMessage: `Ops! Algo deu errado, tente novamente` }
  }
}
