export class RegistrationDTO {
  name: string
  lastName: string
  telephone: string
  cpf: string
  email: string
  password: string
  code?: string

  constructor(name: string, lastName: string, telephone: string, cpf: string, email: string, password: string, code?: string) {
    this.name = name
    this.lastName = lastName
    this.telephone = telephone
    this.email = email
    this.password = password
    this.code = code
    this.cpf = cpf
  }
}
