
import FormOne from '@/components/UserInfosForms/FormOne.vue'
import FormThree from '@/components/UserInfosForms/FormThree.vue'
import FormTwo from '@/components/UserInfosForms/FormTwo.vue'
import usersService from '@/services/users'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, provide, ref } from 'vue'
import { HandleErrorAxiosResponseDTO } from '../../dtos/HandleErrorAxiosResponseDTO'
import { ErrorAxiosResponseType } from '../../types/ErrorAxiosResponseType'

export default defineComponent({
  name: 'ModalUserInfo',
  components: {
    FormOne,
    FormTwo,
    FormThree,
  },
  setup() {
    const $q = useQuasar()
    const page = ref('page-1')
    const formData = ref<Array<{ key: string; value: string }>>([])
    const success = ref(false)
    const isLoading = ref(false)

    provide('formData', formData)
    provide('isLoading', isLoading)

    const handleChangeForm = (formPage: string) => (page.value = formPage)

    const start = () => {
      isLoading.value = true
      const tags = Object.values(formData.value).map((it) => {
        return { key: it['key'], value: it['value'] }
      })

      if (!tags.length) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Preencha ao menos um campo',
          color: 'yellow-8',
          timeout: 2000,
          position: 'top',
          classes: 'notify',
        })

        setTimeout(() => {
          isLoading.value = false
        }, 2000)
      } else
        usersService
          .post('/users-tags', { tags })
          .then(() => {
            $q.notify({
              textColor: 'grey-1',
              message: 'Dados salvos com sucesso',
              color: 'green',
              timeout: 2000,
              position: 'top',
              classes: 'notify',
            })

            setTimeout(() => {
              window.location.href = '/'
            }, 2000)
          })
          .catch((err: ErrorAxiosResponseType) => {
            const { error } = new HandleErrorAxiosResponseDTO(err)

            $q.notify({
              textColor: 'grey-1',
              message: error.userMessage,
              color: 'red',
              timeout: 2000,
              position: 'top',
              classes: 'notify',
            })
          })
    }
    provide('start', start)

    return { page, handleChangeForm, start, success }
  },
})
