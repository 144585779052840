
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiSelectNew from '@/components/Generics/PazzeiSelectNew.vue'
import usersService from '@/services/users'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, inject, onMounted, onUnmounted, Ref, ref, watch } from 'vue'

export default defineComponent({
  name: 'FormTwo',
  components: {
    PazzeiBtn,
    PazzeiSelectNew,
  },
  setup() {
    const $q = useQuasar()
    const controller = new AbortController()
    const subjects = ref<{ favorite: Array<string>; improve: Array<string> }>({
      favorite: [],
      improve: [],
    })
    const formData = inject<Ref<Array<{ key: string; value: string }>>>('formData')
    const subjectOptions = ref([])

    onMounted(() => {
      usersService
        .get('/users-tags/favorite-subject', { signal: controller.signal })
        .then((res) => {
          subjectOptions.value = res.data.data
        })
        .catch(() => {
          $q.notify({
            textColor: 'grey-1',
            message: 'Erro ao obter tags de usuário',
            color: 'red',
            timeout: 2000,
            position: 'top',
            classes: 'notify',
          })
        })
    })

    onUnmounted(() => controller.abort())

    const handleItems = (items: Array<string>, key: string) => {
      if (formData) {
        items.forEach((value) => {
          if (!formData.value.find((it) => it.value === value)) formData.value.push({ key, value })
        })

        formData.value = formData.value.filter((item) => {
          if (item.key === key) return items.includes(item.value)
          else return true
        })
      }
    }

    watch(
      subjects,
      () => {
        handleItems(subjects.value.favorite, 'favorite_subject')
        handleItems(subjects.value.improve, 'target_subject')
      },
      { deep: true }
    )

    return {
      subjects,
      subjectOptions,
    }
  },
})
