
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import paymentsService from '@/services/payment'
import { UserHistoric } from '@/types/UsersHistoricTypes'
import { formatCurrency } from '@/utils/common/formatCurrency'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
  props: {
    id: {
      type: Number,
      required: true,
      default: -1,
    },
    sizeEditLoading: {
      type: String,
      default: '45px',
    },
  },
  name: 'ContentModalBillingInformation',
  setup(props) {
    const infos = ref<UserHistoric>({} as UserHistoric)
    const isFetchData = ref(false)
    const $q = useQuasar()

    const getOneBilling = async () => {
      try {
        isFetchData.value = true
        const response = await paymentsService.get<ResponseDTO<UserHistoric>>('/', { params: { id: props.id } })

        if (response.data.data) {
          infos.value = response.data.data
        }
      } catch (error) {
        console.error(error)
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao buscar dados',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      } finally {
        isFetchData.value = false
      }
    }

    onMounted(() => {
      getOneBilling()
    })

    return {
      infos,
      formatCurrency,
      isFetchData,
    }
  },
})
