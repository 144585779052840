
import ListSummaryModal from '@/components/Application/ListSummaryModal/index.vue'
import ModalCreateList from '@/components/CreateList/ModalCreateList.vue'
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiChip from '@/components/Generics/PazzeiChip.vue'
import PazzeiInput from '@/components/Generics/PazzeiInput.vue'
import PazzeiSelectNew from '@/components/Generics/PazzeiSelectNew.vue'
import PazzeiSimple from '@/components/Generics/PazzeiSimpleSelect.vue'
import PazzeiSubjectTabs from '@/components/Generics/PazzeiSubjectTabs.vue'
import RemoveSubjectIcon from '@/components/Icons/default/RemoveSubjectIcon.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutSubWrapper from '@/components/Layouts/MainLayoutSubWrapper.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import ModalConfirmation from '@/components/Library/ModalConfirmation/index.vue'
import { DataByTabsDTO } from '@/dtos/CreateListPage/DataByTabsDTO'
import { QuestionTagsDTO } from '@/dtos/CreateListPage/QuestionTagsDTO'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import authService from '@/services/authService'
import questionsService from '@/services/question'
import DefaultTheme from '@/theme/defaultTheme'
import { colors } from '@/utils/colorPallete/colors'
import setBarGraphicInfos from '@/utils/graphics/bar'
import createListRules from '@/utils/vuelidateRules/createListRules'
import useVuelidate from '@vuelidate/core'
import { ApexOptions } from 'apexcharts'
import { AxiosError } from 'axios'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { computed, defineComponent, onMounted, provide, ref, toRaw, watch } from 'vue'
import ChartCollapse from '../components/Application/chartCollapse/ChartCollapse.vue'
import { balanceQuestionsAcrossSubjects, distributeQuestionsToChildren } from '../components/Application/enemModulePage/WeightingFunctions'
import QuestionControlInput from '../components/Application/questionControlInput/QuestionControlInput.vue'
import TabCreateList from '../components/Application/tabCreateList/TabCreateList.vue'
import Collapse from '../components/Library/Collapse/index.vue'
import TabPanel from '../components/Library/Tabs/TabPanel/index.vue'
import TabPanels from '../components/Library/Tabs/TabPanels/index.vue'
import { CreateListDTO } from '../dtos/CreateListPage/CreateListDTO'
import { HandleErrorAxiosResponseDTO } from '../dtos/HandleErrorAxiosResponseDTO'
import listsService from '../services/list'
import { FilterQuestionDivisionsResponseType } from '../types/FilterQuestionDivisionsResponseType'
import { SubjectTreeCollapse, SubjectTreeCollapseType } from '../types/SubjectTreeCollapseType'
import { SummaryTreeSubjectsType, SummaryType } from '../types/SummaryType'
import { Tree } from '../utils/hardCoded/enemModule/weightedQuestionTree/types'

export default defineComponent({
  name: 'FilterPage',
  components: {
    GenericLayout,
    ChartCollapse,
    MainLayoutSubWrapper,
    MainLayoutWrapper,
    PazzeiInput,
    TabPanel,
    TabPanels,
    Collapse,
    PazzeiSubjectTabs,
    QuestionControlInput,
    TabCreateList,
    PazzeiSelectNew,
    ListSummaryModal,
    PazzeiSimple,
    PazzeiBtn,
    PazzeiChip,
    ModalCreateList,
    ModalConfirmation,
  },
  setup() {
    const $q = useQuasar()
    const validation = ref<{ listName: string | undefined; color: string | undefined }>({
      listName: '',
      color: '',
    })

    const currentTabTypeSelectionQuestions = ref('Por total de questões')
    const v$ = useVuelidate(createListRules, validation)

    const colorsNames = colors.map((color) => color.name)

    const availableTagsToSelect = ref<QuestionTagsDTO>(new QuestionTagsDTO())
    const tagsRetrievedBack = ref<Array<string>>([])
    const selectedTagsOnCurrentTab = ref<QuestionTagsDTO>(new QuestionTagsDTO())
    const listSummaryModalIsOpen = ref(false)
    const listSummaryInfos = ref<{ listName: string; color: string; summary: Record<string, { filters: QuestionTagsDTO; total: number }> }>({
      listName: '',
      color: '',
      summary: {},
    })

    const filterCopy = ref<QuestionTagsDTO>(new QuestionTagsDTO())
    const inputNumberDesiredRef = ref<HTMLInputElement | null>()
    const selectedColorName = ref(Array<string>('Rosa'))
    const currentTab = ref('')
    const totalNumberDesiredQuestions = ref('0')
    const enterNumberQuestions = ref(false)
    const selectedSubjects = ref<Array<string>>([])
    const isSearchingQuestions = ref(false)
    const isModalOpen = ref(true)
    const isCreatingList = ref(false)
    const forceComponentsReload = ref(0)
    const totalElements = ref(0)
    const graphicBar = ref<ApexOptions>()
    const divChartHeight = ref('120')
    const divChartVisibility = ref(false)
    const dataListToSend = ref<CreateListDTO>()
    const previousValue = ref(0)
    const requestQueueNumber = ref(0)
    const isModalConfirmOpen = ref(false)
    const confirmFunction = ref<(() => void) | undefined>()
    const confirmLabel = ref('')
    const openModalConfirm = () => {
      confirmFunction.value = removeSubject
      confirmLabel.value = 'Tem certeza que deseja remover esta matéria?'

      isModalConfirmOpen.value = true
    }

    const userPermissionGroupOrRoot = ref(false)
    const permissionsToCheck = ['group:users:users:manage', 'root:users:users:manage']
    const permissions = authService.getPermissions()?.permissions
    userPermissionGroupOrRoot.value = permissionsToCheck.some((permission) => permissions?.includes(permission))

    const subjectHasQuestionIdsPerTag = ref<Record<string, DataByTabsDTO>>({})

    const totalSelectedQuestionsOnCurrentTab = computed(() => {
      if (!currentTab.value || !subjectHasQuestionIdsPerTag.value[currentTab.value]) return 0
      return subjectHasQuestionIdsPerTag.value[currentTab.value].subjectTree.children.reduce((acc, division) => {
        return acc + division.totalDesiredQuestions
      }, 0)
    })

    const listName = ref<string>('')
    const shouldGiveInstantFeedback = ref(false)
    const showFeedbackOnCompletion = ref(false)
    const notIncludeAlreadyAnswered = ref(false)
    const selectedGroups = ref<Array<{ id: string; name: string }>>([])
    const expirationDate = ref<string>('')
    const setSelectedDateTime = (newDate: string) => {
      expirationDate.value = newDate
    }

    const setSelectedGroups = (groups: Array<{ id: string; name: string }>) => (selectedGroups.value = groups)
    const selectedStudents = ref<Array<{ id: string; name: string }>>([])
    const setSelectedStudents = (students: Array<{ id: string; name: string }>) => (selectedStudents.value = students)
    const handleCleanFilters = () => {
      isModalConfirmOpen.value = true

      confirmFunction.value = handleFilterCleanAll
      confirmLabel.value = 'Tem certeza que deseja limpar todos os filtros?'
    }

    provide('tags', availableTagsToSelect)
    provide('listName', listName)
    provide('color', selectedColorName)
    provide('instantFeedback', shouldGiveInstantFeedback)
    provide('showFeedbackOnCompletion', showFeedbackOnCompletion)
    provide('notIncludeAlreadyAnswered', notIncludeAlreadyAnswered)
    provide('currentTab', currentTab)
    provide('subjectsSelected', selectedSubjects)
    provide('tabHasSelectedTags', subjectHasQuestionIdsPerTag)
    provide('isCreatingList', isCreatingList)
    provide('selectedGroups', { selectedGroups, setSelectedGroups })
    provide('selectedStudents', { selectedStudents, setSelectedStudents })
    provide('setSelectedDateTime', setSelectedDateTime)

    const removeSubject = () => {
      if (selectedSubjects.value.length) {
        const tabIndex = selectedSubjects.value.indexOf(currentTab.value)

        if (tabIndex !== -1) {
          selectedSubjects.value.splice(tabIndex, 1)
        }
      }
    }

    onMounted(async () => {
      confirmFunction.value = removeSubject

      try {
        const res = await questionsService.get<ResponseDTO<QuestionTagsDTO>>('/tags')
        tagsRetrievedBack.value = res.data.data.subjects
        availableTagsToSelect.value = res.data.data
        availableTagsToSelect.value.sources = availableTagsToSelect.value.sources.filter((source) => {
          if (source !== 'A-TESTE' && source !== 'G1' && source !== 'G2') return true
        })
        availableTagsToSelect.value.years = res.data.data.years.map((it) => String(it))
        availableTagsToSelect.value.years.sort((a, b) => Number(b) - Number(a))
        availableTagsToSelect.value.years = availableTagsToSelect.value.years.filter((year) => Number(year) !== 0)
      } catch (err) {
        const { error } = new HandleErrorAxiosResponseDTO(err)

        $q.notify({
          textColor: 'grey-1',
          message: error.userMessage,
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
    })

    const distributionByTheme = (tree: SubjectTreeCollapseType, totalDesiredQuestion: number, key: string) => {
      const distributionTree = buildDistributionTree()
      const questionAmount = totalDesiredQuestion > tree.maxQuestions ? tree.maxQuestions : totalDesiredQuestion

      switch (key) {
        case 'division':
          {
            const division = distributionTree[0].children.find((it) => it.childId === tree.childId)

            if (division) {
              division.children = []
              division.quantity = questionAmount

              distributeByDivisions(tree, questionAmount, division.children)
            }
          }
          break
        case 'topic':
          {
            const division = distributionTree[0].children.find((it) => it.childId === tree.fatherId)

            if (division) {
              const topic = division.children.find((it) => it.childId === tree.childId)

              if (topic) {
                topic.children = []
                topic.quantity = questionAmount

                distributeByTopics(topic.name, tree, questionAmount, topic.children, true)
              }

              division.quantity = division.children.reduce((acc, it) => acc + it.quantity, 0)
            }
          }
          break
        case 'item':
          {
            const division = distributionTree[0].children.find((it) => it.childId === tree.grandfatherId)

            if (division) {
              const topic = division.children.find((it) => it.childId === tree.fatherId)

              if (topic) {
                const item = topic.children.find((it) => it.childId === tree.childId)

                if (item) {
                  item.children = []
                  item.quantity = questionAmount

                  distributeByItems(item.name, tree, questionAmount, item.children, true)
                }
                topic.quantity = topic.children.reduce((acc, it) => acc + it.quantity, 0)
              }
              division.quantity = division.children.reduce((acc, it) => acc + it.quantity, 0)
            }
          }
          break
        case 'subItem':
          {
            const division = distributionTree[0].children.find((it) => it.childId === tree.greatGrandfatherId)

            if (division) {
              const topic = division.children.find((it) => it.childId === tree.grandfatherId)

              if (topic) {
                const item = topic.children.find((it) => it.childId === tree.fatherId)

                if (item) {
                  const subItem = item.children.find((it) => it.childId === tree.childId)

                  if (subItem) {
                    subItem.children = []
                    subItem.quantity = questionAmount
                  }
                  item.quantity = item.children.reduce((acc, it) => acc + it.quantity, 0)
                }
                topic.quantity = topic.children.reduce((acc, it) => acc + it.quantity, 0)
              }
              division.quantity = division.children.reduce((acc, it) => acc + it.quantity, 0)
            }
          }
          break
        default:
          break
      }

      buildTree(distributionTree)
    }

    const distributeByDivisions = (tree: SubjectTreeCollapseType, totalDesiredQuestion: number, distributionList: Array<DistributionType>) => {
      const { fatherTree: childrenTopic, questionDistributed: divisions } = distribution(tree.name, tree, totalDesiredQuestion, true)

      divisions?.[0].divisions?.forEach((division) => {
        distributionList.push({
          name: division.name || '',
          children: [],
          quantity: division.quantity || 0,
        })

        distributeByTopics(division.name || '', childrenTopic, division.quantity || 0, distributionList[distributionList.length - 1].children)
      })
    }

    const distributeByTopics = (name: string, tree: SubjectTreeCollapseType, totalDesiredQuestion: number, distributionList: Array<DistributionType>, ignoreInherence = false) => {
      const { fatherTree: childrenItems, questionDistributed: items } = distribution(name, tree, totalDesiredQuestion, ignoreInherence)

      items?.[0].divisions?.forEach((item) => {
        distributionList.push({
          name: item.name || '',
          children: [],
          quantity: item.quantity || 0,
        })

        distributeByItems(item.name || '', childrenItems, item.quantity || 0, distributionList[distributionList.length - 1].children)
      })
    }

    const distributeByItems = (name: string, tree: SubjectTreeCollapseType, totalDesiredQuestion: number, distributionList: Array<DistributionType>, ignoreInherence = false) => {
      const { questionDistributed: subItems } = distribution(name, tree, totalDesiredQuestion, ignoreInherence)

      subItems?.[0].divisions?.forEach((subItem) => {
        distributionList.push({
          name: subItem.name || '',
          children: [],
          quantity: subItem.quantity || 0,
        })
      })
    }

    const distribution = (actualTheme: string, tree: SubjectTreeCollapseType, totalDesiredQuestion = 0, ignoreInheritance = false) => {
      const fatherTree = ignoreInheritance ? tree : toRaw(tree.children.find((child) => child.name === actualTheme)) || ({} as SubjectTreeCollapseType)

      const grandfatherTree = [
        new Tree(
          actualTheme,
          0,
          fatherTree.children.map(
            (childTree) =>
              new Tree(
                childTree.name,
                0,
                childTree.children.map((item) => new Tree(item.name, -1, [], item.maxQuestions, -1, -1)),
                childTree.maxQuestions,
                -1,
                -1
              )
          ),
          fatherTree.maxQuestions,
          -1,
          -1
        ),
      ]

      const subjectsBalancedQuestions = balanceQuestionsAcrossSubjects(fatherTree.maxQuestions, grandfatherTree, totalDesiredQuestion, { isCreatingList: true })

      const questionDistributed = distributeQuestionsToChildren(grandfatherTree, subjectsBalancedQuestions, {} as any)

      return { fatherTree, questionDistributed: questionDistributed.length ? questionDistributed : null }
    }

    type DistributionType = {
      name: string
      children: Array<DistributionType>
      quantity: number
      childId?: number
      fatherId?: number
    }

    const distributeSubjectQuestions = (tree: SubjectTreeCollapse, totalDesiredQuestion: number) => {
      const distributionList: Array<DistributionType> = []
      const subjectName = currentTab.value
      const totalQuestionsInSubject = subjectHasQuestionIdsPerTag.value[subjectName].subjectTree.total

      distributionList.push({
        name: subjectName,
        children: [],
        quantity: totalDesiredQuestion,
      })

      const fatherTree = [
        new Tree(
          subjectName,
          0,
          tree.children.map(
            (division) =>
              new Tree(
                division.name,
                0,
                division.children.map((topic) => new Tree(topic.name, 0, [], topic.maxQuestions, -1, -1)),
                division.maxQuestions,
                -1,
                -1
              )
          ),
          totalQuestionsInSubject,
          -1,
          -1
        ),
      ]

      const balancedQuestions = balanceQuestionsAcrossSubjects(totalQuestionsInSubject, fatherTree, totalDesiredQuestion, { isCreatingList: true })

      const divisions = distributeQuestionsToChildren(fatherTree, balancedQuestions, {} as any)

      divisions?.[0].divisions?.forEach((division, divisionIndex) => {
        distributionList[0].children.push({
          name: division.name || '',
          children: [],
          quantity: division.quantity || 0,
        })

        const { questionDistributed: topics, fatherTree: childrenTopic } = distribution(division.name || '', tree, division.quantity || 0)

        topics?.[0].divisions?.forEach((topic, topicIndex) => {
          distributionList[0].children[divisionIndex].children.push({
            name: topic.name || '',
            children: [],
            quantity: topic.quantity || 0,
          })

          const { questionDistributed: items, fatherTree: childrenItem } = distribution(topic.name || '', childrenTopic, topic.quantity || 0)

          items?.[0].divisions?.forEach((item, itemIndex) => {
            distributionList[0].children[divisionIndex].children[topicIndex].children.push({
              name: item.name || '',
              children: [],
              quantity: item.quantity || 0,
            })

            const { questionDistributed: subItems } = distribution(item.name || '', childrenItem, item.quantity || 0)

            subItems?.[0].divisions?.forEach((subItem) => {
              distributionList[0].children[divisionIndex].children[topicIndex].children[itemIndex].children.push({
                name: subItem.name || '',
                children: [],
                quantity: subItem.quantity || 0,
              })
            })
          })
        })
      })

      buildTree(distributionList)
    }

    const buildDistributionTree = () => {
      const distributionTree: Array<DistributionType> = [
        {
          name: currentTab.value,
          children: [],
          quantity: 0,
        },
      ]

      subjectHasQuestionIdsPerTag.value[currentTab.value].subjectTree.children.forEach((division, divisionIndex) => {
        distributionTree[0].children.push({
          name: division.name,
          children: [],
          quantity: division.totalDesiredQuestions,
          childId: division.childId,
          fatherId: division.fatherId,
        })

        division.children.forEach((topic, topicIndex) => {
          distributionTree[0].children[divisionIndex].children.push({
            name: topic.name,
            children: [],
            quantity: topic.totalDesiredQuestions,
            childId: topic.childId,
            fatherId: topic.fatherId,
          })

          topic.children.forEach((item, itemIndex) => {
            distributionTree[0].children[divisionIndex].children[topicIndex].children.push({
              name: item.name,
              children: [],
              quantity: item.totalDesiredQuestions,
              childId: item.childId,
              fatherId: item.fatherId,
            })

            item.children.forEach((subItem) => {
              distributionTree[0].children[divisionIndex].children[topicIndex].children[itemIndex].children.push({
                name: subItem.name,
                children: [],
                quantity: subItem.totalDesiredQuestions,
                childId: subItem.childId,
                fatherId: subItem.fatherId,
              })
            })
          })
        })
      })

      return distributionTree
    }

    const buildTree = (distributedQuestionsTree: Array<DistributionType>) => {
      subjectHasQuestionIdsPerTag.value[currentTab.value].subjectTree.totalDesiredQuestions = distributedQuestionsTree[0].children.reduce((acc, it) => acc + it.quantity, 0)

      subjectHasQuestionIdsPerTag.value[currentTab.value].subjectTree.children.forEach((division) => {
        const divisionFound = distributedQuestionsTree[0].children.find((it) => it.name === division.name)

        if (divisionFound) {
          division.totalDesiredQuestions = divisionFound.quantity

          division.children.forEach((topic) => {
            const topicFound = divisionFound.children.find((it) => it.name === topic.name)

            if (topicFound) {
              topic.totalDesiredQuestions = topicFound.quantity

              topic.children.forEach((item) => {
                const itemFound = topicFound.children.find((it) => it.name === item.name)

                if (itemFound) {
                  item.totalDesiredQuestions = itemFound.quantity

                  item.children.forEach((subItem) => {
                    const subItemFound = itemFound.children.find((it) => it.name === subItem.name)

                    if (subItemFound) {
                      subItem.totalDesiredQuestions = subItemFound.quantity
                    }
                  })
                }
              })
            }
          })
        }
      })

      forceComponentsReload.value++
    }

    const closeModal = () => (isModalOpen.value = false)
    const openModal = () => (isModalOpen.value = true)

    const updateSubject = (subjects: Array<string>) => (selectedSubjects.value = subjects)

    const handleCopy = () => {
      const filterCopyAux = Object.values(selectedTagsOnCurrentTab.value)
        .map((it) => it.length > 0)
        .some((it) => it)

      if (!filterCopyAux) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Não há filtros para copiar',
          color: 'yellow-8',
          position: 'top',
          classes: 'notify',
        })
        return
      }

      filterCopy.value = Object.keys(selectedTagsOnCurrentTab.value).reduce((acc, cur) => {
        let key: keyof QuestionTagsDTO = cur as keyof QuestionTagsDTO
        if (selectedTagsOnCurrentTab.value[key].length > 0) acc[key] = selectedTagsOnCurrentTab.value[key].map((it) => it)
        return acc
      }, {} as QuestionTagsDTO)

      $q.notify({
        textColor: 'grey-1',
        message: 'Filtros copiados com sucesso',
        color: 'green',
        position: 'top',
        classes: 'notify',
      })
    }

    const handlePaste = () => {
      const hasFiltersOnPaste = Object.values(filterCopy.value)
        .map((it) => it.length > 0)
        .some((it) => it)

      if (!hasFiltersOnPaste) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Não há filtros na área de transferência',
          color: 'yellow-8',
          position: 'top',
          classes: 'notify',
        })
        return
      }

      for (const entry of Object.keys(selectedTagsOnCurrentTab.value)) {
        let key: keyof QuestionTagsDTO = entry as keyof QuestionTagsDTO
        selectedTagsOnCurrentTab.value[key] = filterCopy.value[key] ? filterCopy.value[key].map((it) => it) : []
      }

      $q.notify({
        textColor: 'grey-1',
        message: 'Filtros colados com sucesso',
        color: 'green',
        position: 'top',
        classes: 'notify',
      })
    }

    const handleFilterCleanAll = () => {
      selectedTagsOnCurrentTab.value.levels = []
      selectedTagsOnCurrentTab.value.types = []
      selectedTagsOnCurrentTab.value.years = []
      selectedTagsOnCurrentTab.value.sources = []

      loadQuestionIdsForSubjectIfAbsent(currentTab.value, new QuestionTagsDTO())

      forceComponentsReload.value++
    }

    const handleRemoveFilterOnTab = ({ label, field }: { label: string; field: string }) => {
      for (const entry of Object.keys(selectedTagsOnCurrentTab.value)) {
        let key: keyof QuestionTagsDTO = entry as keyof QuestionTagsDTO

        if (entry === field) selectedTagsOnCurrentTab.value[key] = selectedTagsOnCurrentTab.value[key].filter((it: string) => it !== label)
      }
      forceComponentsReload.value++
    }

    const processQuestionTags = (questionIdsForSubjectResponse: FilterQuestionDivisionsResponseType) => {
      const subjectTreeCollapseTypeAux = new SubjectTreeCollapse(questionIdsForSubjectResponse)

      return subjectTreeCollapseTypeAux
    }

    const loadQuestionIdsForSubjectIfAbsent = async (subject: string, filters: QuestionTagsDTO) => {
      if (subjectHasQuestionIdsPerTag.value[subject]) return

      isSearchingQuestions.value = true

      const foundSubject = availableTagsToSelect.value.subjects.find((element) => element.includes(subject))

      if (foundSubject) subject = foundSubject

      requestQueueNumber.value++

      try {
        const questionIdsForSubjectResponse = await questionsService.post<ResponseDTO<FilterQuestionDivisionsResponseType>>('/v2/subject-tree', {
          filter: {
            sources: filters.sources,
            years: filters.years,
            types: filters.types,
            levels: filters.levels,
            subjects: [subject],
          },
        })

        const questionIdsPerTag = processQuestionTags(questionIdsForSubjectResponse.data.data)
        subjectHasQuestionIdsPerTag.value[subject] = new DataByTabsDTO(filters, questionIdsPerTag)
        requestQueueNumber.value--
      } catch (err) {
        const { error } = new HandleErrorAxiosResponseDTO(err)

        $q.notify({
          textColor: 'grey-1',
          message: error.userMessage,
          color: 'red',
          position: 'top',
          classes: 'notify',
        })

        requestQueueNumber.value--
      }

      isSearchingQuestions.value = false
    }

    const watchSubjectsChange = async () => {
      await Promise.all(selectedSubjects.value.map((subject) => loadQuestionIdsForSubjectIfAbsent(subject, new QuestionTagsDTO())))
      for (const key of Object.keys(subjectHasQuestionIdsPerTag.value)) {
        if (!selectedSubjects.value.some((it) => it === key)) {
          delete subjectHasQuestionIdsPerTag.value[key]
        }
      }

      if (!currentTab.value || !selectedSubjects.value.some((it) => it === currentTab.value)) {
        currentTab.value = selectedSubjects.value[0]
      }
    }

    const watchTabChange = () => {
      if (subjectHasQuestionIdsPerTag.value[currentTab.value]) totalNumberDesiredQuestions.value = String(subjectHasQuestionIdsPerTag.value[currentTab.value].totalSelectedQuestions)

      if (subjectHasQuestionIdsPerTag.value[currentTab.value]) {
        selectedTagsOnCurrentTab.value.years = [...subjectHasQuestionIdsPerTag.value[currentTab.value].filters.years]
        selectedTagsOnCurrentTab.value.levels = [...subjectHasQuestionIdsPerTag.value[currentTab.value].filters.levels]
        selectedTagsOnCurrentTab.value.types = [...subjectHasQuestionIdsPerTag.value[currentTab.value].filters.types]
        selectedTagsOnCurrentTab.value.sources = [...subjectHasQuestionIdsPerTag.value[currentTab.value].filters.sources]
      } else {
        selectedTagsOnCurrentTab.value.years = []
        selectedTagsOnCurrentTab.value.levels = []
        selectedTagsOnCurrentTab.value.types = []
        selectedTagsOnCurrentTab.value.sources = []
      }

      if (subjectHasQuestionIdsPerTag.value[currentTab.value]) {
        graphicBar.value = setBarGraphicInfos(subjectHasQuestionIdsPerTag.value[currentTab.value].subjectTree || {})
        divChartHeight.value = 120 + 30 * (graphicBar.value.series![0] as any).data.length + 'px'
        totalElements.value = subjectHasQuestionIdsPerTag.value[currentTab.value].subjectTree.total
      }

      forceComponentsReload.value++
    }

    function areSetsEqual(arrayA: Array<string>, arrayB: Array<string>): boolean {
      if (arrayA.length != arrayB.length) return false
      return arrayA.every((itA) => arrayB.includes(itA)) && arrayB.every((itB) => arrayA.includes(itB))
    }

    const watchTagsChange = async () => {
      if (subjectHasQuestionIdsPerTag.value[currentTab.value] && subjectHasQuestionIdsPerTag.value[currentTab.value].totalSelectedQuestions) {
        subjectHasQuestionIdsPerTag.value[currentTab.value].totalSelectedQuestions = totalSelectedQuestionsOnCurrentTab.value
      }
      totalNumberDesiredQuestions.value = String(totalSelectedQuestionsOnCurrentTab.value)
      forceComponentsReload.value++

      if (!currentTab.value) return

      if (
        subjectHasQuestionIdsPerTag.value[currentTab.value] &&
        areSetsEqual(subjectHasQuestionIdsPerTag.value[currentTab.value].filters.years, selectedTagsOnCurrentTab.value.years) &&
        areSetsEqual(subjectHasQuestionIdsPerTag.value[currentTab.value].filters.levels, selectedTagsOnCurrentTab.value.levels) &&
        areSetsEqual(subjectHasQuestionIdsPerTag.value[currentTab.value].filters.types, selectedTagsOnCurrentTab.value.types) &&
        areSetsEqual(subjectHasQuestionIdsPerTag.value[currentTab.value].filters.sources, selectedTagsOnCurrentTab.value.sources)
      ) {
        return
      }

      delete subjectHasQuestionIdsPerTag.value[currentTab.value]

      await loadQuestionIdsForSubjectIfAbsent(currentTab.value, new QuestionTagsDTO(selectedTagsOnCurrentTab.value))

      graphicBar.value = setBarGraphicInfos(subjectHasQuestionIdsPerTag.value[currentTab.value]?.subjectTree || {})
    }

    const createList = async () => {
      validation.value.listName = listName.value
      validation.value.color = selectedColorName.value[0]

      await v$.value.$validate()

      const totalSelectedQuestions = Object.values(subjectHasQuestionIdsPerTag.value).reduce((acc, it) => acc + it.totalSelectedQuestions, 0)

      if (totalSelectedQuestions === 0) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Escolha no mínimo uma questão',
          color: 'yellow-8',
          position: 'top',
          classes: 'notify',
        })

        return
      }

      if (totalSelectedQuestions > 200) {
        $q.notify({
          textColor: 'grey-1',
          message: 'O número de questões não deve ser maior que 200',
          color: 'yellow-8',
          position: 'top',
          classes: 'notify',
        })

        return
      }

      if (listName.value && selectedColorName.value[0] && listName.value.length >= 3) {
        listSummaryInfos.value.listName = listName.value
        listSummaryInfos.value.color = colors.find((it) => it.name == selectedColorName.value[0])!.hex

        Object.keys(listSummaryInfos.value.summary).forEach((subject) => delete listSummaryInfos.value.summary[subject])

        for (const subject of Object.keys(subjectHasQuestionIdsPerTag.value)) {
          if (!listSummaryInfos.value.summary[subject]) listSummaryInfos.value.summary[subject] = { filters: new QuestionTagsDTO(), total: 0 }

          listSummaryInfos.value.summary[subject].filters = subjectHasQuestionIdsPerTag.value[subject].filters

          if (currentTabTypeSelectionQuestions.value === 'Por total de questões')
            listSummaryInfos.value.summary[subject].total = subjectHasQuestionIdsPerTag.value[subject].subjectTree.totalDesiredQuestions
          else listSummaryInfos.value.summary[subject].total = subjectHasQuestionIdsPerTag.value[subject].totalSelectedQuestions
        }

        listSummaryModalIsOpen.value = true
      }
    }

    const formatDataToSend = () => {
      const auxSummary: SummaryType = { includeAlreadyAnswered: !notIncludeAlreadyAnswered.value, subjects: [] }

      for (const subject of Object.keys(subjectHasQuestionIdsPerTag.value)) auxSummary.subjects.push(new SummaryTreeSubjectsType(subject, subjectHasQuestionIdsPerTag.value[subject]))
      return auxSummary
    }

    const finishListCreation = async () => {
      const formattedSummary = formatDataToSend()

      try {
        const { data } = await questionsService.post<ResponseDTO<Array<number>>>('/', formattedSummary)

        dataListToSend.value = new CreateListDTO(
          listName.value,
          colors.find((it) => it.name == selectedColorName.value[0])?.hex ?? DefaultTheme().colors.primaryColor,
          shouldGiveInstantFeedback.value,
          data.data,
          selectedStudents.value.length ? selectedStudents.value.map((it) => it.id) : undefined,
          selectedGroups.value.length ? selectedGroups.value.map((it) => Number(it.id)) : undefined,
          expirationDate.value || expirationDate.value !== '' ? expirationDate.value : undefined,
          userPermissionGroupOrRoot.value ? showFeedbackOnCompletion.value : undefined
        )

        const listCreatedResponse = await listsService.post<ResponseDTO<CreateListDTO>>('/', dataListToSend.value)
        if (listCreatedResponse.status === 200) {
          $q.notify({
            textColor: 'grey-1',
            message: 'Lista criada com sucesso',
            color: 'green',
            position: 'top',
            classes: 'notify',
          })
          setTimeout(() => {
            if (userPermissionGroupOrRoot.value) window.location.href = '/exercicios'
            else window.location.href = '/listas'
          }, 1500)
        }
      } catch (err) {
        if (err instanceof AxiosError) {
          const { error } = new HandleErrorAxiosResponseDTO(err)

          $q.notify({
            textColor: 'grey-1',
            message: error.userMessage,
            color: 'yellow-8',
            position: 'top',
            classes: 'notify',
          })
        }
      } finally {
        isCreatingList.value = false
      }
    }

    watch(selectedColorName, () => forceComponentsReload.value++, { deep: true })
    watch(currentTab, watchTabChange, { deep: true })
    watch(selectedTagsOnCurrentTab.value, watchTagsChange, { deep: true })
    watch(selectedSubjects, watchSubjectsChange, { deep: true })
    watch(
      totalSelectedQuestionsOnCurrentTab,
      () => {
        if (subjectHasQuestionIdsPerTag.value[currentTab.value]) subjectHasQuestionIdsPerTag.value[currentTab.value].totalSelectedQuestions = totalSelectedQuestionsOnCurrentTab.value
      },
      { deep: true }
    )

    return {
      distributeSubjectQuestions,
      handleRemoveFilterOnTab,
      selectedSubjects,
      subjectHasQuestionIdsPerTag,
      forceComponentsReload,
      previousValue,
      listSummaryModalIsOpen,
      availableTagsToSelect,
      selectedTagsOnCurrentTab,
      currentTabTypeSelectionQuestions,
      handleFilterCleanAll,
      updateSubject,
      listSummaryInfos,
      confirmFunction,
      isSearchingQuestions,
      confirmLabel,
      totalNumberDesiredQuestions,
      enterNumberQuestions,
      inputNumberDesiredRef,
      isCreatingList,
      tagsRetrievedBack,
      handleCopy,
      handlePaste,
      finishListCreation,
      divChartHeight,
      graphicBar,
      requestQueueNumber,
      createList,
      currentTab,
      handleCleanFilters,
      divChartVisibility,
      isModalOpen,
      totalSelectedQuestionsOnCurrentTab,
      listName,
      distributionByTheme,
      selectedColorName,
      totalElements,
      colorsNames,
      v$,
      isModalConfirmOpen,
      closeModal,
      openModal,
      removeSubject,
      openModalConfirm,
      RemoveSubjectIcon,
      DefaultTheme,
    }
  },
})
