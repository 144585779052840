import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["stroke"]
const _hoisted_4 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 66 66",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("rect", {
      x: "5",
      y: "5",
      width: "56",
      height: "56",
      rx: "28",
      fill: _ctx.DefaultTheme().colors.error100
    }, null, 8, _hoisted_2),
    _createElementVNode("rect", {
      x: "5",
      y: "5",
      width: "56",
      height: "56",
      rx: "28",
      stroke: _ctx.DefaultTheme().colors.error50,
      "stroke-width": "10"
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      d: "M38.8326 21.3334V34.1667M44.6659 30.4334V25.0667C44.6659 23.7599 44.6659 23.1065 44.4116 22.6074C44.1879 22.1684 43.8309 21.8114 43.3919 21.5877C42.8928 21.3334 42.2394 21.3334 40.9326 21.3334H28.4703C26.7652 21.3334 25.9127 21.3334 25.2241 21.6454C24.6172 21.9204 24.1014 22.3629 23.7374 22.9209C23.3243 23.554 23.1946 24.3966 22.9354 26.0819L22.3251 30.0485C21.9832 32.2712 21.8122 33.3826 22.142 34.2473C22.4315 35.0063 22.976 35.641 23.6822 36.0426C24.4867 36.5 25.6111 36.5 27.86 36.5H28.7992C29.4526 36.5 29.7793 36.5 30.0289 36.6272C30.2484 36.7391 30.4269 36.9175 30.5388 37.1371C30.6659 37.3866 30.6659 37.7133 30.6659 38.3667V41.7899C30.6659 43.3787 31.9539 44.6667 33.5427 44.6667C33.9217 44.6667 34.2651 44.4435 34.419 44.0972L38.3399 35.2752C38.5182 34.874 38.6074 34.6734 38.7483 34.5263C38.8729 34.3962 39.0259 34.2968 39.1954 34.2358C39.387 34.1667 39.6066 34.1667 40.0457 34.1667H40.9326C42.2394 34.1667 42.8928 34.1667 43.3919 33.9124C43.8309 33.6887 44.1879 33.3317 44.4116 32.8927C44.6659 32.3936 44.6659 31.7402 44.6659 30.4334Z",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_4)
  ], 8, _hoisted_1))
}