import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64fb9484"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center justify-center span-icon" }
const _hoisted_2 = ["placeholder", "value", "maxlength", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      tabindex: "0",
      class: _normalizeClass(["flex items-center justify-center", {
      'question-page': _ctx.questionPage,
      square: _ctx.square,
      transparent: _ctx.transparent,
      'search-list': _ctx.searchList,
      'create-list': _ctx.createList,
      'mobile-schools': _ctx.mobileSchool,
      'div-input': !_ctx.mobileSchool,
      'shared-list': _ctx.sharedList,
    }])
    }, [
      _createElementVNode("span", _hoisted_1, [
        _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
      ]),
      _createElementVNode("input", {
        ref: "inputRef",
        class: _normalizeClass(["input", {
        'question-page': _ctx.questionPage,
        cvv: _ctx.cvv,
        center: _ctx.center,
        transparent: _ctx.transparent,
        'center-goal': _ctx.goalBtn,
        'search-list': _ctx.searchList,
        input__collapsed: _ctx.inputCollapsed,
      }]),
        type: 'text',
        placeholder: _ctx.placeholder,
        value: _ctx.modelValue,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput($event))),
        onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('enter', $event.target)), ["enter"])),
        maxlength: _ctx.maxLength,
        readonly: _ctx.readonly
      }, null, 42, _hoisted_2),
      (_ctx.$slots.button)
        ? _renderSlot(_ctx.$slots, "button", { key: 0 }, undefined, true)
        : _createCommentVNode("", true)
    ], 2),
    (!_ctx.hideBottomSpace && (_ctx.error || _ctx.inputValidation))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["error", { checkout: _ctx.checkout }])
        }, _toDisplayString(_ctx.errorMessage), 3))
      : _createCommentVNode("", true)
  ], 64))
}