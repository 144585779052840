
import LoginLayout from '@/components/Layouts/LoginLayout.vue';
import AuthenticationForm from '@/components/LoginForms/AuthenticationForm.vue';

import { defineComponent, inject, ref } from 'vue'
import { InstitutionConfigType } from '@/types/InstitutionConfigType'

export default defineComponent({
  name: 'LoginPage',
  components: {
    LoginLayout,
    AuthenticationForm,
  },
  setup() {
    const hasTab = ref(false);
    const institutionConfig = inject<InstitutionConfigType>('institutionConfig');

    return {
      hasTab,
      title: institutionConfig!.name,
    }
  },
})
