import { Socket } from 'socket.io-client'
import { Callback, ElapsedTimeDTO, ExceptionDTO, HeartbeatDTO } from './types'

export class StopWatchWebSocketServiceEvents {
  constructor(private socket: Socket) {}

  heartbeat(data: HeartbeatDTO) {
    this.socket.emit('heartbeat', data)
  }

  exception(callback: Callback<ExceptionDTO>) {
    this.socket.on('exception', (data: ExceptionDTO) => callback(data))
  }

  elapsedTime(callback: Callback<ElapsedTimeDTO>) {
    this.socket.on('elapsedTime', (data: ElapsedTimeDTO) => callback(data))
  }

  close() {
    this.socket.close()
  }
}
