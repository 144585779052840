
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import Input from '@/components/Library/Input/index.vue'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import { PlanSchoolUserDTO } from '@/dtos/schoolPlanPages/UserDTO/PlanSchoolUserDTO'
import creditsService from '@/services/credits'
import usersService from '@/services/users'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, onMounted, ref, watch } from 'vue'

export default defineComponent({
  name: 'ContentModalReleaseGratuity',
  components: {
    Input,
    PazzeiBtn,
  },
  props: {
    userId: {
      type: String,
      required: false,
      default: '',
    },
    sizeEditLoading: {
      type: String,
    },
  },

  setup(props, { emit }) {
    const formData = ref<{ gratuity: string }>({
      gratuity: '',
    })
    const isFetchData = ref(false)
    const isLoading = ref(false)
    const newFreeDays = ref(0)
    const freeDays = ref<number | null>(null)
    const roleDescription = ref('')
    const $q = useQuasar()

    const handleMessageGratuity = () => {
      if (freeDays.value !== null && freeDays.value > 0)
        return `*Este usuário possui ${freeDays.value} dia${freeDays.value > 1 ? 's' : ''} de gratuidade${roleDescription.value ? ` como ${roleDescription.value}` : ''}.`
      else if (freeDays.value === null) return '*Este usuário já tem acesso liberado por tempo indeterminado.'
      else if (freeDays.value === 0) return '*O período de gratuidade deste usuário expirou hoje.'
      else return '*O período de gratuidade deste usuário já expirou.'
    }

    const getCurrentDate = () => {
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate() + 1)
      return currentDate.toISOString().split('T')[0]
    }

    const fetchUserCredits = async () => {
      isFetchData.value = true

      try {
        const { data: infos } = await usersService.get<ResponseDTO<PlanSchoolUserDTO>>(`/${props.userId}`)

        freeDays.value = infos.data.credits !== null ? Number(infos.data.credits) : null
        roleDescription.value = infos.data.roleDescription

        if (freeDays.value !== null) {
          const currentDate = new Date()
          const daysToAdd = freeDays.value
          currentDate.setDate(currentDate.getDate() + daysToAdd)
          const newDate = currentDate.toISOString().substr(0, 10)
          formData.value.gratuity = newDate
        }
      } catch (error) {
        console.error(error)
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao buscar dados',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      } finally {
        isFetchData.value = false
      }
    }

    const sendTimeGratuity = async () => {
      const data = { userId: props.userId, credits: newFreeDays.value }

      isLoading.value = true
      try {
        await creditsService.patch('/', data)
        $q.notify({
          textColor: 'grey-1',
          message: 'Dados salvos com sucesso',
          color: 'green',
          position: 'top',
          classes: 'notify',
        })
      } catch (error) {
        console.error(error)
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao salvar dados',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      } finally {
        isLoading.value = false
        emit('close')
      }
    }

    onMounted(() => {
      fetchUserCredits()
    })

    watch(
      formData,
      () => {
        const currentDate = new Date()
        newFreeDays.value = Math.ceil((new Date(formData.value.gratuity).getTime() - currentDate.getTime()) / (1000 * 3600 * 24))
      },
      { deep: true }
    )

    return {
      sendTimeGratuity,
      handleMessageGratuity,
      getCurrentDate,
      formData,
      freeDays,
      isFetchData,
      isLoading,
    }
  },
})
