
import HintTooltip from '@/components/Generics/HintTooltip/HintTooltip.vue';
import PazzeiButton from '@/components/Generics/Redesign/PazzeiButton.vue';
import listsService from '@/services/list';
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'ListSubscriptionComponent',
  props: {
    codeLength: { type: Number, default: 10 }
  },
  components: { PazzeiButton, HintTooltip },
  setup(props) {
    const inputCode = ref<string>('');
    const isLoading = ref<boolean>(false);
    const isDisabled = ref<boolean>(true);
    const error = ref<string | false>(false);

    /**
     * Valida integridade do campo e trata excessões
     */
    watch(inputCode, (inValue) => {
      if (!inValue.length) {
        error.value = false;
        isDisabled.value = true;
        return;
      }

      if ((inValue.length !== props.codeLength) || !/^\d+$/.test(inValue)) {
        error.value = `O código é composto por ${props.codeLength} caracteres numéricos.`;
        isDisabled.value = true;
        return;
      }


      if (error.value) error.value = false;
      if (isDisabled.value) isDisabled.value = false;
    });

    /**
     * Valida entrada e impede caracteres não-numéricos
     */
    const validateInput = (event: Event) => {
      const input = event.target as HTMLInputElement;
      const newValue = input.value.replace(/\D/g, '');
      if (newValue !== input.value) {
        input.value = newValue;
        inputCode.value = newValue;
      }
    };

    /**
     * Trata acão de clique no botão de inscricão
     */
    const handleSubscription = async () => {
      if (isDisabled.value) return;

      try {
        isLoading.value = true;
        await listsService.post(`/shared-lists/${inputCode.value}/code/add-user`).then(res => {
          if (res.status === 200 || res.status === 201) {
            setTimeout(() => window.location.reload(), 250)
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        isLoading.value = false;
        inputCode.value = '';
        isDisabled.value = true;
      }
    }

    return {
      inputCode, handleSubscription, validateInput, isLoading, isDisabled, error,
    }
  },

})
