import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withKeys as _withKeys, vModelText as _vModelText, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c9d9d546"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "q-card-div" }
const _hoisted_2 = {
  key: 0,
  class: "elipses"
}
const _hoisted_3 = {
  class: "q-mt-lg div-wrapper-name",
  style: {"padding-top":"5px"}
}
const _hoisted_4 = { class: "p-div" }
const _hoisted_5 = {
  key: 1,
  class: "buttons-editing-name"
}
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "font-size-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GroupIcon = _resolveComponent("GroupIcon")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_circular_progress = _resolveComponent("q-circular-progress")!
  const _component_PazzeiMenuLists = _resolveComponent("PazzeiMenuLists")!
  const _component_PazzeiBtn = _resolveComponent("PazzeiBtn")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["q-backdrop-prog", _ctx.checkForGoldenClass()])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["hide-golden-border", _ctx.checkForGoldenClass()])
      }, null, 2)
    ], 2),
    _createVNode(_component_q_card, {
      class: _normalizeClass(["q-card-cpnt q-py-md", _ctx.checkForGoldenClass()])
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["q-progress", _ctx.checkForGoldenClass()])
        }, [
          _createVNode(_component_q_circular_progress, {
            color: _ctx.btnLabel() === 'Ver respostas' || (_ctx.btnLabel() === 'Expirado' && _ctx.tryFinished === true) ? 'accent' : 'primary',
            class: "q-circ-progress",
            "font-size": "12px",
            max: _ctx.subjectInfo.totalQuestions,
            size: _ctx.progressSize.progressCircleSize,
            "show-value": "",
            thickness: 0.17,
            "track-color": "grey-3",
            value: _ctx.totalResponses
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_avatar, {
                size: _ctx.progressSize.progressAvatarSize
              }, {
                default: _withCtx(() => [
                  (!_ctx.isWhiteLabel)
                    ? (_openBlock(), _createBlock(_component_GroupIcon, {
                        key: 0,
                        fill: _ctx.DefaultTheme().colors.whiteColor
                      }, null, 8, ["fill"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["size"])
            ]),
            _: 1
          }, 8, ["color", "max", "size", "thickness", "value"])
        ], 2),
        (!_ctx.notShowMenu)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.userCanInteract)
                ? (_openBlock(), _createBlock(_component_PazzeiMenuLists, {
                    key: 0,
                    listId: _ctx.subjectInfo.id,
                    isTest: _ctx.isTest,
                    list: _ctx.subjectInfo
                  }, null, 8, ["listId", "isTest", "list"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (!_ctx.editName)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: _normalizeClass(["text-center text-h6 q-ma-none p-title p-list-name", _ctx.totalResponses ? '' : 'disabled'])
                }, _toDisplayString(_ctx.name), 3))
              : _createCommentVNode("", true)
          ]),
          (_ctx.editName)
            ? _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 0,
                onKeydown: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.handleNameChange && _ctx.handleNameChange(...args)), ["enter"])),
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.name) = $event)),
                tabindex: "1",
                ref: "focusEditName",
                maxlength: "40",
                class: _normalizeClass(["text-center text-h6 q-ma-none p-title input-name", _ctx.totalResponses ? '' : 'disabled'])
              }, null, 34)), [
                [_vModelText, _ctx.name]
              ])
            : _createCommentVNode("", true),
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.totalResponses ? 'info-p text-center' : 'disabled')
          }, _toDisplayString(_ctx.totalResponses) + " de " + _toDisplayString(_ctx.subjectInfo.totalQuestions) + " questões respondidas", 3)
        ]),
        (_ctx.editName)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_PazzeiBtn, {
                label: "Cancelar",
                "subject-list": "",
                cancel: "",
                class: "btn-edit",
                onClick: _ctx.cancelEditName
              }, null, 8, ["onClick"]),
              _createVNode(_component_PazzeiBtn, {
                label: "Salvar",
                "subject-list": "",
                success: "",
                class: "btn-edit",
                onClick: _ctx.handleNameChange
              }, null, 8, ["onClick"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_PazzeiBtn, {
                class: _normalizeClass(["btn-primary", _ctx.btnLabel() === 'Ver respostas' ? 'golden-button' : _ctx.isExpired ? 'expired-button' : 'btn-pazzei']),
                "subject-list": "",
                label: _ctx.btnLabel(),
                onClick: _ctx.handleClick
              }, null, 8, ["label", "class", "onClick"])
            ])),
        (_ctx.expirationDate)
          ? (_openBlock(), _createBlock(_component_q_tooltip, {
              key: 3,
              "transition-show": "scale",
              "transition-hide": "scale",
              class: _normalizeClass({ [_ctx.handleTooltipInfos().color]: true, 'text-body2': true })
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.handleTooltipInfos().message), 1)
                ])
              ]),
              _: 1
            }, 8, ["class"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}