import { formatCurrency } from '@/utils/common/formatCurrency'

export type BillingInfosData = {
  statusCode: number
  message: string
  data: BillingInfos
}

export type PlanInfo = {
  id?: number
  description: string
  recurrence?: string
}

export class BillingInfos {
  id: number
  plan: PlanInfo
  planId: number
  holderName: string
  paymentMethod: string
  autoRenew: boolean
  createdAt: string
  discount: string
  price: number
  fullPrice: string
  hasCoupon: boolean
  status: string
  isReversed: boolean
  cardNumber: string
  installments: number
  hasValidity: boolean
  validity: number
  _qrCodeUrl?: string
  _pixCopyAndPaste?: string

  constructor(data: any) {
    this.id = data.id ? data.id : -1
    this.plan = data.plan ? data.plan : ''
    this.planId = data.planId
    this.holderName = data.holderName
    this.paymentMethod = data.paymentMethod ? data.paymentMethod : ''
    this.autoRenew = data.autoRenew && data.autoRenew === 'Automática' ? true : false
    this.createdAt = data.createdAt ? data.createdAt : '-'
    this.discount = data.discount || data.discount === 0 ? formatCurrency(Number(data.discount)) : ''
    this.price = data.price || data.price === 0 ? data.price : 0
    this.fullPrice = data.fullPrice ? formatCurrency(Number(data.fullPrice)) : 'R$ 00,00'
    this.hasCoupon = data.hasCoupon
    this.status = data.status
    this.isReversed = data.isReversed
    this.cardNumber = data.cardNumber ? data.cardNumber : '-'
    this.installments = data.installments ? data.installments : 0
    this.hasValidity = data.hasValidity
    this.validity = data.validity ? data.validity : 1
    this._qrCodeUrl = data._qrCodeUrl ? data._qrCodeUrl : undefined
    this._pixCopyAndPaste = data._pixCopyAndPaste ? data._pixCopyAndPaste : undefined
  }
}
