
import DefaultTheme from '@/theme/defaultTheme';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AlertIconRedIcon',
  props: {
    size: { type: Number, default: 48 },
    stroke: { type: String, default: DefaultTheme().colors.primaryColor }, 
    fill: { type: String, default: 'currentColor' }, 
  },
  setup() {
    return {
      DefaultTheme
    };
  }
});
