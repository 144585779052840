import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-87bfaf50"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PazzeiModal = _resolveComponent("PazzeiModal")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#portal-root" }, [
    (!_ctx.isStaticTerm)
      ? (_openBlock(), _createBlock(_component_PazzeiModal, {
          key: 0,
          congrats: "",
          terms: "",
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
          "responsiveness-width": "90%",
          "responsiveness-height": "70%"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "terms",
              style: {"text-align":"justify"},
              innerHTML: _ctx.termHTML
            }, null, 8, _hoisted_1)
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_PazzeiModal, {
          key: 1,
          congrats: "",
          terms: "",
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
          "responsiveness-width": "90%",
          "responsiveness-height": "70%"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "terms",
              style: {"text-align":"justify"},
              innerHTML: _ctx.staticTerms
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }))
  ]))
}