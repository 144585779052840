import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7371ec77"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "embeded-dashboard" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainLayoutWrapper = _resolveComponent("MainLayoutWrapper")!

  return (_openBlock(), _createBlock(_component_MainLayoutWrapper, { "answer-list": "" }, {
    main: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("iframe", {
          width: "100%",
          height: "100%",
          src: _ctx.dashboardUrl,
          frameborder: "0",
          allowFullScreen: "true"
        }, null, 8, _hoisted_2)
      ])
    ]),
    _: 1
  }))
}