import { ThemeType } from "../types";
import ArcanjoBrandAssets from "./assets";
import { ArcanjoBrandColors } from "./tokens/colors";
import { ArcanjoBrandFontSizes } from "./tokens/fonts";
import { ArcanjoBrandSizes } from "./tokens/sizes";

const ArcanjoBrandTheme: ThemeType = {
  colors: ArcanjoBrandColors,
  fontSizes: ArcanjoBrandFontSizes,
  sizes: ArcanjoBrandSizes,
  assets: ArcanjoBrandAssets
};

export default ArcanjoBrandTheme;
