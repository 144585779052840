
import CaptionView from '@/components/Application/captionView/CaptionView.vue'
import CardKnowledgeArea from '@/components/Application/enemModulePage/cardKnowledgeArea/CardKnowledgeArea.vue'
import QuestionsControl from '@/components/Application/questionsControl/QuestionsControl.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutSubWrapper from '@/components/Layouts/MainLayoutSubWrapper.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import Button from '@/components/Library/Button/index.vue'
import Table from '@/components/Library/Table/index.vue'
import ModalViewAnsweredQuestion from '@/components/ListsFinishedModal/ModalViewAnsweredQuestion.vue'
import { AreasDTO, EnemModuleReportsDTO, LevelsDTO, SubjectsDTO } from '@/dtos/EnemModuleReportsDTO'
import { HandleErrorAxiosResponseDTO } from '@/dtos/HandleErrorAxiosResponseDTO'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import reportsService from '@/services/reports'
import { KnowledgeAreaInfosType } from '@/types/KnowledgeAreaInfosType'
import { largeAreas } from '@/utils/hardCoded/enemModule/enemModule'
import { AxiosError } from 'axios'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { computed, defineComponent, provide, ref, toRaw, Ref, inject } from 'vue'
import { useRoute } from 'vue-router'
import Modal from '../components/Library/Modal/index.vue'
import router from '../router'
import { QuestionCacheType } from '../types/QuestionCacheType'
import { timeInWords } from '../utils/common/time'

export default defineComponent({
  name: 'TestPage',
  components: {
    QuestionsControl,
    Button,
    CaptionView,
    CardKnowledgeArea,
    GenericLayout,
    MainLayoutSubWrapper,
    MainLayoutWrapper,
    ModalViewAnsweredQuestion,
    Modal,
    Table,
  },
  setup() {
    const $q = useQuasar()

    const modalViewAnsweredQuestion = ref(false)
    const alternativesLoaded = ref(false)
    const reportsLoaded = ref(false)
    const isLoading = computed(() => !alternativesLoaded.value)

    const listName = ref('')
    const isListEnemModuleAndAtYourPace = ref(false)
    const isListEnemModule = ref(false)
    const knowledgeAreaInfos = ref<Array<KnowledgeAreaInfosType>>([])
    const responsesCache = ref<Array<QuestionCacheType>>([])
    const idQuestion = ref<number>()
    const indexQuestion = ref<number>()
    const userCanInteract = inject<Ref<string>>('userCanInteract')

    const { id: listId, tryId } = useRoute().params

    let test = false
    let listCreatedAt = ''
    let listDuration = 0
    let permissionToShowQuestions = false
    const showTableOfAreas = ref(false)

    const handleBackToStartBtnClick = () => {
      if (test) router.push('/banco-de-provas')
      else router.push('/listas')
    }

    const getPerformance = async () => {
      try {
        const { data } = await reportsService.get<ResponseDTO<EnemModuleReportsDTO>>('performance', { params: { listId } })

        const keys = Object.keys(data.data.subjects)
        if (data.data.subjects && data.data.subjects[keys[0]].levels) showTableOfAreas.value = true

        const areaInfos = transformDataIntoKnowledgeArea(data.data.areas)

        largeAreas.forEach((largeArea) => {
          const area = areaInfos.find((area) => area.title === largeArea.title)
          if (area) knowledgeAreaInfos.value.push(area)
        })
      } catch (err) {
        if (err instanceof AxiosError) {
          const { error } = new HandleErrorAxiosResponseDTO(err)
          $q.notify({
            textColor: 'grey-1',
            message: error.userMessage,
            color: 'yellow-8',
            position: 'top',
            classes: 'notify',
          })
        }
      } finally {
        reportsLoaded.value = true
      }
    }

    const onAlternativesLoaded = (isLoaded: boolean) => (alternativesLoaded.value = isLoaded)

    const getPercentage = (total: number, value: number) => ((value * 100) / total).toFixed()

    const getReportByLevel = (reportingLevels: Array<LevelsDTO>) => {
      const data = reportingLevels
        .map((report) => ({
          difficulty: report.name,
          totalQuestions: report.totalQuestions,
          totalCorrects: report.totalQuestions ? `${report.totalCorrects} (${getPercentage(report.totalQuestions, report.totalCorrects)}%)` : '-',
          totalIncorrects: report.totalQuestions ? `${report.totalIncorrects} (${getPercentage(report.totalQuestions, report.totalIncorrects)}%)` : '-',
        }))
        .sort((a, b) => {
          if (a.difficulty.length <= b.difficulty.length) return -1
          if (a.difficulty.length > b.difficulty.length) return 1
          return 0
        })

      const { totalQuestions, totalCorrects, totalIncorrects } = reportingLevels.reduce(
        (acc, curr) => {
          acc.totalQuestions += curr.totalQuestions
          acc.totalCorrects += curr.totalCorrects
          acc.totalIncorrects += curr.totalIncorrects
          return acc
        },
        { totalQuestions: 0, totalCorrects: 0, totalIncorrects: 0 }
      )

      const total = {
        totalQuestions,
        totalCorrects: `${totalCorrects} (${getPercentage(totalQuestions, totalCorrects)}%)`,
        totalIncorrects: `${totalIncorrects} (${getPercentage(totalQuestions, totalIncorrects)}%)`,
      }

      return { data, total }
    }

    const getReportBySubject = (subjects: Array<SubjectsDTO>) =>
      subjects.map((subject) => {
        const { data, total } = getReportByLevel(subject.levels)

        return { subject: subject.name, data, total }
      })

    const transformDataIntoKnowledgeArea = (areas: Array<AreasDTO>) =>
      areas.map((area) => {
        const largeAreaInfos = largeAreas.find((largeArea) => largeArea.title === area.name)
        const generalReport = getReportByLevel(area.levels)
        const reportBySubject = getReportBySubject(area.subjects)
        const reports = [{ subject: 'Geral', data: generalReport.data, total: generalReport.total }, ...reportBySubject]

        return {
          icon: largeAreaInfos?.icon || '',
          title: area.name,
          description: `Teste realizado no dia ${listCreatedAt.split('T')[0].split('-').reverse().join('/')} em ${timeInWords(listDuration)}.`,
          reports,
        }
      })

    const getQuestionsAndAlternativesResponses = ({
      userResponses,
      listNam,
      listEnemModuleAndAtYourPace,
      listEnemModule,
      isTest,
      createdAt,
      duration,
      showQuestions,
    }: {
      userResponses: Array<QuestionCacheType>
      listNam: string
      listEnemModuleAndAtYourPace: boolean
      listEnemModule: boolean
      isTest: boolean
      createdAt: string
      duration: number
      showQuestions: boolean
    }) => {
      responsesCache.value = userResponses
      listName.value = listNam
      isListEnemModuleAndAtYourPace.value = listEnemModuleAndAtYourPace
      isListEnemModule.value = listEnemModule
      test = isTest
      listCreatedAt = createdAt
      listDuration = duration
      permissionToShowQuestions = showQuestions

      if (isListEnemModule.value) getPerformance()
    }

    const isListOnline = () => {
      return test && !isListEnemModuleAndAtYourPace.value && !isListEnemModule.value
    }

    // Não mexer nessa linha, porque a ordem dos parâmetros é importante!!
    const onQuestionClick = (questionLabel: number, questionId: number, questionOrder: number) => {

      if(isListOnline() && !permissionToShowQuestions) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Não é possível visualizar a questão',
          color: 'yellow-8',
          position: 'top',
          classes: 'notify',
        })
        return;
      }

      const question = toRaw(responsesCache.value).find((response) => response.questionId === questionId)

      idQuestion.value = question?.questionId
      indexQuestion.value = question?.order

      modalViewAnsweredQuestion.value = true
    }

    provide('onQuestionClick', onQuestionClick)

    return {
      listName,
      modalViewAnsweredQuestion,
      isLoading,
      knowledgeAreaInfos,
      listId,
      tryId,
      onAlternativesLoaded,
      alternativesLoaded,
      getQuestionsAndAlternativesResponses,
      onQuestionClick,
      handleBackToStartBtnClick,
      idQuestion,
      indexQuestion,
      isListEnemModuleAndAtYourPace,
      isListEnemModule,
      showTableOfAreas,
      userCanInteract,
    }
  },
})
