export class UserDTO {
  name: string
  lastName: string
  email: string
  groupId: number
  registrationCompleted: boolean
  roleCode: string
  legalRepresentative?: string
  emailLegalRepresentative?: string
  credits?: number
  autoRenew?: boolean
  institutionId?: number
  isLegalAge?: boolean
  cpf?: string
  id?: string
  createdAt?: string
  updatedAt?: string

  constructor({
    email,
    groupId,
    name,
    lastName,
    registrationCompleted,
    roleCode,
    legalRepresentative,
    emailLegalRepresentative,
    credits,
    autoRenew,
    createdAt,
    updatedAt,
  }: {
    name: string
    lastName: string
    email: string
    groupId: number
    registrationCompleted: boolean
    roleCode: string
    legalRepresentative?: string
    emailLegalRepresentative?: string
    credits?: number
    autoRenew?: boolean
    createdAt?: string
    updatedAt?: string
  }) {
    this.name = name
    this.lastName = lastName
    this.email = email
    this.groupId = groupId
    this.registrationCompleted = registrationCompleted
    this.roleCode = roleCode
    this.legalRepresentative = legalRepresentative
    this.emailLegalRepresentative = emailLegalRepresentative
    this.credits = credits
    this.autoRenew = autoRenew
    this.createdAt = createdAt
    this.updatedAt = updatedAt
  }
}
