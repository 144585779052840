
import DefaultTheme from "@/theme/defaultTheme";
import { defineComponent, PropType, ref } from "vue";

export default defineComponent({
  name: 'CustomDropdown',
  props: {
    dropdownOptions: { type: Array as PropType<Array<{id: number, label: string}>>}
  },
  setup() {
    const showOptions = ref(false);
     
    const handleShowOptions = () => {
      showOptions.value = !showOptions.value;
    }

    return {
      showOptions,
      handleShowOptions,
      DefaultTheme
    }
  }
});

