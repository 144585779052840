
import Tabs from '@/components/Generics/PazzeiTab.vue';
import authService from '@/services/authService';
import DefaultTheme from '@/theme/defaultTheme';
import { InstitutionConfigType } from '@/types/InstitutionConfigType';
import { defineComponent, inject, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'LoginLayout',
  components: { Tabs },
  props: {
    tab: String,
    title: String,
    hasTab: Boolean,
    textCenter: Boolean,
    greetings: Boolean,
  },
  setup() {
    const person = ref<string | undefined>('')
    const institutionConfig = inject<InstitutionConfigType>('institutionConfig');
    const brandHomeUrl = ref<string>(institutionConfig?.landingPageUrl ?? '/');

    onMounted(() => {
      person.value = authService.getPermissions()?.roleDescription
    })

    return {
      person,
      DefaultTheme,
      brandHomeUrl,
    }
  },
})
