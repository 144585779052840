
import PazzeiSimpleSelect from '@/components/Generics/PazzeiSimpleSelect.vue'
import Button from '@/components/Library/Button/index.vue'
import Input from '@/components/Library/Input/index.vue'
import Modal from '@/components/Library/Modal/index.vue'
import useVuelidate from '@vuelidate/core'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { computed, defineComponent, onMounted, onUnmounted, onUpdated, ref, watch } from 'vue'
import { PaymentProfileDTO } from '../../dtos/PaymentProfileDTO'
import paymentsService from '../../services/payment'
import changePayCard from '../../utils/vuelidateRules/changePayCard'

export default defineComponent({
  name: 'ModalChangeFormPayment',
  emits: ['update:visibility'],
  props: {
    visibility: { type: Boolean, default: false, required: true },
  },
  components: { Modal, Input, Button, PazzeiSimpleSelect },
  setup(props, { emit }) {
    const modalVisibility = ref(props.visibility)

    const formData = ref({
      name: '',
      document: '',
      cardNumber: '',
      expirationMonth: [''],
      expirationYear: [''],
      expirationDate: '',
      cvv: '',
      autoRenew: true,
    })
    const monthArray = Array.from({ length: 12 }, (_, index) => (index < 9 ? '0' + (index + 1) : (index + 1).toString()))
    const currentYear = new Date().getFullYear()
    const yearArray = Array.from({ length: 15 }, (_, index) => (currentYear + index).toString())

    const isLoading = ref(false)
    const expiredMonth = ref({
      message: '',
      expired: false,
    })
    const $q = useQuasar()
    const rules = computed(() => changePayCard)

    const v$ = useVuelidate(rules, formData)

    const cancelChanges = async () => {
      isLoading.value = false
      clearFormData()
      modalVisibility.value = false
    }

    const clearFormData = () => {
      formData.value.name = ''
      formData.value.document = ''
      formData.value.cardNumber = ''
      formData.value.expirationMonth = ['']
      formData.value.expirationYear = ['']
      formData.value.expirationDate = ''
      formData.value.cvv = ''
      formData.value.autoRenew = true
    }

    const handleChangePayment = async () => {
      isLoading.value = true
      const result = await v$.value.$validate()

      if (result) {
        const yearTwoDigits = formData.value.expirationYear[0] ? formData.value.expirationYear[0].slice(2, 4) : '**'

        const month = formData.value.expirationMonth[0] ? formData.value.expirationMonth[0] : '**'
        formData.value.expirationDate = month + '/' + yearTwoDigits

        const paymentProfile = new PaymentProfileDTO(formData.value.name, formData.value.expirationDate, formData.value.cardNumber.replaceAll(' ', ''), formData.value.cvv)

        try {
          await paymentsService.put('/credit-cards', paymentProfile)

          $q.notify({
            textColor: 'grey-1',
            message: 'Dados salvos com sucesso',
            color: 'green',
            position: 'top',
            classes: 'notify',
          })

          setTimeout(() => {
            clearFormData()
            modalVisibility.value = false
          }, 3000)
        } catch (error) {
          $q.notify({
            textColor: 'grey-1',
            message: 'Erro ao salvar os dados',
            color: 'red',
            position: 'top',
            classes: 'notify',
          })
        }
      }
      isLoading.value = false
    }

    watch(
      () => modalVisibility.value,
      () => {
        emit('update:visibility', modalVisibility.value)
      }
    )
    const innerWidth = ref(window.innerWidth)
    const width = ref('34.5em')
    const height = ref('35.5em')

    const setNewTab = () => {
      innerWidth.value = window.innerWidth
    }

    window.addEventListener('resize', setNewTab)

    onMounted(() => {
      if (innerWidth.value <= 768) {
        width.value = '90%'
        height.value = '70%'
      }
    })

    onUpdated(() => {
      if (modalVisibility.value !== props.visibility) modalVisibility.value = props.visibility
    })

    onUnmounted(() => window.removeEventListener('resize', setNewTab))

    return { modalVisibility, formData, yearArray, monthArray, handleChangePayment, isLoading, expiredMonth, v$, cancelChanges, width, height }
  },
})
