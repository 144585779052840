
import PazzeiButton from '@/components/Generics/Redesign/PazzeiButton.vue'
import PazzeiCheckbox from '@/components/Generics/Redesign/PazzeiCheckbox.vue'
import PazzeiModal from '@/components/Generics/Redesign/PazzeiModal/PazzeiModal.vue'
import PazzeiToggle from '@/components/Generics/Redesign/PazzeiToggle.vue'
import { FilterListsDTO, GenericFilterDTO } from '@/dtos/modal/FilterListsDTO'
import { UserHasListDTO } from '@/dtos/SubjectInfoDTO'
import DefaultTheme from '@/theme/defaultTheme'
import { defineComponent, PropType, ref } from 'vue'
import CustomCTA from '../Generics/Redesign/CustomCTA.vue'
import FilterTag from '../Generics/Redesign/FilterTag.vue'
import RecycleBinIcon from '../Icons/default/redesign/sharedList/RecycleBinIcon.vue'
import ModalClearFilters from './ModalClearFilters.vue'

export default defineComponent({
  name: 'ModalFilterList',
  emits: ['close', 'updateSubjects', 'apply-filters'],
  props: {
    status: { type: Array as PropType<Array<FilterListsDTO>>, default: () => [] },
    availableAnswers: { type: GenericFilterDTO },
    listCreators: { type: Array as PropType<Array<UserHasListDTO>>, default: () => [] }
  },
  components: {
    PazzeiModal,
    FilterTag,
    PazzeiToggle,
    PazzeiCheckbox,
    PazzeiButton,
    ModalClearFilters,
    CustomCTA
  },
  setup(props) {
    const context = {
      name: 'filterList',
      title: 'Filtros',
    }

    const showModal = ref(false);
    const showActionLabel = ref(false);

    const handleFilterSelection = (filter: FilterListsDTO) => {
      filter.isActive = !filter.isActive
    }

    const handleAvailableAnswers = (filter: GenericFilterDTO) => {
      filter.isActive = !filter.isActive;
    }

    const clearAvailableAnswers = (filter: GenericFilterDTO) => {
      filter.isActive = false;
    }

    const handleShowActionLabel = () => {
      showActionLabel.value = !showActionLabel.value;
    }

    const handleListCreator = (creator: UserHasListDTO) => {
      creator.active = !creator.active;
    }

    const clearCreatorFilters = () => {
      props.listCreators.forEach((userHasList: UserHasListDTO) => {
        userHasList.active = false;
      })
    }

    const clearFilters = () => {
      showModal.value = true;
    }

    const handleClearFiltersModalClose = () => {
      showModal.value = false;
    }

    const handleClearFilters = () => {
      props.status.forEach((item) => item.isActive = false);

      if (props.availableAnswers) {
        clearAvailableAnswers(props.availableAnswers)
      }

      clearCreatorFilters();
    }

    return {
      context,
      handleFilterSelection,
      handleAvailableAnswers,
      handleShowActionLabel,
      showActionLabel,
      clearCreatorFilters,
      handleListCreator,
      showModal,
      clearFilters,
      handleClearFiltersModalClose,
      handleClearFilters,
      RecycleBinIcon,
      DefaultTheme
    }
  }
})
