export class CreateNewAddressDTO {
  street: string
  number: string
  zipcode: string
  city: string
  state: string
  country: string
  neighborhood: string

  constructor(street: string, number: string, zipcode: string, city: string, state: string, country: string, neighborhood: string) {
    this.street = street
    this.number = number
    this.zipcode = zipcode
    this.city = city
    this.state = state
    this.country = country
    this.neighborhood = neighborhood
  }
}
