import { ScopedType } from '../types/ScopedType'

export class PermissionDTO {
  scope: ScopedType
  microservice: string
  resource: string
  action: string
  basePermission: string
  constructor(scope: ScopedType, microservice: string, resource: string, action: string) {
    if (!scope || !microservice || !resource || !action) throw new Error('Missing parameters')
    this.scope = scope
    this.microservice = microservice
    this.resource = resource
    this.action = action
    this.basePermission = `${microservice}:${resource}:${action}`
  }
}
