import { PermissionDTO } from '@/dtos/PermissionDTO'
import { TokenDecodeDTO } from '@/dtos/TokenDecodeDTO'
import { ScopedType } from '@/types/ScopedType'
import jwtDecode from 'jwt-decode'

const authService = {
  setUserOnSession(token: string, refreshToken?: string) {
    const parsedToken = token
    const parsedRefreshToken = refreshToken

    sessionStorage.setItem('token', parsedToken)
    if (parsedRefreshToken) sessionStorage.setItem('refreshToken', parsedRefreshToken)
  },

  setUserOnLocal(token: string, refreshToken: string) {
    const parsedToken = token
    const parsedRefreshToken = refreshToken

    localStorage.setItem('token', parsedToken)
    localStorage.setItem('refreshToken', parsedRefreshToken)
  },

  logoutUser() {
    localStorage.removeItem('plan')
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('refreshToken')
    window.location.href = '/'
  },

  setOtpToken(otp: string) {
    const parsedOtp = otp
    sessionStorage.setItem('otpToken', parsedOtp)
  },

  verifyPermissions() {
    const token = localStorage.getItem('token') ?? sessionStorage.getItem('token')

    if (token) {
      try {
        const checkToken: TokenDecodeDTO = jwtDecode(token)
        const permissionsToCheck = ['self:lists:lists:manage', 'root:lists:lists:manage', 'group:lists:lists:manage', 'all:lists:lists:manage']
        const hasPermission = permissionsToCheck.some((permission) => checkToken.payload.permissions.includes(permission))
        return hasPermission ? 'home' : 'checkout'
      } catch {
        return 'error'
      }
    }
  },

  validatePermissions(permission: string) {
    const token = localStorage.getItem('token') ?? sessionStorage.getItem('token')
    if (token) {
      const checkToken: TokenDecodeDTO = jwtDecode(token)
      if (checkToken.payload.permissions.includes(permission)) return true
      return false
    }
    return false
  },

  getPermissions() {
    const token = localStorage.getItem('token') ?? sessionStorage.getItem('token')
    if (token) {
      const checkToken: TokenDecodeDTO = jwtDecode(token)
      return checkToken.payload
    }
  },

  getHigherScoped(): ScopedType | undefined {
    const token = localStorage.getItem('token') ?? sessionStorage.getItem('token')

    if (token) {
      const checkToken: TokenDecodeDTO = jwtDecode(token)
      const scopes = checkToken.payload.permissions.filter((permission) => !['root:users:simulate-access:manage'].includes(permission)).flatMap((it) => it.split(':')[0])
      if (scopes.includes('all')) return 'all'
      if (scopes.includes('root')) return 'root'
      if (scopes.includes('group')) return 'group'
      if (scopes.includes('self')) return 'self'
    }
  },

  generatePermissions() {
    const token = localStorage.getItem('token') ?? sessionStorage.getItem('token')
    if (token) {
      const decodedToken: TokenDecodeDTO = jwtDecode(token)
      const permissions = decodedToken.payload.permissions
      return permissions.map((permission) => {
        const splittedPermission = permission.split(':')
        return new PermissionDTO(splittedPermission[0] as ScopedType, splittedPermission[1], splittedPermission[2], splittedPermission[3])
      })
    } else return Array<PermissionDTO>()
  },
}

export default authService
