import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["stroke"]
const _hoisted_4 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 66 66",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("rect", {
      x: "5",
      y: "5",
      width: "56",
      height: "56",
      rx: "28",
      fill: _ctx.DefaultTheme().colors.error100
    }, null, 8, _hoisted_2),
    _createElementVNode("rect", {
      x: "5",
      y: "5",
      width: "56",
      height: "56",
      rx: "28",
      stroke: _ctx.DefaultTheme().colors.error50,
      "stroke-width": "10"
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      d: "M32.9996 29.4998V34.1664M32.9996 38.8331H33.0113M31.3842 23.5401L21.7885 40.1145C21.2562 41.0338 20.9901 41.4935 21.0294 41.8708C21.0638 42.1998 21.2361 42.4988 21.5037 42.6934C21.8105 42.9164 22.3417 42.9164 23.4039 42.9164H42.5953C43.6576 42.9164 44.1887 42.9164 44.4955 42.6934C44.7631 42.4988 44.9355 42.1998 44.9698 41.8708C45.0092 41.4935 44.743 41.0338 44.2108 40.1145L34.6151 23.5401C34.0848 22.6241 33.8196 22.1661 33.4736 22.0123C33.1719 21.8781 32.8274 21.8781 32.5256 22.0123C32.1797 22.1661 31.9145 22.6241 31.3842 23.5401Z",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_4)
  ], 8, _hoisted_1))
}