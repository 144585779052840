import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 20 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M1.66675 5.8335L8.47085 10.5964C9.02182 10.982 9.29731 11.1749 9.59697 11.2496C9.86166 11.3156 10.1385 11.3156 10.4032 11.2496C10.7029 11.1749 10.9783 10.982 11.5293 10.5964L18.3334 5.8335M5.66675 16.6668H14.3334C15.7335 16.6668 16.4336 16.6668 16.9684 16.3943C17.4388 16.1547 17.8212 15.7722 18.0609 15.3018C18.3334 14.767 18.3334 14.067 18.3334 12.6668V7.3335C18.3334 5.93336 18.3334 5.2333 18.0609 4.69852C17.8212 4.22811 17.4388 3.84566 16.9684 3.60598C16.4336 3.3335 15.7335 3.3335 14.3334 3.3335H5.66675C4.26662 3.3335 3.56655 3.3335 3.03177 3.60598C2.56137 3.84566 2.17892 4.22811 1.93923 4.69852C1.66675 5.2333 1.66675 5.93336 1.66675 7.3335V12.6668C1.66675 14.067 1.66675 14.767 1.93923 15.3018C2.17892 15.7722 2.56137 16.1547 3.03177 16.3943C3.56655 16.6668 4.26662 16.6668 5.66675 16.6668Z",
      "stroke-width": "1.66667",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}