
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiInput from '@/components/Generics/PazzeiInput.vue'
import PazzeiModal from '@/components/Generics/PazzeiModal.vue'
import PazzeiSelectNew from '@/components/Generics/PazzeiSelectNew.vue'
import PazzeiSimple from '@/components/Generics/PazzeiSimpleSelect.vue'
import authService from '@/services/authService'
import { colors } from '@/utils/colorPallete/colors'
import modalCreateListRules from '@/utils/vuelidateRules/modalCreateListRules'
import useVuelidate from '@vuelidate/core'
import { defineComponent, inject, onMounted, onUnmounted, PropType, Ref, ref } from 'vue'
import { useRouter } from 'vue-router'
import ContentModalAddReceivers from '../Application/planSchoolPortal/modals/modalCreateListExercices/ContentModalAddReceivers.vue'

export default defineComponent({
  name: 'ModalCreateList',
  emits: ['close', 'updateSubjects'],
  props: {
    subjectOptions: { type: Array as PropType<Array<string | number>>, require: true },
    open: Boolean,
    selectedSubjects: { type: Array as PropType<Array<string>>, default: () => [] },
  },
  components: {
    PazzeiModal,
    PazzeiInput,
    PazzeiSelectNew,
    PazzeiSimple,
    ContentModalAddReceivers,
    PazzeiBtn,
  },
  setup(props, { emit }) {
    const editing = ref(false)
    const filters = inject<Ref<{ subject: Array<string> | undefined }>>('tags')
    const color = inject<Ref<Array<string>>>('color')
    const inputColor = ref<Array<string>>([''])
    const listName = inject<Ref<string>>('listName')
    const instantFeedback = inject<Ref<boolean>>('instantFeedback')
    const showFeedbackOnCompletion = inject<Ref<boolean>>('showFeedbackOnCompletion')
    const notIncludeAlreadyAnswered = inject<Ref<boolean>>('notIncludeAlreadyAnswered')
    const optionsColors = ref(colors.map((color) => color.name))
    const subject = ref(Array<string>())
    const isAddReceivers = ref(false)
    const validation = ref<{ listName: string | undefined; color: string | undefined; subjects: string }>({
      listName: '',
      color: '',
      subjects: '',
    })
    const key = ref(0)
    const userPermissionProfessor = ref(false)
    const v$ = useVuelidate(modalCreateListRules, validation)
    const router = useRouter()
    const height = ref('70%')
    const optionsGroups = ref<Array<string>>()

    const setSelectedDateTime = inject<(value?: Date) => void>('setSelectedDateTime')
    const openAddReceivers = () => (isAddReceivers.value = true)

    onMounted(async () => {
      const permissionsToCheck = ['group:users:users:manage', 'root:users:users:manage']
      const permissions = authService.getPermissions()?.permissions
      userPermissionProfessor.value = permissionsToCheck.some((permission) => permissions?.includes(permission))

      if (color) validation.value.color = color.value[0]
      if (listName) validation.value.listName = listName.value
      if (filters?.value && filters?.value.subject) validation.value.subjects = filters.value.subject[0]
    })

    const handlePrimaryButton = async () => {
      validation.value.listName = listName?.value
      validation.value.color = color?.value[0]
      validation.value.subjects = subject.value[0]

      const result = await v$.value.$validate()

      if (result && userPermissionProfessor.value) openAddReceivers()
      else nextStep()
    }

    const handleModalClose = (value?: Date) => {
      editing.value = true

      setSelectedDateTime?.(value)

      emit('updateSubjects', [...subject.value])

      emit('close')
    }

    const returnStep = () => (isAddReceivers.value = false)

    const nextStep = async () => {
      key.value++
      validation.value.listName = listName?.value
      validation.value.color = color?.value[0]
      validation.value.subjects = subject.value[0]

      const result = await v$.value.$validate()
      if (result) {
        emit('updateSubjects', [...subject.value])

        editing.value = true
        if (editing.value) height.value = '55%'
        emit('close')
      }
    }

    const redirectPermission = () => {
      if (userPermissionProfessor.value) {
        router.push('/exercicios')
      } else {
        router.push('/')
      }
    }

    onUnmounted(() => (isAddReceivers.value = false))

    return {
      v$,
      handleModalClose,
      optionsColors,
      nextStep,
      key,
      color,
      listName,
      instantFeedback,
      notIncludeAlreadyAnswered,
      subject,
      inputColor,
      editing,
      router,
      height,
      openAddReceivers,
      isAddReceivers,
      userPermissionProfessor,
      handlePrimaryButton,
      optionsGroups,
      returnStep,
      redirectPermission,
      showFeedbackOnCompletion,
    }
  },
})
