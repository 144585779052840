import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d473ee9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "titles-names" }
const _hoisted_2 = { class: "container-titles-infos" }
const _hoisted_3 = { class: "h1-title" }
const _hoisted_4 = { class: "div-header-progress" }
const _hoisted_5 = { class: "q-ma-none text-gray" }
const _hoisted_6 = { class: "linear-progress" }
const _hoisted_7 = { class: "question-main-wrapper" }
const _hoisted_8 = {
  key: 0,
  class: "question-main"
}
const _hoisted_9 = {
  key: 0,
  class: "doubt-button-main"
}
const _hoisted_10 = { class: "div-footer" }
const _hoisted_11 = { class: "div-footer-inputs" }
const _hoisted_12 = { class: "footer-navigation" }
const _hoisted_13 = { class: "div-previous-btn" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = { class: "count" }
const _hoisted_16 = { class: "pazzei-input" }
const _hoisted_17 = { class: "div-next-btn" }
const _hoisted_18 = ["disabled"]
const _hoisted_19 = { class: "doubt-button-footer" }
const _hoisted_20 = {
  ref: "finalButtonsRef",
  class: "final-buttons-div"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ForgottenQuestions = _resolveComponent("ForgottenQuestions")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_MultipleActions = _resolveComponent("MultipleActions")!
  const _component_ModalCongrats = _resolveComponent("ModalCongrats")!
  const _component_PazzeiModal = _resolveComponent("PazzeiModal")!
  const _component_PazzeiDot = _resolveComponent("PazzeiDot")!
  const _component_q_linear_progress = _resolveComponent("q-linear-progress")!
  const _component_StopWatch = _resolveComponent("StopWatch")!
  const _component_QuestionWrapper = _resolveComponent("QuestionWrapper")!
  const _component_Button = _resolveComponent("Button")!
  const _component_CooliconIcon = _resolveComponent("CooliconIcon")!
  const _component_PazzeiInput = _resolveComponent("PazzeiInput")!
  const _component_MainLayoutSubWrapper = _resolveComponent("MainLayoutSubWrapper")!
  const _component_MainLayoutWrapper = _resolveComponent("MainLayoutWrapper")!
  const _component_GenericLayout = _resolveComponent("GenericLayout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Modal, {
      modelValue: _ctx.modalForgottenQuestionsVisible,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalForgottenQuestionsVisible) = $event)),
      width: "auto",
      height: "auto",
      "hide-icon": "",
      "prevent-closing": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ForgottenQuestions, {
          modelValue: _ctx.modalForgottenQuestionsVisible,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalForgottenQuestionsVisible) = $event)),
          "questions-not-answered": _ctx.questionsNotAnswered,
          onClose: _ctx.handleCloseModalForgottenQuestions,
          onFinalizeList: _ctx.handleFinalizeTry,
          "list-name": _ctx.listInfos.name,
          "list-large-areas": _ctx.listLargeAreas
        }, null, 8, ["modelValue", "questions-not-answered", "onClose", "onFinalizeList", "list-name", "list-large-areas"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_Modal, {
      modelValue: _ctx.openMultipleActionsModal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.openMultipleActionsModal) = $event)),
      width: "auto",
      height: "auto",
      variant: "small-close-icon"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_MultipleActions, {
          title: _ctx.messagesOnModal.title,
          "auxiliary-info": _ctx.messagesOnModal.auxiliaryInfo,
          info: _ctx.messagesOnModal.info,
          "array-of-buttons": _ctx.messagesOnModal.arrayOfButton
        }, null, 8, ["title", "auxiliary-info", "info", "array-of-buttons"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_GenericLayout, null, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_Teleport, { to: "#portal-root" }, [
          (_ctx.isModalVisibleCongrats)
            ? (_openBlock(), _createBlock(_component_PazzeiModal, {
                key: 0,
                noImage: "",
                onClose: _ctx.closeModal,
                "responsiveness-width": "90%",
                "responsiveness-height": "40%"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ModalCongrats, { showFinishFeedback: _ctx.showFinishFeedback }, null, 8, ["showFinishFeedback"])
                ]),
                _: 1
              }, 8, ["onClose"]))
            : _createCommentVNode("", true)
        ])),
        _createVNode(_component_MainLayoutWrapper, { "answer-list": "" }, {
          main: _withCtx(() => [
            _createVNode(_component_MainLayoutSubWrapper, { "answer-list": "" }, {
              title: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.isListEnemModule ? _ctx.areaName : _ctx.listInfos.name), 1)
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass({ 'series-container': _ctx.isListEnemModuleAndAtYourPace, 'series-container-without-dot': !_ctx.isListEnemModuleAndAtYourPace })
                  }, [
                    (_ctx.isListEnemModuleAndAtYourPace)
                      ? (_openBlock(), _createBlock(_component_PazzeiDot, {
                          key: 0,
                          count: _ctx.seriesCount,
                          serie: _ctx.currentSerie
                        }, null, 8, ["count", "serie"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.questionNumber) + "/" + _toDisplayString(_ctx.isListEnemModule ? (_ctx.isListEnemModuleAndAtYourPace ? 15 : _ctx.listInfos.totalQuestions) : _ctx.listInfos.totalQuestions) + " Questões", 1),
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_q_linear_progress, {
                          size: "1em",
                          value: _ctx.listInfos.totalQuestions ? _ctx.questionNumber / (_ctx.isListEnemModule ? (_ctx.isListEnemModuleAndAtYourPace ? 15 : _ctx.listInfos.totalQuestions) : _ctx.listInfos.totalQuestions) : 0
                        }, null, 8, ["value"])
                      ])
                    ]),
                    (_ctx.listTimeElapsed !== undefined)
                      ? (_openBlock(), _createBlock(_component_StopWatch, {
                          key: 1,
                          "try-id": Number(_ctx.tryId),
                          "start-time": _ctx.listTimeElapsed
                        }, null, 8, ["try-id", "start-time"]))
                      : _createCommentVNode("", true)
                  ], 2)
                ])
              ]),
              main: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  (_ctx.currentQuestionId !== 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        (_openBlock(), _createBlock(_component_QuestionWrapper, {
                          modelValue: _ctx.alternativesModel,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.alternativesModel) = $event)),
                          questionId: _ctx.currentQuestionId,
                          listId: Number(_ctx.id),
                          key: _ctx.currentQuestionId,
                          answers: _ctx.listTryDTO?.userResponses,
                          "is-instant-feedback": _ctx.listInfos.instantFeedback,
                          correctAlternatives: _ctx.correctAlternatives,
                          onGetQuestionType: _ctx.getQuestionType,
                          onGetQuestionAnswer: _ctx.saveResponse,
                          onGetTags: _ctx.getAreaName,
                          onStopLoading: _ctx.stopLoading
                        }, null, 8, ["modelValue", "questionId", "listId", "answers", "is-instant-feedback", "correctAlternatives", "onGetQuestionType", "onGetQuestionAnswer", "onGetTags", "onStopLoading"])),
                        (_ctx.isLoadingQuestion)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                              _createVNode(_component_Button, {
                                label: _ctx.isDoubtLabelButton(),
                                variant: "secondary-purple",
                                width: "13em",
                                "v-model": _ctx.isDoubt,
                                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleMarkAsDoubt()))
                              }, null, 8, ["label", "v-model"])
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("button", {
                          class: _normalizeClass(["button cursor-pointer", _ctx.questionNumber == 1 ? 'last-question' : '']),
                          disabled: _ctx.questionNumber < 2 || _ctx.skippingQuestions,
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.previousQuestion(Number(_ctx.questionNumberInput))))
                        }, [
                          _createVNode(_component_CooliconIcon, {
                            fill: _ctx.DefaultTheme().colors.whiteColor
                          }, null, 8, ["fill"])
                        ], 10, _hoisted_14),
                        _createElementVNode("span", {
                          class: _normalizeClass(["btn-label left", _ctx.questionNumber === 1 ? 'last-question' : ''])
                        }, "Anterior", 2)
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, [
                          _createVNode(_component_PazzeiInput, {
                            type: "number",
                            questionPage: "",
                            modelValue: _ctx.questionNumberInput,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.questionNumberInput) = $event)),
                            onEnter: _ctx.onEnterKey,
                            "hide-bottom-space": "",
                            "max-length": 3
                          }, null, 8, ["modelValue", "onEnter"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("span", {
                          class: _normalizeClass(["btn-label right", _ctx.questionNumber == _ctx.listInfos.listHasQuestions.length ? 'last-question' : ''])
                        }, "Próxima", 2),
                        _createElementVNode("button", {
                          class: _normalizeClass(["button cursor-pointer", _ctx.questionNumber == _ctx.listInfos.listHasQuestions.length ? 'last-question' : '']),
                          disabled: _ctx.questionNumber >= _ctx.listInfos.listHasQuestions.length || _ctx.skippingQuestions || _ctx.lastQuestion,
                          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.nextQuestion(Number(_ctx.questionNumberInput))))
                        }, [
                          _createVNode(_component_CooliconIcon, {
                            fill: _ctx.DefaultTheme().colors.whiteColor,
                            style: {"rotate":"180deg"}
                          }, null, 8, ["fill"])
                        ], 10, _hoisted_18)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createVNode(_component_Button, {
                        label: _ctx.isDoubtLabelButton(),
                        variant: "secondary-purple",
                        width: "14em",
                        "v-model": _ctx.isDoubt,
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.handleMarkAsDoubt())),
                        loading: _ctx.isLoadingDoubt
                      }, null, 8, ["label", "v-model", "loading"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _createVNode(_component_Button, {
                      label: "Fazer uma pausa",
                      variant: "secondary-bold",
                      disabled: _ctx.questionNumber === _ctx.listInfos.listHasQuestions.length,
                      onClick: _ctx.takeABreak
                    }, null, 8, ["disabled", "onClick"]),
                    _createVNode(_component_Button, {
                      label: "Finalizar",
                      variant: "primary-bold",
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.finalizeList(false)))
                    })
                  ], 512)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}