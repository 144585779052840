
import { listHasQuestionDTO } from '@/dtos/ResponseDTOs/ListHasQuestionDTO';
import { defineComponent, PropType } from 'vue';
import { ResponseHasAlternativesType } from '@/types/ResponseHasAlternativesType';

export default defineComponent({
  props: {
    relatedQuestions: { type: Array as PropType<listHasQuestionDTO[]> },
    currentQuestionId: Number,
    listId: Number,
    questionsArray: { type: Object, required: false },
    userIsCheckingQuestions : Boolean
  },
  emits: ['handle-question-change'],
  setup(props, {emit}) {
    const handleQuestionNavigation = (questionIndex: number) => {
      if (props.userIsCheckingQuestions) {
        return;
      }
      
      emit('handle-question-change', questionIndex + 1)
    }

    const questionIsAnswered = (responseHasAlternatives: ResponseHasAlternativesType[]) => {
      return responseHasAlternatives.some((rha) => rha.alternativeId !== null);
    }

    return { questionIsAnswered, handleQuestionNavigation }
  }
})
