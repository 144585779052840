
import PazzeiSimpleSelect from '@/components/Generics/PazzeiSimpleSelect.vue';
import ArrowPageIcon from '@/components/Icons/default/ArrowPageIcon.vue';
import { computed, defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'PaginationTableComponent',
  components: { PazzeiSimpleSelect, ArrowPageIcon },
  emits: ['pageSizeChange', 'currentPage'],
  props: {
    totalElements: { type: Number, default: 0 },
    isFilterSelected: { type: Boolean, default: false },
    backgroundPagination: { type: Boolean, default: true },
  },

  setup(props, { emit }) {
    const optionsPagination = [10, 20, 50, 100]
    const selectedPageSize = ref<{ state: Array<number> }>({ state: [20] })
    const currentPage = ref(1)

    watch(
      selectedPageSize,
      () => {
        currentPage.value = 1
        emit('pageSizeChange', selectedPageSize.value.state[0])
      },
      { deep: true, immediate: true }
    )

    watch(
      currentPage,
      () => {
        emit('currentPage', currentPage.value)
      },
      { deep: true, immediate: true }
    )

    watch(
      () => props.isFilterSelected,
      (newValue) => {
        if (newValue) {
          currentPage.value = 1
        }
      }
    )

    const getPaginationRange = computed(() => {
      const totalPages = Math.ceil(props.totalElements / selectedPageSize.value.state[0])
      return `${currentPage.value > totalPages ? 1 : currentPage.value}-${totalPages} de ${props.totalElements}`
    })

    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--
      }
    }

    const nextPage = () => {
      const totalPages = Math.ceil(props.totalElements / selectedPageSize.value.state[0])
      currentPage.value = currentPage.value < totalPages ? currentPage.value + 1 : 1
    }

    return {
      optionsPagination,
      selectedPageSize,
      getPaginationRange,
      previousPage,
      nextPage,
      currentPage,
    }
  },
})
