
import staticTerms from '@/assets/static/getTermsHtml'
import PazzeiModal from '@/components/Generics/PazzeiModal.vue'
import { TermsTypes } from '@/components/Terms/helpers/interfaces'
import { TermsURLDTO } from '@/dtos/registerPage/termsURLDTO'
import axios from 'axios'
import { defineComponent, onMounted, ref } from 'vue'
import usersService from '../../services/users'

export default defineComponent({
  name: 'ModalTerms',
  props: {
    currentTerm: { type: String, default: '', required: true },
    isStaticTerm: { type: Boolean, default: false },
  },
  components: { PazzeiModal },
  setup(props) {
    const termHTML = ref('')

    const getTerms = async () => {
      const { data } = await usersService.get<TermsURLDTO>('/terms/last-terms')

      const termsURL = { termsOfUse: 'terms-of-use/', privacyPolicy: 'privacy-policy/' }
      data.data.forEach((term) => {
        if (term.termType.type === 'terms-of-use') {
          termsURL.termsOfUse += term.id
        } else {
          termsURL.privacyPolicy += term.id
        }
      })

      const termUrl = process.env.VUE_APP_AWS_STATIC_FILES_BASE_URL + termsURL[props.currentTerm as keyof TermsTypes] + '.html'

      const { data: termHtml } = await axios.get(termUrl)
      termHTML.value = termHtml
    }

    onMounted(getTerms)

    return { termHTML, staticTerms }
  },
})
