import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 16 19",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M8.11111 1.63379H5.26667C3.77319 1.63379 3.02646 1.63379 2.45603 1.92444C1.95426 2.1801 1.54631 2.58805 1.29065 3.08981C1 3.66025 1 4.40698 1 5.90046V13.3671C1 14.8606 1 15.6073 1.29065 16.1778C1.54631 16.6795 1.95426 17.0875 2.45603 17.3431C3.02646 17.6338 3.77319 17.6338 5.26667 17.6338H8.11111M8.11111 1.63379L13.4444 6.96712M8.11111 1.63379V4.12268C8.11111 5.11833 8.11111 5.61615 8.30488 5.99644C8.47532 6.33095 8.74728 6.60291 9.08179 6.77336C9.46208 6.96712 9.95991 6.96712 10.9556 6.96712H13.4444M13.4444 6.96712V7.85601M13.4444 12.3005C11.669 12.3005 11.6681 12.7326 11.6667 13.482L11.6667 13.4849C11.6651 14.2852 11.6667 14.5226 13.4444 14.5226C15.2222 14.5226 15.2222 14.7054 15.2222 15.5597C15.2222 16.2018 15.2222 16.7449 13.4444 16.7449M13.4444 12.3005L15.2222 12.3005M13.4444 12.3005L13.4444 11.4116M13.4444 16.7449H11.6667M13.4444 16.7449L13.4444 17.6338",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}