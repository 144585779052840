
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import CooliconIcon from '@/components/Icons/default/CooliconIcon.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutSubWrapper from '@/components/Layouts/MainLayoutSubWrapper.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import QuestionWrapperProficiency from '@/components/Layouts/QuestionWrapperProficiency.vue'
import DefaultTheme from '@/theme/defaultTheme'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { AlternativesSelectorModelValueType } from '../components/Application/alternativesSelector/types/AlternativesSelectorModelValueType'
import { HandleErrorAxiosResponseDTO } from '../dtos/HandleErrorAxiosResponseDTO'
import { ResponseDTO } from '../dtos/ResponseDTOs/ResponseDTO'
import listsService from '../services/list'
import questionsService from '../services/question'
import { ProficiencyQuestionState } from '../types/ProficiencySubjectType'

export default defineComponent({
  name: 'AnswerTestPage',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const subjectName = ref(route.query.materia || '')
    const catId = ref(route.params.id || -1)
    const questionSource = ref<ProficiencyQuestionState | Record<string, never>>({})
    const alternativesModel = ref<AlternativesSelectorModelValueType>()
    const buttonIsDisabled = ref(true)
    const tagLevel = ref()
    const shouldStop = ref(false)
    const proficiency = ref(0)
    const $q = useQuasar()

    onMounted(async () => {
      try {
        const { data } = await listsService.get<ResponseDTO<ProficiencyQuestionState>>(`/cat/${catId.value}`)

        questionSource.value = data.data
        shouldStop.value = data.data.shouldStop
        proficiency.value = data.data.estimatedTheta

        if (!shouldStop.value) handleSearchByTagLevel(data.data)
      } catch (error) {
        console.error(error)
      }
    })

    const handleSearchByTagLevel = (data: ProficiencyQuestionState) => (tagLevel.value = data.question.tags.find((tag) => tag.key === 'level')?.value)

    const makePause = () => {
      router.push(`/teste-de-proficiencia`)
    }

    const handleNextQuestion = async () => {
      buttonIsDisabled.value = true

      try {
        const { data } = await questionsService.put<ResponseDTO<ProficiencyQuestionState>>(`/cat`, {
          questionId: alternativesModel.value?.questionId,
          alternativeId: alternativesModel.value?.alternatives[0],
          catId: catId.value,
        })

        questionSource.value = data.data
        shouldStop.value = data.data.shouldStop
        proficiency.value = data.data.estimatedTheta

        if (!shouldStop.value) handleSearchByTagLevel(data.data)
      } catch (err) {
        const { error } = new HandleErrorAxiosResponseDTO(err)

        $q.notify({
          textColor: 'grey-1',
          message: error.userMessage,
          color: 'red',
          timeout: 2000,
          position: 'top',
          classes: 'notify',
        })

        console.error(error)
      }
    }

    watch(alternativesModel, () => (buttonIsDisabled.value = alternativesModel.value?.alternatives?.length === 0))

    return {
      makePause,
      proficiency,
      alternativesModel,
      tagLevel,
      handleNextQuestion,
      buttonIsDisabled,
      subjectName,
      questionSource,
      shouldStop,
      DefaultTheme,
    }
  },
  components: {
    MainLayoutWrapper,
    PazzeiBtn,
    MainLayoutSubWrapper,
    GenericLayout,
    QuestionWrapperProficiency,
    CooliconIcon,
  },
})
