
import CardButton from '@/components/Application/entranceExamPage/TestDataBaseModal/CardButton/index.vue'
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiSimpleSelect from '@/components/Generics/PazzeiSimpleSelect.vue'
import PazzeiDropdown from '@/components/Generics/Redesign/PazzeiDropdown.vue'
import PazzeiToggle from '@/components/Generics/Redesign/PazzeiToggle.vue'
import Input from '@/components/Library/Input/index.vue'
import Modal from '@/components/Library/Modal/index.vue'
import Tab from '@/components/Library/Tabs/Tab/index.vue'
import TabPanel from '@/components/Library/Tabs/TabPanel/index.vue'
import TabPanels from '@/components/Library/Tabs/TabPanels/index.vue'
import Tabs from '@/components/Library/Tabs/Tabs/index.vue'
import { QuestionTagsDTO } from '@/dtos/CreateListPage/QuestionTagsDTO'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import { CreateListSchoolsDTO } from '@/dtos/schoolPlanPages/InstitutionDTO/CreateListSchoolsDTO'
import { InstitutionHasInstitutionPlanDTO } from '@/dtos/schoolPlanPages/InstitutionHasInstitutionPlanDTO/InstitutionHasInstitutionPlanDTO'
import { PlanSchoolCreateUserDTO } from '@/dtos/schoolPlanPages/UserDTO/PlanSchoolUserDTO'
import getStates, { ApiDataStates, getDistricts } from '@/services/apis/ibgeStates'
import { AddressTypeReturn, getAddressByCep } from '@/services/apis/viaCep'
import groupsService from '@/services/groups'
import questionsService from '@/services/question'
import usersService from '@/services/users'
import { EntitiesInstitutionPlans, InstitutionPlans } from '@/types/InstitutionPlansType'
import { User } from '@/types/UsersType'
import { formatCep, formatCnpj, formatPhone } from '@/utils/common/generalFormatations'
import { firstTabRules, secondTabRules } from '@/utils/vuelidateRules/modalSchoolRules'
import useVuelidate from '@vuelidate/core'
import debounce from 'quasar/src/utils/debounce.js';import useQuasar from 'quasar/src/composables/use-quasar.js';
import { PropType, computed, defineComponent, onMounted, onUnmounted, onUpdated, ref, watch, watchEffect } from 'vue'
import PazzeiSelectObject from '../../../../Generics/PazzeiSelectObject.vue'


export default defineComponent({
  name: 'ModalRegisterSchool',
  emits: ['update:visibility'],
  components: { PazzeiBtn, Input, PazzeiSelectObject, Tab, TabPanel, TabPanels, Tabs, Modal, CardButton, PazzeiSimpleSelect, PazzeiDropdown, PazzeiToggle },
  props: {
    visibility: { type: Boolean, default: false, required: true },
    isEditingSchool: {
      type: Boolean,
      default: false,
    },
    isViewRegisterSchool: {
      type: Boolean,
      default: false,
    },
    institutionId: {
      type: Number,
      required: false,
      default: -1,
    },
    listInstitution: Function as PropType<() => void>,
    sizeEditLoading: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const controller = new AbortController()
    const listUserByInstitution = ref<User[]>()
    const visibleModal = ref(true)
    const searchValue = ref<string | undefined>('')
    const scrollValue = ref(0)
    const optionsOfficeSchool = ['Diretor', 'Coordenador', 'etc']
    const optionsRoots = ref<Array<{ name: string; id: string }>>([])
    const $q = useQuasar()
    const isFetchData = ref(false)
    const isLoading = ref(false)
    const totalElements = ref(0)
    const update = ref(1)
    const userInstitutionId = ref<string | undefined>('')
    const institutionIdCreated = ref<number | undefined>(-1)
    const stateOptions = ref<Array<string>>([])
    const statesInfos = ref<Array<ApiDataStates>>([])
    const ufAux = ref('')
    const userLocationState = ref<{ state: Array<string> | undefined }>({
      state: [],
    })
    const currentPage = ref(1)
    const pageSize = 30
    const formDataAux = ref<CreateListSchoolsDTO>()
    const citiesOptions = ref<Array<string>>(['Selecione um estado'])
    const userInfosAux = ref<{ city: Array<string> | undefined }>({
      city: [],
    })
    const innerWidth = ref(window.innerWidth)
    const width = ref('80%')

    const setNewTab = () => {
      innerWidth.value = window.innerWidth
    }
    const subject = ref(Array<string>())
    const optionsSubject = ref<Array<string>>([])

    window.addEventListener('resize', setNewTab)
    const tabs = ref('schoolData')
    const labelButtonBack = ref('Cancelar')

    const modalState = ref(props.visibility)
    const isEdit = ref(props.isEditingSchool)
    onUpdated(() => (modalState.value = props.visibility))

    const heightModal = ref('75%')
    let cacheUF = ''

    const institutionHasInstitutionPlan = ref<InstitutionHasInstitutionPlanDTO>()

    const formData = ref<{
      id: number
      name: string
      corporateName: string
      cnpj: string
      telephone: string
      email: string
      managerName: string
      managerNameSelect: { id: string; name: string; email: string }[]
      totalStudent: string,
      externalId?: string,
    }>({
      id: 0,
      name: '',
      corporateName: '',
      cnpj: '',
      telephone: '',
      email: '',
      managerName: '',
      managerNameSelect: [],
      totalStudent: '',
      externalId: '',
    })

    const formAddress = ref<{
      address: string
      cep: string
      state: string
      number: string
      city: string
    }>({
      address: '',
      cep: '',
      state: '',
      number: '',
      city: '',
    })

    const viaCep = ref<AddressTypeReturn>({
      cep: '',
      logradouro: '',
      complemento: '',
      bairro: '',
      localidade: '',
      uf: '',
      ibge: '',
      gia: '',
      ddd: '',
      siafi: '',
    })

    const isLastTab = computed(() => tabs.value === 'schoolSettings')

    const v$ = useVuelidate(firstTabRules(isEdit.value), formData)
    const vAddress$ = useVuelidate(secondTabRules(isEdit.value), formAddress)
    const subjectList = ref<Array<string> | undefined>([])
    const isSelected = ref(false)
    const loadSubjects = ref(true)
    const backButtonLabel = computed(() => (tabs.value === 'schoolData' ? 'Cancelar' : 'Voltar'))

    onMounted(async () => {
      if (innerWidth.value <= 768) {
        width.value = '96%'
        heightModal.value = '82%'
      } else if (innerWidth.value <= 1500) {
        heightModal.value = '85%'
      }

      if (isEdit.value || props.isViewRegisterSchool) {
        await fetchInstitutionData(props.institutionId)
        listUserInstitution(currentPage.value, pageSize)
      }
      getSubjects()

      await fetchInstitutionPlans()

      const { data, statesUf } = await getStates(controller)
      stateOptions.value = statesUf
      statesInfos.value = data
    })

    const changeStepper = async () => {
      const result = await v$.value.$validate()
      const resultAddress = await vAddress$.value.$validate()

      if (tabs.value === 'schoolData' && result) {
        tabs.value = getNextTab()
      } else if (tabs.value === 'address' && resultAddress) {
        tabs.value = getNextTab()
      } else if (tabs.value === 'subjects') {
        tabs.value = getNextTab()
      }
    }

    const backButtonStepper = () => {
      if (tabs.value === 'schoolData') {
        modalState.value = false
      } else {
        tabs.value = getPreviousTab()
      }
    }

    const getNextTab = () => {
      const tabOrder = ['schoolData', 'address', 'subjects', 'schoolSettings']
      const currentTabIndex = tabOrder.indexOf(tabs.value)
      return tabOrder[currentTabIndex + 1]
    }

    const getPreviousTab = () => {
      const tabOrder = ['schoolData', 'address', 'subjects', 'schoolSettings']
      const currentTabIndex = tabOrder.indexOf(tabs.value)
      return tabOrder[currentTabIndex - 1]
    }

    const handleClick = (subject: string) => {
      if (subjectList.value?.includes(subject)) {
        const findSubject = subjectList.value.indexOf(subject)

        if (findSubject !== -1) {
          subjectList.value.splice(findSubject, 1)
        }
      } else {
        subjectList.value?.push(subject)
      }
    }

    const notification = (sucess: boolean, message: string) => {
      if (sucess) {
        $q.notify({
          textColor: 'grey-1',
          message: message,
          color: 'green',
          position: 'top',
          classes: 'notify',
        })
      } else {
        $q.notify({
          textColor: 'grey-1',
          message: message,
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    const listUserInstitution = async (page: number, pageSize: number) => {
      const reqBody = {
        page: page,
        pageSize: pageSize,
        filter: {
          institution: props.institutionId,
        },
        search: {
          roleDescription: 'Diretor',
          name: searchValue.value !== '' ? searchValue.value : undefined,
        },
      }

      try {
        const response = await usersService.post<{ data: { entities: User[]; totalElements: number } }>('/filter-users', reqBody)
        listUserByInstitution.value = response.data.data.entities
        totalElements.value = response.data.data.totalElements

        const newUserFormatted = listUserByInstitution.value?.map((it: User) => ({
          name: `${it.name} ${it.lastName !== null ? it.lastName : ''}`,
          id: it.id,
          email: it.email,
        }))

        if (page === 1) {
          optionsRoots.value = newUserFormatted
        } else {
          optionsRoots.value = [...optionsRoots.value, ...newUserFormatted]
        }
      } catch (error) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao buscar dados',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    const updateCitiesOptions = async (ufId: number) => {
      citiesOptions.value = await getDistricts(ufId?.toString())
    }

    const debouncedGetInstitutionUsers = debounce(listUserInstitution, 500)

    const handleSearch = (value: any) => {
      searchValue.value = value
      currentPage.value = 1
      debouncedGetInstitutionUsers(currentPage.value, pageSize)
    }

    const lastIndexScroll = (event: number) => {
      scrollValue.value = event
    }

    let previousValueOfCep = ''
    const handleLocationStateChange = async () => {
      if (statesInfos.value.length > 0 && userLocationState.value.state![0] !== '') {
        const uf = statesInfos.value.filter((item) => item.sigla === userLocationState.value.state![0])
        if (ufAux.value !== uf[0]?.sigla) {
          userInfosAux.value.city = ['']
          formAddress.value.address = ''
          formAddress.value.cep = ''
          formAddress.value.state = uf[0] ? uf[0].sigla : ''
          citiesOptions.value = await getDistricts(uf[0].id.toString())
        }
      }
    }

    const handleCepChange = async () => {
      if (formAddress.value.cep?.length === 8) {
        previousValueOfCep = formAddress.value.cep
        viaCep.value = await getAddressByCep(formAddress.value.cep.replace('-', ''))
        formAddress.value.address = viaCep.value.logradouro !== '' ? viaCep.value.logradouro : String(formDataAux.value?.address)
        userInfosAux.value.city = [viaCep.value.localidade]
        userLocationState.value.state = [viaCep.value.uf]
        formAddress.value.state = userLocationState.value.state[0]
        ufAux.value = userLocationState.value.state[0]
        update.value++
      }
    }

    const validateForm = async () => {
      const result = await v$.value.$validate()
      return result
    }

    const validateFormAddress = async () => {
      const result = await vAddress$.value.$validate()
      return result
    }

    const getSubjects = async () => {
      try {
        const res = await questionsService.get<ResponseDTO<QuestionTagsDTO>>('/tags')
        optionsSubject.value = res.data.data.subjects
      } catch (error) {
        console.error(error)
      } finally {
        loadSubjects.value = false
      }
    }

    const fetchInstitutionData = async (institutionId: number) => {
      isFetchData.value = true

      try {
        const { data: infos } = await groupsService.get<ResponseDTO<CreateListSchoolsDTO>>('/institutions/single', { params: { id: institutionId } })
        formDataAux.value = infos.data

        formData.value.id = infos.data.id || 0
        formData.value.name = infos.data.name || ''
        formData.value.corporateName = infos.data.corporateName || ''
        formData.value.telephone = formatPhone(infos.data.phone !== null ? infos.data.phone : '')
        formData.value.email = infos.data.institutionManager?.email || ''
        formData.value.cnpj = formatCnpj(infos.data.cnpj !== null ? infos.data.cnpj : '')
        formData.value.managerNameSelect = [
          infos.data.institutionManager
            ? {
                name: `${infos.data.institutionManager?.name || ''} ${infos.data.institutionManager?.lastName !== null ? infos.data.institutionManager?.lastName : ''}` || '',
                id: infos.data.institutionManager?.id || '',
                email: infos.data.institutionManager?.email || '',
              }
            : { name: '', id: '', email: '' },
        ]
        formData.value.managerName = infos.data.institutionManager?.name || ''
        formData.value.totalStudent = String(infos.data.studentsQuota)
        formAddress.value.address = infos.data.address || ''
        formAddress.value.cep = formatCep(String(infos.data.cep !== null ? infos.data.cep : ''))
        userLocationState.value.state = infos.data.state ? [infos.data.state] : undefined
        formAddress.value.number = String(infos.data.number)
        userInfosAux.value.city = infos.data.city ? [infos.data.city] : undefined
        userInstitutionId.value = infos.data.institutionManager?.id || undefined
        subjectList.value = infos.data.subjects ?? undefined
        formData.value.externalId = infos.data?.externalId ?? undefined
        institutionHasInstitutionPlan.value = infos.data.institutionHasInstitutionPlan
        selectedInstitutionPlan.value = infos.data.institutionHasInstitutionPlan?.institutionPlan ?? undefined
        update.value++
      } catch (error) {
        console.error(error)
        notification(false, 'Erro ao buscar dados')
      } finally {
        isFetchData.value = false
      }
    }

    const fetchInstitutionPlans = async () => {
      try {
        const response = await groupsService.get<ResponseDTO<EntitiesInstitutionPlans>>('/institution_plans')
        institutionPlans.value = response.data.data.entities

        institutionPlans.value.forEach((item) => {
          item.selected = false;
        })
      } catch (error) {
        console.error(error)
        notification(false, 'Erro ao buscar os planos disponíveis')
      }
    }

    const saveResourceInstitution = async () => {
      const defaultRole = 'Diretor'
      formAddress.value.city = String(userInfosAux.value.city)
      formAddress.value.state = String(userLocationState.value.state)

      if (previousValueOfCep) formAddress.value.cep = previousValueOfCep

      const result = await v$.value.$validate()
      const resultAddress = await vAddress$.value.$validate()

      if (result && resultAddress) {
        isLoading.value = true

        try {
          if (props.isEditingSchool) {
            const institutionData = new CreateListSchoolsDTO(
              formData.value.name,
              formData.value.corporateName,
              formData.value.cnpj,
              formData.value.telephone,
              formData.value.totalStudent,
              formAddress.value.cep,
              formAddress.value.address,
              formAddress.value.city,
              formAddress.value.state,
              formAddress.value.number,
              subjectList.value,
              undefined,
              formData.value.managerNameSelect[0].id,
              institutionHasInstitutionPlan.value,
              formData.value.externalId,
              formData.value.id,
            )
            await groupsService.patch('/institutions', institutionData)
          } else {
            const institutionData = new CreateListSchoolsDTO(
              formData.value.name,
              formData.value.corporateName,
              formData.value.cnpj,
              formData.value.telephone,
              formData.value.totalStudent,
              formAddress.value.cep,
              formAddress.value.address,
              formAddress.value.city,
              formAddress.value.state,
              formAddress.value.number,
              subjectList.value,
              undefined,
              undefined,
              institutionHasInstitutionPlan.value,
              formData.value.externalId,
            )
            const response = await groupsService.post('/institutions', institutionData)
            institutionIdCreated.value = response.data.data
            const data = new PlanSchoolCreateUserDTO(formData.value.managerName, formData.value.email, defaultRole, undefined, institutionIdCreated.value)
            await usersService.post('/pre-registration', data)
          }

          notification(true, 'Dados salvos com sucesso')
        } catch (error) {
          console.error(error)
          notification(false, 'Erro ao salvar dados')
        } finally {
          props.listInstitution?.()
          isLoading.value = false
          modalState.value = false
        }
      }
    }

    const institutionPlans = ref<InstitutionPlans[]>();
    const selectedInstitutionPlan = ref<InstitutionPlans>();

    const handlePlanSelection = (option: InstitutionPlans) => {
      selectedInstitutionPlan.value = option

      institutionHasInstitutionPlan.value = {
        institutionId: formData.value.id || institutionIdCreated.value,
        institutionPlan: option,
        institutionPlanId: option.id
      };
    }

    onUnmounted(() => window.removeEventListener('resize', setNewTab))

    watchEffect(async () => {
      if (userLocationState.value.state) {
        if (userLocationState.value.state.length > 0 && statesInfos.value.length > 0 && cacheUF !== userLocationState.value.state[0]) {
          cacheUF = userLocationState.value.state[0]

          const uf = statesInfos.value.filter((item) => item.sigla === userLocationState.value.state![0])
          await updateCitiesOptions(uf[0]?.id)
        }
      }
    })

    watch(tabs, () => {
      if (tabs.value === 'schoolData') {
        labelButtonBack.value = 'Cancelar'
      } else {
        labelButtonBack.value = 'Voltar'
      }
    })

    watch(userLocationState, handleLocationStateChange, { deep: true })

    watch(() => formAddress.value.cep, handleCepChange)

    watch(
      () => modalState.value,
      () => emit('update:visibility', modalState.value)
    )

    watch(
      userInfosAux,
      () => {
        formAddress.value.city = userInfosAux.value.city![0].toString()
      },
      { deep: true }
    )

    watch(
      formData.value,
      () => {
        if (props.isEditingSchool) formData.value.email = formData.value.managerNameSelect[0].email
      },
      { deep: true }
    )

    return {
      visibleModal,
      optionsOfficeSchool,
      v$,
      vAddress$,
      formData,
      formAddress,
      isLoading,
      isFetchData,
      optionsRoots,
      stateOptions,
      citiesOptions,
      userLocationState,
      userInfosAux,
      update,
      tabs,
      isLastTab,
      width,
      backButtonLabel,
      subject,
      optionsSubject,
      subjectList,
      heightModal,
      isSelected,
      loadSubjects,
      modalState,
      handleSearch,
      handleClick,
      lastIndexScroll,
      validateForm,
      validateFormAddress,
      saveResourceInstitution,
      changeStepper,
      backButtonStepper,
      institutionPlans,
      selectedInstitutionPlan,
      handlePlanSelection,
      institutionHasInstitutionPlan,
    }
  },
})
