
import DefaultTheme from '@/theme/defaultTheme';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'EnemModuleBookClosedIcon',
  props: {
    size: { type: Number, default: 20 },
    stroke: { type: String, default: DefaultTheme().colors.primaryColor },
  },
})
