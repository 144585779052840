import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flex justify-center items-center",
  style: {"width":"100vw","height":"100vh"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_circular_progress = _resolveComponent("q-circular-progress")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_circular_progress, {
      indeterminate: "",
      size: "70px",
      color: "secondary"
    })
  ]))
}