
import { defineComponent, onMounted, onUnmounted, onUpdated, PropType, ref, watch } from 'vue';

export default defineComponent({
  props: {
    options: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    tabindex: {
      type: Number,
      required: false,
      default: -1,
    },
    readonly: {
      type: Boolean,
    },
    name: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
    },
    rounded: Boolean,
    placeholder: String,
    error: Boolean,
    errorMessage: String,
    valueSelectedDefault: Boolean,
    topic: String,
    valuePrimary: Number,
    itemsSelected: {
      type: Array as PropType<Array<string>>,
    },
  },
  setup(props, context) {
    const selected = ref('')
    const topic = ref(props.topic)
    const filtered = ref<string>('')
    const arraySelected = ref<Array<string> | undefined>([])

    onUpdated(() => {
      arraySelected.value = props.itemsSelected
    })

    watch(
      arraySelected,
      () => {
        arraySelected.value = props.itemsSelected
      },
      { deep: true }
    )

    if (props.valueSelectedDefault) {
      selected.value = props.valuePrimary ? props.valuePrimary.toString() : (props.options[props.options.length - 1] as string)
    }
    const open = ref(false)
    const handleClick = (option: string, name: string) => {
      selected.value = option
      open.value = false
      context.emit('input', option, name, props.topic)
    }

    const handleClose = (event: Event) => {
      if (props.multiple)
        if (event.target instanceof HTMLInputElement) {
          if (event.target.id !== 'input-search') open.value = false
        } else {
          open.value = false
        }
    }
    document.addEventListener('click', handleClose)

    const handleMultipleItems = (option: string, name: string) => {
      context.emit('input', option, name)
    }

    onMounted(() => {
      if (props.valueSelectedDefault) {
        selected.value = props.valuePrimary ? props.valuePrimary.toString() : (props.options[props.options.length - 1] as string)
      }

      context.emit('input', props.options[0])
      context.emit('getOnMounted', props.valuePrimary ? props.valuePrimary : props.options[props.options.length - 1], topic.value)
    })

    onUnmounted(() => {
      document.removeEventListener('click', handleClose)
    })

    const inputValidation = ref(false)
    watch(selected, () => {
      if (!selected.value) {
        inputValidation.value = true
      } else {
        inputValidation.value = false
      }
    })

    const handleClickMultiple = () => {
      if (open.value !== true) {
        let timeout: number
        timeout = setTimeout(() => {
          open.value = !open.value
          clearTimeout(timeout)
        }, 10)
      }
    }

    return { filtered, handleClickMultiple, inputValidation, selected, handleClick, handleMultipleItems, open, arraySelected }
  },
})
