import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1db84392"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["tabindex"]
const _hoisted_2 = ["value", "disabled", "placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "item input"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 1,
  class: "item single virtual-scroll"
}
const _hoisted_6 = ["onMousedown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_virtual_scroll = _resolveComponent("q-virtual-scroll")!

  return (_openBlock(), _createElementBlock("div", {
    class: "custom-select",
    tabindex: _ctx.tabindex
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({
        open: _ctx.open,
        rounded: _ctx.rounded,
        'rounded-simple': _ctx.roundedSimple,
        disabled: _ctx.disabled,
        'mobile-items-input': _ctx.tableMobile,
        selected: !_ctx.liteStyle,
        'selected-lite-style': _ctx.liteStyle,
      }),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.mouseDown && _ctx.mouseDown(...args)))
    }, [
      _createElementVNode("input", {
        class: "input-select",
        id: "input-main",
        type: "text",
        value: _ctx.inputShow,
        disabled: _ctx.disabled,
        readonly: "",
        placeholder: _ctx.placeholder
      }, null, 8, _hoisted_2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["items", { selectHide: !_ctx.open, checkout: _ctx.checkout, 'items-up': _ctx.moveUp, 'mobile-items': _ctx.tableMobile }])
    }, [
      (!_ctx.notSearchInput)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _withDirectives(_createElementVNode("input", {
              ref: "inputSearch",
              id: "input-search",
              autocomplete: "off",
              disabled: _ctx.disabled,
              placeholder: "Buscar",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filtered) = $event)),
              class: "text-gray input-search",
              style: {"background":"transparent"},
              onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.open = false)),
              onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.open = true))
            }, null, 40, _hoisted_4), [
              [_vModelText, _ctx.filtered]
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.reports)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_q_virtual_scroll, {
              class: "scroll item",
              items: _ctx.reportsOptions,
              style: {"max-height":"160px"},
              onVirtualScroll: _ctx.handleScroll
            }, {
              default: _withCtx(({ item, index }) => [
                (_openBlock(), _createBlock(_component_q_item, {
                  key: index,
                  onMousedown: ($event: any) => (_ctx.handleClickWithObject(item?.name, item?.id)),
                  style: {"min-height":"1em"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1032, ["onMousedown"]))
              ]),
              _: 1
            }, 8, ["items", "onVirtualScroll"])
          ]))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.optionsComputed, (option, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass({ 'item-mobile': _ctx.tableMobile, 'item single': !_ctx.tableMobile, 'mobile-select-modal': _ctx.mobileRegisterModal }),
              key: i,
              onMousedown: ($event: any) => (_ctx.handleClick(option))
            }, _toDisplayString(option), 43, _hoisted_6))
          }), 128))
    ], 2),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["error", { checkout: _ctx.checkout }])
        }, _toDisplayString(_ctx.errorMessage), 3))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}