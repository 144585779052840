
import LoadingSpinner from '@/components/Generics/LoadingSpinner/LoadingSpinner.vue';
import DefaultTheme from '@/theme/defaultTheme';
import { computed, defineComponent } from 'vue';

const availableButtonVariants = [
  'primary', 'primary-light', 'secondary', 'success', 'warning', 'error', 'outline', 'anchor'
];
const availableButtonType = ['button', 'submit'];
const availableJustifyContent = ['start', 'center', 'end'];

export default defineComponent({
  name: 'ActionButton',
  components: { LoadingSpinner },
  props: {
    type: { type: String, default: 'button', validator: (value: string) => availableButtonType.includes(value) },
    variant: { type: String, default: 'primary', validator: (value: string) => availableButtonVariants.includes(value) },
    fontSize: { type: Number, default: 16 },
    fontWeight: { type: Number, default: 600 },
    fullWidth: { type: Boolean, default: false },
    justifyContent: { type: String, default: 'center', validator: (value: string) => availableJustifyContent.includes(value) },
    label: { type: String },
    iconLeft: [Object, Function], 
    iconRight: [Object, Function], 
    alt: { type: String, required: true },
    classes: { type: String },
    disabled: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    iconFill: { type: String, default: DefaultTheme().colors.primaryColor },
    iconStroke: { type: String, default: DefaultTheme().colors.primaryColor },
    iconSize: { type: [String, Number], default: 24 },
  },
  setup(props) {
    const iconProps = computed(() => ({
      fill: props.iconFill,
      stroke: props.iconStroke,
      size: props.iconSize,
    }));

    return { iconProps };
  }
});
