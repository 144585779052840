import axios from 'axios'

export class CustomObjectSelected {
  nome: string
  isSelected: boolean

  constructor(nome: string, isSelected: boolean) {
    this.nome = nome
    this.isSelected = isSelected
  }
}

class ApiTypes {
  id: number
  sigla: string
  nome: string
  regiao: {
    id: number
    sigla: string
    nome: string
  }

  constructor(nome: string, id: number, sigla: string, regiao: { id: number; sigla: string; nome: string }) {
    this.id = id
    this.sigla = sigla
    this.regiao = regiao
    this.nome = nome
  }
}

export class ApiDataStates {
  id: number
  nome: string
  sigla: string

  constructor(id: number, nome: string, sigla: string) {
    this.id = id
    this.nome = nome
    this.sigla = sigla
  }
}

const getStates = async (controller?: AbortController): Promise<{ statesName: string[]; data: Array<ApiDataStates>; statesUf: string[] }> => {
  const { data } = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/', { signal: controller?.signal })

  const statesName = data.map((item: ApiTypes) => item.nome).sort()
  const statesUf = data.map((item: ApiTypes) => item.sigla).sort()
  return { statesName, statesUf, data }
}

export const getDistricts = async (uf: string) => {
  const { data } = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`)

  const citiesName = data.map((item: any) => item.nome).sort((a: string, b: string) => a.localeCompare(b))
  return citiesName
}

export default getStates
