import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, KeepAlive as _KeepAlive, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a106c57a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "entrance-exam-container" }
const _hoisted_2 = { class: "entrance-exam-actions" }
const _hoisted_3 = {
  key: 0,
  class: "size-container"
}
const _hoisted_4 = {
  key: 1,
  class: "entrance-exam-steps"
}
const _hoisted_5 = { class: "entrance-exam-stepper entrance-exam-stepper-margin-top" }
const _hoisted_6 = { class: "entrance-exam-stepper entrance-exam-stepper-height-fixed" }
const _hoisted_7 = { class: "entrance-exam-institution-component" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 0,
  class: "no-lists-found"
}
const _hoisted_10 = { class: "entrance-exam-button-wrapper" }
const _hoisted_11 = { class: "entrance-exam-one-button" }
const _hoisted_12 = { class: "entrance-exam-one-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentModalAddReceivers = _resolveComponent("ContentModalAddReceivers")!
  const _component_Steps = _resolveComponent("Steps")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isAddReceivers)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_ContentModalAddReceivers, {
              isPageListQuestions: "",
              onSendDateTime: _ctx.dateTimeHandler,
              onShowOptionSeeFeedback: _ctx.showOptionSeeFeedback,
              showFeedback: ""
            }, null, 8, ["onSendDateTime", "onShowOptionSeeFeedback"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_Steps, {
                titles: _ctx.titles,
                onChangeStepsTo: _ctx.changeStepsTo
              }, null, 8, ["titles", "onChangeStepsTo"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_Steps, {
                selected: "",
                choices: _ctx.choices
              }, null, 8, ["choices"])
            ])
          ]))
    ]),
    _createElementVNode("div", _hoisted_7, [
      (!_ctx.isAddReceivers)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_openBlock(), _createBlock(_KeepAlive, null, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.viewComponent), {
                choices: _ctx.choices,
                region: _ctx.tags.region,
                source: _ctx.tags.source,
                state: _ctx.tags.state,
                year: _ctx.tags.year,
                onGetSelection: _ctx.getOptionSelected
              }, null, 40, ["choices", "region", "source", "state", "year", "onGetSelection"]))
            ], 1024)),
            (_ctx.filtersNotFound)
              ? (_openBlock(), _createElementBlock("p", _hoisted_9, "Ops, não encontramos nenhuma prova com esses filtros"))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_Button, {
          label: _ctx.handleLabelButton(_ctx.viewComponent).secondary,
          variant: "secondary-bold",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isLoading ? null : _ctx.handleSecondaryButtonAction(_ctx.viewComponent))),
          height: "100%",
          width: "100%",
          disabled: _ctx.isLoading
        }, null, 8, ["label", "disabled"])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_Button, {
          label: _ctx.handleLabelButton(_ctx.viewComponent).primary,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isAddReceivers ? _ctx.handleCreateList() : _ctx.blockButton || _ctx.isLoading ? null : _ctx.handlePrimaryButtonAction(_ctx.viewComponent))),
          height: "100%",
          width: "100%",
          loading: _ctx.isLoading,
          disabled: _ctx.blockButton,
          variant: "primary-bold"
        }, null, 8, ["label", "loading", "disabled"])
      ])
    ])
  ]))
}