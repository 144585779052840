import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a3479c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-container" }
const _hoisted_2 = { class: "cta-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XCloseIcon = _resolveComponent("XCloseIcon")!
  const _component_PazzeiButton = _resolveComponent("PazzeiButton")!

  return (_ctx.displayCard)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass({
      'card-container': true,
      'warning': _ctx.trialExpiresIn <= 0 ? true : false
    })
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h5", null, _toDisplayString(_ctx.heading), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.text), 1)
        ]),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.isClosable)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "close-card",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cardHasBeenClosed && _ctx.cardHasBeenClosed(...args)))
              }, [
                _createVNode(_component_XCloseIcon, {
                  stroke: _ctx.DefaultTheme().colors.secondaryGray
                }, null, 8, ["stroke"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.totalCredits !== 0)
            ? (_openBlock(), _createBlock(_component_PazzeiButton, {
                key: 1,
                classes: _ctx.context.classes,
                text: _ctx.context.text,
                alt: _ctx.context.text,
                style: 'width: fit-content;',
                "text-classes": { text: 'text-14px', fontWeight: 'font-weight-600'},
                onClick: _ctx.checkout
              }, null, 8, ["classes", "text", "alt", "onClick"]))
            : _createCommentVNode("", true)
        ])
      ], 2))
    : _createCommentVNode("", true)
}