
import LoginLayout from '@/components/Layouts/LoginLayout.vue';
import RegistrationForm from '@/components/LoginForms/RegistrationForm.vue';
import { productName } from '@/theme/defaultTheme';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RegisterPage',
  components: { LoginLayout, RegistrationForm },
  setup() {
    const title = productName();

    return {
      title,
    }
  },
})
