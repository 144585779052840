import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PazzeiModalDoodle = _resolveComponent("PazzeiModalDoodle")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#portal-root" }, [
    (_ctx.displayModal)
      ? (_openBlock(), _createBlock(_component_PazzeiModalDoodle, {
          key: 0,
          context: _ctx.context,
          name: "ModalNoAccess",
          heading: "Ops, essa área é exclusiva",
          description: "Parece que você não tem acesso a essa área no momento. Assine ou renove sua assinatura para liberar todos os conteúdos e recursos do Pazzei.",
          confirmButtonText: "Ver planos disponíveis",
          confirmButtonVariant: "primary",
          cancelButtonText: "Voltar",
          onOnConfirm: _ctx.confirm,
          onOnCancel: _ctx.cancel,
          onOnClose: _ctx.close
        }, null, 8, ["context", "onOnConfirm", "onOnCancel", "onOnClose"]))
      : _createCommentVNode("", true)
  ]))
}