
import ContentModalBillingInformation from '@/components/Application/planSchoolPortal/modals/modalsUsersComponent/modalsPurchaseHistory/ContentModalBillingInformation.vue'
import ContentModalRefoundPayment from '@/components/Application/planSchoolPortal/modals/modalsUsersComponent/modalsPurchaseHistory/ContentModalRefundPayment.vue'
import TablesComponentHistory from '@/components/Application/planSchoolPortal/mountTableComponent/TablesComponent.vue'
import PaginationTableComponent from '@/components/Application/planSchoolPortal/mountTableComponent/components/PaginationTableComponent.vue'
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import ArrowBackIcon from '@/components/Icons/default/ArrowBackIcon.vue'
import EditRegisterIcon from '@/components/Icons/default/EditRegisterIcon.vue'
import ExportcsvIcon from '@/components/Icons/default/ExportcsvIcon.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import Modal from '@/components/Library/Modal/index.vue'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import paymentsService from '@/services/payment'
import usersService from '@/services/users'
import DefaultTheme from '@/theme/defaultTheme'
import { EntitiesUserHistoric, UserHistoric } from '@/types/UsersHistoricTypes'
import { formatCurrency } from '@/utils/common/formatCurrency'
import { columnsHistoric } from '@/utils/hardCoded/planSchool/users/usersAdmin'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, onMounted, provide, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'ViewPurchaseHistory',
  components: { GenericLayout, MainLayoutWrapper, PazzeiBtn, TablesComponentHistory, PaginationTableComponent, ContentModalBillingInformation, ContentModalRefoundPayment, Modal, ArrowBackIcon },
  setup() {
    const isSearchInputExpanded = ref(true)
    const sizeColumnHistoric = ref('1fr / 2.7fr 1.1fr 1.4fr 1.1fr 1.1fr 1.5fr 1.1fr 0.25fr')
    const router = useRouter()
    const route = useRoute()
    const $q = useQuasar()
    const rowsPurchasesHistoric = ref<UserHistoric[]>([])
    const previousPageSize = ref(20)
    const previousCurrentPage = ref(1)
    const currentPage = ref(1)
    const userId = String(route.params.id)
    const billingId = ref(-1)
    const billingPrice = ref('')
    const pageSize = ref(20)
    const totalElements = ref(0)
    const isLoading = ref(false)
    const sizeLoading = ref('60px')
    const heightLoading = ref('6.7em')
    const heightTable = ref('49em')
    const widthTable = ref('100%')
    const height = ref('46.5em')
    const width = ref('38em')
    const heightRefund = ref('26.5em')
    const widthRefund = ref('38em')
    const userName = ref('')
    const innerWidth = ref(window.innerWidth)
    const rows = ref<UserHistoric[]>([])
    const isModalOpen = ref(false)
    const openModalInfos = () => {
      isModalOpen.value = true
    }
    const isModalRefundOpen = ref(false)
    const openModalRefundPayment = () => (isModalRefundOpen.value = true)
    const closeModalRefundPayment = () => (isModalRefundOpen.value = false)

    const setNewTab = () => {
      innerWidth.value = window.innerWidth
    }
    window.addEventListener('resize', setNewTab)

    const handlePageSizeChange = (selectedPageSize: number) => {
      if (selectedPageSize !== previousPageSize.value) {
        pageSize.value = selectedPageSize
        getPurchasesHistory()
        previousPageSize.value = selectedPageSize
      }
    }

    const handleCurrentPage = (selectedCurrentPage: number) => {
      if (selectedCurrentPage !== previousCurrentPage.value) {
        currentPage.value = selectedCurrentPage
        getPurchasesHistory()
        previousCurrentPage.value = selectedCurrentPage
      }
    }

    const fetchNameUser = async () => {
      const { data: infos } = await usersService.get(`/${userId}`)
      userName.value = `${infos.data.name} ${infos.data.lastName !== null ? infos.data.lastName : ''}`
    }

    const getPurchasesHistory = async () => {
      const data = {
        userId: userId,
        page: currentPage.value,
        pageSize: pageSize.value,
      }

      isLoading.value = true
      try {
        const { data: infos } = await paymentsService.post<ResponseDTO<EntitiesUserHistoric>>('/list-billings', data)
        rows.value = infos.data.data
        rowsPurchasesHistoric.value = rows.value.map((it: UserHistoric) => {
          return {
            id: it.id,
            holderName: it.holderName,
            recurrence: it.plan ? it.plan.recurrence : '',
            paymentMethod: it.paymentMethod ?? '',
            price: it.price || it.price === 0 ? formatCurrency(Number(it.price)) : '',
            createdAt: it.createdAt ?? '',
            status: translateBillingStatus(it.status) ?? '',
            reversedAmount: it.reversedAmount || it.reversedAmount === 0 ? formatCurrency(Number(it.reversedAmount)) : '',
          }
        })
        totalElements.value = infos.data.totalElements
      } catch (error) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao buscar dados',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
        console.error(error)
      } finally {
        isLoading.value = false
      }
    }

    const translateBillingStatus = (status: string): string => {
      switch (status) {
        case 'paid':
          return 'Pago'
        case 'pending':
          return 'Pendente'
        case 'rejected':
          return 'Recusado'
        case 'refunded':
          return 'Estornado'
        case 'pending_refund':
          return 'Estorno pendente'
        case 'partially_refunded':
          return 'Parcialmente Estornado'
        default:
          return ''
      }
    }

    const downloadCsvFile = async () => {
      try {
        const documentBodyRef = ref<HTMLElement | undefined>(document?.body);

        if (documentBodyRef.value) {
          const response = await paymentsService.post<ResponseDTO<any>>('/csv', { userId })
          const fileData = decodeURIComponent(escape(window.atob(response.data.data)))
          const blob = new Blob([fileData], { type: 'text/csv;charset=utf-8;' })
          const link = document.createElement('a')
          const url = URL.createObjectURL(blob);

          link.setAttribute('href', url)
          link.setAttribute('download', 'Histórico-de-compras.csv')
          link.style.visibility = 'hidden'
          documentBodyRef.value?.appendChild?.(link);
          link.click()
          documentBodyRef.value.removeChild?.(link);
        }
      } catch (error) {
        console.error(error)
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao exportar CSV',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    const handleValueUpdated = (value: UserHistoric) => {
      billingId.value = value.id

      if (!value.price && !value.reversedAmount) return

      const formattedPrice = String(value.price).split(' ')[1].replace(',', '.')
      const formattedReversedAmount = value.reversedAmount !== '' ? String(value.reversedAmount).split(' ')[1].replace(',', '.') : '0.00'

      if (formattedReversedAmount !== '0.00') {
        billingPrice.value = formatCurrency(parseFloat(formattedPrice) - parseFloat(formattedReversedAmount))
      } else {
        billingPrice.value = formatCurrency(parseFloat(formattedPrice))
      }

      if (value.paymentMethod === 'Pix' || value.status === 'Estornado' || value.status === 'Recusado' || value.status === 'Estorno pendente' || value.status === 'Pendente') {
        optionsMenu.value[1].disabled = true
      } else {
        optionsMenu.value[1].disabled = false
      }
    }

    onMounted(() => {
      if (innerWidth.value <= 768) {
        heightTable.value = '62vh'
        sizeColumnHistoric.value = '1fr / 2.7fr 1.1fr 1.6fr 1.1fr 1.1fr 1.6fr 1.2fr 0.25fr'
        sizeLoading.value = '2.1875em'
        widthTable.value = '70em'
        width.value = '90%'
        height.value = '32em'
        heightRefund.value = '17em'
        heightLoading.value = '8em'
      }

      if (innerWidth.value <= 1500 && innerWidth.value > 768) {
        width.value = '33em'
        height.value = '40em'
      }

      fetchNameUser()
      getPurchasesHistory()
    })

    const iconProps = {
      fill: DefaultTheme().colors.pinkColor,
      stroke: DefaultTheme().colors.pinkColor,
      size: 20,
    }

    const optionsMenu = ref([
      { label: 'Informações da cobrança', icon: EditRegisterIcon, iconProps, action: () => openModalInfos() },
      {
        label: 'Estornar Pagamento',
        icon: EditRegisterIcon,
        iconProps,
        action: () => openModalRefundPayment(),
        disabled: false,
      },
    ])

    provide('emitValueToParent', handleValueUpdated)
    provide('optionsMenu', optionsMenu)
    provide('heightLoading', heightLoading)

    return {
      handlePageSizeChange,
      handleCurrentPage,
      downloadCsvFile,
      getPurchasesHistory,
      closeModalRefundPayment,
      isSearchInputExpanded,
      innerWidth,
      sizeColumnHistoric,
      router,
      totalElements,
      columnsHistoric,
      isLoading,
      sizeLoading,
      heightTable,
      rowsPurchasesHistoric,
      userName,
      isModalOpen,
      width,
      height,
      widthTable,
      billingId,
      billingPrice,
      isModalRefundOpen,
      heightRefund,
      widthRefund,
      ExportcsvIcon,
      DefaultTheme,
    }
  },
})
