import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f35f6a7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container flex row items-center" }
const _hoisted_2 = ["checked"]
const _hoisted_3 = { class: "checkmark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckIcon = _resolveComponent("CheckIcon")!

  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("input", {
      type: "checkbox",
      checked: _ctx.isChecked
    }, null, 8, _hoisted_2),
    _createElementVNode("span", _hoisted_3, [
      (_ctx.isChecked)
        ? (_openBlock(), _createBlock(_component_CheckIcon, {
            key: 0,
            stroke: _ctx.DefaultTheme().colors.success400
          }, null, 8, ["stroke"]))
        : _createCommentVNode("", true)
    ]),
    _createTextVNode(" " + _toDisplayString(_ctx.labelText), 1)
  ]))
}