import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2904e79b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-body-row" }
const _hoisted_2 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_PazzeiSimpleSelect = _resolveComponent("PazzeiSimpleSelect")!
  const _component_CloseFilterIcon = _resolveComponent("CloseFilterIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filtersData, (filter, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "table-col"
      }, [
        (filter.type === 'input')
          ? (_openBlock(), _createBlock(_component_Input, {
              key: 0,
              "max-length": filter.maxLength,
              variant: "semi-rounded",
              placeholder: filter.placeholder,
              fullWidthInput: "",
              width: "100%",
              modelValue: filter.value,
              "onUpdate:modelValue": ($event: any) => ((filter.value) = $event),
              modelModifiers: { "unmasked-value": true },
              mask: filter.key === 'telephone' || filter.key === 'phone' ? '(##) #####-####' : undefined
            }, null, 8, ["max-length", "placeholder", "modelValue", "onUpdate:modelValue", "mask"]))
          : (filter.type === 'date')
            ? (_openBlock(), _createBlock(_component_Input, {
                key: 1,
                "max-length": filter.maxLength,
                type: "date",
                variant: "semi-rounded",
                placeholder: filter.placeholder,
                fullWidthInput: "",
                width: "100%",
                modelValue: filter.value,
                "onUpdate:modelValue": ($event: any) => ((filter.value) = $event),
                mask: "##/##/####"
              }, null, 8, ["max-length", "placeholder", "modelValue", "onUpdate:modelValue"]))
            : (filter.type === 'checkbox')
              ? (_openBlock(), _createElementBlock("span", _hoisted_2))
              : (_openBlock(), _createBlock(_component_PazzeiSimpleSelect, {
                  key: 3,
                  name: filter.key,
                  options: filter.options,
                  placeholder: filter.placeholder,
                  "not-search-input": "",
                  modelValue: filter.value,
                  "onUpdate:modelValue": ($event: any) => ((filter.value) = $event)
                }, null, 8, ["name", "options", "placeholder", "modelValue", "onUpdate:modelValue"]))
      ]))
    }), 128)),
    _createElementVNode("div", {
      class: "table-col btn-close-filter",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openFilterSelected?.()))
    }, [
      _createVNode(_component_CloseFilterIcon, {
        size: 14,
        stroke: _ctx.DefaultTheme().colors.purple900
      }, null, 8, ["stroke"])
    ])
  ]))
}