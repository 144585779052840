import { InstitutionManager } from '@/types/InstitutionType'
import { InstitutionHasInstitutionPlanDTO } from '../InstitutionHasInstitutionPlanDTO/InstitutionHasInstitutionPlanDTO'

export class CreateListSchoolsDTO {
  name?: string
  corporateName?: string
  cnpj?: string
  phone?: string
  institutionManager?: InstitutionManager
  studentsQuota?: number
  cep?: string
  address?: string
  city?: string
  state?: string
  number?: number
  subjects?: Array<string>
  managerId?: string
  id?: number
  institutionHasInstitutionPlan?: InstitutionHasInstitutionPlanDTO
  externalId?: string

  constructor(
    name: string,
    corporateName: string,
    cnpj: string,
    phone: string,
    studentsQuota: string,
    cep?: string,
    address?: string,
    city?: string,
    state?: string,
    number?: string,
    subjects?: Array<string>,
    institutionManager?: InstitutionManager,
    managerId?: string,
    institutionHasInstitutionPlan?: InstitutionHasInstitutionPlanDTO,
    externalId?: string,
    id?: number,
  ) {
    this.name = name !== '' ? name : undefined
    this.corporateName = corporateName !== '' ? corporateName : undefined
    this.cnpj = cnpj !== '' ? cnpj : undefined
    this.phone = phone !== '' ? phone : undefined
    this.studentsQuota = studentsQuota !== '' ? Number(studentsQuota) : undefined
    this.cep = cep !== '' ? cep : undefined
    this.address = address !== '' ? address : undefined
    this.city = city !== '' ? city : undefined
    this.state = state !== '' ? state : undefined
    this.number = number !== '' ? Number(number) : undefined
    this.subjects = subjects ?? undefined
    this.institutionManager = institutionManager ?? undefined
    this.managerId = managerId !== '' ? managerId : undefined
    this.institutionHasInstitutionPlan = institutionHasInstitutionPlan
    this.externalId = externalId
    this.id = id
  }
}
