import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e35a438"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "pazzei-button-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`flex pazzei-button items-center default-text disable-selection pointer ${_ctx.classes} ${_ctx.disabled || _ctx.isLoading ? 'disabled' : ''}`)
  }, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.icon && !_ctx.iconRight)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), _mergeProps({
                key: 0,
                class: "icon"
              }, _ctx.iconProps), null, 16))
            : _createCommentVNode("", true),
          _createElementVNode("span", {
            class: _normalizeClass(`
          ${_ctx.textClasses['text']}
          ${_ctx.textClasses['fontWeight']}
          pazzei-button-text
        `)
          }, _toDisplayString(_ctx.text), 3),
          (_ctx.icon && _ctx.iconRight)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), _mergeProps({
                key: 1,
                class: "icon"
              }, _ctx.iconProps), null, 16))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 1 }))
      : _createCommentVNode("", true)
  ], 2))
}