
import EnemModuleAtom from '@/components/Icons/enemModuleAtomIcon.vue'
import EnemModuleBookClosed from '@/components/Icons/enemModuleBookClosedIcon.vue'
import EnemModuleCalculator from '@/components/Icons/enemModuleCalculatorIcon.vue'
import EnemModuleGlobe from '@/components/Icons/enemModuleGlobeIcon.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ColoredIcon',
  components: {
    EnemModuleAtom,
    EnemModuleBookClosed,
    EnemModuleCalculator,
    EnemModuleGlobe,
  },
  props: {
    alt: { type: String, default: '' },
    icon: { type: String, default: '' },
    size: { type: Number, default: 30 },
  },
  setup() {
    return {}
  },
})
