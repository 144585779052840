
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    actionLabel: String,
    icon: [Object, Function],
    iconFill: { type: String, default: 'currentColor' },
    iconStroke: { type: String, default: 'none' },
    iconSize: { type: [String, Number], default: 24 }
  },
  setup(props) {
    const showActionLabel = ref(false);

    const handleShowActionLabel = () => {
      showActionLabel.value = !showActionLabel.value;
    };

    const iconProps = computed(() => ({
      fill: props.iconFill,
      stroke: props.iconStroke,
      size: props.iconSize
    }));

    return {
      handleShowActionLabel,
      showActionLabel,
      iconProps
    };
  }
});
