export const millisecondsToTime = (milliseconds: number) => new Date(milliseconds).toISOString().slice(11, 19)

export const timeInWords = (milliseconds: number) => {
  const time = millisecondsToTime(milliseconds)

  const [hours, minutes, seconds] = time.split(':')

  const hoursInWords = hours === '00' ? '' : `${parseInt(hours)} hora${parseInt(hours) > 1 ? 's' : ''}`
  const minutesInWords = minutes === '00' ? '' : `${parseInt(minutes)} minuto${parseInt(minutes) > 1 ? 's' : ''}`
  const secondsInWords = seconds === '00' ? '' : `${parseInt(seconds)} segundo${parseInt(seconds) > 1 ? 's' : ''}`

  return hoursInWords || minutesInWords ? `${hoursInWords ? hoursInWords + ' e ' : ''} ${minutesInWords}`.trim() : secondsInWords || '0 segundos'
}
