import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-676dc85a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "disabled", "loading"]
const _hoisted_2 = { class: "span" }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_circular_progress = _resolveComponent("q-circular-progress")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([_ctx.handleClass(), "button-default"]),
    type: _ctx.submit ? 'submit' : 'button',
    disabled: _ctx.disabled,
    loading: _ctx.loading
  }, [
    _createElementVNode("span", _hoisted_2, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), _mergeProps({
            key: 0,
            class: ["icon", { span__collapsed: _ctx.btnCollapsed }]
          }, _ctx.iconProps), null, 16, ["class"]))
        : _createCommentVNode("", true),
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass(["span", { span__collapsed: _ctx.btnCollapsed, 'subject-list': _ctx.subjectList }])
          }, _toDisplayString(_ctx.label), 3))
        : _createCommentVNode("", true),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_q_circular_progress, {
              indeterminate: "",
              size: "20px",
              color: "yellow-12"
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ], 10, _hoisted_1))
}