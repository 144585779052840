import {
  FilterQuestionDivisionsResponseType,
  FilterQuestionsDivisionsType,
  FilterQuestionsItemsType,
  FilterQuestionsSubItemsType,
  FilterQuestionsTopicsType,
} from './FilterQuestionDivisionsResponseType'

export class SubjectTreeCollapse {
  name: string
  children: Array<SubjectTreeCollapseDivision>
  total: number
  totalDesiredQuestions: number
  maxQuestions: number
  childId: number
  fatherId: number
  grandfatherId: number
  greatGrandfatherId: number

  constructor(filterQuestionDivisionsResponseType: FilterQuestionDivisionsResponseType) {
    this.children = filterQuestionDivisionsResponseType.subjects.length
      ? filterQuestionDivisionsResponseType.subjects[0].divisions.map((division, idx) => new SubjectTreeCollapseDivision(division, idx, 0))
      : []
    this.total = filterQuestionDivisionsResponseType.subjects.length ? filterQuestionDivisionsResponseType.subjects[0].total : 0
    this.totalDesiredQuestions = 0
    this.maxQuestions = 0
    this.name = ''
    this.childId = -1
    this.fatherId = -1
    this.grandfatherId = -1
    this.greatGrandfatherId = -1
  }
}

export class SubjectTreeCollapseDivision {
  name: string
  maxQuestions: number
  totalDesiredQuestions: number
  children: Array<SubjectTreeCollapseTopics>
  isExpanded: boolean
  childId: number
  fatherId: number
  grandfatherId: number
  greatGrandfatherId: number

  constructor(filterQuestionsDivisionsType: FilterQuestionsDivisionsType, childId: number, fatherId: number) {
    this.name = filterQuestionsDivisionsType.name
    this.maxQuestions = filterQuestionsDivisionsType.total
    this.totalDesiredQuestions = 0
    this.children = filterQuestionsDivisionsType.topics.map((topic, idx) => new SubjectTreeCollapseTopics(topic, idx, childId, fatherId, -1))
    this.isExpanded = false
    this.childId = childId
    this.fatherId = fatherId
    this.grandfatherId = -1
    this.greatGrandfatherId = -1
  }
}

export class SubjectTreeCollapseTopics {
  name: string
  maxQuestions: number
  totalDesiredQuestions: number
  children: Array<SubjectTreeCollapseItems>
  isExpanded: boolean
  childId: number
  fatherId: number
  grandfatherId: number
  greatGrandfatherId: number

  constructor(filterQuestionsTopicsType: FilterQuestionsTopicsType, childId: number, fatherId: number, grandfatherId: number, greatGrandfatherId: number) {
    this.name = filterQuestionsTopicsType.name
    this.maxQuestions = filterQuestionsTopicsType.total
    this.totalDesiredQuestions = 0
    this.children = filterQuestionsTopicsType.items.map((item, idx) => new SubjectTreeCollapseItems(item, idx, childId, fatherId, grandfatherId))
    this.isExpanded = false
    this.childId = childId
    this.fatherId = fatherId
    this.grandfatherId = grandfatherId
    this.greatGrandfatherId = greatGrandfatherId
  }
}

export class SubjectTreeCollapseItems {
  name: string
  maxQuestions: number
  totalDesiredQuestions: number
  children: Array<SubjectTreeCollapseSubItems>
  isExpanded: boolean
  childId: number
  fatherId: number
  grandfatherId: number
  greatGrandfatherId: number

  constructor(filterQuestionsItemsType: FilterQuestionsItemsType, childId: number, fatherId: number, grandfatherId: number, greatGrandfatherId: number) {
    this.name = filterQuestionsItemsType.name
    this.maxQuestions = filterQuestionsItemsType.total
    this.totalDesiredQuestions = 0
    this.children = filterQuestionsItemsType.subItems.map((subItem, idx) => new SubjectTreeCollapseSubItems(subItem, idx, childId, fatherId, grandfatherId))
    this.isExpanded = false
    this.childId = childId
    this.fatherId = fatherId
    this.grandfatherId = grandfatherId
    this.greatGrandfatherId = greatGrandfatherId
  }
}

export class SubjectTreeCollapseSubItems {
  name: string
  maxQuestions: number
  totalDesiredQuestions: number
  children: Array<SubjectTreeCollapseSubItems>
  isExpanded: boolean
  childId: number
  fatherId: number
  grandfatherId: number
  greatGrandfatherId: number

  constructor(filterQuestionsSubItemsType: FilterQuestionsSubItemsType, childId: number, fatherId: number, grandfatherId: number, greatGrandfatherId: number) {
    this.maxQuestions = filterQuestionsSubItemsType.total
    this.name = filterQuestionsSubItemsType.name
    this.totalDesiredQuestions = 0
    this.isExpanded = false
    this.children = []
    this.childId = childId
    this.fatherId = fatherId
    this.grandfatherId = grandfatherId
    this.greatGrandfatherId = greatGrandfatherId
  }
}

export type SubjectTreeCollapseType = SubjectTreeCollapse | SubjectTreeCollapseDivision | SubjectTreeCollapseTopics | SubjectTreeCollapseItems | SubjectTreeCollapseSubItems
