
import { defineComponent, PropType } from 'vue'
import { ListsInfosType } from '@/dtos/ListRetrieveDTO'

export default defineComponent({
  name: 'RedesignedSubWrapper',
  props: {
    answerList: { type: Boolean, default: false },
    page: { type: String, default: '' },
    exerciseListConcluded: { type: Array as PropType<ListsInfosType[]>, default: () => ([]) },
    displayCardsInline: Boolean,
    isMobile: { type: Boolean, default: false },
  },
})
