
import InstitutionPlans from '@/helpers/InstitutionPlans';
import getUserInfos from '@/helpers/UserInfos';
import DefaultTheme from '@/theme/defaultTheme';
import { defineComponent, onBeforeMount, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import PazzeiButton from '../Generics/Redesign/PazzeiButton.vue';
import XCloseIcon from '../Icons/default/XCloseIcon.vue';

export default defineComponent({
  name: 'InviteToTestPage',
  components: { PazzeiButton, XCloseIcon,  },
  emits: ['expiring-trial', 'set-trial-info'],
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const totalCredits = ref(0);
    const userName = ref('');

    const trialExpiresIn = ref(7);
    const heading = ref('');
    const text = ref('');
    const displayCard = ref(false);
    const context = {
      text: 'Ver planos disponíveis',
      classes: 'md no-background-color'
    };

    const isClosable = ref(false);
    const isNotHomePage = ref(true);

    const checkout = () => {
      router.push('/checkout');
    }

    const cardHasBeenClosed = () => {
      displayCard.value = false;

      if (totalCredits.value > 0) {
        localStorage.setItem('defaultCardHasBeenShown', 'true');
        return;
      } else if (totalCredits.value === 0) {
        localStorage.setItem('lastDayAvailableCardHasBeenShown', 'true');
        return;
      }

      localStorage.setItem('noCreditsCardHasBeenShown', 'true');
    }

    const handleDisplayCard = async () => {
      if (totalCredits.value > 0 && route.path === '/') {
        displayCard.value = true
        return;
      }

      if (totalCredits.value > 0 && !localStorage.getItem('defaultCardHasBeenShown')) {
        displayCard.value = true;
        return;
      } else if (totalCredits.value === 0 && !localStorage.getItem('lastDayAvailableCardHasBeenShown')) {
        displayCard.value = true;
        return;
      } else if (totalCredits.value < 0 && !localStorage.getItem('noCreditsCardHasBeenShown')) {
        displayCard.value = true;
        return;
      }
    }

    onBeforeMount(async () => {
      const { credits, roleCode, name } = await getUserInfos();
      userName.value = name;
      totalCredits.value = credits;

      trialExpiresIn.value = credits;
      localStorage.setItem('pazzeiTrialPageHasBeenShown', 'true')

      const institutionPlan = await InstitutionPlans.getPlan();

      const userShouldSeeAlerts = ['pazzei:trial', 'pazzei:no-credits'].includes(roleCode) && !institutionPlan;

      if (!userShouldSeeAlerts) {
        emit('expiring-trial', false);
        return;
      }
      
      await handleDisplayCard();

      if (route.path !== '/') {
        isClosable.value = true;
      } else {
        isNotHomePage.value = false;
      }

      if (credits > 0 && userShouldSeeAlerts) {
        emit('set-trial-info', {
          credits,
          headingMessage: 'Como você quer estudar hoje?',
        })
        heading.value = `Psiu! Você ainda tem ${credits} ${credits > 1 ? 'dias' : 'dia'} para testar a plataforma como quiser.`
        text.value = 'Depois desse período, você pode assinar o Pazzei e desbloquear todos os recursos da plataforma.';
      } else if (credits === 0 && userShouldSeeAlerts) {
        emit('expiring-trial', true);
        emit('set-trial-info', {
          credits,
          headingMessage: 'Como você quer estudar hoje?',
        })
        heading.value = 'Seu período de teste acaba hoje.'
        text.value = 'Esperamos que você tenha aproveitado o Pazzei durante o seu teste.\nNós adoraríamos saber o que você achou da experiência com o Pazzei: responda a nossa pesquisa e desbloqueie um presente especial.'
      } else if (credits < 0 && userShouldSeeAlerts){
        emit('expiring-trial', true);
        emit('set-trial-info', {
          credits,
          headingMessage: 'O que você quer fazer hoje?',
        })
        heading.value = 'Acesso limitado.',
        text.value = 'Não identificamos uma assinatura ativa para sua conta. Você ainda terá acesso à plataforma de forma limitada.\nAssine ou renove sua assinatura para liberar todos os conteúdos e recursos do Pazzei.'
      }
    });

    return { heading, text, displayCard, trialExpiresIn, context, checkout, cardHasBeenClosed, isClosable, totalCredits, userName, isNotHomePage, DefaultTheme }
  }
})
