
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'TabProfile',
  props: {
    tab: String,
  },
  setup(props) {
    const tabRef = ref(props.tab)
    const handleClick = (item: string) => (tabRef.value = item)

    return {
      tabRef,
      handleClick,
    }
  },
})
