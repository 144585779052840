import { helpers, minLength, required } from '@vuelidate/validators'

const messageRequired = 'Este campo é obrigatório'

const modalCreateGroupRules: Record<string, any> = {
  listName: { required: helpers.withMessage(messageRequired, required), minLength: helpers.withMessage('O nome do groupo deve ter no mínimo 3 caracteres', minLength(3)) },
  users: { required: helpers.withMessage(messageRequired, required) },
}

export default modalCreateGroupRules
