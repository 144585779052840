import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 17 17",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M7.25655 1.60455H5.29021C3.78849 1.60455 3.03764 1.60455 2.46406 1.8968C1.95952 2.15388 1.54933 2.56407 1.29225 3.06861C1 3.64219 1 4.39304 1 5.89476V11.615C1 13.1167 1 13.8676 1.29225 14.4412C1.54933 14.9457 1.95952 15.3559 2.46406 15.613C3.03764 15.9052 3.78849 15.9052 5.29021 15.9052H11.0105C12.5122 15.9052 13.2631 15.9052 13.8366 15.613C14.3412 15.3559 14.7514 14.9457 15.0084 14.4412C15.3007 13.8676 15.3007 13.1167 15.3007 11.615V9.20179M6.82668 10.4482L8.00182 10.216C8.63592 10.0907 8.95298 10.028 9.24863 9.91262C9.51106 9.81018 9.76045 9.67707 9.99163 9.51608C10.2521 9.33469 10.4806 9.10617 10.9377 8.64912L15.5393 4.04748C16.1536 3.43321 16.1536 2.4373 15.5393 1.82303C14.925 1.20877 13.9291 1.20877 13.3149 1.82303L8.63456 6.50332C8.20629 6.9316 7.99215 7.14574 7.81921 7.38848C7.66567 7.60397 7.53653 7.83585 7.43417 8.07984C7.31885 8.35468 7.24955 8.64948 7.11094 9.23908L6.82668 10.4482Z",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}