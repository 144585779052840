import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 16 18",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M9.88889 15.4762L11.4889 17L15.2222 13.4444M1 17C1 13.5636 3.78578 10.7778 7.22222 10.7778C8.54422 10.7778 9.76993 11.1901 10.7778 11.8931M10.7778 4.55556C10.7778 6.51923 9.1859 8.11111 7.22222 8.11111C5.25854 8.11111 3.66667 6.51923 3.66667 4.55556C3.66667 2.59188 5.25854 1 7.22222 1C9.1859 1 10.7778 2.59188 10.7778 4.55556Z",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}