
import PazzeiButton from '@/components/Generics/Redesign/PazzeiButton.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'ForbiddenPage',
  components: { MainLayoutWrapper, GenericLayout, PazzeiButton },
  setup() {
    const router = useRouter()
    const context = {
      text: 'Voltar',
      classes: 'primary with-background-color lg'
    }

    return { router, context }
  },
})
