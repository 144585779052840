
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiInput from '@/components/Generics/PazzeiInput.vue'
import PazzeiInputCode from '@/components/Generics/PazzeiInputCode.vue'
import PazzeiModal from '@/components/Generics/PazzeiModal.vue'
import { ChangePasswordDTO } from '@/dtos/ChangePasswordDTO'
import { ForgotPasswordDTO } from '@/dtos/ForgotPasswordDTO'
import { VerifyOTPDTO } from '@/dtos/VerifyOTPDTO'
import router from '@/router'
import authenticationService from '@/services/authentication'
import newPassword from '@/utils/vuelidateRules/passwordRules'
import { useVuelidate } from '@vuelidate/core'
import { email, helpers, required, sameAs } from '@vuelidate/validators'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch, watchEffect } from 'vue'
import { HandleErrorAxiosResponseDTO } from '../../dtos/HandleErrorAxiosResponseDTO'
import authService from '../../services/authService'
import { ErrorAxiosResponseType } from '../../types/ErrorAxiosResponseType'

export default defineComponent({
  name: 'PazzeiForgotPwd',
  emits: ['close'],
  props: { email: String },
  components: {
    PazzeiBtn,
    PazzeiModal,
    PazzeiInputCode,
    PazzeiInput,
  },
  setup(props, context) {
    const $q = useQuasar()

    const userNotFound = ref(false)
    const codeSended = ref(false)
    const isLoading = ref(false)
    const code = ref('')
    const invalidCode = ref(false)
    const timer = ref(60)
    const interval = ref(0)
    const responsivenessHeight = ref('')
    const responsivenessWidth = ref('90%')

    const screen = ref('email')

    watch(
      screen,
      () => {
        if (screen.value === 'email') responsivenessHeight.value = '55%'
        else if (screen.value === 'code') responsivenessHeight.value = '70%'
        else responsivenessHeight.value = '65%'
      },
      { deep: true, immediate: true }
    )

    const firstStepRules = computed(() => {
      return {
        email: {
          required: helpers.withMessage('Este campo é obrigatório', required),
          regex: helpers.withMessage('Insira um e-mail válido', email),
        },
      }
    })

    const thirdStepRules = computed(() => {
      return {
        newPassword,
        newPasswordConfirmation: {
          required: helpers.withMessage('Este campo é obrigatório', required),
          sameAs: helpers.withMessage('As senhas devem coincidir', sameAs(thirdStepFormData.newPassword)),
        },
      }
    })

    const returnToLoginPage = () => router.push('/login')

    const firstStepFormData: { email: string | undefined } = reactive({
      email: '',
    })

    const thirdStepFormData = reactive({
      newPassword: '',
      newPasswordConfirmation: '',
    })

    const returnToPreviousPage = () => {
      screen.value = 'email'
      codeSended.value = false
    }

    const validateFirstStep$ = useVuelidate(firstStepRules, firstStepFormData)

    const validateThirdStep$ = useVuelidate(thirdStepRules, thirdStepFormData)

    const handleRecovery = async () => {
      const result = await validateFirstStep$.value.$validate()
      if (result) {
        const data = new ForgotPasswordDTO(firstStepFormData.email)
        isLoading.value = true
        authenticationService
          .post('/request-otp', data)
          .then((res) => {
            authService.setOtpToken(res.data.data.otpToken)
            screen.value = 'code'
          })
          .catch(() => {
            userNotFound.value = true
          })
          .finally(() => {
            isLoading.value = false
          })
      }
    }

    const handlePasswordChange = async () => {
      isLoading.value = true
      const isValid = await validateThirdStep$.value.$validate()
      if (!isValid) {
        isLoading.value = false
        return
      }
      const data = new ChangePasswordDTO(thirdStepFormData.newPassword)
      authenticationService
        .patch('/', data, {
          headers: { Authorization: `Bearer ${tempToken.value}` },
        })
        .then((res) => {
          authService.setUserOnSession(res.data.data.token, res.data.data.refreshToken)

          $q.notify({
            textColor: 'grey-1',
            message: 'Dados salvos com sucesso',
            color: 'green',
            timeout: 2000,
            position: 'top',
            classes: 'notify',
          })

          setTimeout(() => {
            window.location.href = '/'
          }, 2000)
        })
        .catch((err: ErrorAxiosResponseType) => {
          const { error } = new HandleErrorAxiosResponseDTO(err)

          $q.notify({
            textColor: 'grey-1',
            message: error.userMessage,
            color: 'red',
            timeout: 2000,
            position: 'top',
            classes: 'notify',
          })
        })
        .finally(async () => {
          await new Promise((resolve) => setTimeout(resolve, 5000))
          isLoading.value = false
        })
    }

    onMounted(() => {
      firstStepFormData.email = props.email
    })

    const getCode = (codeInput: string) => {
      if (codeInput.length >= 6) code.value = codeInput
    }

    const tempToken = ref('')

    watchEffect(() => {
      if (code.value.length === 6) {
        isLoading.value = true
        const data = new VerifyOTPDTO(code.value)
        const otpToken = sessionStorage.getItem('otpToken')
        authenticationService
          .post('/otp', data, { headers: { Authorization: `Bearer ${otpToken}` } })
          .then((res) => {
            tempToken.value = res.data.data.token
            sessionStorage.removeItem('otpToken')
            screen.value = 'recovery'
          })
          .catch((err: ErrorAxiosResponseType) => {
            const { error } = new HandleErrorAxiosResponseDTO(err)

            invalidCode.value = true

            $q.notify({
              textColor: 'grey-1',
              message: error.userMessage,
              color: 'red',
              timeout: 2000,
              position: 'top',
              classes: 'notify',
            })
          })
          .finally(() => {
            isLoading.value = false
          })
      }
    })

    const close = (e: Event) => {
      if (e instanceof KeyboardEvent) if (e.key === 'Escape') closeModal()
    }

    document.addEventListener('keydown', close)

    onUnmounted(() => document.removeEventListener('keydown', close))

    const closeModal = () => context.emit('close')

    const resendCode = async () => {
      codeSended.value = true
      await handleRecovery()
      interval.value = startTimer()
    }

    const startTimer = () =>
      setInterval(() => {
        if (timer.value === 0) {
          clearInterval(interval.value)
          timer.value = 60
          codeSended.value = false
        } else {
          timer.value--
        }
      }, 1000)

    return {
      closeModal,
      codeSended,
      returnToPreviousPage,
      isPwd: ref(true),
      isPwd2: ref(true),
      invalidCode,
      getCode,
      resendCode,
      code,
      userNotFound,
      validateFirstStep$,
      validateThirdStep$,
      screen,
      firstStepFormData,
      thirdStepFormData,
      handleRecovery,
      handlePasswordChange,
      isLoading,
      returnToLoginPage,
      timer,
      responsivenessHeight,
      responsivenessWidth,
    }
  },
})
