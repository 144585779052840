
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import Input from '@/components/Library/Input/index.vue'
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import paymentsService from '@/services/payment'
import useQuasar from 'quasar/src/composables/use-quasar.js';

export default defineComponent({
  props: {
    billingPrice: {
      type: String,
      required: true,
      default: '',
    },

    billingId: {
      type: Number,
      default: -1,
    },

    reloadData: Function as PropType<() => void>,
  },
  emits: ['close'],

  components: { Input, PazzeiBtn },

  setup(props, { emit }) {
    const messageRequired = 'Este campo é obrigatório'
    const $q = useQuasar()
    const isLoading = ref(false)

    const formData = ref({
      chargebackPrice: '',
    })

    const rules = computed(() => {
      return {
        chargebackPrice: { required: helpers.withMessage(messageRequired, required) },
      }
    })
    const v$ = useVuelidate(rules, formData)

    const requestRefund = async () => {
      const data = {
        amount: parseFloat(formData.value.chargebackPrice.replace(',', '.')),
      }

      const result = await v$.value.$validate()
      if (result) {
        try {
          isLoading.value = true
          await paymentsService.patch(`/billings/${props.billingId}/refund`, data)

          $q.notify({
            textColor: 'grey-1',
            message: 'Solicitação de estorno realizada com sucesso',
            color: 'green',
            position: 'top',
            classes: 'notify',
          })
        } catch (error) {
          $q.notify({
            textColor: 'grey-1',
            message: 'Erro ao solicitar estorno',
            color: 'red',
            position: 'top',
            classes: 'notify',
          })
          console.error(error)
        } finally {
          props.reloadData?.()
          isLoading.value = false
          emit('close')
        }
      }
    }

    watch(
      () => formData.value.chargebackPrice,
      () => {
        const refundValue = parseFloat(formData.value.chargebackPrice.replace(/\./g, '').replace(',', '.'))
        const totalPrice = parseFloat(props.billingPrice.split(' ')[1].replace(',', '.'))
        if (refundValue > totalPrice) {
          formData.value.chargebackPrice = props.billingPrice
        }
      }
    )

    return {
      v$,
      formData,
      isLoading,
      requestRefund,
    }
  },
})
