import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f1f97e1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tab-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.isWhiteLabel)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          to: "/",
          style: {"width":"50%","text-align":"center"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClick('authentication'))),
              class: _normalizeClass(["tab-item", _ctx.tabRef === 'authentication' ? _ctx.tabRef : ''])
            }, "Entrar", 2)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "/cadastro",
          style: {"width":"50%","text-align":"center"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleClick('registration'))),
              class: _normalizeClass(["tab-item", _ctx.tabRef === 'registration' ? _ctx.tabRef : ''])
            }, "Criar conta", 2)
          ]),
          _: 1
        }),
        _createElementVNode("div", {
          class: _normalizeClass(["tab-indicator", _ctx.tabRef])
        }, null, 2)
      ]))
    : _createCommentVNode("", true)
}