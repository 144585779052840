
import { largeAreas } from '@/utils/hardCoded/enemModule/enemModule'
import { defineComponent, onMounted, PropType, ref, toRaw } from 'vue'
import CaptionView from '../Application/captionView/CaptionView.vue'
import Button from '../Library/Button/index.vue'
import QuestionNumberLabel from './QuestionNumberLabel.vue'

export default defineComponent({
  name: 'ModalForgottenQuestions',
  emits: ['close', 'finalizeList', 'update:modelValue'],
  components: {
    Button,
    CaptionView,
    QuestionNumberLabel,
  },
  props: {
    listName: { type: String, default: '' },
    listLargeAreas: {
      type: Object as PropType<{ areas: Array<string>; isListEnemModuleAndAtYourPace: boolean }>,
      required: true,
      default: () => ({ areas: [], isListEnemModuleAndAtYourPace: false }),
    },
    questionsNotAnswered: {
      type: Array as PropType<Array<{ order: number; questionId: number; isDoubt: boolean; isAnswered: boolean; questionBelongingArea?: string }>>,
      required: true,
      default: () => [],
    },
  },
  setup(props) {
    const auxQuestionsNotAnswered = ref<Array<{ name: string; questions: Array<{ key: string; value: number; isDoubt: boolean; isAnswered: boolean; label: number }> }>>([])
    const auxQuestionsSeriesNotAnswered = ref<
      Array<{ name: string; series: Array<{ number: number; questions: Array<{ key: string; value: number; isDoubt: boolean; isAnswered: boolean; label: number }> }> }>
    >([])

    onMounted(async () => {
      if (props.listLargeAreas.isListEnemModuleAndAtYourPace) {
        const partitionQuestion: Array<Array<{ order: number; questionId: number; isDoubt: boolean; isAnswered: boolean; questionBelongingArea?: string }>> = []
        const iterationAmount = Math.ceil(props.questionsNotAnswered.length / 15)

        for (let i = 0; i < iterationAmount; i++) partitionQuestion.push(toRaw(props.questionsNotAnswered).slice(i * 15, (i + 1) * 15))

        const arraySeries: Array<{ name: string; series: Array<{ number: number; questions: Array<{ key: string; value: number; isDoubt: boolean; isAnswered: boolean; label: number }> }> }> = []

        partitionQuestion.forEach((item, idx) => {
          const index = arraySeries.findIndex((tItem) => tItem.name === item[0].questionBelongingArea)

          if (index === -1) {
            arraySeries.push({
              name: item[0].questionBelongingArea || '',
              series: [
                {
                  number: idx + 1,
                  questions: item.map((item, index) => {
                    return {
                      key: item.questionId.toString(),
                      value: item.order,
                      isDoubt: item.isDoubt,
                      isAnswered: item.isAnswered,
                      label: index + 1,
                    }
                  }),
                },
              ],
            })
          } else {
            arraySeries[index].series.push({
              number: idx + 1,
              questions: item.map((item, index) => {
                return {
                  key: item.questionId.toString(),
                  value: item.order,
                  isDoubt: item.isDoubt,
                  isAnswered: item.isAnswered,
                  label: index + 1,
                }
              }),
            })
          }
        })

        auxQuestionsSeriesNotAnswered.value = arraySeries
      } else {
        if (props.listLargeAreas.areas.length > 0) {
          const questionInterval = props.listLargeAreas.isListEnemModuleAndAtYourPace ? 15 : 45
          const largeAreasOrder = props.listLargeAreas.areas.map((item) => ({ order: largeAreas.find((largeArea) => largeArea.title === item)!.order, area: item })).sort((a, b) => a.order - b.order)

          auxQuestionsNotAnswered.value = largeAreasOrder.map((largeArea, idx) => {
            let nameArea = largeArea.area || ''
            const questions = props.questionsNotAnswered.slice(idx * questionInterval, (idx + 1) * questionInterval).map((item, index) => {
              if (item.questionBelongingArea) nameArea = item.questionBelongingArea

              return {
                key: item.questionId.toString(),
                value: item.order,
                isDoubt: item.isDoubt,
                isAnswered: item.isAnswered,
                label: index + 1,
              }
            })

            return {
              name: nameArea,
              questions,
            }
          })
        } else {
          auxQuestionsNotAnswered.value = [
            {
              name: props.listName,
              questions: props.questionsNotAnswered.map((item) => {
                return {
                  key: item.questionId.toString(),
                  value: item.order,
                  isDoubt: item.isDoubt,
                  isAnswered: item.isAnswered,
                  label: item.order,
                }
              }),
            },
          ]
        }
      }
    })

    const handleStateQuestion = (isDoubt: boolean, isAnswered: boolean) => {
      if (isDoubt) return 'doubt'
      else return isAnswered ? 'answered' : 'unanswered'
    }

    return { auxQuestionsNotAnswered, handleStateQuestion, auxQuestionsSeriesNotAnswered }
  },
})
