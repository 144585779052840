
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import DefaultTheme from '@/theme/defaultTheme'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, inject, Ref, ref } from 'vue'
import { DeleteListDTO } from '../../dtos/DeleteListDTO'
import { SubjectInfoDTO } from '../../dtos/SubjectInfoDTO'
import listsService from '../../services/list'
import DuplicateIcon from '../Icons/default/DuplicateIcon.vue'
import EditIcon from '../Icons/default/EditIcon.vue'
import ElipseIcon from '../Icons/default/ElipseIcon.vue'
import PrinterIcon from '../Icons/default/PrinterIcon.vue'
import ProficiencyHistoryIcon from '../Icons/default/ProficiencyHistoryIcon.vue'
import TrashIcon from '../Icons/default/TrashIcon.vue'
import PazzeiBtn from './PazzeiBtn.vue'
import ModalPrintList from './Redesign/ModalPrintList.vue'

export default defineComponent({
  name: 'PazzeiMenuLists',
  props: {
    list: { type: Object },
    listId: Number,
    isTest: Boolean,
    isTestProficiency: {
      type: Boolean,
      default: false,
    },
    subjectName: { type: String, default: '' },
  },
  setup(props) {
    const data = ref(Array<number>())
    const $q = useQuasar()
    const getEditName = inject<Ref<boolean>>('editName')
    const editNameList = () => (getEditName!.value = true)
    const getExerciseListNotStarted = inject<Ref<SubjectInfoDTO[]>>('exerciseListNotStarted')
    const getExerciseListStarted = inject<Ref<SubjectInfoDTO[]>>('exerciseListStarted')
    const initExerciseListPage = inject<() => void>('initExerciseListPage')
    const reloadLists = inject<() => void>('loadLists')
    const getExerciseList = inject<Ref<SubjectInfoDTO[]>>('exerciseList')
    const emitValueToParent = inject<(value: any) => void>('emitValueToParent')
    const openHistoric = inject<(value: any) => void>('openHistoric')
    const confirmationBeforeDelete = ref(false)
    const isLoading = ref(false)

    const confirmBeforeDeleting = () => (confirmationBeforeDelete.value = true)

    const handleEmitValueToParent = () => {
      emitValueToParent?.(props.subjectName)
    }

    const handleClickOpenHistoric = () => {
      openHistoric?.(true)
    }

    const deleteList = () => {
      const deleteOneList = ref(props.listId)
      if (getExerciseListNotStarted) getExerciseListNotStarted.value = getExerciseListNotStarted?.value.filter((it) => it.id !== deleteOneList.value)
      if (getExerciseListStarted) getExerciseListStarted.value = getExerciseListStarted?.value.filter((it) => it.id !== deleteOneList.value)
      if (getExerciseList) getExerciseList.value = getExerciseList?.value.filter((it) => it.id !== deleteOneList.value)
      if (props.listId) data.value.push(props.listId)
      const parsedData = new DeleteListDTO(data.value)
      listsService
        .delete('/', { data: parsedData })
        .then(() => {
          confirmationBeforeDelete.value = false

          if (props.isTest) reloadLists?.()
          else initExerciseListPage?.()

          $q.notify({
            textColor: 'white',
            message: 'Lista excluída com sucesso',
            color: 'green',
            position: 'top',
            classes: 'notify',
          })
        })
        .catch(() => (confirmationBeforeDelete.value = false))
    }
    const duplicateList = () => {
      if (props.listId)
        listsService.post<ResponseDTO<SubjectInfoDTO>>('/duplicate', { id: props.listId }).then((res) => {
          $q.notify({
            textColor: 'white',
            message: 'Lista duplicada com sucesso',
            color: 'green',
            position: 'top',
            classes: 'notify',
          })
          if (getExerciseListNotStarted) getExerciseListNotStarted.value.push(res.data.data)
        })
    }

    const showPrintListModal = ref(false);

    const printList = ref<SubjectInfoDTO>();
    const handleShowPrintListModal = (value: boolean, list?: SubjectInfoDTO) => {
      showPrintListModal.value = value;
      printList.value = list;
    }

    return { handleClickOpenHistoric, handleEmitValueToParent, deleteList, editNameList, confirmationBeforeDelete, isLoading, confirmBeforeDeleting, duplicateList, DefaultTheme, handleShowPrintListModal, showPrintListModal }
  },
  components: { PazzeiBtn, EditIcon, DuplicateIcon, TrashIcon, ElipseIcon, PrinterIcon, ProficiencyHistoryIcon, ModalPrintList },
})
