
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'TabCreateList',
  props: {
    modelValue: { type: String, default: '', required: true },
    tabs: { type: Array as PropType<Array<string>>, default: () => [], required: true },
  },
  setup(_, { emit }) {
    const handleTabClick = (tab: string) => emit('update:modelValue', tab)

    return { handleTabClick }
  },
})
