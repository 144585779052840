import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eb94d818"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "input-icon-container" }
const _hoisted_3 = { class: "input-container" }
const _hoisted_4 = ["autocomplete", "autofocus", "min", "disabled", "id", "maxlength", "placeholder", "readonly", "type"]
const _hoisted_5 = { class: "input-icon-container" }
const _hoisted_6 = {
  key: 0,
  class: "input-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input-container-wrapper-outer", { 'input-full': _ctx.fullWidthInput }]),
    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.inputRef?.focus()))
  }, [
    _createElementVNode("label", {
      class: "label",
      for: _ctx.forLabel
    }, _toDisplayString(_ctx.label), 9, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass({
        [`input--${_ctx.variant}`]: _ctx.variant,
        'change-font-color': _ctx.changeFontColor,
        ..._ctx.classNames,
        'input-container-wrapper-inner': !_ctx.liteStyle,
        'input-container-wrapper-inner-lite-style': _ctx.liteStyle,
      }),
      style: _normalizeStyle({ height: _ctx.height, width: _ctx.width })
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "prepend", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          ref: "inputRef",
          autocomplete: _ctx.autocomplete,
          autofocus: _ctx.autofocus,
          min: _ctx.minDate,
          class: _normalizeClass(["input", { 'change-font-color': _ctx.changeFontColor }]),
          disabled: _ctx.disabled,
          id: _ctx.forLabel,
          maxlength: _ctx.mask.length || _ctx.maxLength,
          placeholder: _ctx.placeholder,
          readonly: _ctx.readOnly,
          type: _ctx.type,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
          onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleBlur())),
          onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleInputFocus(true)))
        }, null, 42, _hoisted_4), [
          [_vModelDynamic, _ctx.inputValue]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "append", {}, undefined, true)
      ])
    ], 6),
    (!_ctx.hideBottomSpace)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass({ 'input-message-container': !_ctx.isAbsolute, 'input-message-container-checkout': _ctx.isAbsolute })
        }, [
          (_ctx.error)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.errorMessage), 1))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}