import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c84e7de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inputs-wrapper" }
const _hoisted_2 = { class: "div-input" }
const _hoisted_3 = { class: "grid-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentModalCreateCoupon = _resolveComponent("ContentModalCreateCoupon")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_ModalConfirmation = _resolveComponent("ModalConfirmation")!
  const _component_SearchIcon = _resolveComponent("SearchIcon")!
  const _component_PazzeiInput = _resolveComponent("PazzeiInput")!
  const _component_PazzeiBtn = _resolveComponent("PazzeiBtn")!
  const _component_TablesComponentCoupon = _resolveComponent("TablesComponentCoupon")!
  const _component_PaginationTableComponent = _resolveComponent("PaginationTableComponent")!
  const _component_MainLayoutWrapper = _resolveComponent("MainLayoutWrapper")!
  const _component_GenericLayout = _resolveComponent("GenericLayout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Modal, {
      modelValue: _ctx.isModalOpen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isModalOpen) = $event)),
      height: !_ctx.isModalConfirm ? _ctx.heightModal : _ctx.heightModalSmall,
      width: _ctx.widthModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ContentModalCreateCoupon, {
          listData: _ctx.getCouponData,
          onClose: _ctx.closeModal,
          onChangeSizeModal: _ctx.receiveChangeSize
        }, null, 8, ["listData", "onClose", "onChangeSizeModal"])
      ]),
      _: 1
    }, 8, ["modelValue", "height", "width"]),
    _createVNode(_component_ModalConfirmation, {
      modelValue: _ctx.isModalConfirmOpen,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isModalConfirmOpen) = $event)),
      label: "Tem certeza que deseja invalidar este cupom?",
      "click-confirm": _ctx.ValidateOrInvalidateCoupons
    }, null, 8, ["modelValue", "click-confirm"]),
    _createVNode(_component_GenericLayout, null, {
      default: _withCtx(() => [
        _createVNode(_component_MainLayoutWrapper, null, {
          nav: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_PazzeiInput, {
                  mobileSchool: "",
                  "max-length": 80,
                  placeholder: 'Buscar código',
                  "hide-bottom-space": "",
                  "search-list": "",
                  "input-collapsed": _ctx.isSearchInputExpanded,
                  modelValue: _ctx.filterField,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filterField) = $event))
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_SearchIcon, {
                      size: 32,
                      fill: _ctx.DefaultTheme().colors.primary,
                      style: {"padding-left":"15px"},
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isSearchInputExpanded = !_ctx.isSearchInputExpanded))
                    }, null, 8, ["fill"])
                  ]),
                  _: 1
                }, 8, ["input-collapsed", "modelValue"])
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["btn-container", { 'btn-pazzei__collapsed': !_ctx.isSearchInputExpanded }])
              }, [
                _createVNode(_component_PazzeiBtn, {
                  icon: _ctx.AddIcon,
                  iconStroke: _ctx.DefaultTheme().colors.whiteColor,
                  label: "Cadastrar Cupom",
                  fill: "",
                  class: _normalizeClass(["btn-pazzei", { 'btn-pazzei__collapsed': !_ctx.isSearchInputExpanded }]),
                  "btn-collapsed": !_ctx.isSearchInputExpanded,
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openModal()))
                }, null, 8, ["icon", "iconStroke", "class", "btn-collapsed"])
              ], 2)
            ])
          ]),
          main: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_TablesComponentCoupon, {
                columns: _ctx.columnsCoupon,
                rows: _ctx.rows,
                sizeColumnsTable: _ctx.sizeColumnsCoupon,
                showOptionsMenu: "",
                isLoading: _ctx.isLoading,
                sizeLoading: _ctx.sizeLoading,
                heightTable: _ctx.heightTable,
                widthTable: _ctx.widthTable
              }, null, 8, ["columns", "rows", "sizeColumnsTable", "isLoading", "sizeLoading", "heightTable", "widthTable"])
            ])
          ]),
          footer: _withCtx(() => [
            _createVNode(_component_PaginationTableComponent, {
              onPageSizeChange: _ctx.handlePageSizeChange,
              onCurrentPage: _ctx.handleCurrentPage,
              totalElements: _ctx.totalElements
            }, null, 8, ["onPageSizeChange", "onCurrentPage", "totalElements"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}