import authService from "@/services/authService";
import groupsService from "@/services/groups";
import getUserInfos from "./UserInfos";

const getPlan = async () => {
  const userLogged = localStorage.getItem('token') ?? sessionStorage.getItem('token')

  if (!userLogged) { return }

  const userInfos = await getUserInfos();
  const userPermissions = authService.getPermissions();

  if (userPermissions?.roleDescription === 'Aluno') { return }

  const institutionId = userInfos.institutionId;
  const plan = (await groupsService.get(`/institution_plans/single?id=${institutionId}`))?.data?.data?.institutionPlan;

  return plan;
}

export default { getPlan }
