import { HandleErrorAxiosResponseDTO } from "@/dtos/HandleErrorAxiosResponseDTO";
import { InstitutionHasInstitutionPlanDTO } from "@/dtos/ResponseDTOs/InstitutionPlanDTO";
import { ResponseDTO } from "@/dtos/ResponseDTOs/ResponseDTO";
import { UserDTO } from "@/dtos/ResponseDTOs/UserDTO";
import TallyService from "@/lib/tally/services/tallyService";
import router from "@/router";
import authService from "@/services/authService";
import groupsService from "@/services/groups";
import listsService from "@/services/list";
import usersService from "@/services/users";
import { getSubdomainFromLocalStorage } from "@/utils/subdomain";
import { AxiosError } from "axios";
import useQuasar from 'quasar/src/composables/use-quasar.js';

const AuthHelper = async (token: string, refreshToken: string) => {
  const $q = useQuasar();

  try {
    authService.setUserOnLocal(token, refreshToken)
    localStorage.setItem('keeplogged', 'true')

    const { data } = await listsService.get<ResponseDTO<Array<string>>>('/areas')
    localStorage.setItem('areas', JSON.stringify(data.data))

    const responseMe = await usersService.get<ResponseDTO<UserDTO>>('/me')
    const userId = responseMe.data.data.id;

    const tempUserDTO = new UserDTO({
      name: responseMe.data.data.name,
      lastName: responseMe.data.data.lastName,
      email: responseMe.data.data.email,
      roleCode: responseMe.data.data.roleCode,
      registrationCompleted: responseMe.data.data.registrationCompleted,
      credits: responseMe.data.data.credits,
      autoRenew: responseMe.data.data.autoRenew,
      groupId: 0,
    })

    sessionStorage.setItem(
      'userInfos',
      JSON.stringify({
        credits: tempUserDTO.credits,
        autoRenew: tempUserDTO.autoRenew,
        institutionId: responseMe.data.data.institutionId,
        roleCode: tempUserDTO.roleCode,
      })
    )
    sessionStorage.setItem('warningHasBeenShown', 'false')


    const userLogged = localStorage.getItem('token') ?? sessionStorage.getItem('token')
    if (userLogged) {
      const userInfos: any = sessionStorage.getItem('userInfos');
      const institutionId = JSON.parse(userInfos)?.institutionId;
      let institutionPlanId;

      if (institutionId) {
        try {
          const { data: entity } = await groupsService.get<ResponseDTO<InstitutionHasInstitutionPlanDTO>>(
            `/institution_plans/single?id=${institutionId}`
          );

          institutionPlanId = entity.data.institutionPlanId;
        } catch (error) {
          localStorage.removeItem('plan')
        }

        TallyService.loginForm(userId, institutionPlanId, JSON.parse(userInfos));
      }
    }

    const userScoped = authService.getHigherScoped()
    const userCanViewHomePage = authService.validatePermissions('self:lists:lists:enem-simulation')

    if (!userScoped) {
      $q.notify({
        textColor: 'grey-1',
        message: 'Erro ao fazer login, tente novamente',
        color: 'red',
        position: 'top',
        classes: 'notify',
      });

      router.push('/login')
    } else if (userScoped === 'self') {
      if (userCanViewHomePage) router.push('/')
      else router.push(localStorage.getItem('lastPage') ?? '/')
    } else if (userScoped === 'all') router.push('/instituicoes')
    else router.push('/usuarios')
  } catch (err) {
    if (err instanceof AxiosError) {
      const { error } = new HandleErrorAxiosResponseDTO(err)

      $q.notify({
        textColor: 'grey-1',
        message: error.userMessage,
        color: 'red',
        position: 'top',
        classes: 'notify',
      })
    }
  }
}

export default AuthHelper;
