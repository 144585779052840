
import PazzeiButton from '@/components/Generics/Redesign/PazzeiButton.vue';
import PazzeiModal from '@/components/Generics/Redesign/PazzeiModal/PazzeiModal.vue';
import AlertIcon from '@/components/Icons/default/redesign/sharedList/AlertIcon.vue';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'ModalLimitTime',
  emits: ['close', 'updateSubjects', 'start-list', 'close-modal-limit-time'],
  props: {
    maxTime: { type: Number, default: 0 }
  },
  components: {
    PazzeiModal,
    PazzeiButton,
  },
  setup(props, {emit}) {
    const context = {
      name: 'limitTime',
      icon: AlertIcon,
      isBorderless: true,
      isLoaderModal: false,
      loaderGIF: require('@/assets/gif/loader_pazzei_marca.gif'),
    }

    const showModal = ref(true);
    const showLoader = ref(false);

    const handleModalClose = () => {
      showModal.value = false;
    }

    const handleShowLoader = () => {
      showLoader.value = true;
      context.icon = null as any;
      context.isLoaderModal = true;

      setTimeout(() => {
        emit('start-list')
      }, 1500)
    }

    return {
      context,
      showModal,
      handleModalClose,
      showLoader,
      handleShowLoader,
    }
  }
})
