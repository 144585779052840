
import PazzeiButton from '@/components/Generics/Redesign/PazzeiButton.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import getUserInfos from '@/helpers/UserInfos'
import { defineComponent, onBeforeMount, onBeforeUnmount, ref } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'InviteToTestPage',
  components: { MainLayoutWrapper, GenericLayout, PazzeiButton },
  setup() {
    const router = useRouter();
    const lastPage = ref(localStorage.getItem('lastPage') || '/');
    const trialExpiresIn = ref(7);

    onBeforeMount(async () => {
      const { credits } = await getUserInfos();
      trialExpiresIn.value = credits;
      localStorage.setItem('pazzeiTrialPageHasBeenShown', 'true')
    });

    onBeforeUnmount(() => {
      localStorage.removeItem('pazzeiTrialPageHasBeenShown');
    })

    const context = {
      text: 'Testar o Pazzei',
      classes: ' primary with-background-color lg'
    };

    const contextSharedListsButton = {
      text: 'Voltar para as listas compartilhadas',
      classes: 'md'
    };

    return { router, context, contextSharedListsButton, trialExpiresIn, lastPage }
  }
})
