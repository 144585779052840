import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-97105ae6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "div-questions" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alternative = _resolveComponent("Alternative")!
  const _component_AlternativesSelector = _resolveComponent("AlternativesSelector")!
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.maxWidthTotal ? 'max-width-total' : 'file-html']),
          innerHTML: _ctx.questionArtifacts?.textBaseHTML
        }, null, 10, _hoisted_2),
        _createElementVNode("div", {
          id: "question-artifacts",
          innerHTML: _ctx.questionArtifacts.questionHTML,
          class: _normalizeClass([_ctx.maxWidthTotal ? 'max-width-total' : 'file-html']),
          height: "80px",
          width: "100%"
        }, null, 10, _hoisted_3),
        (!_ctx.showLoading)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["question-wrapper-alternatives-container", { 'padding-modal-alternatives': _ctx.paddingRightAlternatives }])
            }, [
              _createVNode(_component_AlternativesSelector, {
                summationTotal: _ctx.summationModel,
                "onUpdate:summationTotal": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.summationModel) = $event)),
                modelValue: _ctx.alternativesModel,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.alternativesModel) = $event)),
                "question-id": _ctx.questionSource.question.id,
                type: "mcq"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionArtifacts.alternatives, (value, index) => {
                    return (_openBlock(), _createBlock(_component_Alternative, {
                      id: value.id,
                      index: index,
                      key: value.id
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(), _createElementBlock("div", {
                          class: _normalizeClass(["v-html", [_ctx.maxWidthTotal ? 'file-html-alternatives-modal' : 'file-html-alternatives']]),
                          key: index,
                          innerHTML: value.html,
                          width: "100%"
                        }, null, 10, _hoisted_4))
                      ]),
                      _: 2
                    }, 1032, ["id", "index"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["summationTotal", "modelValue", "question-id"])
            ], 2))
          : _createCommentVNode("", true)
      ])
    ], 512), [
      [_vShow, !_ctx.isHtmlLoading]
    ]),
    (_ctx.isHtmlLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_Skeleton)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}