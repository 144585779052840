
import ModalConfirmation from '@/components/Library/ModalConfirmation/index.vue';
import DefaultTheme from '@/theme/defaultTheme';
import { defineComponent, inject, onMounted, onUpdated, PropType, Ref, ref } from 'vue';
import TrashIcon from '../Icons/default/TrashIcon.vue';

export default defineComponent({
  name: 'PazzeiSubjectTabs',
  components: {
    ModalConfirmation,
    TrashIcon,
  },
  emits: ['removeSubject', 'open'],
  props: {
    tabs: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    modelValue: String,
  },
  setup(props, { emit }) {
    const currentTabObj = inject<Ref<string>>('currentTab')
    const currentTab = ref<{ index: number; name: string }>({
      index: 0,
      name: '',
    })
    const isModalConfirmOpen = ref(false)
    const openModalConfirm = () => (isModalConfirmOpen.value = true)

    const confirmRemove = () => {
      emit('removeSubject')
    }

    onMounted(() => {
      currentTab.value.name = currentTabObj?.value as string
    })

    onUpdated(() => (currentTab.value.name = props.modelValue as string))

    const selectTab = (name: string, index: number) => {
      currentTab.value.index = index
      currentTab.value.name = name

      if (currentTabObj) {
        currentTabObj.value = currentTab.value.name
      }
    }
    return { currentTab, selectTab, isModalConfirmOpen, openModalConfirm, confirmRemove, DefaultTheme }
  },
})
