import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cc7e724"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "navigation-panel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PazzeiButton = _resolveComponent("PazzeiButton")!

  return (Boolean(_ctx.onRegress))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PazzeiButton, {
          text: 'Voltar',
          icon: _ctx.ReturnIcon,
          iconStroke: _ctx.DefaultTheme().colors.secondaryGray,
          alt: 'Voltar',
          style: {"max-width":"93px"},
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onRegress()))
        }, null, 8, ["icon", "iconStroke"])
      ]))
    : _createCommentVNode("", true)
}