import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 36 36",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M22.1666 17.1667V14.6667C22.1666 12.3655 20.3011 10.5 17.9999 10.5C15.6987 10.5 13.8333 12.3655 13.8333 14.6667V17.1667M15.3333 25.5H20.6666C22.0667 25.5 22.7668 25.5 23.3016 25.2275C23.772 24.9878 24.1544 24.6054 24.3941 24.135C24.6666 23.6002 24.6666 22.9001 24.6666 21.5V21.1667C24.6666 19.7665 24.6666 19.0665 24.3941 18.5317C24.1544 18.0613 23.772 17.6788 23.3016 17.4392C22.7668 17.1667 22.0667 17.1667 20.6666 17.1667H15.3333C13.9331 17.1667 13.2331 17.1667 12.6983 17.4392C12.2279 17.6788 11.8454 18.0613 11.6057 18.5317C11.3333 19.0665 11.3333 19.7665 11.3333 21.1667V21.5C11.3333 22.9001 11.3333 23.6002 11.6057 24.135C11.8454 24.6054 12.2279 24.9878 12.6983 25.2275C13.2331 25.5 13.9331 25.5 15.3333 25.5Z",
      "stroke-width": "1.67",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}