
import { computed, defineComponent, inject, PropType, Ref, ref, watch } from 'vue';

export default defineComponent({
  name: 'PazzeiInput',
  props: {
    placeholder: String,
    readonly: Boolean,
    transparent: Boolean,
    sharedList: Boolean,
    modelValue: String,
    error: Boolean,
    errorMessage: String,
    questionPage: Boolean,
    hideBottomSpace: Boolean,
    checkout: Boolean,
    telephone: Boolean,
    creditCard: Boolean,
    expirationDate: Boolean,
    cvv: Boolean,
    square: Boolean,
    center: Boolean,
    centerGoal: Boolean,
    cep: Boolean,
    createList: Boolean,
    searchList: Boolean,
    mobileSchool: Boolean,
    cpf: Boolean,
    inputCollapsed: { type: Boolean, default: false },
    alphabeticOnly: Boolean,
    type: {
      type: String as PropType<'text' | 'number' | 'password'>,
      default: 'text',
    },
    maxLength: {
      type: Number,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const goalBtn = inject('goalBtn', false)

    const inputValue = computed<string | undefined>(() => props.modelValue)
    let init = false
    const inputRef = ref<HTMLInputElement>()

    const inputValidation = ref(false)

    const telephone = (v: string) => {
      v = v.replace(/\D/g, '')
      v = v.replace(/^(\d\d)(\d)/g, '($1) $2')
      v = v.replace(/(\d{5})(\d)/, '$1-$2')
      return v
    }

    const cep = (v: string) => {
      v = v
        .replace(/\D/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})\d+?$/, '$1')

      return v
    }

    const formater = (v: string, d: number, s: string) => {
      let newValue = ''
      v = v.replace(/[\s | /]/g, '')

      for (let i = 0; i < v.length; i++) {
        if (i % d === 0 && i > 0) newValue = newValue.concat(s)
        newValue = newValue.concat(v[i])
      }

      return newValue
    }

    const formatCPF = (value: string) => {
      value = value.replace(/\D/g, '')
      value = value.replace(/^(\d{3})(\d)/g, '$1.$2')
      value = value.replace(/^(\d{3})\.(\d{3})(\d)/g, '$1.$2.$3')
      value = value.replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d{1,2})/g, '$1.$2.$3-$4')
      return value
    }

    const onInput = (event: Event) => {
      if (event.target instanceof HTMLInputElement) {
        let str = ''
        let x = ((event.target as HTMLInputElement).value as string).replace(/(\d|[-,.|@/\\=~^´`_¨"'!;%<>?&:;$+#(){}[*\]])/g, '')
        let t = ((event.target as HTMLInputElement).value as string).replace(/\D/g, '')

        if (!isNaN(parseInt(t)) && !props.alphabeticOnly) {
          if (props.creditCard) {
            event.target.value = formater(t, 4, ' ')
            str = formater(event.target.value, 4, ' ')
          } else if (props.expirationDate) {
            event.target.value = formater(t, 2, '/')
            str = formater(event.target.value, 2, '/')
          } else if (props.telephone) {
            event.target.value = telephone(t)
            str = telephone(event.target.value)
          } else if (props.cep) {
            event.target.value = cep(t)
            str = cep(event.target.value)
          } else if (props.type === 'number') {
            event.target.value = t.replace(/^([a-zA-Z])/g, '').replace(/(\d)/g, '$1')
            str = t.replace(/^([a-zA-Z])/g, '').replace(/(\d)/g, '$1')
          } else if (props.cpf) {
            event.target.value = formatCPF(t)
            str = formatCPF(event.target.value)
          } else {
            str = event.target.value
          }

          emit('update:modelValue', str)
        } else if (props.alphabeticOnly) {
          event.target.value = x
          str = x

          emit('update:modelValue', x)
        } else {
          if (!props.creditCard && !props.expirationDate && !props.telephone && !props.cvv && !props.cep && props.type !== 'number' && !props.alphabeticOnly) {
            emit('update:modelValue', event.target.value)
          } else {
            event.target.value = t
            emit('update:modelValue', t)
          }
        }
      }
    }

    watch(
      inputValue,
      () => {
        let tel: string | undefined | Ref<string | undefined>
        if (props.telephone && !init) {
          init = true

          tel = telephone(inputValue.value as string)
          emit('update:modelValue', tel)
        }
      },
      { deep: true, immediate: true }
    )

    return { inputRef, inputValidation, onInput, goalBtn }
  },
  methods: {
    onInputUpdate(event: Event) {
      if (event.target && event.target instanceof HTMLInputElement) {
        return event.target.value
      }
    },
    onEnterKeyUp(event: Event) {
      if (event.target && event.target instanceof HTMLInputElement) {
        return event.target.value
      }
    },
  },
})
