import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a4bc80b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "test-summary-container" }
const _hoisted_2 = { class: "test-summary-knowledge-area" }
const _hoisted_3 = { class: "test-summary-inputs-container" }
const _hoisted_4 = { class: "test-summary-inputs" }
const _hoisted_5 = {
  key: 0,
  class: "test-summary-btn-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardKnowledgeArea = _resolveComponent("CardKnowledgeArea")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.knowledgeAreas.areas, (knowledgeArea) => {
        return (_openBlock(), _createBlock(_component_CardKnowledgeArea, {
          key: knowledgeArea.id,
          "card-content": knowledgeArea,
          "foreign-language": _ctx.isForeignLanguageSelected,
          "onUpdate:foreign-language": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isForeignLanguageSelected) = $event)),
          "has-error": _ctx.hasError
        }, null, 8, ["card-content", "foreign-language", "has-error"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_q_checkbox, {
          class: "test-summary-checkbox",
          modelValue: _ctx.instantFeedback,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.instantFeedback) = $event)),
          label: "Mostrar respostas durante a prova",
          size: "36px"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_q_checkbox, {
          class: "test-summary-checkbox",
          modelValue: _ctx.showAlreadyAnsweredQuestion,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showAlreadyAnsweredQuestion) = $event)),
          label: "Não incluir questões já respondidas",
          size: "36px"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_q_checkbox, {
          class: "test-summary-checkbox",
          modelValue: _ctx.shuffle,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.shuffle) = $event)),
          label: "Embaralhar questões",
          size: "36px"
        }, null, 8, ["modelValue"])
      ]),
      (!_ctx.lockResources)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_Button, {
              class: "test-summary-btn start",
              label: "Iniciar simulado",
              variant: "primary-bold",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (!_ctx.isLoading ? _ctx.handleNextBtnClick() : null)),
              loading: _ctx.isLoading,
              disabled: _ctx.lockResources
            }, null, 8, ["loading", "disabled"]),
            _createVNode(_component_Button, {
              class: "test-summary-btn",
              label: "Voltar",
              variant: "secondary-bold",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (!_ctx.isLoading ? _ctx.handlePreviousBtnClick() : null)),
              disabled: _ctx.isLoading || _ctx.lockResources
            }, null, 8, ["disabled"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}