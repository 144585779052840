
import { PropType, defineComponent } from 'vue';

const __default__ = defineComponent({
  name: 'CardComponent',
  props: {
    overflow: { type: String, default: 'initial' },
    variant: { type: String as PropType<'shaded-border' | 'primary'>, default: 'primary' },
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "03a7777b": (_ctx.overflow)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__