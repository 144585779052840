
import PazzeiInputCode from '@/components/Generics/PazzeiInputCode.vue'
import PazzeiModal from '@/components/Generics/PazzeiModal.vue'
import { InstitutionHasInstitutionPlanDTO } from '@/dtos/ResponseDTOs/InstitutionPlanDTO'
import { LoginResponseDTO } from '@/dtos/ResponseDTOs/LoginResponseDTO'
import TallyService from '@/lib/tally/services/tallyService'
import groupsService from '@/services/groups'
import { AxiosError } from 'axios'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, inject, onUpdated, provide, Ref, ref, watch } from 'vue'
import { ConfirmationEmailDTO } from '../../dtos/ConfirmationEmailDTO'
import { EditEmailDTO } from '../../dtos/EditUserInfosDTO'
import { FormRegistrationDTO } from '../../dtos/FormRegistrationDTO'
import { HandleErrorAxiosResponseDTO } from '../../dtos/HandleErrorAxiosResponseDTO'
import { RegistrationDTO } from '../../dtos/RegistrationDTO'
import { ResponseDTO } from '../../dtos/ResponseDTOs/ResponseDTO'
import { UserDTO } from '../../dtos/ResponseDTOs/UserDTO'
import router from '../../router'
import authenticationService from '../../services/authentication'
import authService from '../../services/authService'
import listsService from '../../services/list'
import usersService from '../../services/users'

export default defineComponent({
  name: 'ModalEmailConfirmation',
  emits: ['close'],
  props: {
    open: { type: Boolean },
    message: { type: String },
    edintingProfile: { type: Boolean },
  },
  components: { PazzeiModal, PazzeiInputCode },
  setup(props, { emit }) {
    const $q = useQuasar()
    const formData = inject<FormRegistrationDTO>('formData')

    const modalVisibility = ref(false)
    const ctrlInputCode = ref({
      code: '',
      codeSended: false,
      invalidCode: false,
    })

    const getUser = inject<Ref<UserDTO>>('userDTO')
    provide('userDTO', getUser)

    onUpdated(() => {
      modalVisibility.value = props.open
    })

    const resendCode = async () => {
      ctrlInputCode.value.codeSended = true

      const confirmation = new ConfirmationEmailDTO(formData!.email, formData!.name)

      authenticationService.post('/request-email-verification', confirmation).then((res) => {
        authService.setOtpToken(res.data.data.otpToken)
      })
    }

    const closeModal = () => {
      emit('close')
    }

    const editEmailUser = async () => {
      if (formData) {
        try {
          const changeData = new EditEmailDTO(formData.email.toLowerCase())
          const res = await usersService.patch<ResponseDTO<LoginResponseDTO>>('/update', changeData)

          sessionStorage.removeItem('otpToken')
          authService.setUserOnLocal(res.data.data.token, res.data.data.refreshToken)

          $q.notify({
            textColor: 'grey-1',
            message: 'Dados salvos com sucesso',
            color: 'green',
            timeout: 2000,
            position: 'top',
            classes: 'notify',
          })

          setTimeout(() => {
            window.location.reload()
          }, 2000)
        } catch (err) {
          if (err instanceof AxiosError) {
            const { error } = new HandleErrorAxiosResponseDTO(err)

            $q.notify({
              textColor: 'grey-1',
              message: error.userMessage,
              color: 'red',
              timeout: 2000,
              position: 'top',
              classes: 'notify',
            })
          }
        }
      }
    }

    const registerUser = async () => {
      if (formData) {
        try {
          const otpToken = sessionStorage.getItem('otpToken')
          const resgistrationData = new RegistrationDTO(
            formData.name,
            formData.lastName,
            formData.telephone?.replace(/(\(|\)| |-)/gm, ''),
            formData.cpf?.replace(/\D/g, ''),
            formData.email.toLowerCase(),
            formData.password,
            ctrlInputCode.value.code
          )
          const res = await usersService.post<ResponseDTO<LoginResponseDTO>>('/', resgistrationData, { headers: { Authorization: `Bearer ${otpToken}` } })

          $q.notify({
            textColor: 'grey-1',
            message: 'Usuário criado com sucesso',
            color: 'green',
            timeout: 2000,
            position: 'top',
            classes: 'notify',
          })

          authService.setUserOnLocal(res.data.data.token, res.data.data.refreshToken)

          const { data } = await listsService.get<ResponseDTO<Array<string>>>('/areas')
          localStorage.setItem('areas', JSON.stringify(data.data))

          const userMe = await usersService.get<ResponseDTO<UserDTO>>('/me')
          const userId = userMe.data.data.id;

          if (getUser) {
            getUser.value = new UserDTO({
              name: userMe.data.data.name,
              lastName: userMe.data.data.lastName,
              email: userMe.data.data.email,
              roleCode: userMe.data.data.roleCode,
              registrationCompleted: userMe.data.data.registrationCompleted,
              credits: userMe.data.data.credits,
              autoRenew: userMe.data.data.autoRenew,
              groupId: 0,
            })

            sessionStorage.setItem(
              'userInfos',
              JSON.stringify({
                credits: getUser.value.credits,
                autoRenew: getUser.value.autoRenew,
                institutionId: userMe.data.data.institutionId,
                roleCode: userMe.data.data.roleCode,
              })
            )
            sessionStorage.setItem('warningHasBeenShown', 'false')
          }
          setTimeout(() => {
            if(localStorage.getItem('firstAccess') === 'true') {
              localStorage.removeItem('firstAccess');
              if (authService.verifyPermissions() === 'home') router.push('/listas-compartilhadas')
            }
            else {
              if (authService.verifyPermissions() === 'home') router.push(localStorage.getItem('lastPage') ?? '/')
              else router.push('/checkout')
            }
          }, 2000)

          const userLogged = localStorage.getItem('token') ?? sessionStorage.getItem('token')
          if (userLogged) {
            const userInfos: any = sessionStorage.getItem('userInfos');
            const institutionId = JSON.parse(userInfos)?.institutionId;
            let institutionPlanId;

            if(institutionId) {
              try {
                const { data: entity } = await groupsService.get<ResponseDTO<InstitutionHasInstitutionPlanDTO>>(
                  `/institution_plans/single?id=${institutionId}`
                );
                
                institutionPlanId = entity.data.institutionPlanId;
              } catch (error) {
                localStorage.removeItem('plan')
              }
            }

            TallyService.loginForm(userId, institutionPlanId, JSON.parse(userInfos));
          }

          sessionStorage.removeItem('otpToken')
        } catch (err) {
          const { error } = new HandleErrorAxiosResponseDTO(err)
          $q.notify({
            textColor: 'grey-1',
            message: error.userMessage,
            color: 'red',
            timeout: 2000,
            position: 'top',
            classes: 'notify',
          })
        }
      }
    }
    const t = ref(false)

    const validateOtp = async () => {
      if (!t.value) {
        t.value = true

        if (props.edintingProfile) editEmailUser()
        else registerUser()
      }
    }

    const getCode = (codeInput: string) => {
      if (codeInput.length >= 6) {
        ctrlInputCode.value.code = codeInput
      }
    }

    watch(
      ctrlInputCode.value,
      () => {
        if (ctrlInputCode.value.code.length === 6) {
          t.value = false
          validateOtp()
        }
      },
      { deep: true }
    )
    return { getCode, close, resendCode, closeModal, ctrlInputCode, modalVisibility }
  },
})
