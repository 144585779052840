import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TrialCard = _resolveComponent("TrialCard")!
  const _component_EnemModule = _resolveComponent("EnemModule")!
  const _component_EnemTestSummary = _resolveComponent("EnemTestSummary")!
  const _component_MainLayoutSubWrapper = _resolveComponent("MainLayoutSubWrapper")!
  const _component_MainLayoutWrapper = _resolveComponent("MainLayoutWrapper")!
  const _component_GenericLayout = _resolveComponent("GenericLayout")!

  return (_openBlock(), _createBlock(_component_GenericLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_MainLayoutWrapper, { "answer-list": "" }, {
        main: _withCtx(() => [
          _createVNode(_component_MainLayoutSubWrapper, { "answer-list": "" }, {
            title: _withCtx(() => [
              _createVNode(_component_TrialCard),
              _createElementVNode("p", {
                class: _normalizeClass(["q-ma-none h1-title", { summary: _ctx.isSummary }])
              }, _toDisplayString(_ctx.isSummary ? 'Resumo da Prova' : 'Módulo Enem'), 3)
            ]),
            main: _withCtx(() => [
              (!_ctx.isSummary)
                ? (_openBlock(), _createBlock(_component_EnemModule, {
                    key: 0,
                    onSelectedAreas: _ctx.onAreasSelected,
                    cache: _ctx.largeAreasCache,
                    lockResources: _ctx.isForbidden
                  }, null, 8, ["onSelectedAreas", "cache", "lockResources"]))
                : (_openBlock(), _createBlock(_component_EnemTestSummary, {
                    key: 1,
                    "knowledge-areas": _ctx.areas,
                    summary: _ctx.isSummary,
                    "onUpdate:summary": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isSummary) = $event)),
                    cache: _ctx.largeAreasCache,
                    "onUpdate:cache": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.largeAreasCache) = $event)),
                    lockResources: _ctx.isForbidden
                  }, null, 8, ["knowledge-areas", "summary", "cache", "lockResources"]))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}