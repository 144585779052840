
import PazzeiButton from '@/components/Generics/Redesign/PazzeiButton.vue';
import DefaultTheme from '@/theme/defaultTheme';
import { defineComponent } from 'vue';
import ForwardIcon from '../Icons/default/redesign/sharedList/ForwardIcon.vue';
import ReturnIcon from '../Icons/default/redesign/sharedList/ReturnIcon.vue';

export default defineComponent({
  name: 'ListQuestionsNavigation',
  components: { PazzeiButton },
  props: {
    hideQuestionNumber: { type: Boolean, default: false },
    isDoubt: { type: Boolean, required: true },
    questionNumber: { type: Number, required: true },
    totalQuestions: { type: Number, required: true },
    goForward: { type: Function, required: true },
    goBack: { type: Function, required: true },
  },
  setup() {
    return {
      ForwardIcon,
      ReturnIcon,
      DefaultTheme,
    };
  },
})
