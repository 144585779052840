<template>
  <div v-if="!isMobile">
    <div class="list-header" :class="{'answering-list': answeringList ?? true}">
      <slot name="list-name"></slot>
      <div class="list-current-info__container">
        <slot name="list-progress"></slot>
        <slot name="stopwatch"></slot>
      </div>
      <slot name="cta-wrapper"></slot>

    </div>
  </div>
  <div v-else-if="isMobile">
    <div class="list-header list-header__mobile" :class="{'answering-list': answeringList ?? true}">
      <slot name="list-name"></slot>
      <div class="list-current-info__container list-current-info__container__mobile">
        <slot name="list-progress"></slot>
        <slot name="stopwatch"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    answeringList: Boolean,
    isMobile: { type: Boolean, required: true }
  },
  setup() {
    //
  },
})
</script>

<style scoped>
.list-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 42px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--default-border);
}

.list-header.answering-list {
  gap: 32px;
}

.list-current-info__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 12px;
}

@media only screen and (max-width: 768px) {
  .list-header {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding-bottom: 0;
    border-bottom: 1px solid var(--default-border);
    height: fit-content;
  }

  .list-header.answering-list {
    row-gap: 8px;
    padding-bottom: 16px;
  }
}
</style>
