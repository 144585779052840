
import DefaultTheme from '@/theme/defaultTheme';
import { defineComponent } from 'vue';
import CheckIcon from '../Icons/default/CheckIcon.vue';

export default defineComponent({
  components: {
    CheckIcon,
  },
  setup() {
    return {
      DefaultTheme,
    }
  },
})
