
import ContentModalEditGroupName from '@/components/Application/planSchoolPortal/modals/modalsGroupComponent/ContentModalEditGroupName.vue'
import ContentModalRegisterGroups from '@/components/Application/planSchoolPortal/modals/modalsGroupComponent/ContentModalRegisterGroups.vue'
import PaginationTableComponent from '@/components/Application/planSchoolPortal/mountTableComponent/components/PaginationTableComponent.vue'
import TablesComponentGroups from '@/components/Application/planSchoolPortal/mountTableComponent/TablesComponent.vue'
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiInput from '@/components/Generics/PazzeiInput.vue'
import AddIcon from '@/components/Icons/default/AddIcon.vue'
import EditRegisterIcon from '@/components/Icons/default/EditRegisterIcon.vue'
import EstatisticsIcon from '@/components/Icons/default/EstatisticsIcon.vue'
import RemoveSchoolIcon from '@/components/Icons/default/RemoveSchoolIcon.vue'
import SearchIcon from '@/components/Icons/default/SearchIcon.vue'
import ViewStudentsIcon from '@/components/Icons/default/ViewStudentsIcon.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import Modal from '@/components/Library/Modal/index.vue'
import ModalConfirmation from '@/components/Library/ModalConfirmation/index.vue'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import groupsService from '@/services/groups'
import DefaultTheme from '@/theme/defaultTheme'
import { EntitiesGroups, Groups } from '@/types/GroupsType'
import { User } from '@/types/UsersType'
import { columnsGroups } from '@/utils/hardCoded/planSchool/groups/groups'
import debounce from 'quasar/src/utils/debounce.js';import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, onBeforeUnmount, onMounted, provide, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'ViewGroupsPage',
  components: {
    GenericLayout,
    MainLayoutWrapper,
    PazzeiBtn,
    PazzeiInput,
    Modal,
    TablesComponentGroups,
    PaginationTableComponent,
    ContentModalRegisterGroups,
    ContentModalEditGroupName,
    ModalConfirmation,
    SearchIcon
  },

  setup() {
    const isSearchInputExpanded = ref(true)
    const isModalOpen = ref(false)
    const isEditing = ref(false)
    const isModalOpenEditName = ref(false)
    const isModalOpenAddStudent = ref(false)
    const sizeColumnGroups = ref('1fr / 3.5fr 3.5fr 2fr 0.25fr')
    const optionsPagination = [10, 20, 50, 100]
    const router = useRouter()
    const innerWidth = ref(window.innerWidth)
    const width = ref('66.875em')
    const height = ref('34em')
    const widthModalSmall = ref('60.625em')
    const heightModalSmall = ref('24.5em')
    const currentPage = ref(1)
    const pageSize = ref(20)
    const isLoading = ref(false)
    const sizeLoading = ref('60px')
    const totalElements = ref(0)
    const rowsGroups = ref<Groups[]>([])
    const updatedRowsGroups = ref<Partial<Groups> & { owner: string }[]>([])
    const filterField = ref<string | undefined>('')
    const heightLoading = ref('13.5em')
    const isModalConfirmOpen = ref(false)
    const isAddUser = ref(false)
    const groupId = ref('')
    const listUserByInstitution = ref<User[]>()
    const $q = useQuasar()
    const previousPageSize = ref(20)
    const previousCurrentPage = ref(1)
    const heightTable = ref('49em')
    const widthTable = ref('70em')
    const sizeEditLoading = ref('3em')

    const openModalEditName = () => (isModalOpenEditName.value = true)
    const openModal = () => (isModalOpen.value = true)
    const closeModal = () => ((isModalOpen.value = false), (isAddUser.value = !isAddUser.value))
    const closeModalEditName = () => (isModalOpenEditName.value = false)
    const handleModalConfirm = () => (isModalConfirmOpen.value = true)
    const setNewTab = () => {
      innerWidth.value = window.innerWidth
    }
    window.addEventListener('resize', setNewTab)

    const handlePageSizeChange = (selectedPageSize: number) => {
      if (selectedPageSize !== previousPageSize.value) {
        pageSize.value = selectedPageSize
        getGroupData()
        previousPageSize.value = selectedPageSize
      }
    }

    const handleCurrentPage = (selectedCurrentPage: number) => {
      if (selectedCurrentPage !== previousCurrentPage.value) {
        currentPage.value = selectedCurrentPage
        getGroupData()
        previousCurrentPage.value = selectedCurrentPage
      }
    }

    onMounted(() => {
      if (innerWidth.value <= 768) {
        width.value = '90%'
        height.value = '20em'
        widthModalSmall.value = '90%'
        heightModalSmall.value = '14.5em'
        sizeColumnGroups.value = '1fr / 2.5fr 2.5fr 1fr 0.25fr'
        heightTable.value = '58vh'
        sizeLoading.value = '2.1875em'
        widthTable.value = '53em'
        heightLoading.value = '16em'
        sizeEditLoading.value = '2em'
      }

      if (innerWidth.value > 768 && innerWidth.value <= 1500) {
        height.value = '32.5em'
        width.value = '85%'
      }

      getGroupData()
    })

    const iconProps = {
      fill: DefaultTheme().colors.pinkColor,
      stroke: DefaultTheme().colors.pinkColor,
      size: 20,
    }

    const optionsMenu = ref([
      { label: 'Ver Usuários', icon: ViewStudentsIcon, iconProps, action: (row: Groups) => router.push({ name: 'viewStudentsGroupPage', params: { id: row.id } }) },
      { label: 'Renomear Grupo', icon: EditRegisterIcon, iconProps, action: () => openModalEditName?.() },
      { label: 'Adicionar Usuário', icon: AddIcon, iconProps, action: () => openModal?.() },
      { label: 'Excluir Grupo', icon: RemoveSchoolIcon, iconProps, action: () => handleModalConfirm?.() },
      { label: 'Ver Estatísticas', icon: EstatisticsIcon, iconProps, action: () => '', disabled: true },
    ])

    const handleValueUpdated = (value: Groups) => {
      groupId.value = value.id
      isAddUser.value = true
    }

    const removeGroup = async () => {
      try {
        await groupsService.delete<Promise<Groups>>('/', { data: { id: groupId.value } })

        $q.notify({
          textColor: 'grey-1',
          message: 'Grupo deletado com sucesso',
          color: 'green',
          position: 'top',
          classes: 'notify',
        })

        getGroupData()
      } catch (error) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao deletar grupo',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    const getGroupData = async () => {
      const requestBody = {
        page: currentPage.value,
        pageSize: pageSize.value,
        search: {
          name: filterField.value !== '' ? filterField.value : undefined,
        },
      }

      isLoading.value = true
      try {
        const response = await groupsService.post<ResponseDTO<EntitiesGroups>>('/list', requestBody)
        rowsGroups.value = response.data.data.entities
        totalElements.value = response.data.data.totalElements
        updatedRowsGroups.value = rowsGroups.value.map((row: Groups) => {
          return {
            name: row.name,
            owner: `${row.owner.name} ${row.owner.lastName}`,
            institutionId: row.institutionId,
            id: row.id,
            members: row.members,
          }
        })
      } catch (error) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao buscar dados',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      } finally {
        isLoading.value = false
      }
    }

    const debouncedGetGroupData = debounce(() => {
      getGroupData()
    }, 500)

    watch(filterField, () => {
      currentPage.value = 1
      debouncedGetGroupData()
    })

    provide('heightLoading', heightLoading)
    provide('optionsMenu', optionsMenu)
    provide('emitValueToParent', handleValueUpdated)

    onBeforeUnmount(() => {
      window.removeEventListener('resize', setNewTab)
    })

    return {
      isSearchInputExpanded,
      isModalOpen,
      openModal,
      optionsPagination,
      innerWidth,
      width,
      height,
      closeModal,
      isEditing,
      columnsGroups,
      updatedRowsGroups,
      sizeColumnGroups,
      isModalOpenEditName,
      isModalOpenAddStudent,
      openModalEditName,
      widthModalSmall,
      heightModalSmall,
      closeModalEditName,
      handlePageSizeChange,
      handleCurrentPage,
      sizeLoading,
      isLoading,
      totalElements,
      isModalConfirmOpen,
      groupId,
      listUserByInstitution,
      removeGroup,
      getGroupData,
      isAddUser,
      filterField,
      heightTable,
      widthTable,
      sizeEditLoading,
      AddIcon,
      DefaultTheme,
    }
  },
})
