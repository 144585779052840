
import authService from '@/services/authService';
import DefaultTheme from '@/theme/defaultTheme';
import { defineComponent, onMounted, PropType, ref } from 'vue';

export default defineComponent({
  name: 'B2bAuthLayout',
  props: {
    tab: String,
    heading: {
      type: Object as PropType<{
        title?: string;
        subtitle?: string;
      }>,
      required: false,
      default: () => ({})
    },
    hasTab: Boolean,
    textCenter: Boolean,
    greetings: Boolean,
  },
  setup() {
    const person = ref<string | undefined>('')

    onMounted(() => {
      person.value = authService.getPermissions()?.roleDescription
    })

    return {
      person,
      DefaultTheme
    }
  },
})
