import { RouteRecordRaw } from 'vue-router'
import { redirectLink } from '@/router/redirect'
import LoginPage from '@/views/LoginPage.vue'
import LoginB2bPage from '@/views/LoginB2bPage/LoginB2bPage.vue'
import ViewAccessB2BPage from '@/views/ViewAccessB2BPage.vue'
import SignUpB2bInvitePage from '@/views/SignUpB2bInvitePage/SignUpB2bInvitePage.vue'
import SignUpB2bPage from '@/views/SignUpB2bPage/SignUpB2bPage.vue'
import RegisterPage from '@/views/RegisterPage.vue'
import ExerciseListsPage from '@/views/ExerciseListsPage.vue'
import HomePage from '@/views/HomePage.vue'
import AnswerListPage from '@/views/AnswerListPage.vue'
import RedesignedAnswerListPage from '@/views/Redesign/AnswerListPage/AnswerListPage.vue'
import CreateListPage from '@/views/CreateListPage.vue'
import ViewMorePage from '@/views/ViewMorePage.vue'
import AnswerSheetPage from '@/views/AnswerSheetPage.vue'
import ReportsPage from '@/views/ReportsPage.vue'
import CheckoutPage from '@/views/CheckoutPage.vue'
import RefreshPage from '@/views/RefreshPage.vue'
import MyProfile from '@/components/EditingProfile/MyProfile.vue'
import MyCredentials from '@/components/EditingProfile/MyCredentials.vue'
import MyPreferences from '@/components/EditingProfile/MyPreferences.vue'
import MyPlans from '@/components/EditingProfile/MyPlans.vue'
import TestsPage from '@/views/TestsPage.vue'
import ViewMoreTestsPage from '@/views/ViewMoreTestsPage.vue'
import ViewMoreSharedList from '@/views/ViewMoreSharedList.vue'
import ViewInstitutionsPage from '@/views/ViewInstitutionsPage.vue'
import ViewQuestionsPage from '@/views/ViewQuestionsPage.vue'
import ViewListTestsPageVue from '@/views/ViewListTestsPage.vue'
import ViewUsersPage from '@/views/ViewUsersPage.vue'
import ViewPurchaseHistory from '@/views/ViewPurchaseHistory.vue'
import ViewStudentsInstitutionsPage from '@/views/ViewStudentsInstitutionsPage.vue'
import PreRegistrationPage from '@/views/PreRegistrationPage.vue'
import ViewGroupsPage from '@/views/ViewGroupsPage.vue'
import ViewStudentsGroupPage from '@/views/ViewStudentsGroupPage.vue'
import PaymentFinishedPage from '@/components/Checkout/PaymentFinishedPage.vue'
import ViewSharedLists from '@/views/ViewSharedLists.vue'
import ForbiddenPage from '@/views/ForbiddenPage.vue'
import InviteToTestPage from '@/views/InviteToTestPage.vue'
import ViewSharingHistory from '@/views/ViewSharingHistory.vue'
import ViewUsersCouponPage from '@/views/ViewUsersCouponPage.vue'
import ViewCouponPage from '@/views/ViewCouponPage.vue'
import ProficiencyTestPage from '@/views/ProficiencyTestPage.vue'
import AnswerTestPage from '@/views/AnswerTestPage.vue'
import ViewEnemModule from '@/views/ViewEnemModule.vue'
import ViewCheckAnswerVue from '@/views/ViewCheckAnswer.vue'
import TabsBi from '@/components/TabsAnalise/TabsBi.vue'
import ChangePasswordPage from '@/views/ChangePasswordPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/redirecting',
    name: 'redirectPage',
    component: {
      template: '<div></div>',
    },
    beforeEnter: async (to, from, next) => {
      await redirectLink(to, from, next)
    },
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/login',
    name: 'loginPage',
    component: LoginPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/b2b/login',
    name: 'b2bLoginPage',
    component: LoginB2bPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/b2b/access',
    name: 'b2bAccessPage',
    component: ViewAccessB2BPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/login-convite',
    name: 'b2bSignUpInvitePage',
    component: SignUpB2bInvitePage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/b2b/registro',
    name: 'b2bSignUpPage',
    component: SignUpB2bPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/cadastro',
    name: 'registerPage',
    component: RegisterPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/listas',
    name: 'exerciseListsPage',
    component: ExerciseListsPage,
    meta: {
      requiresAuth: true,
      requiredScoped: ['self'],
      requiredPermission: 'tests:lists:retrieve',
    },
  },
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: {
      requiresAuth: true,
      requiredScoped: ['self'],
      requiredPermission: 'users:users:manage',
    },
  },
  {
    path: '/responder-lista-enem/:id/:tryId?',
    name: 'enemAnswerListPage',
    component: AnswerListPage,
    meta: {
      requiresAuth: true,
      requiredScoped: ['self'],
      requiredPermission: 'questions:responses:manage',
    },
  },
  {
    path: '/responder-lista/:id/:tryId?',
    name: 'answerListPage',
    component: RedesignedAnswerListPage,
    meta: {
      requiresAuth: true,
      requiredScoped: ['self'],
      requiredPermission: 'questions:responses:manage',
    },
  },
  {
    path: '/criar-lista',
    name: 'filterPage',
    component: CreateListPage,
    meta: {
      requiresAuth: true,
      requiredPermission: 'lists:lists:retrieve',
    },
  },
  {
    path: '/ver-mais/:id',
    name: 'viewMorePage',
    component: ViewMorePage,
    meta: {
      requiresAuth: true,
      requiredScoped: ['self'],
      requiredPermission: 'lists:lists:retrieve',
    },
  },
  {
    path: '/gabarito/:id/:tryId?',
    name: 'answerSheetPage',
    component: AnswerSheetPage,
    meta: {
      requiresAuth: true,
      requiredScoped: ['self'],
      requiredPermission: 'reports:feedback:retrieve',
    },
  },
  {
    path: '/checkout',
    name: 'checkoutPage',
    component: CheckoutPage,
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    redirect: '/',
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/refresh',
    name: 'refreshPage',
    component: RefreshPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/meus-dados',
    name: 'MyProfile',
    component: MyProfile,
    meta: {
      requiresAuth: true,
      requiredPermission: 'users:users:manage',
    },
  },
  {
    path: '/dados-da-conta',
    name: 'MyCredentials',
    component: MyCredentials,
    meta: {
      requiresAuth: true,
      requiredPermission: 'users:users:manage',
    },
  },
  {
    path: '/perfil-do-aluno',
    name: 'MyPreferences',
    component: MyPreferences,
    meta: {
      requiresAuth: true,
      requiredScoped: ['self'],
      requiredPermission: 'users:users:manage',
    },
  },
  {
    path: '/meus-planos',
    name: 'MyPlans',
    component: MyPlans,
    meta: {
      requiresAuth: true,
      requiredScoped: ['self'],
      requiredPermission: 'payments:plans:retrieve',
    },
  },
  {
    path: '/banco-de-provas',
    name: 'testsPage',
    component: TestsPage,
    meta: {
      requiresAuth: true,
      requiredScoped: ['self'],
      requiredPermission: 'tests:lists:retrieve',
    },
  },
  {
    path: '/ver-mais-banco-de-provas/:id',
    name: 'viewMoreTestsPage',
    component: ViewMoreTestsPage,
    meta: {
      requiresAuth: true,
      requiredScoped: ['self'],
      requiredPermission: 'tests:lists:retrieve',
    },
  },
  {
    path: '/ver-mais-listas-compartilhadas/:id',
    name: 'ViewMoreSharedList',
    component: ViewMoreSharedList,
    meta: {
      requiresAuth: true,
      requiredScoped: ['self'],
      requiredPermission: 'lists:lists:retrieve',
    },
  },
  {
    path: '/instituicoes',
    name: 'viewInstitutionsPage',
    component: ViewInstitutionsPage,
    meta: {
      requiresAuth: true,
      requiredScoped: ['all'],
      requiredPermission: 'groups:institutions:retrieve',
    },
  },
  {
    path: '/exercicios',
    name: 'viewQuestionsPage',
    component: ViewQuestionsPage,
    meta: {
      requiresAuth: true,
      requiredScoped: ['root', 'group'],
      requiredPermission: 'lists:lists:retrieve',
    },
  },
  {
    path: '/provas',
    name: 'viewListBancoDeProvas',
    component: ViewListTestsPageVue,
    meta: {
      requiresAuth: true,
      requiredScoped: ['root', 'group'],
      requiredPermission: 'lists:lists:retrieve',
    },
  },
  {
    path: '/usuarios',
    name: 'viewUsersPage',
    component: ViewUsersPage,
    meta: {
      requiresAuth: true,
      requiredScoped: ['all', 'root', 'group'],
      requiredPermission: 'users:users:retrieve',
    },
  },
  {
    path: '/historico-compras/:id',
    name: 'viewPurchaseHistory',
    component: ViewPurchaseHistory,
    meta: {
      requiresAuth: true,
      requiredScoped: ['all'],
      requiredPermission: 'payments:billings:retrieve',
    },
  },
  {
    path: '/instituicoes-alunos/:id',
    name: 'viewStudentsInstitutionsPage',
    component: ViewStudentsInstitutionsPage,
    meta: {
      requiresAuth: true,
      requiredScoped: ['all', 'root'],
      requiredPermission: 'users:users:retrieve',
    },
  },
  {
    path: '/completar-cadastro/:id?',
    name: 'schoolPlanLoginPage',
    component: PreRegistrationPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/grupos',
    name: 'viewGroupsPage',
    component: ViewGroupsPage,
    meta: {
      requiresAuth: true,
      requiredScoped: ['all', 'root', 'group'],
      requiredPermission: 'groups:groups:retrieve',
    },
  },
  {
    path: '/grupo-alunos/:id',
    name: 'viewStudentsGroupPage',
    component: ViewStudentsGroupPage,
    meta: {
      requiresAuth: true,
      requiredScoped: ['all', 'root', 'group'],
      requiredPermission: 'users:users:retrieve',
    },
  },
  {
    path: '/bem-vindo',
    name: 'PaymentFinishedPage',
    component: PaymentFinishedPage,
    meta: {
      requiresAuth: true,
      requiredScoped: ['self'],
      requiredPermission: 'payments:payments:checkout',
    },
  },
  {
    path: '/listas-compartilhadas',
    name: 'ViewSharedList',
    component: ViewSharedLists,
    meta: {
      requiresAuth: true,
      requiredScoped: ['self'],
      requiredPermission: 'lists:shared-lists:retrieve',
    },
  },
  {
    path: '/acesso-restrito',
    name: 'ForbiddenPage',
    component: ForbiddenPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/testar-o-pazzei',
    name: 'InviteToTestPage',
    component: InviteToTestPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/historico-compartilhamento/:id',
    name: 'viewSharingHistory',
    component: ViewSharingHistory,
    meta: {
      requiresAuth: true,
      requiredScoped: ['root', 'group'],
      requiredPermission: 'lists:lists:retrieve',
    },
  },
  {
    path: '/cupons/:id',
    name: 'viewUsersCouponPage',
    component: ViewUsersCouponPage,
    meta: {
      requiresAuth: true,
      requiredScoped: ['all'],
      requiredPermission: 'payments:coupons:retrieve',
    },
  },
  {
    path: '/cupons',
    name: 'viewCouponPage',
    component: ViewCouponPage,
    meta: {
      requiresAuth: true,
      requiredScoped: ['all'],
      requiredPermission: 'payments:coupons:retrieve',
    },
  },
  {
    path: '/teste-de-proficiencia',
    name: 'ProficiencyTestPage',
    component: ProficiencyTestPage,
    meta: {
      requiresAuth: true,
      requiredScoped: ['self'],
      requiredPermission: 'lists:tri:retrieve',
    },
  },
  {
    path: '/responder-teste/:id',
    name: 'answerTestPage',
    component: AnswerTestPage,
    meta: {
      requiresAuth: true,
      requiredScoped: ['self'],
      requiredPermission: 'questions:tri:manage',
    },
  },
  {
    path: '/modulo-enem',
    name: 'ViewEnemModule',
    component: ViewEnemModule,
    meta: {
      requiresAuth: true,
      requiredScoped: ['self'],
      requiredPermission: 'lists:lists:enem-simulation',
    },
  },
  {
    path: '/verificar-respostas/:id/:tryId',
    name: 'ViewCheckAnswerVue',
    component: ViewCheckAnswerVue,
    meta: {
      requiresAuth: true,
      requiredScoped: ['self'],
      requiredPermission: 'questions:responses:manage',
    },
  },
  {
    path: '/analise-de-desempenho',
    name: 'TabsBi',
    component: TabsBi,
    meta: {
      requiresAuth: false,
      requiredScoped: ['root', 'group'],
      requiredPermission: 'lists:lists:retrieve',
    },
  },
  {
    path: '/redefinir-senha',
    name: 'ChangePasswordPage',
    component: ChangePasswordPage,
    meta: {
      requiresAuth: true,
      requiredScoped: ['self'],
      requiredPermission: 'users:users:manage',
    },
  },
]

export default routes;
