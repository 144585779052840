
import SubjectList from '@/components/Generics/PazzeiSubjectList.vue'
import ArrowBackIcon from '@/components/Icons/default/ArrowBackIcon.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutSubWrapper from '@/components/Layouts/MainLayoutSubWrapper.vue'
import listService from '@/services/list'
import DefaultTheme from '@/theme/defaultTheme'
import { defineComponent, onMounted, onUnmounted, ref } from 'vue-demi'
import { useRoute } from 'vue-router'
import MainLayoutWrapper from '../components/Layouts/MainLayoutWrapper.vue'
import { SubjectInfoDTO } from '../dtos/SubjectInfoDTO'
import { inject } from 'vue'
import { InstitutionConfigType } from '@/types/InstitutionConfigType'

export default defineComponent({
  name: 'ViewMorePage',
  components: {
    GenericLayout,
    MainLayoutSubWrapper,
    SubjectList,
    MainLayoutWrapper,
    ArrowBackIcon
  },
  setup() {
    const controller = new AbortController()
    const route = useRoute()
    const id = route.params.id
    const exerciseLists = ref(Array<SubjectInfoDTO>())
    const isLoading = ref(true)
    const institutionConfig = inject<InstitutionConfigType>('institutionConfig');

    onMounted(async () => {
      const pageSize = id === 'listas-iniciadas' ? Number(sessionStorage.getItem('listsStarted') ?? 20) : Number(sessionStorage.getItem('listsNotStarted') ?? 20)
      const page = Math.ceil(pageSize / 100)

      for (let i = 1; i <= page; i++)
        await listService.post('/shared', { pageSize: 100, page: i, filter: { started: id === 'listas-novas' ? false : true, isExpired: false } }, { signal: controller.signal }).then((res) => {
          exerciseLists.value = [...exerciseLists.value, ...res.data.data.data]
          isLoading.value = false
        })
    })

    onUnmounted(() => controller.abort())

    return { id, exerciseLists, isLoading, DefaultTheme, isWhiteLabel: institutionConfig?.isWhiteLabel }
  },
})
