
import { defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import B2bAuthLayout from '@/components/Layouts/B2bAuthLayout/B2bAuthLayout.vue'
import B2bAuthForm from '@/components/LoginForms/B2bAuthForm/B2bAuthForm.vue'

export default defineComponent({
  name: 'LoginB2bPage',
  components: {
    B2bAuthLayout,
    B2bAuthForm,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const removeQueryParam = (param: string) => {
      const { [param]: _, ...newQuery } = route.query;
      router.replace({ query: newQuery }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    }

    const getCodeFromQuery = () => {
      const { cod } = route.query;
      const listCode = cod;

      removeQueryParam('cod');

      return (listCode && listCode.length === 10) ? listCode as string : undefined;
    }

    const heading = {
      title: 'Bem-vindo ao Pazzei!',
      subtitle: 'Tudo pronto para começar a resolver suas listas?'
    }

    return {
      listCode: getCodeFromQuery(),
      heading,
    }
  },
})
