
import DefaultTheme from '@/theme/defaultTheme';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StopWatchEyeIcon',
  props: {
    eyeOff: { type: Boolean, default: false },
    size: { type: Number, default: 20 },
    stroke: { type: String, default: DefaultTheme().colors.primary600 },
  },
  setup() {
    return {}
  },
})
