
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, onMounted } from 'vue'
import router from '../router'
import authService from '../services/authService'
import authenticationService from '../services/authentication'

export default defineComponent({
  name: 'RefreshPage',
  setup() {
    const $q = useQuasar()
    onMounted(() => {
      const checkToken = localStorage.getItem('refreshToken')

      if (!checkToken) authService.logoutUser()
      else
        authenticationService
          .post(
            '/token-sign-in',
            {},
            {
              headers: {
                Authorization: `Bearer ${checkToken}`,
              },
            }
          )
          .then((res) => {
            authService.setUserOnLocal(res.data.data.token, res.data.data.refreshToken)
            router.push(localStorage.getItem('lastPage') ?? '/')
          })
          .catch(() => {
            $q.notify({
              textColor: 'grey-1',
              message: 'Sessão expirada',
              color: 'red',
              position: 'top',
              classes: 'notify',
            })
            authService.logoutUser()
          })
    })
  },
})
