import { LayoutMenuItem } from '@/types/MenuType'
import HomeIcon from '@/components/Icons/default/redesign/HomeIcon.vue'
import DefaultTheme from '@/theme/defaultTheme'
import CreateListIcon from '@/components/Icons/default/redesign/CreateListIcon.vue'
import PreviousTestsIcon from '@/components/Icons/default/redesign/PreviousTestsIcon.vue'
import TestKnowledgeIcon from '@/components/Icons/default/redesign/TestKnowledgeIcon.vue'
import EnemModuleIcon from '@/components/Icons/default/redesign/EnemModuleIcon.vue'
import SharedListsIcon from '@/components/Icons/default/SharedListsIcon.vue'
import InstitutionIcon from '@/components/Icons/default/redesign/InstitutionIcon.vue'
import UsersIcon from '@/components/Icons/default/redesign/UsersIcon.vue'
import GroupsIcon from '@/components/Icons/default/redesign/GroupsIcon.vue'
import ReportsIcon from '@/components/Icons/default/ReportsIcon.vue'
import CouponsIcon from '@/components/Icons/default/redesign/CouponsIcon.vue'
import MyAccountIcon from '@/components/Icons/default/redesign/MyAccountIcon.vue'
import LogoutIcon from '@/components/Icons/default/redesign/LogoutIcon.vue'
import { UserDTO } from '@/dtos/ResponseDTOs/UserDTO'
import { InstitutionConfigType } from '@/types/InstitutionConfigType'
import authService from '@/services/authService'

export const genericLayoutMenuItems: {
  common: (institutionInfo?: InstitutionConfigType, user?: UserDTO) => LayoutMenuItem[],
  auth: (institutionInfo?: InstitutionConfigType, user?: UserDTO) => LayoutMenuItem[],
} = {
  common: (institutionInfo) => {
    const userConfig = authService.getPermissions();
    const enemPermissions = userConfig?.permissions.filter(permission => permission.includes('enem')) || [];
    
    const items = [
      {
        icon: HomeIcon,
        iconProps: {fill: DefaultTheme().colors.primary, stroke: DefaultTheme().colors.primary},
        label: 'Início',
        separator: false,
        to: '/'
      },
      {
        icon: CreateListIcon,
        iconProps: {fill: DefaultTheme().colors.primary, stroke: DefaultTheme().colors.primary},
        label: 'Montar lista',
        separator: false,
        to: '/listas',
      },
      {
        icon: PreviousTestsIcon,
        iconProps: {fill: DefaultTheme().colors.primary, stroke: DefaultTheme().colors.primary},
        label: 'Provas anteriores',
        separator: false,
        to: '/banco-de-provas',
      },
      {
        icon: TestKnowledgeIcon,
        iconProps: {fill: DefaultTheme().colors.primary, stroke: DefaultTheme().colors.primary},
        label: 'Testar conhecimentos',
        separator: false,
        to: '/teste-de-proficiencia',
      },
      {
        icon: EnemModuleIcon,
        iconProps: {fill: DefaultTheme().colors.primary, stroke: DefaultTheme().colors.primary},
        label: 'Treine para o Enem',
        separator: false,
        to: '/modulo-enem',
      },
      {
        icon: SharedListsIcon,
        iconProps: {fill: DefaultTheme().colors.primary, stroke: DefaultTheme().colors.primary},
        label: 'Listas compartilhadas',
        separator: true,
        to: '/listas-compartilhadas',
      },
      {
        icon: InstitutionIcon,
        iconProps: {fill: DefaultTheme().colors.primary, stroke: DefaultTheme().colors.primary},
        label: 'Instituições',
        separator: true,
        to: '/instituicoes',
      },
      {
        icon: UsersIcon,
        iconProps: {fill: DefaultTheme().colors.primary, stroke: DefaultTheme().colors.primary},
        label: 'Usuários',
        separator: false,
        to: '/usuarios',
      },
      {
        icon: GroupsIcon,
        iconProps: {fill: DefaultTheme().colors.primary, stroke: DefaultTheme().colors.primary},
        label: 'Grupos',
        separator: false,
        to: '/grupos',
      },
      {
        icon: CreateListIcon,
        iconProps: {fill: DefaultTheme().colors.primary, stroke: DefaultTheme().colors.primary},
        label: 'Montar lista',
        separator: false,
        to: '/exercicios',
      },
      {
        icon: PreviousTestsIcon,
        iconProps: {fill: DefaultTheme().colors.primary, stroke: DefaultTheme().colors.primary},
        label: 'Banco de provas',
        separator: false,
        to: '/provas',
      },
      {
        icon: ReportsIcon,
        iconProps: {fill: DefaultTheme().colors.primary, stroke: DefaultTheme().colors.primary},
        label: 'Análise de desempenho',
        separator: true,
        to: '/analise-de-desempenho',
        top: true,
      },
      {
        icon: CouponsIcon,
        iconProps: {fill: DefaultTheme().colors.primary, stroke: DefaultTheme().colors.primary},
        label: 'Cupons',
        separator: false,
        to: '/cupons',
      },
    ];
    
    if (institutionInfo?.isWhiteLabel || !enemPermissions?.length) {
      return items.filter(item => item.label !== 'Treine para o Enem')
    }
    return items;
  },
  auth: () => [
    {
      icon: MyAccountIcon,
      iconProps: {
        fill: DefaultTheme().colors.gray700,
        stroke: DefaultTheme().colors.gray700,
      },
      label: 'Minha conta',
      separator: false,
      to: '/meus-dados',
    },
    {
      icon: LogoutIcon,
      iconProps: {
        fill: DefaultTheme().colors.gray700,
        stroke: DefaultTheme().colors.gray700,
      },
      label: 'Sair',
      separator: false,
      to: '/',
      logout: true,
    },
  ]
}
