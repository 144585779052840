import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.size + 1,
    height: _ctx.size,
    viewBox: "0 0 21 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M10.4996 10.0001H10.508M13.4459 12.9464C9.54067 16.8516 5.05575 18.6984 3.42857 17.0712C1.80138 15.444 3.64811 10.9591 7.55336 7.05384C11.4586 3.1486 15.9435 1.30187 17.5707 2.92905C19.1979 4.55624 17.3512 9.04115 13.4459 12.9464ZM13.4459 7.05369C17.3511 10.9589 19.1979 15.4439 17.5707 17.071C15.9435 18.6982 11.4586 16.8515 7.55332 12.9462C3.64808 9.041 1.80135 4.55609 3.42853 2.9289C5.05572 1.30172 9.54064 3.14845 13.4459 7.05369ZM10.9163 10.0001C10.9163 10.2302 10.7298 10.4168 10.4996 10.4168C10.2695 10.4168 10.083 10.2302 10.083 10.0001C10.083 9.77 10.2695 9.58345 10.4996 9.58345C10.7298 9.58345 10.9163 9.77 10.9163 10.0001Z",
      stroke: _ctx.stroke,
      "stroke-width": "1.66667",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}