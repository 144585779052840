import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c7505ac2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-button-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card-button-container", { 'card-button-container--active': _ctx.selected }]),
    style: _normalizeStyle({ 'pointer-events': _ctx.isDisabled ? 'none' : 'auto', opacity: _ctx.isDisabled ? '0.5' : '1' })
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.label.replace('G1 - ', '')), 1)
  ], 6))
}