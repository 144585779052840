
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiInput from '@/components/Generics/PazzeiInput.vue'
import { UserInfosDTO } from '@/dtos/UserInfosDTO'
import usersService from '@/services/users'
import email from '@/utils/vuelidateRules/emailRules'
import password from '@/utils/vuelidateRules/passwordRules'
import { useVuelidate } from '@vuelidate/core'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { computed, defineComponent, onMounted, onUnmounted, provide, reactive, ref } from 'vue'
import { AuthenticationDTO } from '../../dtos/AuthenticationDTO'
import { ConfirmationEmailDTO } from '../../dtos/ConfirmationEmailDTO'
import { ValidationEmailDTO } from '../../dtos/ValidationEmailDTO'
import authenticationService from '../../services/authentication'
import LayoutEditing from '../Layouts/LayoutEditing.vue'
import ModalEmailConfirmation from '../LoginForms/ModalEmailConfirmation.vue'
import ModalChangePassword from './ModalChangePassword.vue'

export default defineComponent({
  name: 'MyCredentials',
  components: { PazzeiInput, PazzeiBtn, LayoutEditing, ModalEmailConfirmation, ModalChangePassword },
  setup() {
    const isLoading = ref(false)
    const loading = ref(true)
    const userInfos = ref(new UserInfosDTO('', 0, '', '', '', '', '', ''))
    const $q = useQuasar()
    const emailTemp = ref('')
    const readonly = ref(true)
    const formPassword = ref({
      password: '',
    })
    const flags = ref({
      credentials: false,
      prompt: false,
      password: false,
      modalVisibility: false,
      disableSave: true,
      savePassword: true,
    })
    const formData = reactive({
      email: '',
      name: '',
    })

    provide('formData', formData)

    const rules = computed(() => {
      return {
        email,
      }
    })

    const passwordConfirmation = computed(() => {
      return {
        password,
      }
    })

    const v$ = useVuelidate(rules, formData)
    const passwordConfirmation$ = useVuelidate(passwordConfirmation, formPassword.value)

    onMounted(async () => {
      const { data: infos } = await usersService.get('/me')
      userInfos.value = infos.data
      emailTemp.value = userInfos.value.email

      try {
        formData.email = userInfos.value.email
        formData.name = userInfos.value.name
      } finally {
        loading.value = false
      }
    })

    onUnmounted(() => document.removeEventListener('keydown', close))

    const close = (e: Event) => {
      if (e instanceof KeyboardEvent) if (e.key === 'Escape') closeModal()
    }

    document.addEventListener('keydown', close)

    const cancelChanges = () => {
      formData.email = emailTemp.value
      readonly.value = true
    }

    const closeModal = () => {
      flags.value.modalVisibility = false
    }

    const closeModalPassword = () => {
      flags.value.password = false
    }

    const notification = (success: boolean, message: string) => {
      if (success) {
        $q.notify({
          textColor: 'grey-1',
          message: message,
          color: 'green',
          position: 'top',
          classes: 'notify',
        })
      } else {
        $q.notify({
          textColor: 'grey-1',
          message: message,
          color: 'yellow-8',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    const validateEmail = async () => {
      const result = await v$.value.email.$validate()
      if (result) {
        if (formData.email === emailTemp.value) notification(false, 'Informe um e-mail diferente')
        else {
          const data = new ValidationEmailDTO(formData.email)
          usersService.post('/email', data).then((res) => {
            const userExists = res.data.data.exists
            if (!userExists) flags.value.prompt = !flags.value.prompt
            else notification(false, 'E-mail já utilizado por outro usuário')
          })
        }
      }
    }

    const submitEmail = async () => {
      const result = await passwordConfirmation$.value.password.$validate()
      if (result) {
        isLoading.value = true
        const loginDTO = new AuthenticationDTO(emailTemp.value, formPassword.value.password, true, false)

        authenticationService
          .post('/', loginDTO)
          .then(() => {
            const confirmation = new ConfirmationEmailDTO(formData.email, userInfos.value.name)
            authenticationService.post('/request-email-verification', confirmation).then((res) => {
              sessionStorage.setItem('otpToken', res.data.data.otpToken)
              flags.value.prompt = !flags.value.prompt
              flags.value.modalVisibility = true
              isLoading.value = false
            })
          })
          .catch((err) => console.error(err))
          .finally(() => {
            isLoading.value = false
            closeModal()
          })
      }
    }

    return {
      v$,
      formData,
      loading,
      submitEmail,
      userInfos,
      isLoading,
      flags,
      validateEmail,
      passwordConfirmation,
      passwordConfirmation$,
      formPassword,
      closeModal,
      isPwd: ref(true),
      isPwdOk: ref(true),
      isPwdCurrent: ref(true),
      readonly,
      cancelChanges,
      closeModalPassword,
    }
  },
})
