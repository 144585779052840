
import TermsChangeModal from '@/components/Application/exerciseListPage/TermsChangeModal/index.vue'
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiGoal from '@/components/Generics/PazzeiGoal.vue'
import PazzeiModal from '@/components/Generics/PazzeiModal.vue'
import SubjectList from '@/components/Generics/PazzeiSubjectList.vue'
import MountListsIcon from '@/components/Icons/default/MountListsIcon.vue'
import SearchIcon from '@/components/Icons/default/SearchIcon.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutSubWrapper from '@/components/Layouts/MainLayoutSubWrapper.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import TrialCard from '@/components/Trial/TrialCard.vue'
import router from '@/router'
import authService from '@/services/authService'
import listService from '@/services/list'
import DefaultTheme from '@/theme/defaultTheme'
import { defineComponent, inject, onMounted, onUnmounted, provide, Ref, ref, watch } from 'vue'
import GoalsChart from '../components/Application/goalsChart/GoalsChart.vue'
import PazzeiInput from '../components/Generics/PazzeiInput.vue'
import ModalImage from '../components/Library/ModalImage/index.vue'
import ModalUserInfos from '../components/UserInfosForms/ModalUserInfos.vue'
import { TermsURLData, TermsURLDTO } from '@/dtos/registerPage/termsURLDTO'
import { PagedDTO } from '@/dtos/ResponseDTOs/PagedDTO'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import { UserDTO } from '@/dtos/ResponseDTOs/UserDTO'
import { SubjectInfoDTO } from '@/dtos/SubjectInfoDTO'
import usersService from '@/services/users'
import { checkImageAvailability } from '@/utils/common/checkImageAvailability'
import { InstitutionConfigType } from '@/types/InstitutionConfigType'

export default defineComponent({
  name: 'ExerciseListPage',
  components: {
    GenericLayout,
    TermsChangeModal,
    PazzeiModal,
    PazzeiInput,
    ModalUserInfos,
    MainLayoutSubWrapper,
    GoalsChart,
    MainLayoutWrapper,
    PazzeiBtn,
    SubjectList,
    PazzeiGoal,
    ModalImage,
    SearchIcon,
    TrialCard
  },
  setup() {
    const controller = new AbortController()
    const isExerciseList = ref(true)
    const termsNeedAccept = ref<TermsURLData[]>([])
    const exerciseList = ref<SubjectInfoDTO[]>([])
    const searchList = ref('')
    const foundList = ref<SubjectInfoDTO[]>([])
    const foundFlag = ref(true)
    const show = ref(false)
    const isModalVisible = ref(false)
    const isImageVisible = ref(false)
    const notFound = ref(1)
    const exerciseListStarted = ref<SubjectInfoDTO[]>([])
    const isSearchInputExpanded = ref(true)
    const exerciseListNotStarted = ref<SubjectInfoDTO[]>([])
    const getUser = inject<Ref<UserDTO>>('userDTO')
    const institutionConfig = inject<InstitutionConfigType>('institutionConfig');
    const userCanInteract = inject<Ref<string>>('userCanInteract')

    const totalLists = ref({
      listsStarted: 0,
      listsNotStarted: 0,
      listsTotal: 0,
    })
    const innerWidth = ref(window.innerWidth)
    const setNewTab = () => {
      innerWidth.value = window.innerWidth
    }
    const isImageAvailable = ref(false)
    const imgBucketBanner = ref(`${process.env.VUE_APP_AWS_STATIC_FILES_BASE_URL}advertising/main-advertising-portal.jpg`)
    const userScoped = ref(authService.getHigherScoped())
    window.addEventListener('resize', setNewTab)
    onUnmounted(() => window.removeEventListener('resize', setNewTab))
    provide('userDTO', getUser)
    provide('isExerciseList', isExerciseList)
    provide('exerciseListStarted', exerciseListStarted)
    provide('exerciseListNotStarted', exerciseListNotStarted)
    provide('exerciseList', exerciseList)

    let key = ref(0)
    const isLoading = ref(true)
    const infinityScrollPage = ref(0)
    const infinityScrollPageSize = ref(20)
    let debounce: number
    const currentDate = new Date()
    const oneDayTime = 24 * 60 * 60 * 1000

    const initExerciseListPage = async () => {
      show.value = true

      const { data } = await usersService.get<TermsURLDTO>('/terms/needs-to-accept')
      termsNeedAccept.value = data.data

      isLoading.value = true
      Promise.all([
        listService.post<ResponseDTO<PagedDTO<SubjectInfoDTO>>>('/lists', { pageSize: 100, filter: { isTest: false } }, { signal: controller.signal }).then((res) => {
          exerciseList.value = res.data.data.data
          infinityScrollPage.value = Math.ceil(res.data.data.pageSize / infinityScrollPageSize.value)
          totalLists.value.listsTotal = res.data.data.totalElements
          key.value++
        }),
        listService.post<ResponseDTO<PagedDTO<SubjectInfoDTO>>>('/lists', { pageSize: 4, filter: { started: true, isTest: false } }, { signal: controller.signal }).then((res) => {
          exerciseListStarted.value = res.data.data.data
          totalLists.value.listsStarted = res.data.data.totalElements
          key.value++
        }),
        listService.post<ResponseDTO<PagedDTO<SubjectInfoDTO>>>('/lists', { pageSize: 4, filter: { started: false, isTest: false } }, { signal: controller.signal }).then((res) => {
          exerciseListNotStarted.value = res.data.data.data
          totalLists.value.listsNotStarted = res.data.data.totalElements
          key.value++
        }),
      ]).finally(() => {
        isLoading.value = false

        sessionStorage.setItem('listsStarted', totalLists.value.listsStarted.toString())
        sessionStorage.setItem('listsNotStarted', totalLists.value.listsNotStarted.toString())
      })
    }

    provide('initExerciseListPage', initExerciseListPage)

    onMounted(async () => {
      initExerciseListPage()
      isImageAvailable.value = await checkImageAvailability(imgBucketBanner.value)

      if (userScoped.value !== 'self') return

      const lastImageClosed = localStorage.getItem('lastImageClosed')
      if (!lastImageClosed) {
        openImage()
      } else {
        const lastClosedDate = new Date(lastImageClosed)
        const timeDifference = currentDate.getTime() - lastClosedDate.getTime()

        if (timeDifference >= oneDayTime) {
          openImage()
        }
      }
    })

    onUnmounted(() => {
      document.removeEventListener('keydown', close)
      controller.abort()
    })

    const close = (e: Event) => {
      if (e instanceof KeyboardEvent) if (isModalVisible.value && e.key === 'Escape') closeModal()
    }

    const closeImage = () => {
      isImageVisible.value = false
      localStorage.setItem('lastImageClosed', new Date().toISOString())
    }

    const openImage = () => {
      if (innerWidth.value <= 768 && isImageAvailable.value) isImageVisible.value = true
    }

    const closeModal = () => (isModalVisible.value = !isModalVisible.value)

    document.addEventListener('keydown', close)

    const submitSearch = () => {
      if (!userCanInteract?.value) return;
      const data = searchList.value
      isLoading.value = true

      listService
        .post('/lists', { pageSize: 100, search: { name: data }, filter: { isTest: false } })
        .then((res) => {
          foundList.value = res.data.data.data
          foundFlag.value = false
          notFound.value = res.data.data.data.length
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    const handleCreateLists = () => {
      if (!userCanInteract) return;
      router.push('/criar-lista')
    }

    const fetchLists = async (index: number, done: (stop: boolean) => void) => {
      let stop = false
      try {
        const response = await listService.post<ResponseDTO<PagedDTO<SubjectInfoDTO>>>('/lists', {
          page: ++infinityScrollPage.value,
          pageSize: infinityScrollPageSize.value,
          filter: { isTest: false },
        })
        exerciseList.value.push(...response.data.data.data)
        if (response.data.data.currentPage + 1 > response.data.data.totalPages) stop = true
      } catch (error) {
        stop = true
      } finally {
        done(stop)
      }
    }

    watch(
      getUser!,
      () => {
        if (!getUser?.value?.registrationCompleted && getUser?.value.roleCode !== 'institution:student') {
          isModalVisible.value = true
        } else show.value = true
      },
      { deep: true }
    )

    watch(searchList, () => {
      clearTimeout(debounce)
      debounce = setTimeout(() => {
        if (searchList.value == '') foundFlag.value = true
        else {
          isLoading.value = true
          submitSearch()
        }
      }, 1000)
    })

    watch(innerWidth, () => {
      if (innerWidth.value > 768) closeImage()
    })

    return {
      isModalVisible,
      key,
      closeModal,
      show,
      exerciseList,
      isSearchInputExpanded,
      termsNeedAccept,
      handleCreateLists,
      searchList,
      submitSearch,
      foundList,
      foundFlag,
      notFound,
      exerciseListStarted,
      exerciseListNotStarted,
      isLoading,
      fetchLists,
      totalLists,
      isImageVisible,
      closeImage,
      userScoped,
      imgBucketBanner,
      isImageAvailable,
      MountListsIcon,
      DefaultTheme,
      isWhiteLabel: institutionConfig!.isWhiteLabel,
      userCanInteract,
      institutionConfig,
    }
  },
})
