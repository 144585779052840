import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26412047"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "div-forms-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabProfile = _resolveComponent("TabProfile")!
  const _component_MainLayoutWrapper = _resolveComponent("MainLayoutWrapper")!
  const _component_GenericLayout = _resolveComponent("GenericLayout")!

  return (_openBlock(), _createBlock(_component_GenericLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_MainLayoutWrapper, null, {
        main: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_TabProfile, { tab: _ctx.tab }, null, 8, ["tab"]),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.plans ? 'plans' : 'div-forms')
            }, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ], 2)
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}