
import TabProfile from '@/components/EditingProfile/TabProfile.vue';
import GenericLayout from '@/components/Layouts/GenericLayout.vue';
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'LayoutEditing',
  props: {
    tab: String,
    plans: Boolean,
  },
  components: { MainLayoutWrapper, GenericLayout, TabProfile },
  setup(props) {
    const tabRef = ref(props.tab)
    const handleClick = (item: string) => (tabRef.value = item)

    return {
      tabRef,
      handleClick,
    }
  },
})
