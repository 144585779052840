
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'TableComponent',
  props: {
    columns: { type: Array as PropType<Array<string>>, required: true, default: () => [] },
  },
  setup() {
    return {}
  },
})
