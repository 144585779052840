import { QuestionTagsDTO } from '../../../dtos/CreateListPage/QuestionTagsDTO'
import { ResponseDTO } from '../../../dtos/ResponseDTOs/ResponseDTO'
import questionsService from '../../../services/question'
import { largeAreas, subjectsOrder } from '../../../utils/hardCoded/enemModule/enemModule'
import { AuxTree, SearchQuestions, Tree } from '../../../utils/hardCoded/enemModule/weightedQuestionTree/types'
import { amountOfQuestionsOnNoSeuRitmoCategory, amoutOfQuestionsOnSimuladoEnemCategory } from '../../../utils/hardCoded/enemModule/weightedQuestionTree/weightedQuestionTree'
import { AreasOnKnowledgeTree, FilterEnemKnowledgeTree } from './WeightingFunctionsTypes'
import { SelectedAreasToWeighting } from './enemModule/types'

export const balanceQuestionsAcrossSubjects = (totalQuestionsToDistribute: number, subjectsToDistribute: Tree[], totalQuestionsOnArea: number, options: { isCreatingList: boolean } | null = null) => {
  const minQuestionsPerSubject = options?.isCreatingList ? 0 : 1
  let totalOfSubjectsQuestions = 0

  const totalQuestionsOnSubject = subjectsToDistribute.reduce((acc: { [x: string]: number }, cur) => {
    if (!acc[cur.name]) {
      acc[cur.name] = Math.floor(minQuestionsPerSubject + totalQuestionsToDistribute * cur.percentage)
      totalOfSubjectsQuestions += Math.floor(minQuestionsPerSubject + totalQuestionsToDistribute * cur.percentage)
    }
    return acc
  }, {})

  if (totalOfSubjectsQuestions < totalQuestionsOnArea) {
    const difOnArea = totalQuestionsOnArea - totalOfSubjectsQuestions
    for (let i = 0; i < difOnArea; i++) {
      const subjectToIncrement = subjectsToDistribute.reduce((acc, cur) => {
        if (!acc) acc = cur
        const currentMissingQuestions = minQuestionsPerSubject + totalQuestionsToDistribute * cur.percentage - totalQuestionsOnSubject[cur.name]
        const accMissingQuestions = minQuestionsPerSubject + totalQuestionsToDistribute * acc.percentage - totalQuestionsOnSubject[acc.name]
        if (currentMissingQuestions > accMissingQuestions) return cur
        return acc
      })
      totalQuestionsOnSubject[subjectToIncrement.name]++
    }
  }

  return totalQuestionsOnSubject
}

export const distributeQuestionsToChildren = (subjectsToDistribute: Tree[], totalQuestionsOnSubject: { [x: string]: number }, filters: QuestionTagsDTO) => {
  const subjects: Array<AuxTree> = []

  subjectsToDistribute.forEach((subj) => {
    const currentQuantityPerDivision: { [x: string]: number } = {}

    const expectedDivisionsQuantities = subj.children.reduce((acc: { [x: string]: number }, cur) => {
      if (!acc[cur.name]) {
        acc[cur.name] = (cur.totalQuestions / subj.totalQuestions) * totalQuestionsOnSubject[subj.name]
        currentQuantityPerDivision[cur.name] = 0
      }
      return acc
    }, {})

    for (let a = 0; a < totalQuestionsOnSubject[subj.name]; a++) {
      if (subj.children.length === 0) return subjects.push(new AuxTree(subj.name, [], filters))

      const divisionToSave = subj.children.reduce((acc, cur) => {
        if (!acc) acc = cur
        const currentMissingQuestions = expectedDivisionsQuantities[cur.name] - currentQuantityPerDivision[cur.name]
        const accMissingQuestions = expectedDivisionsQuantities[acc.name] - currentQuantityPerDivision[acc.name]
        if (currentMissingQuestions > accMissingQuestions) return cur
        return acc
      })

      currentQuantityPerDivision[divisionToSave.name] += 1
    }

    const divisionToSave = Object.keys(currentQuantityPerDivision).map((it) => {
      return {
        name: it,
        quantity: currentQuantityPerDivision[it],
      }
    })

    subjects.push(new AuxTree(subj.name, divisionToSave, filters))
  })

  return subjects
}

export const weighUp = async (filterTree: FilterEnemKnowledgeTree, filters: QuestionTagsDTO, totalQuestionsOnArea = 0) => {
  const { data: weightedQuestionTreeFiltered } = await questionsService.post<ResponseDTO<Array<Tree>>>('/enem-knowledge-tree', { filter: filterTree })
  let subjects: Array<AuxTree> = []

  const minQuestionsPerSubject = 1
  weightedQuestionTreeFiltered.data.forEach((area) => {
    const subjectsToDistribute = area.children
    const totalQuestionsToDistribute = totalQuestionsOnArea - minQuestionsPerSubject * subjectsToDistribute.length

    const totalQuestionsOnSubject = balanceQuestionsAcrossSubjects(totalQuestionsToDistribute, subjectsToDistribute, totalQuestionsOnArea)

    subjects = distributeQuestionsToChildren(subjectsToDistribute, totalQuestionsOnSubject, filters)
  })

  return subjects
}

export const weighting = async (knowledgeAreas: SelectedAreasToWeighting, isSpanishForeignLanguageSelected: boolean): Promise<AuxTree[]> => {
  let totalQuestionsOnArea = 0
  const areasTitles = knowledgeAreas.areas.map((it) => it.title)
  let largeAreasToSum = []

  if (knowledgeAreas.type === 'Simulado Enem') {
    largeAreasToSum = largeAreas.filter((it) => areasTitles.includes(it.title))
    totalQuestionsOnArea = amoutOfQuestionsOnSimuladoEnemCategory
  } else {
    largeAreasToSum = largeAreas.filter((it) => it.title === areasTitles[0])
    totalQuestionsOnArea = amountOfQuestionsOnNoSeuRitmoCategory
  }

  const areasOnKnowledgeTree = largeAreasToSum.map((it) => {
    if (it.title === 'Linguagens, Códigos e suas Tecnologias') {
      const subjects = isSpanishForeignLanguageSelected ? it.subjectsWithSpanish : it.subjectsWithEnglish
      if (subjects) return new AreasOnKnowledgeTree(it.title, subjects)
    }
    return new AreasOnKnowledgeTree(it.title, it.subjectsOnArea)
  })

  const filterTree = new FilterEnemKnowledgeTree(areasOnKnowledgeTree)
  const filters = new QuestionTagsDTO({ types: ['Múltipla escolha'], subjects: [], levels: [], sources: [], years: [] })

  return weighUp(filterTree, filters, totalQuestionsOnArea)
}

export const formatDataToSend = async (shuffle: boolean, showAlreadyAnsweredQuestion: boolean, subjects: AuxTree[], listId?: number): Promise<number[]> => {
  const questionsToAddOnList: Array<number> = []

  if (shuffle) {
    const orderedSubjectsByArea = largeAreas.map((it) => {
      return subjects.filter((sub) => it.subjectsOnArea.includes(sub.name!))
    })

    for (const area of orderedSubjectsByArea) {
      if (area.length) {
        const formattedSummary = new SearchQuestions(showAlreadyAnsweredQuestion, area, listId)
        const { data } = await questionsService.post<ResponseDTO<Array<number>>>('/', formattedSummary)
        questionsToAddOnList.push(...data.data)
      }
    }
  } else {
    const orderedSubjects = subjectsOrder
      .map((subjectName) => {
        const foundSubject = subjects.find((subject) => subject.name === subjectName)
        return foundSubject ? { ...foundSubject } : null
      })
      .filter((subject) => subject !== null)

    for (const sub of orderedSubjects) {
      const formattedSummary = new SearchQuestions(showAlreadyAnsweredQuestion, [sub], listId)
      const { data } = await questionsService.post<ResponseDTO<Array<number>>>('/', formattedSummary)
      questionsToAddOnList.push(...data.data)
    }
  }
  return questionsToAddOnList
}
