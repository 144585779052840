
import CaptionView from '@/components/Application/captionView/CaptionView.vue'
import QuestionsControl from '@/components/Application/questionsControl/QuestionsControl.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutSubWrapper from '@/components/Layouts/MainLayoutSubWrapper.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import Button from '@/components/Library/Button/index.vue'
import Modal from '@/components/Library/Modal/index.vue'
import ModalViewAnsweredQuestion from '@/components/ListsFinishedModal/ModalViewAnsweredQuestion.vue'
import { QuestionCacheType } from '@/types/QuestionCacheType'
import { defineComponent, provide, ref, toRaw } from 'vue'
import { useRoute } from 'vue-router'
import router from '../router'
import listsService from '../services/list'

export default defineComponent({
  name: 'ViewEnemModule',
  components: {
    Button,
    CaptionView,
    GenericLayout,
    MainLayoutSubWrapper,
    MainLayoutWrapper,
    Modal,
    ModalViewAnsweredQuestion,
    QuestionsControl,
  },
  setup() {
    const { id: listId, tryId } = useRoute().params

    const auxQuestionsNotAnswered = ref<Array<{ name: string; questions: Array<{ key: string; value: number; isDoubt: boolean; isAnswered: boolean; label: number }> }>>([])
    const auxQuestionsSeriesNotAnswered = ref<
      Array<{ name: string; series: Array<{ number: number; questions: Array<{ key: string; value: number; isDoubt: boolean; isAnswered: boolean; label: number }> }> }>
    >([])

    const responsesCache = ref<Array<QuestionCacheType>>([])
    const listName = ref('')
    const isListEnemModuleAndAtYourPace = ref(false)
    const isListEnemModule = ref(false)

    const modalViewAnsweredQuestion = ref(false)
    const idQuestion = ref<number>()
    const indexQuestion = ref<number>()

    const handleStateQuestion = (isDoubt: boolean, isAnswered: boolean) => {
      if (isDoubt) return 'doubt'
      else return isAnswered ? 'answered' : 'unanswered'
    }

    const getQuestionsAndAlternativesResponses = ({
      userResponses,
      listNam,
      listEnemModuleAndAtYourPace,
      listEnemModule,
    }: {
      userResponses: Array<QuestionCacheType>
      listNam: string
      listEnemModuleAndAtYourPace: boolean
      listEnemModule: boolean
    }) => {
      responsesCache.value = userResponses
      listName.value = listNam
      isListEnemModuleAndAtYourPace.value = listEnemModuleAndAtYourPace
      isListEnemModule.value = listEnemModule
    }

    const onQuestionClick = (questionLabel: number, questionId: number, questionOrder: number) => {
      const question = toRaw(responsesCache.value).find((response) => response.questionId === questionId)

      idQuestion.value = question?.questionId
      indexQuestion.value = question?.order

      modalViewAnsweredQuestion.value = true
    }

    const handleFinalizeTry = async () => {
      await listsService.patch('/tries/finish-try', undefined, { params: { tryId: Number(tryId) } })

      router.push(`/gabarito/${listId}/${tryId}`)
    }

    provide('onQuestionClick', onQuestionClick)

    return {
      listName,
      modalViewAnsweredQuestion,
      isListEnemModuleAndAtYourPace,
      auxQuestionsNotAnswered,
      handleStateQuestion,
      auxQuestionsSeriesNotAnswered,
      listId,
      handleFinalizeTry,
      tryId,
      isListEnemModule,
      idQuestion,
      indexQuestion,
      getQuestionsAndAlternativesResponses,
      onQuestionClick,
      responsesCache,
    }
  },
})
