
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiGoal from '@/components/Generics/PazzeiGoal.vue'
import SubjectList from '@/components/Generics/PazzeiSubjectList.vue'
import MountListsIcon from '@/components/Icons/default/MountListsIcon.vue'
import SearchIcon from '@/components/Icons/default/SearchIcon.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutSubWrapper from '@/components/Layouts/MainLayoutSubWrapper.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import TrialCard from '@/components/Trial/TrialCard.vue'
import authService from '@/services/authService'
import listService from '@/services/list'
import DefaultTheme from '@/theme/defaultTheme'
import { checkImageAvailability } from '@/utils/common/checkImageAvailability'
import { defineComponent, inject, onMounted, onUnmounted, provide, Ref, ref, watch } from 'vue'
import GoalsChart from '../components/Application/goalsChart/GoalsChart.vue'
import TestModal from '../components/CreateTest/TestModal.vue'
import PazzeiInput from '../components/Generics/PazzeiInput.vue'
import ModalImage from '../components/Library/ModalImage/index.vue'
import { PagedDTO } from '@/dtos/ResponseDTOs/PagedDTO'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import { UserDTO } from '@/dtos/ResponseDTOs/UserDTO'
import { SubjectInfoDTO } from '@/dtos/SubjectInfoDTO'
import { InstitutionConfigType } from '@/types/InstitutionConfigType'

export default defineComponent({
  name: 'TestsPage',
  components: {
    GenericLayout,
    PazzeiInput,
    MainLayoutSubWrapper,
    GoalsChart,
    MainLayoutWrapper,
    PazzeiBtn,
    SubjectList,
    PazzeiGoal,
    TestModal,
    ModalImage,
    SearchIcon,
    TrialCard
  },
  setup() {
    const controller = new AbortController()
    const modalVisibility = ref(false)
    const isExerciseList = ref(true)
    const exerciseList = ref<SubjectInfoDTO[]>([])
    const searchList = ref('')
    const foundList = ref<SubjectInfoDTO[]>([])
    const foundFlag = ref(true)
    const show = ref(false)
    const isModalVisible = ref(false)
    const isImageVisible = ref(false)
    const notFound = ref(1)
    const exerciseListStarted = ref<SubjectInfoDTO[]>([])
    const exerciseListNotStarted = ref<SubjectInfoDTO[]>([])
    const isSearchInputExpanded = ref(true)
    const totalLists = ref({
      listsStarted: 0,
      listsNotStarted: 0,
      listsTotal: 0,
    })
    const innerWidth = ref(window.innerWidth)
    const isImageAvailable = ref(false)
    const imgBucketBanner = ref(`${process.env.VUE_APP_AWS_STATIC_FILES_BASE_URL}advertising/main-advertising-portal.jpg`)
    let key = ref(0)
    const isLoading = ref(true)
    const infinityScrollPage = ref(0)
    const infinityScrollPageSize = ref(20)
    const userScoped = ref(authService.getHigherScoped())

    const getUser = inject<Ref<UserDTO>>('userDTO')
    const institutionConfig = inject<InstitutionConfigType>('institutionConfig');
    const userCanInteract = inject<Ref<string>>('userCanInteract')

    provide('userDTO', getUser)
    provide('isExerciseList', isExerciseList)
    provide('exerciseListStarted', exerciseListStarted)
    provide('exerciseListNotStarted', exerciseListNotStarted)
    provide('exerciseList', exerciseList)

    let debounce: number
    const currentDate = new Date()
    const oneDayTime = 24 * 60 * 60 * 1000

    const setNewTab = () => {
      innerWidth.value = window.innerWidth
    }

    const loadLists = () => {
      show.value = true

      isLoading.value = true
      Promise.all([
        listService.post<ResponseDTO<PagedDTO<SubjectInfoDTO>>>('/lists', { pageSize: 100, filter: { isTest: true } }, { signal: controller.signal }).then((res) => {
          exerciseList.value = res.data.data.data
          totalLists.value.listsTotal = res.data.data.totalElements
          key.value++
          infinityScrollPage.value = Math.ceil(res.data.data.pageSize / infinityScrollPageSize.value)
        }),
        listService.post<ResponseDTO<PagedDTO<SubjectInfoDTO>>>('/lists', { pageSize: 4, filter: { started: true, isTest: true } }, { signal: controller.signal }).then((res) => {
          exerciseListStarted.value = res.data.data.data
          totalLists.value.listsStarted = res.data.data.totalElements
          key.value++
        }),
        listService.post<ResponseDTO<PagedDTO<SubjectInfoDTO>>>('/lists', { pageSize: 4, filter: { started: false, isTest: true } }, { signal: controller.signal }).then((res) => {
          exerciseListNotStarted.value = res.data.data.data
          totalLists.value.listsNotStarted = res.data.data.totalElements
          key.value++
        }),
      ]).finally(() => {
        isLoading.value = false

        sessionStorage.setItem('listsStarted', totalLists.value.listsStarted.toString())
        sessionStorage.setItem('listsNotStarted', totalLists.value.listsNotStarted.toString())
      })
    }

    window.addEventListener('resize', setNewTab)

    provide('loadLists', loadLists)

    onMounted(async () => {
      loadLists()
      isImageAvailable.value = await checkImageAvailability(imgBucketBanner.value)

      if (userScoped.value !== 'self') return

      const lastImageClosed = localStorage.getItem('lastImageClosed')
      if (!lastImageClosed) {
        openImage()
      } else {
        const lastClosedDate = new Date(lastImageClosed)
        const timeDifference = currentDate.getTime() - lastClosedDate.getTime()

        if (timeDifference >= oneDayTime) {
          openImage()
        }
      }
    })

    const closeImage = () => {
      isImageVisible.value = false
      localStorage.setItem('lastImageClosed', new Date().toISOString())
    }

    const openImage = () => {
      if (innerWidth.value <= 768 && isImageAvailable.value) isImageVisible.value = true
    }

    onUnmounted(() => {
      controller.abort()
      window.removeEventListener('resize', setNewTab)
    })

    const submitSearch = () => {
      if (!userCanInteract) return
      const data = searchList.value
      isLoading.value = true

      listService
        .post('/lists', { pageSize: 100, search: { name: data }, filter: { isTest: true } })
        .then((res) => {
          foundList.value = res.data.data.data
          foundFlag.value = false
          notFound.value = res.data.data.data.length
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    const fetchLists = async (index: number, done: (stop: boolean) => void) => {
      let stop = false
      try {
        const response = await listService.post<ResponseDTO<PagedDTO<SubjectInfoDTO>>>('/lists', { page: ++infinityScrollPage.value, pageSize: infinityScrollPageSize.value, filter: { isTest: true } })
        exerciseList.value.push(...response.data.data.data)
        if (response.data.data.currentPage + 1 > response.data.data.totalPages) stop = true
      } catch (error) {
        stop = true
      } finally {
        done(stop)
      }
    }

    watch(
      getUser!,
      () => {
        if (!getUser?.value?.registrationCompleted) {
          isModalVisible.value = true
        } else show.value = true
      },
      { deep: true }
    )

    watch(searchList, () => {
      clearTimeout(debounce)
      debounce = setTimeout(() => {
        if (searchList.value == '') foundFlag.value = true
        else {
          isLoading.value = true
          submitSearch()
        }
      }, 1000)
    })

    watch(innerWidth, () => {
      if (innerWidth.value > 768) closeImage()
    })

    return {
      isModalVisible,
      key,
      show,
      exerciseList,
      searchList,
      submitSearch,
      foundList,
      foundFlag,
      isSearchInputExpanded,
      modalVisibility,
      notFound,
      exerciseListStarted,
      exerciseListNotStarted,
      isLoading,
      fetchLists,
      totalLists,
      isImageVisible,
      closeImage,
      userScoped,
      imgBucketBanner,
      isImageAvailable,
      MountListsIcon,
      DefaultTheme,
      isWhiteLabel: institutionConfig!.isWhiteLabel,
      userCanInteract,
    }
  },
})
