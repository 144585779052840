import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 16 18",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M13.4444 11.6667V17M13.4444 17L11.6667 15.2222M13.4444 17L15.2222 15.2222M8.11111 1H5.26667C3.77319 1 3.02646 1 2.45603 1.29065C1.95426 1.54631 1.54631 1.95426 1.29065 2.45603C1 3.02646 1 3.77319 1 5.26667V12.7333C1 14.2268 1 14.9735 1.29065 15.544C1.54631 16.0457 1.95426 16.4537 2.45603 16.7094C3.02646 17 3.77319 17 5.26667 17H8.11111M8.11111 1L13.4444 6.33333M8.11111 1V3.48889C8.11111 4.48454 8.11111 4.98236 8.30488 5.36265C8.47532 5.69716 8.74728 5.96913 9.08179 6.13957C9.46208 6.33333 9.95991 6.33333 10.9556 6.33333H13.4444M13.4444 6.33333V8.11111M4.55556 13.4444H8.11111M4.55556 9.88889H9.88889M4.55556 6.33333H5.44444",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: _ctx.stroke
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}