
import { computed, defineComponent, PropType } from 'vue';
import { ListSummaryOptions } from './helpers/listSummaryOptions';

export default defineComponent({
  name: 'ListSummaryCard',
  props: {
    filterOptions: { type: Object as PropType<ListSummaryOptions>, default: new ListSummaryOptions(), required: true },
  },
  setup(props) {
    const filters = computed(() => {
      const { years, levels, types, source } = props.filterOptions.filter

      return {
        years: years && years.length ? years.join(', ') : 'Filtro não aplicado',
        levels: levels && levels.length ? levels.join(', ') : 'Filtro não aplicado',
        types: types && types.length ? types.join(', ') : 'Filtro não aplicado',
        source: source && source.length ? source.join(', ').replaceAll('G1 - ', '') : 'Filtro não aplicado',
      }
    })

    const hasAnyFilters = computed(() => {
      const { years, levels, types, source } = props.filterOptions.filter

      return years?.length || levels?.length || types?.length || source?.length
    })

    return { filters, hasAnyFilters }
  },
})
