import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9a20108c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "button_content flex justify-center w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["button_wrapper flex default-text disable-selection pointer", `variant-${_ctx.variant} ${_ctx.fullWidth ? 'full-width' : ''} ${_ctx.classes ? _ctx.classes : ''} items-${_ctx.justifyContent}`]),
    disabled: _ctx.disabled || _ctx.isLoading
  }, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          (_ctx.iconLeft)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.iconLeft), _mergeProps({
                key: 0,
                class: "button_icon button_icon__left"
              }, _ctx.iconProps), null, 16))
            : _createCommentVNode("", true),
          _createElementVNode("span", {
            class: _normalizeClass(["button_label", `text-${_ctx.fontSize}px font-weight-${_ctx.fontWeight}`])
          }, _toDisplayString(_ctx.label), 3),
          (_ctx.iconRight)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.iconRight), _mergeProps({
                key: 1,
                class: "button_icon button_icon__right"
              }, _ctx.iconProps), null, 16))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 1 }))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}