
import EliminateOptionIcon from '@/components/Icons/default/redesign/sharedList/EliminateOptionIcon.vue';
import DefaultTheme from '@/theme/defaultTheme';
import { defineComponent, inject, onMounted, PropType, ref, watch } from 'vue';
import { AlternativesSetType } from './../types/AlternativesSet';
import { ParentPropsType, ParentType } from './../types/ParentType';

export default defineComponent({
  name: 'AlternativeOption',
  props: {
    correct: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
    discarded: { type: Boolean, default: false },
    wrong: { type: Boolean, default: false },
    notSelectable: { type: Boolean, default: false },
    id: { type: Number, required: true, default: -1 },
    index: { type: Number, required: true, default: -1 },
    content: { type: String, default: '' },
    label: { type: String, default: '' },
    alternativeObject: { type: Object as PropType<{selected: boolean}> },
    displaySelectedOnly: { type: Boolean, default: false },
    isCheckingQuestions: { type: Boolean, default: false },
    showAnswer: { type: Boolean, default: false },
  },
  components: {
    EliminateOptionIcon
  },
  setup(props, { emit }) {
    const isSelected = ref<boolean | null>(false)
    const horizontalMode = ref(false)
    const questionType = ref('summation')
    const alternativeOptionLabelsSettedUp = ref(false);

    const defineSetSelectedAlternatives = inject<(alternativesSetType: AlternativesSetType) => void>('defineSetSelectedAlternatives')
    const setMcq = inject<(index: number) => void>('setMcq')
    const setSummation = inject<(index: number) => void>('setSummation')
    const defineSetDiscardedAlternatives = inject<(alternativesSetType: AlternativesSetType) => void>('defineSetDiscardedAlternatives')

    const showRemoveAlternativeIcon = ref(false);

    const initSettings = (alternativeId: number) => {
      if (questionType.value === 'true/false') {
        const state = props.label ? (props.label === 'V' ? true : false) : null
        isSelected.value = state

        defineSetSelectedAlternatives?.({ alternativeId, state })
      }
    }

    const setAlternativeOptionLabels = (parent: ParentType, index: number) => {
      const p = parent as ParentPropsType

      questionType.value = p.type
      horizontalMode.value = p.horizontal

      if (p.modelValue.discardedAlternatives?.length && !alternativeOptionLabelsSettedUp.value) {
        p.modelValue.discardedAlternatives.forEach((i) => {
          if (i === props.id) {
            emit('update:discarded', props.id, props.discarded)
          }
        })
      }

      if (p.modelValue.alternatives?.length) {
        if(!alternativeOptionLabelsSettedUp.value) {
          p.modelValue.alternatives.forEach((i) => {
            if (i === props.id) {
              handleAlternativeSelection(props.id)
            }
          })
        }

        if (questionType.value === 'true/false' && isSelected.value !== null) {
          if (!isSelected.value) p.alternativeOptionLabels[index] = 'F'
          else p.alternativeOptionLabels[index] = 'V'
        } else if (!alternativeOptionLabelsSettedUp.value) {
          if (questionType.value === 'mcq') {
            if (p.modelValue.alternatives && p.modelValue.alternatives[0] === props.id) {
              isSelected.value = true
            }
          } else if (questionType.value === 'summation') {
            if (
              p.modelValue.alternatives
              && p.modelValue.alternatives.length
              && p.modelValue.alternatives.find((alt) => alt === props.id)
            ) {
              isSelected.value = true
            }
          }
        } else {
          if (questionType.value === 'mcq') {
            if (!(p.modelValue.alternatives && p.modelValue.alternatives[0] === props.id)) {
              isSelected.value = p.arraySelectedAlternatives[index]
            }
          } else if (questionType.value === 'summation') {
            if (
              p.modelValue.alternatives
              && p.modelValue.alternatives.length
              && p.modelValue.alternatives.find((alt) => alt === props.id)
            ) {
              isSelected.value = true;
            } else {
              isSelected.value = false;
            }
          }
        }
      }

      alternativeOptionLabelsSettedUp.value = true;
      return p.alternativeOptionLabels[index]
    }

    const handleAlternativeSelection = (alternativeId: number) => {
      if ((props.notSelectable || props.correct || props.wrong || props.discarded)) {
        return;
      }

      switch (questionType.value) {
        case 'true/false':
          defineSetSelectedAlternatives?.({ alternativeId, state: isSelected.value })
          break
        default:
          if (questionType.value === 'mcq') {
            setMcq?.(props.index)
          }
          if (questionType.value === 'summation') {
            setSummation?.(props.index)
          }

          defineSetSelectedAlternatives?.(alternativeId)
      }

      isSelected.value = true;
    }

    const handleHover = (isHovering: boolean) => {
      showRemoveAlternativeIcon.value = isHovering;
    }

    const handleAlternativeRemoval = (alternativeId: number, e?: Event) => {
      e?.stopPropagation();
      const newState = !props.discarded;

      emit('update:discarded', props.id, newState)

      defineSetDiscardedAlternatives?.({ alternativeId, state: newState })
    }

    const showAlternativeSpanText = ref(true);
    const getAlternativeSpanText = () => {
      if ((props.selected || isSelected.value) && props.correct) {
        return 'Alternativa marcada - correta'
      } else if ((props.selected || isSelected.value) && props.wrong) {
        return 'Alternativa marcada - incorreta'
      } else if (!(props.selected || isSelected.value) && props.correct) {
        return 'Gabarito - correta'
      }

      showAlternativeSpanText.value = false;
    }

    onMounted(() => {
      initSettings(props.id);
    });

    watch(questionType, () => initSettings(props.id))

    return { horizontalMode, isSelected, setAlternativeOptionLabels, handleAlternativeSelection, questionType, showRemoveAlternativeIcon, handleHover, handleAlternativeRemoval, getAlternativeSpanText, showAlternativeSpanText, DefaultTheme }
  },
})
