import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e7f5b50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-group" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttonList, (button, index) => {
      return (_openBlock(), _createElementBlock("button", {
        key: index,
        class: _normalizeClass(["button-group-button", { 'button-group-button-first': index === 0, 'button-group-button--selected': button === _ctx.modelValue }]),
        onClick: ($event: any) => (_ctx.$emit('update:modelValue', button))
      }, _toDisplayString(button), 11, _hoisted_2))
    }), 128))
  ]))
}