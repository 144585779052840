import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e1c4226c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "year-card-button-wrapper" }
const _hoisted_2 = { class: "year-card-button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardButton = _resolveComponent("CardButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (n) => {
        return (_openBlock(), _createBlock(_component_CardButton, {
          key: n,
          label: String(n),
          selected: _ctx.selectedValue === n,
          onClick: ($event: any) => (_ctx.handleClick(n))
        }, null, 8, ["label", "selected", "onClick"]))
      }), 128))
    ])
  ]))
}