
import PazzeiSimpleSelect from '@/components/Generics/PazzeiSimpleSelect.vue';
import CloseFilterIcon from '@/components/Icons/default/CloseFilterIcon.vue';
import Input from '@/components/Library/Input/index.vue';
import DefaultTheme from '@/theme/defaultTheme';
import { defineComponent, inject, Ref, ref, watch } from 'vue';

export default defineComponent({
  components: { Input, PazzeiSimpleSelect, CloseFilterIcon },

  setup() {
    const openFilterSelected = inject<() => void>('openFilterSelected')
    const filters = inject<Array<object>>('filters')
    const isFilterSelected = inject<Ref<boolean>>('isFilterSelected')
    const optionsOfficeSchool = ['Diretor', 'Coordenador', 'etc']

    const emitValueToParent = inject<(value: object) => void>('emitNewValue')

    const filtersData = ref<Array<any> | undefined>(filters)

    watch(
      filtersData,
      () => {
        const filterValues = filtersData.value?.reduce((values: any, filter: any) => {
          if (Array.isArray(filter.value)) {
            values[filter.key] = filter.value[0]
          } else {
            values[filter.key] = filter.value
          }
          return values
        }, {})

        emitValueToParent?.(filterValues)
      },
      { deep: true }
    )

    watch(isFilterSelected!, () => {
      if (!isFilterSelected?.value) {
        filtersData.value?.forEach((item) => {
          item.value = ''
        })
      }
    })

    return {
      optionsOfficeSchool,
      openFilterSelected,
      filters,
      filtersData,
      DefaultTheme,
    }
  },
})
