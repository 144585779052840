
import { TermsChangeModal } from '@/components/Application/exerciseListPage/TermsChangeModal/helpers/interfaces'
import CooliconIcon from '@/components/Icons/default/CooliconIcon.vue'
import Button from '@/components/Library/Button/index.vue'
import Modal from '@/components/Library/Modal/index.vue'
import { TermsURLData } from '@/dtos/registerPage/termsURLDTO'
import DefaultTheme from '@/theme/defaultTheme'
import axios from 'axios'
import { defineComponent, PropType, ref, watch } from 'vue'
import usersService from '../../../../services/users'

export default defineComponent({
  name: 'TermsChangeModal',
  props: {
    newTerms: { type: Array as PropType<TermsURLData[]>, default: () => [] },
  },
  components: { Button, Modal, CooliconIcon},
  setup(props) {
    const modalVisibility = ref(false)
    const termHTML = ref<TermsChangeModal>({ termsOfUse: {}, privacyPolicy: {} })
    const currentTerm = ref(0)
    const termsPhrase = ref<{ phrase: string; term: string[] }>({ phrase: '', term: [] })

    const renderPhraseTermsUpdated = (terms: { termsOfUse: string; privacyPolicy: string }) => {
      if (terms.termsOfUse && terms.privacyPolicy) return { phrase: 'Os termos de uso e política de privacidade deste produto mudaram', term: ['Termo de uso', 'Política de privacidade'] }

      if (terms.termsOfUse) return { phrase: 'O termo de uso deste produto mudou', term: ['Termo de uso'] }

      if (terms.privacyPolicy) return { phrase: 'O termo de política de privacidade mudou', term: ['Política de privacidade'] }

      return { phrase: '', term: [''] }
    }

    const getTerms = async () => {
      const termsURL = { termsOfUse: '', privacyPolicy: '' }
      props.newTerms.forEach((term) => {
        if (term.termType.type === 'terms-of-use') {
          termsURL.termsOfUse = process.env.VUE_APP_AWS_STATIC_FILES_BASE_URL + 'terms-of-use/' + term.id + '.html'
        } else {
          termsURL.privacyPolicy = process.env.VUE_APP_AWS_STATIC_FILES_BASE_URL + 'privacy-policy/' + term.id + '.html'
        }
      })

      termsURL.termsOfUse = termsURL.termsOfUse ? await axios.get(termsURL.termsOfUse) : ''
      termsURL.privacyPolicy = termsURL.privacyPolicy ? await axios.get(termsURL.privacyPolicy) : ''

      termsPhrase.value = renderPhraseTermsUpdated(termsURL)

      termHTML.value = { ...termsURL } as TermsChangeModal
    }

    const handleTermsChange = () => {
      if (props.newTerms.length !== 0) {
        getTerms()
        modalVisibility.value = true
      }
    }

    const handleAcceptTerms = () => {
      props.newTerms.forEach(async (term) => {
        await usersService.post('/terms', { id: term.id })
      })

      modalVisibility.value = false
    }

    watch(() => props.newTerms, handleTermsChange)

    return { modalVisibility, currentTerm, termsPhrase, termHTML, handleAcceptTerms, DefaultTheme }
  },
})
