
import TabCreateList from '@/components/Application/tabCreateList/TabCreateList.vue'
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiSelectObject from '@/components/Generics/PazzeiSelectObject.vue'
import TabPanel from '@/components/Library/Tabs/TabPanel/index.vue'
import TabPanels from '@/components/Library/Tabs/TabPanels/index.vue'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import groupsService from '@/services/groups'
import Input from '@/components/Library/Input/index.vue'
import listsService from '@/services/list'
import usersService from '@/services/users'
import { EntitiesGroups, Groups } from '@/types/GroupsType'
import { User } from '@/types/UsersType'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import debounce from 'quasar/src/utils/debounce.js';import useQuasar from 'quasar/src/composables/use-quasar.js';
import { PropType, computed, defineComponent, inject, onMounted, ref, watch, Ref, watchEffect } from 'vue'

export default defineComponent({
  name: 'ModalAddReceivers',
  props: {
    isPageListQuestions: {
      type: Boolean,
      default: false,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    labelSecundaryButton: { type: String, default: 'Cancelar' },
    clickSecundaryButton: Function as PropType<() => void>,
    clickConfirm: Function as PropType<(value: Date | undefined) => void>,
    addPadding: {
      type: Boolean,
      default: false,
    },
    hasMarginTop: {
      type: Boolean,
      default: false,
    },
    listId: {
      type: Number,
      default: null,
    },
    getListsData: Function as PropType<() => void>,
    assignListCreated: Boolean,
    showFeedback: Boolean,
  },
  components: {
    PazzeiBtn,
    TabCreateList,
    TabPanels,
    TabPanel,
    PazzeiSelectObject,
    Input,
  },
  emits: ['sendDateTime', 'close', 'showOptionSeeFeedback'],

  setup(props, { emit }) {
    const validation = ref<{ groups: { id: string; name: string }[]; students: { id: string; name: string }[] }>({
      groups: [],
      students: [],
    })
    const optionstab = ref('Selecionar por aluno')
    const selectListTab = ref(['Selecionar por aluno', 'Selecionar por grupo'])
    const studentOptions = ref<Array<{ name: string; id: string }>>([])
    const groupOptions = ref<Array<{ name: string; id: string }>>([])
    const listUserByInstitution = ref<User[]>()
    const listGroups = ref<Groups[]>()
    const $q = useQuasar()
    const messageRequired = 'Este campo é obrigatório'
    const scrollValue = ref(0)
    const totalElements = ref(0)
    const searchValue = ref<string | undefined>('')
    const isLoading = ref(false)
    const currentPage = ref(1)
    const pageSize = 30
    const expirationDate = ref('')
    const expirationTime = ref('')
    const showOptionSeeFeedback = ref(false)
    const heightContent = inject<Ref<string>>('heightContentModal')

    const dateTime = computed(() => {
      if (expirationDate.value || expirationTime.value) {
        const formatDate = `${expirationDate.value}T${expirationTime.value === '' ? '23:59' : expirationTime.value}`
        return new Date(formatDate)
      } else {
        return undefined
      }
    })

    watchEffect(() => {
      if (expirationDate.value || expirationTime.value) emit('sendDateTime', dateTime.value)
    })

    watch(showOptionSeeFeedback, () => {
      emit('showOptionSeeFeedback', showOptionSeeFeedback.value)
    })

    const { setSelectedGroups } = inject<{ setSelectedGroups: (t: Array<{ id: string; name: string }>) => void }>('selectedGroups', {
      setSelectedGroups: () => {
        return
      },
    })

    const { setSelectedStudents } = inject<{ setSelectedStudents: (t: Array<{ id: string; name: string }>) => void }>('selectedStudents', {
      setSelectedStudents: () => {
        return
      },
    })

    const rules = computed(() => {
      return {
        students: { required: helpers.withMessage(messageRequired, required) },
        groups: { required: helpers.withMessage(messageRequired, required) },
      }
    })

    const v$ = useVuelidate(rules, validation)

    const lastIndexScroll = (event: number) => {
      scrollValue.value = event
    }

    watch(
      validation,
      () => {
        if (validation.value.groups) setSelectedGroups(validation.value.groups)
        if (validation.value.students) setSelectedStudents(validation.value.students)
      },
      { deep: true }
    )

    const handleModalClose = () => {
      emit('close')
    }

    const getCurrentDate = () => {
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate())
      return currentDate.toISOString().split('T')[0]
    }

    const listUserStudent = async (page: number, pageSize: number) => {
      const reqBody = {
        page: page,
        pageSize: pageSize,
        search: {
          name: searchValue.value !== '' ? searchValue.value : undefined,
          roleDescription: 'Aluno',
        },
      }

      try {
        const response = await usersService.post<{ data: { entities: User[]; totalElements: number } }>('/filter-users', reqBody)
        listUserByInstitution.value = response.data.data.entities
        totalElements.value = response.data.data.totalElements

        const newListFormatted = listUserByInstitution.value?.map((it: User) => ({
          name: `${it.name} ${it.lastName !== null ? it.lastName : ''}`,
          id: it.id,
        }))

        if (page === 1) {
          studentOptions.value = newListFormatted
        } else {
          studentOptions.value = [...studentOptions.value, ...newListFormatted]
        }
      } catch (error) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao buscar dados',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    const getGroupData = async (page: number, pageSize: number) => {
      const requestBody = {
        page: page,
        pageSize: pageSize,
        search: {
          name: searchValue.value !== '' ? searchValue.value : undefined,
        },
      }

      try {
        const response = await groupsService.post<ResponseDTO<EntitiesGroups>>('/list', requestBody)
        listGroups.value = response.data.data.entities
        totalElements.value = response.data.data.totalElements
        const newListFormattedGroups = listGroups.value?.map((row: Groups) => {
          return {
            name: row.name,
            id: row.id,
          }
        })

        if (page === 1) {
          groupOptions.value = newListFormattedGroups
        } else {
          groupOptions.value = [...groupOptions.value, ...newListFormattedGroups]
        }
      } catch (error) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao buscar dados',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    const assignReceivers = async () => {
      try {
        isLoading.value = true
        const data = {
          id: props.listId,
          expiresAt: dateTime.value,
          userIds: validation.value.students.length ? validation.value.students.map((it) => it.id) : undefined,
          groupIds: validation.value.groups.length ? validation.value.groups.map((it) => Number(it.id)) : undefined,
        }
        await listsService.patch('/assign', data)
        $q.notify({
          textColor: 'grey-1',
          message: `Usuário(s) vinculado(s) com sucesso`,
          color: 'green',
          position: 'top',
          classes: 'notify',
        })
      } catch (error) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao vincular usuário(s)',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      } finally {
        isLoading.value = false
        props.getListsData?.()
        handleModalClose()
      }
    }

    const handleSearch = (value: string) => {
      searchValue.value = value
      currentPage.value = 1

      if (optionstab.value === 'Selecionar por aluno') {
        debouncedGetInstitutionUsers(currentPage.value, pageSize)
      } else {
        debouncedGetGroupData(currentPage.value, pageSize)
      }
    }

    const debouncedGetInstitutionUsers = debounce(listUserStudent, 500)
    const debouncedGetGroupData = debounce(getGroupData, 500)

    watch(searchValue, () => {
      if (optionstab.value === 'Selecionar por aluno') {
        debouncedGetInstitutionUsers(currentPage.value, pageSize)
      } else {
        debouncedGetGroupData(currentPage.value, pageSize)
      }
    })

    watch(scrollValue, (newValue) => {
      const lastIndex = optionstab.value === 'Selecionar por aluno' ? studentOptions.value.length - 1 : groupOptions.value.length - 1

      if (newValue === lastIndex && (studentOptions.value.length < totalElements.value || groupOptions.value.length < totalElements.value)) {
        const remainingResults = totalElements.value - studentOptions.value.length
        const remainingPages = Math.ceil(remainingResults / pageSize)

        if (remainingPages > 0) {
          currentPage.value++
          if (optionstab.value === 'Selecionar por aluno') {
            listUserStudent(currentPage.value, pageSize)
          } else {
            getGroupData(currentPage.value, pageSize)
          }
        }
      }
    })

    watch(optionstab, async () => {
      if (optionstab.value === 'Selecionar por aluno') {
        searchValue.value = ''
        await listUserStudent(currentPage.value, pageSize)
      } else {
        searchValue.value = ''
        await getGroupData(currentPage.value, pageSize)
      }
    })

    onMounted(async () => {
      await listUserStudent(currentPage.value, pageSize)
    })

    return {
      v$,
      handleModalClose,
      optionstab,
      selectListTab,
      validation,
      studentOptions,
      isLoading,
      lastIndexScroll,
      handleSearch,
      groupOptions,
      assignReceivers,
      expirationDate,
      expirationTime,
      dateTime,
      getCurrentDate,
      heightContent,
      showOptionSeeFeedback,
    }
  },
})
