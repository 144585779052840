
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import Input from '@/components/Library/Input/index.vue'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import { SubjectInfoDTO } from '@/dtos/SubjectInfoDTO'
import listsService from '@/services/list'
import useVuelidate from '@vuelidate/core'
import { helpers, minLength, required } from '@vuelidate/validators'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'

export default defineComponent({
  name: 'ContentModalEditListName',
  emits: ['close'],
  components: { Input, PazzeiBtn },
  props: {
    listId: {
      type: Number,
      default: -1,
    },
    listExercices: Function as PropType<() => void>,
    sizeEditLoading: {
      type: String,
    },
    modalTitle: { type: String, default: '' },
    label: { type: String, default: '' },
  },
  setup(props, { emit }) {
    const isLoading = ref(false)
    const isFetchData = ref(false)
    const $q = useQuasar()
    const messageRequired = 'Este campo é obrigatório'
    const validation = ref<{ listName: string }>({
      listName: '',
    })

    const rules = computed(() => {
      return {
        listName: { required: helpers.withMessage(messageRequired, required), minLength: helpers.withMessage('O nome do grupo deve ter no mínimo 3 caracteres', minLength(3)) },
      }
    })

    const v$ = useVuelidate(rules, validation)

    const fetchNameList = async (listId: number) => {
      isFetchData.value = true
      try {
        const { data: groupName } = await listsService.get<ResponseDTO<SubjectInfoDTO>>('/id', { params: { listId: listId } })
        validation.value.listName = groupName.data.name
      } catch (error) {
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao buscar nome',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      } finally {
        isFetchData.value = false
      }
    }

    const updateListName = async () => {
      const result = await v$.value.$validate()
      if (result) {
        isLoading.value = true
        try {
          const data = ref({ id: props.listId, listName: validation.value.listName })
          await listsService.patch<ResponseDTO<SubjectInfoDTO>>('/', data.value)
          $q.notify({
            textColor: 'grey-1',
            message: 'Nome editado com sucesso',
            color: 'green',
            position: 'top',
            classes: 'notify',
          })
        } catch (error) {
          $q.notify({
            textColor: 'grey-1',
            message: 'Erro ao editar nome',
            color: 'red',
            position: 'top',
            classes: 'notify',
          })
        } finally {
          props.listExercices?.()
          isLoading.value = false
          emit('close')
        }
      }
    }

    onMounted(() => {
      fetchNameList(props.listId)
    })
    return {
      v$,
      validation,
      updateGroupName: updateListName,
      isLoading,
      isFetchData,
    }
  },
})
