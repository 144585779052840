export const QuestionTagsOrderEnum = [
  {
    order: 1,
    value: 'subject',
  },
  {
    order: 2,
    value: 'division',
  },
  {
    order: 3,
    value: 'topic',
  },
  {
    order: 4,
    value: 'item',
  },
  {
    order: 5,
    value: 'subitem',
  },
]
