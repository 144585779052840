import axios from 'axios'

export interface AddressTypeReturn {
  cep: string
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: string
  ibge: string
  gia: string
  ddd: string
  siafi: string
}

export const getAddressByCep = async (cep: string): Promise<AddressTypeReturn> => {
  const res = await axios.get<AddressTypeReturn>(`https://viacep.com.br/ws/${cep}/json/`)

  return res.data
}
