export const THEMES = {
    PAZZEI: 'portal',
    ARCANJO: 'tropadoarcanjo',
    BIOEXPLICA: 'bioexplica',
}

export type FontSizes = {
    eight: string
    ten: string
    twelve: string
    fourteen: string
    sixteen: string
    eighteen: string
    twenty: string
    twentyFour: string
    twentyEight: string
    thirtyFour: string
}

export type SizesType = {
    sm: string
    md: string
    lg: string
    xl: string
}

export type ColorsType = {
    black: string;
    gray: string;
    graphite: string;
    primaryColor: string;
    grayColor: string;
    whiteColor: string;
    colorGrayDark: string;
    colorGray: string;
    pinkColor: string;
    blueColor: string;
    greenBtnColor: string;
    backdropColor: string;
    
    secondaryGray: string;
    lightGray: string;
    disabled: string;
    
    primary: string;
    secondary: string;
    error: string;
    errorHover: string;
    
    defaultBackground: string;
    secondaryBackground: string;
    selectedItemBackground: string;
    
    defaultBorder: string;
    highlightBorder: string;
    successBorder: string;
    errorBorder: string;
    disabledBorder: string;
    
    defaultFontColor: string;
    warningFontColor: string;
    errorFontColor: string;
    
    selectedOptionBackground: string;
    
    correctQuestionPrimary: string;
    correctQuestionBackground: string;
    correctQuestionBorder: string;
    correctQuestionNumber: string;
    correctQuestionNumberHover: string;
    
    incorrectQuestionPrimary: string;
    incorrectQuestionBackground: string;
    incorrectQuestionBorder: string;
    incorrectQuestionNumber: string;
    incorrectQuestionNumberHover: string;
    
    defaultLoadingBackground: string;
    defaultLoadingBorder: string;
    
    lightGreenColor: string;
    
    gray25: string;
    gray50: string;
    gray100: string;
    gray200: string;
    gray300: string;
    gray400: string;
    gray500: string;
    gray600: string;
    gray700: string;
    gray750: string;
    gray800: string;
    gray900: string;
    
    primary25: string;
    primary50: string;
    primary100: string;
    primary200: string;
    primary300: string;
    primary400: string;
    primary500: string;
    primary600: string;
    primary700: string;
    primary800: string;
    primary900: string;
    
    secondary25: string;
    secondary50: string;
    secondary100: string;
    secondary200: string;
    secondary300: string;
    secondary400: string;
    secondary500: string;
    secondary600: string;
    secondary700: string;
    secondary800: string;
    secondary900: string;
    
    tertiary25: string;
    tertiary50: string;
    tertiary100: string;
    tertiary200: string;
    tertiary300: string;
    tertiary400: string;
    tertiary500: string;
    tertiary600: string;
    tertiary700: string;
    tertiary800: string;
    tertiary900: string;
    
    error25: string;
    error50: string;
    error100: string;
    error200: string;
    error300: string;
    error400: string;
    error500: string;
    error600: string;
    error700: string;
    error800: string;
    error900: string;
    
    warning25: string;
    warning50: string;
    warning100: string;
    warning200: string;
    warning300: string;
    warning400: string;
    warning500: string;
    warning600: string;
    warning700: string;
    warning800: string;
    warning900: string;
    
    success25: string;
    success50: string;
    success100: string;
    success200: string;
    success300: string;
    success400: string;
    success500: string;
    success600: string;
    success700: string;
    success800: string;
    success900: string;
    
    blue25: string;
    blue50: string;
    blue100: string;
    blue200: string;
    blue300: string;
    blue400: string;
    blue500: string;
    blue600: string;
    blue700: string;
    blue800: string;
    blue900: string;
    
    darkBlue25: string;
    darkBlue50: string;
    darkBlue100: string;
    darkBlue200: string;
    darkBlue300: string;
    darkBlue400: string;
    darkBlue500: string;
    darkBlue600: string;
    darkBlue700: string;
    darkBlue800: string;
    darkBlue900: string;
    
    indigo25: string;
    indigo50: string;
    indigo100: string;
    indigo200: string;
    indigo300: string;
    indigo400: string;
    indigo500: string;
    indigo600: string;
    indigo700: string;
    indigo800: string;
    indigo900: string;
    
    purple25: string;
    purple50: string;
    purple100: string;
    purple200: string;
    purple300: string;
    purple400: string;
    purple500: string;
    purple600: string;
    purple700: string;
    purple800: string;
    purple900: string;
    
    fuchsia25: string;
    fuchsia50: string;
    fuchsia100: string;
    fuchsia200: string;
    fuchsia300: string;
    fuchsia400: string;
    fuchsia500: string;
    fuchsia600: string;
    fuchsia700: string;
    fuchsia800: string;
    fuchsia900: string;
    
    rose25: string;
    rose50: string;
    rose100: string;
    rose200: string;
    rose300: string;
    rose400: string;
    rose500: string;
    rose600: string;
    rose700: string;
    rose800: string;
    rose900: string;
    
    darkOrange25: string;
    darkOrange50: string;
    darkOrange100: string;
    darkOrange200: string;
    darkOrange300: string;
    darkOrange400: string;
    darkOrange500: string;
    darkOrange600: string;
    darkOrange700: string;
    darkOrange800: string;
    darkOrange900: string;
    
    orange25: string;
    orange50: string;
    orange100: string;
    orange200: string;
    orange300: string;
    orange400: string;
    orange500: string;
    orange600: string;
    orange700: string;
    orange800: string;
    orange900: string;
};

export type AssetsType = {
    logo: string;
    shortLogo: string;
    superProLogo: string;
}

export type ThemeType = {
    colors: ColorsType;
    fontSizes: FontSizes;
    sizes: SizesType;
    assets: AssetsType;
};
