import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = { id: "Group" }
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]
const _hoisted_5 = ["fill"]
const _hoisted_6 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 24 22",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("g", _hoisted_2, [
      _createElementVNode("path", {
        id: "Vector",
        d: "M15.7783 7.33385H5.40445C4.74484 7.33385 4.21375 6.7887 4.21375 6.11164C4.21375 5.43458 4.74484 4.8894 5.40445 4.8894H15.7783C16.4379 4.8894 16.969 5.43458 16.969 6.11164C16.969 6.7887 16.4379 7.33385 15.7783 7.33385Z",
        fill: _ctx.fill
      }, null, 8, _hoisted_3),
      _createElementVNode("path", {
        id: "Vector_2",
        d: "M15.1873 17.1111H1.47263C0.813019 17.1111 0.281921 16.5659 0.281921 15.8889C0.281921 15.2118 0.813019 14.6667 1.47263 14.6667H15.1873C18.4682 14.6667 21.1409 11.9233 21.1409 8.55556C21.1409 5.18786 18.4682 2.44444 15.1873 2.44444H1.47263C0.813019 2.44444 0.281921 1.89929 0.281921 1.22223C0.281921 0.545176 0.813019 0 1.47263 0H15.1873C19.7789 0 23.5223 3.83374 23.5223 8.55556C23.5223 13.2774 19.7874 17.1111 15.1873 17.1111Z",
        fill: _ctx.fill
      }, null, 8, _hoisted_4),
      _createElementVNode("path", {
        id: "Vector_3",
        d: "M15.7783 12.2213H5.40445C4.74484 12.2213 4.21375 11.6761 4.21375 10.9991C4.21375 10.322 4.74484 9.77686 5.40445 9.77686H15.7783C16.4379 9.77686 16.969 10.322 16.969 10.9991C16.969 11.6761 16.4379 12.2213 15.7783 12.2213Z",
        fill: _ctx.fill
      }, null, 8, _hoisted_5),
      _createElementVNode("path", {
        id: "Vector_4",
        d: "M15.7784 22.0001H5.40457C4.74497 22.0001 4.21387 21.4549 4.21387 20.7779C4.21387 20.1008 4.74497 19.5557 5.40457 19.5557H15.7784C16.438 19.5557 16.9691 20.1008 16.9691 20.7779C16.9691 21.4549 16.438 22.0001 15.7784 22.0001Z",
        fill: _ctx.fill
      }, null, 8, _hoisted_6)
    ])
  ], 8, _hoisted_1))
}