
import { defineComponent, inject, Ref } from 'vue'

export default defineComponent({
  name: 'MainLayoutWrapper',
  props: {
    answerList: { type: Boolean, default: false },
  },
  setup() {
    const isExerciseList = inject<Ref<boolean>>('isExerciseList')

    return { isExerciseList }
  },
})
