const staticTerms = `<!DOCTYPE html>
<html><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
   
   </head>
   <body>
  <div class="h-full text-gray" style="padding-right: 0.5em; font-size: 12px; font-family: Maison Neue; font-weight: normal; src: url(&#39;@/assets/fonts/Maison Neue Light.woff&#39;)&#39;">
      <h1 class="q-ma-none text-size-20 text-decoration-underline font-bold">TERMOS E CONDIÇÕES</h1>
      <p>
        Este documento, e todo o conteúdo do site <a href="https://static-files.pazzei.com.br/terms-of-use/www.pazzei.com.br">www.pazzei.com.br</a> é oferecido por <strong class="font-bold">COLIBRI INFORMÁTICA LTDA</strong>, neste termo representado
        apenas por PAZZEI, inscrito no CNPJ/CPF n°00.213.220/0001-01, titular da propriedade intelectual sobre software, website, aplicativos, conteúdos e demais ativos relacionados à plataforma
        pazzei.com.br. que regulamenta todos os direitos e obrigações com todos que acessam o site, denominado neste termo como “VISITANTE”, resguardado todos os direitos previstos na legislação, trazem
        as cláusulas abaixo como requisito para acesso e visita do mesmo, situado no endereço <strong class="font-bold">www.pazzei.com.br</strong>
      </p>
      <p>A permanência no website implica automaticamente na leitura e aceitação tácita dos presentes termos de uso a seguir.</p>

      <h2 class="text-size-20 text-decoration-underline font-bold">TERMO 01 – Referente ao objeto</h2>
      <p>
        Este objeto visa licenciar o uso de seu software, website, aplicativos e demais ativos da propriedade intelectual, fornecendo ferramentas que auxiliam e façam com que tenham dinamismo aos seus
        usuários.
      </p>
      <p>
        A referida plataforma se caracteriza pela prestação do serviço de geração de listas de exercícios de forma automática com base em filtros selecionados pelos usuários, utilizando um banco de
        questões de vestibulares, processos seletivos e exames, destinado a melhorar a rotina acadêmica de alunos.
      </p>
      <p>
        A referida plataforma realiza a venda à distância por meio eletrônico do seguinte serviço de assinatura de software de lista de exercícios e simulados, gerados automaticamente, no plano
        essencial.
      </p>
      <p>Plano Essencial:</p>
      <ul>
        <li>Acesso ilimitado ao banco de questões disponível.</li>
        <li>Acesso ilimitado ao gerador automático de lista de exercícios.</li>
        <li>Banco inteligente com mais de 150.000 questões dos principais vestibulares e exames do Brasil.</li>
        <li>Todas as questões resolvidas e comentadas.</li>
        <li>Visualize relatórios da sua performance nas suas listas online.</li>
      </ul>

      <h2 class="text-size-20 text-decoration-underline font-bold">TERMO 02 – Referente a aceitação</h2>
      <p>
        O presente Termo estabelece obrigações contratadas de livre e espontânea vontade, por tempo indeterminado, entre a plataforma e as pessoas físicas ou jurídicas, usuárias do site e seu
        aplicativo.
      </p>
      <p>Ao utilizar a plataforma o usuário aceita integralmente as presentes normas e compromete-se a observá-las, sob o risco de aplicação das penalidades cabíveis.</p>
      <p>
        A aceitação do presente instrumento é imprescindível para o acesso e para a utilização de quaisquer serviços fornecidos pela empresa. Caso não concorde com as disposições deste instrumento, o
        usuário não deve utilizá-los.
      </p>

      <h2 class="text-size-20 text-decoration-underline font-bold">Termo 03 – Referente ao acesso dos usuários</h2>
      <p>
        Serão utilizadas soluções técnicas a disposição do responsável pela plataforma para permitir que os usuários tenham acesso ao serviço 24 (vinte e quatro) horas por dia, 7 (sete) dias por semana.
      </p>
      <p>
        A navegação pode ser interrompida, limitada ou suspensa por período para atualizações, modificações e ou qualquer interferência que possa vir a ocorrer para que possam ocorrer melhorias quanto
        ao seu funcionamento.
      </p>

      <h2 class="text-size-20 text-decoration-underline font-bold">Termo 04 – Referente ao cadastro</h2>
      <p>O acesso às funcionalidades da plataforma exigirá a realização de um cadastro prévio e, a depender dos serviços ou produtos escolhidos, o pagamento de determinado valor.</p>
      <p>
        Ao se cadastrar o usuário deverá informar dados completos, recentes e válidos, sendo de sua exclusiva responsabilidade manter referidos dados atualizados, bem como o usuário se compromete com a
        veracidade dos dados fornecidos.
      </p>
      <p>O usuário se compromete a não informar seus dados cadastrais e/ou de acesso à plataforma a terceiros, responsabilizando-se integralmente pelo uso que deles seja feito.</p>
      <p>
       Menores de 18 anos e aqueles que não possuírem plena capacidade civil deverão obter previamente o consentimento expresso de seus responsáveis legais para utilização da plataforma e dos serviços ou produtos, sendo de responsabilidade exclusiva dos mesmos o eventual acesso por menores de idade e por aqueles que não possuem plena capacidade civil sem a prévia autorização. Ao criar a conta o usuário que for menor de idade deverá informar o nome e email do responsável legal, inclusive, informar se é emancipado na forma do disposto na legislação civil (hipóteses previstas no artigo 5º, parágrafo único, do Código Civil brasileiro). É de responsabilidade exclusiva dos pais o uso da plataforma por parte dos adolescentes, bem como a gestão do tempo de uso da plataforma e a concessão dos dados de acesso. 
      </p>
      <p>Mediante a realização do cadastro o usuário declara e garante expressamente ser plenamente capaz, podendo exercer e usufruir livremente dos serviços e produtos.</p>
      <p>O usuário deverá fornecer um endereço de e-mail válido, através do qual o site realizará todas as comunicações necessárias.</p>
      <p>
        Após a confirmação do cadastro, o usuário possuirá um login e uma senha pessoal, a qual assegura ao usuário o acesso individual à mesma. Desta forma, compete ao usuário exclusivamente a
        manutenção de referida senha de maneira confidencial e segura, evitando o acesso indevido às informações pessoais.
      </p>
      <p>
        Toda e qualquer atividade realizada com o uso da senha será de responsabilidade do usuário, que deverá informar prontamente a plataforma em caso de uso indevido da respectiva senha. O uso
        indevido da respectiva senha, nas situações em que o usuário seja menor de idade, acarreta a obrigação de seu responsável legal ressarcir eventuais danos morais e/ou materiais que venha a causar
        a plataforma ou a terceiros.
      </p>
      <p>Não será permitido ceder, vender, alugar ou transferir, de qualquer forma, a conta, que é pessoal e intransferível.</p>
      <p>Caberá ao usuário assegurar que o seu equipamento seja compatível com as características técnicas que viabilize a utilização da plataforma e dos serviços ou produtos.</p>
      <p>
        O usuário poderá, a qualquer tempo, requerer o cancelamento de seu cadastro junto ao site <a href="https://static-files.pazzei.com.br/terms-of-use/www.pazzei.com.br/contato">www.pazzei.com.br/contato</a>. O seu descadastramento será realizado o mais rapidamente possível, desde que não sejam verificados débitos em aberto. Os planos disponíveis são de assinatura mensal ou semestral. 
Os planos mensais, são pagos mês a mês, no mesmo dia da contratação, ou seja, contratando-se dia 15, a cobrança será todo dia 15, via cartão de crédito, com assinatura recorrente. Poderá ser feito o cancelamento mediante solicitação por email contato@pazzei.com.br com pelo menos 24h de antecedência. Não haverá devolução do valor pago, mesmo sem o consumo de conteúdo e utilização da plataforma por parte do contratante, até que seja feita a solicitação de cancelamento.
      </p>
       <p> Já o plano semestral será pago à vista ou parcelado via cartão de crédito em até 6x sem juros sendo que neste caso, em virtude do valor promocional ofertado, não há devolução dos valores pagos e nem cancelamento do respectivo plano antes do término do período contrato, sendo que o serviço permanecerá à disposição do usuário durante todo o tempo previsto para o plano.
Tanto nos planos mensais como semestrais, mesmo quando há cancelamento, o usuário poderá realizar o acesso à plataforma até o final do seu período já pago. </p>
      <p>
        O usuário, ao aceitar os Termos e Política de Privacidade, autoriza expressamente a plataforma a coletar, usar, armazenar, tratar, ceder ou utilizar as informações derivadas do uso dos serviços,
        do site e quaisquer plataformas, incluindo todas as informações preenchidas pelo usuário no momento em que realizar ou atualizar seu cadastro, além de outras expressamente descritas na Política
        de Privacidade que deverá ser autorizada pelo usuário usuário e/ou seu representante legal.
      </p>

      <h2 class="text-size-20 text-decoration-underline font-bold">TERMO 05 – Referente aos serviços e produtos</h2>
      <p>Em relação aos serviços, a plataforma poderá disponibilizar para o usuário um conjunto específico de funcionalidades e ferramentas para otimizar o uso dos serviços e produtos.</p>
      <p>
        Na plataforma os serviços e produtos oferecidos serão descritos e apresentados ao consumidor de forma exata, onde terão acesso as informações pertinentes as suas características, qualidades,
        composição, preço, garantia, prazos em relação a sua validade e origem, entre outros dados, como também os possíveis riscos que possam apresentar à saúde e segurança dos usuários.
      </p>
      <p>Referente a finalização e contratação de nosso produto, o usuário se responsabiliza de ter antes se informado sobre as especificações e a destinação de nosso produto ofertado.</p>

      <h2 class="text-size-20 text-decoration-underline font-bold">TERMO 06 – Referente aos preços</h2>
      <p>
        Esta plataforma reserva o direito de reajustar de maneira unilateral, em qualquer momento, os valores de seus serviços e produtos sem consulta ou anuência prévia por parte dos usuários,
        esclarecendo-se que são aplicados os valores vigentes no momento da compra do produto.
      </p>
      <p>
        Na contratação de determinado serviço ou produto, a plataforma poderá solicitar as informações financeiras do usuário, como CPF, endereço de cobrança e dados de cartões. Ao inserir referidos
        dados o usuário concorda que sejam cobrados, de acordo com a forma de pagamento que venha a ser escolhida, os preços então vigentes e informados quando da contratação. Referidos dados
        financeiros poderão ser armazenados para facilitar acessos e contratações futuras.
      </p>
      <p>
        A contratação dos serviços será renovada automaticamente pela plataforma, independentemente de comunicação ao usuário, mediante cobrança periódica da mesma forma de pagamento indicada pelo
        usuário quando da contratação do serviço.
      </p>

      <h2 class="text-size-20 text-decoration-underline font-bold">TERMO 07 – Referente ao cancelamento, troca e devolução</h2>
      <p>
        O usuário poderá cancelar a contratação dos serviços de acordo com os termos que forem definidos no momento de sua contratação. Ainda, o usuário também poderá cancelar os serviços em até 7
        (sete) dias após a contratação, mediante contato com o e-mail contato@pazzei.com.br, de acordo com o Código de Defesa do Consumidor (Lei no. 8.078/90) Art. 41.
      </p>
      <p>O serviço poderá ser cancelado por:</p>
      <p>a) parte do usuário:  quando solicitado o cancelamento pelo e-mail contato@pazzei.com.br com pelo menos 24 horas de antecedência em relação ao dia de renovação do seu plano. Não será estornado nenhum valor da contratação independente do consumo do conteúdo pelo contratante enquanto o plano estiver ativo dentro das condições de renovação mensal.</p>
      <p>b) violação dos Termos de Uso: os serviços serão cessados imediatamente.</p>

      <h2 class="text-size-20 text-decoration-underline font-bold">Da troca e devolução</h2>
      <p>A política de troca e devoluções da plataforma é regida conforme o Código de Defesa do Consumidor (Lei nº 8.078/90).</p>
      <p>A troca e/ou devolução do serviço poderá ocorrer por:</p>
      <p>a) direito de arrependimento;</p>
      <p>
        Em caso de arrependimento, o usuário poderá devolver o produto em até 7 (sete) dias após o seu recebimento, mediante contato com o contato@pazzei.com.br, de acordo com o Código de Defesa do
        Consumidor (Lei nº 8.078/90, artigo 49).
      </p>

      <h2 class="text-size-20 text-decoration-underline font-bold">TERMO 08 – Referente ao suporte</h2>
      <p>Em caso de qualquer dúvida, sugestão ou problema com a utilização da plataforma, o usuário poderá entrar em contato com o suporte, através dos canais:</p>
      <p>- E-mail: contato@pazzei.com.br</p>
      <p>Estes serviços de atendimento ao usuário estarão disponíveis nos seguintes dias e horários:</p>
      <p>Segunda a sexta das 9h às 17h, exceto feriados.</p>

      <h2 class="text-size-20 text-decoration-underline font-bold">TERMO 09 – Referente às responsabilidades</h2>
      <p>É de responsabilidade do usuário:</p>
      <p>a) defeitos ou vícios técnicos originados no próprio sistema do usuário;</p>
      <p>b) a correta utilização da plataforma, dos serviços ou produtos oferecidos, prezando pela boa convivência, pelo respeito e cordialidade entre os usuários;</p>
      <p>c) pelo cumprimento e respeito ao conjunto de regras disposto nesse Termo de Condições Geral de Uso, na respectiva Política de Privacidade e na legislação nacional e internacional;</p>
      <p>d) pela proteção aos dados de acesso à sua conta/perfil (login e senha).</p>
      <p>É de responsabilidade da plataforma Pazzei:</p>
      <p>a) indicar as características do serviço ou produto;</p>
      <p>b) os defeitos e vícios encontrados no serviço ou produto oferecido desde que lhe tenha dado causa;</p>
      <p>c) as informações que foram por ele divulgadas, sendo que os comentários ou informações divulgadas por usuários são de inteira responsabilidade dos próprios usuários;</p>
      <p>d) os conteúdos ou atividades ilícitas praticadas através da sua plataforma.</p>
      <p>
        A plataforma não se responsabiliza por links externos contidos em seu sistema que possam redirecionar o usuário a ambientes externos a sua rede. Não poderão ser incluídos links externos ou
        páginas que sirvam para fins comerciais ou publicitários ou quaisquer informações ilícitas, violentas, polêmicas, pornográficas, xenofóbicas, discriminatórias ou ofensivas.
      </p>

      <h2 class="text-size-20 text-decoration-underline font-bold">TERMO 10 – Referente às sanções</h2>
      <p>Sem prejuízo das demais medidas legais cabíveis, a COLIBRI INFORMÁTICA LTDA poderá, a qualquer momento, advertir, suspender ou cancelar a conta do usuário:</p>
      <p>a) que violar qualquer dispositivo do presente Termo;</p>
      <p>b) que descumprir os seus deveres de usuário;</p>
      <p>c) que tenha qualquer comportamento fraudulento, doloso ou que ofenda a terceiros.</p>

      <h2 class="text-size-20 text-decoration-underline font-bold">TERMO 11 - Referente a jurisdição para resolução de conflitos</h2>
      <p>
        Eventualmente se existirem controvérsias decorrentes do presente instrumento será aplicado integralmente o Direito Brasileiro. Os eventuais assuntos jurídicos deverão ser apresentados no foro da
        comarca em que se encontra a sede da empresa.
      </p>
    </div>
     
</body></html>`

export default staticTerms;
