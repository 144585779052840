import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 20 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size + 1,
    height: _ctx.size
  }, [
    _createElementVNode("path", {
      d: "M17.18 0L10 7.18L2.82 0L0 2.82L7.18 10L0 17.18L2.82 20L10 12.82L17.18 20L20 17.18L12.82 10L20 2.82L17.18 0Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}