import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13d5dd04"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "modal--list-summary-variant-container"
}
const _hoisted_2 = { class: "modal--list-summary-variant-backdrop" }
const _hoisted_3 = { class: "modal-content-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseIcon = _resolveComponent("CloseIcon")!
  const _component_Backdrop = _resolveComponent("Backdrop")!

  return (_ctx.modelValue)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "#portal-root"
      }, [
        _createVNode(_component_Backdrop, {
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (!_ctx.preventClosing ? _ctx.handleClose(_ctx.notClose) : null), ["self"]))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              ref: "modalRef",
              class: _normalizeClass(["modal", { [`modal--${_ctx.variant}`]: _ctx.variant }]),
              style: _normalizeStyle({ height: _ctx.height, width: _ctx.width }),
              tabindex: "-1"
            }, [
              (!_ctx.hideIcon)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["close", { [`close--${_ctx.variant}`]: _ctx.variant, 'modal-close-button-container-checkout': _ctx.isCheckout }])
                  }, [
                    _createElementVNode("button", {
                      class: "modal-close-button",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.preventClosing ? _ctx.handleClose() : null))
                    }, [
                      _createVNode(_component_CloseIcon, {
                        fill: _ctx.DefaultTheme().colors.primary,
                        class: _normalizeClass({ [`modal--${_ctx.variant}`]: _ctx.variant })
                      }, null, 8, ["fill", "class"])
                    ])
                  ], 2))
                : _createCommentVNode("", true),
              (_ctx.variant === 'list-summary')
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _renderSlot(_ctx.$slots, "list-summary", {}, undefined, true)
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ])
            ], 6)
          ]),
          _: 3
        })
      ]))
    : _createCommentVNode("", true)
}