
import XCloseIcon from '@/components/Icons/default/XCloseIcon.vue';
import { ModalInfoDTO } from '@/dtos/modal/ModalInfoDTO';
import DefaultTheme from '@/theme/defaultTheme';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'PazzeiModal',
  emits: ['close-modal'],
  props: {
    context: {
      type: Object as PropType<ModalInfoDTO>,
      required: true,
    },
    responsivenessWidth: { type: String, default: '70%' },
    responsivenessHeight: { type: String, default: '40%' },
  },
  components: {
    XCloseIcon
  },
  setup(props, ctx) {
    const closeModal = () => {
      ctx.emit('close-modal', true);
    };

    return {
      closeModal,
      DefaultTheme
    }
  }
})
