import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6665be62"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, { to: "/meus-dados" }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClick('profile'))),
          class: _normalizeClass(["tab-item", _ctx.tabRef === 'profile' ? _ctx.tabRef : ''])
        }, "Meus Dados", 2)
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, { to: "/dados-da-conta" }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleClick('credentials'))),
          class: _normalizeClass(["tab-item", _ctx.tabRef === 'credentials' ? _ctx.tabRef : ''])
        }, "Dados da Conta", 2)
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, { to: "/perfil-do-aluno" }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleClick('preferences'))),
          class: _normalizeClass(["tab-item", _ctx.tabRef === 'preferences' ? _ctx.tabRef : ''])
        }, "Perfil do aluno", 2)
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, { to: "/meus-planos" }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleClick('plan'))),
          class: _normalizeClass(["tab-item", _ctx.tabRef === 'plan' ? _ctx.tabRef : ''])
        }, "Meu plano", 2)
      ]),
      _: 1
    }),
    _createElementVNode("div", {
      class: _normalizeClass(["tab-indicator", _ctx.tabRef])
    }, null, 2)
  ]))
}