
import PazzeiAvatar from '@/components/Generics/PazzeiAvatar.vue'
import InstitutionPlans from '@/helpers/InstitutionPlans'
import authService from '@/services/authService'
// import DefaultTheme, { productName, THEMES } from '@/theme/defaultTheme'
import { UserDTO } from '@/dtos/ResponseDTOs/UserDTO'
import { userScopeCanGo } from '@/enum/userCanSeeEnum'
import DefaultTheme from '@/theme/defaultTheme'
import { THEMES } from '@/theme/types'
import { InstitutionConfigType } from '@/types/InstitutionConfigType'
import { genericLayoutMenuItems } from '@/utils/menu-items'
import { getSubdomainFromLocalStorage } from '@/utils/subdomain'
import { computed, defineComponent, inject, onBeforeMount, onMounted, onUnmounted, provide, reactive, Ref, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CloseIcon from '../Icons/default/CloseIcon.vue'
import MenuIcon from '../Icons/default/MenuIcon.vue'
import ChevronLeftDoubleIcon from '../Icons/default/redesign/ChevronLeftDoubleIcon.vue'
import WarningIcon from '../Icons/default/WarningIcon.vue'
import NoAccess from '../Modal/NoAccess.vue'
import getUserInfos from '@/helpers/UserInfos'
import authenticationService from '@/services/authentication'
import AuthHelper from '@/helpers/AuthHelper'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import PazzeiButton from '@/components/Generics/Redesign/PazzeiButton.vue';

export default defineComponent({
  name: 'GenericLayout',
  components: { PazzeiAvatar, ChevronLeftDoubleIcon, MenuIcon, CloseIcon, WarningIcon, NoAccess, PazzeiButton },
  props: {
    isPageReports: { type: Boolean, default: false },
    redesignedSharedLists: { type: Boolean, default: false },
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    const institutionConfig = inject<InstitutionConfigType>('institutionConfig');
    const userConfig = inject<Ref<UserDTO>>('userDTO')

    const page = route.path;
    const title = institutionConfig?.name;
    const currentTheme = institutionConfig?.config.theme ?? DefaultTheme();
    const area = localStorage.getItem('areas') ?? ''
    const areasToCheck = JSON.parse(area)

    let menuList = genericLayoutMenuItems.common(institutionConfig, userConfig?.value);
    const authMenuList = genericLayoutMenuItems.auth(institutionConfig, userConfig?.value);

    const permissions = authService.getPermissions()?.permissions
    const institutionStudent = !!permissions?.includes('self:lists:shared-lists:retrieve')
    const isSimulating = permissions?.includes('root:users:simulate-access:manage')

    const miniState = ref(localStorage.getItem('drawerMiniState') === 'true')
    const selected = ref(localStorage.getItem('selectedTab') ?? 'Montar lista')
    const drawer = ref(true)
    const userInfos = ref<{ autoRenew?: boolean; credits?: number }>({})
    const warningHasBeenShown = ref(false)
    const brandHomeUrl = ref<string>(institutionConfig?.landingPageUrl ?? '/');
    const innerWidth = ref(window.innerWidth)
    const userEmail = ref('')
    const userId = ref('')

    provide('warningHasBeenShown', warningHasBeenShown)

    const setInnerWidth = () => {
      innerWidth.value = window.innerWidth
    }

    window.addEventListener('resize', setInnerWidth)

    const logo = reactive({
      current: !miniState.value ? currentTheme.assets.logo : currentTheme.assets.shortLogo,
    })

    onMounted(async () => {
      const auxUserInfos = sessionStorage.getItem('userInfos')
      userInfos.value = JSON.parse(auxUserInfos ?? '{}')
      warningHasBeenShown.value = sessionStorage.getItem('warningHasBeenShown') === 'true'

      const user = await getUserInfos();
      userEmail.value = user?.email
      userId.value = user?.id

      if (page === '/checkout' || page === '/bem-vindo') {
        showNavBar.value = false
        warningHasBeenShown.value = true
      }
      if (innerWidth.value <= 768) drawer.value = false
    })

    const isInternalLogoLink = () => {
      return brandHomeUrl.value.startsWith('/');
    }

    const logout = () => {
      authService.logoutUser()
    }

    const institutionPlanId = ref(0);

    const filteredMenuList = computed(() => {
      const userScoped = authService.getHigherScoped()

      if (!permissions?.includes('self:lists:lists:enem-simulation') && (institutionPlanId.value && ![1, 2, 3].includes(institutionPlanId.value))) {
        menuList = menuList.filter(item => item.to !== '/modulo-enem')
      }

      if (userScoped && (userScoped === 'root' || userScoped === 'group')) return menuList.filter((menu) => userScopeCanGo.userRootandGroup.includes(menu.to))
      else if (institutionStudent) {
        if (areasToCheck.length) return menuList.filter((menu) => userScopeCanGo.userB2b.includes(menu.to) && !menu.top)
        return menuList.filter((menu) => userScopeCanGo.userB2bWithoutModuleEnem.includes(menu.to) && !menu.top)
      } else if (userScoped === 'self' && !institutionStudent) return menuList.filter((menu) => userScopeCanGo.userB2c.includes(menu.to))
      else return menuList.filter((menu) => userScopeCanGo.userAll.includes(menu.to))
    })

    const filteredHeaderMenuList = computed(() => {
      if (institutionStudent) {
        menuList.map((menu) => {
          if (menu.top) {
            menu.label = 'Métricas';
          }
        })

        if (areasToCheck.length) return menuList.filter((menu) => userScopeCanGo.userB2b.includes(menu.to) && menu.top)
        return menuList.filter((menu) => userScopeCanGo.userB2bWithoutModuleEnem.includes(menu.to) && menu.top)
      } else return menuList.filter((menu) => userScopeCanGo.userAll.includes(menu.to) && menu.top)
    })

    const itemClickHandle = (label: string, to: string) => {
      localStorage.setItem('selectedTab', label)

      router.push(to).then(() => {
        if (route.path !== '/testar-o-pazzei') {
          localStorage.setItem('lastPage', route.path)
        }

        if (innerWidth.value <= 768) drawer.value = false
      })
    }

    const drawerStateHandle = () => {
      logo.current = miniState.value ?  DefaultTheme().assets.logo : DefaultTheme().assets.shortLogo,

      miniState.value = !miniState.value
      localStorage.setItem('drawerMiniState', `${miniState.value}`)
    }

    const showNavBar = ref(true)

    const seeActiveTab = () => {
      if (
        page === '/meus-dados'
        || page === '/dados-da-conta'
        || page === '/perfil-do-aluno'
        || page === '/meus-planos'
        || page === '/testar-o-pazzei'
      ) {
        return 'no-active-tab'
      }
      else return 'active-tab'
    }

    const handleCloseWarning = () => {
      warningHasBeenShown.value = true
      sessionStorage.setItem('warningHasBeenShown', 'true')
    }

    const $q = useQuasar();

    const notification = (success: boolean, message: string) => {
      if (success) {
        $q.notify({
          textColor: 'grey-1',
          message: message,
          color: 'green',
          position: 'top',
          classes: 'notify',
        })
      } else {
        $q.notify({
          textColor: 'grey-1',
          message: message,
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    const stopSimulation = async () => {
      try {
        const { data: res } = await authenticationService.patch('/stop-simulation', { id: userId.value })
        const { token, refreshToken } = res.data;

        if (token && refreshToken) {
          await AuthHelper(token, refreshToken).then(() => {
            notification(true, 'Simulação encerrada com sucesso')
          }).finally(() => {
            router.push('/').then(() => {
              window.location.reload();
            })
          });
          
          return;
        }

        notification(false, 'Falha ao encerrar simulação, tente fazer logout e login novamente')
        return;
      } catch (error) {
        notification(false, 'Falha ao encerrar simulação, tente fazer logout e login novamente')
      }
    }

    onUnmounted(() => {
      window.removeEventListener('resize', setInnerWidth)
    })

    watch(
      route,
      () => {
        if (route.path === '/') selected.value = 'Início'
        else if (route.path === '/listas') selected.value = 'Montar lista'
        else if (route.path === '/usuarios') selected.value = 'Usuários'
        else if (route.path === '/instituicoes') selected.value = 'Instituições'
        else if (route.path === '/modulo-enem') selected.value = 'Treine para o Enem'
      },
      { deep: true, immediate: true }
    )

    const canShowFooter = computed(() => {
       const allowedSubdomains = [THEMES.ARCANJO]
       const currentSubdomain = getSubdomainFromLocalStorage();

       if(!currentSubdomain) {
         return false
       }

       return allowedSubdomains.includes(currentSubdomain);
    })

    onBeforeMount(async () => {
      const institutionPlan = await InstitutionPlans.getPlan();
      institutionPlanId.value = institutionPlan.id;
    })

    return {
      drawer,
      miniState,
      selected,
      handleCloseWarning,
      warningHasBeenShown,
      menuList,
      authMenuList,
      userInfos,
      itemClickHandle,
      drawerStateHandle,
      showNavBar,
      seeActiveTab,
      hideButtonOnMobile: ref(false),
      filteredMenuList,
      logo,
      logout,
      filteredHeaderMenuList,
      DefaultTheme,
      THEMES,
      canShowFooter,
      title,
      brandHomeUrl,
      isInternalLogoLink: isInternalLogoLink(),
      userEmail,
      stopSimulation,
      isSimulating,
    }
  },
})
