import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.size + 1,
    height: _ctx.size,
    viewBox: "0 0 21 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M2.71765 15.2823L6.54587 11.4541M15.6813 2.31873C18.7729 5.41038 18.7729 10.4229 15.6813 13.5146C12.5896 16.6062 7.57707 16.6062 4.48542 13.5146M14.6667 18.3333H6.33335M10.5 18.3333V15.8333M15.0833 7.91665C15.0833 10.6781 12.8448 12.9167 10.0833 12.9167C7.32192 12.9167 5.08335 10.6781 5.08335 7.91665C5.08335 5.15523 7.32192 2.91665 10.0833 2.91665C12.8448 2.91665 15.0833 5.15523 15.0833 7.91665Z",
      stroke: _ctx.stroke,
      "stroke-width": "1.66667",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}