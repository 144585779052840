export class FilterEnemKnowledgeTree {
  filters: {
    areas: Array<AreasOnKnowledgeTree>
  }

  constructor(areas: Array<AreasOnKnowledgeTree>) {
    this.filters = {
      areas: areas,
    }
  }
}

export class AreasOnKnowledgeTree {
  name: string
  subjects: Array<string>

  constructor(name: string, subjects: Array<string>) {
    this.name = name
    this.subjects = subjects
  }
}
