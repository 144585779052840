
import PaginationTableComponent from '@/components/Application/planSchoolPortal/mountTableComponent/components/PaginationTableComponent.vue'
import TablesComponentCoupon from '@/components/Application/planSchoolPortal/mountTableComponent/TablesComponent.vue'
import ArrowBackIcon from '@/components/Icons/default/ArrowBackIcon.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import paymentsService from '@/services/payment'
import DefaultTheme from '@/theme/defaultTheme'
import { ResponseListUsersCoupon, ResponseListUsersData } from '@/types/ResponseCouponType'
import { formatCurrency } from '@/utils/common/formatCurrency'
import { usersCoupon } from '@/utils/hardCoded/coupon/usersCoupon'
import debounce from 'quasar/src/utils/debounce.js';import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, onBeforeUnmount, onMounted, provide, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'ViewUsersCouponPage',
  components: {
    GenericLayout,
    MainLayoutWrapper,
    TablesComponentCoupon,
    PaginationTableComponent,
    ArrowBackIcon
  },

  setup() {
    const isSearchInputExpanded = ref(true)
    const isModalOpen = ref(false)
    const sizeColumnsCoupon = ref('1fr / 2.5fr 2fr 1.5fr 1.5fr 1.5fr 1fr 0.15fr')
    const optionsPagination = [10, 20, 50, 100]
    const innerWidth = ref(window.innerWidth)
    const currentPage = ref(1)
    const router = useRouter()
    const pageSize = ref(20)
    const isLoading = ref(false)
    const sizeLoading = ref('60px')
    const totalElements = ref(0)
    const filterField = ref<string | undefined>('')
    const heightLoading = ref('13.5em')
    const $q = useQuasar()
    const previousPageSize = ref(20)
    const previousCurrentPage = ref(1)
    const heightTable = ref('49em')
    const widthTable = ref('70em')
    const route = useRoute()
    const couponId = route.params.id
    const rows = ref<Array<ResponseListUsersData>>([])
    const nameCoupon = ref(route.query.codigoCupom || '')

    const openModal = () => (isModalOpen.value = true)
    const setNewTab = () => {
      innerWidth.value = window.innerWidth
    }
    window.addEventListener('resize', setNewTab)

    const handlePageSizeChange = (selectedPageSize: number) => {
      if (selectedPageSize !== previousPageSize.value) {
        pageSize.value = selectedPageSize
        listUsersCouponData()
        previousPageSize.value = selectedPageSize
      }
    }

    const handleCurrentPage = (selectedCurrentPage: number) => {
      if (selectedCurrentPage !== previousCurrentPage.value) {
        currentPage.value = selectedCurrentPage
        listUsersCouponData()
        previousCurrentPage.value = selectedCurrentPage
      }
    }

    onMounted(() => {
      if (innerWidth.value <= 768) {
        sizeColumnsCoupon.value = '1fr / 3.5fr 2.5fr 2fr 2fr 2.5fr 2fr 0.15fr'
        heightTable.value = '58vh'
        sizeLoading.value = '2.1875em'
        widthTable.value = '60em'
        heightLoading.value = '16em'
      }

      listUsersCouponData()
    })

    const listUsersCouponData = async () => {
      isLoading.value = true
      try {
        const { data: infos } = await paymentsService.get<ResponseDTO<ResponseListUsersCoupon>>('/coupons/used', { params: { id: couponId, page: currentPage.value, pageSize: pageSize.value } })
        rows.value = infos.data.data.map((it) => {
          return {
            code: it.code,
            name: it.name,
            plan: it.plan,
            createdAt: it.createdAt,
            discount: it.discount || it.discount === 0 ? formatCurrency(Number(it.discount)) : '',
            price: it.price || it.price === 0 ? formatCurrency(Number(it.price)) : '',
            fullPrice: it.fullPrice || it.fullPrice === 0 ? formatCurrency(Number(it.fullPrice)) : '',
          }
        })

        totalElements.value = infos.data.totalElements
      } catch (error) {
        console.error(error)
        $q.notify({
          textColor: 'grey-1',
          message: 'Erro ao buscar dados',
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      } finally {
        isLoading.value = false
      }
    }

    const debouncedListUsersCouponData = debounce(() => {
      listUsersCouponData()
    }, 500)

    watch(filterField, () => {
      currentPage.value = 1
      debouncedListUsersCouponData()
    })

    provide('heightLoading', heightLoading)

    onBeforeUnmount(() => {
      window.removeEventListener('resize', setNewTab)
    })

    return {
      openModal,
      handlePageSizeChange,
      handleCurrentPage,
      getCouponData: listUsersCouponData,
      isSearchInputExpanded,
      isModalOpen,
      optionsPagination,
      innerWidth,
      sizeLoading,
      isLoading,
      totalElements,
      filterField,
      heightTable,
      widthTable,
      sizeColumnsCoupon,
      usersCoupon,
      rows,
      router,
      nameCoupon,
      DefaultTheme
    }
  },
})
