
import B2bAuthLayout from '@/components/Layouts/B2bAuthLayout/B2bAuthLayout.vue'
import { defineComponent } from 'vue'
import B2bSignUpInviteForm from '@/components/LoginForms/B2bSignUpInviteForm/B2bSignUpInviteForm.vue'
import { useRoute, useRouter } from 'vue-router'
import { emailRegex } from '@/utils/regex/emailRegex'

export default defineComponent({
  name: 'SignUpB2bInvitePage',
  components: {
    B2bAuthLayout,
    B2bSignUpInviteForm,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const removeQueryParam = (param: string) => {
      const { [param]: _, ...newQuery } = route.query;
      router.replace({ query: newQuery }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    }

    const getEmailFromQuery = () => {
      const { email: _email } = route.query;
      const email = _email as string;

      removeQueryParam('email');

      return (email && emailRegex.test(email)) ? email as string : undefined;
    }

    const heading = {
      title: 'Bem-vindo ao Pazzei!',
      subtitle: `Você foi convidado pela sua escola a usar o Pazzei.\nComplete os campos abaixo para criar sua conta.`
    }

    return {
      heading,
      email: getEmailFromQuery(),
    }
  },
})
