import axios from 'axios'

const groupsService = axios.create({
  baseURL: process.env.VUE_APP_PAZZEI_GROUPS_BASE_URL,
  timeout: 60000,
})

groupsService.interceptors.request.use((request) => {
  const token = sessionStorage.getItem('token') ?? localStorage.getItem('token')

  if (request.headers && token) {
    request.headers.Authorization = `Bearer ${token}`
    request.headers['Access-Control-Allow-Origin'] = '*'
  }
  return request
})

export default groupsService
