
import Button from '@/components/Library/Button/index.vue'
import ButtonGroup from '@/components/Library/ButtonGroup/index.vue'
import CardModule from '@/components/Library/CardModule/index.vue'
import TabPanel from '@/components/Library/Tabs/TabPanel/index.vue'
import TabPanels from '@/components/Library/Tabs/TabPanels/index.vue'
import { largeAreas } from '@/utils/hardCoded/enemModule/enemModule'
import { computed, defineComponent, onMounted, PropType, ref, toRaw } from 'vue'
import { OnSelectedAreaType } from './types'

export default defineComponent({
  name: 'EnemModule',
  emits: ['selected-areas'],
  components: {
    Button,
    ButtonGroup,
    CardModule,
    TabPanel,
    TabPanels,
  },
  props: {
    cache: { type: Object as PropType<OnSelectedAreaType>, default: () => ({}) },
    lockResources: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const buttonGroup = ref('Simulado Enem')
    const selectedAreas = ref({
      languagesAndCodesActive: false,
      humanitiesAndTechnologiesActive: false,
      naturalSciencesAndTechnologiesActive: false,
      mathematicsAndTechnologiesActive: false,
    })

    const area = localStorage.getItem('areas') ?? ''
    const areasToCheck: Array<string> = JSON.parse(area)

    const showAreas = ref({
      languagesAndCodesActive: false,
      humanitiesAndTechnologiesActive: false,
      naturalSciencesAndTechnologiesActive: false,
      mathematicsAndTechnologiesActive: false,
    })

    areasToCheck.forEach((it) => {
      if (it === 'Linguagens, Códigos e suas Tecnologias') showAreas.value.languagesAndCodesActive = true
      else if (it === 'Ciências Humanas e suas Tecnologias') showAreas.value.humanitiesAndTechnologiesActive = true
      else if (it === 'Ciências da Natureza e suas Tecnologias') showAreas.value.naturalSciencesAndTechnologiesActive = true
      else if (it === 'Matemática e suas Tecnologias') showAreas.value.mathematicsAndTechnologiesActive = true
    })

    onMounted(() => {
      if (props.cache && props.cache.areas) {
        buttonGroup.value = props.cache.type

        props.cache.areas.forEach((area) => {
          const idx = area.id as keyof typeof selectedAreas.value

          selectedAreas.value[idx] = true
        })
      }
    })

    const btnIsDisabled = computed(() => Object.values(selectedAreas.value).every((area) => !area))

    const handleNextBtnClick = () => {
      const selectedAreasEntries = Object.entries(selectedAreas.value)

      const large = selectedAreasEntries.filter((area) => area[1]).map((area) => largeAreas.find((largeArea) => largeArea.id === area[0]))

      emit('selected-areas', { areas: large, type: toRaw(buttonGroup.value) })
    }

    return { buttonGroup, selectedAreas, btnIsDisabled, handleNextBtnClick, showAreas }
  },
})
