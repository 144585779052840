import { PermissionDTO } from '@/dtos/PermissionDTO'
import InstitutionPlans from '@/helpers/InstitutionPlans'
import getUserInfos from '@/helpers/UserInfos'
import authService from '@/services/authService'
import { ScopedType } from '@/types/ScopedType'

import { createRouter, createWebHistory, RouteMeta } from 'vue-router'
import routes from './routes'
import lockedPaths from '@/utils/lockedPaths'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const redirectRoutesByScope: Record<ScopedType, Array<string>> = {
  root: ['exerciseListsPage'],
  all: [],
  group: ['exerciseListsPage'],
  self: [],
}

class ProtectedRoute {
  requiresAuth = false
  requiredScoped = Array<ScopedType>()
  requiredPermission = Array<PermissionDTO>()
  constructor(met: RouteMeta)
  constructor(met: RouteMeta & { requiresAuth: boolean; requiredScoped: Array<string>; requiredPermission: string })
  constructor(
    met:
      | RouteMeta
      | (RouteMeta & {
          requiresAuth: boolean
          requiredScoped: Array<ScopedType>
          requiredPermission: string
        })
  ) {
    if ('requiresAuth' in met && typeof met.requiresAuth === 'boolean') this.requiresAuth = met.requiresAuth
    if ('requiredScoped' in met && Array.isArray(met.requiredScoped)) this.requiredScoped = met.requiredScoped
    if ('requiredPermission' in met && typeof met.requiredPermission === 'string') {
      if (this.requiredScoped.length) {
        const splittedPermission = met.requiredPermission.split(':')
        if (splittedPermission.length !== 3) throw new Error('Invalid permission')
        this.requiredPermission = this.requiredScoped.map((it) => {
          return new PermissionDTO(it, splittedPermission[0], splittedPermission[1], splittedPermission[2])
        })
      } else {
        const splittedPermission = met.requiredPermission.split(':')
        this.requiredPermission = (['self', 'root', 'group', 'all'] as Array<ScopedType>).map((it) => {
          return new PermissionDTO(it, splittedPermission[0], splittedPermission[1], splittedPermission[2])
        })
      }
    }
  }

  hasPermission(permissionDTOs: Array<PermissionDTO>) {
    if (!this.requiresAuth) return true
    if (!this.requiredPermission.length) return true
    return permissionDTOs.some((permissionDTO) => {
      this.requiredPermission.find((it) => {
        return it.basePermission === permissionDTO.basePermission && it.scope === permissionDTO.scope
      })
    })
  }

  isForbidden(permissionDTOs: Array<PermissionDTO>) {
    if (!this.requiresAuth) return false
    if (!this.requiredPermission.length) return false
    return (
      !this.hasPermission(permissionDTOs) &&
      permissionDTOs.some((permissionDTO) => this.requiredPermission.some((requiredPermissionDTO) => {
        return requiredPermissionDTO.basePermission === permissionDTO.basePermission
      }))
    )
  }
}

const redirectToPazzeiTrialPage = async (roleCode: string, credits: number, path: string) => {
  if (
    roleCode === 'pazzei:trial'
    && (credits >= 0 && credits <= 7)
    && ['/', '/listas', '/banco-de-provas', '/modulo-enem'].includes(path)
    && !localStorage.getItem('pazzeiTrialPageHasBeenShown')
  ) {
    return true;
  }
}

router.beforeEach(async (to, from, next) => {
  const protectedRoute = new ProtectedRoute(to.meta)
  const userPermissionDTOs = authService.generatePermissions()
  const hasCredits = authService.verifyPermissions() === 'home';

  const userInfos = await getUserInfos();
  const roleCode = userInfos?.roleCode;
  const credits = userInfos?.credits;

  const { createdAt, registrationCompleted } = userInfos || {};

  const institutionPlan = await InstitutionPlans.getPlan();
  const isPrivateRoute = lockedPaths.includes(to.path);

  if (await redirectToPazzeiTrialPage(roleCode, credits, to.path)) {
    localStorage.setItem('lastPage', to.path);
    next({ name: 'InviteToTestPage'});
    return;
  }

  if ((roleCode === 'pazzei:no-credits' && !institutionPlan) || !hasCredits) {
    localStorage.setItem('lastPage', '/');
  }

  if (protectedRoute.requiresAuth) {
    if (!userPermissionDTOs.length) next({ name: 'loginPage' })

    if (!protectedRoute.hasPermission(userPermissionDTOs)) {
      const userScope = authService.getHigherScoped()
      const toRouteName = to.name
      const isRouteForbidden = false;

      const mustChangePassword = userInfos
        && (!registrationCompleted
          && (new Date(createdAt).getFullYear() >= 2025) 
          && institutionPlan?.roleCode === 'arcanjo:user'
        )

      if(to.path === '/redefinir-senha' && !mustChangePassword) {
        next({ name: 'HomePage' })
        return
      }

      if (to.path !== '/' && userScope && toRouteName && typeof toRouteName === 'string' && !redirectRoutesByScope[userScope].includes(toRouteName)) {
        if (isRouteForbidden && isPrivateRoute) {
          next({ name: 'ForbiddenPage' })
        }
      } 
      else {
        if (mustChangePassword) {
          next({ name: 'ChangePasswordPage' })
          return
        }

        switch (userScope) {
          case 'group':
          case 'root': {
            next({ name: 'viewUsersPage' })
            break
          }
          case 'all': {
            next({ name: 'viewInstitutionsPage' })
            break
          }
        }
      }
      if (isRouteForbidden && isPrivateRoute) {
        next({ name: 'ForbiddenPage' })
      }
    }
  }
  next()
})

export default router
