
import CheckIcon from '@/components/Icons/default/redesign/CheckIcon.vue';
import DefaultTheme from '@/theme/defaultTheme';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PazzeiCheckbox',
  props: {
    labelText: { type: String },
    isChecked: { type: Boolean }
  },
  components: {
    CheckIcon
  },
  setup() {
    return {
      DefaultTheme
    }
  }
})
